import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  CommodityDto,
  CommodityDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  commodityStore,
  getCommodities,
  deleteCommodity,
  updateCommoditiesColumns,
} from '../commodities.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_COMMODITY_LINK_KEY,
  DELETE_COMMODITY_LINK_KEY,
  GetCommodityParams,
} from '../commodities.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { COMMODITIES_CREATE_PATH } from '../commodity.route';
import { CommodityDialog } from './commodity.dialog';
import { authStore, userHas } from '../../auth/auth.store';
import { CommodityGrid } from '../../common/components/grid/commodity-grid.component';

export type CommoditiesListProps = {
  goToDetails?: (commodityParams: GetCommodityParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    commodity: CommodityDto,
    commodityParams: GetCommodityParams,
  ) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const CommoditiesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch,
}: CommoditiesListProps) => {
  const { user: currentUser } = authStore.getState();
  const [
    commodities,
    setCommodities,
  ] = useState<CommodityDtoPagedResult | null>(null);

  useEffect(() => {
    getCommoditiesData();
  }, [offset, limit, sort, filter, search]);
  const getCommoditiesData = () => {
    getCommodities({ offset, limit, sort, filter, search }).then(
      (commoditiesData) => {
        setCommodities(commoditiesData);
      },
      () => {},
    );
  };
  const { commodityColumns: columns } = useStore(commodityStore);

  const onDeleteCommodity = (commodity: CommodityDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete Commodity`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteCommodity(commodity).then(
          () => {
            onDelete();
            getCommoditiesData();
          },
          () => {},
        );
      }
    });
  };

  const onEditCommodity = (commodity: CommodityDto) => {
    goToDetails({ commodityId: commodity?.commodityId });
  };

  const history = useHistory();
  const onCreateNewCommodity = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + COMMODITIES_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onCommoditySelect = (commodity, commodityId) => {
    if (onSelect) onSelect(commodity, commodityId);
    showDialog({
      dialog: CommodityDialog,
      props: {
        commodityId: commodity.commodityId,
        title: 'Update Commodity',
        saveButtonRenderCondition:
          currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole,
      },
    }).then((commodity) => {
      if (commodity !== null) {
        getCommoditiesData();
      }
    });
  };

  return (
    <>
      <CommodityGrid
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={['commodityId']}
        data={commodities?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        onFilter={onFilter}
        filter={filter}
        total={commodities?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_COMMODITY_LINK_KEY, commodities?.items[0]?.links)
            ? onDeleteCommodity
            : null
        }
        onSort={onSort}
        onEdit={onEditCommodity}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateCommoditiesColumns}
        onSelect={onCommoditySelect}
      />
      {!(
        (commodities && commodities.items && commodities.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">You Don't Have Any Commodity Yet</h3>
          {userHas(CREATE_COMMODITY_LINK_KEY) && (
            <Button size={'lg'} color="primary" onClick={onCreateNewCommodity}>
              Create Commodity
            </Button>
          )}
        </div>
      )}
    </>
  );
};
