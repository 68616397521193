import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  FORWARDING_AGENTS_CREATE_PATH,
  FORWARDING_AGENTS_LIST_PATH,
} from '../forwardingAgent.route';
import { ForwardingAgentEdit } from '../components/forwardingAgent-edit.component';
import {
  addMessage,
  Message,
  messagesStore,
} from '../../common/messages.store';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import { CREATE_CONTACT_LINK_KEY } from '../../contacts/contacts.service';
import { authStore, userHas } from '../../auth/auth.store';

export type ForwardingAgentScreenProps = { pageTitle: string };

export const ForwardingAgentScreen = ({
  pageTitle,
}: ForwardingAgentScreenProps) => {
  const { user: currentUser } = authStore.getState();

  const history = useHistory();
  // @ts-ignore
  const { organizationId, contactId } = useParams();
  const { messages } = messagesStore.getState();
  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + FORWARDING_AGENTS_CREATE_PATH, {
      organizationId,
    });
  }
  const onForwardingAgentCreated = () => {
    const path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'forwarding-agent-created',
      type: 'success',
      autoHide: true,
      message: 'Forwarding Agent successfully created!',
    };
    history.push(path);
    addMessage(message);
  };
  const onForwardingAgentUpdated = () => {
    const path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'forwarding-agent-updated',
      type: 'success',
      autoHide: true,
      message: 'Forwarding Agent successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + FORWARDING_AGENTS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };
  return (
    <InternalLayout
      title={'Forwarding Agents'}
      createPath={
        userHas(CREATE_CONTACT_LINK_KEY) && currentUser?.isInOrgAdminRole
          ? createPath()
          : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ForwardingAgentEdit
        contactId={contactId}
        onForwardingAgentUpdated={onForwardingAgentUpdated}
        onForwardingAgentCreated={onForwardingAgentCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
