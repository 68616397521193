import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { PortEdit } from '../components/port-edit.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PORTS_LIST_PATH, PORTS_CREATE_PATH } from '../ports.route';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_PORT_LINK_KEY } from '../port.service';
import { userHas } from '../../auth/auth.store';

export type PortScreenProps = { pageTitle: string };

export const PortScreen = ({ pageTitle }: PortScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, portId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + PORTS_CREATE_PATH, {
    organizationId,
  });

  const onPortCreated = () => {
    const path = generatePath(INTERNAL_PATH + PORTS_CREATE_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'port-created',
      type: 'success',
      autoHide: true,
      message: 'Port successfully created!',
    };
    history.push(path);
    addMessage(message);
  };

  const onPortUpdated = () => {
    const path = generatePath(INTERNAL_PATH + PORTS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'port-updated',
      type: 'success',
      autoHide: true,
      message: 'Port successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + PORTS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };

  return (
    <InternalLayout
      title={'Port'}
      createPath={userHas(CREATE_PORT_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PortEdit
        portId={portId}
        onPortUpdated={onPortUpdated}
        onPortCreated={onPortCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
