import {
  AccountType,
  AddressType,
  ChargeDto,
  CommodityDto,
  ContactAddressDto,
  ContactDto,
  ContactType,
  DocumentTemplateDto,
  DocumentTemplateType,
  LinkDto,
  OrderDeliveryDto,
  OrderDto,
  OrderPickupDto,
  OrderStatuses,
  OrderTrackingDto,
  OrderTrackingStatuses,
  OrganizationDto,
  PaidAs,
  QuoteStatuses,
  TrackingEventDto,
  UninvoicedOrderDto,
} from './data.models';
import { InputPublicProps } from '../modules/common/components/input/input.component';

export class OrderDeliveryData implements OrderDeliveryDto {}

export class OrderPickupData implements OrderDeliveryDto {}

export interface OrderForListDto extends OrderDto {
  firstOrderPickupsPickDate?: string;
  firstOrderDeliveriesDeliveryDate?: string;
  firstOrderPickupsShipperAddressName?: string;
  firstOrderDeliveriesConsigneeAddressName?: string;
}
export interface UninvoicedOrderForListDto extends UninvoicedOrderDto {
  shippingDateString?: string;
}
export interface CarrierPayManagerForListDto {
  organizationId: number;
  orderCarrierId: number;
  orderId: number;
  carrierId: number;
  charges: number[];
  carrierName: string;
  customerName: string;
  origin: string;
  shippedDate: Date | string;
  destination: string;
  deliveredDate: Date | string;
  carrierFee: number;
  balanceOwning: number;
  billReceived: boolean;
  receivedDate?: any;
  aging?: any;
  transactionNumber?: any;
  accountingTransactionId: number;
  links: LinkDto[];
}
export interface DriverPayManagerForListDto {
  organizationId: number;
  orderCarrierId: number;
  orderId: number;
  carrierId: number;
  charges: number[];
  carrierName: string;
  customerName: string;
  origin: string;
  shippedDate: Date | string;
  destination: string;
  deliveredDate: Date | string;
  carrierFee: number;
  balanceOwning: number;
  gross: number;
  billReceived: boolean;
  receivedDate?: any;
  aging?: any;
  transactionNumber?: any;
  accountingTransactionId: number;
  links: LinkDto[];
}
export interface InvoiceManagerForListDto {
  organizationId: number;
  orderId: number;
  orderNumber: string;
  shippingDate?: Date;
  customerId: number;
  customerName?: string;
  carrierDriverIds: number[];
  carrierDriverNames: string[];
  transactionNumber?: string;
  accountingTransactionId?: number;
  invoiceGenerated: boolean;
  links: LinkDto[];
}
export interface CarrierPayManagerForListDtoPagedResult {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: CarrierPayManagerForListDto[] | undefined;
  links?: LinkDto[] | undefined;
}
export interface DriverPayManagerForListDtoPagedResult {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: DriverPayManagerForListDto[] | undefined;
  links?: LinkDto[] | undefined;
}
export interface InvoiceManagerForListDtoPagedResult {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: InvoiceManagerForListDto[] | undefined;
  links?: LinkDto[] | undefined;
}

export interface OrderDtoForListPagedResult {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: OrderForListDto[] | undefined;
  links?: LinkDto[] | undefined;
}

export interface UninvoicedOrderDtoForListPagedResult {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: UninvoicedOrderForListDto[] | undefined;
  links?: LinkDto[] | undefined;
}

export interface ReactSelectItem {
  value?: string;
  label?: string;
}
export interface CreateContactAndContactAddressesCommand {
  contact?: ContactDto;
  shippingAddress?: ContactAddressDto;
  billingAddress?: ContactAddressDto;
}
export interface UpdateContactAndContactAddressesCommand {
  contact?: ContactDto;
  shippingAddress?: ContactAddressDto;
  billingAddress?: ContactAddressDto;
}
export interface ContactWithAddressesDto extends ContactDto {
  shippingAddress?: ContactAddressDto;
  billingAddress?: ContactAddressDto;
  mcCode?: string;
  ffCode?: string;
  dotCode?: string;
}
export interface ProfitValues {
  expense: number;
  income: number;
  profit: number;
}
export interface FormattedProfitValues {
  expense: string;
  income: string;
  profit: string;
}
export interface ChargeStatisticValues {
  paidAs: PaidAs;
  amount: number;
  tax: number;
  totalAmount: number;
  amountDue: number;
}
export class OrderPickupEditFormDto implements OrderPickupDto {
  orderPickupId?: number;
  created?: Date;
  createdBy?: string | undefined;
  lastModified?: Date;
  lastModifiedBy?: string | undefined;
  orderId?: number;
  orderPickupSequence?: number;
  pickDate?: Date | undefined | null | string;
  shipperAddressId?: number | undefined;
  shipperAddressName?: string | undefined;
  shipperContactId?: number | undefined;
  shipperContactName?: string | undefined;
  shipperContactType?: ContactType | undefined;
  shippingNotes?: string | undefined;
  links?: LinkDto[] | undefined;
  shipperFilter: string;
}
export class OrderDeliveryEditFormDto implements OrderDeliveryDto {
  orderDeliveryId?: number;
  consigneeAddressId?: number | undefined;
  consigneeAddressName?: string | undefined;
  consigneeContactId?: number | undefined;
  consigneeContactName?: string | undefined;
  consigneeContactType?: ContactType | undefined;
  created?: Date;
  createdBy?: string | undefined;
  deliveryDate?: Date | undefined | null | string;
  deliveryNotes?: string | undefined;
  lastModified?: Date;
  lastModifiedBy?: string | undefined;
  orderDeliverySequence?: number;
  orderId?: number;
  links?: LinkDto[] | undefined;
  consigneeFilter: string;
}
export class OrganizationEditFormDto implements OrganizationDto {}
export class DocumentTemplateEditFormDto implements DocumentTemplateDto {
  documentTemplateId?: number | undefined;
  bodyHtmlTemplate?: string | undefined;
  bodyTextTemplate?: string | undefined;
  created?: Date | string;
  createdBy?: string | undefined;
  description?: string | undefined;
  documentTemplateType?: DocumentTemplateType | undefined;
  fileNameTemplate?: string | undefined;
  isDefault?: boolean | undefined;
  isInactive?: boolean | undefined;
  lastModified?: Date | string;
  lastModifiedBy?: string | undefined;
  name?: string | undefined;
  organizationId?: number | undefined;
  subjectTemplate?: string | undefined;
  createdByUserName?: string | undefined;
  updatedByUserName?: string | undefined;
  links?: LinkDto[] | undefined;
}
export class OrderEditFormDto implements OrderDto {
  orderId?: number;
  billToContactId?: number | undefined;
  billToContactName?: string | undefined;
  billToContactType?: ContactType | undefined;
  carrierContactId?: number | undefined;
  carrierContactName?: string | undefined;
  created?: Date;
  createdBy?: string | undefined;
  divisionId?: number;
  divisionName?: string;
  employeeContactId?: number | undefined;
  employeeContactName?: string | undefined;
  equipmentTypeId?: number | undefined;
  equipmentTypeName?: string | undefined;
  lastModified?: Date;
  lastModifiedBy?: string | undefined;
  orderNumber?: string | undefined;
  orderStatus?: string | OrderStatuses;
  quoteStatus?: string | QuoteStatuses;
  organizationId?: number;
  salespersonContactId?: number | undefined;
  salespersonContactName?: string | undefined;
  orderPickups?: OrderPickupEditFormDto[] | undefined;
  orderDeliveries?: OrderDeliveryEditFormDto[] | undefined;
  commodities?: CommodityDto[] | undefined;
  charges?: ChargeDto[] | undefined;
  trackingEvents?: TrackingEventDto[];
  totalPcsCrt: number;
  weighTotal: number;
  volumeTotal: number;
  carriers: ContactDto[];
  links?: LinkDto[] | undefined;
  createdByUserName: string;
  lastModifiedByUserName: string;
  customValues?: object | undefined;
  trackingNumber?: number;
  modeOfTransportationId?: number;
  modeOfTransportationDescription?: string;
  paymentTermId?: number;
  paymentTermDescription?: string;
  expDate?: Date;
}
export class SendEmailFormDto {
  recipients?: string[] | undefined;
  sendMeACopy?: boolean | undefined;
  subject?: string | undefined;
  message?: string | undefined;
  documentTemplates?: DocumentTemplateDto[] | undefined;
}
export enum Ocean {
  Vessel = 'Vessel',
  VesselAndContainerized = 'Vessel Containerized',
  Barge = 'Barge',
}
export enum Rail {
  Rail = 'Rail',
  RailAndContainerized = 'Rail Containerized',
}
export enum Ground {
  Truck = 'Truck',
  TruckAndContainerized = 'Truck Containerized',
  Auto = 'Auto',
  Pedestrian = 'Pedestrian',
  RoadAndOther = 'Road Other',
}
export enum Air {
  Air = 'Air',
  AirAndContainerized = 'Air Containerized',
}
export enum Pipe {
  FixedTransport = 'Fixed Transport',
}
export enum Unknown {
  PassengerAndHandCarried = 'Passenger HandCarried',
}
export enum Mail {
  Mail = 'Mail',
}
export class ReceiveCarrierPayFormDto {
  balanceOwning: number | string;
  receivedDate: Date;
  transactionNumber: string;
  accountId: number;
  accountName: string;
  accountType?: AccountType | undefined;
  divisionId: number;
  divisionName: string;
}
export class ReceiveDriverPayFormDto {
  receivedDate: Date;
  accountId: number;
  accountName: string;
  accountType?: AccountType | undefined;
  divisionId: number;
  divisionName: string;
}
export class GenerateInvoiceFormDto {
  invoiceDate: Date;
  transactionNumber: string;
  accountId: number;
  accountName: string;
  accountType?: AccountType | undefined;
  divisionId: number;
  divisionName: string;
}

export interface ContactAddressSelectInputProps extends InputPublicProps {
  contactId: number;
  addressType: AddressType;
}
