import React from 'react';
import { TrackingEventDto } from '../../../models/data.models';
import { useStore } from 'effector-react';
import { OrderTrackingEventsGrid } from '../../common/components/grid/orderTrackingEvents-grid.component';
import { orderTrackingStore } from '../orderTrackings.store';

export type OrderTrackingEventsListProps = {
  data: TrackingEventDto[] | null | undefined;
};

export const OrderTrackingEventsList = ({
  data,
}: OrderTrackingEventsListProps) => {
  const { orderTrackingEventsColumns: columns } = useStore(orderTrackingStore);

  return (
    <OrderTrackingEventsGrid
      rowKeys={['orderTrackingEventId']}
      data={data}
      columns={columns}
      total={data?.length}
      className={'order-tracking-events-grid'}
    />
  );
};
