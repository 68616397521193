import {
  AttachmentDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateAttachmentCommandValues,
  UpdateAttachmentCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createAttachmentRequest,
  getAttachmentsListRequest,
  getAttachmentRequest,
  deleteAttachmentRequest,
  updateAttachmentRequest,
  GetAttachmentParams,
} from './attachments.service';

type AttachmentsStoreState = {
  links: LinkDto[];
  attachmentColumns: Column[];
};

export const updateAttachmentsColumns = createEvent<Column[]>();

export const getAttachments = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getAttachmentsListRequest(currentOrganization, params);
});

export const createAttachment = createEffect(({ attachmentData, file }) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createAttachmentCommand: CreateAttachmentCommandValues = {
    ...attachmentData,
  };

  return createAttachmentRequest(
    currentOrganization!,
    createAttachmentCommand,
    file,
  );
});

export const getAttachment = createEffect(
  (attachmentParams: GetAttachmentParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAttachmentRequest(
      currentOrganization as LinkResourceBaseDto,
      attachmentParams,
    );
  },
);

export const updateAttachment = createEffect((attachment: AttachmentDto) => {
  const updateAttachmentCommand: UpdateAttachmentCommandValues = {
    ...attachment,
  };
  return updateAttachmentRequest(attachment, updateAttachmentCommand);
});

export const deleteAttachment = createEffect((attachment: AttachmentDto) => {
  return deleteAttachmentRequest(attachment);
});

const defaultState: AttachmentsStoreState = {
  links: [],
  attachmentColumns: [
    { name: 'attachmentType', visible: true, title: 'Attachment Type' },
    { name: 'created', visible: true, title: 'Created' },
    { name: 'createdByUserName', visible: true, title: 'Created By' },
    { name: 'description', visible: true, title: 'Description' },
    { name: 'fileName', visible: true, title: 'File Name' },
    { name: 'fileUri', visible: true, title: 'File Uri' },
    { name: 'lastModified', visible: true, title: 'Last Modified' },
    {
      name: 'lastModifiedByUserName',
      visible: true,
      title: 'Last Modified By',
    },
    { name: 'organizationId', visible: true, title: 'Organization Id' },
    { name: 'parentId', visible: true, title: 'Parent Id' },
    { name: 'parentType', visible: true, title: 'Parent Type' },
    { name: 'previewUri', visible: true, title: 'Preview Uri' },
    { name: 'thumbnailUri', visible: true, title: 'Thumbnail Uri' },
  ],
};

export const attachmentStore: Store<AttachmentsStoreState> = createStore(
  defaultState,
).on(updateAttachmentsColumns, (state, payload) => {
  return { ...state, attachmentColumns: payload };
});
