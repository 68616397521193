import {
  ModeOfTransportationDto,
  LinkDto,
  LinkResourceBaseDto,
  ModeOfTransportationDtoPagedResult,
  CreateModeOfTransportationCommandValues,
  UpdateModeOfTransportationCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createModeOfTransportationRequest,
  getModeOfTransportationsListRequest,
  getModeOfTransportationRequest,
  deleteModeOfTransportationRequest,
  updateModeOfTransportationRequest,
  GetModeOfTransportationParams,
} from './modeOfTransportations.service';

type ModeOfTransportationsStoreState = {
  links: LinkDto[];
  modeOfTransportationColumns: Column[];
};

export const updateModeOfTransportationsColumns = createEvent<Column[]>();

export const getModeOfTransportations = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getModeOfTransportationsListRequest(currentOrganization, params);
  },
);

export const createModeOfTransportation = createEffect(
  (modeOfTransportationData: ModeOfTransportationDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createModeOfTransportationCommand: CreateModeOfTransportationCommandValues = {
      transportationMethod: {
        transportationMethod: modeOfTransportationData.transportationMethod,
        transportationMethodDescription:
          modeOfTransportationData.transportationMethodDescription,
      },
      description: modeOfTransportationData.description,
      usCustomsCode: modeOfTransportationData.usCustomsCode,
    };

    return createModeOfTransportationRequest(
      currentOrganization!,
      createModeOfTransportationCommand,
    );
  },
);

export const getModeOfTransportation = createEffect(
  (modeOfTransportationParams: GetModeOfTransportationParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getModeOfTransportationRequest(
      currentOrganization as LinkResourceBaseDto,
      modeOfTransportationParams,
    );
  },
);

export const updateModeOfTransportation = createEffect(
  (modeOfTransportation: ModeOfTransportationDto) => {
    const updateModeOfTransportationCommand: UpdateModeOfTransportationCommandValues = {
      transportationMethod: {
        transportationMethod: modeOfTransportation.transportationMethod,
        transportationMethodDescription:
          modeOfTransportation.transportationMethodDescription,
      },
      description: modeOfTransportation.description,
      usCustomsCode: modeOfTransportation.usCustomsCode,
    };
    return updateModeOfTransportationRequest(
      modeOfTransportation,
      updateModeOfTransportationCommand,
    );
  },
);

export const deleteModeOfTransportation = createEffect(
  (modeOfTransportation: ModeOfTransportationDto) => {
    return deleteModeOfTransportationRequest(modeOfTransportation);
  },
);

const defaultState: ModeOfTransportationsStoreState = {
  links: [],
  modeOfTransportationColumns: [
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'transportationMethod',
      visible: true,
      title: 'Method',
      sortName: 'transportationMethod',
    },
    {
      name: 'usCustomsCode',
      visible: true,
      title: 'Code',
      sortName: 'usCustomsCode',
    },
  ],
};

export const modeOfTransportationStore: Store<ModeOfTransportationsStoreState> = createStore(
  defaultState,
).on(updateModeOfTransportationsColumns, (state, payload) => {
  return { ...state, modeOfTransportationColumns: payload };
});
