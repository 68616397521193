import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { NOT_FOUND_PATH } from '../notFound/not-found.route';
import { TrackingScreen } from './screens/tracking.screen';

export const TRACKING_PATH = '/org/:organizationId/tracking';

export const TrackingRoute = (props: RouteProps) => {
  const isCorrectRoute: boolean =
    new RegExp(
      /^.+\/org{1}\/\d+\/tracking{1}\?orderSelector{1}\=[^\=\/\:\?&]*$/i,
    ).test(window.location.href) ||
    new RegExp(/^.+\/org{1}\/\d+\/tracking{1}\??$/i).test(window.location.href);

  return (
    <Route {...props}>
      {isCorrectRoute ? (
        <TrackingScreen pageTitle="Seahorse Tracking" />
      ) : (
        <Redirect to={NOT_FOUND_PATH} />
      )}
    </Route>
  );
};
