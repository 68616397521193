import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { QuoteScreen } from './screen/quote.screen';
import { QuotesListScreen } from './screen/quotes-list.screen';

export const QUOTE_LIST_PATH = '/quotes';
export const QUOTE_EDIT_PATH = '/quotes/:quoteId';
export const QUOTE_CREATE_PATH = '/quotes/0';
export const QUOTES_PATH = '/org/:organizationId/quotes';

export const QuoteRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${QUOTE_LIST_PATH}`}>
          <QuotesListScreen pageTitle="Quotes" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${QUOTE_CREATE_PATH}`}>
          <QuoteScreen pageTitle="Add New Quote" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${QUOTE_EDIT_PATH}`}>
          <QuoteScreen pageTitle="Update Quote" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
