import React, { useEffect, useRef, useState } from 'react';
import { showDialog } from '../../common/dialog.store';
import { ErrorMessage, Field } from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { ContactDto } from '../../../models/data.models';
import { CustomerDialog } from './customer.dialog';
import { CustomersList } from './customers-list.component';
import { CountriesList } from '../../countries/components/countries-list.component';

export type ContactSelectProps = {
  onSelect?: (contact: ContactDto) => void;
  required?: boolean;
  nameId: string;
  header: string;
  selectedName?: string | number;
  id: string;
  selectedFilter?: string;
  setFieldValue?: (key: string, value: string) => void;
};

export const CustomerSelect = ({
  id = '',
  nameId = '',
  header = '',
  selectedName = '',
  required = false,
  selectedFilter,
  onSelect = () => {},
}: ContactSelectProps) => {
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState('');
  const [search, setSearch] = useState('');
  const [name, setName] = useState(selectedName);
  const getFilter = (): string => {
    return selectedFilter;
  };
  const limit = 10;
  return (
    <FormContext.Consumer>
      {(context) => (
        <div className="form-group">
          <label className="input-label" htmlFor={nameId}>
            <span className="d-flex justify-content-between align-items-center">
              {header}
            </span>
          </label>
          <div className={`dropdown ${open ? 'show' : ''}`}>
            <div onClick={() => setOpen(!open)} className="input-group">
              <ErrorMessage
                name={id}
                component="div"
                className="invalid-feedback"
              />
              <Field required={required} type="hidden" name={id} />
              <input
                type="search"
                required={required}
                className="form-control"
                name={nameId}
                value={name}
                placeholder={'Select Customer'}
                readOnly={true}
              />
              <div className="input-group-append">
                <span className="input-group-text dropdown-toggle">&nbsp;</span>
              </div>
            </div>
            <div
              className={`dropdown-menu ${open ? 'show' : ''}`}
              aria-labelledby="dropdownMenuButton"
            >
              <h6 className="dropdown-header">Customer</h6>
              <div>
                <CustomersList
                  isDropDownList={true}
                  search={search}
                  onSearch={setSearch}
                  limit={limit}
                  onSort={setSort}
                  onPageChanged={(page) => setOffset(page * limit)}
                  goToDetails={(contactId) => {
                    if (contactId) {
                      showDialog(
                        {dialog: CustomerDialog, props: {
                          title: 'Update Customer',
                          contactId,
                          disableDots: true,
                        }},
                      ).then((result) => {
                        setOffset(0);
                      });
                    }
                  }}
                  sort={sort}
                  offset={offset}
                  filter={getFilter()}
                  onSelect={(contact, contactId) => {
                    if (context) {
                      context.setFieldValue(id, contact.contactId);
                    }
                    setOpen(false);
                    setName(contact.name || '');
                  }}
                />
              </div>
              <div className="dropdown-divider" />
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  showDialog(
                    {dialog: CustomerDialog, props: {
                      title: 'Create Customer',
                      contactId: 0,
                      disableDots: true,
                    }},
                  ).then((result) => {
                    setOffset(0);
                  });
                }}
              >
                Create New
              </button>
            </div>
          </div>
        </div>
      )}
    </FormContext.Consumer>
  );
};
