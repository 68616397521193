import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { ContactDto, OrderDto } from '../../../models/data.models';
import { useStore } from 'effector-react';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { DriverPayManagerForListDtoPagedResult } from '../../../models/custom.models';
import { GetOrderParams } from '../../orders/orders.service';
import { deleteOrder } from '../../orders/orders.store';
import { ORDER_CREATE_PATH } from '../../orders/order.route';
import { DriverPayManagerGrid } from '../../common/components/grid/driverPayManager-grid.component';
import {
  driverPayManagerStore,
  getDriverPays,
  updateDriverPayColumns,
} from '../driverPayManager.store';
import { OrderDialog } from '../../orders/components/order.dialog';
import { ReceiveDriverPayDialog } from './receiveDriverPay.dialog';
import { getContact } from '../../contacts/contacts.store';
import { parseSearchQuery } from '../../../utils/query.utils';
import { getFormattedDate } from '../../../utils/formatting.utils';

export type DriverPayManagerListProps = {
  goToDetails?: (orderParams: GetOrderParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (order: OrderDto, orderId: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const DriverPayManagerList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch = () => {},
}: DriverPayManagerListProps) => {
  const [
    driverPays,
    setDriverPays,
  ] = useState<DriverPayManagerForListDtoPagedResult | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedPays, setSelectedPays] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);

  useEffect(() => {
    getOrdersData();
  }, [offset, limit, sort, filter, search]);

  useEffect(() => {
    const query = parseSearchQuery(filter);
    if (query['carrierId'])
      getContact({ contactId: query['carrierId'] }).then(
        (driver) => {
          setSelectedDriver(driver);
        },
        () => {},
      );
  }, []);

  const getOrdersData = () => {
    setIsLoading(true);
    if (selectedDriver !== null && filter !== '') {
      getDriverPays({ offset, limit, sort, filter, search }).then(
        (ordersData) => {
          ordersData.items.forEach((item) => {
            item.shippedDate = item.shippedDate
              ? getFormattedDate(item.shippedDate, false)
              : null;
            item.deliveredDate = item.deliveredDate
              ? getFormattedDate(item.deliveredDate, false)
              : null;
            item.receivedDate = item.receivedDate
              ? getFormattedDate(item.receivedDate, false)
              : null;
          });
          const orderDtoResult: DriverPayManagerForListDtoPagedResult = {
            limit: ordersData.limit,
            offset: ordersData.offset,
            links: ordersData.links,
            totalCount: ordersData.totalCount,
            items: ordersData.items,
          };
          setDriverPays(orderDtoResult);
          setIsLoading(false);
        },
        () => {},
      );
    } else {
      setIsLoading(false);
      setSelectedDriver(null);
      setDriverPays({
        limit: 20,
        offset: 0,
        links: [],
        totalCount: 0,
        items: [],
      });
    }
  };
  const { orderDriverPayColumns: columns } = useStore(driverPayManagerStore);

  const onDeleteOrder = (order: OrderDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${order.orderId} Order`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteOrder(order).then(
            () => {
              onDelete();
              getOrdersData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditOrder = (order: OrderDto) => {
    goToDetails({ orderId: order.orderId });
  };
  const history = useHistory();
  const onCreateNewOrder = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onChangeItem = () => {
    getOrdersData();
  };

  const onDriverPayReceived = () => {
    getOrdersData();
  };
  const onDriverPaySelect = (driverPay, driverPayId) => {
    if (onSelect) onSelect(driverPay, driverPayId);
    showDialog({
      dialog: OrderDialog,
      props: {
        orderId: driverPay.orderId,
        title: 'Update Order',
        className: '',
      },
    }).then((driverPay) => {
      if (driverPay !== null) {
        getOrdersData();
      }
    });
  };

  const onSelectionChanged = (orderCarrierId, value) => {
    setSelectedPays((selectedPays) => {
      if (value) {
        selectedPays.push(orderCarrierId);
      } else {
        const index = selectedPays.indexOf(orderCarrierId);
        if (index > -1) {
          selectedPays.splice(index, 1);
        }
      }
      return [...selectedPays];
    });
  };

  const onReceiveDriverPay = () => {
    showDialog({
      dialog: ReceiveDriverPayDialog,
      props: {
        className: 'receive-driver-pay-modal',
        title: 'Generate Driver Bill',
        driverContactId: selectedDriver.contactId,
        orderIds: selectedPays,
        onDriverPayReceived: onDriverPayReceived,
      },
    }).then(
      (result) => {
        setSelectedPays([]);
      },
      () => {},
    );
  };

  return (
    <>
      <DriverPayManagerGrid
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={null}
        data={driverPays?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={driverPays?.totalCount}
        sort={sort}
        onDelete={onDeleteOrder}
        onSort={(field) => {
          onSort(field);
        }}
        search={search}
        onEdit={onEditOrder}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateDriverPayColumns}
        onSelect={onDriverPaySelect}
        onChangeItem={onChangeItem}
        filter={filter}
        onFilter={onFilter}
        onSearch={onSearch}
        onSelectionChanged={onSelectionChanged}
        selectedDriver={selectedDriver}
        setSelectedDriver={setSelectedDriver}
      />
      <div className={'px-3'}>
        <div className={'row pt-3'}>
          <div className={'ml-auto col-3'}>
            <Button
              disabled={selectedPays.length === 0}
              color="primary"
              className="btn-block ml-auto"
              type="button"
              onClick={onReceiveDriverPay}
            >
              Receive now
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
