import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { UserForm } from './user.form';
import { UserDto } from '../../../models/data.models';
import { createUser, getUser, updateUser } from '../users.store';
import { Panel } from '../../common/components/panel/panel.component';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormContext } from '../../common/components/form/form.component';
import { useHistory } from 'react-router-dom';
import { authStore, updateUserInfo, userHas } from '../../auth/auth.store';
import { UserDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { UPDATE_USER_LINK_KEY } from '../users.service';

export type UserEditProps = {
  userId?: string | null;
  onUserCreated?: (user: UserDto) => void;
  onUserUpdated?: (user: UserDto) => void;
  onUserLoaded?: (user: UserDto) => void;
  onCancel?: () => void;
};

const initialState: UserDto = {
  userId: '',
  email: UserDefaultValues.email,
  firstName: UserDefaultValues.firstName,
  lastName: UserDefaultValues.lastName,
  userName: UserDefaultValues.userName,
  links: [],
  isPassword: UserDefaultValues.isPassword,
  isConfirmPassword: UserDefaultValues.isConfirmPassword,
  confirmPassword: UserDefaultValues.confirmPassword,
};

const userSchema = Yup.object().shape({
  firstName: Yup.string().required("Can't be blank").nullable(true),
  lastName: Yup.string().required("Can't be blank").nullable(true),
  email: Yup.string()
    .email('Invalid email address')
    .required("Can't be blank")
    .nullable(true),
  isPassword: Yup.boolean(),
  isConfirmPassword: Yup.boolean(),
  password: Yup.string().when('isPassword', {
    is: true,
    then: Yup.string()
      .min(6, 'Min length is 6')
      .max(100, 'Max length is 100')
      .required("Can't be blank")
      .nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  confirmPassword: Yup.string().when('isConfirmPassword', {
    is: true,
    then: Yup.string()
      .equals([Yup.ref('password')], 'Passwords are not equal')
      .required("Can't be blank")
      .nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
});

export const UserEdit = ({
  userId,
  onUserLoaded = () => {},
  onUserCreated = () => {},
  onUserUpdated = () => {},
  onCancel = () => {},
}: UserEditProps) => {
  const isCreateMode = !userId || userId === '0';
  const currentUser = authStore.getState().user;
  const editingCurrentUser =
    currentUser?.userId && userId === currentUser?.userId;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserDto | null>(null);
  const history = useHistory();
  const [showChangePassword, setShowChangePassword] = useState(isCreateMode);

  useEffect(() => {
    if (isCreateMode) {
      initialState.isPassword = true;
      initialState.isConfirmPassword = true;
      setIsLoading(false);
    } else if (editingCurrentUser) {
      setUser(() => {
        return {
          ...currentUser,
          password: null,
          confirmPassword: null,
        };
      });
      setIsLoading(false);
      onUserLoaded(currentUser);
    } else if (userId) {
      getUser({ userId }).then(
        (userDto: UserDto) => {
          setUser(() => {
            return {
              ...userDto,
              password: null,
              confirmPassword: null,
            };
          });
          setIsLoading(false);
          onUserLoaded(userDto);
        },
        () => {},
      );
    } else {
      throw new Error('User keys were not provided');
    }
  }, [userId]);

  const onSubmit = (data: UserDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createUser(data)
        .then(
          (result) => {
            onUserCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else if (editingCurrentUser) {
      updateUserInfo(data)
        .then(
          (result) => {
            onUserUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateUser(data)
        .then(
          (result) => {
            onUserUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <div className={'user-edit-form'}>
      <UserForm
        initialValues={user || initialState}
        onSubmit={onSubmit}
        id="userForm"
        validationSchema={userSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <Tabs>
              <TabList>
                <Tab name="user">User</Tab>
              </TabList>
              <TabPanel>
                <Panel className="m-3">
                  <h3 className="font-weight-normal mb-4">
                    {isCreateMode ? 'Add User' : 'Edit User'}
                  </h3>
                  <div className="row mb-4">
                    <div className="col-5">
                      <div className="row mb-3">
                        <div className="col-6">
                          <UserForm.FirstName />
                        </div>
                        <div className="col-6">
                          <UserForm.LastName />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12">
                          <UserForm.UserName disabled={editingCurrentUser} />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12">
                          <UserForm.Email />
                        </div>
                      </div>
                      {showChangePassword ? (
                        <>
                          {' '}
                          <div className={'font-weight-bold mb-3 text-primary'}>
                            Set Password
                          </div>
                          <div className="row mb-4">
                            <div className="col-12">
                              <UserForm.Password
                                updatingCurrentPassword={!isCreateMode}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        (userHas(UPDATE_USER_LINK_KEY, user?.links) ||
                          editingCurrentUser) && (
                          <div className="row mb-5">
                            <div className="col-6">
                              <Button
                                name="change-password"
                                color="secondary"
                                className="btn-block"
                                type="button"
                                onClick={() => {
                                  context.setFieldValue('isPassword', true);
                                  context.setFieldValue(
                                    'isConfirmPassword',
                                    true,
                                  );
                                  setShowChangePassword(true);
                                }}
                              >
                                Change Password
                              </Button>
                            </div>
                          </div>
                        )
                      )}
                      {!editingCurrentUser ? (
                        <div className="row mb-4">
                          <div className="col-12">
                            <UserForm.Inactive />
                          </div>
                        </div>
                      ) : null}
                      <div className="justify-content-end row">
                        {(userHas(UPDATE_USER_LINK_KEY, user?.links) ||
                          isCreateMode ||
                          editingCurrentUser) && (
                          <div className="col-6">
                            <Button
                              name="save-user"
                              type="submit"
                              color="primary"
                              className="btn-block"
                              disabled={isSending}
                              isSending={isSending}
                            >
                              {isCreateMode ? 'Create User' : 'Update User'}
                            </Button>
                          </div>
                        )}
                        <div className="col-6">
                          <Button
                            name="cancel"
                            type="button"
                            color="secondary"
                            className="btn-block"
                            onClick={onCancel}
                            disabled={isSending}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </TabPanel>
            </Tabs>
          )}
        </FormContext.Consumer>
      </UserForm>
    </div>
  );
};
