import { FormikProps, FormikValues } from 'formik';
import React, { CSSProperties } from 'react';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { Column, OrderTrackingRoutesDto } from '../../../../models/data.models';

export type OrderTrackingRoutesGridProps = {
  data?: OrderTrackingRoutesDto[];
  columns?: Column[];
  sort?: string | null;
  className?: string;
  total?: number;
  style?: CSSProperties;
  rowKeys?: string[] | null;
  showPagination?: boolean;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  loading?: boolean;
  context?: FormikProps<FormikValues>;
  isCreateMode?: boolean;
};

export const OrderTrackingRoutesGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  total = 0,
  style = {},
  rowKeys = ['id'],
  showEmptyTable = true,
  showAllStore = false,
  loading,
  context,
  isCreateMode,
}: OrderTrackingRoutesGridProps) => {
  const colByName: { [key: string]: any } = {};

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  return (
    <div className={`grid ${className}`} style={style}>
      {total > 0 || showEmptyTable === true ? (
        <div className="bg-white mx-3">
          <table className="table" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                {isCreateMode ? (
                  <th scope="col" key={'select'} style={{ width: '20px' }} />
                ) : null}
                {columns
                  .filter((col) => {
                    if (showAllStore && col.visible) {
                      return true;
                    }
                    return col.visible && rowKeys?.includes(col.name);
                  })
                  .map((col) => {
                    return (
                      <th
                        scope="col"
                        key={col.name}
                        style={{
                          width:
                            col.name === 'type' || col.name === 'date'
                              ? '10%'
                              : col.name === 'address' || col.name === 'notes'
                              ? '30%'
                              : '20%',
                        }}
                      >
                        <a className={'inactive-link'}>
                          {col.title}
                          {sort === col.name || sort === col.sortName ? (
                            <FaSortAlphaDown />
                          ) : null}
                          {sort === '-' + col.name ||
                          sort === '-' + col.sortName ? (
                            <FaSortAlphaUp />
                          ) : null}
                        </a>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="m-5 text-center">
                  <h3 className="text-muted mb-4">Loading...</h3>
                </div>
              ) : (
                data.map((row, index) => {
                  return (
                    <tr
                      key={
                        rowKeys ? rowKeys?.map((x) => row[x]).join('_') : index
                      }
                    >
                      {isCreateMode ? (
                        <td>
                          {
                            <label
                              key={`chk${index}`}
                              className="pointer d-flex align-items-center justify-content-center"
                            >
                              <input
                                className={'pointer'}
                                type={'checkbox'}
                                id={row.type}
                                onChange={(value) => {
                                  if (value.target.checked) {
                                    context.values.selectedRows.push(row.type);
                                    context.setFieldValue(
                                      'selectedRows',
                                      context.values.selectedRows,
                                    );
                                  } else {
                                    context.values.selectedRows.splice(
                                      context.values.selectedRows.indexOf(
                                        row.type,
                                      ),
                                      1,
                                    );
                                    context.setFieldValue(
                                      'selectedRows',
                                      context.values.selectedRows,
                                    );
                                  }
                                }}
                              />
                            </label>
                          }
                        </td>
                      ) : null}
                      {Object.values(columns)
                        .filter((item) => {
                          if (showAllStore && item.visible) {
                            return true;
                          }
                          return item.visible && rowKeys?.includes(item.name);
                        })
                        .map((item, index) => {
                          return (
                            <td
                              key={`${rowKeys?.map((x) => row[x]).join('_')}_${
                                item.name
                              }`}
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              title={row[item.name]}
                            >
                              {typeof row[item.name] === 'boolean' ? (
                                row[item.name] ? (
                                  <>&#x2713;</>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <>{row[item.name]}</>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-4">Nothing Found</p>
      )}
    </div>
  );
};
