import { $enum } from 'ts-enum-util';
import {
  ContactWithAddressesDto,
  ReactSelectItem,
} from '../models/custom.models';
import {
  ContactAddressDto,
  ContactDto,
  CustomFieldDto,
  CustomFieldType,
  LinkDto,
  PaidAs,
} from '../models/data.models';
import { getContact } from '../modules/contacts/contacts.store';
import { addMessage, messagesStore } from '../modules/common/messages.store';
import { v4 } from 'uuid';
import { Dispatch, KeyboardEvent, SetStateAction } from 'react';
import * as Yup from 'yup';
import Geocode from 'react-geocode';
import {
  organizationsStore,
  setCurrentOrganization,
} from '../modules/organization/organization.store';
import { GET_ORGANIZATION_LINK_KEY } from '../modules/organization/organization.service';
import { FormikProps, FormikValues } from 'formik';

export const getEnumKeyByValue = (
  input: any | any[],
  enumName: object,
): any | any[] => {
  if (Array.isArray(input)) {
    return $enum(enumName)
      .map((value, key) => {
        return {
          key: key,
          value: value,
        };
      })
      .filter((item) => input.includes(item.value))
      .map((item) => item.key);
  } else {
    return $enum(enumName)
      .map((value, key) => {
        return {
          key: key,
          value: value,
        };
      })
      .filter((item) => input === item.value)[0]?.key;
  }
};

export const getEnumValueByValue = (
  input: any | any[],
  enumName: object,
): any | any[] => {
  if (Array.isArray(input)) {
    return $enum(enumName)
      .map((value, key) => {
        return {
          key: key,
          value: value,
        };
      })
      .filter((item) => input.includes(item.value))
      .map((item) => item.key);
  } else {
    const rt = $enum(enumName)
      .map((value, key) => {
        return {
          key: key,
          value: value,
        };
      })
      .filter((item) => input === item.key)[0];
    return rt?.value;
  }
};

export const countDecimals = (number: Number) => {
  if (Math.floor(number.valueOf()) === number.valueOf()) return 0;

  var str = number.toString();
  if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0;
  }
  return 0;
};

export const getEnumValues = (enumObject: any): ReactSelectItem[] => {
  return $enum(enumObject).map((value, key) => {
    return {
      value: key,
      label: value,
    };
  });
};

export const getPaidAs = async (contactId: number): Promise<PaidAs> => {
  return await getContact({
    contactId: Number(contactId) || null,
  }).then((contactData: ContactDto) => {
    return contactData.paidAs;
  });
};

export const formatAddress = (address: ContactAddressDto): string => {
  if (!address) return '';

  const addressLine1 = address.addressLine || '';
  const addressLine2 = address.addressLine2 || '';
  const city = address.city || '';
  const state = address.stateCode || '';
  const postalCode = address.postalCode || '';

  const cityStatePostal = [
    `${city}${city && state ? ',' : ''}`,
    state,
    postalCode,
  ]
    .filter(Boolean)
    .join(' ');

  return [addressLine1, addressLine2, cityStatePostal]
    .filter(Boolean)
    .join(', ');
};

export const createErrorMessage = (inputName: string, errorMessage: string) => {
  const message = `${inputName}: ${errorMessage}`;
  const isThereAlreadySuchError = messagesStore
    .getState()
    .messages.some((messageObject) => messageObject.message === message);
  !isThereAlreadySuchError && addMessage({ id: v4(), message, type: 'danger' });
};

export const findInputName = (
  formId: string,
  labelFor: string,
  tabName?: string,
): string => {
  let formTabName = '';
  if (tabName) {
    formTabName = `${
      (document.querySelector(`li[name="${tabName}"]`) as HTMLElement)
        ?.innerText
    } - `;
  }
  const inputName = (document.querySelector(
    `#${formId} label[for="${labelFor}"] .input-label-primary`,
  ) as HTMLElement)?.innerText;
  return `${formTabName}${inputName}`;
};

export const validateNumberInput = (e: KeyboardEvent) => {
  if (
    (e.key < '0' || e.key > '9') &&
    e.key !== '-' &&
    e.key !== '.' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Delete' &&
    e.key !== 'Backspace' &&
    e.key !== 'Shift' &&
    e.key !== 'Control' &&
    e.key !== 'Tab' &&
    e.key !== 'End' &&
    e.key !== 'Home' &&
    ((e.key !== 'a' && e.key !== 'c' && e.key !== 'x' && e.key !== 'z') ||
      !e.getModifierState('Control')) &&
    ((e.key !== 'Home' &&
      e.key !== 'End' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight') ||
      !e.getModifierState('Shift'))
  ) {
    e.preventDefault();
  }
};

export const validatePositiveNumberInput = (e: KeyboardEvent) => {
  if (
    (e.key < '0' || e.key > '9') &&
    e.key !== '.' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Delete' &&
    e.key !== 'Backspace' &&
    e.key !== 'Shift' &&
    e.key !== 'Control' &&
    e.key !== 'Tab' &&
    e.key !== 'End' &&
    e.key !== 'Home' &&
    ((e.key !== 'a' && e.key !== 'c' && e.key !== 'x' && e.key !== 'z') ||
      !e.getModifierState('Control')) &&
    ((e.key !== 'Home' &&
      e.key !== 'End' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight') ||
      !e.getModifierState('Shift'))
  ) {
    e.preventDefault();
  }
};

export const splitByCapitals = (str?: string): string => {
  return str ? str.split(/(?=[A-Z])/).join(' ') : '';
};

export const escapeString = (str?: string, columnType?: string): string => {
  var luceneEscapes = [
    '\\',
    '+',
    '-',
    '&&',
    '||',
    '!',
    '(',
    ')',
    '{',
    '}',
    '[',
    ']',
    '^',
    '"',
    '~',
    '*',
    '?',
    ':',
    ' ',
  ];
  luceneEscapes.forEach((symbol) => {
    if (symbol === '\\' || symbol === '"') {
      if (columnType === 'customField') {
        str = str.replaceAll(symbol, `\\\\\\${symbol}`);
      } else {
        str = str.replaceAll(symbol, `\\${symbol}`);
      }
    } else if (symbol === '+') {
      str = str.replaceAll(symbol, `\\%2b`);
    } else {
      str = str.replaceAll(symbol, `\\${symbol}`);
    }
  });
  return str;
};

export const unescapeString = (
  str?: string,
  isCustomField?: boolean,
): string => {
  var luceneEscapes = [
    '\\\\\\\\',
    '\\\\\\"',
    '\\\\',
    '\\"',
    '\\ ',
    '\\+',
    '\\-',
    '\\&&',
    '\\||',
    '\\!',
    '\\(',
    '\\)',
    '\\{',
    '\\}',
    '\\[',
    '\\]',
    '\\^',
    '\\~',
    '\\*',
    '\\?',
    '\\:',
  ];
  luceneEscapes.forEach((symbol) => {
    if ((symbol === '\\\\\\\\' || symbol === '\\\\\\"') && isCustomField) {
      str = str.replaceAll(symbol, symbol.slice(3));
    } else if ((symbol === '\\\\' || symbol === '\\"') && !isCustomField) {
      str = str.replaceAll(symbol, symbol.slice(1));
    } else {
      str = str.replaceAll(symbol, symbol.slice(1));
    }
  });
  return str;
};

export const generateValidationSchemaWithCustomFields = (
  customFields: CustomFieldDto[],
  schema: any,
) => {
  customFields?.forEach((customField) => {
    if (customField.customFieldType === CustomFieldType.Number) {
      const customFieldSchema = Yup.object().shape({
        customValues: Yup.object().shape({
          [`${customField.internalName}`]: Yup.string()
            .transform((value) => (value === null ? '' : value))
            .test('numberFormat', 'Incorrect number format', (value) => {
              return (
                (new RegExp(
                  /^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm,
                ).test(value?.toString()) &&
                  Number(value) < Number.MAX_SAFE_INTEGER &&
                  Number(value) > Number.MIN_SAFE_INTEGER) ||
                value === undefined ||
                value === ''
              );
            })
            .test('length', 'Max number length is 17', (value) => {
              return (
                value === undefined || value?.replace('-', '').length <= 17
              );
            })
            .nullable(true),
        }),
      });
      schema = schema.concat(customFieldSchema);
    }
  });
  return schema;
};

export const getLocationName = async (
  lat: number,
  lng: number,
): Promise<string> => {
  let locationName: string = 'Unknown location';
  Geocode.setApiKey('AIzaSyDxYtyuWkst0uDfs3EnzfFJ6On1rRDivIY');
  Geocode.setLanguage('en');
  Geocode.setLocationType('ROOFTOP');
  return Geocode.fromLatLng(lat, lng)
    .then((response: any) => {
      if (response.results.length > 1) {
        response.results.map((result: any) => {
          if (result.types.includes('premise')) {
            locationName = result.formatted_address as string;
          }
        });
        return locationName;
      } else {
        return response.results[0].formatted_address as string;
      }
    })
    .catch(() => {
      Geocode.setLocationType('GEOMETRIC_CENTER');
      return Geocode.fromLatLng(lat, lng)
        .then((response: any) => {
          if (response.results.length > 1) {
            const plusCodeAddress = response.results.filter((result: any) => {
              return result.types.includes('plus_code');
            });
            const routeAddress = response.results.filter((result: any) => {
              return result.types.includes('route');
            });

            if (plusCodeAddress[0].address_components.length > 3) {
              locationName = plusCodeAddress[0].formatted_address as string;
            } else {
              locationName = routeAddress[0].formatted_address as string;
            }
            return locationName;
          } else {
            return response.results[0].formatted_address as string;
          }
        })
        .catch(() => {
          Geocode.setLocationType('APPROXIMATE');
          return Geocode.fromLatLng(lat, lng).then((response: any) => {
            return response.results[0].formatted_address as string;
          });
        });
    });
};

export const parseGeocodeResults = (result: any) => {
  const types = {
    home: ['street_number'],
    postal_code: ['postal_code'],
    street: [
      'street_address',
      'route',
      'intersection',
      'airport',
      'park',
      'plus_code',
    ],
    place: [
      'premise',
      'subpremise',
      'point_of_interest',
      'tourist_attraction',
      'natural_feature',
      'colloquial_area',
    ],
    state: ['administrative_area_level_1'],
    region: [
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
      'administrative_area_level_6',
      'administrative_area_level_7',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
      'sublocality_level_5',
      'postal_town',
      'political',
      'neighborhood',
    ],
    country: ['country'],
  };

  const address = {
    home: [],
    place: [],
    postal_code: [],
    street: [],
    stateCode: [],
    stateName: [],
    region: [],
    city: [],
    countryCode: [],
    countryName: [],
  };

  result.address_components.forEach((component) => {
    for (const type in types) {
      if (types[type].includes(component.types[0])) {
        if (type === 'country' || type === 'state') {
          address[`${type}Code`].push(component.short_name);
          address[`${type}Name`].push(component.long_name);
        } else {
          address[type].push(component.long_name);
        }
      }
    }
  });
  const addressLine =
    address.home.length > 0 && address.street.length > 0
      ? `${address.home} ${address.street.join(', ')}`
      : result.suggestion.formattedSuggestion.mainText;

  let addressLine2 = address.place.join(', ');
  if (addressLine2.length > 0) {
    addressLine2 += ', ';
  }
  if (result.suggestion) {
    addressLine2 += result.suggestion.types.some((suggestionType) =>
      types.place.includes(suggestionType),
    )
      ? result.suggestion.formattedSuggestion.mainText.toString()
      : '';
  }
  if (addressLine2.endsWith(', ')) {
    addressLine2 = addressLine2.replace(', ', '');
  }
  if (addressLine === addressLine2) {
    addressLine2 = '';
  }
  const city = address.city.join(', ');
  const postalCode = address.postal_code.toString();
  const stateCode = address.stateCode.toString();
  const stateName = address.stateName.toString();
  const countryCode = address.countryCode.toString();
  const countryName = address.countryName.toString();
  const latitude = result.geometry.location.lat();
  const longitude = result.geometry.location.lng();

  return {
    addressLine,
    addressLine2,
    city,
    postalCode,
    stateCode,
    stateName,
    countryCode,
    countryName,
    latitude,
    longitude,
  };
};

export const b64DecodeUnicode = (str) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
};

export const getRedirectOrganization = (organizationId: string) => {
  const { currentOrganization } = organizationsStore.getState();
  const getOrganizationLink = currentOrganization.links?.find(
    (x: LinkDto) => x.rel === GET_ORGANIZATION_LINK_KEY,
  );
  const getOrganizationUrl = new URL(getOrganizationLink.href);
  const pathNameParts = getOrganizationUrl.pathname.split('/');
  pathNameParts[pathNameParts.length - 1] = organizationId;
  const newGetOrganizationLink = `${
    getOrganizationUrl.origin
  }${pathNameParts.join('/')}`;
  getOrganizationLink.href = newGetOrganizationLink;
  currentOrganization.links?.map((x: LinkDto) => {
    if (x.rel === GET_ORGANIZATION_LINK_KEY) {
      x = getOrganizationLink;
    }
  });
  setCurrentOrganization(currentOrganization);
};

export const getPlaceDetails = (
  placesService: google.maps.places.PlacesService,
  placeId: string,
): Promise<google.maps.places.PlaceResult> => {
  return new Promise((resolve, reject) => {
    const placeDetailsRequest = {
      placeId,
      fields: [
        'address_component',
        'formatted_phone_number',
        'website',
        'geometry',
        'name',
      ],
      language: 'en',
    };

    placesService.getDetails(
      placeDetailsRequest,
      (
        result: google.maps.places.PlaceResult,
        status: google.maps.places.PlacesServiceStatus,
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        }
      },
    );
  });
};

export const getFindPlaceById = async (
  placeId: string,
  placesService: google.maps.places.PlacesService,
  setShippingFilterState: Dispatch<SetStateAction<string>>,
  setContact: Dispatch<SetStateAction<ContactWithAddressesDto>>,
  initialValues: ContactWithAddressesDto,
  contactAddressInitialState: ContactAddressDto,
  context: FormikProps<FormikValues>,
): Promise<string> => {
  const placeDetails = await getPlaceDetails(placesService, placeId);
  const addressInfo = parseGeocodeResults(placeDetails);

  context.setFieldValue('shippingAddress.addressLine', addressInfo.addressLine);
  context.setFieldValue(
    'shippingAddress.addressLine2',
    addressInfo.addressLine2,
  );
  context.setFieldValue('shippingAddress.city', addressInfo.city);
  context.setFieldValue('shippingAddress.postalCode', addressInfo.postalCode);
  context.setFieldValue('shippingAddress.countryCode', addressInfo.countryCode);
  context.setFieldValue('shippingAddress.stateCode', addressInfo.stateCode);
  context.setFieldValue('shippingAddress.latitude', addressInfo.latitude);
  context.setFieldValue('shippingAddress.longitude', addressInfo.longitude);
  setShippingFilterState(`countryCode:${addressInfo.countryCode}`);

  context.setFieldValue('name', placeDetails.name);
  context.setFieldValue('phoneNumber', placeDetails.formatted_phone_number);
  context.setFieldValue('website', placeDetails.website);

  const autocompleteInput = document.querySelector(
    '#shippingAddress\\.addressLine',
  ) as HTMLInputElement;

  Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value',
  ).set.call(autocompleteInput, addressInfo.addressLine);
  autocompleteInput.dispatchEvent(new Event('change', { bubbles: true }));
  setContact((oldContact) => {
    if (!oldContact) {
      oldContact = initialValues;
    }
    if (!oldContact.shippingAddress) {
      oldContact.shippingAddress = { ...contactAddressInitialState };
    }
    oldContact.name = placeDetails.name;
    oldContact.phoneNumber = placeDetails.formatted_phone_number;
    oldContact.website = placeDetails.website;
    oldContact.shippingAddress.addressLine = addressInfo.addressLine;
    oldContact.shippingAddress.addressLine2 = addressInfo.addressLine2;
    oldContact.shippingAddress.city = addressInfo.city;
    oldContact.shippingAddress.postalCode = addressInfo.postalCode;
    oldContact.shippingAddress.countryCode = addressInfo.countryCode;
    oldContact.shippingAddress.countryName = addressInfo.countryName;
    oldContact.shippingAddress.stateCode = addressInfo.stateCode;
    oldContact.shippingAddress.stateName = addressInfo.stateName;
    oldContact.shippingAddress.latitude = addressInfo.latitude;
    oldContact.shippingAddress.longitude = addressInfo.longitude;
    return { ...oldContact };
  });

  return placeDetails.name;
};

export const activateAutocompleteEvent = async (
  selector: string,
  value: string,
) => {
  const autocompleteInput = document.querySelector(
    selector,
  ) as HTMLInputElement;
  Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value',
  ).set.call(autocompleteInput, value);
  autocompleteInput.dispatchEvent(new Event('change', { bubbles: true }));
};
