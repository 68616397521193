import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PackageCategoryScreen } from './screen/packageCategory.screen';
import { PackageCategoriesListScreen } from './screen/packageCategories-list.screen';

export const PACKAGE_CATEGORY_LIST_PATH = '/package-categories';
export const PACKAGE_CATEGORY_EDIT_PATH =
  '/package-categories/:packageCategoryCode';
export const PACKAGE_CATEGORY_CREATE_PATH = '/package-categories/0';
export const PACKAGE_CATEGORIES_PATH =
  '/org/:organizationId/package-categories';

export const PackageCategoryRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${PACKAGE_CATEGORY_LIST_PATH}`}>
          <PackageCategoriesListScreen pageTitle="Package Categories" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${PACKAGE_CATEGORY_CREATE_PATH}`}
        >
          <PackageCategoryScreen pageTitle="Add New Package Category" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${PACKAGE_CATEGORY_EDIT_PATH}`}>
          <PackageCategoryScreen pageTitle="Update Package Category" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
