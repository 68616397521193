import React from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';
import { CreateOrganizationScreen } from './screens/create-organization.screen';

export const ORGANIZATION_LIST_PATH = '/organizations';
export const ORGANIZATION_EDIT_PATH = '/organization';
export const ORGANIZATION_CREATE_PATH = '/organizations/0';
export const OUTER_ORGANIZATION_CREATE_PATH = '/createOrganization';

export const OrganizationRoute = (props: RouteProps) => {
  return (
    <Route {...props}>
      <CreateOrganizationScreen pageTitle="Create Organization - Seahorse Express" />
    </Route>
  );
};
