import React from 'react';
import { Login } from '../components/login.component';
import { Panel } from '../../common/components/panel/panel.component';
import {
  login,
  getUserInfo,
  authStore,
  getUserAdditionalData,
} from '../auth.store';
import {
  getOrganizations,
  organizationsStore,
} from '../../organization/organization.store';
import { Redirect, useHistory } from 'react-router-dom';
import { useStore } from 'effector-react';
import { OUTER_ORGANIZATION_CREATE_PATH } from '../../organization/organization.route';
import { Helmet } from 'react-helmet';
import { getCurrencies } from '../../currencies/currencies.store';

export type LoginScreenProps = { pageTitle: string };

export const LoginScreen = ({ pageTitle }: LoginScreenProps) => {
  const history = useHistory();
  const { user } = authStore.getState();
  const { currentOrganization } = useStore(organizationsStore);
  const onLogin = (loginParams) => {
    login(loginParams).then(() => {
      return getUserInfo().then(() => {
        return getOrganizations({ sort: 'organizationId' }).then(
          async (result) => {
            if (result.items.length) {
              getUserAdditionalData();
              await getCurrencies();
              history.push(`/org/${result.items[0].organizationId}/`);
            } else {
              history.push(OUTER_ORGANIZATION_CREATE_PATH);
            }
          },
        );
      });
    });
  };
  if (user && currentOrganization) {
    return <Redirect to={`/org/${currentOrganization.organizationId}/`} />;
  }
  return (
    <div className="py-5">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="d-flex justify-content-center">
        <Panel style={{ maxWidth: '500px', width: '100%' }}>
          <h1 className="text-center login-register-header font-weight-light">
            Sign in
          </h1>
          <Login onLogin={onLogin} />
        </Panel>
      </div>
    </div>
  );
};
