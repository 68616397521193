import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  PackageCategoryDto,
  PackageCategoryDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  packageCategoryStore,
  getPackageCategories,
  deletePackageCategory,
  updatePackageCategoriesColumns,
} from '../packageCategories.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_PACKAGECATEGORY_LINK_KEY,
  DELETE_PACKAGECATEGORY_LINK_KEY,
  GetPackageCategoryParams,
} from '../packageCategories.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PACKAGE_CATEGORY_CREATE_PATH } from '../packageCategories.route';
import { PackageCategoryDialog } from './packageCategory.dialog';
import { userHas } from '../../auth/auth.store';

export type PackageCategoriesListProps = {
  goToDetails?: (packageCategoryParams: GetPackageCategoryParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    packageCategory: PackageCategoryDto,
    packageCategoryParams: GetPackageCategoryParams,
  ) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const PackageCategoriesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: PackageCategoriesListProps) => {
  const [
    packageCategories,
    setPackageCategories,
  ] = useState<PackageCategoryDtoPagedResult | null>(null);
  const getPackageCategoriesData = () => {
    getPackageCategories({ offset, limit, sort, filter, search }).then(
      (packageCategoriesData) => {
        setPackageCategories(packageCategoriesData);
        setLoading(false);
      },
    );
  };
  useEffect(() => {
    getPackageCategoriesData();
  }, [offset, limit, sort, filter, search]);
  const [loading, setLoading] = useState(true);
  const { packageCategoryColumns: columns } = useStore(packageCategoryStore);

  const onDeletePackageCategory = (packageCategory: PackageCategoryDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${packageCategory.packageCategoryCode} Package Category`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deletePackageCategory(packageCategory).then(() => {
          onDelete();
          getPackageCategoriesData();
        });
      }
    });
  };

  const onEditPackageCategory = (packageCategory: PackageCategoryDto) => {
    goToDetails({ packageCategoryCode: packageCategory.packageCategoryCode });
  };
  const history = useHistory();
  const onCreateNewPackageCategory = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(
      INTERNAL_PATH + PACKAGE_CATEGORY_CREATE_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    history.push(createPath);
  };
  const onPackageCategorySelect = (packageCategory, packageCategoryId) => {
    if (onSelect) onSelect(packageCategory, packageCategoryId);
    showDialog({
      dialog: PackageCategoryDialog,
      props: {
        packageCategoryCode: packageCategory.packageCategoryCode,
        title: 'Update Package Category',
      },
    }).then((packageCategory) => {
      if (packageCategory !== null) {
        getPackageCategoriesData();
      }
    });
  };
  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          isDropDownList={isDropDownList}
          search={search}
          showEmptyTable={true}
          onSearch={onSearch}
          showAllStore={true}
          rowKeys={['packageCategoryCode']}
          data={packageCategories?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={packageCategories?.totalCount}
          sort={sort}
          onDelete={
            userHas(
              DELETE_PACKAGECATEGORY_LINK_KEY,
              packageCategories?.items[0]?.links,
            )
              ? onDeletePackageCategory
              : null
          }
          onSort={onSort}
          onEdit={onEditPackageCategory}
          onPageChanged={onPageChanged}
          onColumnsChanged={updatePackageCategoriesColumns}
          onSelect={onPackageCategorySelect}
          filter={filter}
          onFilter={onFilter}
        />
        {!(
          (packageCategories &&
            packageCategories.items &&
            packageCategories.items.length) ||
          filter ||
          search
        ) && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">
              You Don't Have Any Package Category Yet
            </h3>
            {userHas(CREATE_PACKAGECATEGORY_LINK_KEY) && (
              <Button
                size={'lg'}
                color="primary"
                onClick={onCreateNewPackageCategory}
              >
                Create Package Category
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
};
