import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { QuickBooksForm } from './quickBooks.form';
import {
  OrganizationConfigDto,
  OrganizationConfigDtoPagedResult,
} from '../../../models/data.models';
import { Panel } from '../../common/components/panel/panel.component';
import {
  createOrganizationConfig,
  getOrganizationConfigs,
} from '../../organizationConfigs/organizationConfigs.store';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getQuickBooksTokens, initQuickBooks } from '../quickBooks.service';

export type OrganizationConfigEditProps = {
  organizationId?: number | null;
  organizationConfigName?: string;
  onQuickBooksCreated?: () => void;
  onQuickBooksUpdated?: () => void;
};

const initialState: OrganizationConfigDto = {
  organizationId: 0,
  configName: 'QuickBooks',
  customValues: {},
};

export const QuickBooksEdit = ({
  organizationConfigName,
  organizationId,
  onQuickBooksCreated = () => {},
  onQuickBooksUpdated = () => {},
}: OrganizationConfigEditProps) => {
  const quickBooksData = new URLSearchParams(window.location.search);
  const code = quickBooksData.get('code');
  const realmId = quickBooksData.get('realmId');
  const dataExists = code !== null && realmId !== null;

  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateMode, setIsCreateMode] = useState(null);
  const [quickBooks, setQuickBooks] = useState<OrganizationConfigDto | null>(
    null,
  );

  useEffect(() => {
    if (dataExists) {
      setIsSending(true);
      getQuickBooksTokens(code, realmId)
        .then(
          (quickBooksTokensDto) => {
            if (
              quickBooksTokensDto.accessToken &&
              quickBooksTokensDto.refreshToken
            ) {
              initialState.organizationId = organizationId;
              initialState.customValues = {
                code: code,
                realmId: realmId,
                ...quickBooksTokensDto,
                postOnTxnCreate: true,
                postOnTxnUpdate: true,
              };
              createOrganizationConfig(initialState).then(() => {
                onQuickBooksCreated();
                getQuickBooksData();
              });
            }
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  }, [dataExists]);

  useEffect(() => {
    getQuickBooksData();
  }, [organizationConfigName]);

  const getQuickBooksData = () => {
    getOrganizationConfigs({
      limit: 1,
      offset: 0,
      sort: '',
      filter: `configName:${organizationConfigName} AND organizationId:${organizationId}`,
      search: '',
    }).then(
      (quickBooksDtoResult: OrganizationConfigDtoPagedResult) => {
        if (quickBooksDtoResult.items.length === 0) {
          setIsCreateMode(true);
          initialState.organizationId = organizationId;
          setQuickBooks(initialState);
        } else {
          setIsCreateMode(false);
          const quickBooksDto = quickBooksDtoResult.items[0];
          quickBooksDto.postOnTxnCreate =
            quickBooksDto.customValues['postOnTxnCreate'] === 'true';
          quickBooksDto.postOnTxnUpdate =
            quickBooksDto.customValues['postOnTxnUpdate'] === 'true';
          setQuickBooks(quickBooksDto);
        }
        setIsLoading(false);
      },
      () => {},
    );
  };

  const onSubmit = (data: OrganizationConfigDto) => {
    setIsSending(true);
    initQuickBooks()
      .then(
        (authUrl) => {
          localStorage.setItem('referer_org', organizationId.toString());
          window.location.assign(authUrl);
        },
        () => {},
      )
      .catch(() => setIsSending(false));
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const onChangePostOnTxnCreate = (value) => {
    const data = quickBooks;
    data.customValues['postOnTxnCreate'] = value;
    createOrganizationConfig(data).then(() => {
      onQuickBooksUpdated();
      getQuickBooksData();
    });
  };

  const onChangePostOnTxnUpdate = (value) => {
    const data = quickBooks;
    data.customValues['postOnTxnUpdate'] = value;
    createOrganizationConfig(data).then(() => {
      onQuickBooksUpdated();
      getQuickBooksData();
    });
  };

  return (
    <Tabs>
      <TabList>
        <Tab name="quickBooks">QuickBooks</Tab>
      </TabList>
      <QuickBooksForm
        initialValues={quickBooks}
        onSubmit={onSubmit}
        id={'QuickBooks-edit-form'}
      >
        <TabPanel>
          <Panel className="m-3">
            <h3 className="header-form">QuickBooks Config</h3>
            <div className="row">
              <div className="col-3">
                <Button
                  name="update-QuickBooks"
                  type="submit"
                  color="primary"
                  className="btn-block"
                  form={'QuickBooks-edit-form'}
                  disabled={isSending}
                  isSending={isSending}
                >
                  {isCreateMode ? 'Connect QuickBooks' : 'Reconnect QuickBooks'}
                </Button>
              </div>
            </div>
            {!isCreateMode && (
              <>
                <hr />
                <div className="row">
                  <div className="d-flex align-items-center p-3">
                    <QuickBooksForm.PostToQB
                      name={'postOnTxnCreate'}
                      id={'postOnTxnCreateCheckbox'}
                      label={'Post on transaction create'}
                      onChange={onChangePostOnTxnCreate}
                    />
                  </div>
                  <div className="d-flex align-items-center p-3">
                    <QuickBooksForm.PostToQB
                      name={'postOnTxnUpdate'}
                      id={'postOnTxnUpdateCheckbox'}
                      label={'Post on transaction update'}
                      onChange={onChangePostOnTxnUpdate}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex flex-column align-items-start px-3 pb-3 pt-0">
                    <p style={{ overflowWrap: 'anywhere' }}>
                      <b>Authorization Code: </b>
                      {quickBooks.customValues['code']}
                    </p>
                    <p style={{ overflowWrap: 'anywhere' }}>
                      <b>QuickBooks Company ID: </b>
                      {quickBooks.customValues['realmId']}
                    </p>
                    <p style={{ overflowWrap: 'anywhere' }}>
                      <b>Access Token: </b>
                      {quickBooks.customValues['accessToken']}
                    </p>
                    <p style={{ overflowWrap: 'anywhere' }}>
                      <b>Access Token expires at: </b>
                      {quickBooks.customValues['accessTokenExpiresAt']}
                    </p>
                    <p style={{ overflowWrap: 'anywhere' }}>
                      <b>Refresh Token: </b>
                      {quickBooks.customValues['refreshToken']}
                    </p>
                    <p style={{ overflowWrap: 'anywhere' }}>
                      <b>Refresh Token expires at: </b>
                      {quickBooks.customValues['refreshTokenExpiresAt']}
                    </p>
                  </div>
                </div>
              </>
            )}
          </Panel>
        </TabPanel>
      </QuickBooksForm>
    </Tabs>
  );
};
