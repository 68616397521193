import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  PackageTypeDto,
  PackageTypeDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  packageTypeStore,
  getPackageTypes,
  deletePackageType,
  updatePackageTypesColumns,
} from '../packageTypes.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_PACKAGETYPE_LINK_KEY,
  DELETE_PACKAGETYPE_LINK_KEY,
  GetPackageTypeParams,
} from '../packageTypes.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PACKAGETYPE_CREATE_PATH } from '../packageType.route';
import { PackageTypeDialog } from './packageType.dialog';
import { userHas } from '../../auth/auth.store';

export type PackageTypesListProps = {
  goToDetails?: (packageTypeParams: GetPackageTypeParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    packageType: PackageTypeDto,
    packageTypeParams: GetPackageTypeParams,
  ) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const PackageTypesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  showAllStore = true,
  rowKeys = ['packageTypeId'],
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: PackageTypesListProps) => {
  const [
    packageTypes,
    setPackageTypes,
  ] = useState<PackageTypeDtoPagedResult | null>(null);

  useEffect(() => {
    getPackageTypesData();
  }, [offset, limit, sort, filter, search]);
  const getPackageTypesData = () => {
    getPackageTypes({ offset, limit, sort, filter, search }).then(
      (packageTypesData) => {
        setPackageTypes(packageTypesData);
        setLoading(false);
      },
      () => {},
    );
  };
  const [loading, setLoading] = useState(true);
  const { packageTypeColumns: columns } = useStore(packageTypeStore);

  const onDeletePackageType = (packageType: PackageTypeDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${packageType.packageTypeId} Package Type`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deletePackageType(packageType).then(
            () => {
              onDelete();
              getPackageTypesData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };
  const history = useHistory();
  const onEditPackageType = (packageType: PackageTypeDto) => {
    goToDetails({ packageTypeId: packageType.packageTypeId });
  };

  const onCreateNewPackageType = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + PACKAGETYPE_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onPackageTypeSelect = (packageType, packageTypeId) => {
    if (onSelect) onSelect(packageType, packageTypeId);
    showDialog({
      dialog: PackageTypeDialog,
      props: {
        packageTypeId: packageType.packageTypeId,
        title: 'Update Package Type',
      },
    }).then((packageType) => {
      if (packageType !== null) {
        getPackageTypesData();
      }
    });
  };
  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          isDropDownList={isDropDownList}
          search={search}
          onSearch={onSearch}
          showEmptyTable={true}
          showAllStore={showAllStore}
          rowKeys={rowKeys}
          data={packageTypes?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={packageTypes?.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_PACKAGETYPE_LINK_KEY, packageTypes?.items[0]?.links)
              ? onDeletePackageType
              : null
          }
          onSort={onSort}
          onEdit={onEditPackageType}
          onPageChanged={onPageChanged}
          onColumnsChanged={updatePackageTypesColumns}
          onSelect={onPackageTypeSelect}
          filter={filter}
          onFilter={onFilter}
        />
        {!(
          (packageTypes && packageTypes.items && packageTypes.items.length) ||
          filter ||
          search
        ) && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">
              You Don't Have Any Package Type Yet
            </h3>
            {userHas(CREATE_PACKAGETYPE_LINK_KEY) && (
              <Button
                size={'lg'}
                color="primary"
                onClick={onCreateNewPackageType}
              >
                Create Package Type
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
};
