import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CarrierListScreen } from './screens/carrier-list.screen';
import { CarrierScreen } from './screens/carrier.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_CARRIERS } from '../contacts/contact.route';

export const CARRIERS_LIST_PATH = '/carriers';
export const CARRIERS_EDIT_PATH = '/carriers/:contactId';
export const CARRIERS_CREATE_PATH = '/carriers/0';
export const CARRIERS_PATH = '/org/:organizationId/carriers';

export const CarriersRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${CARRIERS_LIST_PATH}`}>
          <CarrierListScreen pageTitle="Carriers" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CARRIERS_CREATE_PATH}`}>
          <CarrierScreen pageTitle="Add New Carrier" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CARRIERS_EDIT_PATH}`}>
          <CarrierScreen pageTitle="Update Carrier" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${CONTACTS_TAB_EDIT_PATH_FOR_CARRIERS}`}
        >
          <ContactScreen pageTitle="Contacts" isTab={true} />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
