import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { NOT_FOUND_PATH } from '../notFound/not-found.route';
import { OrderTrackingScreen } from './screens/orderTracking.screen';

export const ORDERTRACKING_PATH = '/org/:organizationId/orderTrackings';

export const OrderTrackingRoute = (props: RouteProps) => {
  const isCorrectRoute: boolean =
    new RegExp(
      /^.+\/org{1}\/\d+\/orderTrackings{1}\?id{1}\=[^\=\/\:\?&]*$/i,
    ).test(window.location.href) ||
    new RegExp(/^.+\/org{1}\/\d+\/orderTrackings{1}\??$/i).test(
      window.location.href,
    );

  return (
    <Route {...props}>
      {isCorrectRoute ? (
        <OrderTrackingScreen pageTitle="Load Tracking" />
      ) : (
        <Redirect to={NOT_FOUND_PATH} />
      )}
    </Route>
  );
};
