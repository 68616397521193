import {
  SalesTaxDto,
  LinkDto,
  LinkResourceBaseDto,
  SalesTaxDtoPagedResult,
  CreateSalesTaxCommandValues,
  UpdateSalesTaxCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createSalesTaxRequest,
  getSalesTaxesListRequest,
  getSalesTaxRequest,
  deleteSalesTaxRequest,
  updateSalesTaxRequest,
  GetSalesTaxParams,
} from './salesTaxes.service';

type SalesTaxesStoreState = {
  links: LinkDto[];
  salesTaxColumns: Column[];
};

export const updateSalesTaxesColumns = createEvent<Column[]>();

export const getSalesTaxes = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getSalesTaxesListRequest(currentOrganization, params);
});

export const createSalesTax = createEffect((salesTaxData: SalesTaxDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createSalesTaxCommand: CreateSalesTaxCommandValues = {
    ...salesTaxData,
  };

  return createSalesTaxRequest(currentOrganization!, createSalesTaxCommand);
});

export const getSalesTax = createEffect((salesTaxParams: GetSalesTaxParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getSalesTaxRequest(
    currentOrganization as LinkResourceBaseDto,
    salesTaxParams,
  );
});

export const updateSalesTax = createEffect((salesTax: SalesTaxDto) => {
  const updateSalesTaxCommand: UpdateSalesTaxCommandValues = { ...salesTax };
  return updateSalesTaxRequest(salesTax, updateSalesTaxCommand);
});

export const deleteSalesTax = createEffect((salesTax: SalesTaxDto) => {
  return deleteSalesTaxRequest(salesTax);
});

const defaultState: SalesTaxesStoreState = {
  links: [],
  salesTaxColumns: [
    {
      name: 'description',
      visible: true,
      title: 'Name',
      sortName: 'description',
    },
    { name: 'taxCode', visible: true, title: 'Code', sortName: 'taxCode' },
    { name: 'rate', visible: true, title: 'Rate', sortName: 'rate' },
    {
      name: 'authorityContactName',
      visible: true,
      title: 'Authority Name',
      sortName: 'AuthorityContact.Name',
    },
  ],
};

export const salesTaxStore: Store<SalesTaxesStoreState> = createStore(
  defaultState,
).on(updateSalesTaxesColumns, (state, payload) => {
  return { ...state, salesTaxColumns: payload };
});
