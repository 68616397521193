import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  Column,
  ContactDto,
  ContactType,
  ContactViewDto,
  ContactViewDtoPagedResult,
  ContactViewPagedResult,
  CustomFieldEntityType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  contactFilterFields,
  deleteContact,
  getContacts,
} from '../../contacts/contacts.store';
import { updateCustomersColumns, customerStore } from '../customer.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { CUSTOMERS_CREATE_PATH } from '../customer.route';
import { CustomerDialog } from './customer.dialog';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { getCustomFields } from '../../customFields/customFields.store';
import {
  CREATE_CONTACT_LINK_KEY,
  DELETE_CONTACT_LINK_KEY,
} from '../../contacts/contacts.service';
import { userHas } from '../../auth/auth.store';
import { ContactsGrid } from '../../common/components/grid/contacts-grid.component';

export type CustomersListProps = {
  goToDetails?: (id?: number) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onFilter?: (query: string) => void;
  onSelect?: (country: ContactDto, contactId: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const CustomersList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  filter = `contactType:${ContactType.Customer}`,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: CustomersListProps) => {
  const [contacts, setContacts] = useState<ContactViewDtoPagedResult | null>(
    null,
  );
  const history = useHistory();
  useEffect(() => {
    getCustomFieldsColumns();
    getContactsData();
  }, [offset, limit, sort, filter, search]);
  const getContactsData = () => {
    getContacts({ offset, limit, sort, filter, search }).then(
      (contact) => {
        const contactPagedResult: ContactViewPagedResult = {
          items: contact.items.map((item) => {
            const contactViewDto: ContactViewDto = {
              contactId: item.contactId,
              accountNumber: item.accountNumber,
              contactFirstName: item.contactFirstName,
              created: getFormattedDate(item.created),
              contactLastName: item.contactLastName,
              contactType: item.contactType,
              createdBy: item.createdBy,
              createdByUserName: item.createdByUserName,
              divisionId: item.divisionId,
              divisionName: item.divisionName,
              emailAddress: item.emailAddress,
              faxNumber: item.faxNumber,
              idNumber: item.idNumber,
              idNumberType: item.idNumberType,
              lastModified: getFormattedDate(item.lastModified),
              lastModifiedBy: item.lastModifiedBy,
              lastModifiedByUserName: item.lastModifiedByUserName,
              links: item.links,
              mobilePhoneNumber: item.mobilePhoneNumber,
              name: item.name,
              organizationId: item.organizationId,
              linkToContactId: item.linkToContactId,
              phoneNumber: item.phoneNumber,
              website: item.website,
            };
            if (item?.customValues) {
              Object.keys(item.customValues).forEach(
                (customFieldInternalName) => {
                  if (!contactViewDto.hasOwnProperty(customFieldInternalName))
                    contactViewDto[customFieldInternalName] =
                      item.customValues[customFieldInternalName];
                },
              );
            }
            return contactViewDto;
          }),
          limit: contact.limit,
          links: contact.links,
          offset: contact.offset,
          totalCount: contact.totalCount,
        };

        setContacts(contactPagedResult);
      },
      () => {},
    );
  };

  const [customFieldsNames, setCustomFieldsNames] = useState<string[]>([]);
  const getCustomFieldsColumns = () => {
    getCustomFields({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Customer} AND isInactive: false AND isSystem: false`,
    }).then(
      (customFieldsData) => {
        const customFieldsColumns: Column[] = [];
        const customFieldsColumnsNames: string[] = [];
        customFieldsData.items.forEach((customFieldDto) => {
          customFieldsColumns.push({
            name: customFieldDto.internalName,
            visible: true,
            title: customFieldDto.displayName,
            showFilter: true,
            type: 'customField',
            customFieldType: customFieldDto.customFieldType,
          });
          customFieldsColumnsNames.push(customFieldDto.internalName);
        });
        setCustomFieldsNames(customFieldsColumnsNames);
        const existingColumns = customerStore.getState().customerColumns;
        const existingColumnsNames: string[] = customerStore
          .getState()
          .customerColumns.map((column) => {
            return column.name;
          });
        const customFieldsColumnsToAdd: Column[] = [];
        customFieldsColumns.forEach((customColumn) => {
          if (existingColumnsNames.indexOf(customColumn.name) === -1) {
            customFieldsColumnsToAdd.push(customColumn);
          }
        });
        for (
          let i = customerStore.defaultState.customerColumns.length;
          i < existingColumns.length;
          i++
        ) {
          if (
            customFieldsColumnsNames.indexOf(existingColumns[i].name) === -1
          ) {
            existingColumns.splice(i, 1);
          }
        }
        if (customFieldsColumnsToAdd.length > 0) {
          updateCustomersColumns([
            ...existingColumns,
            ...customFieldsColumnsToAdd,
          ]);
        }
      },
      () => {},
    );
  };

  const { customerColumns: columns } = useStore(customerStore);

  const onCustomerSelect = (customer, customerId) => {
    if (onSelect) onSelect(customer, customerId);
    showDialog({
      dialog: CustomerDialog,
      props: {
        contactId: customer.contactId,
        title: 'Update Customer',
        disableDots: true,
      },
    }).then(
      (customer) => {
        if (customer !== null) {
          getContactsData();
        }
      },
      () => {},
    );
  };

  const onDeleteContact = (contact: ContactDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete Customer`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteContact(contact).then(() => {
          onDelete();
          getContactsData();
        });
      }
    });
  };

  return (
    <>
      <ContactsGrid
        contactType={'Customer'}
        isDropDownList={isDropDownList}
        showEmptyTable={true}
        search={search}
        onSearch={onSearch}
        showAllFilters={false}
        rowIncludeFilterKeys={[...contactFilterFields, ...customFieldsNames]}
        showAllStore={true}
        rowKeys={['contactId']}
        data={contacts?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={contacts?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_CONTACT_LINK_KEY, contacts?.items[0]?.links)
            ? onDeleteContact
            : null
        }
        filter={filter}
        onFilter={onFilter}
        onSort={onSort}
        onEdit={(contact: ContactDto) => {
          goToDetails(contact.contactId);
        }}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateCustomersColumns}
        onSelect={onCustomerSelect}
        getContactsData={getContactsData}
      />
      {!(
        (contacts &&
          contacts.items &&
          contacts.items.length &&
          contacts.items.length > 0) ||
        (filter && filter !== `contactType:${ContactType.Customer}`) ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">You Don't Have Any Customer Yet</h3>
          {userHas(CREATE_CONTACT_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={() => {
                const { currentOrganization } = organizationsStore.getState();
                const createPath = generatePath(
                  INTERNAL_PATH + CUSTOMERS_CREATE_PATH,
                  {
                    organizationId: currentOrganization.organizationId,
                  },
                );
                history.push(createPath);
              }}
            >
              Create Customer
            </Button>
          )}
        </div>
      )}
    </>
  );
};
