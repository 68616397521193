import {
  CurrencyDto,
  LinkDto,
  LinkResourceBaseDto,
  CurrencyDtoPagedResult,
  CreateCurrencyCommandValues,
  UpdateCurrencyCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_CURRENCIES_LINK_KEY = 'get-currencies';
export const CREATE_CURRENCY_LINK_KEY = 'create-currency';
export const GET_CURRENCY_LINK_KEY = 'get-currency';
export const UPDATE_CURRENCY_LINK_KEY = 'update-currency';
export const DELETE_CURRENCY_LINK_KEY = 'delete-currency';

export const CURRENCY_ENTITY_NAME = 'Currency';

export interface GetCurrencyParams {
  currencyId: number;
}

export const getCurrencyRequest = async (
  resource: LinkResourceBaseDto | null,
  { currencyId }: GetCurrencyParams,
): Promise<CurrencyDto> => {
  if (resource && currencyId) {
    const getCurrencyLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CURRENCY_LINK_KEY,
    );
    if (getCurrencyLink) {
      const result = await execLink(getCurrencyLink, { currencyId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CURRENCY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getCurrenciesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CurrencyDtoPagedResult> => {
  if (resource) {
    const getCurrenciesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CURRENCIES_LINK_KEY,
    );
    if (getCurrenciesListLink) {
      const result = await execLink(getCurrenciesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CURRENCIES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createCurrencyRequest = async (
  resource: LinkResourceBaseDto,
  currency: CreateCurrencyCommandValues,
) => {
  const createCurrencyLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CURRENCY_LINK_KEY,
  );
  if (createCurrencyLink) {
    const result = await execLink(createCurrencyLink, currency);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_CURRENCY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateCurrencyRequest = async (
  resource: LinkResourceBaseDto,
  currency: UpdateCurrencyCommandValues,
) => {
  const updateCurrencyLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CURRENCY_LINK_KEY,
  );
  if (updateCurrencyLink) {
    const result = await execLink(updateCurrencyLink, currency);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_CURRENCY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteCurrencyRequest = async (currency: CurrencyDto) => {
  const deleteCurrencyLink = currency.links?.find(
    (x: LinkDto) => x.rel === DELETE_CURRENCY_LINK_KEY,
  );
  if (deleteCurrencyLink) {
    const result = await execLink(deleteCurrencyLink, currency);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_CURRENCY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
