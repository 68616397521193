import React, { CSSProperties } from 'react';

export type StandaloneCheckboxProps = {
  label: string;
  name: string;
  id?: string;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  required?: boolean;
  checked: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (value: boolean) => void;
};

export const StandaloneCheckbox = ({
  label,
  name,
  id = null,
  disabled = false,
  checked = false,
  size = 'md',
  className = '',
  style = {},
  onClick = () => {},
  onChange = () => {},
  required,
}: StandaloneCheckboxProps) => {
  return (
    <span className={`form-check ${className}`} style={style}>
      <span className="custom-control custom-checkbox" onClick={onClick}>
        <input
          className={`custom-control-input custom-control-input-${size}`}
          type="checkbox"
          id={id ? id : name}
          name={name}
          checked={checked}
          onChange={(event) => onChange(event.target.checked)}
          disabled={disabled}
          required={required}
        />
        <label
          className={`custom-control-label custom-control-label-${size} font-size-${size} text-muted`}
          htmlFor={id ? id : name}
        >
          {label}
        </label>
      </span>
    </span>
  );
};
