import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  Column,
  ContactDto,
  ContactDtoPagedResult,
  ContactType,
  ContactViewDto,
  ContactViewPagedResult,
  CustomFieldEntityType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  contactStore,
  getContacts,
  deleteContact,
  updateContactsColumns,
  contactFilterFields,
} from '../contacts.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_CONTACT_LINK_KEY,
  DELETE_CONTACT_LINK_KEY,
  GetContactParams,
} from '../contacts.service';
import { ContactDialog } from './contact.dialog';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { getCustomFields } from '../../customFields/customFields.store';
import { userHas } from '../../auth/auth.store';
import { ContactsGrid } from '../../common/components/grid/contacts-grid.component';

export type ContactsListProps = {
  goToDetails?: (contactParams: GetContactParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (contact: ContactDto, contactParams: GetContactParams) => void;
  onFilter?: (query: string) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
  parentId?: number;
  disableDots?: boolean;
  showPagination?: boolean;
  isTab?: boolean;
  addButtonRenderCondition?: boolean;
  showSortColumns?: boolean;
};

export const ContactsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  filter = `contactType:${ContactType.Contact}`,
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  showAllStore = true,
  rowKeys = ['contactId'],
  onSearch,
  isDropDownList = false,
  parentId,
  disableDots = false,
  showPagination = true,
  isTab,
  addButtonRenderCondition,
  showSortColumns = true,
}: ContactsListProps) => {
  const [contacts, setContacts] = useState<ContactDtoPagedResult | null>(null);

  !parentId
    ? useEffect(() => {
        getCustomFieldsColumns();
        getContactsData();
      }, [offset, limit, sort, filter, search])
    : useEffect(() => {
        getCustomFieldsColumns();
        getContactsData();
      }, [contacts?.items?.length]);
  const getContactsData = () => {
    getContacts({ offset, limit, sort, filter, search }).then(
      (contact) => {
        const contactPagedResult: ContactViewPagedResult = {
          items: contact.items.map((item) => {
            const contactViewDto: ContactViewDto = {
              contactId: item.contactId,
              accountNumber: item.accountNumber,
              contactFirstName: item.contactFirstName,
              created: getFormattedDate(item.created),
              contactLastName: item.contactLastName,
              contactType: item.contactType,
              createdBy: item.createdBy,
              createdByUserName: item.createdByUserName,
              divisionId: item.divisionId,
              divisionName: item.divisionName,
              emailAddress: item.emailAddress,
              faxNumber: item.faxNumber,
              idNumber: item.idNumber,
              idNumberType: item.idNumberType,
              lastModified: getFormattedDate(item.lastModified),
              lastModifiedBy: item.lastModifiedBy,
              lastModifiedByUserName: item.lastModifiedByUserName,
              links: item.links,
              mobilePhoneNumber: item.mobilePhoneNumber,
              name: item.name,
              organizationId: item.organizationId,
              linkToContactId: item.linkToContactId,
              phoneNumber: item.phoneNumber,
              website: item.website,
            };
            if (item?.customValues) {
              Object.keys(item.customValues).forEach(
                (customFieldInternalName) => {
                  if (!contactViewDto.hasOwnProperty(customFieldInternalName))
                    contactViewDto[customFieldInternalName] =
                      item.customValues[customFieldInternalName];
                },
              );
            }
            return contactViewDto;
          }),
          limit: contact.limit,
          links: contact.links,
          offset: contact.offset,
          totalCount: contact.totalCount,
        };

        setContacts(contactPagedResult);
      },
      () => {},
    );
  };

  const [customFieldsNames, setCustomFieldsNames] = useState<string[]>([]);
  const getCustomFieldsColumns = () => {
    getCustomFields({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Contact} AND isInactive: false AND isSystem: false`,
    }).then((customFieldsData) => {
      const customFieldsColumns: Column[] = [];
      const customFieldsColumnsNames: string[] = [];
      customFieldsData.items.forEach((customFieldDto) => {
        customFieldsColumns.push({
          name: customFieldDto.internalName,
          visible: true,
          title: customFieldDto.displayName,
          showFilter: true,
          type: 'customField',
          customFieldType: customFieldDto.customFieldType,
        });
        customFieldsColumnsNames.push(customFieldDto.internalName);
      });
      setCustomFieldsNames(customFieldsColumnsNames);
      const existingColumns = contactStore.getState().contactColumns;
      const existingColumnsNames: string[] = contactStore
        .getState()
        .contactColumns.map((column) => {
          return column.name;
        });
      const customFieldsColumnsToAdd: Column[] = [];
      customFieldsColumns.forEach((customColumn) => {
        if (existingColumnsNames.indexOf(customColumn.name) === -1) {
          customFieldsColumnsToAdd.push(customColumn);
        }
      });
      for (
        let i = contactStore.defaultState.contactColumns.length;
        i < existingColumns.length;
        i++
      ) {
        if (customFieldsColumnsNames.indexOf(existingColumns[i].name) === -1) {
          existingColumns.splice(i, 1);
        }
      }
      if (customFieldsColumnsToAdd.length > 0) {
        updateContactsColumns([
          ...existingColumns,
          ...customFieldsColumnsToAdd,
        ]);
      }
    });
  };

  const { contactColumns: columns } = useStore(contactStore);

  const onDeleteContact = (contact: ContactDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${contact.contactId} Contact`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteContact(contact).then(() => {
          onDelete();
          getContactsData();
        });
      }
    });
  };

  const onEditContact = (contact: ContactDto) => {
    goToDetails({ contactId: contact.contactId });
  };

  const onCreateNewContact = () => {
    goToDetails({ contactId: 0 });
  };
  const onContactSelect = (contact, contactId) => {
    if (onSelect) onSelect(contact, contactId);
    showDialog({
      dialog: ContactDialog,
      props: {
        contactId: contact.contactId,
        title: 'Update Contact',
      },
    }).then((contact) => {
      if (contact !== null) {
        getContactsData();
      }
    });
  };

  return (
    <>
      <ContactsGrid
        contactType={'Contact'}
        isDropDownList={isDropDownList}
        showEmptyTable={true}
        search={search}
        onSearch={onSearch}
        showAllStore={showAllStore}
        showAllFilters={false}
        rowIncludeFilterKeys={[...contactFilterFields, ...customFieldsNames]}
        rowKeys={rowKeys}
        data={
          parentId
            ? contacts?.items.filter(
                (contact) => contact.linkToContactId == parentId,
              )
            : contacts?.items
        }
        columns={columns}
        offset={offset}
        limit={limit}
        total={contacts?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_CONTACT_LINK_KEY, contacts?.items[0]?.links)
            ? onDeleteContact
            : null
        }
        onSort={onSort}
        onEdit={onEditContact}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateContactsColumns}
        onSelect={onContactSelect}
        filter={filter}
        onFilter={onFilter}
        className={'contacts-list'}
        disableDots={disableDots}
        showPagination={showPagination}
        isTab={isTab}
        getContactsData={getContactsData}
        parentId={parentId}
        addButtonRenderCondition={addButtonRenderCondition}
        showSortColumns={showSortColumns}
      />
      {!(
        (contacts &&
          contacts.items &&
          contacts.items.length &&
          contacts.items.length > 0) ||
        (filter && filter !== `contactType:${ContactType.Contact}`) ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">You Don't Have Any Contact Yet</h3>
          {userHas(CREATE_CONTACT_LINK_KEY) && (
            <Button size={'lg'} color="primary" onClick={onCreateNewContact}>
              Create Contact
            </Button>
          )}
        </div>
      )}
    </>
  );
};
