import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetPortsQuery, OrderTypes } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import React from 'react';
import {
  QUOTE_CREATE_PATH,
  QUOTE_EDIT_PATH,
  QUOTE_LIST_PATH,
} from '../quote.route';
import { QuotesList } from '../components/quotes-list.component';
import {
  CREATE_ORDER_LINK_KEY,
  GetOrderParams,
} from '../../orders/orders.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';

export type QuotesListScreenProps = { pageTitle: string };

export const QuotesListScreen = ({ pageTitle }: QuotesListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = '-orderNumber~ToInt32',
    filter = `orderType:${OrderTypes.Quote}`,
    search = '',
  } = useQuery() as GetPortsQuery;
  const history = useHistory();

  const createPath = generatePath(INTERNAL_PATH + QUOTE_CREATE_PATH, {
    organizationId,
  });

  const goToDetails = (quoteParams: GetOrderParams) => {
    const quoteId = quoteParams.orderId;
    const createOrEdit = quoteId === 0 ? QUOTE_CREATE_PATH : QUOTE_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      quoteId,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + QUOTE_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + QUOTE_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + QUOTE_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + QUOTE_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };

  return (
    <InternalLayout
      title={'Quotes'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <QuotesList
        offset={offset}
        limit={limit}
        sort={sort}
        search={search}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        onSearch={onSearch}
        filter={filter}
      />
    </InternalLayout>
  );
};
