import {
  StateDto,
  LinkDto,
  LinkResourceBaseDto,
  StateDtoPagedResult,
  CreateStateCommandValues,
  UpdateStateCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_STATES_LINK_KEY = 'get-states';
export const CREATE_STATE_LINK_KEY = 'create-state';
export const GET_STATE_LINK_KEY = 'get-state';
export const UPDATE_STATE_LINK_KEY = 'update-state';
export const DELETE_STATE_LINK_KEY = 'delete-state';

export const STATE_ENTITY_NAME = 'State';

export interface GetStateParams {
  countryCode: string;
  stateCode: string;
}

export const getStateRequest = async (
  resource: LinkResourceBaseDto | null,
  { countryCode, stateCode }: GetStateParams,
): Promise<StateDto> => {
  if (resource && countryCode && stateCode) {
    const getStateLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_STATE_LINK_KEY,
    );
    if (getStateLink) {
      const result = await execLink(getStateLink, { countryCode, stateCode });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_STATE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getStatesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<StateDtoPagedResult> => {
  if (resource) {
    const getStatesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_STATES_LINK_KEY,
    );
    if (getStatesListLink) {
      const result = await execLink(getStatesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_STATES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createStateRequest = async (
  resource: LinkResourceBaseDto,
  state: CreateStateCommandValues,
) => {
  const createStateLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_STATE_LINK_KEY,
  );
  if (createStateLink) {
    const result = await execLink(createStateLink, state);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_STATE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateStateRequest = async (
  resource: LinkResourceBaseDto,
  state: UpdateStateCommandValues,
) => {
  const updateStateLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_STATE_LINK_KEY,
  );
  if (updateStateLink) {
    const result = await execLink(updateStateLink, state);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_STATE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteStateRequest = async (state: StateDto) => {
  const deleteStateLink = state.links?.find(
    (x: LinkDto) => x.rel === DELETE_STATE_LINK_KEY,
  );
  if (deleteStateLink) {
    const result = await execLink(deleteStateLink, state);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_STATE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
