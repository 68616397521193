import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { EmployeeEdit } from '../components/employee-edit.component';
import { EMPLOYEES_CREATE_PATH, EMPLOYEES_LIST_PATH } from '../employee.route';
import { addMessage, Message } from '../../common/messages.store';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import { CREATE_CONTACT_LINK_KEY } from '../../contacts/contacts.service';
import { authStore, userHas } from '../../auth/auth.store';

export type EmployeeScreenProps = { pageTitle: string };

export const EmployeeScreen = ({ pageTitle }: EmployeeScreenProps) => {
  const { user: currentUser } = authStore.getState();

  const history = useHistory();
  // @ts-ignore
  const { organizationId, contactId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + EMPLOYEES_CREATE_PATH, {
      organizationId,
    });
  }
  const onEmployeeCreated = () => {
    const path = generatePath(INTERNAL_PATH + EMPLOYEES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'employee-created',
      type: 'success',
      autoHide: true,
      message: 'Employee successfully created!',
    };
    history.push(path);
    addMessage(message);
  };
  const onEmployeeUpdated = () => {
    const path = generatePath(INTERNAL_PATH + EMPLOYEES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'employee-updated',
      type: 'success',
      autoHide: true,
      message: 'Employee successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + EMPLOYEES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };
  return (
    <InternalLayout
      title={'Employees'}
      createPath={
        userHas(CREATE_CONTACT_LINK_KEY) && currentUser?.isInOrgAdminRole
          ? createPath()
          : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <EmployeeEdit
        contactId={contactId}
        onEmployeeUpdated={onEmployeeUpdated}
        onEmployeeCreated={onEmployeeCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
