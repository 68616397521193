import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetPortsQuery } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import React from 'react';
import {
  DIVISION_CREATE_PATH,
  DIVISION_EDIT_PATH,
  DIVISION_LIST_PATH,
} from '../division.route';
import { DivisionsList } from '../components/divisions-list.component';
import {
  CREATE_DIVISION_LINK_KEY,
  GetDivisionParams,
} from '../divisions.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';

export type DivisionsListScreenProps = { pageTitle: string };

export const DivisionsListScreen = ({
  pageTitle,
}: DivisionsListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = 'divisionId',
    filter = '',
    search = '',
  } = useQuery() as GetPortsQuery;
  const history = useHistory();

  const createPath = generatePath(INTERNAL_PATH + DIVISION_CREATE_PATH, {
    organizationId,
  });

  const goToDetails = (divisionParams: GetDivisionParams) => {
    const createOrEdit =
      divisionParams.divisionId === 0
        ? DIVISION_CREATE_PATH
        : DIVISION_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      divisionId: divisionParams.divisionId,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + DIVISION_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + DIVISION_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + DIVISION_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + DIVISION_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };

  return (
    <InternalLayout
      title={'Divisions'}
      createPath={userHas(CREATE_DIVISION_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <DivisionsList
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        search={search}
        onSearch={onSearch}
      />
    </InternalLayout>
  );
};
