import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { OrderScreen } from './screen/order.screen';
import { OrdersListScreen } from './screen/orders-list.screen';

export const ORDER_LIST_PATH = '/orders';
export const ORDER_EDIT_PATH = '/orders/:orderId';
export const ORDER_CREATE_PATH = '/orders/0';
export const ORDERS_PATH = '/org/:organizationId/orders';

export const OrderRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${ORDER_LIST_PATH}`}>
          <OrdersListScreen pageTitle="Orders" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${ORDER_CREATE_PATH}`}>
          <OrderScreen pageTitle="Add New Order" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${ORDER_EDIT_PATH}`}>
          <OrderScreen pageTitle="Update Order" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
