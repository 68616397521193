import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
  AttachmentParentType,
  AddressType,
  ContactAddressDto,
  ContactDto,
  ContactLinkType,
  ContactType,
  CountryDto,
  DivisionDto,
  IDNumberType,
  StateDto,
  UserDto,
  UserGroupDto,
  CustomFieldDto,
  CustomFieldEntityType,
  VisibleTransactions,
} from '../../../models/data.models';
import {
  createContactAndContactAddresses,
  getContact,
  updateContactAndContactAddresses,
} from '../../contacts/contacts.store';
import { Panel } from '../../common/components/panel/panel.component';
import {
  ContactWithAddressesDto,
  CreateContactAndContactAddressesCommand,
  ReactSelectItem,
  UpdateContactAndContactAddressesCommand,
} from '../../../models/custom.models';
import { Button } from '../../common/components/button/button.component';
import { ContactForm } from '../../contacts/components/contact.form';
import { FormikProps, FormikValues } from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { canSeeUserList } from '../../users/users.service';
import { AttachmentsFilesList } from '../../attachments/components/attachments-files-list.component';
import {
  getEnumValues,
  generateValidationSchemaWithCustomFields,
  parseGeocodeResults,
} from '../../../utils/helper.utils';
import { ContactAddressesOtherAddressesTabList } from '../../contactAddresses/components/contactAddresses-forOtherAddressesTab-list.component';
import {
  ContactAddressesDefaultValues,
  ContactDefaultValues,
} from '../../common/DefaultValues';
import { getCustomFields } from '../../customFields/customFields.store';
import {
  authStore,
  getUserAdditionalData,
  getUserInfo,
  userHas,
} from '../../auth/auth.store';
import { useStore } from 'effector-react';
import * as Yup from 'yup';
import { UPDATE_CONTACT_LINK_KEY } from '../../contacts/contacts.service';

export interface FormCreateContactCommand {
  values: ContactDto;
}
export type EmployeeEditProps = {
  contactId?: number;
  validateOnChange?: boolean;
  onEmployeeCreated?: (contact: ContactDto) => void;
  onEmployeeUpdated?: (contact: ContactDto) => void;
  onCancel?: () => void;
};

const getInitialState = () => {
  const initialState = {
    name: ContactDefaultValues.contactName,
    divisionId: ContactDefaultValues.divisionId,
    accountNumber: ContactDefaultValues.accountNumber,
    contactAddresses: ContactDefaultValues.contactAddresses,
    shippingAddress: ContactDefaultValues.shippingAddress,
    billingAddress: ContactDefaultValues.billingAddress,
    contactFirstName: ContactDefaultValues.contactFirstName,
    contactLastName: ContactDefaultValues.contactLastName,
    contactType: ContactDefaultValues.contactType,
    created: null,
    createdBy: null,
    createdByUserName: null,
    divisionName: ContactDefaultValues.divisionName,
    emailAddress: ContactDefaultValues.emailAddress,
    faxNumber: ContactDefaultValues.faxNumber,
    idNumber: ContactDefaultValues.idNumber,
    idNumberType: ContactDefaultValues.idNumberType,
    lastModified: null,
    lastModifiedBy: null,
    lastModifiedByUserName: null,
    links: null,
    mobilePhoneNumber: ContactDefaultValues.mobilePhoneNumber,
    organizationId: null,
    linkToContactId: ContactDefaultValues.linkToContactId,
    contactLinkType: ContactDefaultValues.contactLinkType,
    phoneNumber: ContactDefaultValues.phoneNumber,
    website: ContactDefaultValues.website,
    contactId: ContactDefaultValues.contactId,
    userEmployee: {},
  };
  return initialState;
};

const contactAddressInitialState: ContactAddressDto = {
  addressType: ContactAddressesDefaultValues.addressType,
  contactId: ContactAddressesDefaultValues.contactId,
  stateName: ContactAddressesDefaultValues.stateName,
  stateCode: ContactAddressesDefaultValues.stateCode,
  links: null,
  countryName: ContactAddressesDefaultValues.countryName,
  countryCode: ContactAddressesDefaultValues.countryCode,
  createdBy: null,
  lastModified: null,
  created: null,
  lastModifiedBy: null,
  addressLine: ContactAddressesDefaultValues.addressLine,
  organizationId: null,
  contactAddressId: ContactAddressesDefaultValues.contactAddressId,
  addressLine2: ContactAddressesDefaultValues.addressLine2,
  city: ContactAddressesDefaultValues.city,
  postalCode: ContactAddressesDefaultValues.postalCode,
  latitude: ContactAddressesDefaultValues.latitude,
  longitude: ContactAddressesDefaultValues.longitude,
};

const addressSchema = Yup.object()
  .shape(
    {
      addressLine: Yup.string().when(
        ['addressLine2', 'city', 'stateCode', 'postalCode'],
        {
          is: (addressLine2, city, stateCode, postalCode) =>
            addressLine2?.length > 0 ||
            city?.length > 0 ||
            stateCode?.length > 0 ||
            postalCode?.length > 0,
          then: Yup.string().required("Can't be blank").nullable(true),
          otherwise: Yup.string().nullable(true),
        },
      ),
      addressLine2: Yup.string().nullable(true),
      city: Yup.string().nullable(true),
      postalCode: Yup.string().nullable(true),
      countryCode: Yup.string().when('addressLine', {
        is: (addressLine) => addressLine?.length > 0,
        then: Yup.string().required("Can't be blank").nullable(true),
        otherwise: Yup.string().nullable(true),
      }),
      stateCode: Yup.string().when(['addressLine', 'countryCode'], {
        is: (addressLine, countryCode) =>
          addressLine?.length > 0 &&
          (countryCode == 'US' || countryCode == 'CA'),
        then: Yup.string().required("Can't be blank").nullable(true),
        otherwise: Yup.string().nullable(true),
      }),
    },
    [
      ['stateCode', 'countryCode'],
      ['addressLine', 'stateCode'],
    ],
  )
  .nullable(true);

let employeesSchema = Yup.object().shape({
  name: Yup.string().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
  shippingAddress: addressSchema,
  billingAddress: addressSchema,
});

export const EmployeeEdit = ({
  contactId,
  onEmployeeCreated = () => {},
  onEmployeeUpdated = () => {},
  onCancel = () => {},
}: EmployeeEditProps) => {
  const isCreateMode = !contactId || contactId == 0;
  const { user: currentUser } = useStore(authStore);
  const [employeeUserIsCurrentUser, setEmployeeUserIsCurrentUser] = useState(
    false,
  );
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState<ContactWithAddressesDto | null>(null);
  const [
    initialValues,
    setInitialValues,
  ] = useState<ContactWithAddressesDto | null>(getInitialState());
  const [filterShippingState, setShippingFilterState] = useState<string>(` `);
  const [filterBillingState, setBillingFilterState] = useState<string>(` `);
  const ref = useRef<HTMLFormElement>();
  const otherAddressesLimit = 10;
  const [otherAddressesOffset, setOtherAddressesOffset] = useState(0);
  const [customFields, setCustomFields] = useState<CustomFieldDto[]>([]);

  useEffect(() => {
    getCustomFields({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Employee} AND isInactive: false AND isSystem: false`,
    }).then((fields) => {
      const customFields: CustomFieldDto[] = fields.items;
      employeesSchema = generateValidationSchemaWithCustomFields(
        customFields,
        employeesSchema,
      );
      setCustomFields(customFields);
    });
    if (isCreateMode) {
      setIsLoading(false);
    } else if (contactId) {
      getContact({ contactId: Number(contactId) || null }).then(
        (contactData: ContactDto) => {
          const shippingAddress = contactData?.contactAddresses.find(
            (x) => x.addressType == AddressType.Shipping,
          );
          const billingAddress = contactData?.contactAddresses.find(
            (x) => x.addressType == AddressType.Billing,
          );
          const contact = { ...contactData, shippingAddress, billingAddress };
          setContact(contact);
          setInitialValues(contact);
          setShippingFilterState(
            shippingAddress
              ? `countryCode:${shippingAddress.countryCode}`
              : ' ',
          );
          setBillingFilterState(
            billingAddress ? `countryCode:${billingAddress.countryCode}` : ' ',
          );
          setEmployeeUserIsCurrentUser(
            contact?.userEmployee?.userId === currentUser?.userId,
          );
          setIsLoading(false);
        },
        () => {},
      );
    }
  }, [contactId]);

  const onSubmit = (data: ContactWithAddressesDto) => {
    if (data.billingAddress) {
      data.billingAddress.addressType = AddressType.Billing;
    }
    if (data.shippingAddress) {
      data.shippingAddress.addressType = AddressType.Shipping;
    }
    data.customValues = contact.customValues;
    data.contactLinkType = ContactLinkType.ParentContact;
    setIsSending(true);
    if (isCreateMode) {
      data.contactType = ContactType.Employee;
      const contactAndContactAddresses: CreateContactAndContactAddressesCommand = {
        contact: data,
        billingAddress: data.billingAddress,
        shippingAddress: data.shippingAddress,
      };
      createContactAndContactAddresses(contactAndContactAddresses)
        .then(
          (result) => {
            onEmployeeCreated(result);
            getUserInfo().then(
              () => {
                getUserAdditionalData();
              },
              () => {},
            );
          },
          () => {},
        )
        .finally(() => {
          setIsSending(false);
        });
    } else {
      if (data.billingAddress) {
        data.billingAddress.contactId = data.contactId;
      }
      if (data.shippingAddress) {
        data.shippingAddress.contactId = data.contactId;
      }
      const updateContactAndContactAddressesCommand: UpdateContactAndContactAddressesCommand = {
        contact: data,
        billingAddress: data.billingAddress,
        shippingAddress: data.shippingAddress,
      };
      updateContactAndContactAddresses(updateContactAndContactAddressesCommand)
        .then(
          (result) => {
            onEmployeeUpdated(result);
            getUserInfo().then(
              () => {
                getUserAdditionalData();
              },
              () => {},
            );
          },
          () => {},
        )
        .finally(() => {
          setIsSending(false);
        });
    }
  };
  const onChangeShippingContactAddressCountryCode = (
    country: CountryDto,
    context: FormikProps<FormikValues>,
  ) => {
    context.setFieldValue(`shippingAddress.stateCode`, null);
    context.setFieldValue(`shippingAddress.stateName`, null);
    context.setFieldValue(
      `shippingAddress.addressLine`,
      context.values.shippingAddress?.addressLine || null,
    );
    setShippingFilterState(
      country?.countryCode ? `countryCode:${country.countryCode}` : ' ',
    );
    setContact((oldContact) => {
      if (!oldContact) {
        oldContact = initialValues;
      }
      if (!oldContact.shippingAddress) {
        oldContact.shippingAddress = { ...contactAddressInitialState };
      }
      oldContact.shippingAddress.countryCode = country?.countryCode;
      oldContact.shippingAddress.countryName = country?.name;
      delete oldContact.shippingAddress.stateCode;
      delete oldContact.shippingAddress.stateName;
      return { ...oldContact };
    });
  };
  const onChangeShippingContactAddressStateCode = (
    state: StateDto,
    context: FormikProps<FormikValues>,
  ) => {
    setContact((oldContact) => {
      if (!oldContact) {
        oldContact = initialValues;
      }
      if (!oldContact.shippingAddress) {
        oldContact.shippingAddress = { ...contactAddressInitialState };
      }
      oldContact.shippingAddress.stateCode = state?.stateCode;
      oldContact.shippingAddress.stateName = state?.name;
      return { ...oldContact };
    });
  };
  const onChangeBillingContactAddressCountryCode = (
    country: CountryDto,
    context: FormikProps<FormikValues>,
  ) => {
    context.setFieldValue(`billingAddress.stateCode`, null);
    context.setFieldValue(`billingAddress.stateName`, null);
    context.setFieldValue(
      `billingAddress.addressLine`,
      context.values.billingAddress?.addressLine || null,
    );
    setBillingFilterState(
      country?.countryCode ? `countryCode:${country.countryCode}` : ' ',
    );
    setContact((oldContact) => {
      if (!oldContact) {
        oldContact = initialValues;
      }
      if (!oldContact.billingAddress) {
        oldContact.billingAddress = { ...contactAddressInitialState };
      }
      oldContact.billingAddress.countryCode = country?.countryCode;
      oldContact.billingAddress.countryName = country?.name;
      delete oldContact.billingAddress.stateCode;
      delete oldContact.billingAddress.stateName;
      return { ...oldContact };
    });
  };
  const onChangeBillingContactAddressStateCode = (
    state: StateDto,
    context: FormikProps<FormikValues>,
  ) => {
    setContact((oldContact) => {
      if (!oldContact) {
        oldContact = initialValues;
      }
      if (!oldContact.billingAddress) {
        oldContact.billingAddress = { ...contactAddressInitialState };
      }
      oldContact.billingAddress.stateCode = state?.stateCode;
      oldContact.billingAddress.stateName = state?.name;
      return { ...oldContact };
    });
  };
  const onIdNumberTypeChange = (newIdNumberType: ReactSelectItem) => {
    setContact((contact) => {
      if (!contact) {
        contact = { ...getInitialState() };
      }
      contact.idNumberType = IDNumberType[newIdNumberType?.label];
      return { ...contact };
    });
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  const unsetUserEmployee = (context) => {
    setContact((oldContact) => {
      if (!oldContact) {
        oldContact = getInitialState();
      }
      oldContact.userEmployee = null;
      return { ...oldContact };
    });
    context.setFieldValue('userEmployee', null);
  };

  const onSelectPlaceAutocompleteShippingAddress = (
    results: any,
    context: FormikProps<FormikValues>,
  ) => {
    const addressInfo = parseGeocodeResults(results);
    context.setFieldValue(
      'shippingAddress.addressLine',
      addressInfo.addressLine,
    );
    context.setFieldValue(
      'shippingAddress.addressLine2',
      addressInfo.addressLine2 ?? '',
    );
    context.setFieldValue('shippingAddress.city', addressInfo.city);
    context.setFieldValue('shippingAddress.postalCode', addressInfo.postalCode);
    context.setFieldValue(
      'shippingAddress.countryCode',
      addressInfo.countryCode,
    );
    context.setFieldValue('shippingAddress.stateCode', addressInfo.stateCode);
    context.setFieldValue('shippingAddress.latitude', addressInfo.latitude);
    context.setFieldValue('shippingAddress.longitude', addressInfo.longitude);
    setShippingFilterState(`countryCode:${addressInfo.countryCode}`);
    setContact((oldContact) => {
      if (!oldContact) {
        oldContact = initialValues;
      }
      if (!oldContact.shippingAddress) {
        oldContact.shippingAddress = { ...contactAddressInitialState };
      }
      oldContact.shippingAddress.addressLine = addressInfo.addressLine;
      oldContact.shippingAddress.addressLine2 = addressInfo.addressLine2;
      oldContact.shippingAddress.city = addressInfo.city;
      oldContact.shippingAddress.postalCode = addressInfo.postalCode;
      oldContact.shippingAddress.countryCode = addressInfo.countryCode;
      oldContact.shippingAddress.countryName = addressInfo.countryName;
      oldContact.shippingAddress.stateCode = addressInfo.stateCode;
      oldContact.shippingAddress.stateName = addressInfo.stateName;
      oldContact.shippingAddress.latitude = addressInfo.latitude;
      oldContact.shippingAddress.longitude = addressInfo.longitude;
      return { ...oldContact };
    });
    return addressInfo.addressLine;
  };

  const onSelectPlaceAutocompleteBillingAddress = (
    results: any,
    context: FormikProps<FormikValues>,
  ) => {
    const addressInfo = parseGeocodeResults(results);
    context.setFieldValue(
      'billingAddress.addressLine',
      addressInfo.addressLine,
    );
    context.setFieldValue(
      'billingAddress.addressLine2',
      addressInfo.addressLine2,
    );
    context.setFieldValue('billingAddress.city', addressInfo.city);
    context.setFieldValue('billingAddress.postalCode', addressInfo.postalCode);
    context.setFieldValue(
      'billingAddress.countryCode',
      addressInfo.countryCode,
    );
    context.setFieldValue('billingAddress.stateCode', addressInfo.stateCode);
    context.setFieldValue('billingAddress.latitude', addressInfo.latitude);
    context.setFieldValue('billingAddress.longitude', addressInfo.longitude);
    setBillingFilterState(`countryCode:${addressInfo.countryCode}`);
    setContact((oldContact) => {
      if (!oldContact) {
        oldContact = initialValues;
      }
      if (!oldContact.billingAddress) {
        oldContact.billingAddress = { ...contactAddressInitialState };
      }
      oldContact.billingAddress.addressLine = addressInfo.addressLine;
      oldContact.billingAddress.addressLine2 = addressInfo.addressLine2;
      oldContact.billingAddress.city = addressInfo.city;
      oldContact.billingAddress.postalCode = addressInfo.postalCode;
      oldContact.billingAddress.countryCode = addressInfo.countryCode;
      oldContact.billingAddress.countryName = addressInfo.countryName;
      oldContact.billingAddress.stateCode = addressInfo.stateCode;
      oldContact.billingAddress.stateName = addressInfo.stateName;
      oldContact.billingAddress.latitude = addressInfo.latitude;
      oldContact.billingAddress.longitude = addressInfo.longitude;
      return { ...oldContact };
    });
    return addressInfo.addressLine;
  };

  return (
    <div className={'employee-edit-form'}>
      <ContactForm
        id={'EmployeeForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={ref}
        validationSchema={employeesSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <Tabs>
              <TabList>
                <Tab name="general">General</Tab>
                <Tab name="shippingAddress">Address</Tab>
                <Tab name="billingAddress">Billing Address</Tab>
                <Tab name="other" disabled={isCreateMode}>
                  Other Addresses
                </Tab>
                {canSeeUserList() && <Tab>User</Tab>}
                <Tab name="additional">Additional</Tab>
                <Tab name="attachments" disabled={isCreateMode}>
                  Attachments
                </Tab>
              </TabList>
              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  {isCreateMode ? (
                    <h2 className="header-form">Add New Employee</h2>
                  ) : (
                    <h2>Update Employee</h2>
                  )}
                  <div className="row">
                    <div className="col-4">
                      <ContactForm.NameInput />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <ContactForm.Phone />
                    </div>
                    <div className="col-4">
                      <ContactForm.MobilePhone />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <ContactForm.Fax />
                    </div>
                    <div className="col-4">
                      <ContactForm.Email />
                    </div>
                  </div>
                  <div className="row">
                    <div className={'col-4'}>
                      <ContactForm.ContactFirstName />
                    </div>
                  </div>
                  <div className="row">
                    <div className={'col-4'}>
                      <ContactForm.ContactLastName />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <ContactForm.IdNumber />
                    </div>
                    <div className="col-4">
                      <ContactForm.IdNumberType
                        placeholder={'Select Id Number Type'}
                        id={'idNumberType'}
                        header={'Id Number Type'}
                        name={'idNumberType'}
                        disabled={false}
                        required={false}
                        defaultValue={
                          contact?.idNumberType
                            ? {
                                label: IDNumberType[contact?.idNumberType],
                                value: contact?.idNumberType,
                              }
                            : null
                        }
                        onChange={onIdNumberTypeChange}
                        multiple={false}
                        options={getEnumValues(IDNumberType)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <ContactForm.DivisionSelector
                        id={'divisionId'}
                        header={'Division'}
                        required={true}
                        defaultValue={
                          contact &&
                          contact.divisionId != null &&
                          contact.divisionName != null
                            ? {
                                divisionId: contact.divisionId,
                                divisionName: contact.divisionName,
                              }
                            : ''
                        }
                        onChange={(data?: DivisionDto) => {
                          setContact((contact) => {
                            if (!contact) {
                              contact = { ...getInitialState() };
                            }
                            contact.divisionId = data?.divisionId;
                            contact.divisionName = data?.divisionName;
                            return { ...contact };
                          });
                        }}
                        nameId={'divisionName'}
                        defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                        disabled={
                          !(
                            (currentUser?.isInOrgAdminRole ||
                              currentUser?.isInOperationRole) &&
                            VisibleTransactions[
                              currentUser?.visibleTransactions
                            ] === VisibleTransactions.AllTransactions
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="justify-content-end row">
                    {(userHas(UPDATE_CONTACT_LINK_KEY, contact?.links) ||
                      isCreateMode) &&
                      currentUser?.isInOrgAdminRole && (
                        <div className="col-3">
                          <Button
                            form={'EmployeeForm'}
                            type="submit"
                            color="primary"
                            className="w-100 btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Employee
                          </Button>
                        </div>
                      )}
                    <div className="col-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onCancel}
                        className="w-100 btn-secondary"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Panel>
              </TabPanel>
              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  <div className="row">
                    <div className="col-4">
                      <ContactForm.ContactAddressAddressLine
                        defaultValue={contact?.shippingAddress?.addressLine}
                        name={`shippingAddress.addressLine`}
                        onSelect={onSelectPlaceAutocompleteShippingAddress}
                        lat={contact?.shippingAddress?.latitude}
                        lng={contact?.shippingAddress?.longitude}
                      />
                      <ContactForm.ContactAddressAddressLine2
                        name={`shippingAddress.addressLine2`}
                      />
                      <ContactForm.StateSelect
                        selectedFilter={filterShippingState}
                        id={'shippingAddress.stateCode'}
                        header={'State'}
                        required={true}
                        defaultValue={
                          contact &&
                          contact.shippingAddress &&
                          contact.shippingAddress.stateCode &&
                          contact.shippingAddress.stateName
                            ? {
                                stateCode: contact.shippingAddress.stateCode,
                                name: contact.shippingAddress.stateName,
                                countryCode:
                                  contact.shippingAddress.countryCode,
                              }
                            : ''
                        }
                        onChange={onChangeShippingContactAddressStateCode}
                        nameId={`shippingAddress.stateName`}
                      />
                      <ContactForm.ContactAddressCity
                        name={`shippingAddress.city`}
                      />
                      <ContactForm.ContactAddressCountryCode
                        id={'shippingAddress.countryCode'}
                        header={'Country'}
                        required={true}
                        defaultValue={
                          contact &&
                          contact.shippingAddress &&
                          contact.shippingAddress.countryCode &&
                          contact.shippingAddress.countryName
                            ? {
                                countryCode:
                                  contact.shippingAddress.countryCode,
                                name: contact.shippingAddress.countryName,
                              }
                            : ''
                        }
                        onChange={onChangeShippingContactAddressCountryCode}
                        nameId={`shippingAddress.countryName`}
                      />
                      <ContactForm.ContactAddressPostalCode
                        name={`shippingAddress.postalCode`}
                      />
                      <ContactForm.Latitude
                        name={'shippingAddress.latitude'}
                        onChange={(value) =>
                          setContact((oldContact) => {
                            if (!oldContact.shippingAddress) {
                              oldContact.shippingAddress = contactAddressInitialState;
                            }
                            oldContact.shippingAddress.latitude = value;
                            return { ...oldContact };
                          })
                        }
                      />
                      <ContactForm.Longitude
                        name={'shippingAddress.longitude'}
                        onChange={(value) =>
                          setContact((oldContact) => {
                            if (!oldContact.shippingAddress) {
                              oldContact.shippingAddress = contactAddressInitialState;
                            }
                            oldContact.shippingAddress.longitude = value;
                            return { ...oldContact };
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="justify-content-end row">
                    {(userHas(UPDATE_CONTACT_LINK_KEY, contact?.links) ||
                      isCreateMode) &&
                      currentUser?.isInOrgAdminRole && (
                        <div className="col-3">
                          <Button
                            form={'EmployeeForm'}
                            type="submit"
                            color="primary"
                            className="w-100 btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Employee
                          </Button>
                        </div>
                      )}
                    <div className="col-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onCancel}
                        className="w-100 btn-secondary"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Panel>
              </TabPanel>
              <TabPanel forceRender={true}>
                <Panel className="m-3">
                  <div className="row">
                    <div className="col-4">
                      <ContactForm.ContactAddressAddressLine
                        defaultValue={contact?.billingAddress?.addressLine}
                        name={`billingAddress.addressLine`}
                        onSelect={onSelectPlaceAutocompleteBillingAddress}
                        lat={contact?.billingAddress?.latitude}
                        lng={contact?.billingAddress?.longitude}
                      />
                      <ContactForm.ContactAddressAddressLine2
                        name={`billingAddress.addressLine2`}
                      />
                      <ContactForm.StateSelect
                        selectedFilter={filterBillingState}
                        id={'billingAddress.stateCode'}
                        header={'State'}
                        required={true}
                        defaultValue={
                          contact &&
                          contact.billingAddress &&
                          contact.billingAddress.stateCode &&
                          contact.billingAddress.stateName
                            ? {
                                stateCode: contact.billingAddress.stateCode,
                                name: contact.billingAddress.stateName,
                                countryCode: contact.billingAddress.countryCode,
                              }
                            : ''
                        }
                        onChange={onChangeBillingContactAddressStateCode}
                        nameId={`billingAddress.stateName`}
                      />
                      <ContactForm.ContactAddressCity
                        name={`billingAddress.city`}
                      />
                      <ContactForm.ContactAddressCountryCode
                        id={'billingAddress.countryCode'}
                        header={'Country'}
                        required={true}
                        defaultValue={
                          contact &&
                          contact.billingAddress &&
                          contact.billingAddress.countryCode &&
                          contact.billingAddress.countryName
                            ? {
                                countryCode: contact.billingAddress.countryCode,
                                name: contact.billingAddress.countryName,
                              }
                            : ''
                        }
                        onChange={onChangeBillingContactAddressCountryCode}
                        nameId={`billingAddress.countryName`}
                      />
                      <ContactForm.ContactAddressPostalCode
                        name={`billingAddress.postalCode`}
                      />
                      <ContactForm.Latitude
                        name={'billingAddress.latitude'}
                        onChange={(value) =>
                          setContact((oldContact) => {
                            if (!oldContact.billingAddress) {
                              oldContact.billingAddress = contactAddressInitialState;
                            }
                            oldContact.billingAddress.latitude = value;
                            return { ...oldContact };
                          })
                        }
                      />
                      <ContactForm.Longitude
                        name={'billingAddress.longitude'}
                        onChange={(value) =>
                          setContact((oldContact) => {
                            if (!oldContact.billingAddress) {
                              oldContact.billingAddress = contactAddressInitialState;
                            }
                            oldContact.billingAddress.longitude = value;
                            return { ...oldContact };
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="justify-content-end row">
                    {(userHas(UPDATE_CONTACT_LINK_KEY, contact?.links) ||
                      isCreateMode) &&
                      currentUser?.isInOrgAdminRole && (
                        <div className="col-3">
                          <Button
                            form={'EmployeeForm'}
                            type="submit"
                            color="primary"
                            className="w-100 btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Employee
                          </Button>
                        </div>
                      )}
                    <div className="col-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onCancel}
                        className="w-100 btn-secondary"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Panel>
              </TabPanel>
              <TabPanel>
                <Panel className="m-3">
                  <ContactAddressesOtherAddressesTabList
                    contactId={contactId}
                    isDropDownList={false}
                    showAllStore={false}
                    rowKeys={[
                      'addressLine',
                      'addressLine2',
                      'city',
                      'countryCode',
                      'postalCode',
                      'stateCode',
                    ]}
                    limit={otherAddressesLimit}
                    onPageChanged={(page) =>
                      setOtherAddressesOffset(page * otherAddressesLimit)
                    }
                    filter={`contactId: ${contactId} AND addressType: "Other"`}
                    offset={otherAddressesOffset}
                    hideColumnsSelect={true}
                    onFilter={null}
                    addButtonRenderCondition={
                      !currentUser?.isInOrgUserRole &&
                      !currentUser?.isInAccountingRole &&
                      !currentUser?.isInOperationRole
                    }
                  />
                  <div className="justify-content-end row mt-4">
                    {(userHas(UPDATE_CONTACT_LINK_KEY, contact?.links) ||
                      isCreateMode) &&
                      currentUser?.isInOrgAdminRole && (
                        <div className="col-3">
                          <Button
                            form={'EmployeeForm'}
                            type="submit"
                            color="primary"
                            className="w-100 btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Employee
                          </Button>
                        </div>
                      )}
                    <div className="col-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onCancel}
                        className="w-100 btn-secondary"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Panel>
              </TabPanel>
              {canSeeUserList() && (
                <TabPanel>
                  <Panel className={'m-3'}>
                    <h2 className="header-form">User</h2>
                    <div className="row">
                      <div className="col-5">
                        <ContactForm.User
                          id={'userEmployee.userId'}
                          header={'Username'}
                          selectedFilter={``}
                          required={false}
                          defaultValue={
                            contact &&
                            contact.userEmployee &&
                            contact.userEmployee.userId != null &&
                            contact.userEmployee.userUserName != null
                              ? {
                                  userId: contact.userEmployee.userId,
                                  userName: contact.userEmployee.userUserName,
                                }
                              : ''
                          }
                          onChange={(data?: UserDto) => {
                            setContact((oldContact) => {
                              if (!oldContact) {
                                oldContact = getInitialState();
                              }
                              if (!oldContact?.userEmployee) {
                                oldContact.userEmployee = {};
                              }
                              if (!data) {
                                unsetUserEmployee(context);
                              } else {
                                oldContact.userEmployee.userId = data?.userId;
                                oldContact.userEmployee.userUserName =
                                  data?.userName;
                                return { ...oldContact };
                              }
                            });
                          }}
                          nameId={'userEmployee.userUserName'}
                          disabled={employeeUserIsCurrentUser}
                        />
                        <ContactForm.UserGroups
                          id={'userEmployee.userGroups'}
                          header={'Groups'}
                          selectedFilter={``}
                          required={false}
                          defaultValue={
                            contact &&
                            contact.userEmployee &&
                            contact.userEmployee.userGroups != null
                              ? contact.userEmployee.userGroups
                              : ''
                          }
                          onChange={(data?: UserGroupDto[]) => {
                            setContact((oldContact) => {
                              if (!oldContact) {
                                oldContact = getInitialState();
                              }
                              if (!oldContact?.userEmployee) {
                                oldContact.userEmployee = {};
                              }
                              oldContact.userEmployee.userGroups = data;
                              return { ...oldContact };
                            });
                          }}
                          nameId={'userGroups'}
                          disabled={employeeUserIsCurrentUser}
                        />
                        <div className={'font-weight-bold mb-3 text-primary'}>
                          Transactions visible to the employee
                        </div>
                        <ContactForm.TransactionsVisible
                          onChange={(value) => {
                            context.setFieldValue(
                              'userEmployee.visibleTransactions',
                              value,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="justify-content-end row">
                      {(userHas(UPDATE_CONTACT_LINK_KEY, contact?.links) ||
                        isCreateMode) &&
                        currentUser?.isInOrgAdminRole && (
                          <div className="col-3">
                            <Button
                              form={'EmployeeForm'}
                              type="submit"
                              color="primary"
                              className="w-100 btn-block"
                              disabled={isSending}
                              isSending={isSending}
                            >
                              Save Employee
                            </Button>
                          </div>
                        )}
                      <div className="col-3">
                        <Button
                          type="button"
                          color="primary"
                          onClick={onCancel}
                          className="w-100 btn-secondary"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
              )}
              <TabPanel forceRender={isCreateMode ? false : true}>
                <ContactForm.CustomValues
                  customFields={customFields}
                  entityType={CustomFieldEntityType.Employee}
                  defaultValue={{ ...contact?.customValues }}
                  onChange={(result) => {
                    setContact((contact) => {
                      contact.customValues = result;
                      return { ...contact };
                    });
                  }}
                  saveButtonRenderCondition={
                    (userHas(UPDATE_CONTACT_LINK_KEY, contact?.links) ||
                      isCreateMode) &&
                    currentUser?.isInOrgAdminRole
                  }
                  isSending={isSending}
                  formName={'EmployeeForm'}
                  entityName={'Employee'}
                  onCancel={onCancel}
                  context={context}
                />
              </TabPanel>
              <TabPanel>
                <Panel className="m-3">
                  <AttachmentsFilesList
                    parentId={contactId}
                    parentType={AttachmentParentType.Contact}
                  />
                  <div className="justify-content-end row mt-4">
                    {(userHas(UPDATE_CONTACT_LINK_KEY, contact?.links) ||
                      isCreateMode) &&
                      currentUser?.isInOrgAdminRole && (
                        <div className="col-3">
                          <Button
                            form={'EmployeeForm'}
                            type="submit"
                            color="primary"
                            className="w-100 btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save Employee
                          </Button>
                        </div>
                      )}
                    <div className="col-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onCancel}
                        className="w-100 btn-secondary"
                        disabled={isSending}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Panel>
              </TabPanel>
            </Tabs>
          )}
        </FormContext.Consumer>
      </ContactForm>
    </div>
  );
};
