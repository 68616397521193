import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  AddressType,
  ContactAddressDto,
  ContactAddressDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  contactAddressStore,
  getContactAddresses,
  deleteContactAddress,
  updateContactAddressesColumns,
} from '../contactAddresses.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { GetContactAddressParams } from '../contactAddresses.service';
import { ListParams } from '../../common/models/ListParams';
import { ContactAddressesDialog } from './contactAddresses.dialog';

export type ContactAddressesOtherAddressesTabListProps = {
  contactId: number;
  goToDetails?: (contactAddressParams: GetContactAddressParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onCreate?: (contactAddress: ContactAddressDto) => void;
  onEdit?: (contactAddress: ContactAddressDto) => void;
  onDelete?: (contactAddress: ContactAddressDto) => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSelect?: (
    contactAddress: ContactAddressDto,
    contactAddressId: string,
  ) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  isDropDownList?: boolean;
  hideColumnsSelect?: boolean;
  addButtonRenderCondition?: boolean;
};

export const ContactAddressesOtherAddressesTabList = ({
  contactId,
  goToDetails = () => {},
  offset = 0,
  limit = 10,
  search = {},
  filter = ' ',
  sort = null,
  onDelete = () => {},
  onCreate = () => {},
  onEdit = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onFilter = () => {},
  onSelect,
  showAllStore = true,
  rowKeys = ['contactAddressId'],
  isDropDownList = false,
  hideColumnsSelect = false,
  addButtonRenderCondition = true,
}: ContactAddressesOtherAddressesTabListProps) => {
  const [
    contactAddresses,
    setContactAddresses,
  ] = useState<ContactAddressDtoPagedResult | null>(null);
  const updateContactAddressDialogTitle = 'Update Contact Address';
  const createContactAddressDialogTitle = 'Create Contact Address';
  const deleteContactAddressDialogTitle = 'Delete Contact Address';

  useEffect(() => {
    GetContactAddresses({ offset, limit, sort, filter });
  }, [offset, limit, sort, filter]);
  function GetContactAddresses(params: ListParams = {}) {
    getContactAddresses(params).then(
      (contactAddressesData) => {
        setContactAddresses(contactAddressesData);
      },
      () => {},
    );
  }
  const { contactAddressColumns: columns } = useStore(contactAddressStore);

  const onCreateNewContactAddress = () => {
    showDialog({
      dialog: ContactAddressesDialog,
      props: {
        title: createContactAddressDialogTitle,
        contactAddressId: 0,
        contactId,
        contactAddressType: AddressType.Other,
      },
    }).then(
      (result) => {
        if (result) {
          onCreate(result);
          GetContactAddresses({ offset, limit, sort, filter });
        }
      },
      () => {},
    );
  };

  const onEditContactAddress = (contactAddress: ContactAddressDto) => {
    if (contactAddress) {
      showDialog({
        dialog: ContactAddressesDialog,
        props: {
          title: updateContactAddressDialogTitle,
          contactAddressId: contactAddress.contactAddressId,
          contactId,
          contactAddressType: AddressType.Other,
        },
      }).then(
        (result) => {
          if (result) {
            onEdit(result);
          }
          GetContactAddresses({ offset, limit, sort, filter });
        },
        () => {},
      );
    }
  };

  const onDeleteContactAddress = (contactAddress: ContactAddressDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: deleteContactAddressDialogTitle,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteContactAddress(contactAddress).then(
            () => {
              onDelete(result);
              GetContactAddresses({ offset, limit, sort, filter });
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  return (
    <div>
      <div className="row m-3">
        {addButtonRenderCondition && (
          <div className="offset-10 col-2">
            <Button
              size={'sm'}
              color="primary"
              className="btn-light btn-cancel w-100 h-100"
              name="create-otherContactAddress"
              onClick={onCreateNewContactAddress}
            >
              Add Contact Address
            </Button>
          </div>
        )}
      </div>
      {contactAddresses &&
      contactAddresses.items &&
      contactAddresses.items.length ? (
        <Grid
          isDropDownList={isDropDownList}
          showAllStore={showAllStore}
          rowKeys={rowKeys}
          data={contactAddresses.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={contactAddresses.totalCount}
          sort={sort}
          onDelete={onDeleteContactAddress}
          onSort={onSort}
          onEdit={onEditContactAddress}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateContactAddressesColumns}
          onSelect={onEditContactAddress}
          filter={filter}
          onFilter={onFilter}
          className={'contactAddresses-list'}
          hideColumnsSelect={hideColumnsSelect}
        />
      ) : (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Other Contact Addresses Yet
          </h3>
        </div>
      )}
    </div>
  );
};
