import React from 'react';
import { RouteDto } from '../../../models/data.models';

export type OrderTrackingRouteProps = {
  type: 'origin' | 'destination';
  route: RouteDto;
};

export const OrderTrackingRoute = ({
  type,
  route,
}: OrderTrackingRouteProps) => {
  return (
    <div
      className={`${
        type === 'origin' ? 'origin-info' : ''
      } d-flex flex-column mb-5`}
    >
      <div className="origin d-flex align-items-center">
        <img
          className={
            type === 'origin'
              ? 'order-tracking-origin'
              : 'order-tracking-destination'
          }
        />
        <div className="origin-text">
          <div className="origin-title">
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </div>
          <div className="origin-address">{route.address}</div>
        </div>
      </div>
      <div className="pickup d-flex align-items-center">
        <img
          className={
            type === 'origin'
              ? 'order-tracking-pickup'
              : 'order-tracking-delivery'
          }
        />
        <div className="pickup-text">
          <div className="pickup-title">
            {type === 'origin' ? 'Pickup' : 'Delivery'}
          </div>
          <div className="pickup-scheduled">{`Scheduled: ${route.date}`}</div>
          <div className="pickup-actual">Actual: NA</div>
        </div>
      </div>
    </div>
  );
};
