import {
  AccountingItemDto,
  LinkDto,
  LinkResourceBaseDto,
  AccountingItemDtoPagedResult,
  CreateAccountingItemCommandValues,
  UpdateAccountingItemCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createAccountingItemRequest,
  getAccountingItemsListRequest,
  getAccountingItemRequest,
  deleteAccountingItemRequest,
  updateAccountingItemRequest,
  GetAccountingItemParams,
  downloadQuickBooksAccountingItemsRequest,
} from './accountingItems.service';

type AccountingItemsStoreState = {
  links: LinkDto[];
  accountingItemColumns: Column[];
};

export const updateAccountingItemsColumns = createEvent<Column[]>();

export const getAccountingItems = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getAccountingItemsListRequest(currentOrganization, params);
});

export const createAccountingItem = createEffect(
  (accountingItemData: AccountingItemDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createAccountingItemCommand: CreateAccountingItemCommandValues = {
      organizationId: accountingItemData.organizationId,
      accountId: accountingItemData.accountId,
      description: accountingItemData.description,
      isInactive: accountingItemData.isInactive,
      itemCode: accountingItemData.itemCode,
      itemType: accountingItemData.itemType,
      price: accountingItemData.price,
      salesTaxId: accountingItemData.salesTaxId,
      tariff: accountingItemData.tariff,
    };

    return createAccountingItemRequest(
      currentOrganization!,
      createAccountingItemCommand,
    );
  },
);

export const getAccountingItem = createEffect(
  (accountingItemParams: GetAccountingItemParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAccountingItemRequest(
      currentOrganization as LinkResourceBaseDto,
      accountingItemParams,
    );
  },
);

export const updateAccountingItem = createEffect(
  (accountingItem: AccountingItemDto) => {
    const updateAccountingItemCommand: UpdateAccountingItemCommandValues = {
      ...accountingItem,
    };
    return updateAccountingItemRequest(
      accountingItem,
      updateAccountingItemCommand,
    );
  },
);

export const downloadQuickBooksAccountingItems = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return downloadQuickBooksAccountingItemsRequest(currentOrganization);
});

export const deleteAccountingItem = createEffect(
  (accountingItem: AccountingItemDto) => {
    return deleteAccountingItemRequest(accountingItem);
  },
);

const defaultState: AccountingItemsStoreState = {
  links: [],
  accountingItemColumns: [
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'itemCode',
      visible: true,
      title: 'Item Code',
      sortName: 'itemCode',
    },
    { name: 'itemType', visible: true, title: 'Type', sortName: 'itemType' },
    {
      name: 'accountName',
      visible: true,
      title: 'Account Name',
      sortName: 'Account.AccountName',
    },
  ],
};

export const accountingItemStore: Store<AccountingItemsStoreState> = createStore(
  defaultState,
).on(updateAccountingItemsColumns, (state, payload) => {
  return { ...state, accountingItemColumns: payload };
});
