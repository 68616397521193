import {
  ContainerTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  ContainerTypeDtoPagedResult,
  CreateContainerTypeCommandValues,
  UpdateContainerTypeCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_CONTAINERTYPES_LINK_KEY = 'get-containertypes';
export const CREATE_CONTAINERTYPE_LINK_KEY = 'create-containertype';
export const GET_CONTAINERTYPE_LINK_KEY = 'get-containertype';
export const UPDATE_CONTAINERTYPE_LINK_KEY = 'update-containertype';
export const DELETE_CONTAINERTYPE_LINK_KEY = 'delete-containertype';

export const CONTAINER_TYPE_ENTITY_NAME = 'Container Type';

export interface GetContainerTypeParams {
  containerTypeCode: string;
}

export const getContainerTypeRequest = async (
  resource: LinkResourceBaseDto | null,
  { containerTypeCode }: GetContainerTypeParams,
): Promise<ContainerTypeDto> => {
  if (resource && containerTypeCode) {
    const getContainerTypeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTAINERTYPE_LINK_KEY,
    );
    if (getContainerTypeLink) {
      const result = await execLink(getContainerTypeLink, {
        containerTypeCode,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTAINERTYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getContainerTypesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContainerTypeDtoPagedResult> => {
  if (resource) {
    const getContainerTypesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTAINERTYPES_LINK_KEY,
    );
    if (getContainerTypesListLink) {
      const result = await execLink(getContainerTypesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTAINERTYPES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createContainerTypeRequest = async (
  resource: LinkResourceBaseDto,
  containerType: CreateContainerTypeCommandValues,
) => {
  const createContainerTypeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTAINERTYPE_LINK_KEY,
  );
  if (createContainerTypeLink) {
    const result = await execLink(createContainerTypeLink, containerType);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_CONTAINERTYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateContainerTypeRequest = async (
  resource: LinkResourceBaseDto,
  containerType: UpdateContainerTypeCommandValues,
) => {
  const updateContainerTypeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTAINERTYPE_LINK_KEY,
  );
  if (updateContainerTypeLink) {
    const result = await execLink(updateContainerTypeLink, containerType);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_CONTAINERTYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteContainerTypeRequest = async (
  containerType: ContainerTypeDto,
) => {
  const deleteContainerTypeLink = containerType.links?.find(
    (x: LinkDto) => x.rel === DELETE_CONTAINERTYPE_LINK_KEY,
  );
  if (deleteContainerTypeLink) {
    const result = await execLink(deleteContainerTypeLink, containerType);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_CONTAINERTYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
