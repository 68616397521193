import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetCountriesQuery } from '../../../models/data.models';
import {
  USER_CREATE_PATH,
  USER_EDIT_PATH,
  USERS_LIST_PATH,
} from '../users.route';
import { UsersList } from '../components/users-list.component';
import { CREATE_USER_LINK_KEY, GetUserParams } from '../users.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';

export type UsersListScreenProps = { pageTitle: string };

export const UsersListScreen = ({ pageTitle }: UsersListScreenProps) => {
  // @ts-ignore
  const {
    offset = 0,
    limit = 20,
    filter = '',
    sort = 'userName',
    search = '',
  } = useQuery() as GetCountriesQuery;
  const history = useHistory();
  const createPath = generatePath(USER_CREATE_PATH);

  const goToDetails = (userParams: GetUserParams) => {
    const path = generatePath(USER_EDIT_PATH, {
      ...userParams,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(USERS_LIST_PATH);
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(USERS_LIST_PATH);
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };
  const onSort = (field: string) => {
    const path = generatePath(USERS_LIST_PATH);
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };
  const onSearch = (query: string) => {
    const path = generatePath(USERS_LIST_PATH);
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };
  return (
    <InternalLayout
      title={'Users'}
      createPath={userHas(CREATE_USER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <UsersList
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        filter={filter}
        onFilter={onFilter}
        search={search}
        onSearch={onSearch}
      />
    </InternalLayout>
  );
};
