import {
  CustomFieldDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateCustomFieldCommandValues,
  UpdateCustomFieldCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCustomFieldRequest,
  getCustomFieldsListRequest,
  getCustomFieldRequest,
  deleteCustomFieldRequest,
  updateCustomFieldRequest,
  GetCustomFieldParams,
  recreateSystemCustomFieldsRequest,
  RecreateSystemCustomfieldsParams,
} from './customFields.service';

type CustomFieldsStoreState = {
  links: LinkDto[];
  customFieldColumns: Column[];
};

export const updateCustomFieldsColumns = createEvent<Column[]>();

export const getCustomFields = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCustomFieldsListRequest(currentOrganization, params);
});

export const createCustomField = createEffect(
  (customFieldData: CustomFieldDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createCustomFieldCommand: CreateCustomFieldCommandValues = {
      ...customFieldData,
    };

    return createCustomFieldRequest(
      currentOrganization!,
      createCustomFieldCommand,
    );
  },
);

export const getCustomField = createEffect(
  (customFieldParams: GetCustomFieldParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCustomFieldRequest(
      currentOrganization as LinkResourceBaseDto,
      customFieldParams,
    );
  },
);

export const updateCustomField = createEffect((customField: CustomFieldDto) => {
  const updateCustomFieldCommand: UpdateCustomFieldCommandValues = {
    ...customField,
  };
  return updateCustomFieldRequest(customField, updateCustomFieldCommand);
});

export const deleteCustomField = createEffect((customField: CustomFieldDto) => {
  return deleteCustomFieldRequest(customField);
});

export const recreateSystemCustomFields = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');
  const params: RecreateSystemCustomfieldsParams = {
    organizationId: currentOrganization.organizationId,
  };
  return recreateSystemCustomFieldsRequest(
    currentOrganization as LinkResourceBaseDto,
    params,
  );
});

const defaultState: CustomFieldsStoreState = {
  links: [],
  customFieldColumns: [
    {
      name: 'fieldId',
      visible: true,
      title: 'Field Id',
      sortName: 'fieldId',
    },
    {
      name: 'category',
      visible: true,
      title: 'Category',
      sortName: 'category',
    },
    {
      name: 'customFieldEntityType',
      visible: true,
      title: 'Custom Field Entity Type',
      sortName: 'customFieldEntityType',
    },
    {
      name: 'customFieldType',
      visible: true,
      title: 'Custom Field Type',
      sortName: 'customFieldType',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'displayName',
      visible: true,
      title: 'Display Name',
      sortName: 'displayName',
    },
    {
      name: 'internalName',
      visible: true,
      title: 'Internal Name',
      sortName: 'internalName',
    },
    {
      name: 'isInactive',
      visible: true,
      title: 'Is Inactive',
      sortName: 'isInactive',
    },
    {
      name: 'isSystem',
      visible: true,
      title: 'Is System',
      sortName: 'isSystem',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'createdByUserName',
      visible: false,
      title: 'Created by',
      sortName: 'CreatedUser.UserName',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
    {
      name: 'lastModifiedByUserName',
      visible: false,
      title: 'Updated by',
      sortName: 'UpdatedUser.UserName',
    },
  ],
};

export const customFieldStore: Store<CustomFieldsStoreState> = createStore(
  defaultState,
).on(updateCustomFieldsColumns, (state, payload) => {
  return { ...state, customFieldColumns: payload };
});
