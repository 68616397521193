import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { LeadListScreen } from './screens/lead-list.screen';
import { LeadScreen } from './screens/lead.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_LEADS } from '../contacts/contact.route';

export const LEADS_LIST_PATH = '/leads';
export const LEADS_EDIT_PATH = '/leads/:contactId';
export const LEADS_CREATE_PATH = '/leads/0';
export const LEADS_PATH = '/org/:organizationId/leads';

export const LeadRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${LEADS_LIST_PATH}`}>
          <LeadListScreen pageTitle="Leads" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${LEADS_CREATE_PATH}`}>
          <LeadScreen pageTitle="Add New Lead" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${LEADS_EDIT_PATH}`}>
          <LeadScreen pageTitle="Update Lead" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${CONTACTS_TAB_EDIT_PATH_FOR_LEADS}`}
        >
          <ContactScreen pageTitle="Contacts" isTab={true} />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
