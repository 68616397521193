import React, { useEffect, useState } from 'react';
import { DivisionDtoPagedResult } from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { getDivision, getDivisions } from '../divisions.store';
import { showDialog } from '../../common/dialog.store';
import { DivisionDialog } from './division.dialog';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import {
  CREATE_DIVISION_LINK_KEY,
  DIVISION_ENTITY_NAME,
  GET_DIVISION_LINK_KEY,
} from '../divisions.service';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export const SelectDivisionPaginate = ({
  id = 'divisionId',
  nameId = 'divisionName',
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  defaultValueFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeDivision = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  readonly = false,
  classNamePrefix = 'select',
  showCreateDialogTitle = 'Create Division',
}): JSX.Element => {
  const [value, setValue] = useState(null);
  const context = useFormikContext();
  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  useEffect(() => {
    if (!selectedValue) {
      setDefaultDivision();
    }
  }, []);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseContactDtoPagedResult: DivisionDtoPagedResult = await getDivisions(
      {
        limit: 10,
        offset: (page - 1) * 10,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseContactDtoPagedResult.items,
      hasMore: responseContactDtoPagedResult.items.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeDivision === 'function') {
      onChangeDivision(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };

  const setDefaultDivision = () => {
    getDivisions({
      limit: 1,
      offset: 0,
      sort,
      filter: defaultValueFilter,
    }).then(
      (divisions) => {
        if (divisions.items.length > 0) {
          const defaultDivision = divisions.items[0];
          setValue(defaultDivision);
          onSelect(defaultDivision);
        }
      },
      () => {},
    );
  };

  const onSelect = (division) => {
    onChange(division, context);
    if (context) {
      if (isMulti === true) {
        if (division?.length > 0) {
          context.setFieldValue(id, division?.length);
          context.setFieldError(id, undefined);
        } else {
          if (required) {
            context.setFieldValue(id, '');
          }
        }
      } else {
        context.setFieldValue(id, division?.divisionId);
        context.setFieldValue(nameId, division?.divisionName);
        context.setFieldError(id, undefined);
        if (required && !division) {
          context.setFieldValue(id, '');
        }
      }
    }
  };

  const [clearLoadOptionsCache, setClearLoadOptionsCache] = useState(false);

  const onDivisionEditIconClick = () => {
    if (value?.divisionId) {
      showDialog({
        dialog: DivisionDialog,
        props: {
          divisionId: value.divisionId,
          title: 'Update Division',
        },
      }).then(
        (division) => {
          if (division !== null) {
            getDivision({
              divisionId: Number(value.divisionId) || null,
            }).then(
              (divisionDto) => {
                onSelect(divisionDto);
                setClearLoadOptionsCache(!clearLoadOptionsCache);
              },
              () => {},
            );
          }
        },
        () => {},
      );
    }
  };

  if (useContext) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
                {!isMulti && value && userHas(GET_DIVISION_LINK_KEY) ? (
                  <div
                    className="menu-icon-wrapper d-flex icon-edit-entity"
                    onClick={onDivisionEditIconClick}
                    data-cy={`division-edit-icon`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </div>
                ) : null}
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              className={id}
              entityName={DIVISION_ENTITY_NAME}
              canCreateNewEntity={userHas(CREATE_DIVISION_LINK_KEY)}
              onCreateNewEntity={() => {
                return showDialog({
                  dialog: DivisionDialog,
                  props: {
                    title: showCreateDialogTitle,
                    divisionId: 0,
                  },
                }).then(
                  (division) => {
                    setClearLoadOptionsCache(!clearLoadOptionsCache);
                    return division;
                  },
                  () => {},
                );
              }}
              key={filter}
              value={value}
              loadOptions={loadOptions}
              getOptionValue={(option) => option?.divisionId}
              getOptionLabel={(option) => `${option?.divisionName}`}
              onChange={onSelect}
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true)}
              isDisabled={isDisabled}
              isClearable={readonly ? false : isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={readonly ? false : isSearchable}
              placeholder={placeholder}
              menuIsOpen={readonly ? false : undefined}
              classNamePrefix={classNamePrefix}
              components={{
                IndicatorSeparator: () => null,
              }}
              additional={{
                page: 1,
              }}
              cacheUniqs={[clearLoadOptionsCache]}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={DIVISION_ENTITY_NAME}
        canCreateNewEntity={userHas(CREATE_DIVISION_LINK_KEY)}
        onCreateNewEntity={() => {
          return showDialog({
            dialog: DivisionDialog,
            props: {
              title: showCreateDialogTitle,
              divisionId: 0,
            },
          }).then(
            (division) => {
              setClearLoadOptionsCache(!clearLoadOptionsCache);
              return division;
            },
            () => {},
          );
        }}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.divisionId}
        getOptionLabel={(option) => `${option?.divisionName}`}
        onChange={(division?: any) => {
          onChange(division, context);
        }}
        onFocus={clearMessages}
        classNamePrefix={classNamePrefix}
        menuIsOpen={readonly ? false : undefined}
        isDisabled={isDisabled}
        isClearable={readonly ? false : isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={readonly ? false : isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
