import { Dialog } from '../../common/components/dialog/dialog.component';
import {
  AttachmentDto,
  AttachmentParentType,
} from '../../../models/data.models';
import { AddAttachments } from './add-attachments.component';

export type AddAttachmentsDialogProperties = {
  parentId: number;
  parentType: AttachmentParentType;
  files?: File[] | null;
  title: string;
};

export class AddAttachmentsDialog extends Dialog<AddAttachmentsDialogProperties> {
  constructor(props: AddAttachmentsDialogProperties) {
    super(props);
    this.onAttachmentSaved = this.onAttachmentSaved.bind(this);
  }

  onAttachmentSaved = (attachments: AttachmentDto[]) => {
    this.close(attachments);
  };

  renderContent(): any {
    const { files, parentId, parentType } = this
      .props as AddAttachmentsDialogProperties;
    return (
      <AddAttachments
        files={files}
        parentId={parentId}
        parentType={parentType}
        onAttachmentsCreated={this.onAttachmentSaved}
        onAttachmentsUpdated={this.onAttachmentSaved}
      />
    );
  }
}
