import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetContainerDescriptionsQuery } from '../../../models/data.models';
import {
  CONTAINER_DESCRIPTION_EDIT_PATH,
  CONTAINER_DESCRIPTIONS_LIST_PATH,
  CONTAINER_DESCRIPTION_CREATE_PATH,
} from '../containerDescriptions.route';
import { ContainerDescriptionsList } from '../components/containerDescriptions-list.component';
import {
  CREATE_CONTAINERDESCRIPTION_LINK_KEY,
  GetContainerDescriptionParams,
} from '../containerDescriptions.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';

export type ContainerDescriptionsListScreenProps = { pageTitle: string };

export const ContainerDescriptionsListScreen = ({
  pageTitle,
}: ContainerDescriptionsListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = 'containerDescriptionCode',
    filter = '',
    search = '',
  } = useQuery() as GetContainerDescriptionsQuery;
  const history = useHistory();

  const createPath = (): string => {
    return generatePath(INTERNAL_PATH + CONTAINER_DESCRIPTION_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = (
    containerDescriptionParams: GetContainerDescriptionParams,
  ) => {
    const path = generatePath(INTERNAL_PATH + CONTAINER_DESCRIPTION_EDIT_PATH, {
      organizationId,
      containerDescriptionCode:
        containerDescriptionParams.containerDescriptionCode,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(
      INTERNAL_PATH + CONTAINER_DESCRIPTIONS_LIST_PATH,
      {
        organizationId,
      },
    );
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(
      INTERNAL_PATH + CONTAINER_DESCRIPTIONS_LIST_PATH,
      {
        organizationId,
      },
    );
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(
      INTERNAL_PATH + CONTAINER_DESCRIPTIONS_LIST_PATH,
      {
        organizationId,
      },
    );
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(
      INTERNAL_PATH + CONTAINER_DESCRIPTIONS_LIST_PATH,
      {
        organizationId,
      },
    );
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };
  return (
    <InternalLayout
      title={'Container Descriptions'}
      createPath={
        userHas(CREATE_CONTAINERDESCRIPTION_LINK_KEY) ? createPath() : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ContainerDescriptionsList
        search={search}
        onSearch={onSearch}
        offset={offset}
        limit={limit}
        sort={sort}
        filter={filter}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
      />
    </InternalLayout>
  );
};
