import React, { CSSProperties, useState } from 'react';
import { FormContext } from '../form/form.component';
import { ErrorMessage, Field, FormikProps, FormikValues } from 'formik';
import { clearMessages } from '../../messages.store';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { FaMapMarkerAlt } from 'react-icons/fa';

export type PlaceAutocompleteInputProps = {
  name?: string;
  header?: string;
  placeholder?: string;
  defaultValue?: string;
  onSelect?: (results: any, context: FormikProps<FormikValues>) => string;
  customStyles?: CSSProperties;
  lat?: number;
  lng?: number;
  renderDropdown?: boolean;
  onChange?: () => void;
};

export const PlaceAutocompleteInput = ({
  name,
  header,
  placeholder = '',
  defaultValue = '',
  onSelect,
  customStyles = {},
  lat,
  lng,
  renderDropdown = true,
  onChange = () => {},
}: PlaceAutocompleteInputProps) => {
  const [stateValue, setStateValue] = useState(defaultValue);
  return (
    <FormContext.Consumer>
      {(context) => (
        <>
          <Field type="hidden" name={name} value={stateValue} />
          <PlacesAutocomplete
            value={stateValue}
            onChange={(address) => {
              setStateValue(address);
              context.setFieldValue(name, address);
            }}
            onSelect={
              onSelect &&
              ((address: string, placeId?: string, suggestion?: object) => {
                if (placeId && suggestion) {

                  geocodeByPlaceId(placeId)
                    .then((results) => {
                      console.log(results);
                      setStateValue(
                        onSelect({ ...results[0], suggestion }, context),
                      );
                    })
                    .catch((error) => console.error(error));
                }
              })
            }
            searchOptions={{
              language: 'en',
              offset: 0,
              types: ['address'],
              componentRestrictions: { country: 'us' },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              return (
                <div className="form-group ">
                  <label className="input-label" htmlFor={name}>
                    <span className="input-label-primary">{header}</span>
                  </label>
                  <div className="form-group d-flex align-items-center">
                    <input
                      {...getInputProps({
                        name,
                        id: name,
                        placeholder,
                        className:
                          'location-search-input form-control form-control-md mr-2',
                        onFocus: () => {
                          clearMessages();
                          onChange();
                        },
                      })}
                    />
                    <a
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                      style={
                        !lat || !lng
                          ? {
                              pointerEvents: 'none',
                              cursor: 'not-allowed',
                              textDecoration: 'none',
                            }
                          : null
                      }
                    >
                      <FaMapMarkerAlt size={24} title="Open in Google Maps" />
                    </a>
                  </div>

                  {suggestions.length > 0 && renderDropdown ? (
                    <div
                      className="autocomplete-dropdown-container"
                      style={customStyles}
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              title: suggestion.description,
                            })}
                          >
                            <span>
                              <span className="suggestion-item-main">
                                {suggestion.formattedSuggestion.mainText}
                              </span>{' '}
                              <span className="suggestion-item-secondary">
                                {suggestion.formattedSuggestion.secondaryText}{' '}
                              </span>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            }}
          </PlacesAutocomplete>
          <ErrorMessage
            name={name}
            component="div"
            className="invalid-feedback"
          />
        </>
      )}
    </FormContext.Consumer>
  );
};
