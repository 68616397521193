import {
  PaymentTermDto,
  LinkDto,
  LinkResourceBaseDto,
  PaymentTermDtoPagedResult,
  CreatePaymentTermCommandValues,
  UpdatePaymentTermCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_PAYMENTTERMS_LINK_KEY = 'get-paymentterms';
export const CREATE_PAYMENTTERM_LINK_KEY = 'create-paymentterm';
export const GET_PAYMENTTERM_LINK_KEY = 'get-paymentterm';
export const UPDATE_PAYMENTTERM_LINK_KEY = 'update-paymentterm';
export const DELETE_PAYMENTTERM_LINK_KEY = 'delete-paymentterm';
export const DOWNLOAD_QB_PAYMENTTERMS_LINK_KEY = 'download-qbpaymentterms';

export const PAYMENT_TERM_ENTITY_NAME = 'Payment Term';

export interface GetPaymentTermParams {
  paymentTermId: number;
}

export const getPaymentTermRequest = async (
  resource: LinkResourceBaseDto | null,
  { paymentTermId }: GetPaymentTermParams,
): Promise<PaymentTermDto> => {
  if (resource && paymentTermId) {
    const getPaymentTermLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PAYMENTTERM_LINK_KEY,
    );
    if (getPaymentTermLink) {
      const result = await execLink(getPaymentTermLink, { paymentTermId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PAYMENTTERM_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getPaymentTermsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PaymentTermDtoPagedResult> => {
  if (resource) {
    const getPaymentTermsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PAYMENTTERMS_LINK_KEY,
    );
    if (getPaymentTermsListLink) {
      const result = await execLink(getPaymentTermsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PAYMENTTERMS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createPaymentTermRequest = async (
  resource: LinkResourceBaseDto,
  paymentTerm: CreatePaymentTermCommandValues,
) => {
  const createPaymentTermLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PAYMENTTERM_LINK_KEY,
  );
  if (createPaymentTermLink) {
    const result = await execLink(createPaymentTermLink, paymentTerm);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_PAYMENTTERM_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updatePaymentTermRequest = async (
  resource: LinkResourceBaseDto,
  paymentTerm: UpdatePaymentTermCommandValues,
) => {
  const updatePaymentTermLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PAYMENTTERM_LINK_KEY,
  );
  if (updatePaymentTermLink) {
    const result = await execLink(updatePaymentTermLink, paymentTerm);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_PAYMENTTERM_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deletePaymentTermRequest = async (paymentTerm: PaymentTermDto) => {
  const deletePaymentTermLink = paymentTerm.links?.find(
    (x: LinkDto) => x.rel === DELETE_PAYMENTTERM_LINK_KEY,
  );
  if (deletePaymentTermLink) {
    const result = await execLink(deletePaymentTermLink, paymentTerm);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_PAYMENTTERM_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const downloadQuickBooksPaymentTermsRequest = async (
  resource?: LinkResourceBaseDto | null,
): Promise<any> => {
  if (resource) {
    const downloadQuickBooksPaymentTermsLink = resource?.links?.find(
      (x: LinkDto) => x.rel === DOWNLOAD_QB_PAYMENTTERMS_LINK_KEY,
    );
    if (downloadQuickBooksPaymentTermsLink) {
      const result = await execLink(downloadQuickBooksPaymentTermsLink);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${DOWNLOAD_QB_PAYMENTTERMS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
