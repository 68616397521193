import {
  OrderTrackingDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateOrderTrackingCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createOrderTrackingRequest,
  getOrderTrackingsListRequest,
  getOrderTrackingRequest,
  GetOrderTrackingParams,
} from './orderTrackings.service';

type OrderTrackingsStoreState = {
  links: LinkDto[];
  orderTrackingColumns: Column[];
  orderTrackingRoutesColumns: Column[];
  orderTrackingEventsColumns: Column[];
};

export const updateOrderTrackingsColumns = createEvent<Column[]>();

export const getOrderTrackings = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getOrderTrackingsListRequest(currentOrganization, params);
});

export const createOrderTracking = createEffect(
  (orderTrackingData: OrderTrackingDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createOrderTrackingCommand: CreateOrderTrackingCommandValues = {
      ...orderTrackingData,
    };

    return createOrderTrackingRequest(
      currentOrganization!,
      createOrderTrackingCommand,
    );
  },
);

export const getOrderTracking = createEffect(
  (orderTrackingParams: GetOrderTrackingParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getOrderTrackingRequest(
      currentOrganization as LinkResourceBaseDto,
      orderTrackingParams,
    );
  },
);

const defaultState: OrderTrackingsStoreState = {
  links: [],
  orderTrackingColumns: [
    { name: 'carrierContactName', visible: true, title: 'Carrier' },
    { name: 'driverContactName', visible: true, title: 'Driver' },
    { name: 'phoneNumber', visible: true, title: 'Phone Number' },
    { name: 'emailAddress', visible: true, title: 'Email' },
    { name: 'created', visible: true, title: 'Send On' },
    {
      name: 'orderTrackingStatus',
      visible: true,
      title: 'Status',
    },
  ],
  orderTrackingRoutesColumns: [
    {
      name: 'type',
      visible: true,
      title: 'Type',
    },
    {
      name: 'name',
      visible: true,
      title: 'Name',
    },
    { name: 'address', visible: true, title: 'Address' },
    {
      name: 'date',
      visible: true,
      title: 'Date',
    },
    {
      name: 'notes',
      visible: true,
      title: 'Notes',
    },
  ],
  orderTrackingEventsColumns: [
    {
      name: 'eventDate',
      visible: true,
      title: 'Event date',
    },
    {
      name: 'eventName',
      visible: true,
      title: 'Event type',
    },
    { name: 'location', visible: true, title: 'Location' },
    {
      name: 'description',
      visible: true,
      title: 'Details',
    },
  ],
};

export const orderTrackingStore: Store<OrderTrackingsStoreState> = createStore(
  defaultState,
).on(updateOrderTrackingsColumns, (state, payload) => {
  return { ...state, orderTrackingColumns: payload };
});
