import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import { PaymentDto, PaymentDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  paymentStore,
  getPayments,
  deletePayment,
  updatePaymentsColumns,
} from '../payments.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_PAYMENT_LINK_KEY,
  DELETE_PAYMENT_LINK_KEY,
  GetPaymentParams,
} from '../payments.service';
import { PaymentDialog } from './payment.dialog';
import {
  getFormattedDate,
  getFormattedPrice,
} from '../../../utils/formatting.utils';
import { userHas } from '../../auth/auth.store';
import { useParams } from 'react-router-dom';

export type PaymentsListProps = {
  goToDetails?: (paymentParams: GetPaymentParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (payment: PaymentDto, paymentParams: GetPaymentParams) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const PaymentsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch,
}: PaymentsListProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const [payments, setPayments] = useState<PaymentDtoPagedResult | null>(null);

  const getData = ({ offset, limit, sort, filter, search }) => {
    getPayments({ offset, limit, sort, filter, search }).then(
      (paymentsData) => {
        paymentsData.items.forEach((paymentDto) => {
          paymentDto.created = getFormattedDate(paymentDto.created);
          paymentDto.lastModified = getFormattedDate(paymentDto.lastModified);
          paymentDto.paymentDate = getFormattedDate(
            paymentDto.paymentDate,
            false,
          );
          paymentDto.amountReceived = getFormattedPrice(
            Number(paymentDto.amountReceived),
            paymentDto.currencyDecimals,
            paymentDto.currencySymbol,
          );
        });
        setPayments(paymentsData);
      },
    );
  };

  useEffect(() => {
    getData({ offset, limit, sort, filter, search });
  }, [offset, limit, sort, filter, search]);

  const { paymentColumns: columns } = useStore(paymentStore);

  const onDeletePayment = (payment: PaymentDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${payment.paymentId} Payment`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deletePayment(payment).then(() => {
          onDelete();
          getData({ offset, limit, sort, filter, search });
        });
      }
    });
  };

  const onEditPayment = (payment: PaymentDto) => {
    goToDetails({ paymentId: payment.paymentId });
  };

  const onCreateNewPayment = () => {
    goToDetails({ paymentId: 0 });
  };

  const onPaymentSelect = (payment, paymentId) => {
    if (onSelect) onSelect(payment, paymentId);
    showDialog({
      dialog: PaymentDialog,
      props: {
        paymentId: payment.paymentId,
        title: 'Update Payment',
        organizationId,
      },
    }).then((payment) => {
      if (payment !== null) {
        getData({ offset, limit, sort, filter, search });
      }
    });
  };

  return (
    <>
      <Grid
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={['paymentId']}
        data={payments?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={payments?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_PAYMENT_LINK_KEY, payments?.items[0]?.links)
            ? onDeletePayment
            : null
        }
        onSort={onSort}
        onEdit={onEditPayment}
        onPageChanged={onPageChanged}
        onColumnsChanged={updatePaymentsColumns}
        onSelect={onPaymentSelect}
        filter={filter}
        onFilter={onFilter}
      />
      {!(
        (payments && payments.items && payments.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">You Don't Have Any Payments Yet</h3>
          {userHas(CREATE_PAYMENT_LINK_KEY) && (
            <Button size={'lg'} color="primary" onClick={onCreateNewPayment}>
              Create Payment
            </Button>
          )}
        </div>
      )}
    </>
  );
};
