import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  EquipmentTypeDto,
  EquipmentTypeDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  equipmentTypeStore,
  getEquipmentTypes,
  deleteEquipmentType,
  updateEquipmentTypesColumns,
} from '../equipmentType.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_EQUIPMENTTYPE_LINK_KEY,
  DELETE_EQUIPMENTTYPE_LINK_KEY,
  GetEquipmentTypeParams,
} from '../equipmentType.service';
import { EquipmentTypeDialog } from './equipmentType.dialog';
import { userHas } from '../../auth/auth.store';

export type EquipmentTypesListProps = {
  goToDetails?: (equipmentTypeParams: GetEquipmentTypeParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSearch?: (query: string) => void;
  onSelect?: (
    equipmentType: EquipmentTypeDto,
    equipmentTypeParams: GetEquipmentTypeParams,
  ) => void;
  onFilter?: (query: string) => void;
};

export const EquipmentTypesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  filter = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSearch = () => {},
  onSelect,
  onFilter = () => {},
}: EquipmentTypesListProps) => {
  const [
    equipmentTypes,
    setEquipmentTypes,
  ] = useState<EquipmentTypeDtoPagedResult | null>(null);

  useEffect(() => {
    getEquipmentTypesData();
  }, [offset, limit, sort, filter, search]);

  const getEquipmentTypesData = () => {
    getEquipmentTypes({ offset, limit, sort, filter, search }).then(
      (equipmentTypesData) => {
        setEquipmentTypes(equipmentTypesData);
      },
      () => {},
    );
  };

  const { equipmentTypeColumns: columns } = useStore(equipmentTypeStore);

  const onDeleteEquipmentType = (equipmentType: EquipmentTypeDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${equipmentType.equipmentTypeId} Equipment Type`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteEquipmentType(equipmentType).then(
            () => {
              getEquipmentTypesData();
              onDelete();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditEquipmentType = (equipmentType: EquipmentTypeDto) => {
    goToDetails({ equipmentTypeId: equipmentType.equipmentTypeId });
  };

  const onCreateNewEquipmentType = () => {
    goToDetails({ equipmentTypeId: 0 });
  };
  const onEquipmentTypeSelect = (equipmentType, equipmentTypeId) => {
    if (onSelect) onSelect(equipmentType, equipmentTypeId);
    showDialog({
      dialog: EquipmentTypeDialog,
      props: {
        equipmentTypeId: equipmentType.equipmentTypeId,
        title: 'Update Equipment Type',
      },
    }).then(
      (equipmentType) => {
        if (equipmentType !== null) {
          getEquipmentTypesData();
        }
      },
      () => {},
    );
  };

  return (
    <>
      <Grid
        rowKeys={null}
        showAllStore={true}
        data={equipmentTypes?.items}
        columns={columns}
        offset={offset}
        showEmptyTable={true}
        limit={limit}
        total={equipmentTypes?.totalCount}
        sort={sort}
        onDelete={
          userHas(
            DELETE_EQUIPMENTTYPE_LINK_KEY,
            equipmentTypes?.items[0]?.links,
          )
            ? onDeleteEquipmentType
            : null
        }
        onSort={onSort}
        onEdit={onEditEquipmentType}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateEquipmentTypesColumns}
        onSelect={onEquipmentTypeSelect}
        onSearch={onSearch}
        search={search}
      />
      {!(
        equipmentTypes &&
        equipmentTypes.items &&
        equipmentTypes.items.length
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Equipment Type Yet
          </h3>
          {userHas(CREATE_EQUIPMENTTYPE_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={onCreateNewEquipmentType}
            >
              Create Equipment Type
            </Button>
          )}
        </div>
      )}
    </>
  );
};
