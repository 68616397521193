import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { EventDefinitionsListScreen } from './screens/eventDefinitions-list.screen';
import { EventDefinitionScreen } from './screens/eventDefinitions.screen';

export const EVENTDEFINITIONS_LIST_PATH = '/eventDefinitions';
export const EVENTDEFINITIONS_EDIT_PATH =
  '/eventDefinitions/:eventDefinitionId';
export const EVENTDEFINITIONS_CREATE_PATH = '/eventDefinitions/0';
export const EVENTDEFINITIONS_PATH = '/org/:organizationId/eventDefinitions';

export const EventDefinitionRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${EVENTDEFINITIONS_LIST_PATH}`}>
          <EventDefinitionsListScreen pageTitle="Event Definitions" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${EVENTDEFINITIONS_CREATE_PATH}`}
        >
          <EventDefinitionScreen pageTitle="Add New Event Definition" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${EVENTDEFINITIONS_EDIT_PATH}`}>
          <EventDefinitionScreen pageTitle="Update Event Definition" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
