import axios from 'axios';
import { API_ENDPOINT } from '../../properties';
import { LinkDto } from '../../models/data.models';
import URITemplate from 'urijs/src/URITemplate';
import { v4 } from 'uuid';
import {
  addRequest,
  authError,
  removeRequest,
  requestError,
} from './api.store';
import { createEffect } from 'effector';

export const init = () => {
  return axios.get(`${API_ENDPOINT}/api`).then((result) => {
    return result.data;
  });
};

export const execLink = (link: LinkDto, data?: any) => {
  if (link.href) {
    const url = link.templated
      ? URITemplate(link.href).expand(data).toString()
      : link.href;
    if (link.type?.toLowerCase() !== 'get') {
      return axios.request({
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        url,
        // @ts-ignore
        method: link.type,
        data,
      });
    }
    return axios.request({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      url,
      method: 'GET',
    });
  }
  return Promise.reject(new Error("Link url can't be null"));
};

export const postFormData = (link: LinkDto, data?: any, files?: File[]) => {
  if (link.href) {
    const url = link.templated
      ? URITemplate(link.href).expand(data).toString()
      : link.href;
    const formData = new FormData();
    if (data) formData.append('data', JSON.stringify(data));
    if (files?.length == 1) {
      formData.append('file', files[0]);
    } else if (files?.length > 1) {
      files.forEach((file, index) => formData.append(`file${index}`, file));
    }
    return axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  return Promise.reject(new Error("Link url can't be null"));
};

export const execLinkFile = (link: LinkDto, data?: any) => {
  if (link.href) {
    const url = link.templated
      ? URITemplate(link.href).expand(data).toString()
      : link.href;
    if (link.type?.toLowerCase() !== 'get') {
      return axios.request({
        responseType: 'blob',
        url,
        // @ts-ignore
        method: link.type,
        data,
      });
    }
    return axios.request({
      responseType: 'blob',
      url,
      method: 'GET',
    });
  }
  return Promise.reject(new Error("Link url can't be null"));
};

export const postForm = (url: string, params?: any) => {
  const formData = new FormData();
  const keys = Object.keys(params);
  keys.forEach((key) => {
    const value = params[key];
    formData.append(key, value);
  });

  return axios({
    method: 'post',
    url,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
export const postURLSearchParams = (url: string, params?: any) => {
  const URLSearchParam = new URLSearchParams();
  const keys = Object.keys(params);
  keys.forEach((key) => {
    const value = params[key];
    URLSearchParam.append(key, value);
  });

  return axios({
    method: 'post',
    url,
    data: URLSearchParam,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};

function progressInterceptor(req: any): any {
  if ((!req.params || !req.params.silent) && (!req.data || !req.data.silent)) {
    const requestId = v4();
    req.requestId = requestId;
    addRequest({ requestId });
  } else {
    if (req.params && req.params.silent) {
      delete req.params.silent;
    }
    if (req.data && req.data.silent) {
      delete req.data.silent;
    }
  }
  return req;
}

function responseInterceptor(res: any): any {
  if (res.config && res.config.requestId) {
    removeRequest(res.config.requestId);
  }
  return res;
}

async function errorInterceptor(error: any) {
  if (
    error.response &&
    error.response.config &&
    error.response.config.requestId
  ) {
    removeRequest(error.response.config.requestId);
  }
  if (error.response && error.response.status === 401) {
    const result = await handleExpiredToken(error.config);
    if (result.success) return result.data;
  }
  if (error.response && error.response.status !== 401) {
    requestError(error.response);
  }
  return Promise.reject(error);
}

async function handleExpiredToken(request) {
  const result = await renewToken()
    .then((result) => {
      return axios.request(request).catch((error) => {
        if (error.response && error.response.status === 401) {
          authError();
        } else {
          requestError(request);
        }
      });
    })
    .catch((error) => {
      authError();
    });
  return { success: result !== undefined, data: result };
}

axios.interceptors.request.use(progressInterceptor);
axios.interceptors.response.use(responseInterceptor, errorInterceptor);

function authInterceptor(req: any): any {
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  if (token) {
    req.headers['Authorization'] = `Bearer ${token}`;
  }
  return req;
}

export function getBearerToken() {
  return localStorage.getItem('token') || sessionStorage.getItem('token');
}

axios.interceptors.request.use(authInterceptor);

export function addRequestInterceptor(fn: (error: any) => any) {
  axios.interceptors.request.use(fn);
}

export function addErrorInterceptor(fn: (error: any) => any) {
  axios.interceptors.response.use((res) => res, fn);
}

export const renewToken = createEffect(() => {
  const refreshToken =
    localStorage.getItem('refreshToken') ||
    sessionStorage.getItem('refreshToken');
  if (refreshToken)
    return postURLSearchParams(`${API_ENDPOINT}/connect/token`, {
      grant_type: 'refresh_token',
      client_id: 'Seahorse.TMS.ClientApp',
      refresh_token: refreshToken,
    });
  return Promise.reject({ message: 'User is not login' });
});
