import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectAccountingAccountPaginate } from './accountingAccount-react-select.component';

export const AccountingAccountForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      innerRef={innerRef}
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

AccountingAccountForm.AccountId = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'accountId'}
      label={'Account Id'}
      placeholder={'Account Id'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AccountingAccountForm.AccountName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'accountName'}
      label={'Account Name'}
      placeholder={'Account Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AccountingAccountForm.AccountNumber = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'accountNumber'}
      label={'Account Number'}
      placeholder={'Account Number'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AccountingAccountForm.IsInactive = ({
  className,
  style,
  size,
  disabled,
  required = true,
}: InputPublicProps) => {
  return (
    <Input
      required={required}
      name={'isInactive'}
      id={'isInactiveAccountCheckbox'}
      label={'Is Inactive'}
      className={className}
      style={style}
      placeholder={'Is Inactive'}
      type={'checkbox'}
      size={size}
    />
  );
};
AccountingAccountForm.AccountTypeSelect = ({
  onChange,
  options,
  name = 'accountType',
  id = 'accountType',
  placeholder = 'Select Account Type',
  required = true,
  multiple = false,
  disabled = false,
  header,
  defaultValue = '',
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};
AccountingAccountForm.ParentAccountSelect = ({
  selectedFilter,
  onChange,
  id = 'parentAccountId',
  nameId = 'parentAccountName',
  header = 'Parent Account',
  required = true,
  placeholder,
  readonly,
  disabled,
  sort = 'accountName',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectAccountingAccountPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeAccountingAccount={onChange}
    />
  );
};
