import React, { useState } from 'react';
import { ContactForm } from '../../../contacts/components/contact.form';
import { ReactEnumInputSelect } from './enum-input.select';
import { InputProps } from './input.component';
import { CarrierCodesForSelect } from '../../../../models/data.models';
import { FormContext } from '../form/form.component';

export const MCFFInput = ({
  name,
  readonly,
  required = false,
  style = {},
  className = '',
  id = '',
  options = null,
  header,
  onClick,
  disabled = false,
}: InputProps) => {
  const [mcffDefaultValue, setMcffDefaultValue] = useState(options[0]);
  return (
    <FormContext.Consumer>
      {(context) => (
        <div className="form-group">
        <div className="d-flex align-items-end">
          <div className="col-2 p-0">
            <ReactEnumInputSelect
              name={name}
              id={id}
              error={false}
              isSearchable={false}
              isClearable={false}
              readonly={readonly}
              header={header}
              placeholder={'Select'}
              required={required}
              defaultValue={mcffDefaultValue}
              multiple={false}
              disabled={false}
              onChange={(value) => setMcffDefaultValue(value)}
              options={options}
              useContext={false}
              customStyles={{
                control: (provided, state) => {
                  return {
                    ...provided,
                    minHeight: '2.2rem !important',
                  };
                },
              }}
            />
          </div>
          <div
            className={`form-group m-0 ml-2 pl-0 w-100 d-flex align-items-center justify-content-between ${className}`}
            style={style}
          >
            {mcffDefaultValue.value === 'MC' ? (
              <ContactForm.MCCode
                style={{ margin: '0 15px 0 0', flexBasis: '40%' }}
              />
            ) : (
              <ContactForm.FFCode
                style={{ margin: '0 15px 0 0', flexBasis: '40%' }}
              />
            )}
            <a
              className="regular-link"
              href="https://li-public.fmcsa.dot.gov/LIVIEW/pkg_html.prc_lisearch"
              target="_blank"
              style={{ margin: '0 15px 0 0' }}
            >
              FMCSA
            </a>
            <a
              className="regular-link"
              style={{ pointerEvents: disabled ? 'none' : 'auto' }}
              onClick={() =>
                onClick(
                  mcffDefaultValue.value === 'MC'
                    ? CarrierCodesForSelect.MC
                    : CarrierCodesForSelect.FF,
                  context,
                )
              }
            >
              MC / FF Import
            </a>
          </div>
        </div>
        </div>
      )}
    </FormContext.Consumer>
  );
};
