import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  ContainerDescriptionDto,
  ContainerDescriptionDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  containerDescriptionStore,
  getContainerDescriptions,
  deleteContainerDescription,
  updateContainerDescriptionsColumns,
} from '../containerDescriptions.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_CONTAINERDESCRIPTION_LINK_KEY,
  DELETE_CONTAINERDESCRIPTION_LINK_KEY,
  GetContainerDescriptionParams,
} from '../containerDescriptions.service';
import { ContainerDescriptionDialog } from './containerDescription.dialog';
import { userHas } from '../../auth/auth.store';

export type ContainerDescriptionsListProps = {
  goToDetails?: (
    containerDescriptionParams: GetContainerDescriptionParams,
  ) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSelect?: (
    containerDescription: ContainerDescriptionDto,
    containerDescriptionParams: GetContainerDescriptionParams,
  ) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const ContainerDescriptionsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  filter = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: ContainerDescriptionsListProps) => {
  const [
    containerDescriptions,
    setContainerDescriptions,
  ] = useState<ContainerDescriptionDtoPagedResult | null>(null);

  useEffect(() => {
    getContainerDescriptionsData();
  }, [offset, limit, sort, filter, search]);
  const getContainerDescriptionsData = () => {
    getContainerDescriptions({ offset, limit, sort, filter, search }).then(
      (containerDescriptionsData) => {
        setContainerDescriptions(containerDescriptionsData);
      },
    );
  };

  const { containerDescriptionColumns: columns } = useStore(
    containerDescriptionStore,
  );

  const onDeleteContainerDescription = (
    containerDescription: ContainerDescriptionDto,
  ) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${containerDescription.containerDescriptionCode} Container Description`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteContainerDescription(containerDescription).then(() => {
          onDelete();
          getContainerDescriptionsData();
        });
      }
    });
  };

  const onEditContainerDescription = (
    containerDescription: ContainerDescriptionDto,
  ) => {
    goToDetails({
      containerDescriptionCode: containerDescription.containerDescriptionCode,
    });
  };

  const onCreateNewContainerDescription = () => {
    goToDetails({ containerDescriptionCode: null });
  };
  const onContainerDescriptionSelect = (
    containerDescription,
    containerDescriptionId,
  ) => {
    if (onSelect) onSelect(containerDescription, containerDescriptionId);
    showDialog({
      dialog: ContainerDescriptionDialog,
      props: {
        containerDescriptionCode: containerDescription.containerDescriptionCode,
        title: 'Update Container Description',
      },
    }).then((containerDescription) => {
      if (containerDescription !== null) {
        getContainerDescriptionsData();
      }
    });
  };

  return (
    <>
      <Grid
        isDropDownList={isDropDownList}
        showEmptyTable={true}
        search={search}
        onSearch={onSearch}
        showAllStore={true}
        rowKeys={['containerDescriptionCode']}
        data={containerDescriptions?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        filter={filter}
        onFilter={onFilter}
        total={containerDescriptions?.totalCount}
        sort={sort}
        onDelete={
          userHas(
            DELETE_CONTAINERDESCRIPTION_LINK_KEY,
            containerDescriptions?.items[0]?.links,
          )
            ? onDeleteContainerDescription
            : null
        }
        onSort={onSort}
        onEdit={onEditContainerDescription}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateContainerDescriptionsColumns}
        onSelect={onContainerDescriptionSelect}
      />
      {!(
        (containerDescriptions &&
          containerDescriptions.items &&
          containerDescriptions.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Container Description Yet
          </h3>
          {userHas(CREATE_CONTAINERDESCRIPTION_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={onCreateNewContainerDescription}
            >
              Create Container Description
            </Button>
          )}
        </div>
      )}
    </>
  );
};
