import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { VendorEdit } from '../components/vendor-edit.component';
import { VENDORS_CREATE_PATH, VENDORS_LIST_PATH } from '../vendor.route';
import { addMessage, Message } from '../../common/messages.store';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import { CREATE_CONTACT_LINK_KEY } from '../../contacts/contacts.service';
import { userHas } from '../../auth/auth.store';

export type VendorScreenProps = { pageTitle: string };

export const VendorScreen = ({ pageTitle }: VendorScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, contactId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + VENDORS_CREATE_PATH, {
      organizationId,
    });
  }
  const onVendorCreated = () => {
    const path = generatePath(INTERNAL_PATH + VENDORS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'vendor-created',
      type: 'success',
      autoHide: true,
      message: 'Vendor successfully created!',
    };
    history.push(path);
    addMessage(message);
  };
  const onVendorUpdated = () => {
    const path = generatePath(INTERNAL_PATH + VENDORS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'vendor-updated',
      type: 'success',
      autoHide: true,
      message: 'Vendor successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + VENDORS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };
  return (
    <InternalLayout
      title={'Vendors'}
      createPath={userHas(CREATE_CONTACT_LINK_KEY) ? createPath() : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <VendorEdit
        contactId={contactId}
        onVendorUpdated={onVendorUpdated}
        onVendorCreated={onVendorCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
