import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ContainerTypesListScreen } from './screens/containerTypes-list.screen';
import { ContainerTypeScreen } from './screens/containerType.screen';

export const CONTAINER_TYPES_LIST_PATH = '/container-types';
export const CONTAINER_TYPE_EDIT_PATH = '/container-types/:containerTypeCode';
export const CONTAINER_TYPE_CREATE_PATH = '/container-types/0';
export const CONTAINER_TYPES_PATH = '/org/:organizationId/container-types';

export const ContainerTypesRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${CONTAINER_TYPES_LIST_PATH}`}>
          <ContainerTypesListScreen pageTitle="Container Types" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CONTAINER_TYPE_CREATE_PATH}`}>
          <ContainerTypeScreen />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CONTAINER_TYPE_EDIT_PATH}`}>
          <ContainerTypeScreen />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
