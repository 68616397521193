import {
  ContactDto,
  LinkDto,
  LinkResourceBaseDto,
  ContactDtoPagedResult,
  CreateContactCommandValues,
  UpdateContactCommandValues,
  CarrierCodes,
  FMCACarrierDto,
} from '../../models/data.models';
import { execLink, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import {
  CreateContactAndContactAddressesCommand,
  UpdateContactAndContactAddressesCommand,
} from '../../models/custom.models';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';
import { API_ENDPOINT } from '../../properties';
export const GET_CONTACTS_LINK_KEY = 'get-contacts';
export const CREATE_CONTACT_LINK_KEY = 'create-contact';
export const GET_CONTACT_LINK_KEY = 'get-contact';
export const UPDATE_CONTACT_LINK_KEY = 'update-contact';
export const DELETE_CONTACT_LINK_KEY = 'delete-contact';

export const CONTACT_ENTITY_NAME = 'Contact';

export interface GetContactParams {
  contactId: number;
}

export const getContactRequest = async (
  resource: LinkResourceBaseDto | null,
  { contactId }: GetContactParams,
): Promise<ContactDto> => {
  if (resource && contactId) {
    const getContactLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACT_LINK_KEY,
    );
    if (getContactLink) {
      const result = await execLink(getContactLink, { contactId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTACT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getContactsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContactDtoPagedResult> => {
  if (resource) {
    const getContactsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACTS_LINK_KEY,
    );
    if (getContactsListLink) {
      const result = await execLink(getContactsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTACTS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createContactRequest = async (
  resource: LinkResourceBaseDto,
  contact: CreateContactCommandValues | any,
) => {
  const createContactLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTACT_LINK_KEY,
  );
  if (createContactLink) {
    const result = await execLink(createContactLink, contact);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_CONTACT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createContactAndContactAddressesRequest = async (
  resource: LinkResourceBaseDto,
  contact: CreateContactAndContactAddressesCommand | any,
) => {
  const createContactLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTACT_LINK_KEY,
  );
  if (createContactLink) {
    const result = await execLink(createContactLink, contact);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_CONTACT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
export const updateContactAndContactAddressesRequest = async (
  resource: LinkResourceBaseDto,
  contact: UpdateContactAndContactAddressesCommand,
) => {
  const updateContactLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTACT_LINK_KEY,
  );
  if (updateContactLink) {
    const result = await execLink(updateContactLink, contact);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_CONTACT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
export const updateContactRequest = async (
  resource: LinkResourceBaseDto,
  contact: UpdateContactCommandValues,
) => {
  const updateContactLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTACT_LINK_KEY,
  );
  if (updateContactLink) {
    const result = await execLink(updateContactLink, contact);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_CONTACT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteContactRequest = async (contact: ContactDto) => {
  const deleteContactLink = contact.links?.find(
    (x: LinkDto) => x.rel === DELETE_CONTACT_LINK_KEY,
  );
  if (deleteContactLink) {
    const result = await execLink(deleteContactLink, contact);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_CONTACT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

interface ExportContactsParams {
  organizationId: number;
  contactType: string;
  fileType: string;
}

export const exportContactsRequest = async ({
  organizationId,
  contactType,
  fileType,
}: ExportContactsParams): Promise<any> => {
  if (organizationId && contactType) {
    const url = `${API_ENDPOINT}/api/organizations/${organizationId}/contacts/export?contactType=${contactType}&fileType=${fileType}`;
    const exportContactsLink: LinkDto = {
      href: url,
      templated: false,
      type: 'get',
    };
    const result = await execLink(exportContactsLink);
    return result.data;
  }
  return Promise.reject();
};

interface ImportContactsParams {
  organizationId: number;
  divisionId: number;
  contactType: string;
  file: File;
}

export const importContactsRequest = async ({
  organizationId,
  divisionId,
  contactType,
  file,
}: ImportContactsParams): Promise<any> => {
  if (organizationId && file) {
    const url = `${API_ENDPOINT}/api/organizations/${organizationId}/contacts/import?divisionId=${divisionId}&contactType=${contactType}`;
    const exportContactsLink: LinkDto = {
      href: url,
      templated: false,
      type: 'post',
    };
    const result = await postFormData(exportContactsLink, null, [file]);
    return result.data;
  }
  return Promise.reject();
};

interface FMCAImportContactsParams {
  organizationId: number;
  code: string;
  codeType: CarrierCodes;
}

export const fmcaImportContactsRequest = async ({
  organizationId,
  code,
  codeType,
}: FMCAImportContactsParams): Promise<FMCACarrierDto> => {
  if (organizationId && code && codeType) {
    const url = `${API_ENDPOINT}/api/organizations/${organizationId}/contacts/fmcaImport?code=${code}&codeType=${codeType}`;
    const fmcaImportContactsLink: LinkDto = {
      href: url,
      templated: false,
      type: 'get',
    };
    const result = await execLink(fmcaImportContactsLink);
    return result.data;
  }
  return Promise.reject();
};
