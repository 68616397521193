import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  Column,
  CustomFieldEntityType,
  OrderDto,
  OrderDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  ordersStore,
  getOrders,
  deleteOrder,
  updateOrdersColumns,
  createOrder,
} from '../orders.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_ORDER_LINK_KEY,
  DELETE_ORDER_LINK_KEY,
  GetOrderParams,
} from '../orders.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { ORDER_CREATE_PATH } from '../order.route';
import {
  OrderDtoForListPagedResult,
  OrderForListDto,
} from '../../../models/custom.models';
import { OrderGrid } from '../../common/components/grid/order-grid.component';
import { OrderDialog } from './order.dialog';
import { getCustomFields } from '../../customFields/customFields.store';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { userHas } from '../../auth/auth.store';

export type OrdersListProps = {
  goToDetails?: (orderParams: GetOrderParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (order: OrderDto, orderId: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const OrdersList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch = () => {},
}: OrdersListProps) => {
  const [orders, setOrders] = useState<OrderDtoPagedResult | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCustomFieldsColumns();
    getOrdersData();
  }, [offset, limit, sort, filter, search]);
  const getOrdersData = () => {
    getOrders({ offset, limit, sort, filter, search }).then(
      (ordersData) => {
        const orderDtoResult: OrderDtoForListPagedResult = {
          limit: ordersData.limit,
          offset: ordersData.offset,
          links: ordersData.links,
          totalCount: ordersData.totalCount,
          items: ordersData.items.map<OrderForListDto>((item) => {
            const resultOrder: OrderForListDto = item;
            resultOrder.firstOrderPickupsPickDate = item?.orderPickups[0]
              ?.pickDate
              ? getFormattedDate(item?.orderPickups[0]?.pickDate, false)
              : null;
            resultOrder.firstOrderDeliveriesDeliveryDate = item
              ?.orderDeliveries[0]?.deliveryDate
              ? getFormattedDate(item?.orderDeliveries[0]?.deliveryDate, false)
              : null;
            let firstOrderDeliveriesConsigneeAddressName = '';
            if (item?.orderDeliveries[0]?.consigneeContactName) {
              firstOrderDeliveriesConsigneeAddressName += `${
                item?.orderDeliveries[0]?.consigneeContactName ?? ''
              }`;
            }
            if (item?.orderDeliveries[0]?.consigneeCityName) {
              if (item?.orderDeliveries[0]?.consigneeContactName) {
                firstOrderDeliveriesConsigneeAddressName += ' / ';
              }
              firstOrderDeliveriesConsigneeAddressName += `${
                item?.orderDeliveries[0]?.consigneeCityName ?? ''
              }`;
            }
            if (item?.orderDeliveries[0]?.consigneeStateCode) {
              if (item?.orderDeliveries[0]?.consigneeCityName) {
                firstOrderDeliveriesConsigneeAddressName += ', ';
              }
              firstOrderDeliveriesConsigneeAddressName += `${
                item?.orderDeliveries[0]?.consigneeStateCode ?? ''
              }`;
            }

            let firstOrderPickupsShipperAddressName = '';
            if (item?.orderPickups[0]?.shipperContactName) {
              firstOrderPickupsShipperAddressName += `${
                item?.orderPickups[0]?.shipperContactName ?? ''
              }`;
            }
            if (item?.orderPickups[0]?.shipperCityName) {
              if (item?.orderPickups[0]?.shipperContactName) {
                firstOrderPickupsShipperAddressName += ' / ';
              }
              firstOrderPickupsShipperAddressName += `${
                item?.orderPickups[0]?.shipperCityName ?? ''
              }`;
            }
            if (item?.orderPickups[0]?.shipperStateCode) {
              if (item?.orderPickups[0]?.shipperCityName) {
                firstOrderPickupsShipperAddressName += ', ';
              }
              firstOrderPickupsShipperAddressName += `${
                item?.orderPickups[0]?.shipperStateCode ?? ''
              }`;
            }
            resultOrder.firstOrderDeliveriesConsigneeAddressName = firstOrderDeliveriesConsigneeAddressName;
            resultOrder.firstOrderPickupsShipperAddressName = firstOrderPickupsShipperAddressName;
            if (item?.customValues) {
              Object.keys(item.customValues).forEach(
                (customFieldInternalName) => {
                  if (!resultOrder.hasOwnProperty(customFieldInternalName))
                    resultOrder[customFieldInternalName] =
                      item.customValues[customFieldInternalName];
                },
              );
            }
            return item;
          }),
        };
        setOrders(orderDtoResult);
        setLoading(false);
      },
      () => {},
    );
  };
  const getCustomFieldsColumns = () => {
    getCustomFields({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Order} AND isInactive: false AND isSystem: false`,
    }).then(
      (customFieldsData) => {
        const customFieldsColumns: Column[] = [];
        const customFieldsColumnsNames: string[] = [];
        customFieldsData.items.forEach((customFieldDto) => {
          customFieldsColumns.push({
            name: customFieldDto.internalName,
            visible: true,
            title: customFieldDto.displayName,
            showFilter: true,
            type: 'customField',
            customFieldType: customFieldDto.customFieldType,
          });
          customFieldsColumnsNames.push(customFieldDto.internalName);
        });
        const existingColumns = ordersStore.getState().orderColumns;
        const existingColumnsNames: string[] = ordersStore
          .getState()
          .orderColumns.map((column) => {
            return column.name;
          });
        const customFieldsColumnsToAdd: Column[] = [];
        customFieldsColumns.forEach((customColumn) => {
          if (existingColumnsNames.indexOf(customColumn.name) === -1) {
            customFieldsColumnsToAdd.push(customColumn);
          }
        });
        for (
          let i = ordersStore.defaultState.orderColumns.length;
          i < existingColumns.length;
          i++
        ) {
          if (
            customFieldsColumnsNames.indexOf(existingColumns[i].name) === -1
          ) {
            existingColumns.splice(i, 1);
          }
        }
        if (customFieldsColumnsToAdd.length > 0) {
          updateOrdersColumns([
            ...existingColumns,
            ...customFieldsColumnsToAdd,
          ]);
        }
      },
      () => {},
    );
  };

  const { orderColumns: columns } = useStore(ordersStore);

  const onDeleteOrder = (order: OrderDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${order.orderNumber} Order`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteOrder(order).then(
            () => {
              onDelete();
              getOrdersData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditOrder = (order: OrderDto) => {
    goToDetails({ orderId: order.orderId });
  };
  const onCopyOrder = (order: OrderDto) => {
    const orderCopy = { ...order };
    delete orderCopy.charges;
    createOrder(orderCopy).then(
      (result) => {
        getOrdersData();
      },
      () => {},
    );
  };
  const history = useHistory();
  const onCreateNewOrder = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onChangeItem = (data?: any[]) => {
    setOrders((ordersDto) => {
      ordersDto.items = data;
      return { ...ordersDto };
    });
  };
  const onOrderSelect = (order, orderId) => {
    if (onSelect) onSelect(order, orderId);
    if (order?.orderId) {
      showDialog({
        dialog: OrderDialog,
        props: {
          className: 'order-modal',
          title: 'Update Order',
          orderId: order?.orderId,
        },
      }).then(
        (result) => {
          if (result !== null) {
            getOrdersData();
          }
        },
        () => {},
      );
    }
  };
  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div>
        <OrderGrid
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={null}
          data={orders?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={orders?.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_ORDER_LINK_KEY, orders?.items[0]?.links)
              ? onDeleteOrder
              : null
          }
          onSort={(field) => {
            onSort(field);
          }}
          search={search}
          onEdit={onEditOrder}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateOrdersColumns}
          onSelect={onOrderSelect}
          onChangeItem={onChangeItem}
          filter={filter}
          onFilter={onFilter}
          onSearch={onSearch}
          onCopy={userHas(CREATE_ORDER_LINK_KEY) ? onCopyOrder : null}
        />
        {!search && !orders.items.length && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">No Orders Found</h3>
            {userHas(CREATE_ORDER_LINK_KEY) && (
              <Button size={'lg'} color="primary" onClick={onCreateNewOrder}>
                Create Order
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};
