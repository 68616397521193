import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { FormContext } from '../form/form.component';
import { ErrorMessage, Field, FormikProps } from 'formik';
import { clearMessages } from '../../messages.store';

export type QuillInputProps = {
  onChange?: (data: any, context?: any) => void;
  name?: string;
  header?: string;
  required?: boolean;
  validate?: (data: any) => Promise<any>;
  error?: boolean;
  placeholder?: string;
  readonly?: boolean;
  defaultValue?: string;
};

export const QuillInput = ({
  onChange,
  name,
  header,
  required,
  placeholder = '',
  readonly,
  defaultValue = '',
}: QuillInputProps) => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
      ['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const [stateValue, setStateValue] = useState(defaultValue);

  const onChangeInput = (option, contextDto?) => {
    if (typeof onChange === 'function') {
      onChange(option, contextDto);
    }
  };

  useEffect(() => setStateValue(defaultValue), [defaultValue]);

  return (
    <FormContext.Consumer>
      {(context) => (
        <>
          <label className="input-label" htmlFor={name}>
            <span className="d-flex justify-content-between align-items-center">
              <span className="input-label-primary">{header}</span>
            </span>
          </label>
          <Field
            required={required}
            type="hidden"
            name={name}
            value={stateValue}
          />
          <ReactQuill
            className={name}
            style={{ height: '200px', marginBottom: '50px' }}
            onFocus={() => clearMessages()}
            onBlur={() => context.setFieldTouched(name, true)}
            readOnly={readonly}
            placeholder={placeholder}
            value={stateValue}
            onChange={(value, delta, sources, editor) => {
              value = value === '<p><br></p>' ? '' : value;
              setStateValue(value);
              context.setFieldValue(name, value);
              onChangeInput(value);
            }}
            modules={modules}
          />
          <ErrorMessage
            name={name}
            component="div"
            className="invalid-feedback"
          />
        </>
      )}
    </FormContext.Consumer>
  );
};
