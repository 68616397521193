import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetContactsQuery } from '../../../models/data.models';
import {
  COMMODITIES_CREATE_PATH,
  COMMODITIES_EDIT_PATH,
  COMMODITIES_LIST_PATH,
} from '../commodity.route';
import { CommoditiesList } from '../components/commodities-list.component';
import { Helmet } from 'react-helmet';
import { CREATE_COMMODITY_LINK_KEY } from '../commodities.service';
import { userHas } from '../../auth/auth.store';

export type CommodityListScreenProps = { pageTitle: string };

export const CommodityListScreen = ({
  pageTitle,
}: CommodityListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = 'commodityId',
    filter = '',
    search = '',
  } = useQuery() as GetContactsQuery;
  const history = useHistory();

  const createPath = () => {
    return generatePath(INTERNAL_PATH + COMMODITIES_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = ({ commodityId = 0 }) => {
    const path = generatePath(INTERNAL_PATH + COMMODITIES_EDIT_PATH, {
      organizationId,
      commodityId,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + COMMODITIES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + COMMODITIES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + COMMODITIES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + COMMODITIES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };

  return (
    <InternalLayout
      title={'Commodities'}
      createPath={userHas(CREATE_COMMODITY_LINK_KEY) ? createPath() : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CommoditiesList
        search={search}
        onSearch={onSearch}
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        filter={filter}
        onFilter={onFilter}
      />
    </InternalLayout>
  );
};
