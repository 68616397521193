import {
  StateDto,
  LinkDto,
  LinkResourceBaseDto,
  StateDtoPagedResult,
  CreateStateCommandValues,
  UpdateStateCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createStateRequest,
  getStatesListRequest,
  getStateRequest,
  deleteStateRequest,
  updateStateRequest,
  GetStateParams,
} from './states.service';

type StatesStoreState = {
  links: LinkDto[];
  stateColumns: Column[];
};

export const updateStatesColumns = createEvent<Column[]>();

export const getStates = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getStatesListRequest(currentOrganization, params);
});

export const createState = createEffect((stateData: StateDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createStateCommand: CreateStateCommandValues = { ...stateData };

  return createStateRequest(currentOrganization!, createStateCommand);
});

export const getState = createEffect((stateParams: GetStateParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getStateRequest(
    currentOrganization as LinkResourceBaseDto,
    stateParams,
  );
});

export const updateState = createEffect((state: StateDto) => {
  const updateStateCommand: UpdateStateCommandValues = { ...state };
  return updateStateRequest(state, updateStateCommand);
});

export const deleteState = createEffect((state: StateDto) => {
  return deleteStateRequest(state);
});

const defaultState: StatesStoreState = {
  links: [],
  stateColumns: [
    {
      name: 'stateCode',
      visible: true,
      title: 'State Code',
      sortName: 'stateCode',
    },
    { name: 'name', visible: true, title: 'Name', sortName: 'name' },
    {
      name: 'countryCode',
      visible: true,
      title: 'Country Code',
      sortName: 'countryCode',
    },
    {
      name: 'countryName',
      visible: true,
      title: 'Country Name',
      sortName: 'Country.Name',
    },
  ],
};

export const stateStore: Store<StatesStoreState> = createStore(defaultState).on(
  updateStatesColumns,
  (state, payload) => {
    return { ...state, stateColumns: payload };
  },
);
