import React, { useRef, useState } from 'react';
import { showDialog } from '../../common/dialog.store';
import { ErrorMessage, Field, FormikProps, FormikValues } from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { PackageTypeDto } from '../../../models/data.models';
import { PackageTypeDialog } from './packageType.dialog';
import { PackageTypesList } from './packageTypes-list.component';

export type PackageTypeSelectProps = {
  onSelect?: (packageType: PackageTypeDto) => void;
  required?: boolean;
  selectedFieldName?: string;
  nameId: string;
  header: string;
  listHeader?: string;
  id: string;
  selectedName?: string | number;
  selectedFilter?: string;
  placeholder?: string;
  showCreateDialogTitle?: string;
  showUpdateDialogTitle?: string;
  setFieldValue?: (key: string, value: string) => void;
  validate?: (value?: string) => Promise<string>;
};

export const PackageTypeSelect = ({
  id = '',
  nameId = '',
  selectedFieldName,
  header = '',
  selectedName,
  required = false,
  onSelect = () => {},
  listHeader = 'Package Types',
  placeholder = 'Select Package Type',
  showUpdateDialogTitle = 'Update Package Type',
  showCreateDialogTitle = 'Create Package Type',
  validate = () => Promise.resolve(''),
}: PackageTypeSelectProps) => {
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');
  const [search, setSearch] = useState('');
  const [name, setName] = useState(selectedName);
  const [field, setFieldName] = useState(selectedFieldName);
  const [showError, setShowError] = useState(false);
  const limit = 10;
  const getContextValue = (context: FormikProps<FormikValues>) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues.length > 0) {
      setName(changedValues[1]);
    }
    return name;
  };
  const validation = (value?: string) => {
    const err =
      (value === null || value === '') && required && "Can't be blank";
    if (!err) {
      setShowError(false);
      return validate(value);
    }
    setShowError(true);
    return Promise.resolve(err);
  };
  return (
    <FormContext.Consumer>
      {(context) => (
        <div className="form-group">
          <label className="input-label" htmlFor={nameId}>
            <span className="d-flex justify-content-between align-items-center">
              {header}
            </span>
          </label>
          <div className={`dropdown ${open ? 'show' : ''}`}>
            <div onClick={() => setOpen(!open)} className="input-group">
              <Field
                required={required}
                type="hidden"
                name={id}
                validate={(value?: string) => {
                  return validation(value).then(
                    (err) => {
                      return err;
                    },
                    () => {},
                  );
                }}
              />
              <input
                type="search"
                required={required}
                className="form-control"
                name={nameId}
                value={getContextValue(context)}
                placeholder={placeholder}
                readOnly={true}
              />
              <div className="input-group-append">
                <span className="input-group-text dropdown-toggle">&nbsp;</span>
              </div>
            </div>
            <div
              className={`dropdown-menu ${open ? 'show' : ''}`}
              aria-labelledby="dropdownMenuButton"
            >
              <h6 className="dropdown-header">{listHeader}</h6>
              <div>
                <PackageTypesList
                  isDropDownList={true}
                  search={search}
                  onSearch={setSearch}
                  showAllStore={false}
                  rowKeys={['name']}
                  limit={limit}
                  onSort={setSort}
                  onPageChanged={(page) => setOffset(page * limit)}
                  goToDetails={(packageType) => {
                    if (packageType.packageTypeId) {
                      showDialog(
                        {dialog: PackageTypeDialog, props: {
                          title: showUpdateDialogTitle,
                          packageTypeId: packageType.packageTypeId,
                        }},
                      ).then(
                        (result) => {
                          setOffset(0);
                        },
                        () => {},
                      );
                    }
                  }}
                  sort={sort}
                  offset={offset}
                  onSelect={(packageType, contactId) => {
                    if (context) {
                      context.setFieldValue(
                        id,
                        packageType.packageTypeId || '',
                      );
                      context.setFieldValue(nameId, packageType.name || '');
                      context.setFieldValue(field, packageType.name || '');
                      context.setFieldError(id, undefined);
                    }
                    setOpen(false);
                    setName(packageType.name || '');
                    onSelect(packageType);
                  }}
                />
              </div>
              <div className="dropdown-divider" />
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  showDialog(
                    {dialog: PackageTypeDialog, props: {
                      title: showCreateDialogTitle,
                      packageTypeId: 0,
                    }},
                  ).then(
                    (result) => {
                      setOffset(0);
                    },
                    () => {},
                  );
                }}
              >
                Create New
              </button>
            </div>
            {showError ? (
              <ErrorMessage
                name={id}
                component="div"
                className="invalid-feedback"
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
    </FormContext.Consumer>
  );
};
