import React from 'react';
import { Switch, Route, useRouteMatch, RouteProps } from 'react-router-dom';
import { OrganizationScreen } from './screens/organization.screen';

export const ORGANIZATION_LIST_PATH = '/organizations';
export const ORGANIZATION_EDIT_PATH = '/organization';
export const ORGANIZATION_PATH = '/org/:organizationId/organization';

export const InnerOrganizationRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Route exact path={`${match.path}${ORGANIZATION_EDIT_PATH}`}>
        <OrganizationScreen pageTitle="Update Organization" />
      </Route>
    </Route>
  );
};
