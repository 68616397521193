import React from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';

import UserPic from '../../../../img/photo-none.jpg';
import { OrganizationDto, UserDto } from '../../../../models/data.models';
import { generatePath, Link } from 'react-router-dom';
import { toggleMenu } from '../../nav.store';
import { getUserAdditionalData } from '../../../auth/auth.store';
import { getCurrencies } from '../../../currencies/currencies.store';

export type TopBarProps = {
  title: string | null;
  user?: UserDto | null;
  createPath?: string | null;
  logout?: () => void;
  organizationSetCurrent?: (organization: OrganizationDto) => void;
  organizations?: OrganizationDto[];
  currentOrganization?: OrganizationDto | null;
  goToPage?: (path: string) => void;
};

export const TopBar = ({
  organizations = [],
  currentOrganization = null,
  logout = () => {},
  goToPage = () => {},
  organizationSetCurrent = () => {},
  user = null,
  title = null,
  createPath = null,
}: TopBarProps) => {
  const onLogoutClicked = () => {
    if (logout) {
      logout();
    }
    goToPage('/login');
  };
  const onCurrentOrganizationChanged = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    // @ts-ignore
    const org = organizations.find(
      (x) => x.organizationId.toString() === event.target.value,
    );
    if (org && organizationSetCurrent) {
      goToPage(`/org/${org.organizationId}/`);
      organizationSetCurrent(org);
      getUserAdditionalData();
      await getCurrencies();
    }
  };
  return (
    <div className="top-bar p-3 d-flex border-bottom">
      <div className="d-flex">
        <GiHamburgerMenu
          className="i-top-bar mr-4 my-auto"
          onClick={() => toggleMenu()}
        />
        {createPath ? (
          <AiOutlinePlusCircle
            onClick={() => {
              goToPage && goToPage(createPath);
            }}
            className="i-top-bar mr-4 my-auto"
            data-cy="btn-create-new"
          />
        ) : null}

        <div className="top-bar-header-name text-dark mr-4 my-auto">
          {title}
        </div>
      </div>
      <div>
        <select
          data-cy="organization-select"
          className="form-control"
          value={currentOrganization?.organizationId}
          onChange={onCurrentOrganizationChanged}
        >
          {organizations.map((organization) => {
            return (
              <option
                key={organization.organizationId}
                value={organization.organizationId}
              >
                {organization.companyName}
              </option>
            );
          })}
        </select>
      </div>
      <div className="d-flex ml-auto">
        <div className="d-flex">
          <div className="dropdown">
            <img
              alt="User Picture"
              className="top-bar-img-user mr-4 dropdown-toggle pointer"
              aria-haspopup="true"
              aria-expanded="false"
              data-toggle="dropdown"
              src={UserPic}
              data-cy="user-menu"
            />
            {user ? (
              <div
                className="dropdown-menu  dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
              >
                <div className="mb-4">
                  <h3>
                    {user.firstName} {user.lastName}
                  </h3>
                  <small className="text-info">@{user.userName}</small>
                </div>
                <div>
                  <Link
                    data-cy="link-edit-profile"
                    to={generatePath(`/users/${user.userId}`)}
                  >
                    Edit Profile
                  </Link>
                </div>
                <div>
                  <Link
                    data-cy="link-create-organization"
                    to={generatePath('/createOrganization')}
                  >
                    Create Organization
                  </Link>
                </div>
                <div>
                  <a className="pointer" onClick={onLogoutClicked}>
                    Logout
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
