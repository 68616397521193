import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { organizationsStore } from '../../organization/organization.store';
import {
  CUSTOM_FIELDS_CREATE_PATH,
  CUSTOM_FIELDS_LIST_PATH,
} from '../customFields.route';
import { CustomFieldEdit } from '../components/customField-edit.component';
import { Helmet } from 'react-helmet';
import { CREATE_CUSTOMFIELD_LINK_KEY } from '../customFields.service';
import { userHas } from '../../auth/auth.store';

export type CustomFieldsScreenProps = { pageTitle: string };

export const CustomFieldScreen = ({ pageTitle }: CustomFieldsScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, fieldId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + CUSTOM_FIELDS_CREATE_PATH, {
      organizationId,
    });
  }
  const onCustomFieldCreated = () => {
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'custom-field-created',
      type: 'success',
      autoHide: true,
      message: 'Custom Field successfully created!',
    };
    history.push(path);
    addMessage(message);
  };
  const onCustomFieldUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'custom-field-updated',
      type: 'success',
      autoHide: true,
      message: 'Custom Field successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + CUSTOM_FIELDS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };
  return (
    <InternalLayout
      title={'Custom Fields'}
      createPath={userHas(CREATE_CUSTOMFIELD_LINK_KEY) ? createPath() : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CustomFieldEdit
        fieldId={fieldId}
        onCustomFieldCreated={onCustomFieldCreated}
        onCustomFieldUpdated={onCustomFieldUpdated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
