import React from 'react';
import { navigationStore, toggleMenuItem } from '../../nav.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';
import { LinkDto } from '../../../../models/data.models';
import { organizationsStore } from '../../../organization/organization.store';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type ActionsGroupComponentProps = {
  groupName: string;
  displayGroupName: string;
  icon: IconProp;
  actions: string[];
  children: JSX.Element;
};

export const ActionsGroupComponent = ({
  groupName,
  icon,
  actions,
  children,
  displayGroupName,
}: ActionsGroupComponentProps) => {
  const navStore = useStore(navigationStore);
  const { currentOrganization } = organizationsStore.getState();
  const hasAllowedActions = actions.some((action) =>
    currentOrganization?.links?.some((x: LinkDto) => x.rel === action),
  );
  if (hasAllowedActions)
    return (
      <li
        className={`nav-item nav-ripple ${navStore[groupName] ? 'dropup' : ''}`}
      >
        <a
          onClick={() => {
            toggleMenuItem(groupName);
          }}
          className="nav-link active nav-icon-size-medium dropdown-toggle"
          data-cy={`link-${groupName}`}
        >
          <div className="menu-icon-wrapper d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={icon} />
          </div>

          <div className="nav-link-text">{displayGroupName}</div>
        </a>
        <ul
          className={`collapse pl-4 ${navStore[groupName] ? 'show' : ''}`}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          {children}
        </ul>
      </li>
    );
  return null;
};
