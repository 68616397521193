import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { useParams } from 'react-router-dom';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { QuickBooksEdit } from '../components/quickBooks-edit.component';

export type QuickBooksScreenProps = { pageTitle: string };

export const QuickBooksScreen = ({ pageTitle }: QuickBooksScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();

  const onQuickBooksCreated = () => {
    localStorage.removeItem('referer_org');
    window.history.replaceState(null, null, window.location.href.split('?')[0]);
    const message: Message = {
      id: 'QuickBooks-created',
      type: 'success',
      autoHide: true,
      message: 'QuickBooks Config is successfully created!',
    };
    addMessage(message);
  };

  const onQuickBooksUpdated = () => {
    const message: Message = {
      id: 'QuickBooks-updated',
      type: 'success',
      autoHide: true,
      message: 'QuickBooks Config is successfully updated!',
    };
    addMessage(message);
  };

  return (
    <InternalLayout title={'QuickBooks'}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <QuickBooksEdit
        organizationId={organizationId}
        organizationConfigName={'QuickBooks'}
        onQuickBooksCreated={onQuickBooksCreated}
        onQuickBooksUpdated={onQuickBooksUpdated}
      />
    </InternalLayout>
  );
};
