import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  UserDto,
  UserDtoForList,
  UserDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  deleteUser,
  getUsers,
  updateUsersColumns,
  userStore,
} from '../users.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_USER_LINK_KEY,
  DELETE_USER_LINK_KEY,
  GetUserParams,
} from '../users.service';
import { UserDialog } from './user.dialog';
import { userHas } from '../../auth/auth.store';

export type UsersListProps = {
  goToDetails?: (userParams: GetUserParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (user: UserDto, userParams: GetUserParams) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const UsersList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  onSearch,
  filter = null,
  onFilter = () => {},
}: UsersListProps) => {
  const [users, setUsers] = useState<UserDtoPagedResult | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getUsersData = ({ offset, limit, sort, filter, search }) => {
    getUsers({ offset, limit, sort, filter, search }).then((usersData) => {
      setUsers(usersData);
      setLoading(false);
    });
  };

  useEffect(() => {
    getUsersData({ offset, limit, sort, filter, search });
  }, [offset, limit, sort, filter, search]);

  const { userColumns: columns } = useStore(userStore);

  const onDeleteUser = (user: UserDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete "${user.userName}" User`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteUser(user).then(
          () => {
            onDelete();
            getUsersData({ offset, limit, sort, filter, search });
          },
          () => {},
        );
      }
    });
  };

  const onEditUser = (user: UserDto) => {
    goToDetails({ userId: user.userId });
  };

  const onCreateNewUser = () => {
    goToDetails({ userId: '0' });
  };

  const onUserSelect = (user, userId) => {
    if (onSelect) onSelect(user, userId);
    showDialog({
      dialog: UserDialog,
      props: {
        userId: user.userId,
        title: 'Update User',
      },
    }).then((user) => {
      if (user !== null) {
        getUsersData({ offset, limit, sort, filter, search });
      }
    });
  };

  const getUsersForList = ((): UserDtoForList[] =>
    users?.items.map((user) => ({
      ...user,
      name: `${user.firstName} ${user.lastName}`,
    })))();

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Grid
          showAllStore={true}
          rowKeys={['id']}
          data={getUsersForList}
          columns={columns}
          offset={offset}
          limit={limit}
          total={users.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_USER_LINK_KEY, users?.items[0]?.links)
              ? onDeleteUser
              : null
          }
          onSort={onSort}
          onEdit={onEditUser}
          onPageChanged={onPageChanged}
          onFilter={onFilter}
          onColumnsChanged={updateUsersColumns}
          onSearch={onSearch}
          search={search}
          onSelect={onUserSelect}
        />
        {!filter && !search && !getUsersForList.length && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">No Users Found</h3>
            {userHas(CREATE_USER_LINK_KEY) && (
              <Button size={'lg'} color="primary" onClick={onCreateNewUser}>
                Create User
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};
