import React, { CSSProperties, useEffect, useState } from 'react';

import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';

import { Pagination } from '../pagination/pagination.component';
import {
  getAdditionalColumnsData,
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import {
  OrderForListDto,
  ReactSelectItem,
} from '../../../../models/custom.models';
import {
  ChargeStatuses,
  Column,
  ContactDto,
  CustomFieldType,
  ManualOrderStatuses,
  OrderDto,
  OrderStatuses,
} from '../../../../models/data.models';
import {
  PatchOrderParams,
  PATCH_ORDER_LINK_KEY,
} from '../../../orders/orders.service';
import { patchOrder } from '../../../orders/orders.store';
import { getEnumValues, unescapeString } from '../../../../utils/helper.utils';
import { showDialog } from '../../dialog.store';
import { Prompt } from '../prompt/prompt.component';
import { getFormattedDate } from '../../../../utils/formatting.utils';
import { ReactEnumInputSelect } from '../input/enum-input.select';
import { userHas } from '../../../auth/auth.store';
import Dropdown from 'react-bootstrap/Dropdown';
import { getContact } from '../../../contacts/contacts.store';

export const getDisabledAndEnabledOrderStatuses = (
  order: OrderForListDto,
  statuses: ReactSelectItem[],
): ReactSelectItem[] => {
  if (
    order?.charges &&
    (order?.charges.some(
      (charge) =>
        charge.chargeStatus === ChargeStatuses.Posted ||
        charge.chargeStatus === ChargeStatuses.Paid,
    ) ||
      order?.orderStatus === OrderStatuses.Delivered ||
      order?.orderStatus === ManualOrderStatuses.Delivered)
  ) {
    statuses[statuses.length - 1]['isDisabled'] = true;
  }
  return statuses;
};

export type OrderGridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: OrderForListDto[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onSelect?: (item: any, keys: any) => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  onChangeItem?: (items: any[]) => void;
  showAllFilters?: boolean;
};

export const OrderGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onColumnsChanged = () => {},
  onSort = () => {},
  onCopy = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  onChangeItem = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  showEmptyTable = false,
  showAllStore = false,
  showAllFilters = true,
}: OrderGridProps) => {
  const colByName: { [key: string]: any } = {};

  const [addData, setAddData] = useState({});

  useEffect(() => {
    const filterParts = new URLSearchParams(window.location.search)
      .get('filter')
      ?.split(' AND ');

    const billToContactId = filterParts
      ?.find((x) => x.includes('BillToContactId:'))
      ?.split(':')[1];

    const orderStatus = filterParts
      ?.find((x) => x.includes('orderStatus:'))
      ?.split(':')[1];

    let createdByUserName = filterParts
      ?.find((x) => x.includes('CreatedUser.UserName:'))
      ?.split(':')[1];

    if (createdByUserName) {
      createdByUserName = unescapeString(createdByUserName, false);
    }

    const customFieldsNames = columns
      ?.filter((column) => column.type === 'customField')
      .map((column) => column.name);

    const customFieldsValues = customFieldsNames
      .map((name) => {
        return filterParts?.find((x) => x.includes(name))?.split(':')[1];
      })
      .filter((field) => field !== null && field !== undefined)
      .map((field) => {
        return unescapeString(field, true);
      });

    let customFields = {};
    for (let i = 0; i < customFieldsValues.length; i++) {
      customFields[`__${customFieldsNames[i]}`] = customFieldsValues[i];
    }

    if (billToContactId) {
      getContact({ contactId: Number(billToContactId) }).then((contact) => {
        setAddData({
          ...addData,
          __BillToContactName: contact.name,
          __BillToContactType: contact.contactType,
          __orderStatus: orderStatus ? OrderStatuses[orderStatus] : null,
          __createdByUserName: createdByUserName ?? '',
          ...customFields,
        });
      });
    } else {
      setAddData({
        ...addData,
        __orderStatus: orderStatus ? OrderStatuses[orderStatus] : null,
        __createdByUserName: createdByUserName ?? '',
        ...customFields,
      });
    }
  }, [columns]);

  const updateCols = (colName: string) => {
    const col = colByName[colName];
    col.visible = !col.visible;
    return onColumnsChanged(columns);
  };

  const updateSort = (colName: string) => {
    const sortName = columns.find((col) => col.name === colName).sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    (document.querySelector(
      '.filter-dropdown .dropdown-toggle',
    ) as HTMLSpanElement).click();
    setAddData(getAdditionalColumnsData(filters));
    return onFilter(getSearchQuery(filters, columns));
  };

  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  const getClassGridItem = (row?: any): string => {
    const orderStatus = OrderStatuses[row?.orderStatus];
    let classes = [];
    if (orderStatus === OrderStatuses.OnRoute) {
      classes.push('bg-light-green');
    } else if (orderStatus === OrderStatuses.Open) {
      classes.push('bg-light-red');
    } else if (orderStatus === OrderStatuses.Refused) {
      classes.push('bg-violet');
    } else if (orderStatus === OrderStatuses.Covered) {
      classes.push('bg-dark-blue');
    } else if (orderStatus === OrderStatuses.Dispatched) {
      classes.push('bg-brown');
    } else if (
      orderStatus === OrderStatuses.Loading ||
      orderStatus === OrderStatuses.UnLoading ||
      orderStatus === OrderStatuses.Cancelled
    ) {
      classes.push('bg-gray');
    } else if (orderStatus === OrderStatuses.InYard) {
      classes.push('bg-pink');
    }
    return classes.join(' ');
  };
  const isEqualsColumnType = (columnName: string, type: string): boolean => {
    if (columnName == type) {
      return true;
    }
    return false;
  };
  const patchCarriers = (contact: ContactDto[], row?: any) => {
    const contactParams = contact
      ? contact.map((item) => {
          return { carrierId: item.contactId };
        })
      : [];
    const patchOrderParams: PatchOrderParams = {
      resource: { links: row.links },
      patchOrderCommand: {
        patchItems: [
          {
            path: `/OrderCarriers`,
            op: 'replace',
            value: contactParams,
          },
        ],
      },
    };
    patchOrderCommand(patchOrderParams, row);
  };
  const patchOrderStatus = (
    newValueOrderStatus: ReactSelectItem,
    row?: OrderForListDto,
  ) => {
    let newOrderStatus = newValueOrderStatus?.value;

    if (
      newValueOrderStatus.value === OrderStatuses.Cancelled ||
      newValueOrderStatus.value === ManualOrderStatuses.Cancelled
    ) {
      showDialog({
        dialog: Prompt,
        props: {
          title: `Cancel ${row?.orderNumber} Order`,
          message: `Please type order number (${row?.orderNumber}) to confirm its cancelling.`,
          className: 'cancel-order-modal',
          orderNumber: row?.orderNumber,
        },
      }).then((result) => {
        if (!result) {
          newOrderStatus = row?.orderStatus;
        }

        const patchOrderParams: PatchOrderParams = {
          resource: { links: row.links },
          patchOrderCommand: {
            patchItems: [
              {
                path: `/OrderStatus`,
                op: 'add',
                value: `${[newOrderStatus]}`,
              },
            ],
          },
        };
        patchOrderCommand(patchOrderParams, row);
      });
    } else {
      const patchOrderParams: PatchOrderParams = {
        resource: { links: row.links },
        patchOrderCommand: {
          patchItems: [
            {
              path: `/OrderStatus`,
              op: 'add',
              value: `${[newOrderStatus]}`,
            },
          ],
        },
      };
      patchOrderCommand(patchOrderParams, row);
    }
  };
  const getTdClass = (itemName?: any) => {
    let resultClasses = ['cursor-pointer'];
    if (itemName === 'firstOrderPickupsShipperAddressName') {
      resultClasses.push('three-dots order-grid-origin');
    } else if (itemName === 'firstOrderDeliveriesConsigneeAddressName') {
      resultClasses.push('three-dots order-grid-destination');
    } else if (itemName === 'orderStatus') {
      resultClasses.push('order-grid-order-status');
    }
    return resultClasses.join(' ');
  };

  const getFilteredRowsFilter = (): Column[] => {
    return columns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  const patchOrderCommand = (patchOrderParams: PatchOrderParams, row?: any) => {
    patchOrder(patchOrderParams)
      .then((item: OrderDto) => {
        const foundOrderIndex = data.findIndex(
          (x) => x.orderId === row.orderId,
        );
        let firstOrderDeliveriesConsigneeAddressName = '';
        if (item?.orderDeliveries[0]?.consigneeContactName) {
          firstOrderDeliveriesConsigneeAddressName += `${
            item?.orderDeliveries[0]?.consigneeContactName ?? ''
          }`;
        }
        if (item?.orderDeliveries[0]?.consigneeCityName) {
          if (item?.orderDeliveries[0]?.consigneeContactName) {
            firstOrderDeliveriesConsigneeAddressName += ' / ';
          }
          firstOrderDeliveriesConsigneeAddressName += `${
            item?.orderDeliveries[0]?.consigneeCityName ?? ''
          }`;
        }
        if (item?.orderDeliveries[0]?.consigneeStateCode) {
          if (item?.orderDeliveries[0]?.consigneeCityName) {
            firstOrderDeliveriesConsigneeAddressName += ', ';
          }
          firstOrderDeliveriesConsigneeAddressName += `${
            item?.orderDeliveries[0]?.consigneeStateCode ?? ''
          }`;
        }

        let firstOrderPickupsShipperAddressName = '';
        if (item?.orderPickups[0]?.shipperContactName) {
          firstOrderPickupsShipperAddressName += `${
            item?.orderPickups[0]?.shipperContactName ?? ''
          }`;
        }
        if (item?.orderPickups[0]?.shipperCityName) {
          if (item?.orderPickups[0]?.shipperContactName) {
            firstOrderPickupsShipperAddressName += ' / ';
          }
          firstOrderPickupsShipperAddressName += `${
            item?.orderPickups[0]?.shipperCityName ?? ''
          }`;
        }
        if (item?.orderPickups[0]?.shipperStateCode) {
          if (item?.orderPickups[0]?.shipperCityName) {
            firstOrderPickupsShipperAddressName += ', ';
          }
          firstOrderPickupsShipperAddressName += `${
            item?.orderPickups[0]?.shipperStateCode ?? ''
          }`;
        }
        const orderDtoForList: OrderForListDto = {
          lastModifiedByUserName: item.lastModifiedByUserName,
          createdByUserName: item.createdByUserName,
          orderPickups: item?.orderPickups,
          orderDeliveries: item?.orderDeliveries,
          orderStatus: item?.orderStatus,
          firstOrderDeliveriesConsigneeAddressName,
          firstOrderPickupsShipperAddressName,
          firstOrderDeliveriesDeliveryDate: item?.orderDeliveries[0]
            ?.deliveryDate
            ? getFormattedDate(item?.orderDeliveries[0]?.deliveryDate, false)
            : null,
          orderId: item?.orderId,
          firstOrderPickupsPickDate: item?.orderPickups[0]?.pickDate
            ? getFormattedDate(item?.orderPickups[0]?.pickDate, false)
            : null,
          billToContactId: item?.billToContactId,
          billToContactName: item?.billToContactName,
          carrierContactId: item?.carrierContactId,
          carrierContactName: item?.carrierContactName,
          charges: item?.charges,
          commodities: item?.commodities,
          created: item?.created,
          createdBy: item?.createdBy,
          divisionId: item?.divisionId,
          divisionName: item?.divisionName,
          employeeContactId: item?.employeeContactId,
          links: item?.links,
          employeeContactName: item?.employeeContactName,
          orderNumber: item?.orderNumber,
          lastModified: item?.lastModified,
          lastModifiedBy: item?.lastModifiedBy,
          organizationId: item?.organizationId,
          salespersonContactId: item?.salespersonContactId,
          salespersonContactName: item?.salespersonContactName,
          totalPcsCrt: item?.totalPcsCrt,
          volumeTotal: item?.volumeTotal,
          weighTotal: item?.weighTotal,
          carriers: item?.carriers,
        };
        data[foundOrderIndex] = { ...orderDtoForList };
        onChangeItem(data);
      })
      .catch(() => {});
  };
  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const getOrderStatus = (order: OrderForListDto) => {
    if (order?.orderStatus && !ManualOrderStatuses[order?.orderStatus]) {
      return getDisabledAndEnabledOrderStatuses(
        order,
        getEnumValues(OrderStatuses),
      );
    } else {
      return getDisabledAndEnabledOrderStatuses(
        order,
        getEnumValues(ManualOrderStatuses),
      );
    }
  };

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLSpanElement>) =>
      columns.some((column) => column.showFilter) && (
        <span
          className="dropdown-toggle pointer"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          <BsFilter />
          &nbsp;
          <small className="align-middle pl-1 text-uppercase">Filters</small>
          &nbsp;
          <small className="align-middle text-primary">
            {getReadableQueryString(filter, getFilteredRowsFilter(), addData)}
          </small>
        </span>
      ),
  );

  return (
    <div className={`grid ${className}`} style={style}>
      <div className="grid-toolbar d-flex pl-3">
        {onSearch ? (
          <input
            type="search"
            className="form-control my-2"
            placeholder="Search"
            onChange={updateSearch}
            value={decodeURIComponent(search)}
          />
        ) : null}
        {onFilter ? (
          <div className="my-3 px-3 w-100 filter-dropdown">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu>
                {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
                  <div className="px-5" style={{ minHeight: '300px' }}>
                    <div className="filters-form-block">
                      <Filters
                        query={filter}
                        columns={getFilteredRowsFilter()}
                        onFilter={updateFilter}
                        addData={addData}
                      />
                    </div>
                  </div>
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}

        <div className="dropdown dropdown-columns my-3 px-3 ml-auto pointer">
          <div
            className="dropdown-toggle"
            id="dropdownColumnsButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <BsListTask className="" />
            &nbsp;
            <small className="align-middle pl-1 text-uppercase">Columns</small>
          </div>
          <div
            className="dropdown-menu"
            aria-labelledby="dropdownColumnsButton"
            style={{ width: 'max-content', maxWidth: 'unset' }}
          >
            {columns
              .filter((item) => {
                if (showAllStore) {
                  return true;
                }
                return rowKeys?.includes(item.name);
              })
              .map((col) => {
                return (
                  <label key={col.name} className="dropdown-item pointer">
                    <input
                      type="checkbox"
                      onChange={() => updateCols(col.name)}
                      defaultChecked={col.visible}
                    />{' '}
                    {col.title}
                  </label>
                );
              })}
          </div>
        </div>
      </div>
      {total > 0 || showEmptyTable === true ? (
        <div className="bg-white mx-3">
          <table className="table" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                {columns
                  .filter((col) => {
                    if (showAllStore && col.visible) {
                      return true;
                    }
                    return col.visible && rowKeys?.includes(col.name);
                  })
                  .map((col) => {
                    return (
                      <th scope="col" key={col.name}>
                        <a
                          className={col.sortName ? 'link' : 'inactive-link'}
                          onClick={() =>
                            col.sortName ? updateSort(col.name) : null
                          }
                        >
                          {col.title}
                          {sort === col.name || sort === col.sortName ? (
                            <FaSortAlphaDown />
                          ) : null}
                          {sort === '-' + col.name ||
                          sort === '-' + col.sortName ? (
                            <FaSortAlphaUp />
                          ) : null}
                        </a>
                      </th>
                    );
                  })}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => {
                return (
                  <tr
                    data-cy-tracking-number={row.trackingNumber}
                    className={getClassGridItem(row)}
                    key={rowKeys?.map((x) => row[x]).join('_')}
                  >
                    {Object.values(columns)
                      .filter((item) => {
                        if (showAllStore && item.visible) {
                          return true;
                        }
                        return item.visible && rowKeys?.includes(item.name);
                      })
                      .map((item, index) => {
                        switch (item.name) {
                          case 'orderStatus':
                            return (
                              <td
                                className={getTdClass(item.name)}
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                              >
                                <ReactEnumInputSelect
                                  size={{ width: '110%' }}
                                  useContext={false}
                                  name={''}
                                  id={''}
                                  header={''}
                                  placeholder={'Select Order Status'}
                                  required={false}
                                  defaultValue={
                                    row[item.name]
                                      ? {
                                          label: OrderStatuses[row[item.name]],
                                          value: row[item.name],
                                        }
                                      : null
                                  }
                                  multiple={false}
                                  disabled={
                                    !userHas(PATCH_ORDER_LINK_KEY, row?.links)
                                  }
                                  onChange={(
                                    newValueOrderStatus: ReactSelectItem,
                                  ) =>
                                    patchOrderStatus(newValueOrderStatus, row)
                                  }
                                  options={getOrderStatus(row)}
                                  onClick={(event) => event.stopPropagation()}
                                />
                              </td>
                            );
                          case 'carrierContactName':
                            return (
                              <td
                                className={getTdClass(item.name)}
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                onClick={(event) => {
                                  if (onSelect) {
                                    onSelect(
                                      row,
                                      rowKeys?.reduce((keyObj, field) => {
                                        return row[field];
                                      }),
                                    );
                                  }
                                }}
                                style={{
                                  width: `calc(100% / ${
                                    columns.filter(
                                      (column) => column.visible === true,
                                    ).length
                                  })`,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {row?.carriers.map((carrier) => (
                                  <span style={{ whiteSpace: 'pre' }}>
                                    {carrier.name} - {carrier.contactType}
                                    {'\n'}
                                  </span>
                                ))}
                              </td>
                            );
                          default:
                            return (
                              <td
                                className={getTdClass(item.name)}
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                onClick={(event) => {
                                  if (onSelect) {
                                    onSelect(
                                      row,
                                      rowKeys?.reduce((keyObj, field) => {
                                        return row[field];
                                      }),
                                    );
                                  }
                                }}
                                style={{
                                  width: `calc(100% / ${
                                    columns.filter(
                                      (column) => column.visible === true,
                                    ).length
                                  })`,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {columns?.find(
                                  (column) =>
                                    column.name === item.name &&
                                    column.type === 'customField' &&
                                    column.customFieldType ===
                                      CustomFieldType.Boolean,
                                ) ? (
                                  row[item.name] === true ||
                                  row[item.name] === 'true' ? (
                                    <>&#x2713;</>
                                  ) : (
                                    <></>
                                  )
                                ) : columns?.find(
                                    (column) =>
                                      column.name === item.name &&
                                      column.type === 'customField' &&
                                      column.customFieldType ===
                                        CustomFieldType.Date,
                                  ) ? (
                                  getFormattedDate(row[item.name], false)
                                ) : (
                                  <>{row[item.name]}</>
                                )}
                              </td>
                            );
                        }
                      })}
                    <td style={{ textAlign: 'center' }}>
                      <div className="dropdown dropdown-grid">
                        <BsThreeDotsVertical
                          className="dropdown-toggle dropdown-dots-vertical pointer"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        />
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                          style={{ width: 'max-content' }}
                        >
                          {onDelete && (
                            <span
                              title={
                                row.orderStatus === OrderStatuses.Pending &&
                                row.charges?.length > 0
                                  ? 'Order contains charges'
                                  : row.orderStatus !== OrderStatuses.Pending &&
                                    row.charges?.length === 0
                                  ? 'Order is not in the Pending status'
                                  : row.orderStatus !== OrderStatuses.Pending &&
                                    row.charges?.length > 0
                                  ? 'Order contains charges and not in the Pending status'
                                  : null
                              }
                            >
                              <a
                                role="button"
                                className="dropdown-item"
                                onClick={() => {
                                  onDelete(row);
                                }}
                                style={
                                  row.orderStatus !== OrderStatuses.Pending ||
                                  row.charges?.length > 0
                                    ? {
                                        pointerEvents: 'none',
                                        color: 'gray',
                                      }
                                    : null
                                }
                              >
                                Delete
                              </a>
                            </span>
                          )}
                          <a
                            role="button"
                            className="dropdown-item"
                            onClick={() => {
                              onEdit(row);
                            }}
                          >
                            Edit
                          </a>
                          {onCopy && (
                            <a
                              role="button"
                              className="dropdown-item"
                              onClick={() => {
                                onCopy(row);
                              }}
                            >
                              Copy
                            </a>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-4">Nothing Found</p>
      )}
      {total > limit && showPagination === true ? (
        <div className="mt-3 d-flex justify-content-center">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
          />
        </div>
      ) : null}
    </div>
  );
};
