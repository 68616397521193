import React, { useState } from 'react';
import { ChargeGrid } from '../../common/components/grid/charge-grid.component';
import {
  ChargeDto,
  ChargeType,
  ContactType,
  PaidAs,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { DELETE_CHARGE_LINK_KEY, GetChargeParams } from '../charges.service';
import { chargeStore, updateChargesColumns } from '../charges.store';
import { Button } from '../../common/components/button/button.component';
import { ChargeDialog } from './charge.dialog';
import { useHistory } from 'react-router-dom';
import { Store } from 'effector';
import { getFormattedPrice } from '../../../utils/formatting.utils';
import { currencyStore } from '../../currencies/currencies.store';
import { ChargeDefaultValues } from '../../common/DefaultValues';
import { userHas } from '../../auth/auth.store';

export type ChargeForOrderListProps = {
  goToDetails?: (chargeParams: GetChargeParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  items?: ChargeDto[];
  showPagination?: boolean;
  showIncomeCharge?: boolean;
  showExpenseCharge?: boolean;
  changeItems?: React.Dispatch<React.SetStateAction<ChargeDto[]>>;
  onChargeCreated?: (charge: ChargeDto) => void;
  chargeStoreForList?: Store<any>;
  selectedApplyToContact?: {
    contactId: number;
    name: string;
    contactType: ContactType;
    paidAs: PaidAs;
  };
  selectedApplyToCarrier?: {
    contactId: number;
    name: string;
    contactType: ContactType;
    paidAs: PaidAs;
  };
  isCreateModeForTransaction?: boolean | null;
  addIncomeButtonName?: string;
  addExpenseButtonName?: string;
  isCreateMode?: boolean;
  isLoading?: boolean;
  userCanDelete?: boolean;
  charges?: ChargeDto[] | undefined;
};

export const ChargeForOrderList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = 'chargeId',
  showPagination = true,
  showIncomeCharge = false,
  showExpenseCharge = false,
  onPageChanged = () => {},
  onSort = () => {},
  items,
  changeItems,
  onChargeCreated = () => {},
  chargeStoreForList = chargeStore,
  selectedApplyToContact = null,
  selectedApplyToCarrier = null,
  isCreateModeForTransaction = false,
  addIncomeButtonName = `Add ${ChargeType.IncomeCharge}`,
  addExpenseButtonName = `Add ${ChargeType.ExpenseCharge}`,
  isCreateMode = true,
  isLoading = true,
  userCanDelete = false,
  charges = [],
}: ChargeForOrderListProps) => {
  const [fakeChargeIndex, setFakeChargeIndex] = useState<number>(
    !isLoading &&
      (isCreateMode ? 0 : (items[items.length - 1]?.chargeId + 1) | 0),
  );
  const { chargeColumns: columns } = useStore(chargeStoreForList);
  const { defaultCurrency } = currencyStore?.getState();
  const onCreateNewCharge = (chargeType: ChargeType) => {
    const charge: ChargeDto = {
      currencyName: defaultCurrency?.currencyName,
      currencyId: defaultCurrency?.currencyId,
      currencySymbol: defaultCurrency?.symbol,
      note: ChargeDefaultValues.note,
      amountAndTaxAmount: ChargeDefaultValues.amountAndTaxAmount,
      currency: ChargeDefaultValues.currency,
      expense: ChargeDefaultValues.expense,
      income: ChargeDefaultValues.income,
      prepaid: ChargeDefaultValues.prepaid,
      chargeStatus: ChargeDefaultValues.chargeStatus,
      salesTaxAmount: ChargeDefaultValues.salesTaxAmount,
      salesTaxCode: ChargeDefaultValues.salesTaxCode,
      salesTaxId: ChargeDefaultValues.salesTaxId,
      salesTaxRate: ChargeDefaultValues.salesTaxRate,
      totalAmount: ChargeDefaultValues.totalAmount,
      accountingItemId: ChargeDefaultValues.accountingItemId,
      accountingItemName: ChargeDefaultValues.accountingItemName,
      amount: ChargeDefaultValues.amount,
      applyBy: ChargeDefaultValues.applyBy,
      applyToContactId:
        chargeType === ChargeType.IncomeCharge
          ? selectedApplyToContact?.contactId
          : selectedApplyToCarrier?.contactId,
      applyToContactName:
        chargeType === ChargeType.IncomeCharge
          ? selectedApplyToContact?.name
          : selectedApplyToCarrier?.name,
      applyToContactType:
        chargeType === ChargeType.IncomeCharge
          ? selectedApplyToContact?.contactType
          : selectedApplyToCarrier?.contactType,
      chargeId: fakeChargeIndex,
      chargeType: ChargeDefaultValues.chargeType,
      description: ChargeDefaultValues.description,
      freightServiceClassId: ChargeDefaultValues.freightServiceClassId,
      grossVolume: ChargeDefaultValues.grossVolume,
      grossWeight: ChargeDefaultValues.grossWeight,
      isConsolidated: ChargeDefaultValues.isConsolidated,
      paidAs:
        chargeType === ChargeType.IncomeCharge
          ? selectedApplyToContact?.paidAs ?? ChargeDefaultValues.paidAs
          : selectedApplyToCarrier?.paidAs ?? ChargeDefaultValues.paidAs,
      pieces: ChargeDefaultValues.pieces,
      price: ChargeDefaultValues.price,
      quantity: ChargeDefaultValues.quantity,
      showInDocuments: ChargeDefaultValues.showInDocuments,
      unit: ChargeDefaultValues.unit,
      currencyDecimals: defaultCurrency?.decimalPlaces,
      createMode: true,
      allowAutomaticUpdate: ChargeDefaultValues.allowAutomaticUpdate,
    };
    showDialog({
      dialog: ChargeDialog,
      props: {
        title: `Add ${chargeType} Charge`,
        chargeType: chargeType,
        charge,
        chargeId: 0,
        className: 'charge-modal',
        isCreateModeForTransaction,
        charges: charges,
      },
    }).then((result) => {
      if (result) {
        onChargeCreated(result);
        setFakeChargeIndex(fakeChargeIndex + 1);
      }
    });
  };
  const onDeleteCharge = (charge: ChargeDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete "${charge.description}" Charge`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        let isDeleted = false;
        items = items.filter((obj) => {
          const compareChargeResult = obj.chargeId !== charge.chargeId;
          if (!compareChargeResult && isDeleted === false) {
            isDeleted = true;
            return false;
          }
          return true;
        });
        changeItems(items);
      }
    });
  };

  const onEditCharge = (charge: ChargeDto) => {
    if (charge?.createMode !== true) {
      charge.createMode = false;
    }
    goToDetails({
      charge,
      chargeId: charge?.chargeId,
      chargeType: charge?.chargeType,
    });
  };

  const history = useHistory();

  const getFormattedItems = () => {
    const formattedItems: ChargeDto[] = [];
    items.forEach((chargeDto) => {
      formattedItems.push({ ...chargeDto });
      const lastElem = formattedItems[formattedItems.length - 1];
      lastElem.formattedPrice = getFormattedPrice(
        lastElem.price,
        lastElem.currencyDecimals,
        lastElem.currencySymbol,
      );
      lastElem.formattedAmount = getFormattedPrice(
        lastElem.amount,
        lastElem.currencyDecimals,
        lastElem.currencySymbol,
      );
      if (lastElem.income) {
        lastElem.formattedIncome = getFormattedPrice(
          lastElem.income,
          lastElem.currencyDecimals,
          lastElem.currencySymbol,
        );
      }
      if (lastElem.expense) {
        lastElem.formattedExpense = getFormattedPrice(
          lastElem.expense,
          lastElem.currencyDecimals,
          lastElem.currencySymbol,
        );
      }
      lastElem.amountAndTaxAmount = getFormattedPrice(
        lastElem.amount + lastElem.salesTaxAmount,
        lastElem.currencyDecimals,
        lastElem.currencySymbol,
      );
    });
    return formattedItems;
  };

  return (
    <div className="w-100">
      <div>
        <div className="row mb-3">
          <div className="col-2">
            <h3 className="font-weight-normal">Charges</h3>
          </div>
          {showIncomeCharge ? (
            <div
              className={`${showExpenseCharge ? 'offset-6' : 'offset-8'} col-2`}
            >
              <Button
                size={'sm'}
                color="primary"
                className="btn-light btn-cancel w-100 h-100"
                name="create-incomeCharge"
                onClick={(event) => onCreateNewCharge(ChargeType.IncomeCharge)}
              >
                {addIncomeButtonName}
              </Button>
            </div>
          ) : (
            <div />
          )}
          {showExpenseCharge ? (
            <div className={`${showIncomeCharge ? '' : 'offset-8'} col-2`}>
              <Button
                size={'sm'}
                color="primary"
                className="btn-light btn-cancel w-100 h-100"
                name="create-expenseCharge"
                onClick={(event) => onCreateNewCharge(ChargeType.ExpenseCharge)}
              >
                {addExpenseButtonName}
              </Button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      {items && items.length ? (
        <ChargeGrid
          className="commodities-for-order-list"
          showAllStore={true}
          rowKeys={['chargeId', 'commodityStatus']}
          showEmptyTable={true}
          data={getFormattedItems()}
          columns={columns}
          offset={offset}
          limit={limit}
          total={getFormattedItems()?.length}
          sort={sort}
          showPagination={showPagination}
          onDelete={
            userHas(DELETE_CHARGE_LINK_KEY, items[0]?.links) || userCanDelete
              ? onDeleteCharge
              : null
          }
          onSort={onSort}
          onEdit={onEditCharge}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateChargesColumns}
          onSelect={onEditCharge}
          hideColumnsSelect={true}
        />
      ) : (
        <>
          <h3 className="text-center m-5 text-muted">No charges</h3>
        </>
      )}
    </div>
  );
};
