import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { EquipmentTypeForm } from './equipmentType.form';
import {
  AttachmentParentType,
  EquipmentTypeDto,
} from '../../../models/data.models';
import {
  createEquipmentType,
  getEquipmentType,
  updateEquipmentType,
} from '../equipmentType.store';
import { Panel } from '../../common/components/panel/panel.component';
import { EquipmentTypeDefaultValues } from '../../common/DefaultValues';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { AttachmentsFilesList } from '../../attachments/components/attachments-files-list.component';
import * as Yup from 'yup';
import { UPDATE_EQUIPMENTTYPE_LINK_KEY } from '../equipmentType.service';
import { userHas } from '../../auth/auth.store';

export type EquipmentTypeEditProps = {
  equipmentTypeId?: number | null;
  onEquipmentTypeCreated?: (equipmentType: EquipmentTypeDto) => void;
  onEquipmentTypeUpdated?: (equipmentType: EquipmentTypeDto) => void;
  onEquipmentTypeLoaded?: (equipmentType: EquipmentTypeDto) => void;
  onCancel?: () => void;
};

const initialState: EquipmentTypeDto = {
  equipmentTypeId: 0,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  name: EquipmentTypeDefaultValues.equipmentTypeName,
  organizationId: 0,
  links: [],
};

const equipmentTypeSchema = Yup.object().shape({
  name: Yup.string().required("Can't be blank").nullable(true),
});

export const EquipmentTypeEdit = ({
  equipmentTypeId,
  onEquipmentTypeLoaded = () => {},
  onEquipmentTypeCreated = () => {},
  onEquipmentTypeUpdated = () => {},
  onCancel = () => {},
}: EquipmentTypeEditProps) => {
  const isCreateMode = !equipmentTypeId || equipmentTypeId === 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [equipmentType, setEquipmentType] = useState<EquipmentTypeDto | null>(
    null,
  );

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (equipmentTypeId) {
      getEquipmentType({ equipmentTypeId }).then(
        (equipmentTypeDto: EquipmentTypeDto) => {
          setEquipmentType(equipmentTypeDto);
          setIsLoading(false);
          onEquipmentTypeLoaded(equipmentTypeDto);
        },
        () => {},
      );
    } else {
      throw new Error('EquipmentType keys were not provided');
    }
  }, [equipmentTypeId]);

  const onSubmit = (data: EquipmentTypeDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createEquipmentType(data)
        .then(
          (result) => {
            onEquipmentTypeCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateEquipmentType(data)
        .then(
          (result) => {
            onEquipmentTypeUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Tabs>
      <TabList>
        <Tab name="equipmentType">Equipment Type</Tab>
        <Tab name="attachments" disabled={isCreateMode}>
          Attachments
        </Tab>
      </TabList>
      <EquipmentTypeForm
        id={'equipmentTypeForm'}
        initialValues={equipmentType || initialState}
        onSubmit={onSubmit}
        validationSchema={equipmentTypeSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add Equipment Type</h2>
            ) : (
              <h2>Update Equipment Type</h2>
            )}
            <div className="row">
              <div className="col-4">
                <EquipmentTypeForm.Name />
                <div className="w-100 d-flex align-items-center justify-content-end">
                  {(userHas(
                    UPDATE_EQUIPMENTTYPE_LINK_KEY,
                    equipmentType?.links,
                  ) ||
                    isCreateMode) && (
                    <Button
                      name="save-equipmentType"
                      type="submit"
                      color="primary"
                      className="btn-block mr-3"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save Equipment Type
                    </Button>
                  )}
                  <Button
                    type="button"
                    color="primary"
                    onClick={onCancel}
                    className="btn-light btn-cancel"
                    disabled={isSending}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
        </TabPanel>
        <TabPanel>
          <Panel className="m-3">
            <AttachmentsFilesList
              parentId={equipmentTypeId}
              parentType={AttachmentParentType.EquipmentType}
            />
            <div className="justify-content-end row mt-4">
              {(userHas(UPDATE_EQUIPMENTTYPE_LINK_KEY, equipmentType?.links) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    type="submit"
                    color="primary"
                    className="w-100 btn-block"
                    name="save-equipmentType"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Equipment Type
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </EquipmentTypeForm>
    </Tabs>
  );
};
