import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CommodityListScreen } from './screens/commodity-list.screen';
import { CommodityScreen } from './screens/commodity.screen';

export const COMMODITIES_LIST_PATH = '/commodities';
export const COMMODITIES_EDIT_PATH = '/commodities/:commodityId';
export const COMMODITIES_CREATE_PATH = '/commodities/0';
export const COMMODITIES_PATH = '/org/:organizationId/commodities';

export const CommodityRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${COMMODITIES_LIST_PATH}`}>
          <CommodityListScreen pageTitle="Commodities" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${COMMODITIES_CREATE_PATH}`}>
          <CommodityScreen pageTitle="Add New Commodity" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${COMMODITIES_EDIT_PATH}`}>
          <CommodityScreen pageTitle="Update Commodity" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
