import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CommodityForm } from './commodity.form';
import {
  CommodityDto,
  CommodityStatuses,
  DimensionsUnit,
  PackageTypeDto,
  VolumeUnit,
  WeightUnit,
} from '../../../models/data.models';
import {
  createCommodity,
  getCommodity,
  updateCommodity,
} from '../commodities.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ReactSelectItem } from '../../../models/custom.models';
import {
  getEnumValues,
  validateNumberInput,
  validatePositiveNumberInput,
} from '../../../utils/helper.utils';
import { CommodityDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { authStore } from '../../auth/auth.store';
import {
  convertDimension,
  convertWeight,
  getVolume,
} from '../../../utils/converter.utils';
import { FormikProps } from 'formik';
import { FormContext } from '../../common/components/form/form.component';

export type CommodityEditProps = {
  commodityId?: number | null;
  defaultCommodity?: CommodityDto | null;
  onCommodityCreated?: (commodity: CommodityDto) => void;
  onCommodityUpdated?: (commodity: CommodityDto) => void;
  onCommodityLoaded?: (commodity: CommodityDto) => void;
  onCancel?: () => void;
  saveButtonRenderCondition?: boolean;
};

const initialState: CommodityDto = {
  note: CommodityDefaultValues.note,
  quantity: CommodityDefaultValues.quantity,
  unit: CommodityDefaultValues.unit,
  unitaryValue: CommodityDefaultValues.unitaryValue,
  packageTypeName: CommodityDefaultValues.packageTypeName,
  commodityId: null,
  commodityStatus: CommodityDefaultValues.commodityStatus,
  description: CommodityDefaultValues.description,
  dimensionsUnit: CommodityDefaultValues.dimensionsUnit,
  height: CommodityDefaultValues.height,
  length: CommodityDefaultValues.commodityLength,
  organizationId: null,
  packageTypeId: CommodityDefaultValues.packageTypeId,
  pieces: CommodityDefaultValues.pieces,
  volumePiece: CommodityDefaultValues.volumePiece,
  volumeTotal: CommodityDefaultValues.volumeTotal,
  valueTotal: CommodityDefaultValues.valueTotal,
  volumeUnit: CommodityDefaultValues.volumeUnit,
  weightTotal: CommodityDefaultValues.weightTotal,
  weight: CommodityDefaultValues.weight,
  weightByTotal: CommodityDefaultValues.weightByTotal,
  weightUnit: CommodityDefaultValues.weightUnit,
  width: CommodityDefaultValues.width,
  links: [],
};

const numberSchema = Yup.string()
  .transform((value) => (value === null ? '' : value))
  .test('numberFormat', 'Incorrect number format', (value) => {
    return (
      (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
        value?.toString(),
      ) &&
        Number(value) < Number.MAX_SAFE_INTEGER &&
        Number(value) > Number.MIN_SAFE_INTEGER) ||
      value === '' ||
      value === undefined
    );
  })
  .test('length', 'Max value is 999999', (value) => {
    return value === undefined || Number(value) <= 999999;
  })
  .nullable(true);

const commoditySchema = Yup.object().shape({
  description: Yup.string().required("Can't be blank").nullable(true),
  commodityStatus: Yup.string().required("Can't be blank").nullable(true),
  pieces: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .required("Can't be blank")
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === ''
      );
    })
    .test('positive', "Can't be less than or equal to 0", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max value is 999999', (value) => {
      return value === undefined || Number(value) <= 999999;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  length: numberSchema,
  width: numberSchema,
  height: numberSchema,
  weight: numberSchema,
  quantity: numberSchema,
  unit: numberSchema,
  unitaryValue: numberSchema,
  dimensionsUnit: Yup.string().required("Can't be blank").nullable(true),
  weightUnit: Yup.string().required("Can't be blank").nullable(true),
  volumeUnit: Yup.string().required("Can't be blank").nullable(true),
});

export const CommodityEdit = ({
  commodityId,
  defaultCommodity = null,
  onCommodityLoaded = () => {},
  onCommodityCreated = () => {},
  onCommodityUpdated = () => {},
  onCancel = () => {},
  saveButtonRenderCondition = false,
}: CommodityEditProps) => {
  const { user: currentUser } = authStore.getState();
  if (!saveButtonRenderCondition) {
    saveButtonRenderCondition =
      currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole;
  }

  defaultCommodity &&
    (defaultCommodity.valueTotal =
      (defaultCommodity?.quantity || 0) *
      (defaultCommodity?.unit || 0) *
      (defaultCommodity?.unitaryValue || 0));
  const isCreateMode = !commodityId || commodityId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [commodity, setCommodity] = useState<CommodityDto | null>(
    defaultCommodity || initialState,
  );
  const [initialValues, setInitialValues] = useState<CommodityDto | null>(
    defaultCommodity || initialState,
  );
  const [packageType, setPackageType] = useState<{
    packageTypeId: string;
    name: string;
  }>(null);
  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
      if (defaultCommodity != null) {
        setPackageType((packageTypeData) => {
          if (!packageTypeData) {
            packageTypeData = {
              name: null,
              packageTypeId: null,
            };
          }
          packageTypeData.packageTypeId = defaultCommodity?.packageTypeId?.toString();
          packageTypeData.name = defaultCommodity?.packageTypeName;
          return { ...packageTypeData };
        });
      }
    } else if (commodityId) {
      if (defaultCommodity === null) {
        getCommodity({ commodityId, commodity: null }).then(
          (commodityDto: CommodityDto) => {
            commodityDto.valueTotal =
              (commodityDto.quantity || 0) *
              (commodityDto.unit || 0) *
              (commodityDto.unitaryValue || 0);
            setCommodity(commodityDto);
            setInitialValues(commodityDto);
            setIsLoading(false);
            onCommodityLoaded(commodityDto);
            setPackageType((packageTypeData) => {
              if (!packageTypeData) {
                packageTypeData = {
                  name: null,
                  packageTypeId: null,
                };
              }
              packageTypeData.packageTypeId = commodityDto?.packageTypeId?.toString();
              packageTypeData.name = commodityDto?.packageTypeName;
              return { ...packageTypeData };
            });
          },
        );
      } else {
        setIsLoading(false);
        setPackageType((packageTypeData) => {
          if (!packageTypeData) {
            packageTypeData = {
              name: null,
              packageTypeId: null,
            };
          }
          packageTypeData.packageTypeId = defaultCommodity?.packageTypeId?.toString();
          packageTypeData.name = defaultCommodity?.packageTypeName;
          return { ...packageTypeData };
        });
      }
    } else {
      throw new Error('Commodity keys were not provided');
    }
  }, [commodityId]);

  const onSubmit = (data: CommodityDto) => {
    if (data) {
      data.weight = commodity?.weight;
      data.width = commodity?.width;
      data.height = commodity?.height;
      data.length = commodity?.length;
      data.pieces = commodity?.pieces;
      data.weightTotal = commodity?.weightTotal;
      data.volumeTotal = commodity?.volumeTotal;
      data.volumePiece = commodity?.volumePiece;
      data.valueTotal = commodity?.valueTotal;
    }

    if (defaultCommodity === null) {
      setIsSending(true);
      if (isCreateMode) {
        createCommodity(data)
          .then((result) => {
            onCommodityCreated(result);
          })
          .finally(() => setIsSending(false));
      } else {
        updateCommodity(data)
          .then((result) => {
            onCommodityUpdated(result);
          })
          .finally(() => setIsSending(false));
      }
    } else {
      if (isCreateMode) {
        onCommodityCreated(data);
      } else {
        onCommodityUpdated(data);
      }
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const getWeightTotal = (weight: number, pieces: number): number => {
    return (weight || 0) * (pieces || 0);
  };

  const getVolumePiece = (
    length: number,
    width: number,
    height: number,
  ): number => {
    return (length || 0) * (width || 0) * (height || 0);
  };

  const getVolumeTotal = (volumePiece: number, pieces: number): number => {
    return (volumePiece || 0) * (pieces || 0);
  };

  const getValueTotal = (
    quantity: number,
    unit: number,
    unitaryValue: number,
  ): number => {
    return (quantity || 0) * (unit || 0) * (unitaryValue || 0);
  };

  const weightOnChange = (data?: any) => {
    const weight = data.target.value;
    setCommodity((commodityDto) => {
      if (!commodityDto) {
        commodityDto = initialState;
      }
      commodityDto.weight = weight;
      commodityDto.weightTotal = getWeightTotal(weight, commodityDto.pieces);
      return { ...commodityDto };
    });
  };

  const piecesOnChange = (data?: any) => {
    setCommodity((commodityDto) => {
      if (!commodityDto) {
        commodityDto = initialState;
      }
      commodityDto.pieces = Number(data.target.value);
      commodityDto.weightTotal = getWeightTotal(
        commodityDto.weight,
        commodityDto.pieces,
      );
      commodityDto.volumePiece = getVolumePiece(
        commodityDto.length,
        commodityDto.width,
        commodityDto.height,
      );
      commodityDto.volumeTotal = getVolumeTotal(
        commodityDto.volumePiece,
        commodityDto.pieces,
      );
      return { ...commodityDto };
    });
  };

  const lengthOnChange = (data?: any) => {
    const length = data.target.value;
    setCommodity((commodityDto) => {
      if (!commodityDto) {
        commodityDto = initialState;
      }
      commodityDto.length = length;
      commodityDto.volumePiece = getVolumePiece(
        length,
        commodityDto.width,
        commodityDto.height,
      );
      commodityDto.volumeTotal = getVolumeTotal(
        commodityDto.volumePiece,
        commodityDto.pieces,
      );
      return { ...commodityDto };
    });
  };

  const widthOnChange = (data?: any) => {
    const width = data.target.value;
    setCommodity((commodityDto) => {
      if (!commodityDto) {
        commodityDto = initialState;
      }
      commodityDto.width = width;
      commodityDto.volumePiece = getVolumePiece(
        commodityDto.length,
        width,
        commodityDto.height,
      );
      commodityDto.volumeTotal = getVolumeTotal(
        commodityDto.volumePiece,
        commodityDto.pieces,
      );
      return { ...commodityDto };
    });
  };

  const heightOnChange = (data?: any) => {
    const height = data.target.value;
    setCommodity((commodityDto) => {
      if (!commodityDto) {
        commodityDto = initialState;
      }
      commodityDto.height = height;
      commodityDto.volumePiece = getVolumePiece(
        commodityDto.length,
        commodityDto.width,
        height,
      );
      commodityDto.volumeTotal = getVolumeTotal(
        commodityDto.volumePiece,
        commodityDto.pieces,
      );
      return { ...commodityDto };
    });
  };

  const quantityOnChange = (data?: any, context?: any) => {
    const quantity = data.target.value;
    setCommodity((commodityDto) => {
      if (!commodityDto) {
        commodityDto = initialState;
      }
      commodityDto.quantity = quantity;
      commodityDto.valueTotal = getValueTotal(
        quantity,
        commodityDto.unit,
        commodityDto.unitaryValue,
      );
      return { ...commodityDto };
    });
  };

  const unitOnChange = (data?: any) => {
    const unit = data.target.value;
    setCommodity((commodityDto) => {
      if (!commodityDto) {
        commodityDto = initialState;
      }
      commodityDto.unit = unit;
      commodityDto.valueTotal = getValueTotal(
        commodityDto.quantity,
        unit,
        commodityDto.unitaryValue,
      );
      return { ...commodityDto };
    });
  };

  const unitaryValueOnChange = (data?: any) => {
    const unitaryValue = data.target.value;
    setCommodity((commodityDto) => {
      if (!commodityDto) {
        commodityDto = initialState;
      }
      commodityDto.unitaryValue = unitaryValue;
      commodityDto.valueTotal = getValueTotal(
        commodityDto.quantity,
        commodityDto.unit,
        unitaryValue,
      );
      return { ...commodityDto };
    });
  };

  const onCommodityStatusChange = (newValueOrderStatus: ReactSelectItem) => {};

  const onDimensionsUnitChange = (
    data?: { label: string; value: DimensionsUnit },
    context?: FormikProps<CommodityDto>,
  ) => {
    const newLength = convertDimension(
      context.values.length,
      context.values.dimensionsUnit,
      data?.value,
    );
    const newWidth = convertDimension(
      context.values.width,
      context.values.dimensionsUnit,
      data?.value,
    );
    const newHeight = convertDimension(
      context.values.height,
      context.values.dimensionsUnit,
      data?.value,
    );
    setCommodity({
      ...context.values,
      dimensionsUnit: data?.value,
      length: newLength,
      width: newWidth,
      height: newHeight,
    });
    context.setFieldValue('length', newLength);
    context.setFieldValue('width', newWidth);
    context.setFieldValue('height', newHeight);
  };

  const onVolumeUnitChange = (
    data?: { label: string; value: VolumeUnit },
    context?: FormikProps<CommodityDto>,
  ) => {
    setCommodity({
      ...context.values,
      volumeUnit: data?.value,
    });
  };

  const onWeightUnitChange = (
    data?: { label: string; value: WeightUnit },
    context?: FormikProps<CommodityDto>,
  ) => {
    const newWeight = convertWeight(
      context.values.weight,
      context.values.weightUnit,
      data?.value,
    );
    setCommodity({
      ...context.values,
      weightUnit: data?.value,
      weight: newWeight,
    });
    context.setFieldValue('weight', newWeight);
  };

  const parseNumberInput = (value: string) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  };

  return (
    <Panel className="mt-3 mx-3">
      <CommodityForm
        id={'commodity-form'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={commoditySchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <>
              <div className="row">
                <div className="col-6">
                  <CommodityForm.Description />
                </div>
                <div className={'col-6'}>
                  <CommodityForm.PackageTypeSelect
                    required={false}
                    defaultValue={
                      packageType &&
                      packageType.packageTypeId !== undefined &&
                      packageType.packageTypeId != null &&
                      packageType.name !== undefined &&
                      packageType.name != null
                        ? {
                            packageTypeId: packageType?.packageTypeId,
                            name: packageType?.name,
                          }
                        : ''
                    }
                    onChange={(data?: PackageTypeDto, context?: any) => {
                      setPackageType((packageTypeData) => {
                        if (!packageTypeData) {
                          packageTypeData = {
                            name: null,
                            packageTypeId: null,
                          };
                        }
                        packageTypeData.packageTypeId = data?.packageTypeId?.toString();
                        packageTypeData.name = data?.name;
                        return { ...packageTypeData };
                      });
                      context?.setFieldValue('packageTypeName', data?.name);
                    }}
                    nameId={'packageTypeName'}
                  />
                </div>
              </div>
              <div className="row">
                {defaultCommodity ? (
                  <div />
                ) : (
                  <div className={'col-6'}>
                    <CommodityForm.CommodityStatus
                      options={getEnumValues(CommodityStatuses)}
                      required={true}
                      defaultValue={
                        commodity?.commodityStatus
                          ? {
                              label:
                                CommodityStatuses[commodity?.commodityStatus],
                              value: commodity?.commodityStatus,
                            }
                          : null
                      }
                      onChange={onCommodityStatusChange}
                    />
                  </div>
                )}
                <div className={'col-6'}>
                  <CommodityForm.Pieces
                    selectedFieldName={commodity?.pieces?.toString()}
                    onChange={piecesOnChange}
                    onKeyDown={validateNumberInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className={'col-3'}>
                  <CommodityForm.Length
                    selectedFieldName={commodity?.length?.toString()}
                    onChange={lengthOnChange}
                    onKeyDown={validatePositiveNumberInput}
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.Width
                    selectedFieldName={commodity?.width?.toString()}
                    onChange={widthOnChange}
                    onKeyDown={validatePositiveNumberInput}
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.Height
                    selectedFieldName={commodity?.height?.toString()}
                    onChange={heightOnChange}
                    onKeyDown={validatePositiveNumberInput}
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.DimensionsUnit
                    options={getEnumValues(DimensionsUnit)}
                    required={true}
                    defaultValue={
                      commodity?.dimensionsUnit
                        ? {
                            label: DimensionsUnit[commodity?.dimensionsUnit],
                            value: commodity?.dimensionsUnit,
                          }
                        : null
                    }
                    valueInput={
                      commodity?.volumeUnit
                        ? {
                            label:
                              DimensionsUnit[context?.values?.dimensionsUnit],
                            value: context?.values?.dimensionsUnit,
                          }
                        : null
                    }
                    onChange={(
                      data?: { label: string; value: DimensionsUnit },
                      context?: FormikProps<CommodityDto>,
                    ) => {
                      onDimensionsUnitChange(data, context);
                    }}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className={'offset-3 col-3'}>
                  <div className={'form-border-text'}>PIECE</div>
                </div>
                <div className={'col-3'}>
                  <div className={'form-border-text'}>TOTAL</div>
                </div>
                <div className={'col-3'}>
                  <div className={'form-border-text'}>MEASURE</div>
                </div>
              </div>
              <div className="row">
                <div className={'col-3 pt-5'}>
                  <div className={'form-border-text'}>WEIGHT</div>
                </div>
                <div className={'col-3'}>
                  <CommodityForm.Weight
                    defaultValue={commodity?.weight}
                    onChange={weightOnChange}
                    onKeyDown={validatePositiveNumberInput}
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.WeightTotal
                    dependencies={['weight', 'pieces']}
                    callback={({ weight, pieces }) =>
                      parseNumberInput(weight) * parseNumberInput(pieces)
                    }
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.WeightUnit
                    options={getEnumValues(WeightUnit)}
                    required={true}
                    defaultValue={
                      commodity?.weightUnit
                        ? {
                            label: WeightUnit[commodity?.weightUnit],
                            value: commodity?.weightUnit,
                          }
                        : null
                    }
                    valueInput={
                      commodity?.weightUnit
                        ? {
                            label: WeightUnit[context?.values?.weightUnit],
                            value: context?.values?.weightUnit,
                          }
                        : null
                    }
                    onChange={(
                      data?: { label: string; value: WeightUnit },
                      context?: FormikProps<CommodityDto>,
                    ) => {
                      onWeightUnitChange(data, context);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className={'col-3 pt-5'}>
                  <div className={'form-border-text'}>VOLUME</div>
                </div>
                <div className={'col-3'}>
                  <CommodityForm.VolumePiece
                    dependencies={[
                      'length',
                      'width',
                      'height',
                      'dimensionsUnit',
                      'volumeUnit',
                    ]}
                    callback={({
                      length,
                      width,
                      height,
                      dimensionsUnit,
                      volumeUnit,
                    }) =>
                      getVolume(
                        length,
                        width,
                        height,
                        dimensionsUnit,
                        volumeUnit,
                      )
                    }
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.VolumeTotal
                    dependencies={['volumePiece', 'pieces']}
                    callback={({ volumePiece, pieces }) =>
                      parseNumberInput(volumePiece) * parseNumberInput(pieces)
                    }
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.VolumeUnit
                    options={getEnumValues(VolumeUnit)}
                    required={true}
                    defaultValue={
                      commodity?.volumeUnit
                        ? {
                            label: VolumeUnit[commodity?.volumeUnit],
                            value: commodity?.volumeUnit,
                          }
                        : null
                    }
                    valueInput={
                      commodity?.volumeUnit
                        ? {
                            label: VolumeUnit[context?.values?.volumeUnit],
                            value: context?.values?.volumeUnit,
                          }
                        : null
                    }
                    onChange={(
                      data?: { label: string; value: VolumeUnit },
                      context?: FormikProps<CommodityDto>,
                    ) => {
                      onVolumeUnitChange(data, context);
                    }}
                  />
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-3'}>
                  <CommodityForm.Quantity
                    onChange={quantityOnChange}
                    onKeyDown={validatePositiveNumberInput}
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.Unit
                    onChange={unitOnChange}
                    onKeyDown={validatePositiveNumberInput}
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.UnitaryValue
                    onChange={unitaryValueOnChange}
                    onKeyDown={validatePositiveNumberInput}
                  />
                </div>
                <div className={'col-3'}>
                  <CommodityForm.TotalValue
                    dependencies={['quantity', 'unit', 'unitaryValue']}
                    callback={({ quantity, unit, unitaryValue }) =>
                      parseNumberInput(quantity) *
                      parseNumberInput(unit) *
                      parseNumberInput(unitaryValue)
                    }
                  />
                </div>
              </div>
              <div className={'pb-3'}>
                <CommodityForm.Note />
              </div>
              <div className="justify-content-end d-flex col-12 p-0">
                {(saveButtonRenderCondition || isCreateMode) && (
                  <div className="col-6 pl-0">
                    <Button
                      form={'commodity-form'}
                      type="submit"
                      color="primary"
                      className="btn-block"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save Commodity
                    </Button>
                  </div>
                )}
                <div className="col-6 pr-0">
                  <Button
                    type="button"
                    color="primary"
                    onClick={onCancel}
                    className="w-100 btn-secondary"
                    disabled={isSending}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </>
          )}
        </FormContext.Consumer>
      </CommodityForm>
    </Panel>
  );
};
