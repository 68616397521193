import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectAccountingItemPaginate } from '../../accountingItems/components/accountingItem-react-select.component';

export const CurrencyForm = ({
  children,
  onSubmit,
  initialValues,
  innerRef,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      innerRef={innerRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};
CurrencyForm.AccountingItemSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Gain Loss Account',
  required = false,
  defaultValue,
  sort = 'description',
  id = 'gainLossAccountId',
  header = 'Gain Loss Account',
  onChange = () => {},
  nameId = 'gainLossAccountDescription',
}: InputPublicProps) => {
  return (
    <SelectAccountingItemPaginate
      nameId={nameId}
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeAccountingItem={onChange}
    />
  );
};
CurrencyForm.CurrencyCode = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'currencyCode'}
      label={'Currency Code'}
      placeholder={'Currency Code'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CurrencyForm.DecimalPlaces = ({
  onChange,
  options,
  name = 'decimalPlaces',
  id = 'decimalPlaces',
  placeholder = 'Select Decimal Places',
  required = false,
  multiple = false,
  disabled = false,
  header,
  defaultValue = '',
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};
CurrencyForm.ExchangeRateToPrimary = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'exchangeRateToPrimary'}
      label={'Exchange Rate To Primary'}
      placeholder={'Exchange Rate To Primary'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CurrencyForm.IsInactive = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isInactive'}
      id={'isInactiveCurrencyCheckbox'}
      label={'Is Inactive'}
      placeholder={'Is Inactive'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CurrencyForm.CurrencyName = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'currencyName'}
      label={'Currency Name'}
      placeholder={'Currency Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
CurrencyForm.ExchangeRateLastUpdate = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'exchangeRateLastUpdate'}
      label={'Exchange Rate Last Update'}
      placeholder={'Exchange Rate Last Update'}
      required={false}
      type={'date'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};
CurrencyForm.Symbol = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'symbol'}
      label={'Symbol'}
      placeholder={'Symbol'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
