import {
  TrackingEventDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateTrackingEventCommandValues,
  UpdateTrackingEventCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createTrackingEventRequest,
  getTrackingEventsListRequest,
  getTrackingEventRequest,
  deleteTrackingEventRequest,
  updateTrackingEventRequest,
  GetTrackingEventParams,
} from './trackingEvents.service';

type TrackingEventsStoreState = {
  links: LinkDto[];
  trackingEventColumns: Column[];
};

export const updateTrackingEventsColumns = createEvent<Column[]>();

export const getTrackingEvents = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getTrackingEventsListRequest(currentOrganization, params);
});

export const createTrackingEvent = createEffect(
  (trackingEventData: TrackingEventDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createTrackingEventCommand: CreateTrackingEventCommandValues = {
      ...trackingEventData,
    };

    return createTrackingEventRequest(
      currentOrganization!,
      createTrackingEventCommand,
    );
  },
);

export const getTrackingEvent = createEffect(
  (trackingEventParams: GetTrackingEventParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getTrackingEventRequest(
      currentOrganization as LinkResourceBaseDto,
      trackingEventParams,
    );
  },
);

export const updateTrackingEvent = createEffect(
  (trackingEvent: TrackingEventDto) => {
    const updateTrackingEventCommand: UpdateTrackingEventCommandValues = {
      ...trackingEvent,
    };
    return updateTrackingEventRequest(
      trackingEvent,
      updateTrackingEventCommand,
    );
  },
);

export const deleteTrackingEvent = createEffect(
  (trackingEvent: TrackingEventDto) => {
    return deleteTrackingEventRequest(trackingEvent);
  },
);

const defaultState: TrackingEventsStoreState = {
  links: [],
  trackingEventColumns: [
    { name: 'eventDate', visible: true, title: 'Date' },
    { name: 'eventName', visible: true, title: 'Name' },
    { name: 'location', visible: true, title: 'Location' },
    { name: 'description', visible: true, title: 'Description' },
    { name: 'includeInTracking', visible: false, title: 'Include in tracking' },
    { name: 'isInactive', visible: false, title: 'Is inactive' },
    { name: 'sendEmail', visible: true, title: 'Send in email' },
    { name: 'createdBy', visible: false, title: 'Created by' },
    { name: 'created', visible: false, title: 'Created on' },
  ],
};

export const trackingEventsStore: Store<TrackingEventsStoreState> = createStore(
  defaultState,
).on(updateTrackingEventsColumns, (state, payload) => {
  return { ...state, trackingEventColumns: payload };
});
