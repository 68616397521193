import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { PAYMENT_CREATE_PATH, PAYMENT_LIST_PATH } from '../payment.route';
import { PaymentEdit } from '../components/payment-edit.component';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_PAYMENT_LINK_KEY } from '../payments.service';
import { userHas } from '../../auth/auth.store';

export type PaymentScreenProps = { pageTitle: string };

export const PaymentScreen = ({ pageTitle }: PaymentScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, paymentId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + PAYMENT_CREATE_PATH, {
    organizationId,
  });

  const onPaymentCreated = () => {
    const path = generatePath(INTERNAL_PATH + PAYMENT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'mode-of-transportation-created',
      type: 'success',
      autoHide: true,
      message: 'Payment successfully created!',
    };
    history.push(path);
    addMessage(message);
  };

  const onPaymentUpdated = () => {
    const path = generatePath(INTERNAL_PATH + PAYMENT_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'mode-of-transportation-updated',
      type: 'success',
      autoHide: true,
      message: 'Payment successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + PAYMENT_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };

  return (
    <InternalLayout
      title={'Payments'}
      createPath={userHas(CREATE_PAYMENT_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PaymentEdit
        paymentId={paymentId}
        onPaymentUpdated={onPaymentUpdated}
        onPaymentCreated={onPaymentCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
