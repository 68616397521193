import {
  AttachmentDto,
  LinkDto,
  LinkResourceBaseDto,
  AttachmentDtoPagedResult,
  CreateAttachmentCommandValues,
  UpdateAttachmentCommandValues,
} from '../../models/data.models';
import { execLink, postFormData } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_ATTACHMENTS_LINK_KEY = 'get-attachments';
export const CREATE_ATTACHMENT_LINK_KEY = 'create-attachment';
export const GET_ATTACHMENT_LINK_KEY = 'get-attachment';
export const UPDATE_ATTACHMENT_LINK_KEY = 'update-attachment';
export const DELETE_ATTACHMENT_LINK_KEY = 'delete-attachment';

export interface GetAttachmentParams {
  attachmentId: number;
}

export const getAttachmentRequest = async (
  resource: LinkResourceBaseDto | null,
  { attachmentId }: GetAttachmentParams,
): Promise<AttachmentDto> => {
  if (resource && attachmentId) {
    const getAttachmentLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ATTACHMENT_LINK_KEY,
    );
    if (getAttachmentLink) {
      const result = await execLink(getAttachmentLink, { attachmentId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ATTACHMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getAttachmentsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<AttachmentDtoPagedResult> => {
  if (resource) {
    const getAttachmentsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ATTACHMENTS_LINK_KEY,
    );
    if (getAttachmentsListLink) {
      const result = await execLink(getAttachmentsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ATTACHMENTS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createAttachmentRequest = async (
  resource: LinkResourceBaseDto,
  attachment: CreateAttachmentCommandValues,
  file: File,
) => {
  const createAttachmentLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ATTACHMENT_LINK_KEY,
  );
  if (createAttachmentLink) {
    const result = await postFormData(createAttachmentLink, attachment, [file]);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_ATTACHMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateAttachmentRequest = async (
  resource: LinkResourceBaseDto,
  attachment: UpdateAttachmentCommandValues,
) => {
  const updateAttachmentLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ATTACHMENT_LINK_KEY,
  );
  if (updateAttachmentLink) {
    const result = await execLink(updateAttachmentLink, attachment);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_ATTACHMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteAttachmentRequest = async (attachment: AttachmentDto) => {
  const deleteAttachmentLink = attachment.links?.find(
    (x: LinkDto) => x.rel === DELETE_ATTACHMENT_LINK_KEY,
  );
  if (deleteAttachmentLink) {
    const result = await execLink(deleteAttachmentLink, attachment);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_ATTACHMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
