import { Dialog } from '../../common/components/dialog/dialog.component';
import { AttachmentDto, AttachmentType } from '../../../models/data.models';

export type PreviewAttachmentDialogProperties = {
  attachment: AttachmentDto;
  title: string;
  className?: string;
};

export class PreviewAttachmentDialog extends Dialog<PreviewAttachmentDialogProperties> {
  constructor(props: PreviewAttachmentDialogProperties) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose = () => {
    this.close(null);
  };

  renderContent(): any {
    const { attachment } = this.props as PreviewAttachmentDialogProperties;
    return (
      <div className={'p-3 d-flex justify-content-center'}>
        <div className="" style={{ maxWidth: 1024 }}>
          {attachment.attachmentType === AttachmentType.Picture &&
            attachment.presignedPreviewUri && (
              <div className="w-100 d-flex justify-content-center mb-3">
                <img
                  className={'mw-100'}
                  src={attachment.presignedPreviewUri}
                  alt={attachment.fileName}
                />
              </div>
            )}
          <a href={attachment.presignedFileUri} target={'_blank'}>
            Click here to open source file
          </a>
          <p>Description:</p>
          <p>{attachment.description}</p>
        </div>
      </div>
    );
  }
}
