import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PaymentTermListScreen } from './screens/paymentTerm-list.screen';
import { PaymentTermScreen } from './screens/paymentTerm.screen';

export const PAYMENTTERMS_LIST_PATH = '/payment-terms';
export const PAYMENTTERMS_EDIT_PATH = '/payment-terms/:paymentTermId';
export const PAYMENTTERMS_CREATE_PATH = '/payment-terms/0';
export const PAYMENTTERMS_PATH = '/org/:organizationId/payment-terms';

export const PaymentTermRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${PAYMENTTERMS_LIST_PATH}`}>
          <PaymentTermListScreen pageTitle="Payment Terms" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${PAYMENTTERMS_CREATE_PATH}`}>
          <PaymentTermScreen pageTitle="Add New Payment Term" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${PAYMENTTERMS_EDIT_PATH}`}>
          <PaymentTermScreen pageTitle="Update Payment Term" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
