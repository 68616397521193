import React from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';

import { LoginScreen } from './screens/login.screen';
import { SignUpScreen } from './screens/signup.screen';
import { ForgotPasswordScreen } from './screens/forgot-password.screen';
import { ResetPasswordScreen } from './screens/reset-password.screen';
export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password/:userId/:code';

export const LoginRoute = (props: RouteProps) => {
  return (
    <Route {...props}>
      <LoginScreen pageTitle="Login - Seahorse Express" />
    </Route>
  );
};

export const RegisterRoute = (props: RouteProps) => {
  return (
    <Route {...props}>
      <SignUpScreen pageTitle="Sign Up - Seahorse Express" />
    </Route>
  );
};

export const ForgotPasswordRoute = (props: RouteProps) => {
  return (
    <Route {...props}>
      <ForgotPasswordScreen pageTitle="Forgot Password - Seahorse Express" />
    </Route>
  );
};

export const ResetPasswordRoute = (props: RouteProps) => {
  return (
    <Route {...props}>
      <ResetPasswordScreen pageTitle="Reset Password - Seahorse Express" />
    </Route>
  );
};
