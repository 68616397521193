import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { USER_CREATE_PATH, USERS_LIST_PATH } from '../users.route';
import { UserEdit } from '../components/user-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_USER_LINK_KEY } from '../users.service';
import { userHas } from '../../auth/auth.store';

export type UserScreenProps = { pageTitle: string };

export const UserScreen = ({ pageTitle }: UserScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { userId } = useParams();

  const createPath = generatePath(USER_CREATE_PATH);

  const onUserUpdated = () => {
    const path = generatePath(USERS_LIST_PATH);
    const message: Message = {
      id: 'user-updated',
      type: 'success',
      autoHide: true,
      message: 'User successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const onUserCreated = () => {
    const path = generatePath(USERS_LIST_PATH);
    const message: Message = {
      id: 'user-updated',
      type: 'success',
      autoHide: true,
      message: 'User successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const onCancel = () => {
    const path = generatePath(USERS_LIST_PATH);
    history.push(path);
  };

  return (
    <InternalLayout
      title={'User'}
      createPath={userHas(CREATE_USER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <UserEdit
        userId={userId}
        onUserCreated={onUserCreated}
        onUserUpdated={onUserUpdated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
