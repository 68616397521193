import { Dialog } from '../../common/components/dialog/dialog.component';
import { AccountingTransactionDto } from '../../../models/data.models';
import { BillEdit } from './bill-edit.component';

export type InvoiceDialogProperties = {
  organizationId?: number | null;
  accountingTransactionId?: number | null;
  title: string;
  className: string;
};

export class BillDialog extends Dialog<InvoiceDialogProperties> {
  constructor(props: InvoiceDialogProperties) {
    super(props);
    this.onAccountingTransactionSaved = this.onAccountingTransactionSaved.bind(
      this,
    );
  }

  onAccountingTransactionSaved = (
    accountingTransaction: AccountingTransactionDto,
  ) => {
    this.close(accountingTransaction);
  };

  renderContent(): any {
    const { accountingTransactionId, organizationId } = this
      .props as InvoiceDialogProperties;
    return (
      <BillEdit
        organizationId={organizationId}
        organizationConfigName={'QuickBooks'}
        accountingTransactionId={accountingTransactionId}
        onAccountingTransactionCreated={this.onAccountingTransactionSaved}
        onAccountingTransactionUpdated={this.onAccountingTransactionSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
