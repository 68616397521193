import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { PortForm } from './port.form';
import { CountryDto, PortDto, StateDto } from '../../../models/data.models';
import { createPort, getPort, updatePort } from '../ports.store';
import { Panel } from '../../common/components/panel/panel.component';
import { PortDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_PORT_LINK_KEY } from '../port.service';

export type PortEditProps = {
  portId?: number;
  onPortCreated?: (country: PortDto) => void;
  onPortUpdated?: (country: PortDto) => void;
  onCancel?: () => void;
};

const initialState: PortDto = {
  countryCode: PortDefaultValues.countryCode,
  portId: PortDefaultValues.portId,
  name: PortDefaultValues.portName,
  stateCode: PortDefaultValues.stateCode,
  isRail: PortDefaultValues.isRail,
  isAir: PortDefaultValues.isAir,
  isBorderCrossingPoint: PortDefaultValues.isBorderCrossingPoint,
  isMail: PortDefaultValues.isMail,
  isMariTime: PortDefaultValues.isMariTime,
  isRoad: PortDefaultValues.isRoad,
  isMyCompany: PortDefaultValues.isMyCompany,
  created: new Date(),
  createdBy: '',
  lastModified: new Date(),
  lastModifiedBy: '',
  links: [],
  organizationId: 0,
  portRemarks: PortDefaultValues.portRemarks,
};

const portSchema = Yup.object().shape({
  portId: Yup.string().required("Can't be blank").nullable(true),
  countryCode: Yup.string().required("Can't be blank").nullable(true),
  name: Yup.string().required("Can't be blank").nullable(true),
  stateCode: Yup.string().when('countryCode', {
    is: (countryCode) => countryCode == 'US' || countryCode == 'CA',
    then: Yup.string().required("Can't be blank").nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  portRemarks: Yup.string().required("Can't be blank").nullable(true),
});

export const PortEdit = ({
  portId,
  onPortCreated = () => {},
  onPortUpdated = () => {},
  onCancel = () => {},
}: PortEditProps) => {
  const isCreateMode = !portId || portId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [port, setPort] = useState<PortDto | null>(null);
  const [initialValues, setInitialValues] = useState<PortDto | null>(
    initialState,
  );
  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (portId) {
      getPort(portId).then((portData: PortDto) => {
        setPort(portData);
        setInitialValues(portData);
        setIsLoading(false);
      });
    }
  }, [portId]);

  const onSubmit = (data: PortDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createPort(data)
        .then((result) => {
          onPortUpdated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updatePort(data)
        .then((result) => {
          onPortUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Panel className="m-3">
      {isCreateMode ? (
        <h3 className="header-form">Add New Port</h3>
      ) : (
        <h3 className="header-form">Update Port</h3>
      )}
      <div className="row">
        <div className="col-4">
          <PortForm
            id={'port-form'}
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={ref}
            validationSchema={portSchema}
          >
            <PortForm.PortId disabled={!isCreateMode} />
            {isCreateMode ? (
              <PortForm.CountryCode
                id={'countryCode'}
                header={'Country'}
                required={true}
                defaultValue={
                  port && port.countryCodeName && port.countryCode
                    ? {
                        countryCode: port.countryCode,
                        name: port.countryCodeName,
                      }
                    : ''
                }
                onChange={(country?: CountryDto) => {
                  setPort((oldPort) => {
                    if (!oldPort) {
                      oldPort = initialState;
                    }
                    oldPort.countryCode = country?.countryCode;
                    oldPort.countryCodeName = country?.name;
                    return { ...oldPort };
                  });
                }}
                nameId={'countryCodeName'}
              />
            ) : (
              <PortForm.CountryName disabled={true} />
            )}
            <PortForm.PortName />
            <PortForm.PortSubdivision
              readonly={port?.countryCode ? '' : 'readonly'}
              selectedFilter={`countryCode: ${port?.countryCode}`}
              id={'stateCode'}
              header={'State'}
              required={true}
              defaultValue={
                port && port.stateCode && port.stateName
                  ? {
                      stateCode: port.stateCode,
                      name: port.stateName,
                      countryCode: port.countryCode,
                    }
                  : ''
              }
              onChange={(state?: StateDto) => {
                setPort((oldPort) => {
                  if (!oldPort) {
                    oldPort = initialState;
                  }
                  oldPort.stateCode = state?.stateCode;
                  oldPort.stateName = state?.name;
                  return { ...oldPort };
                });
              }}
              nameId={'stateName'}
            />
            <PortForm.Remarks />
            <div className="mt-3">
              <div className="mb-2 text-dark">Transportation Method</div>
              <div className="row mb-3">
                <div className="col-6">
                  <PortForm.Maritime />
                  <PortForm.Rail />
                  <PortForm.Road />
                </div>
                <div className="col-6">
                  <PortForm.Air />
                  <PortForm.Mail />
                  <PortForm.BorderCrossingPoint />
                </div>
              </div>
              <PortForm.IsMyCompany />
            </div>
            <div className="justify-content-end row">
              {(userHas(UPDATE_PORT_LINK_KEY, port?.links) || isCreateMode) && (
                <div className="col-6">
                  <Button
                    form={'port-form'}
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Port
                  </Button>
                </div>
              )}
              <div className="col-6">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </PortForm>
        </div>
      </div>
    </Panel>
  );
};
