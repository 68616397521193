import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CarrierPayManagerListScreen } from './screens/carrierPayManager-list.screen';

export const CARRIER_PAY_MANAGER_LIST_PATH = '/carrier-pay-manager';
export const CARRIER_PAY_MANAGER_PATH =
  '/org/:organizationId/carrier-pay-manager';

export const CarrierPayManagerRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <PrivateRoute
        exact
        path={`${match.path}${CARRIER_PAY_MANAGER_LIST_PATH}`}
      >
        <CarrierPayManagerListScreen pageTitle="Carrier Pay Manager" />
      </PrivateRoute>
    </Route>
  );
};
