import {
  PaymentDto,
  LinkDto,
  LinkResourceBaseDto,
  PaymentDtoPagedResult,
  CreatePaymentCommandValues,
  UpdatePaymentCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPaymentRequest,
  getPaymentsListRequest,
  getPaymentRequest,
  deletePaymentRequest,
  updatePaymentRequest,
  GetPaymentParams,
} from './payments.service';

type PaymentsStoreState = {
  links: LinkDto[];
  paymentColumns: Column[];
};

export const updatePaymentsColumns = createEvent<Column[]>();

export const getPayments = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPaymentsListRequest(currentOrganization, params);
});

export const createPayment = createEffect((paymentData: PaymentDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  paymentData.checkNumber = paymentData.checkNumber ?? '';

  const createPaymentCommand: CreatePaymentCommandValues = { ...paymentData };

  return createPaymentRequest(currentOrganization!, createPaymentCommand);
});

export const getPayment = createEffect((paymentParams: GetPaymentParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPaymentRequest(
    currentOrganization as LinkResourceBaseDto,
    paymentParams,
  );
});

export const updatePayment = createEffect((payment: PaymentDto) => {
  payment.checkNumber = payment.checkNumber ?? '';
  const updatePaymentCommand: UpdatePaymentCommandValues = { ...payment };
  return updatePaymentRequest(payment, updatePaymentCommand);
});

export const deletePayment = createEffect((payment: PaymentDto) => {
  return deletePaymentRequest(payment);
});

const defaultState: PaymentsStoreState = {
  links: [],
  paymentColumns: [
    {
      name: 'paymentId',
      visible: true,
      title: 'Payment Id',
      sortName: 'paymentId',
    },
    {
      name: 'applyToContactName',
      visible: true,
      title: 'Apply To',
      sortName: 'ApplyToContact.Name',
    },
    {
      name: 'paymentDate',
      visible: true,
      title: 'Payment Date',
      sortName: 'paymentDate',
    },
    {
      name: 'amountReceived',
      visible: true,
      title: 'Amount Received',
      sortName: 'amountReceived',
    },
    {
      name: 'checkNumber',
      visible: true,
      title: 'Check Number',
      sortName: 'checkNumber',
    },
    { name: 'memo', visible: true, title: 'Memo', sortName: 'memo' },
    {
      name: 'accountingAccountName',
      visible: true,
      title: 'Accounting Account Name',
      sortName: 'AccountingAccount.AccountName',
    },
    {
      name: 'currencyName',
      visible: false,
      title: 'Currency Name',
      sortName: 'Currency.CurrencyName',
    },
    {
      name: 'divisionName',
      visible: false,
      title: 'Division Name',
      sortName: 'Division.DivisionName',
    },
    {
      name: 'paymentStatus',
      visible: true,
      title: 'Payment Status',
      sortName: 'paymentStatus',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'createdByUserName',
      visible: false,
      title: 'Created By',
      sortName: 'CreatedUser.UserName',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
    {
      name: 'updatedByUserName',
      visible: false,
      title: 'Last Modified by',
      sortName: 'UpdatedUser.UserName',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
  ],
};

export const paymentStore: Store<PaymentsStoreState> = createStore(
  defaultState,
).on(updatePaymentsColumns, (state, payload) => {
  return { ...state, paymentColumns: payload };
});
