import React from 'react';
import { SiQuickbooks } from 'react-icons/si';
import { AccountingTransactionType } from '../../../models/data.models';

export type AccountingTransactionQuickBooksProps = {
  quickBooksId: string;
  quickBooksDocNumber: string;
  accountingTransactionType: AccountingTransactionType;
};

export const AccountingTransactionQuickBooks = ({
  quickBooksId = '',
  quickBooksDocNumber = '',
  accountingTransactionType,
}: AccountingTransactionQuickBooksProps) => {
  return (
    <a
      href={`https://app.sandbox.qbo.intuit.com/app/${accountingTransactionType.toLowerCase()}?txnId=${quickBooksId}`}
      target="_blank"
      className="justify-content-between ml-2 mt-2 mb-4"
    >
      <div className="accounting-transaction-quickbooks d-flex align-items-center justify-content-between p-2">
        <SiQuickbooks size={26} color="green" />
        Doc Number{' '}
        <b>
          <i>{quickBooksDocNumber}</i>
        </b>
      </div>
    </a>
  );
};
