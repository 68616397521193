import React from 'react';
import { Panel } from '../../common/components/panel/panel.component';
import { SignUp, SignUpData } from '../components/signup.component';
import { getUserInfo, signUp } from '../auth.store';
import { getOrganizations } from '../../organization/organization.store';
import { useHistory } from 'react-router-dom';
import { OUTER_ORGANIZATION_CREATE_PATH } from '../../organization/organization.route';
import { Helmet } from 'react-helmet';

export type SignUpScreenProps = { pageTitle: string };

export const SignUpScreen = ({ pageTitle }: SignUpScreenProps) => {
  const history = useHistory();
  const onSubmit = (data: SignUpData) => {
    signUp(data).then(() => {
      return getUserInfo().then(() => {
        return getOrganizations({ sort: 'organizationId' }).then(() => {
          history.push(OUTER_ORGANIZATION_CREATE_PATH);
        });
      });
    });
  };
  return (
    <div className="py-5">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="d-flex justify-content-center">
        <Panel style={{ maxWidth: '500px', width: '100%' }}>
          <h1 className="text-center login-register-header font-weight-light">
            Sign Up
          </h1>
          <SignUp onSubmit={onSubmit} />
        </Panel>
      </div>
    </div>
  );
};
