import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { CountryEdit } from '../components/country-edit.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { COUNTRIES_LIST_PATH, COUNTRY_CREATE_PATH } from '../countries.route';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_COUNTRY_LINK_KEY } from '../countries.service';
import { userHas } from '../../auth/auth.store';

export type CountryScreenProps = { pageTitle: string };

export const CountryScreen = ({ pageTitle }: CountryScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, countryCode } = useParams();

  const createPath = generatePath(INTERNAL_PATH + COUNTRY_CREATE_PATH, {
    organizationId,
  });

  const onCountryCreated = () => {
    const path = generatePath(INTERNAL_PATH + COUNTRIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'country-created',
      type: 'success',
      autoHide: true,
      message: 'Country successfully created!',
    };
    history.push(path);
    addMessage(message);
  };

  const onCountryUpdated = () => {
    const path = generatePath(INTERNAL_PATH + COUNTRIES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'country-updated',
      type: 'success',
      autoHide: true,
      message: 'Country successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + COUNTRIES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };

  return (
    <InternalLayout
      title={'Country'}
      createPath={userHas(CREATE_COUNTRY_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CountryEdit
        countryCode={countryCode === 'create' ? null : countryCode}
        onCountryUpdated={onCountryUpdated}
        onCountryCreated={onCountryCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
