import { createStore, Store, Event, createEvent } from 'effector';
import { requestError } from '../api/api.store';
import { v4 } from 'uuid';

export type Message = {
  id?: string;
  type: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger';
  message: string;
  autoHide?: boolean;
};

type MessagesStoreState = {
  messages: Message[];
};

export const addMessage: Event<Message> = createEvent();
export const removeMessage: Event<Message> = createEvent();
export const clearMessages = createEvent();

const defaultState: MessagesStoreState = {
  messages: [],
};

export const messagesStore: Store<MessagesStoreState> = createStore(
  defaultState,
)
  .on(requestError, (state, payload) => {
    if (payload.data) {
      const errors = [];
      if (payload.data.errors) {
        if (Array.isArray(payload.data.errors)) {
          errors.push(payload.data.errors);
        } else {
          const keys = Object.keys(payload.data.errors);
          keys.forEach((key) => {
            errors.push(payload.data.errors[key]);
          });
        }
      } else if (payload.data.detail) errors.push(payload.data.detail);
      else if (payload.data.error_description) {
        const message =
          (payload.data &&
            payload.data.error_description &&
            payload.data.error_description.split('_').join(' ')) ||
          payload.statusText;
        errors.push(message);
      } else if (payload.data.status === 500) errors.push(payload.data.title);
      errors.forEach((error) => {
        if (Array.isArray(error)) {
          error.forEach((subError) =>
            addMessage({ id: v4(), message: subError, type: 'danger' }),
          );
        } else {
          addMessage({ id: v4(), message: error, type: 'danger' });
        }
      });
    } else {
      const message =
        (payload.data &&
          payload.data.error_description &&
          payload.data.error_description.split('_').join(' ')) ||
        payload.statusText;
      addMessage({ id: v4(), message, type: 'danger' });
    }
  })
  .on(addMessage, (state: MessagesStoreState, payload: Message) => {
    return { messages: [...state.messages, payload] };
  })
  .on(removeMessage, (state: MessagesStoreState, payload: Message) => {
    return {
      messages: state.messages.filter((x) => x.id !== payload.id),
    };
  })
  .on(clearMessages, () => ({ messages: [] }));
