import {
  ModeOfTransportationDto,
  LinkDto,
  LinkResourceBaseDto,
  ModeOfTransportationDtoPagedResult,
  CreateModeOfTransportationCommandValues,
  UpdateModeOfTransportationCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_MODEOFTRANSPORTATIONS_LINK_KEY = 'get-modeoftransportations';
export const CREATE_MODEOFTRANSPORTATION_LINK_KEY =
  'create-modeoftransportation';
export const GET_MODEOFTRANSPORTATION_LINK_KEY = 'get-modeoftransportation';
export const UPDATE_MODEOFTRANSPORTATION_LINK_KEY =
  'update-modeoftransportation';
export const DELETE_MODEOFTRANSPORTATION_LINK_KEY =
  'delete-modeoftransportation';

export const MODE_OF_TRANSPORTATION_ENTITY_NAME = 'Mode of Transportation';

export interface GetModeOfTransportationParams {
  modeOfTransportationId: number;
}

export const getModeOfTransportationRequest = async (
  resource: LinkResourceBaseDto | null,
  { modeOfTransportationId }: GetModeOfTransportationParams,
): Promise<ModeOfTransportationDto> => {
  if (resource && modeOfTransportationId) {
    const getModeOfTransportationLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_MODEOFTRANSPORTATION_LINK_KEY,
    );
    if (getModeOfTransportationLink) {
      const result = await execLink(getModeOfTransportationLink, {
        modeOfTransportationId,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_MODEOFTRANSPORTATION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getModeOfTransportationsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ModeOfTransportationDtoPagedResult> => {
  if (resource) {
    const getModeOfTransportationsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_MODEOFTRANSPORTATIONS_LINK_KEY,
    );
    if (getModeOfTransportationsListLink) {
      const result = await execLink(getModeOfTransportationsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_MODEOFTRANSPORTATIONS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createModeOfTransportationRequest = async (
  resource: LinkResourceBaseDto,
  modeOfTransportation: CreateModeOfTransportationCommandValues,
) => {
  const createModeOfTransportationLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_MODEOFTRANSPORTATION_LINK_KEY,
  );
  if (createModeOfTransportationLink) {
    const result = await execLink(
      createModeOfTransportationLink,
      modeOfTransportation,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_MODEOFTRANSPORTATION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateModeOfTransportationRequest = async (
  resource: LinkResourceBaseDto,
  modeOfTransportation: UpdateModeOfTransportationCommandValues,
) => {
  const updateModeOfTransportationLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_MODEOFTRANSPORTATION_LINK_KEY,
  );
  if (updateModeOfTransportationLink) {
    const result = await execLink(
      updateModeOfTransportationLink,
      modeOfTransportation,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_MODEOFTRANSPORTATION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteModeOfTransportationRequest = async (
  modeOfTransportation: ModeOfTransportationDto,
) => {
  const deleteModeOfTransportationLink = modeOfTransportation.links?.find(
    (x: LinkDto) => x.rel === DELETE_MODEOFTRANSPORTATION_LINK_KEY,
  );
  if (deleteModeOfTransportationLink) {
    const result = await execLink(
      deleteModeOfTransportationLink,
      modeOfTransportation,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_MODEOFTRANSPORTATION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
