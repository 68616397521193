import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { ContainerTypeForm } from './containerType.form';
import { ContainerTypeDto } from '../../../models/data.models';
import {
  createContainerType,
  getContainerType,
  updateContainerType,
} from '../containerTypes.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ContainerTypeDefaultValues } from '../../common/DefaultValues';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_CONTAINERTYPE_LINK_KEY } from '../containerTypes.service';

export type ContainerTypeEditProps = {
  containerTypeCode?: string | null;
  onContainerTypeCreated?: (containerType: ContainerTypeDto) => void;
  onContainerTypeUpdated?: (containerType: ContainerTypeDto) => void;
  onContainerTypeLoaded?: (containerType: ContainerTypeDto) => void;
  onCancel?: () => void;
};

const initialState: ContainerTypeDto = {
  organizationId: 0,
  containerTypeCode: ContainerTypeDefaultValues.containerTypeCode,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: ContainerTypeDefaultValues.description,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  links: [],
};

const containerTypeSchema = Yup.object().shape({
  containerTypeCode: Yup.string()
    .required("Can't be blank")
    .max(10, 'Max length is 10')
    .nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
});

export const ContainerTypeEdit = ({
  containerTypeCode = null,
  onContainerTypeLoaded = () => {},
  onContainerTypeCreated = () => {},
  onContainerTypeUpdated = () => {},
  onCancel = () => {},
}: ContainerTypeEditProps) => {
  const isCreateMode = !containerTypeCode || containerTypeCode === ' ';
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [containerType, setContainerType] = useState<ContainerTypeDto | null>(
    null,
  );

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (containerTypeCode) {
      getContainerType({ containerTypeCode }).then(
        (containerTypeDto: ContainerTypeDto) => {
          setContainerType(containerTypeDto);
          setIsLoading(false);
          onContainerTypeLoaded(containerTypeDto);
        },
        () => {},
      );
    } else {
      throw new Error('ContainerType keys were not provided');
    }
  }, [containerTypeCode]);

  const onSubmit = (data: ContainerTypeDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createContainerType(data)
        .then(
          (result) => {
            onContainerTypeCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateContainerType(data)
        .then(
          (result) => {
            onContainerTypeUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Tabs>
      <TabList>
        <Tab name="containerType">Container Type</Tab>
      </TabList>
      <ContainerTypeForm
        id={'containerType-form'}
        initialValues={containerType || initialState}
        onSubmit={onSubmit}
        validationSchema={containerTypeSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add Container Type</h2>
            ) : (
              <h2>Update Container Type</h2>
            )}
            <div className="row">
              <div className="col-4">
                <ContainerTypeForm.ContainerTypeCode disabled={!isCreateMode} />
                <ContainerTypeForm.Description />
              </div>
            </div>
            <div className="justify-content-end row">
              {(userHas(UPDATE_CONTAINERTYPE_LINK_KEY, containerType?.links) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    form={'containerType-form'}
                    name="save-containerType"
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Container Type
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </ContainerTypeForm>
    </Tabs>
  );
};
