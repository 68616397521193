import {
  ChargeDto,
  LinkDto,
  LinkResourceBaseDto,
  ChargeDtoPagedResult,
  CreateChargeCommandValues,
  UpdateChargeCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createChargeRequest,
  getChargesListRequest,
  getChargeRequest,
  deleteChargeRequest,
  updateChargeRequest,
  GetChargeParams,
} from './charges.service';

type ChargesStoreState = {
  links: LinkDto[];
  chargeColumns: Column[];
};

export const updateChargesColumns = createEvent<Column[]>();

export const getCharges = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getChargesListRequest(currentOrganization, params);
});

export const createCharge = createEffect((chargeData: ChargeDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createChargeCommand: CreateChargeCommandValues = { ...chargeData };

  return createChargeRequest(currentOrganization!, createChargeCommand);
});

export const getCharge = createEffect((chargeParams: GetChargeParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getChargeRequest(
    currentOrganization as LinkResourceBaseDto,
    chargeParams,
  );
});

export const updateCharge = createEffect((charge: ChargeDto) => {
  const updateChargeCommand: UpdateChargeCommandValues = { ...charge };
  return updateChargeRequest(charge, updateChargeCommand);
});

export const deleteCharge = createEffect((charge: ChargeDto) => {
  return deleteChargeRequest(charge);
});

const defaultState: ChargesStoreState = {
  links: [],
  chargeColumns: [
    { name: 'chargeStatus', visible: true, title: 'Status' },
    { name: 'description', visible: true, title: 'Description' },
    { name: 'prepaid', visible: true, title: 'Prepaid' },
    { name: 'quantity', visible: true, title: 'Quantity' },
    { name: 'formattedPrice', visible: true, title: 'Price' },
    { name: 'formattedAmount', visible: true, title: 'Amount' },
    { name: 'salesTaxCode', visible: false, title: 'Tax Code' },
    { name: 'salesTaxRate', visible: true, title: 'Tax Rate' },
    { name: 'salesTaxAmount', visible: true, title: 'Tax Amount' },
    { name: 'amountAndTaxAmount', visible: true, title: 'Amount + Tax Amount' },
    { name: 'currencySymbol', visible: true, title: 'Currency' },
    { name: 'formattedExpense', visible: true, title: 'Expense' },
    { name: 'formattedIncome', visible: true, title: 'Income' },
    { name: 'applyToContactName', visible: true, title: 'Apply To' },
  ],
};

const chargeForAccountingTransactionState: ChargesStoreState = {
  links: [],
  chargeColumns: [
    { name: 'chargeStatus', visible: true, title: 'Status' },
    { name: 'description', visible: true, title: 'Description' },
    { name: 'prepaid', visible: true, title: 'Prepaid' },
    { name: 'quantity', visible: true, title: 'Quantity' },
    { name: 'formattedPrice', visible: true, title: 'Price' },
    { name: 'formattedAmount', visible: true, title: 'Amount' },
    { name: 'salesTaxCode', visible: true, title: 'Tax Code' },
  ],
};

export const chargeStore: Store<ChargesStoreState> = createStore(
  defaultState,
).on(updateChargesColumns, (state, payload) => {
  return { ...state, chargeColumns: payload };
});

export const chargeStoreForAccountingTransaction: Store<ChargesStoreState> = createStore(
  chargeForAccountingTransactionState,
).on(updateChargesColumns, (state, payload) => {
  return { ...state, chargeColumns: payload };
});
