import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CustomFieldsListScreen } from './screens/customFields-list.screen';
import { CustomFieldScreen } from './screens/customField.screen';

export const CUSTOM_FIELDS_LIST_PATH = '/customFields';
export const CUSTOM_FIELDS_EDIT_PATH = '/customFields/:fieldId';
export const CUSTOM_FIELDS_CREATE_PATH = '/customFields/0';
export const CUSTOM_FIELDS_PATH = '/org/:organizationId/customFields';

export const CustomFieldRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${CUSTOM_FIELDS_LIST_PATH}`}>
          <CustomFieldsListScreen pageTitle="Custom Fields" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CUSTOM_FIELDS_CREATE_PATH}`}>
          <CustomFieldScreen pageTitle="Add New Custom Field" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CUSTOM_FIELDS_EDIT_PATH}`}>
          <CustomFieldScreen pageTitle="Update Custom Field" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
