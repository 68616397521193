import {
  EquipmentTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  EquipmentTypeDtoPagedResult,
  CreateEquipmentTypeCommandValues,
  UpdateEquipmentTypeCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createEquipmentTypeRequest,
  getEquipmentTypesListRequest,
  getEquipmentTypeRequest,
  deleteEquipmentTypeRequest,
  updateEquipmentTypeRequest,
  GetEquipmentTypeParams,
} from './equipmentType.service';

type EquipmentTypesStoreState = {
  links: LinkDto[];
  equipmentTypeColumns: Column[];
};

export const updateEquipmentTypesColumns = createEvent<Column[]>();

export const getEquipmentTypes = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getEquipmentTypesListRequest(currentOrganization, params);
});

export const createEquipmentType = createEffect(
  (equipmentTypeData: EquipmentTypeDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createEquipmentTypeCommand: CreateEquipmentTypeCommandValues = {
      ...equipmentTypeData,
    };

    return createEquipmentTypeRequest(
      currentOrganization!,
      createEquipmentTypeCommand,
    );
  },
);

export const getEquipmentType = createEffect(
  (equipmentTypeParams: GetEquipmentTypeParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getEquipmentTypeRequest(
      currentOrganization as LinkResourceBaseDto,
      equipmentTypeParams,
    );
  },
);

export const updateEquipmentType = createEffect(
  (equipmentType: EquipmentTypeDto) => {
    const updateEquipmentTypeCommand: UpdateEquipmentTypeCommandValues = {
      ...equipmentType,
    };
    return updateEquipmentTypeRequest(
      equipmentType,
      updateEquipmentTypeCommand,
    );
  },
);

export const deleteEquipmentType = createEffect(
  (equipmentType: EquipmentTypeDto) => {
    return deleteEquipmentTypeRequest(equipmentType);
  },
);

const defaultState: EquipmentTypesStoreState = {
  links: [],
  equipmentTypeColumns: [
    {
      name: 'equipmentTypeId',
      visible: true,
      title: 'Equipment Type Id',
      sortName: 'equipmentTypeId',
    },
    { name: 'name', visible: true, title: 'Name', sortName: 'name' },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
  ],
};

export const equipmentTypeStore: Store<EquipmentTypesStoreState> = createStore(
  defaultState,
).on(updateEquipmentTypesColumns, (state, payload) => {
  return { ...state, equipmentTypeColumns: payload };
});

export const equipmentTypeFilterFields: any = [
  'equipmentTypeId',
  'created',
  'createdBy',
  'lastModified',
  'lastModifiedBy',
  'name',
  'organizationId',
];
