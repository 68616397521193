import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { ContainerDescriptionForm } from './containerDescription.form';
import { ContainerDescriptionDto } from '../../../models/data.models';
import {
  createContainerDescription,
  getContainerDescription,
  updateContainerDescription,
} from '../containerDescriptions.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ContainerDescriptionDefaultValues } from '../../common/DefaultValues';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as Yup from 'yup';
import { UPDATE_CONTAINERDESCRIPTION_LINK_KEY } from '../containerDescriptions.service';
import { userHas } from '../../auth/auth.store';

export type ContainerDescriptionEditProps = {
  containerDescriptionCode?: string | null;
  onContainerDescriptionCreated?: (
    containerDescription: ContainerDescriptionDto,
  ) => void;
  onContainerDescriptionUpdated?: (
    containerDescription: ContainerDescriptionDto,
  ) => void;
  onContainerDescriptionLoaded?: (
    containerDescription: ContainerDescriptionDto,
  ) => void;
  onCancel?: () => void;
};

const initialState: ContainerDescriptionDto = {
  organizationId: 0,
  containerDescriptionCode:
    ContainerDescriptionDefaultValues.containerDescriptionCode,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: ContainerDescriptionDefaultValues.description,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  links: [],
};

const containerDescriptionSchema = Yup.object().shape({
  containerDescriptionCode: Yup.string()
    .required("Can't be blank")
    .max(10, 'Max length is 10')
    .nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
});

export const ContainerDescriptionEdit = ({
  containerDescriptionCode,
  onContainerDescriptionLoaded = () => {},
  onContainerDescriptionCreated = () => {},
  onContainerDescriptionUpdated = () => {},
  onCancel = () => {},
}: ContainerDescriptionEditProps) => {
  const isCreateMode =
    !containerDescriptionCode || containerDescriptionCode === ' ';
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    containerDescription,
    setContainerDescription,
  ] = useState<ContainerDescriptionDto | null>(null);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (containerDescriptionCode) {
      getContainerDescription({ containerDescriptionCode }).then(
        (containerDescriptionDto: ContainerDescriptionDto) => {
          setContainerDescription(containerDescriptionDto);
          setIsLoading(false);
          onContainerDescriptionLoaded(containerDescriptionDto);
        },
        () => {},
      );
    } else {
      throw new Error('ContainerDescription keys were not provided');
    }
  }, [containerDescriptionCode]);

  const onSubmit = (data: ContainerDescriptionDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createContainerDescription(data)
        .then(
          (result) => {
            onContainerDescriptionCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateContainerDescription(data)
        .then(
          (result) => {
            onContainerDescriptionUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Tabs>
      <TabList>
        <Tab name="containerDescription">Container Description</Tab>
      </TabList>
      <ContainerDescriptionForm
        id={'containerDescription-form'}
        initialValues={containerDescription || initialState}
        onSubmit={onSubmit}
        validationSchema={containerDescriptionSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add Container Description</h2>
            ) : (
              <h2>Update Container Description</h2>
            )}
            <div className="row">
              <div className="col-4">
                <ContainerDescriptionForm.ContainerDescriptionCode
                  disabled={!isCreateMode}
                />
                <ContainerDescriptionForm.Description />
              </div>
            </div>
            <div className="justify-content-end row">
              {(userHas(
                UPDATE_CONTAINERDESCRIPTION_LINK_KEY,
                containerDescription?.links,
              ) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    form={'containerDescription-form'}
                    name="save-containerDescription"
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Container Description
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </ContainerDescriptionForm>
    </Tabs>
  );
};
