import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  Column,
  CustomFieldEntityType,
  OrderDto,
  OrderDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { getOrders, deleteOrder, createOrder } from '../../orders/orders.store';
import { quotesStore, updateQuotesColumns } from '../quotes.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_ORDER_LINK_KEY,
  DELETE_ORDER_LINK_KEY,
  GetOrderParams,
} from '../../orders/orders.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { QUOTE_CREATE_PATH } from '../quote.route';
import {
  OrderDtoForListPagedResult,
  OrderForListDto,
} from '../../../models/custom.models';
import { QuoteDialog } from './quote.dialog';
import { getCustomFields } from '../../customFields/customFields.store';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { userHas } from '../../auth/auth.store';
import { QuoteGrid } from '../../common/components/grid/quote-grid.component';

export type QuotesListProps = {
  goToDetails?: (quoteParams: GetOrderParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (quote: OrderDto, quoteId: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const QuotesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch = () => {},
}: QuotesListProps) => {
  const [quotes, setQuotes] = useState<OrderDtoPagedResult | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCustomFieldsColumns();
    getQuotesData();
  }, [offset, limit, sort, filter, search]);
  const getQuotesData = () => {
    getOrders({ offset, limit, sort, filter, search }).then(
      (quotesData) => {
        const quoteDtoResult: OrderDtoForListPagedResult = {
          limit: quotesData.limit,
          offset: quotesData.offset,
          links: quotesData.links,
          totalCount: quotesData.totalCount,
          items: quotesData.items.map<OrderForListDto>((item) => {
            const resultQuote: OrderForListDto = item;
            resultQuote.firstOrderPickupsPickDate = item?.orderPickups[0]
              ?.pickDate
              ? getFormattedDate(item?.orderPickups[0]?.pickDate, false)
              : null;
            resultQuote.firstOrderDeliveriesDeliveryDate = item
              ?.orderDeliveries[0]?.deliveryDate
              ? getFormattedDate(item?.orderDeliveries[0]?.deliveryDate, false)
              : null;
            let firstOrderDeliveriesConsigneeAddressName = '';
            if (item?.orderDeliveries[0]?.consigneeContactName) {
              firstOrderDeliveriesConsigneeAddressName += `${
                item?.orderDeliveries[0]?.consigneeContactName ?? ''
              }`;
            }
            if (item?.orderDeliveries[0]?.consigneeCityName) {
              if (item?.orderDeliveries[0]?.consigneeContactName) {
                firstOrderDeliveriesConsigneeAddressName += ' / ';
              }
              firstOrderDeliveriesConsigneeAddressName += `${
                item?.orderDeliveries[0]?.consigneeCityName ?? ''
              }`;
            }
            if (item?.orderDeliveries[0]?.consigneeStateCode) {
              if (item?.orderDeliveries[0]?.consigneeCityName) {
                firstOrderDeliveriesConsigneeAddressName += ', ';
              }
              firstOrderDeliveriesConsigneeAddressName += `${
                item?.orderDeliveries[0]?.consigneeStateCode ?? ''
              }`;
            }

            let firstOrderPickupsShipperAddressName = '';
            if (item?.orderPickups[0]?.shipperContactName) {
              firstOrderPickupsShipperAddressName += `${
                item?.orderPickups[0]?.shipperContactName ?? ''
              }`;
            }
            if (item?.orderPickups[0]?.shipperCityName) {
              if (item?.orderPickups[0]?.shipperContactName) {
                firstOrderPickupsShipperAddressName += ' / ';
              }
              firstOrderPickupsShipperAddressName += `${
                item?.orderPickups[0]?.shipperCityName ?? ''
              }`;
            }
            if (item?.orderPickups[0]?.shipperStateCode) {
              if (item?.orderPickups[0]?.shipperCityName) {
                firstOrderPickupsShipperAddressName += ', ';
              }
              firstOrderPickupsShipperAddressName += `${
                item?.orderPickups[0]?.shipperStateCode ?? ''
              }`;
            }
            resultQuote.firstOrderDeliveriesConsigneeAddressName = firstOrderDeliveriesConsigneeAddressName;
            resultQuote.firstOrderPickupsShipperAddressName = firstOrderPickupsShipperAddressName;
            if (item?.customValues) {
              Object.keys(item.customValues).forEach(
                (customFieldInternalName) => {
                  if (!resultQuote.hasOwnProperty(customFieldInternalName))
                    resultQuote[customFieldInternalName] =
                      item.customValues[customFieldInternalName];
                },
              );
            }
            return item;
          }),
        };
        setQuotes(quoteDtoResult);
        setLoading(false);
      },
      () => {},
    );
  };
  const getCustomFieldsColumns = () => {
    getCustomFields({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Order} AND isInactive: false AND isSystem: false`,
    }).then(
      (customFieldsData) => {
        const customFieldsColumns: Column[] = [];
        const customFieldsColumnsNames: string[] = [];
        customFieldsData.items.forEach((customFieldDto) => {
          customFieldsColumns.push({
            name: customFieldDto.internalName,
            visible: true,
            title: customFieldDto.displayName,
            showFilter: true,
            type: 'customField',
            customFieldType: customFieldDto.customFieldType,
          });
          customFieldsColumnsNames.push(customFieldDto.internalName);
        });
        const existingColumns = quotesStore.getState().quoteColumns;
        const existingColumnsNames: string[] = existingColumns.map((column) => {
          return column.name;
        });
        const customFieldsColumnsToAdd: Column[] = [];
        customFieldsColumns.forEach((customColumn) => {
          if (existingColumnsNames.indexOf(customColumn.name) === -1) {
            customFieldsColumnsToAdd.push(customColumn);
          }
        });
        for (
          let i = quotesStore.defaultState.quoteColumns.length;
          i < existingColumns.length;
          i++
        ) {
          if (
            customFieldsColumnsNames.indexOf(existingColumns[i].name) === -1
          ) {
            existingColumns.splice(i, 1);
          }
        }
        if (customFieldsColumnsToAdd.length > 0) {
          updateQuotesColumns([
            ...existingColumns,
            ...customFieldsColumnsToAdd,
          ]);
        }
      },
      () => {},
    );
  };

  const { quoteColumns: columns } = useStore(quotesStore);

  const onDeleteQuote = (quote: OrderDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${quote.orderNumber} Quote`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteOrder(quote).then(
            () => {
              onDelete();
              getQuotesData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditQuote = (quote: OrderDto) => {
    goToDetails({ orderId: quote.orderId });
  };
  const onCopyQuote = (quote: OrderDto) => {
    const quoteCopy = { ...quote };
    delete quoteCopy.charges;
    createOrder(quoteCopy).then(
      (result) => {
        getQuotesData();
      },
      () => {},
    );
  };
  const history = useHistory();
  const onCreateNewQuote = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + QUOTE_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onChangeItem = (data?: any[]) => {
    setQuotes((quotesDto) => {
      quotesDto.items = data;
      return { ...quotesDto };
    });
  };
  const onQuoteSelect = (quote, orderId) => {
    if (onSelect) onSelect(quote, orderId);
    if (quote?.orderId) {
      showDialog({
        dialog: QuoteDialog,
        props: {
          className: 'order-modal',
          title: 'Update Quote',
          orderId: quote?.orderId,
          history: history,
        },
      }).then(
        (result) => {
          if (result !== null) {
            getQuotesData();
          }
        },
        () => {},
      );
    }
  };
  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <div>
        <QuoteGrid
          showEmptyTable={true}
          showAllStore={true}
          rowKeys={null}
          data={quotes?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={quotes?.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_ORDER_LINK_KEY, quotes?.items[0]?.links)
              ? onDeleteQuote
              : null
          }
          onSort={(field) => {
            onSort(field);
          }}
          search={search}
          onEdit={onEditQuote}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateQuotesColumns}
          onSelect={onQuoteSelect}
          onChangeItem={onChangeItem}
          filter={filter}
          onFilter={onFilter}
          onSearch={onSearch}
        />
        {!search && !quotes.items.length && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">No Quotes Found</h3>
            {userHas(CREATE_ORDER_LINK_KEY) && (
              <Button size={'lg'} color="primary" onClick={onCreateNewQuote}>
                Create Quote
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};
