import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { DocumentTemplateForm } from './documentTemplate.form';
import {
  DocumentTemplateDto,
  DocumentTemplateType,
  OrderStatuses,
} from '../../../models/data.models';
import {
  createDocumentTemplate,
  getDocumentTemplate,
  updateDocumentTemplate,
} from '../documentTemplates.store';
import { Panel } from '../../common/components/panel/panel.component';
import { DocumentTemplateEditFormDto } from '../../../models/custom.models';
import { FormContext } from '../../common/components/form/form.component';
import { getEnumKeyByValue, getEnumValues } from '../../../utils/helper.utils';
import { DocumentTemplateDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import beautify from 'beautify';
import { userHas } from '../../auth/auth.store';
import { UPDATE_DOCUMENTTEMPLATE_LINK_KEY } from '../documentTemplates.service';

export type DocumentTemplateEditProps = {
  documentTemplateId?: number | null;
  onDocumentTemplateCreated?: (documentTemplate: DocumentTemplateDto) => void;
  onDocumentTemplateUpdated?: (documentTemplate: DocumentTemplateDto) => void;
  onDocumentTemplateLoaded?: (documentTemplate: DocumentTemplateDto) => void;
  onCancel?: () => void;
};

const initialState: DocumentTemplateDto = {
  documentTemplateId: 0,
  bodyHtmlTemplate: DocumentTemplateDefaultValues.bodyHtmlTemplate,
  bodyTextTemplate: DocumentTemplateDefaultValues.bodyTextTemplate,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: DocumentTemplateDefaultValues.description,
  documentTemplateType: DocumentTemplateDefaultValues.documentTemplateType,
  fileNameTemplate: DocumentTemplateDefaultValues.fileNameTemplate,
  isDefault: DocumentTemplateDefaultValues.isDefault,
  isInactive: DocumentTemplateDefaultValues.isInactive,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  name: DocumentTemplateDefaultValues.documentTemplateName,
  organizationId: 0,
  subjectTemplate: DocumentTemplateDefaultValues.subjectTemplate,
  createdByUserName: '',
  updatedByUserName: '',
  links: [],
};

const documentTemplatesSchema = Yup.object().shape(
  {
    bodyHtmlTemplate: Yup.string().when('bodyTextTemplate', {
      is: (bodyTextTemplate) => bodyTextTemplate?.length > 0,
      then: Yup.string().nullable(true),
      otherwise: Yup.string()
        .required(
          'Either Body Html Template or Body Text Template should not be blank',
        )
        .nullable(true),
    }),
    bodyTextTemplate: Yup.string().when('bodyHtmlTemplate', {
      is: (bodyHtmlTemplate) => bodyHtmlTemplate?.length > 0,
      then: Yup.string().nullable(true),
      otherwise: Yup.string()
        .required(
          'Either Body Html Template or Body Text Template should not be blank',
        )
        .nullable(true),
    }),
    description: Yup.string().required("Can't be blank").nullable(true),
    documentTemplateType: Yup.string()
      .required("Can't be blank")
      .nullable(true),
    name: Yup.string().required("Can't be blank").nullable(true),
  },
  [['bodyHtmlTemplate', 'bodyTextTemplate']],
);

export const DocumentTemplateEdit = ({
  documentTemplateId,
  onDocumentTemplateLoaded = () => {},
  onDocumentTemplateCreated = () => {},
  onDocumentTemplateUpdated = () => {},
  onCancel = () => {},
}: DocumentTemplateEditProps) => {
  const isCreateMode = !documentTemplateId || documentTemplateId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [
    documentTemplateContextFormValue,
    setDocumentTemplateContextFormValue,
  ] = useState<DocumentTemplateEditFormDto | null>(null);
  const [
    initialValues,
    setInitialValues,
  ] = useState<DocumentTemplateEditFormDto | null>(initialState);

  const [bodyHtmlTemplate, setBodyHtmlTemplate] = useState<string | null>(null);

  const [bodyTextTemplate, setBodyTextTemplate] = useState<string | null>(null);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
      const value = Object.keys(DocumentTemplateType).find(
        (key) =>
          DocumentTemplateType[key] ===
          DocumentTemplateDefaultValues.documentTemplateType,
      );
      onDocumentTemplateTypeChange({
        value,
        label: DocumentTemplateDefaultValues.documentTemplateType,
      });
    } else if (documentTemplateId) {
      getDocumentTemplate({ documentTemplateId }).then(
        (documentTemplateDto: DocumentTemplateDto) => {
          if (documentTemplateDto.bodyHtmlTemplate) {
            documentTemplateDto.bodyHtmlTemplate = beautify(
              documentTemplateDto?.bodyHtmlTemplate,
              { format: 'html' },
            );
          }
          setBodyHtmlTemplate(documentTemplateDto.bodyHtmlTemplate);
          setBodyTextTemplate(documentTemplateDto.bodyTextTemplate);
          setDocumentTemplateContextFormValue(
            (documentTemplateContextFormValue) => {
              if (!documentTemplateDto) {
                documentTemplateContextFormValue = initialState;
              } else {
                documentTemplateContextFormValue = documentTemplateDto;
              }
              return documentTemplateContextFormValue;
            },
          );
          setInitialValues(documentTemplateDto);
          setIsLoading(false);
          onDocumentTemplateLoaded(documentTemplateDto);
        },
        () => {},
      );
    } else {
      throw new Error('DocumentTemplate keys were not provided');
    }
  }, [documentTemplateId]);

  const onSubmit = (data: DocumentTemplateDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createDocumentTemplate(data)
        .then(
          (result) => {
            onDocumentTemplateCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateDocumentTemplate(data)
        .then(
          (result) => {
            onDocumentTemplateUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  const onDocumentTemplateTypeChange = (newValueDocumentTemplateType) => {
    setDocumentTemplateContextFormValue((documentTemplateContextFormValue) => {
      if (!documentTemplateContextFormValue) {
        documentTemplateContextFormValue = initialState;
      }
      documentTemplateContextFormValue.documentTemplateType =
        newValueDocumentTemplateType?.value;
      return documentTemplateContextFormValue;
    });
  };
  const onBodyHtmlTemplateChange = (
    newBodyHtmlTemplate: BaseSyntheticEvent,
    context,
  ) => {
    context.setFieldValue('bodyHtmlTemplate', newBodyHtmlTemplate.target.value);
    setBodyHtmlTemplate(newBodyHtmlTemplate.target.value);
  };
  const onBodyTextTemplateChange = (
    newBodyTextTemplate: BaseSyntheticEvent,
    context,
  ) => {
    context.setFieldValue('bodyTextTemplate', newBodyTextTemplate.target.value);
    setBodyTextTemplate(newBodyTextTemplate.target.value);
  };
  const onPrettierIconClick = (context) => {
    const prettiedBodyHtmlTemplate = beautify(context.values.bodyHtmlTemplate, {
      format: 'html',
    });
    context.setFieldValue('bodyHtmlTemplate', prettiedBodyHtmlTemplate);
    setBodyHtmlTemplate(prettiedBodyHtmlTemplate);
  };

  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Tabs>
      <TabList>
        <Tab name="documentTemplate">Document Template</Tab>
      </TabList>
      <DocumentTemplateForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        id={'document-templates-form'}
        validationSchema={documentTemplatesSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h3 className="header-form">Add Document Template</h3>
            ) : (
              <h3 className="header-form">Update Document Template</h3>
            )}
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <DocumentTemplateForm.Name />
                  </div>
                  <div className="col-6">
                    <DocumentTemplateForm.Description />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <DocumentTemplateForm.DocumentTemplateType
                      placeholder={'Document Template Type'}
                      id={'documentTemplateType'}
                      header={'Document Template Type'}
                      name={'documentTemplateType'}
                      disabled={false}
                      required={true}
                      defaultValue={
                        documentTemplateContextFormValue?.documentTemplateType
                          ? {
                              label:
                                DocumentTemplateType[
                                  documentTemplateContextFormValue
                                    ?.documentTemplateType
                                ],
                              value:
                                documentTemplateContextFormValue?.documentTemplateType,
                            }
                          : {
                              label:
                                DocumentTemplateDefaultValues.documentTemplateType,
                              value: getEnumKeyByValue(
                                DocumentTemplateDefaultValues.documentTemplateType,
                                DocumentTemplateType,
                              ),
                            }
                      }
                      onChange={onDocumentTemplateTypeChange}
                      multiple={false}
                      options={getEnumValues(DocumentTemplateType)}
                    />
                  </div>
                  <div className="col-6">
                    <DocumentTemplateForm.FileNameTemplate />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <DocumentTemplateForm.SubjectTemplate />
                  </div>
                </div>
                <DocumentTemplateForm.IsDefault />
                <DocumentTemplateForm.IsInactive />
                <FormContext.Consumer>
                  {(context) => (
                    <>
                      <div className="row">
                        <div className="col-12 mb-3">
                          <DocumentTemplateForm.BodyHtmlTemplate
                            onChange={(value) =>
                              onBodyHtmlTemplateChange(value, context)
                            }
                            defaultValue={bodyHtmlTemplate}
                            required={false}
                            onClick={() => onPrettierIconClick(context)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <DocumentTemplateForm.BodyTextTemplate
                            onChange={(value) =>
                              onBodyTextTemplateChange(value, context)
                            }
                            defaultValue={bodyTextTemplate}
                            required={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </FormContext.Consumer>
                <div className="w-100 d-flex align-items-center justify-content-end">
                  {(userHas(
                    UPDATE_DOCUMENTTEMPLATE_LINK_KEY,
                    documentTemplateContextFormValue?.links,
                  ) ||
                    isCreateMode) && (
                    <Button
                      name="save-documentTemplate"
                      type="submit"
                      color="primary"
                      className="btn-block mr-3"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save Document Template
                    </Button>
                  )}
                  <Button
                    type="button"
                    color="primary"
                    onClick={onCancel}
                    className="btn-light btn-cancel"
                    disabled={isSending}
                  >
                    Close
                  </Button>
                </div>
              </div>
              <div className="col-6">
                <iframe
                  title="HTML Preview"
                  srcDoc={bodyHtmlTemplate}
                  style={{
                    width: '100%',
                    height: '100%',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                  }}
                />
              </div>
            </div>
          </Panel>
        </TabPanel>
      </DocumentTemplateForm>
    </Tabs>
  );
};
