import React, { KeyboardEvent } from 'react';
import {
  Formik,
  Form as HTMLForm,
  FormikProps,
  FormikValues,
  isObject,
  isEmptyArray,
} from 'formik';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import {
  createErrorMessage,
  findInputName,
} from '../../../../utils/helper.utils';
import { clearMessages } from '../../messages.store';

export type FormProps = {
  children: any;
  id?: string;
  ref?: string;
  method?: string;
  initialValues?: any;
  validateOnChange?: boolean;
  innerRef?: any;
  validationSchema?: Yup.ObjectSchema<AnyObject>;
  onSubmit?: (data: any, form: any) => void;
  render?: any;
  className?: string;
  onKeyPress?: (
    event: KeyboardEvent<HTMLFormElement>,
    formikParams: FormikProps<FormikValues>,
  ) => void;
};

export const Form = ({
  children,
  id,
  innerRef,
  method,
  validateOnChange = false,
  initialValues = {},
  onSubmit = () => {},
  render,
  className,
  onKeyPress = (event, formikParams) => {},
  validationSchema,
}: FormProps) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      method={method}
      innerRef={innerRef}
      validateOnChange={validateOnChange}
      validateOnBlur={true}
      validationSchema={validationSchema}
      render={render}
    >
      {(params) => {
        if (params.isSubmitting) {
          const errors = Object.entries(params.errors);
          !isEmptyArray(errors) && clearMessages();
          errors.forEach((error) => {
            if (isObject(error[1])) {
              const subErrors = Object.entries(error[1]);
              subErrors.forEach((subError) => {
                let inputName = '';
                if (error[0] === 'customValues') {
                  inputName = `ADDITIONAL - ${findInputName(
                    id,
                    `${error[0]}.${subError[0]}`,
                  )}`;
                } else {
                  inputName = findInputName(
                    id,
                    `${error[0]}.${subError[0]}`,
                    error[0],
                  );
                }
                createErrorMessage(inputName, subError[1].toString());
              });
            } else {
              const inputName = findInputName(id, `${error[0]}`);
              createErrorMessage(inputName, error[1]);
            }
          });
        }
        return (
          <FormContext.Provider value={params}>
            <HTMLForm
              onKeyPress={(event) => onKeyPress(event, params)}
              className={className}
              id={id}
              noValidate={true}
            >
              {children}
            </HTMLForm>
          </FormContext.Provider>
        );
      }}
    </Formik>
  );
};

export const FormContext = React.createContext<FormikProps<FormikValues> | null>(
  null,
);
