import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import {
  CONTAINER_TYPE_CREATE_PATH,
  CONTAINER_TYPES_LIST_PATH,
} from '../containerTypes.route';
import { ContainerTypeEdit } from '../components/containerType-edit.component';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_CONTAINERTYPE_LINK_KEY } from '../containerTypes.service';
import { userHas } from '../../auth/auth.store';

export const ContainerTypeScreen = () => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, containerTypeCode } = useParams();

  const createPath = generatePath(INTERNAL_PATH + CONTAINER_TYPE_CREATE_PATH, {
    organizationId,
  });

  const onContainerTypeCreated = () => {
    const path = generatePath(INTERNAL_PATH + CONTAINER_TYPES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'containerType-created',
      type: 'success',
      autoHide: true,
      message: 'Container Type successfully created!',
    };
    history.push(path);
    addMessage(message);
  };

  const onContainerTypeUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CONTAINER_TYPES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'containerType-updated',
      type: 'success',
      autoHide: true,
      message: 'Container Type successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const isCreateMode = !containerTypeCode || containerTypeCode === ' ';

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + CONTAINER_TYPES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };

  return (
    <InternalLayout
      title={'Container Types'}
      createPath={userHas(CREATE_CONTAINERTYPE_LINK_KEY) ? createPath : null}
    >
      {isCreateMode ? (
        <Helmet>
          <title>Add New Container Type</title>
        </Helmet>
      ) : (
        <Helmet>
          <title>Update Container Type</title>
        </Helmet>
      )}
      <ContainerTypeEdit
        containerTypeCode={containerTypeCode}
        onContainerTypeUpdated={onContainerTypeUpdated}
        onContainerTypeCreated={onContainerTypeCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
