import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { ChargeStatuses } from '../../../models/data.models';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectSalesTaxPaginate } from '../../salesTaxes/components/salesTax-react-select.component';
import { SelectCurrencyPaginate } from '../../currencies/components/currencies-react-select.component';
import { SelectAccountingItemPaginate } from '../../accountingItems/components/accountingItem-react-select.component';

export const ChargeForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  className,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      className={className}
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

ChargeForm.AccountingItemSelect = ({
  selectedFilter = ` `,
  placeholder = 'Select Accounting Item',
  required = true,
  defaultValue,
  sort = 'description',
  id = 'accountingItemId',
  header = 'Accounting Item',
  onChange = () => {},
  nameId = 'accountingItemName',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectAccountingItemPaginate
      nameId={nameId}
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeAccountingItem={onChange}
      isDisabled={disabled}
    />
  );
};

ChargeForm.Amount = ({
  className,
  style,
  size,
  disabled = true,
  required = true,
  readonly,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      name={'amount'}
      label={'Amount'}
      placeholder={'Amount'}
      required={required}
      type={'input-value'}
      className={className}
      style={style}
      readonly={readonly}
      disabled={disabled}
      size={size}
      valueInput={defaultValue}
    />
  );
};

ChargeForm.ApplyBy = ({
  onChange,
  options,
  name = 'applyBy',
  id = 'applyBy',
  placeholder = 'Select Apply By',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Apply By',
  defaultValue = '',
  isClearable = false,
}: InputPublicProps) => {
  return (
    <Input
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      isClearable={isClearable}
    />
  );
};
ChargeForm.CalculatedOf = ({
  onChange,
  options,
  name = 'calculatedOf',
  id = 'calculatedOf',
  placeholder = '% Of The',
  required = false,
  multiple = false,
  disabled = false,
  header = '% Of The',
  defaultValue = '',
  isClearable = false,
}: InputPublicProps) => {
  return (
    <Input
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      isClearable={isClearable}
    />
  );
};
ChargeForm.ChargeType = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'chargeType'}
      label={'Charge Type'}
      placeholder={'Charge Type'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ChargeForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ChargeForm.FreightServiceClassId = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'freightServiceClassId'}
      label={'Freight Service Class Id'}
      placeholder={'Freight Service Class Id'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ChargeForm.GrossVolume = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'grossVolume'}
      label={'Gross Volume'}
      placeholder={'Gross Volume'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ChargeForm.GrossWeight = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'grossWeight'}
      label={'Gross Weight'}
      placeholder={'Gross Weight'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ChargeForm.IsConsolidated = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isConsolidated'}
      label={'Is Consolidated'}
      placeholder={'Is Consolidated'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};

ChargeForm.PaidAs = ({
  onChange,
  options,
  name = 'paidAs',
  id = 'paidAs',
  placeholder = 'Select Paid As',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Paid As',
  defaultValue = '',
  isClearable = false,
}: InputPublicProps) => {
  return (
    <Input
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      isClearable={isClearable}
    />
  );
};

ChargeForm.ChargeStatus = ({
  className,
  style,
  size,
  disabled,
  name = 'chargeStatus',
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={'Select Charge Status'}
      placeholder={'Select Charge Status'}
      required={true}
      type={'enum-select'}
      enumName={ChargeStatuses}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ChargeForm.Pieces = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'pieces'}
      label={'Pieces'}
      placeholder={'Pieces'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ChargeForm.Price = ({
  defaultValue,
  onChange,
  onBlur,
  maxDecimals,
  parseNumberInput = false,
  required = true,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      type={'input-OnChange-number-with-value'}
      valueInput={defaultValue}
      onChange={onChange}
      name={'price'}
      id={'price'}
      label={'Price'}
      placeholder={'Price'}
      onBlur={onBlur}
      maxDecimals={maxDecimals}
      parseNumberInput={parseNumberInput}
      required={required}
      disabled={disabled}
    />
  );
};
ChargeForm.Apply = ({
  defaultValue,
  onChange,
  onBlur,
  maxDecimals,
  parseNumberInput = false,
  required = true,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      type={'input-OnChange-number-with-value'}
      valueInput={defaultValue}
      onChange={onChange}
      name={'apply'}
      id={'apply'}
      label={'Apply %'}
      placeholder={'Apply'}
      onBlur={onBlur}
      maxDecimals={maxDecimals}
      parseNumberInput={parseNumberInput}
      required={required}
      disabled={disabled}
    />
  );
};
ChargeForm.Quantity = ({
  className,
  style,
  size,
  disabled,
  defaultValue,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'quantity'}
      label={'Quantity'}
      placeholder={'Quantity'}
      required={true}
      type={'input-OnChange-number-with-value'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      valueInput={defaultValue}
      onChange={onChange}
    />
  );
};
ChargeForm.ShowInDocuments = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'showInDocuments'}
      label={'Show In Documents'}
      placeholder={'Show In Documents'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ChargeForm.AllowAutomaticUpdate = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'allowAutomaticUpdate'}
      label={'Allow Automatic Update'}
      placeholder={'Allow Automatic Update'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ChargeForm.Unit = ({
  className,
  style,
  size,
  disabled,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'unit'}
      label={'Unit'}
      placeholder={'Unit'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};

ChargeForm.ApplyToContactSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Apply To Contact',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'applyToContactId',
  header = 'Apply To Contact',
  onChange = () => {},
  nameId = 'applyToContactName',
  typeId = 'applyToContactType',
  contactTypes,
  disabled = false,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      typeId={typeId}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      isDisabled={disabled}
    />
  );
};

ChargeForm.TaxCodeSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Sales Tax',
  required = false,
  defaultValue,
  sort = 'taxCode',
  id = 'salesTaxId',
  header = 'Sales Tax',
  onChange = () => {},
  nameId = 'salesTaxCode',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectSalesTaxPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeSalesTax={onChange}
      isDisabled={disabled}
    />
  );
};

ChargeForm.Expense = ({
  className,
  style,
  size,
  disabled,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'expense'}
      label={'Expense'}
      placeholder={'Expense'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
ChargeForm.Income = ({
  className,
  style,
  size,
  disabled,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'income'}
      label={'Income'}
      placeholder={'Income'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
ChargeForm.Tax = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      name={'tax'}
      label={'Tax'}
      placeholder={'Tax'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};

ChargeForm.AmountDue = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      name={'amountDue'}
      label={'Amount Due'}
      placeholder={'Amount Due'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};
ChargeForm.TotalAmount = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      name={'totalAmount'}
      label={'Total Amount'}
      placeholder={'Total Amount'}
      required={false}
      type={'number'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};

ChargeForm.Profit = ({
  className,
  style,
  size,
  disabled,
  onChange,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      name={'profit'}
      label={'Profit'}
      placeholder={'Profit'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      readonly={readonly}
    />
  );
};

ChargeForm.Note = ({
  className,
  style,
  size,
  disabled,
  name = 'note',
  label = 'Note',
  placeholder = 'Note',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};

ChargeForm.CurrencySelect = ({
  selectedFilter = ``,
  placeholder = 'Select Currency',
  required = true,
  defaultValue,
  sort = 'currencyId',
  id = 'currencyId',
  header = 'Currency',
  onChange = () => {},
  nameId = 'currencyCode',
  isClearable = false,
  disabled,
}: InputPublicProps) => {
  return (
    <SelectCurrencyPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCurrency={onChange}
      isClearable={isClearable}
      isDisabled={disabled}
    />
  );
};
