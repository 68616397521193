import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { PackageTypeForm } from './packageType.form';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  ContainerDescriptionDto,
  ContainerTypeDto,
  PackageCategoryDto,
  PackageTypeDto,
  PortDto,
  StateDto,
} from '../../../models/data.models';
import {
  createPackageType,
  getPackageType,
  updatePackageType,
} from '../packageTypes.store';
import { Panel } from '../../common/components/panel/panel.component';
import { PackageTypeDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_PACKAGETYPE_LINK_KEY } from '../packageTypes.service';

export type PackageTypeEditProps = {
  packageTypeId?: number | null;
  onPackageTypeCreated?: (packageType: PackageTypeDto) => void;
  onPackageTypeUpdated?: (packageType: PackageTypeDto) => void;
  onPackageTypeLoaded?: (packageType: PackageTypeDto) => void;
  onCancel?: () => void;
};

const initialState: PackageTypeDto = {
  packageTypeId: 0,
  air: PackageTypeDefaultValues.air,
  containerDescriptionCode: PackageTypeDefaultValues.containerDescriptionCode,
  containerTypeCode: PackageTypeDefaultValues.containerTypeCode,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  ground: PackageTypeDefaultValues.ground,
  height: PackageTypeDefaultValues.height,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  length: PackageTypeDefaultValues.packageTypeLength,
  maximumWeight: PackageTypeDefaultValues.maximumWeight,
  name: PackageTypeDefaultValues.packageTypeName,
  ocean: PackageTypeDefaultValues.ocean,
  organizationId: null,
  packageCategoryCode: PackageTypeDefaultValues.packageCategoryCode,
  volume: PackageTypeDefaultValues.volume,
  weight: PackageTypeDefaultValues.weight,
  width: PackageTypeDefaultValues.width,
  links: [],
};

const packageTypeSchema = Yup.object().shape({
  name: Yup.string().required("Can't be blank").nullable(true),
  packageCategoryCode: Yup.string().required("Can't be blank").nullable(true),
  length: Yup.number().required("Can't be blank").nullable(true),
  width: Yup.number().required("Can't be blank").nullable(true),
  height: Yup.number().required("Can't be blank").nullable(true),
  volume: Yup.number().required("Can't be blank").nullable(true),
  weight: Yup.number().required("Can't be blank").nullable(true),
  maximumWeight: Yup.number().required("Can't be blank").nullable(true),
});

export const PackageTypeEdit = ({
  packageTypeId,
  onPackageTypeLoaded = () => {},
  onPackageTypeCreated = () => {},
  onPackageTypeUpdated = () => {},
  onCancel = () => {},
}: PackageTypeEditProps) => {
  const isCreateMode = !packageTypeId || packageTypeId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [packageType, setPackageType] = useState<PackageTypeDto | null>(null);
  const [initialValues, setInitialValues] = useState<PackageTypeDto | null>(
    initialState,
  );
  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (packageTypeId) {
      getPackageType({ packageTypeId }).then(
        (packageTypeDto: PackageTypeDto) => {
          setPackageType(packageTypeDto);
          setInitialValues(packageTypeDto);
          setIsLoading(false);
          onPackageTypeLoaded(packageTypeDto);
        },
        () => {},
      );
    } else {
      throw new Error('PackageType keys were not provided');
    }
  }, [packageTypeId]);

  const onSubmit = (data: PackageTypeDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createPackageType(data)
        .then(
          (result) => {
            onPackageTypeCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updatePackageType(data)
        .then(
          (result) => {
            onPackageTypeUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <div className={'order-edit-form small-form'}>
      <PackageTypeForm
        id={'packageType-form'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={ref}
        validationSchema={packageTypeSchema}
      >
        <Tabs forceRenderTabPanel={true}>
          <TabList>
            <div>
              <Tab>Description</Tab>
              <Tab>Dimensions</Tab>
              <Tab>Express Link</Tab>
            </div>
          </TabList>
          <div>
            <TabPanel>
              <Panel className="m-3">
                <PackageTypeForm.Name />
                <PackageTypeForm.ContainerTypeCodeSelect
                  id={'containerTypeCode'}
                  header={'Container Type'}
                  required={false}
                  defaultValue={
                    packageType &&
                    packageType.containerTypeCode &&
                    packageType.containerTypeDescription
                      ? {
                          containerTypeCode: packageType.containerTypeCode,
                          description: packageType.containerTypeDescription,
                        }
                      : ''
                  }
                  onChange={(containerType?: ContainerTypeDto) => {
                    setPackageType((oldPackageType) => {
                      if (!oldPackageType) {
                        oldPackageType = initialState;
                      }
                      oldPackageType.containerTypeCode =
                        containerType?.containerTypeCode;
                      oldPackageType.containerTypeDescription =
                        containerType?.description;
                      return { ...oldPackageType };
                    });
                  }}
                  nameId={'containerTypeDescription'}
                />
                <PackageTypeForm.ContainerDescriptionCodeSelect
                  id={'containerDescriptionCode'}
                  header={'Container Description'}
                  required={false}
                  defaultValue={
                    packageType &&
                    packageType.containerDescriptionCode &&
                    packageType.containerDescriptionCodeDescription
                      ? {
                          containerDescriptionCode:
                            packageType.containerDescriptionCode,
                          description:
                            packageType.containerDescriptionCodeDescription,
                        }
                      : ''
                  }
                  onChange={(
                    containerDescription?: ContainerDescriptionDto,
                  ) => {
                    setPackageType((oldPackageType) => {
                      if (!oldPackageType) {
                        oldPackageType = initialState;
                      }
                      oldPackageType.containerDescriptionCode =
                        containerDescription?.containerDescriptionCode;
                      oldPackageType.containerDescriptionCodeDescription =
                        containerDescription?.description;
                      return { ...oldPackageType };
                    });
                  }}
                  nameId={'containerDescriptionCodeDescription'}
                />
                <PackageTypeForm.PackageCategoryCode
                  id={'packageCategoryCode'}
                  header={'Package Category'}
                  required={true}
                  defaultValue={
                    packageType &&
                    packageType.packageCategoryCode &&
                    packageType.packageCategoryName
                      ? {
                          packageCategoryCode: packageType.packageCategoryCode,
                          name: packageType.packageCategoryName,
                        }
                      : ''
                  }
                  onChange={(containerType?: PackageCategoryDto) => {
                    setPackageType((oldPackageType) => {
                      if (!oldPackageType) {
                        oldPackageType = initialState;
                      }
                      oldPackageType.packageCategoryCode =
                        containerType?.packageCategoryCode;
                      oldPackageType.packageCategoryName = containerType?.name;
                      return { ...oldPackageType };
                    });
                  }}
                  nameId={'packageCategoryName'}
                />
                <div className="mt-3">
                  <div className="mb-2 text-dark">Method</div>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-around">
                      <PackageTypeForm.Air />
                      <PackageTypeForm.Ocean />
                      <PackageTypeForm.Ground />
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-end">
                  {(userHas(UPDATE_PACKAGETYPE_LINK_KEY, packageType?.links) ||
                    isCreateMode) && (
                    <div className="col-6 pl-0">
                      <Button
                        form={'packageType-form'}
                        type="submit"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Package Type
                      </Button>
                    </div>
                  )}
                  <div className="col-6 pr-0">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="btn-block btn-light btn-cancel"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Panel>
            </TabPanel>
            <TabPanel>
              <Panel className="m-3">
                <PackageTypeForm.Length />
                <PackageTypeForm.Width />
                <PackageTypeForm.Height />
                <PackageTypeForm.Volume />
                <PackageTypeForm.Weight />
                <PackageTypeForm.MaximumWeight />
                <div className="w-100 d-flex align-items-center justify-content-end">
                  {(userHas(UPDATE_PACKAGETYPE_LINK_KEY, packageType?.links) ||
                    isCreateMode) && (
                    <div className="col-6 pl-0">
                      <Button
                        form={'packageType-form'}
                        type="submit"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Package Type
                      </Button>
                    </div>
                  )}
                  <div className="col-6 pr-0">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="btn-block btn-light btn-cancel"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Panel>
            </TabPanel>

            <TabPanel>
              <Panel className="m-3">smth</Panel>
            </TabPanel>
          </div>
        </Tabs>
      </PackageTypeForm>
    </div>
  );
};
