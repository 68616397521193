import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { GenerateInvoiceForm } from './generateInvoice.form';
import {
  AccountingAccountDto,
  DivisionDto,
  GenerateInvoiceCommandValues,
  VisibleTransactions,
} from '../../../models/data.models';
import { Panel } from '../../common/components/panel/panel.component';
import { GenerateInvoiceFormDto } from '../../../models/custom.models';
import { FormContext } from '../../common/components/form/form.component';
import { generateInvoice } from '../invoiceManager.store';
import * as Yup from 'yup';
import { authStore } from '../../auth/auth.store';
import { validatePositiveNumberInput } from '../../../utils/helper.utils';

export type ReceiveCarrierPayProps = {
  orderId: number;
  customerId: number;
  transactionNumber: string;
  onInvoiceGenerated?: () => void;
  onCancel?: () => void;
};

const generateInvoiceSchema = Yup.object().shape({
  transactionNumber: Yup.string()
    .required("Can't be blank")
    .transform((value) => (value === null ? '' : value))
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === '' ||
        value === undefined
      );
    })
    .test('length', 'Max length is 20', (value) => {
      return value === undefined || value.length <= 20;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  invoiceDate: Yup.date().required("Can't be blank").nullable(true),
  accountId: Yup.string().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

export const GenerateInvoice = ({
  orderId,
  customerId,
  onInvoiceGenerated = () => {},
  transactionNumber,
  onCancel = () => {},
}: ReceiveCarrierPayProps) => {
  const { user: currentUser } = authStore.getState();

  const isCreateMode = true;

  const [isLoading, setIsLoading] = useState(true);
  const [accountingAccount, setAccountingAccount] = useState(null);

  const [
    initialValues,
    setInitialValues,
  ] = useState<GenerateInvoiceFormDto | null>({
    transactionNumber,
    invoiceDate: new Date(),
    accountId: null,
    accountName: null,
    accountType: null,
    divisionId: null,
    divisionName: null,
  });
  const [
    invoiceContextFormValue,
    setInvoiceContextFormValue,
  ] = useState<GenerateInvoiceFormDto | null>(initialValues);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    }
  }, []);

  const onSubmit = (data: GenerateInvoiceFormDto) => {
    const generateInvoiceCommandValues: GenerateInvoiceCommandValues = {
      ...data,
      orderId,
      customerId,
    };
    return generateInvoice(generateInvoiceCommandValues).then(
      () => {
        onInvoiceGenerated();
      },
      () => {},
    );
  };

  const onReceivedDateChange = (date: Date) => {
    invoiceContextFormValue.invoiceDate = date;
    setInvoiceContextFormValue({ ...invoiceContextFormValue });
  };

  const onAccountChange = (account: AccountingAccountDto) => {
    invoiceContextFormValue.accountId = account?.accountId;
    invoiceContextFormValue.accountName = account?.accountName;
    invoiceContextFormValue.accountType = account?.accountType;
    setInvoiceContextFormValue({ ...invoiceContextFormValue });
  };

  const onDivisionChange = (division: DivisionDto) => {
    invoiceContextFormValue.divisionId = division?.divisionId;
    invoiceContextFormValue.divisionName = division?.divisionName;
    setInvoiceContextFormValue({ ...invoiceContextFormValue });
  };

  return (
    <Panel className="m-3">
      <div className="row">
        <div className="col-12">
          <GenerateInvoiceForm
            id={'generateInvoiceForm'}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={generateInvoiceSchema}
          >
            <FormContext.Consumer>
              {(context) => (
                <>
                  <GenerateInvoiceForm.TransactionNumber
                    onKeyDown={validatePositiveNumberInput}
                  />
                  <GenerateInvoiceForm.InvoiceDate
                    defaultValue={invoiceContextFormValue?.invoiceDate}
                    onChange={onReceivedDateChange}
                  />
                  <GenerateInvoiceForm.AccountingAccountSelect
                    id="accountId"
                    nameId="accountName"
                    header="Account"
                    required={true}
                    listHeader={'List Account'}
                    placeholder={'Select Account'}
                    defaultValue={
                      invoiceContextFormValue &&
                      invoiceContextFormValue.accountId &&
                      invoiceContextFormValue.accountName &&
                      invoiceContextFormValue.accountType
                        ? {
                            accountId: invoiceContextFormValue?.accountId,
                            accountName: invoiceContextFormValue?.accountName,
                            accountType: invoiceContextFormValue?.accountType,
                          }
                        : ''
                    }
                    onChange={onAccountChange}
                  />
                  <GenerateInvoiceForm.DivisionId
                    id={'divisionId'}
                    header={'Division'}
                    required={true}
                    onChange={onDivisionChange}
                    nameId={'divisionName'}
                    defaultValue={
                      invoiceContextFormValue &&
                      invoiceContextFormValue.divisionId &&
                      invoiceContextFormValue.divisionName
                        ? {
                            divisionId: invoiceContextFormValue?.divisionId,
                            divisionName: invoiceContextFormValue?.divisionName,
                          }
                        : ''
                    }
                    defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                    disabled={
                      VisibleTransactions[currentUser?.visibleTransactions] !==
                      VisibleTransactions.AllTransactions
                    }
                  />
                  <div className="row">
                    <div className="col-4 offset-4">
                      <Button
                        form={'generateInvoiceForm'}
                        type="submit"
                        color="primary"
                        className="btn-block"
                      >
                        Save
                      </Button>
                    </div>
                    <div className="ml-auto col-4">
                      <Button
                        type="button"
                        color="primary"
                        className="btn-block"
                        onClick={onCancel}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </FormContext.Consumer>
          </GenerateInvoiceForm>
        </div>
      </div>
    </Panel>
  );
};
