import React, { useEffect, useState } from 'react';
import { CarrierEquipmentDto, Column } from '../../../models/data.models';
import { BsFilter } from 'react-icons/bs';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../utils/query.utils';
import { Filters } from '../../common/components/filters/filters.component';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { Pagination } from '../../common/components/pagination/pagination.component';
import {
  equipmentTypeFilterFields,
  getEquipmentTypes,
} from '../../equipmentTypes/equipmentType.store';
import { showDialog } from '../../common/dialog.store';
import { Input } from '../../common/components/input/input.component';
import { useFormikContext } from 'formik';
import { EquipmentTypeDialog } from '../../equipmentTypes/components/equipmentType.dialog';

export type CarrierEquipmentsListProps = {
  onChange: (carrierEquipments: CarrierEquipmentDto[]) => void;
  carrierEquipments: CarrierEquipmentDto[];
  setCarrierEquipments: React.Dispatch<
    React.SetStateAction<CarrierEquipmentDto[]>
  >;
};

export const CarrierEquipmentsListComponent = ({
  onChange = (data) => {},
  carrierEquipments = [],
  setCarrierEquipments = (data) => {},
}: CarrierEquipmentsListProps) => {
  const rowKeys = ['equipmentTypeId'];
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);

  const columns: Column[] = [
    { name: 'name', visible: true, title: 'Equipment Type' },
  ];

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [sort, setSort] = useState('');
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');
  const context = useFormikContext();

  const onPageChanged = (page: number) => {
    setOffset(page * limit);
  };

  const onFilter = (query: string) => {
    setFilter(query);
  };

  const onSort = (field: string) => {
    setSort(field);
  };

  const onSearch = (query: string) => {
    setSearch(query);
  };

  useEffect(() => {
    getEquipmentTypesData();
  }, [offset, limit, sort, filter, search]);

  const getEquipmentTypesData = () => {
    getEquipmentTypes({
      offset,
      limit,
      sort,
      filter,
      search,
    }).then((equipmentTypesData) => {
      setData(equipmentTypesData.items);
      setTotal(equipmentTypesData.totalCount);
    });
  };

  const colByName: { [key: string]: any } = {};

  const updateSort = (colName: string) => {
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };

  const updateFilter = (filters: any) => {
    return onFilter(getSearchQuery(filters));
  };
  const getFilteredRowsFilter = (): Column[] => {
    return columns.filter((item) => {
      return equipmentTypeFilterFields?.includes(item.name);
    });
  };
  const updateSearch = (event) => {
    return onSearch(event.target.value);
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  const onEquipmentTypeSelect = (equipmentType, equipmentTypeId) => {
    showDialog({
      dialog: EquipmentTypeDialog,
      props: {
        equipmentTypeId: equipmentType.equipmentTypeId,
        title: 'Update Equipment Type',
      },
    }).then((equipmentType) => {
      if (equipmentType !== null) {
        getEquipmentTypesData();
      }
    });
  };

  const onSelectionChanged = (equipmentTypeId, value) => {
    if (value) {
      setCarrierEquipments((carrierEquipments) => {
        const newCarrierEquipment: CarrierEquipmentDto = {
          equipmentTypeId,
          quantity: 0,
        };
        onChange(carrierEquipments);
        context.setFieldValue('carrierEquipments', carrierEquipments);
        return [...carrierEquipments, newCarrierEquipment];
      });
    } else {
      setCarrierEquipments((carrierEquipments) => {
        carrierEquipments = carrierEquipments.filter(
          (item) => item.equipmentTypeId !== equipmentTypeId,
        );
        onChange(carrierEquipments);
        context.setFieldValue('carrierEquipments', carrierEquipments);
        return [...carrierEquipments];
      });
    }
  };

  const onQuantityChanged = (equipmentTypeId, value) => {
    setCarrierEquipments((carrierEquipments) => {
      const carrierEquipment = carrierEquipments.find(
        (item) => item.equipmentTypeId === equipmentTypeId,
      );
      carrierEquipment.quantity = value;
      onChange(carrierEquipments);
      context.setFieldValue('carrierEquipments', carrierEquipments);
      return [...carrierEquipments];
    });
  };

  return (
    <div className={`grid`}>
      <div style={{ fontWeight: 700, fontStyle: '14px' }}>
        Carrier Equipments
      </div>
      <div className={'grid-toolbar d-flex my-2'}>
        {onSearch ? (
          <input
            type="search"
            className={'form-control my-2'}
            placeholder="Search"
            onChange={updateSearch}
            value={search}
          />
        ) : null}
        {onFilter && columns.some((column) => column.showFilter) && (
          <div className={'dropdown dropdown-columns my-3 px-3 w-100 d-flex'}>
            <span
              className="dropdown-toggle ml-auto"
              id="dropdownFilterButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <BsFilter />
              &nbsp;
              <small className="align-middle pl-1 text-uppercase">
                Filters
              </small>
              &nbsp;
              <small className="align-middle text-primary">
                {getReadableQueryString(filter, getFilteredRowsFilter())}
              </small>
            </span>

            <div
              className="dropdown-menu px-5"
              aria-labelledby="dropdownFilterButton"
            >
              <div>
                <Filters
                  query={filter}
                  columns={getFilteredRowsFilter()}
                  onFilter={updateFilter}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="bg-white"
        style={{ borderRadius: '0.3125rem', border: '1px solid #E8ECEF' }}
      >
        <table className="table">
          <thead>
            <tr>
              <th>
                <a className="link">Yes</a>
              </th>
              <th>
                <a className="link">Quantity</a>
              </th>
              {columns
                .filter((col) => {
                  if (col.visible) {
                    return true;
                  }
                  return col.visible && rowKeys?.includes(col.name);
                })
                .map((col) => {
                  return (
                    <th scope="col" key={col.name}>
                      <a className="link" onClick={() => updateSort(col.name)}>
                        {col.title}
                        {col.name === sort ? <FaSortAlphaDown /> : null}
                        {'-' + col.name === sort ? <FaSortAlphaUp /> : null}
                      </a>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => {
              return (
                <tr key={rowKeys?.map((x) => row[x]).join('_')}>
                  <td>
                    <label
                      key={row.equipmentTypeId}
                      className="pointer d-flex align-items-center"
                    >
                      <input
                        className={'pointer'}
                        type={'checkbox'}
                        checked={carrierEquipments.some(
                          (item) =>
                            item.equipmentTypeId === row.equipmentTypeId,
                        )}
                        onChange={(value) =>
                          onSelectionChanged(
                            row.equipmentTypeId,
                            value.target.checked,
                          )
                        }
                      />
                    </label>
                  </td>
                  <td className={'p-0'}>
                    <label
                      key={row.equipmentTypeId}
                      className="pointer align-items-center d-flex m-0"
                    >
                      <Input
                        className={'my-auto'}
                        required={true}
                        type={'input-OnChange-number-with-value'}
                        name={`equipmentType-${row.equipmentTypeId}-quantity`}
                        valueInput={
                          carrierEquipments.find(
                            (item) =>
                              item.equipmentTypeId == row.equipmentTypeId,
                          )?.quantity
                        }
                        onChange={(value) => {
                          onQuantityChanged(row.equipmentTypeId, value);
                        }}
                        disabled={
                          !carrierEquipments.some(
                            (item) =>
                              item.equipmentTypeId === row.equipmentTypeId,
                          )
                        }
                      />
                    </label>
                  </td>
                  {Object.values(columns)
                    .filter((item) => {
                      if (item.visible) {
                        return true;
                      }
                      return item.visible && rowKeys?.includes(item.name);
                    })
                    .map((item, index) => {
                      return (
                        <td
                          key={`${rowKeys?.map((x) => row[x]).join('_')}_${
                            item.name
                          }`}
                          onClick={() => {
                            onEquipmentTypeSelect(
                              row,
                              rowKeys?.reduce((keyObj, field) => {
                                return row[field];
                              }),
                            );
                          }}
                          className={'cursor-pointer'}
                        >
                          {typeof row[item.name] === 'boolean' ? (
                            row[item.name] ? (
                              <>&#x2713;</>
                            ) : (
                              <></>
                            )
                          ) : (
                            <>{row[item.name]}</>
                          )}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {data.length == 0 ? (
        <p className="text-center mt-4">No Invoices Found</p>
      ) : (
        <></>
      )}
      {total > limit ? (
        <div className="mt-3 d-flex justify-content-center">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
          />
        </div>
      ) : null}
    </div>
  );
};
