import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import {
  EVENTDEFINITIONS_CREATE_PATH,
  EVENTDEFINITIONS_LIST_PATH,
} from '../eventDefinitions.route';
import { EventDefinitionEdit } from '../components/eventDefinition-edit.component';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import { CREATE_EVENTDEFINITION_LINK_KEY } from '../eventDefinitions.service';
import { userHas } from '../../auth/auth.store';

export type EventDefinitionScreenProps = { pageTitle: string };

export const EventDefinitionScreen = ({
  pageTitle,
}: EventDefinitionScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, eventDefinitionId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + EVENTDEFINITIONS_CREATE_PATH, {
      organizationId,
    });
  }
  const onEventDefinitionCreated = () => {
    const path = generatePath(INTERNAL_PATH + EVENTDEFINITIONS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'eventDefinition-created',
      type: 'success',
      autoHide: true,
      message: 'Event Definition successfully created!',
    };
    history.push(path);
    addMessage(message);
  };
  const onEventDefinitionUpdated = () => {
    const path = generatePath(INTERNAL_PATH + EVENTDEFINITIONS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'eventDefinition-updated',
      type: 'success',
      autoHide: true,
      message: 'Event Definition successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + EVENTDEFINITIONS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };
  return (
    <InternalLayout
      title={'Event Definitions'}
      createPath={
        userHas(CREATE_EVENTDEFINITION_LINK_KEY) ? createPath() : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <EventDefinitionEdit
        eventDefinitionId={eventDefinitionId}
        onEventDefinitionUpdated={onEventDefinitionUpdated}
        onEventDefinitionCreated={onEventDefinitionCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
