import React from 'react';
import { ContactType } from '../../../models/data.models';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectEventDefinitionPaginate } from '../../eventDefinitions/components/eventDefinitions-react-select.component';

export const OrderTrackingForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      innerRef={innerRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

OrderTrackingForm.CarrierContactSelect = ({
  placeholder = 'Select Carrier',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'carrierContactId',
  nameId = 'carrierContactName',
  header = 'Carrier',
  onChange = () => {},
  readonly,
  contactTypes = [ContactType.Carrier],
  selectedFilter = `contactType: ${ContactType.Carrier}`,
  disabled,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      isDisabled={disabled}
    />
  );
};

OrderTrackingForm.DriverContactSelect = ({
  placeholder = 'Select Driver',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'driverContactId',
  nameId = 'driverContactName',
  header = 'Driver',
  onChange = () => {},
  readonly,
  contactTypes = [ContactType.Driver],
  selectedFilter = `contactType: ${ContactType.Driver}`,
  disabled,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      isDisabled={disabled}
    />
  );
};

OrderTrackingForm.Phone = ({
  className,
  style,
  size,
  required = false,
  defaultValue,
  onChange,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'phoneNumber'}
      label={'Phone'}
      placeholder={'Phone'}
      required={required}
      type={'filterText'}
      className={className}
      style={style}
      size={size}
      valueInput={defaultValue}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

OrderTrackingForm.Email = ({
  className,
  style,
  size,
  required = false,
  defaultValue,
  onChange,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'emailAddress'}
      label={'Email'}
      placeholder={'Email'}
      required={required}
      type={'filterText'}
      className={className}
      style={style}
      size={size}
      valueInput={defaultValue}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

OrderTrackingForm.EventType = ({
  selectedFilter = ``,
  placeholder = 'Select Event Type',
  required = true,
  defaultValue = '',
  sort = 'eventDefinitionId',
  id = 'eventType',
  header = 'Event Type',
  onChange = () => {},
  nameId = 'eventType',
  disabled = false,
  organizationId,
}: InputPublicProps) => {
  return (
    <SelectEventDefinitionPaginate
      nameId={nameId}
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeEventDefinition={onChange}
      isDisabled={disabled}
      organizationId={organizationId}
      isAnonymous={true}
      customLabelStyle={{
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#2F2C2C',
        marginBottom: '2px',
      }}
      customInputStyle={{
        singleValue: (provided, state) => {
          return {
            ...provided,
            fontWeight: 400,
            fontSize: '16px',
          };
        },
        option: (provided, state) => {
          return {
            ...provided,
            fontWeight: '400 !important',
            fontSize: '16px !important',
          };
        },
        placeholder: (provided, state) => {
          return {
            ...provided,
            fontWeight: 400,
            fontSize: '16px',
          };
        },
      }}
    />
  );
};

OrderTrackingForm.Location = ({
  className,
  style,
  onLocate,
  defaultValue,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'locationName'}
      label={'Location'}
      placeholder={'Select Your Location'}
      required={false}
      type={'geo'}
      className={className}
      style={style}
      onLocate={onLocate}
      valueInput={defaultValue}
      onChange={onChange}
      customLabelStyle={{
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#2F2C2C',
        marginBottom: '2px',
      }}
      customInputStyle={{
        fontWeight: 400,
        fontSize: '16px',
        paddingRight: '38px',
      }}
    />
  );
};

OrderTrackingForm.Details = ({
  className,
  style,
  size,
  disabled,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={'details'}
      label={'Details (optional)'}
      placeholder={'Details'}
      required={false}
      type={'textarea'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onChange={onChange}
      customLabelStyle={{
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#2F2C2C',
        marginBottom: '2px',
      }}
      customInputStyle={{
        fontWeight: 400,
        fontSize: '16px',
      }}
    />
  );
};
