import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { VendorScreen } from './screens/vendor.screen';
import { VendorListScreen } from './screens/vendor-list.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_VENDORS } from '../contacts/contact.route';

export const VENDORS_LIST_PATH = '/vendors';
export const VENDORS_EDIT_PATH = '/vendors/:contactId';
export const VENDORS_CREATE_PATH = '/vendors/0';
export const VENDORS_PATH = '/org/:organizationId/vendors';

export const VendorRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${VENDORS_LIST_PATH}`}>
          <VendorListScreen pageTitle="Vendors" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${VENDORS_CREATE_PATH}`}>
          <VendorScreen pageTitle="Add New Vendor" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${VENDORS_EDIT_PATH}`}>
          <VendorScreen pageTitle="Update Vendor" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${CONTACTS_TAB_EDIT_PATH_FOR_VENDORS}`}
        >
          <ContactScreen pageTitle="Contacts" isTab={true} />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
