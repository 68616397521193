import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useStore } from 'effector-react';
import { authStore } from '../auth/auth.store';
import { organizationsStore } from '../organization/organization.store';
import { LOGIN_PATH } from '../auth/auth.router';
import { OUTER_ORGANIZATION_CREATE_PATH } from '../organization/organization.route';
import { NOT_FOUND_PATH } from '../notFound/not-found.route';

export type RootRouteProps = {
  exact?: boolean;
  redirectUrl?: string;
  path: string;
};

export const RootRoute = ({ redirectUrl, ...rest }: RootRouteProps) => {
  const { user } = useStore(authStore);
  let { organizations, currentOrganization } = useStore(organizationsStore);
  currentOrganization = currentOrganization || organizations[0];

  const history = useHistory();
  const isWrongTrackingReferal = !new RegExp(
    /(^.*\/org{1}\/\d+\/tracking{1}\?orderSelector{1}\=.+$)|(^\/{1}$)/i,
  ).test(`${history.location.pathname}${history.location.search}`);

  return (
    <Route {...rest}>
      <Helmet>
        <title>Seahorse Express</title>
      </Helmet>
      {isWrongTrackingReferal ? (
        <Redirect to={NOT_FOUND_PATH} />
      ) : user ? (
        organizations.length ? (
          <Redirect to={`/org/${currentOrganization.organizationId}`} />
        ) : (
          <Redirect to={OUTER_ORGANIZATION_CREATE_PATH} />
        )
      ) : (
        <Redirect to={LOGIN_PATH} />
      )}
    </Route>
  );
};
