import { FormContext } from '../../common/components/form/form.component';
import React, { useEffect, useState } from 'react';
import { SendEmailForm } from './sendEmail.form';
import { SendEmailFormDto } from '../../../models/custom.models';
import { Button } from '../../common/components/button/button.component';
import {
  DocumentTemplateDto,
  DocumentTemplateType,
} from '../../../models/data.models';
import { getContacts } from '../../contacts/contacts.store';
import { getEmail, sendEmail } from '../sendEmail.store';
import { getDocumentTemplates } from '../../documentTemplates/documentTemplates.store';
import { authStore } from '../../auth/auth.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { isEmptyArray, isObject } from 'formik';

export type SendEmailProps = {
  className?: string;
  filter?: string;
  selectedRecipientId?: string | number;
  recipientIds?: number[];
  documentTypes?: DocumentTemplateType[];
  metadata?: object;
  emailType: DocumentTemplateType;
  onEmailSent?: (result) => void;
  onEmailTemplateLoaded?: (data) => void;
  onCancel?: () => void;
};

const emailSchema = Yup.object().shape({
  recipients: Yup.string()
    .transform((value) =>
      isEmptyArray(value) ? '' : isObject(value) ? value[0] : value,
    )
    .required("Can't be blank")
    .nullable(true),
  subject: Yup.string().required("Can't be blank").nullable(true),
  message: Yup.string().required("Can't be blank").nullable(true),
});

export const SendEmailComponent = ({
  className = '',
  filter = '',
  selectedRecipientId,
  recipientIds = [],
  documentTypes = [],
  metadata = {},
  emailType,
  onEmailSent = (result) => {},
  onEmailTemplateLoaded = (data) => {},
  onCancel = () => {},
}: SendEmailProps) => {
  const [sendEmailForm, setSendEmailForm] = useState<SendEmailFormDto>({
    recipients: [],
    sendMeACopy: true,
    subject: '',
    message: '',
    documentTemplates: [],
  });

  const [isSending, setIsSending] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [recipientsFilter, setRecipientsFilter] = useState('');
  const [emailTemplateLoading, setEmailTemplateLoading] = useState(true);
  const [recipientsLoading, setRecipientsLoading] = useState(true);
  const [selectedTemplates, setSelectedTemplates] = useState<
    DocumentTemplateDto[]
  >(null);
  const [initialValues, setInitialValues] = useState<SendEmailFormDto>({
    recipients: [],
    sendMeACopy: true,
    subject: '',
    message: '',
    documentTemplates: [],
  });

  useEffect(() => {
    const contactsFilter = recipientIds
      .map((id) => `contactId: ${id}`)
      .join(' OR ');
    setRecipientsFilter(contactsFilter);
    getContacts({ filter: contactsFilter }).then(
      (contacts) => {
        setRecipients(
          contacts.items
            .filter(
              (contact) =>
                contact.emailAddress &&
                contact.contactId == selectedRecipientId,
            )
            .map((contact) => {
              return {
                name: contact.name,
                emailAddress: contact.emailAddress,
              };
            }),
        );
        setInitialValues((oldValues) => {
          oldValues.recipients = contacts.items
            .filter((contact) => contact.emailAddress)
            .map((contact) => contact.emailAddress);
          return { ...oldValues };
        });
        setRecipientsLoading(false);
      },
      () => {},
    );
    const documentTemplatesFilter = `isDefault: true AND isInActive: false AND (${documentTypes
      .map((documentType) => `documentTemplateType: ${documentType}`)
      .join(' OR ')})`;

    getDocumentTemplates({ filter: documentTemplatesFilter }).then(
      (documentTemplates) => {
        const templates = documentTemplates.items.reduce((prev, cur) => {
          const existing = prev.some(
            (template) =>
              template.documentTemplateType === cur.documentTemplateType,
          );
          if (!existing) prev.push(cur);
          return prev;
        }, []);
        setSelectedTemplates([...templates]);
        setInitialValues((oldValues) => {
          oldValues.documentTemplates = [...templates];
          return { ...oldValues };
        });
      },
      () => {},
    );
    getEmail({
      documentTemplateType: emailType,
      metadata,
    }).then(
      (result) => {
        onEmailTemplateLoaded(result);
        setSendEmailForm((oldSendEmailForm) => {
          oldSendEmailForm.subject = result.subject;
          oldSendEmailForm.message = result.emailBody;
          return { ...oldSendEmailForm };
        });
        setInitialValues((oldValues) => {
          oldValues.subject = result.subject;
          oldValues.message = result.emailBody;
          return { ...oldValues };
        });
        setEmailTemplateLoading(false);
      },
      () => {},
    );
  }, []);

  const onSubmit = async (data: SendEmailFormDto) => {
    const recipientsEmails = recipients.map((recipient) => {
      return recipient.emailAddress;
    });
    if (data.sendMeACopy) {
      recipientsEmails.push(authStore.getState().user.email);
    }
    setIsSending(true);
    sendEmail({
      emailBody: data.message,
      metadata: metadata,
      subject: data.subject,
      recipients: recipientsEmails,
      documentTemplateIds: data.documentTemplates.map((documentTemplate) => {
        return documentTemplate.documentTemplateId;
      }),
    })
      .then(
        (result) => {
          onEmailSent(result);
          addMessage({
            id: v4(),
            message: 'Your message has been sent',
            type: 'success',
          });
        },
        () => {},
      )
      .catch(() =>
        addMessage({
          id: v4(),
          message: 'Something went wrong with your message',
          type: 'warning',
        }),
      )
      .finally(() => setIsSending(false));
  };

  if (emailTemplateLoading || recipientsLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <SendEmailForm
      id={'SendEmailForm'}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={emailSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <>
            <div className={'mb-3'}>
              <SendEmailForm.Recipients
                selectedFilter={recipientsFilter}
                defaultValue={recipients}
                onChange={(value) => {
                  setRecipients(() =>
                    value.map((recipient) => {
                      return {
                        name: recipient.name,
                        emailAddress: recipient.emailAddress,
                      };
                    }),
                  );
                  context.setFieldValue(
                    'recipients',
                    value.map(
                      (recipient) =>
                        `${recipient.name} <${recipient.emailAddress}>`,
                    ),
                  );
                }}
                getOptionLabel={(option) =>
                  `${option.name} <${option.emailAddress}>`
                }
                getOptionValue={(option) => option.emailAddress}
              />
              <SendEmailForm.SendMeACopy />
              <SendEmailForm.Subject disabled={emailTemplateLoading} />
              <SendEmailForm.Message
                disabled={emailTemplateLoading}
                defaultValue={sendEmailForm.message}
                required={true}
              />
              <SendEmailForm.Documents
                selectedFilter={`isInActive: false AND documentTemplateType: ${documentTypes[0]}`}
                defaultValue={selectedTemplates}
                onChange={(value) => {
                  setSelectedTemplates([...value]);
                  context.setFieldValue(
                    'documentTemplates',
                    value.map((documentTemplate) => documentTemplate),
                  );
                }}
                getOptionValue={(option) => option.documentTemplateId}
                getOptionLabel={(option) => option.name}
              />
            </div>
            <div className="row">
              <div className="col-6">
                <Button
                  type="submit"
                  form={'SendEmailForm'}
                  color="primary"
                  className="btn-block ml-auto"
                  disabled={isSending}
                  isSending={isSending}
                >
                  Send Email
                </Button>
              </div>
              <div className="col-6">
                <Button
                  onClick={onCancel}
                  type="button"
                  color="secondary"
                  className="btn-block ml-auto"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </FormContext.Consumer>
    </SendEmailForm>
  );
};
