import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  ContactAddressDto,
  ContactAddressDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  contactAddressStore,
  getContactAddresses,
  deleteContactAddress,
  updateContactAddressesColumns,
} from '../contactAddresses.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { GetContactAddressParams } from '../contactAddresses.service';
import { ListParams } from '../../common/models/ListParams';

export type ContactAddressesListProps = {
  goToDetails?: (contactAddressParams: GetContactAddressParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSelect?: (
    contactAddress: ContactAddressDto,
    contactAddressId: string,
  ) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  isDropDownList?: boolean;
};

export const ContactAddressesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  filter = ' ',
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onFilter = () => {},
  onSelect,
  showAllStore = true,
  rowKeys = ['contactAddressId'],
  isDropDownList = false,
}: ContactAddressesListProps) => {
  const [
    contactAddresses,
    setContactAddresses,
  ] = useState<ContactAddressDtoPagedResult | null>(null);

  useEffect(() => {
    GetContactAddresses({ offset, limit, sort, filter });
  }, [offset, limit, sort, filter]);
  function GetContactAddresses(params: ListParams = {}) {
    getContactAddresses(params).then(
      (contactAddressesData) => {
        setContactAddresses(contactAddressesData);
      },
      () => {},
    );
  }
  const { contactAddressColumns: columns } = useStore(contactAddressStore);

  const onDeleteContactAddress = (contactAddress: ContactAddressDto) => {
    showDialog(
      {dialog: Confirm, props: {
        title: `Delete ${contactAddress.contactAddressId} Contact Address`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      }},
    ).then(
      (result) => {
        if (result) {
          deleteContactAddress(contactAddress).then(
            () => {
              onDelete();
              GetContactAddresses({ offset, limit, sort, filter });
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditContactAddress = (contactAddress: ContactAddressDto) => {
    goToDetails({
      contactAddressId: contactAddress.contactAddressId,
    });
  };

  const onCreateNewContactAddress = () => {
    goToDetails({ contactAddressId: 0 });
  };

  if (
    contactAddresses &&
    contactAddresses.items &&
    contactAddresses.items.length
  ) {
    return (
      <Grid
        isDropDownList={isDropDownList}
        showAllStore={showAllStore}
        rowKeys={rowKeys}
        data={contactAddresses.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={contactAddresses.totalCount}
        sort={sort}
        onDelete={onDeleteContactAddress}
        onSort={onSort}
        onEdit={onEditContactAddress}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateContactAddressesColumns}
        onSelect={onSelect}
        filter={filter}
        onFilter={onFilter}
        className={'contactAddresses-list'}
      />
    );
  }
  return (
    <div className="m-5 text-center">
      <h3 className="text-muted mb-4">
        You Don't Have Any Contact Address Yet
      </h3>
      <Button size={'lg'} color="primary" onClick={onCreateNewContactAddress}>
        Create Contact Address
      </Button>
    </div>
  );
};
