import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const DocumentTemplateForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

DocumentTemplateForm.BodyHtmlTemplate = ({
  className,
  style,
  size,
  disabled,
  name = 'bodyHtmlTemplate',
  label = 'Body Html Template',
  placeholder = 'Body Html Template',
  required,
  type = 'textarea',
  onChange,
  defaultValue,
  onClick,
}: InputPublicProps) => {
  return (
    <Input
      onChange={onChange}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={type}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      rows={10}
      htmlCode={true}
      onClick={onClick}
    />
  );
};

DocumentTemplateForm.BodyTextTemplate = ({
  className,
  style,
  size,
  disabled,
  name = 'bodyTextTemplate',
  label = 'Body Text Template',
  placeholder = 'Body Text Template',
  required,
  type = 'textarea',
  onChange,
  defaultValue,
}: InputPublicProps) => {
  return (
    <Input
      onChange={onChange}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={type}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      rows={10}
    />
  );
};
DocumentTemplateForm.CreatedUserId = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'createdUserId'}
      label={'Created User Id'}
      placeholder={'Created User Id'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DocumentTemplateForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DocumentTemplateForm.DocumentTemplateType = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'documentTemplateType'}
      label={'Document Template Type'}
      placeholder={'Document Template Type'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DocumentTemplateForm.DocumentTemplateType = ({
  onChange,
  options,
  name = 'documentTemplateType',
  id = 'documentTemplateType',
  placeholder = 'Document Template Type',
  required = true,
  multiple = false,
  disabled = false,
  header,
  defaultValue = '',
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};
DocumentTemplateForm.FileNameTemplate = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'fileNameTemplate'}
      label={'File Name Template'}
      placeholder={'File Name Template'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DocumentTemplateForm.IsDefault = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isDefault'}
      label={'Is Default'}
      placeholder={'Is Default'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DocumentTemplateForm.IsInactive = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isInactive'}
      label={'Is Inactive'}
      placeholder={'Is Inactive'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DocumentTemplateForm.Name = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DocumentTemplateForm.SubjectTemplate = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'subjectTemplate'}
      label={'Subject Template'}
      placeholder={'Subject Template'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
DocumentTemplateForm.UpdatedUserId = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'updatedUserId'}
      label={'Updated User Id'}
      placeholder={'Updated User Id'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
