import React, { useEffect, useState } from 'react';
import {
  AttachmentDto,
  AttachmentDtoPagedResult,
  AttachmentParentType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  attachmentStore,
  getAttachments,
  deleteAttachment,
} from '../attachments.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { AttachmentsGrid } from './attachments-grid.component';
import { updateOrdersColumns } from '../../orders/orders.store';
import { AttachmentDialog } from './attachment.dialog';
import { PreviewAttachmentDialog } from './preview-attachment.dialog';
import { getSearchQuery, parseSearchQuery } from '../../../utils/query.utils';
import { ReactDropzoneComponent } from '../../common/components/dropzone/react-dropzone.component';
import { AddAttachmentsDialog } from './add-attachments.dialog';

export type AttachmentsFilesListProps = {
  parentId: number;
  parentType: AttachmentParentType;
};

export const AttachmentsFilesList = ({
  parentId,
  parentType,
}: AttachmentsFilesListProps) => {
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [search, setSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const onPageChanged = (page) => {
    setOffset(limit * page);
  };
  const onSort = (field: string) => {
    setSort(field);
  };
  const onFilter = (query: string) => {
    setFilter(query);
  };
  const onSearch = (search: string) => {
    setSearch(search);
  };
  const [
    attachments,
    setAttachments,
  ] = useState<AttachmentDtoPagedResult | null>(null);

  const getAttachmentsData = () => {
    const filterObject = parseSearchQuery(filter);
    filterObject['parentId'] = parentId;
    filterObject['parentType'] = parentType;
    const newFilter = getSearchQuery(filterObject);
    getAttachments({ offset, limit, sort, filter: newFilter, search }).then(
      (attachmentsData) => {
        setAttachments(attachmentsData);
      },
    );
  };

  useEffect(() => {
    getAttachmentsData();
  }, [offset, sort, filter, search]);

  const { attachmentColumns: columns } = useStore(attachmentStore);

  const onDeleteAttachment = (attachment: AttachmentDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${attachment.attachmentId} Attachment`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteAttachment(attachment).then(getAttachmentsData);
      }
    });
  };

  const onEditAttachment = (attachment: AttachmentDto) => {
    if (attachment?.attachmentId) {
      showDialog({
        dialog: AttachmentDialog,
        props: {
          title: 'Update Order',
          attachmentId: attachment?.attachmentId,
        },
      }).then((result) => {
        if (result !== null) {
          getAttachmentsData();
        }
      });
    }
  };

  const onAttachmentSelect = (attachment, attachmentId) => {
    if (attachment?.attachmentId) {
      showDialog({
        dialog: PreviewAttachmentDialog,
        props: {
          title: 'Attachment Preview',
          attachment: attachment,
          className: 'preview-image-modal',
        },
      });
    }
  };

  const addAttachments = (files) => {
    if (files) {
      showDialog({
        dialog: AddAttachmentsDialog,
        props: {
          files,
          parentId: parentId,
          parentType: parentType,
          title: 'Add Attachments',
        },
      }).then(getAttachmentsData);
    }
  };
  return (
    <ReactDropzoneComponent onDrop={addAttachments}>
      <AttachmentsGrid
        showEmptyTable={false}
        showAllStore={true}
        rowKeys={null}
        data={attachments?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={attachments?.totalCount}
        sort={sort}
        onDelete={onDeleteAttachment}
        onSort={(field) => {
          onSort(field);
        }}
        search={search}
        onEdit={onEditAttachment}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateOrdersColumns}
        onSelect={onAttachmentSelect}
        filter={filter}
        onFilter={onFilter}
        onSearch={onSearch}
      />
    </ReactDropzoneComponent>
  );
};
