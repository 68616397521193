import {
  EventDefinitionDto,
  LinkDto,
  LinkResourceBaseDto,
  EventDefinitionDtoPagedResult,
  CreateEventDefinitionCommandValues,
  UpdateEventDefinitionCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';
import { API_ENDPOINT } from '../../properties';

export const GET_EVENTDEFINITIONS_LINK_KEY = 'get-eventdefinitions';
export const CREATE_EVENTDEFINITION_LINK_KEY = 'create-eventdefinition';
export const GET_EVENTDEFINITION_LINK_KEY = 'get-eventdefinition';
export const UPDATE_EVENTDEFINITION_LINK_KEY = 'update-eventdefinition';
export const DELETE_EVENTDEFINITION_LINK_KEY = 'delete-eventdefinition';

export const EVENTDEFINITION_ENTITY_NAME = 'Event Definition';

export interface GetEventDefinitionParams {
  eventDefinitionId: number;
}

export const getEventDefinitionRequest = async (
  resource: LinkResourceBaseDto | null,
  { eventDefinitionId }: GetEventDefinitionParams,
): Promise<EventDefinitionDto> => {
  if (resource && eventDefinitionId) {
    const getEventDefinitionLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_EVENTDEFINITION_LINK_KEY,
    );
    if (getEventDefinitionLink) {
      const result = await execLink(getEventDefinitionLink, {
        eventDefinitionId,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_EVENTDEFINITION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getEventDefinitionsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<EventDefinitionDtoPagedResult> => {
  if (resource) {
    const getEventDefinitionsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_EVENTDEFINITIONS_LINK_KEY,
    );
    if (getEventDefinitionsListLink) {
      const result = await execLink(getEventDefinitionsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_EVENTDEFINITIONS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getEventDefinitionsListAnonymous = async (
  organizationId: string,
  params: ListParams,
): Promise<EventDefinitionDtoPagedResult> => {
  if (organizationId && params) {
    const url = `${API_ENDPOINT}/api/organizations/${organizationId}/eventDefinitions/getAnonymous{?limit,offset,sort,filter,search}`;
    const getEventDefinitionsListLink: LinkDto = {
      href: url,
      templated: true,
      type: 'get',
    };
    const result = await execLink(getEventDefinitionsListLink, params);
    return result.data;
  }
  return Promise.reject();
};

export const createEventDefinitionRequest = async (
  resource: LinkResourceBaseDto,
  eventDefinition: CreateEventDefinitionCommandValues,
) => {
  const createEventDefinitionLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_EVENTDEFINITION_LINK_KEY,
  );
  if (createEventDefinitionLink) {
    const result = await execLink(createEventDefinitionLink, eventDefinition);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_EVENTDEFINITION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateEventDefinitionRequest = async (
  resource: LinkResourceBaseDto,
  eventDefinition: UpdateEventDefinitionCommandValues,
) => {
  const updateEventDefinitionLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_EVENTDEFINITION_LINK_KEY,
  );
  if (updateEventDefinitionLink) {
    const result = await execLink(updateEventDefinitionLink, eventDefinition);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_EVENTDEFINITION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteEventDefinitionRequest = async (
  eventDefinition: EventDefinitionDto,
) => {
  const deleteEventDefinitionLink = eventDefinition.links?.find(
    (x: LinkDto) => x.rel === DELETE_EVENTDEFINITION_LINK_KEY,
  );
  if (deleteEventDefinitionLink) {
    const result = await execLink(deleteEventDefinitionLink, eventDefinition);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_EVENTDEFINITION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
