import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  CustomValuesInputProps,
  Input,
  InputProps,
  InputPublicProps,
  ReactSelectInputProps,
} from '../../common/components/input/input.component';
import {
  AddressType,
  CarrierCodesForSelect,
  VisibleTransactions,
} from '../../../models/data.models';
import { CustomerSelect } from '../../customers/components/customer-select.component';
import { SelectPaymentTermPaginate } from '../../paymentTerms/components/paymentTerm-react-select.component';
import { SelectDivisionPaginate } from '../../divisions/components/division-react-select.component';
import { SelectContactPaginate } from './contact-react-select.component';
import { SelectCountryPaginate } from '../../countries/components/country-react-select.component';
import { SelectStatePaginate } from '../../states/components/state-react-select.component';
import { SelectUserPaginate } from '../../users/components/user-react-select.component';
import { SelectUserGroupPaginate } from '../../userGroups/components/userGroup-react-select.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';
import { PlaceAutocompleteInput } from '../../common/components/input/input-placeAutocomplete.component';
import { MCFFInput } from '../../common/components/input/input-mcffCode.component';
import { getEnumValues } from '../../../utils/helper.utils';
import { PlaceAutocompleteAsyncInput } from '../../common/components/input/input-placeAutocompleteAsync.component';

export const ContactForm = ({
  children,
  onSubmit,
  initialValues,
  innerRef,
  id,
  validationSchema,
  validateOnChange,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
    >
      {children}
    </Form>
  );
};
ContactForm.Name = ({
  name = 'name',
  placeholder = 'Name',
  header = 'Name',
  defaultValue,
  onSelectAsync,
  renderDropdown = true,
  onChange,
}: InputPublicProps) => {
  return (
    <PlaceAutocompleteAsyncInput
      name={name}
      placeholder={placeholder}
      header={header}
      defaultValue={defaultValue}
      onSelect={onSelectAsync}
      renderDropdown={renderDropdown}
      onChange={onChange}
      types={['establishment']}
    />
  );
};
ContactForm.NameInput = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.Id = ({
  className,
  style,
  size,
  required = true,
}: InputPublicProps) => {
  return (
    <Input
      name={'contactId'}
      label={'Entity Id'}
      placeholder={'Entity Id'}
      required={required}
      type={'text'}
      readonly={'readonly'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.Phone = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'phoneNumber'}
      label={'Phone'}
      placeholder={'Phone'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.MobilePhone = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'mobilePhoneNumber'}
      label={'Mobile Phone'}
      placeholder={'Mobile Phone'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.Fax = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'faxNumber'}
      label={'Fax'}
      placeholder={'Fax'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.Email = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'emailAddress'}
      label={'Email'}
      placeholder={'Email'}
      required={required}
      type={'email'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.Website = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'website'}
      label={'Website'}
      placeholder={'Website'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.AccountNumber = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'accountNumber'}
      label={'Account Number'}
      placeholder={'Account Number'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.ContactFirstName = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'contactFirstName'}
      label={'First Name'}
      placeholder={'First Name'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.ContactLastName = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'contactLastName'}
      label={'Last Name'}
      placeholder={'Last Name'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.IdNumber = ({
  className,
  style,
  size,
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      name={'idNumber'}
      label={'Id Number'}
      placeholder={'Id Number'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.IdNumberType = ({
  onChange,
  options,
  name = 'idNumberType',
  id = 'idNumberType',
  placeholder = 'Select Id Number Type',
  required = false,
  multiple = false,
  disabled = false,
  header,
  defaultValue = '',
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};
ContactForm.DivisionSelector = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = true,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = 'divisionName',
  disabled = true,
}: ReactSelectInputProps) => {
  return (
    <SelectDivisionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isClearable={false}
      isDisabled={disabled}
    />
  );
};

ContactForm.LinkToContactId = ({
  selectedFilter = ``,
  placeholder = 'Select Parent Contact',
  required = false,
  multiple = false,
  defaultValue,
  sort = 'contactType',
  id = 'linkToContactId',
  nameId = 'linkToContactName',
  header = 'Parent Contact',
  onChange = () => {},
  readonly,
  contactTypes = [],
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      useContext={true}
      nameId={nameId}
      id={id}
      header={header}
      placeholder={placeholder}
      selectedSort={sort}
      required={required}
      selectedValue={defaultValue}
      isMulti={multiple}
      onChangeContact={onChange}
      showContactType={true}
      selectedFilter={selectedFilter}
      closeMenuOnSelect={true}
      isSearchable={true}
      contactTypes={contactTypes}
      readonly={readonly ? true : false}
    />
  );
};

ContactForm.ContactAddressAddressLine = ({
  name = 'addressLine',
  label = 'Address Line',
  placeholder = 'Address Line',
  defaultValue,
  lat,
  lng,
  onSelect,
  renderDropdown = true,
  onChange,
}: InputPublicProps) => {
  return (
    <PlaceAutocompleteInput
      name={name}
      placeholder={placeholder}
      header={label}
      defaultValue={defaultValue}
      onSelect={onSelect}
      lat={lat}
      lng={lng}
      renderDropdown={renderDropdown}
      onChange={onChange}
    />
  );
};
ContactForm.ContactAddressAddressLine2 = ({
  className,
  style,
  size,
  ref,
  name = 'addressLine2',
  label = 'Address Line2',
  placeholder = 'Address Line2',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.ContactAddressAddressType = ({
  className,
  style,
  size,
  ref,
  name = 'addressType',
  label = 'Address Type',
  placeholder = 'Select Address Type',
  required = true,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'enum-select'}
      enumName={AddressType}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.ContactAddressCity = ({
  className,
  style,
  size,
  ref,
  name = 'city',
  label = 'City',
  placeholder = 'City',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.ContactAddressContactSelect = ({
  className,
  style,
  size,
  ref,
  id = 'contactId',
  nameId = 'Contact Id',
  header = 'Contact Id',
}: InputPublicProps) => {
  return (
    <CustomerSelect id={id} required={true} nameId={nameId} header={header} />
  );
};
ContactForm.ContactAddressCountryCode = ({
  selectedFilter = ``,
  placeholder = 'Select Country',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'countryCode',
  header = 'Country',
  onChange = () => {},
  readonly,
  nameId = 'name',
}: InputPublicProps) => {
  return (
    <SelectCountryPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeCountry={onChange}
    />
  );
};
ContactForm.StateSelect = ({
  selectedFilter = ``,
  placeholder = 'Select State',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'stateCode',
  header = 'State',
  onChange = () => {},
  readonly,
  nameId = 'stateCode',
}: InputPublicProps) => {
  return (
    <SelectStatePaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeState={onChange}
    />
  );
};
ContactForm.ContactAddressPostalCode = ({
  className,
  style,
  size,
  ref,
  name = 'postalCode',
  label = 'Postal Code',
  placeholder = 'Postal Code',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

ContactForm.PaymentTermsSelect = ({
  className,
  style,
  size,
  selectedFilter,
  onChange,
  id = 'paymentTermsId',
  nameId = 'paymentTermsName',
  header = 'Payment Terms Id',
  required = false,
  readonly,
  disabled,
  sort = 'description',
  placeholder = 'Select Payment Term',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectPaymentTermPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePaymentTerm={onChange}
    />
  );
};

ContactForm.ContactType = ({
  className,
  style,
  size,
  disabled,
  required = true,
  id = 'contactType',
  name = 'contactType',
  header = 'Contact Type',
  placeholder = 'Select Contact Type',
  options,
  defaultValue,
  multiple = false,
  onChange = () => {},
  useContext = true,
  label = 'Contact Type',
  isClearable = true,
}: InputProps) => {
  return (
    <Input
      label={label}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      useContext={useContext}
      isClearable={isClearable}
    />
  );
};

ContactForm.User = ({
  selectedFilter = ``,
  placeholder = 'Select User',
  required = false,
  defaultValue,
  sort = 'userName',
  id = 'userId',
  header = 'User',
  onChange = () => {},
  readonly,
  nameId = 'userName',
  disabled = false,
}: InputPublicProps) => {
  return (
    <SelectUserPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeUser={onChange}
      isDisabled={disabled}
    />
  );
};

ContactForm.UserGroups = ({
  selectedFilter = ``,
  placeholder = 'Select Groups',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'userGroupId',
  header = 'Groups',
  onChange = () => {},
  readonly,
  nameId = 'userGroupName',
  disabled = false,
}: InputPublicProps) => {
  return (
    <SelectUserGroupPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeUserGroup={onChange}
      isDisabled={disabled}
    />
  );
};

ContactForm.TransactionsVisible = ({
  type = 'radio-button',
  enumName = VisibleTransactions,
  name = 'userEmployee.visibleTransactions',
  onChange,
}: InputPublicProps) => {
  return (
    <Input type={type} enumName={enumName} name={name} onChange={onChange} />
  );
};

ContactForm.CreditLimit = ({
  className,
  style,
  size,
  disabled,
  required = false,
  readonly,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'creditLimit'}
      label={'Credit Limit'}
      placeholder={'Credit Limit'}
      required={required}
      type={'text'}
      className={className}
      style={style}
      readonly={readonly}
      disabled={disabled}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};

ContactForm.PaidAs = ({
  onChange,
  options,
  name = 'paidAs',
  id = 'paidAs',
  placeholder = 'Select Paid As',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Paid As',
  defaultValue = '',
}: InputPublicProps) => {
  return (
    <Input
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};

ContactForm.IsACorporation = ({
  className,
  style,
  size,
  disabled,
  required = true,
}: InputPublicProps) => {
  return (
    <Input
      required={required}
      name={'isACorporation'}
      label={'Is a Corporation'}
      className={className}
      style={style}
      type={'checkbox'}
      size={size}
    />
  );
};

ContactForm.CustomValues = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      onChange={onChange}
      defaultValue={defaultValue}
      isSending={isSending}
      saveButtonRenderCondition={saveButtonRenderCondition}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};
ContactForm.Latitude = ({
  className,
  style,
  size,
  ref,
  name = 'latitude',
  label = 'Latitude',
  placeholder = 'Latitude',
  required = false,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'input-OnChange-number'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
    />
  );
};
ContactForm.Longitude = ({
  className,
  style,
  size,
  ref,
  name = 'longitude',
  label = 'Longitude',
  placeholder = 'Longitude',
  required = false,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'input-OnChange-number'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
    />
  );
};
ContactForm.MCFFCode = ({
  className,
  style,
  size,
  ref,
  label = 'M.C. / F.F.',
  required = false,
  disabled = false,
  onClick,
}: InputPublicProps) => {
  return (
    <MCFFInput
      ref={ref}
      header={label}
      required={required}
      disabled={disabled}
      type={'text'}
      className={className}
      style={style}
      size={size}
      options={getEnumValues(CarrierCodesForSelect)}
      onClick={onClick}
    />
  );
};
ContactForm.MCCode = ({
  className,
  style,
  size,
  ref,
  name = 'mcCode',
  placeholder = 'M.C.',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.FFCode = ({
  className,
  style,
  size,
  ref,
  name = 'ffCode',
  placeholder = 'F.F.',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
ContactForm.DOTCode = ({
  className,
  style,
  size,
  ref,
  name = 'dotCode',
  label = 'D.O.T.',
  placeholder = 'D.O.T.',
  required = false,
}: InputPublicProps) => {
  return (
    <Input
      ref={ref}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
