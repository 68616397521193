import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetContactsQuery } from '../../../models/data.models';
import {
  EVENTDEFINITIONS_CREATE_PATH,
  EVENTDEFINITIONS_EDIT_PATH,
  EVENTDEFINITIONS_LIST_PATH,
} from '../eventDefinitions.route';
import { EventDefinitionsList } from '../components/eventDefinitions-list.component';
import {
  CREATE_EVENTDEFINITION_LINK_KEY,
  GetEventDefinitionParams,
} from '../eventDefinitions.service';
import { Helmet } from 'react-helmet';
import { authStore, userHas } from '../../auth/auth.store';

export type EventDefinitionsListScreenProps = { pageTitle: string };

export const EventDefinitionsListScreen = ({
  pageTitle,
}: EventDefinitionsListScreenProps) => {
  const { user: currentUser } = authStore.getState();
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = 'eventDefinitionId',
    filter = '',
    search = '',
  } = useQuery() as GetContactsQuery;
  const history = useHistory();

  const createPath = () => {
    return generatePath(INTERNAL_PATH + EVENTDEFINITIONS_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = (eventDefinitionParams: GetEventDefinitionParams) => {
    const path = generatePath(INTERNAL_PATH + EVENTDEFINITIONS_EDIT_PATH, {
      organizationId,
      eventDefinitionId: eventDefinitionParams.eventDefinitionId,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + EVENTDEFINITIONS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + EVENTDEFINITIONS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + EVENTDEFINITIONS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + EVENTDEFINITIONS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };
  return (
    <InternalLayout
      title={'Event Definitions'}
      createPath={
        userHas(CREATE_EVENTDEFINITION_LINK_KEY) &&
        !currentUser?.isInOperationRole
          ? createPath()
          : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <EventDefinitionsList
        search={search}
        onSearch={onSearch}
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        filter={filter}
      />
    </InternalLayout>
  );
};
