import { GOOGLE_MAPS_SCRIPT } from '../../../utils/useJsScript.utils';
import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderTrackingMap } from './orderTrackingMap.component';

export type OrderTrackingMapDialogProperties = {
  title: string;
  lat?: number;
  lng?: number;
  coords?: GeolocationCoordinates;
  isGeolocationAvailable?: boolean;
  isGeolocationEnabled?: boolean;
  isMobile?: boolean;
};

export class OrderTrackingMapDialog extends Dialog<OrderTrackingMapDialogProperties> {
  constructor(props: OrderTrackingMapDialogProperties) {
    super(props);
    this.onSaveLocation = this.onSaveLocation.bind(this);
  }

  onSaveLocation = (locationName: string, lat: number, lng: number) => {
    this.close({ locationName, lat, lng });
  };

  renderContent(): any {
    const {
      lat,
      lng,
      coords,
      isGeolocationAvailable,
      isGeolocationEnabled,
      isMobile,
    } = this.props as OrderTrackingMapDialogProperties;

    return (
      <OrderTrackingMap
        googleMapURL={GOOGLE_MAPS_SCRIPT}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={
          <div
            style={{
              height: isMobile ? '350px' : '600px',
              paddingBottom: '50px',
            }}
            className={'google-maps'}
          />
        }
        mapElement={<div style={{ height: '100%' }} />}
        onSaveLocation={this.onSaveLocation}
        lat={lat}
        lng={lng}
        coords={coords}
        isGeolocationAvailable={isGeolocationAvailable}
        isGeolocationEnabled={isGeolocationEnabled}
        isMobile={isMobile}
      />
    );
  }
}
