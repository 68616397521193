import {
  CommodityDto,
  LinkDto,
  LinkResourceBaseDto,
  CommodityDtoPagedResult,
  CreateCommodityCommandValues,
  UpdateCommodityCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCommodityRequest,
  getCommoditiesListRequest,
  getCommodityRequest,
  deleteCommodityRequest,
  updateCommodityRequest,
  GetCommodityParams,
} from './commodities.service';

type CommoditiesStoreState = {
  links: LinkDto[];
  commodityColumns: Column[];
};

export const updateCommoditiesColumns = createEvent<Column[]>();
export const updateCommoditiesForOrderColumns = createEvent<Column[]>();
export const getCommodities = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCommoditiesListRequest(currentOrganization, params);
});

export const getCommoditiesForOrder = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return;
  },
);

export const createCommodity = createEffect((commodityData: CommodityDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  commodityData.organizationId = currentOrganization?.organizationId;
  const createCommodityCommand: CreateCommodityCommandValues = {
    ...commodityData,
  };

  return createCommodityRequest(currentOrganization!, createCommodityCommand);
});

export const getCommodity = createEffect(
  (commodityParams: GetCommodityParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getCommodityRequest(
      currentOrganization as LinkResourceBaseDto,
      commodityParams,
    );
  },
);

export const updateCommodity = createEffect((commodity: CommodityDto) => {
  const updateCommodityCommand: UpdateCommodityCommandValues = { ...commodity };
  return updateCommodityRequest(commodity, updateCommodityCommand);
});

export const deleteCommodity = createEffect((commodity: CommodityDto) => {
  return deleteCommodityRequest(commodity);
});

const defaultState: CommoditiesStoreState = {
  links: [],
  commodityColumns: [
    {
      name: 'commodityStatus',
      visible: true,
      title: 'Commodity Status',
      sortName: 'commodityStatus',
    },
    {
      name: 'packageTypeName',
      visible: true,
      title: 'Package Type',
      sortName: 'PackageType.Name',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    { name: 'pieces', visible: true, title: 'pieces', sortName: 'pieces' },
    { name: 'length', visible: true, title: 'Length', sortName: 'length' },
    { name: 'width', visible: true, title: 'Width', sortName: 'width' },
    { name: 'height', visible: true, title: 'Height', sortName: 'height' },
    { name: 'weight', visible: true, title: 'Weight', sortName: 'weight' },
    {
      name: 'volumePiece',
      visible: true,
      title: 'Volume',
      sortName: 'volumePiece',
    },
  ],
};

export const commodityStore: Store<CommoditiesStoreState> = createStore(
  defaultState,
).on(updateCommoditiesColumns, (state, payload) => {
  return { ...state, commodityColumns: payload };
});
export const commodityStoreForOrder: Store<CommoditiesStoreState> = createStore(
  defaultState,
).on(updateCommoditiesForOrderColumns, (state, payload) => {
  return { ...state, commodityColumns: payload };
});
