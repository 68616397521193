import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { BillsListScreen } from './screens/bills-list.screen';
import { BillsScreen } from './screens/bills.screen';

export const BILLS_LIST_PATH = '/bills';
export const BILLS_EDIT_PATH = '/bills/:accountingTransactionId';
export const BILLS_CREATE_PATH = '/bills/0';
export const BILLS_PATH = '/org/:organizationId/bills';

export const BillsRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${BILLS_LIST_PATH}`}>
          <BillsListScreen pageTitle="Bills" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${BILLS_CREATE_PATH}`}>
          <BillsScreen pageTitle="Add New Bill" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${BILLS_EDIT_PATH}`}>
          <BillsScreen pageTitle="Update Bill" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
