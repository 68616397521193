import {
  PackageCategoryDto,
  LinkDto,
  LinkResourceBaseDto,
  PackageCategoryDtoPagedResult,
  CreatePackageCategoryCommandValues,
  UpdatePackageCategoryCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPackageCategoryRequest,
  getPackageCategoriesListRequest,
  getPackageCategoryRequest,
  deletePackageCategoryRequest,
  updatePackageCategoryRequest,
  GetPackageCategoryParams,
} from './packageCategories.service';

type PackageCategoriesStoreState = {
  links: LinkDto[];
  packageCategoryColumns: Column[];
};

export const updatePackageCategoriesColumns = createEvent<Column[]>();

export const getPackageCategories = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPackageCategoriesListRequest(currentOrganization, params);
});

export const createPackageCategory = createEffect(
  (packageCategoryData: PackageCategoryDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createPackageCategoryCommand: CreatePackageCategoryCommandValues = {
      ...packageCategoryData,
    };

    return createPackageCategoryRequest(
      currentOrganization!,
      createPackageCategoryCommand,
    );
  },
);

export const getPackageCategory = createEffect(
  (packageCategoryParams: GetPackageCategoryParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getPackageCategoryRequest(
      currentOrganization as LinkResourceBaseDto,
      packageCategoryParams,
    );
  },
);

export const updatePackageCategory = createEffect(
  (packageCategory: PackageCategoryDto) => {
    const updatePackageCategoryCommand: UpdatePackageCategoryCommandValues = {
      ...packageCategory,
    };
    return updatePackageCategoryRequest(
      packageCategory,
      updatePackageCategoryCommand,
    );
  },
);

export const deletePackageCategory = createEffect(
  (packageCategory: PackageCategoryDto) => {
    return deletePackageCategoryRequest(packageCategory);
  },
);

const defaultState: PackageCategoriesStoreState = {
  links: [],
  packageCategoryColumns: [
    { name: 'name', visible: true, title: 'Name', sortName: 'name' },
    {
      name: 'packageCategoryCode',
      visible: true,
      title: 'Package Category Code',
      sortName: 'packageCategoryCode',
    },
    {
      name: 'isContainer',
      visible: true,
      title: 'Is Container',
      sortName: 'isContainer',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
  ],
};

export const packageCategoryStore: Store<PackageCategoriesStoreState> = createStore(
  defaultState,
).on(updatePackageCategoriesColumns, (state, payload) => {
  return { ...state, packageCategoryColumns: payload };
});
