import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  AccountingItemDto,
  AccountingItemDtoPagedResult,
  OrganizationConfigDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  accountingItemStore,
  getAccountingItems,
  deleteAccountingItem,
  updateAccountingItemsColumns,
  downloadQuickBooksAccountingItems,
} from '../accountingItems.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_ACCOUNTINGITEM_LINK_KEY,
  DELETE_ACCOUNTINGITEM_LINK_KEY,
  GetAccountingItemParams,
} from '../accountingItems.service';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { organizationsStore } from '../../organization/organization.store';
import { INTERNAL_PATH } from '../../../app.router';
import { ACCOUNTINGITEM_CREATE_PATH } from '../accountingItem.route';
import { AccountingItemDialog } from './accountingItem.dialog';
import { userHas } from '../../auth/auth.store';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';
import { getOrganizationConfigs } from '../../organizationConfigs/organizationConfigs.store';

export type AccountingItemsListProps = {
  goToDetails?: (accountingItemParams: GetAccountingItemParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    accountingItem: AccountingItemDto,
    accountingItemParams: GetAccountingItemParams,
  ) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const AccountingItemsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  showAllStore = true,
  rowKeys = ['accountingItemId'],
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: AccountingItemsListProps) => {
  // @ts-ignore
  const { organizationId } = useParams();

  const [
    accountingItems,
    setAccountingItems,
  ] = useState<AccountingItemDtoPagedResult | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [QBConnected, setQBConnected] = useState<boolean>(false);

  useEffect(() => {
    getAccountingItemsData();
  }, [offset, limit, sort, filter, search]);

  useEffect(() => {
    getQuickBooksData();
  }, []);

  const getAccountingItemsData = () => {
    getAccountingItems({ offset, limit, sort, filter, search }).then(
      (accountingItemsData) => {
        setAccountingItems(accountingItemsData);
      },
      () => {},
    );
  };
  const { accountingItemColumns: columns } = useStore(accountingItemStore);

  const onDeleteAccountingItem = (accountingItem: AccountingItemDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete Accounting Item`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteAccountingItem(accountingItem).then(
            () => {
              onDelete();
              getAccountingItemsData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const getQuickBooksData = async (): Promise<void> => {
    if (organizationId) {
      const qbConfigName = 'QuickBooks';
      getOrganizationConfigs({
        limit: 1,
        offset: 0,
        sort: '',
        filter: `configName:${qbConfigName} AND organizationId:${organizationId}`,
        search: '',
      }).then(
        (quickBooksDto: OrganizationConfigDtoPagedResult) => {
          if (quickBooksDto.items.length === 0) {
            setQBConnected(false);
          } else {
            setQBConnected(true);
          }
        },
        () => {},
      );
    }
  };

  const onEditAccountingItem = (accountingItem: AccountingItemDto) => {
    goToDetails({ accountingItemId: accountingItem.accountingItemId });
  };
  const history = useHistory();
  const onCreateNewAccountingItem = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(
      INTERNAL_PATH + ACCOUNTINGITEM_CREATE_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    history.push(createPath);
  };
  const onAccountingItemSelect = (accountingItem, accountingItemId) => {
    if (onSelect) onSelect(accountingItem, accountingItemId);
    showDialog({
      dialog: AccountingItemDialog,
      props: {
        accountingItemId: accountingItem.accountingItemId,
        title: 'Update Accounting Item',
      },
    }).then(
      (accountingItem) => {
        if (accountingItem !== null) {
          getAccountingItemsData();
        }
      },
      () => {},
    );
  };

  const downloadQuickBooksItems = () => {
    setIsLoading(true);
    downloadQuickBooksAccountingItems()
      .then(getAccountingItemsData, () => {})
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <>
      <Grid
        isDropDownList={isDropDownList}
        search={search}
        showEmptyTable={true}
        onSearch={onSearch}
        showAllStore={showAllStore}
        rowKeys={rowKeys}
        data={accountingItems?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={accountingItems?.totalCount}
        sort={sort}
        onDelete={
          userHas(
            DELETE_ACCOUNTINGITEM_LINK_KEY,
            accountingItems?.items[0]?.links,
          )
            ? onDeleteAccountingItem
            : null
        }
        onSort={onSort}
        onEdit={onEditAccountingItem}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateAccountingItemsColumns}
        onSelect={onAccountingItemSelect}
        className={'accountingItems-list'}
        filter={filter}
        onFilter={onFilter}
        actionBar={
          <ActionBarComponent
            buttonText={<>&middot;&middot;&middot;</>}
            style={{ minWidth: 'fit-content' }}
          >
            <button
              disabled={!QBConnected}
              type={'button'}
              onClick={downloadQuickBooksItems}
            >
              Download QuickBooks Items
            </button>
          </ActionBarComponent>
        }
      />
      {!(
        (accountingItems &&
          accountingItems.items &&
          accountingItems.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Accounting Item Yet
          </h3>
          <div className="m-4">
            {userHas(CREATE_ACCOUNTINGITEM_LINK_KEY) && (
              <Button
                size={'lg'}
                color="primary"
                onClick={onCreateNewAccountingItem}
              >
                Create Accounting Item
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
