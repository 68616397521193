import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectSalesTaxPaginate } from '../../salesTaxes/components/salesTax-react-select.component';
import { SelectAccountingAccountPaginate } from '../../accountingAccounts/components/accountingAccount-react-select.component';

export const AccountingItemForm = ({
  children,
  onSubmit,
  initialValues,
  innerRef,
  id,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      innerRef={innerRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

AccountingItemForm.AccountId = ({
  selectedFilter,
  onChange,
  id = 'accountId',
  nameId = 'accountName',
  header = 'Account',
  required = true,
  placeholder,
  readonly,
  disabled,
  sort = 'accountName',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectAccountingAccountPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeAccountingAccount={onChange}
      showAccountType={true}
    />
  );
};
AccountingItemForm.Description = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'description'}
      label={'Description'}
      placeholder={'Description'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AccountingItemForm.IsInactive = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'isInactive'}
      id={'isInactiveAccountingItemCheckbox'}
      label={'Is Inactive'}
      placeholder={'Is Inactive'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AccountingItemForm.ItemCode = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'itemCode'}
      label={'Item Code'}
      placeholder={'Item Code'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
AccountingItemForm.ItemType = ({
  onChange,
  options,
  name = 'itemType',
  id = 'itemType',
  placeholder = 'Select Item Type',
  required = true,
  multiple = false,
  disabled = false,
  header,
  defaultValue = '',
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      type={'react-select'}
      name={name}
      id={id}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
    />
  );
};
AccountingItemForm.Price = ({
  className,
  style,
  size,
  disabled,
  required = true,
  onKeyDown,
}: InputPublicProps) => {
  return (
    <Input
      name={'price'}
      label={'Price'}
      placeholder={'Price'}
      required={required}
      type={'text'}
      minNumber={0}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      onKeyDown={onKeyDown}
    />
  );
};
AccountingItemForm.SalesTaxId = ({
  selectedFilter = ``,
  placeholder = 'Select Sales Tax',
  required = false,
  defaultValue,
  sort = 'taxCode',
  id = 'salesTaxId',
  header = 'Sales Tax',
  onChange = () => {},
  nameId = 'salesTaxCode',
}: InputPublicProps) => {
  return (
    <SelectSalesTaxPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeSalesTax={onChange}
    />
  );
};
AccountingItemForm.Tariff = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'tariff'}
      label={'Tariff'}
      placeholder={'Tariff'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
