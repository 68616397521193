import {
  AccountingTransactionDto,
  LinkDto,
  LinkResourceBaseDto,
  AccountingTransactionDtoPagedResult,
  CreateAccountingTransactionCommandValues,
  UpdateAccountingTransactionCommandValues,
  UpdateChargeCommand,
  CreateChargeCommand,
  Column,
  PostAccountingTransactionToQBCommandValues,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createAccountingTransactionRequest,
  getAccountingTransactionsListRequest,
  getAccountingTransactionRequest,
  deleteAccountingTransactionRequest,
  updateAccountingTransactionRequest,
  GetAccountingTransactionParams,
  postAccountingTransactionToQBRequest,
} from './accountingTransactions.service';

type AccountingTransactionsStoreState = {
  links: LinkDto[];
  accountingTransactionColumns: Column[];
};

export const updateAccountingTransactionsColumns = createEvent<Column[]>();

export const getAccountingTransactions = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAccountingTransactionsListRequest(currentOrganization, params);
  },
);

export const createAccountingTransaction = createEffect(
  (accountingTransactionData: AccountingTransactionDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const charges: CreateChargeCommand[] = accountingTransactionData.charges.map<CreateChargeCommand>(
      (item) => {
        const createChargeCommand: CreateChargeCommand = {
          values: {
            amount: item.amount,
            currencyId: item.currencyId,
            accountingItemId: item.accountingItemId,
            applyBy: item.applyBy,
            applyToContactId: item.applyToContactId,
            chargeType: item.chargeType,
            chargeStatus: item.chargeStatus,
            description: item.description,
            freightServiceClassId: item.freightServiceClassId,
            grossVolume: item.grossVolume,
            grossWeight: item.grossWeight,
            isConsolidated: item.isConsolidated,
            note: item.note,
            paidAs: item.paidAs,
            pieces: item.pieces,
            price: item.price,
            quantity: item.quantity,
            showInDocuments: item.showInDocuments,
            unit: item.unit,
            salesTaxRate: item.salesTaxRate ?? 0,
            salesTaxAmount: item.salesTaxAmount,
            salesTaxId: item.salesTaxId,
            totalAmount: item.totalAmount,
            allowAutomaticUpdate: item.allowAutomaticUpdate,
          },
        };
        return createChargeCommand;
      },
    );
    const createAccountingTransactionCommand: CreateAccountingTransactionCommandValues = {
      divisionId: accountingTransactionData.divisionId,
      accountId: accountingTransactionData.accountId,
      accountingTransactionStatus:
        accountingTransactionData.accountingTransactionStatus,
      accountingTransactionType:
        accountingTransactionData.accountingTransactionType,
      applyToContactID: accountingTransactionData.applyToContactID,
      billToContactAddressId: accountingTransactionData.billToContactAddressId,
      dueDate: accountingTransactionData.dueDate,
      note: accountingTransactionData.note,
      paidAs: accountingTransactionData.paidAs,
      paymentTermsId: accountingTransactionData.paymentTermsId,
      transactionDate: accountingTransactionData.transactionDate,
      transactionNumber: accountingTransactionData.transactionNumber,
      charges,
    };

    return createAccountingTransactionRequest(
      currentOrganization!,
      createAccountingTransactionCommand,
    );
  },
);

export const getAccountingTransaction = createEffect(
  (accountingTransactionParams: GetAccountingTransactionParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAccountingTransactionRequest(
      currentOrganization as LinkResourceBaseDto,
      accountingTransactionParams,
    );
  },
);

export const updateAccountingTransaction = createEffect(
  (accountingTransaction: AccountingTransactionDto) => {
    const charges: UpdateChargeCommand[] = accountingTransaction.charges.map<UpdateChargeCommand>(
      (item) => {
        const updateChargeCommand: UpdateChargeCommand = {
          chargeId: item.chargeId,
          values: {
            amount: item.amount,
            currencyId: item.currencyId,
            accountingItemId: item.accountingItemId,
            applyBy: item.applyBy,
            applyToContactId: item.applyToContactId,
            chargeType: item.chargeType,
            chargeStatus: item.chargeStatus,
            description: item.description,
            freightServiceClassId: item.freightServiceClassId,
            grossVolume: item.grossVolume,
            grossWeight: item.grossWeight,
            isConsolidated: item.isConsolidated,
            note: item.note,
            paidAs: item.paidAs,
            pieces: item.pieces,
            price: item.price,
            quantity: item.quantity,
            showInDocuments: item.showInDocuments,
            unit: item.unit,
            salesTaxRate: item.salesTaxRate ?? 0,
            salesTaxAmount: item.salesTaxAmount,
            totalAmount: item.totalAmount,
            salesTaxId: item.salesTaxId,
            allowAutomaticUpdate: item.allowAutomaticUpdate,
          },
        };
        return updateChargeCommand;
      },
    );
    const updateAccountingTransactionCommand: UpdateAccountingTransactionCommandValues = {
      divisionId: accountingTransaction.divisionId,
      accountId: accountingTransaction.accountId,
      accountingTransactionStatus:
        accountingTransaction.accountingTransactionStatus,
      accountingTransactionType:
        accountingTransaction.accountingTransactionType,
      applyToContactID: accountingTransaction.applyToContactID,
      billToContactAddressId: accountingTransaction.billToContactAddressId,
      dueDate: accountingTransaction.dueDate,
      note: accountingTransaction.note,
      paidAs: accountingTransaction.paidAs,
      paymentTermsId: accountingTransaction.paymentTermsId,
      transactionDate: accountingTransaction.transactionDate,
      transactionNumber: accountingTransaction.transactionNumber,
      customValues: accountingTransaction.customValues,
      charges,
      qbEnable: accountingTransaction.qbEnable,
    };
    return updateAccountingTransactionRequest(
      accountingTransaction,
      updateAccountingTransactionCommand,
    );
  },
);

export const postAccountingTransactionToQB = createEffect(
  (accountingTransaction: AccountingTransactionDto) => {
    const charges: UpdateChargeCommand[] = accountingTransaction.charges.map<UpdateChargeCommand>(
      (item) => {
        const chargeCommand: UpdateChargeCommand = {
          chargeId: item.chargeId,
          values: {
            amount: item.amount,
            currencyId: item.currencyId,
            accountingItemId: item.accountingItemId,
            applyBy: item.applyBy,
            applyToContactId: item.applyToContactId,
            chargeType: item.chargeType,
            chargeStatus: item.chargeStatus,
            description: item.description,
            freightServiceClassId: item.freightServiceClassId,
            grossVolume: item.grossVolume,
            grossWeight: item.grossWeight,
            isConsolidated: item.isConsolidated,
            note: item.note,
            paidAs: item.paidAs,
            pieces: item.pieces,
            price: item.price,
            quantity: item.quantity,
            showInDocuments: item.showInDocuments,
            allowAutomaticUpdate: item.allowAutomaticUpdate,
            unit: item.unit,
            salesTaxRate: item.salesTaxRate ?? 0,
            salesTaxAmount: item.salesTaxAmount,
            totalAmount: item.totalAmount,
            salesTaxId: item.salesTaxId,
          },
        };
        return chargeCommand;
      },
    );
    const postAccountingTransactionToQBCommand: PostAccountingTransactionToQBCommandValues = {
      accountingTransactionId: accountingTransaction.accountingTransactionId,
      divisionId: accountingTransaction.divisionId,
      accountId: accountingTransaction.accountId,
      accountingTransactionStatus:
        accountingTransaction.accountingTransactionStatus,
      accountingTransactionType:
        accountingTransaction.accountingTransactionType,
      applyToContactID: accountingTransaction.applyToContactID,
      billToContactAddressId: accountingTransaction.billToContactAddressId,
      dueDate: accountingTransaction.dueDate,
      note: accountingTransaction.note,
      paidAs: accountingTransaction.paidAs,
      paymentTermsId: accountingTransaction.paymentTermsId,
      transactionDate: accountingTransaction.transactionDate,
      transactionNumber: accountingTransaction.transactionNumber,
      charges,
    };
    return postAccountingTransactionToQBRequest(
      accountingTransaction,
      postAccountingTransactionToQBCommand,
    );
  },
);

export const deleteAccountingTransaction = createEffect(
  (accountingTransaction: AccountingTransactionDto) => {
    return deleteAccountingTransactionRequest(accountingTransaction);
  },
);

const defaultState: AccountingTransactionsStoreState = {
  links: [],
  accountingTransactionColumns: [
    {
      name: 'transactionNumber',
      visible: true,
      title: 'Number',
      sortName: 'transactionNumber~ToInt32',
    },
    {
      name: 'applyToContactName',
      visible: true,
      title: 'Apply To Contact',
      sortName: 'ApplyToContact.Name',
    },
    {
      name: 'transactionDate',
      visible: true,
      title: 'Transaction Date',
      sortName: 'transactionDate',
    },
    {
      name: 'accountName',
      visible: true,
      title: 'Account Name',
      sortName: 'Account.AccountName',
    },
    {
      name: 'accountingTransactionStatus',
      visible: true,
      title: 'Status',
      sortName: 'accountingTransactionStatus',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'createdByUserName',
      visible: false,
      title: 'Created by',
      sortName: 'CreatedUser.UserName',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
    {
      name: 'lastModifiedByUserName',
      visible: false,
      title: 'Last Modified by',
      sortName: 'UpdatedUser.UserName',
    },
  ],
};

export const accountingTransactionStore: Store<AccountingTransactionsStoreState> = createStore(
  defaultState,
).on(updateAccountingTransactionsColumns, (state, payload) => {
  return { ...state, accountingTransactionColumns: payload };
});

export const accountingTransactionFilterFields: any = [
  'transactionNumber',
  'applyToContactName',
  'transactionDate',
  'accountName',
  'accountingTransactionStatus',
];
