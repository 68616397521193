import { v4 } from 'uuid';
import {
  OrganizationConfigDto,
  LinkDto,
  LinkResourceBaseDto,
  OrganizationConfigDtoPagedResult,
  CreateOrganizationConfigCommandValues,
  UpdateOrganizationConfigCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { addMessage } from '../common/messages.store';
import { ListParams } from '../common/models/ListParams';

export const GET_ORGANIZATIONCONFIGS_LINK_KEY = 'get-organizationconfigs';
export const CREATE_ORGANIZATIONCONFIG_LINK_KEY = 'create-organizationconfig';
export const GET_ORGANIZATIONCONFIG_LINK_KEY = 'get-organizationconfig';
export const UPDATE_ORGANIZATIONCONFIG_LINK_KEY = 'update-organizationconfig';
export const DELETE_ORGANIZATIONCONFIG_LINK_KEY = 'delete-organizationconfig';

export interface GetOrganizationConfigParams {
  organizationConfigId: number;
}

export const getOrganizationConfigRequest = async (
  resource: LinkResourceBaseDto | null,
  { organizationConfigId }: GetOrganizationConfigParams,
): Promise<OrganizationConfigDto> => {
  if (resource && organizationConfigId) {
    const getOrganizationConfigLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORGANIZATIONCONFIG_LINK_KEY,
    );
    if (getOrganizationConfigLink) {
      const result = await execLink(getOrganizationConfigLink, {
        organizationConfigId,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORGANIZATIONCONFIG_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrganizationConfigsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrganizationConfigDtoPagedResult> => {
  if (resource) {
    const getOrganizationConfigsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORGANIZATIONCONFIGS_LINK_KEY,
    );
    if (getOrganizationConfigsListLink) {
      const result = await execLink(getOrganizationConfigsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORGANIZATIONCONFIGS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createOrganizationConfigRequest = async (
  resource: LinkResourceBaseDto,
  organizationConfig: CreateOrganizationConfigCommandValues,
) => {
  const createOrganizationConfigLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORGANIZATIONCONFIG_LINK_KEY,
  );
  if (createOrganizationConfigLink) {
    const result = await execLink(
      createOrganizationConfigLink,
      organizationConfig,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_ORGANIZATIONCONFIG_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateOrganizationConfigRequest = async (
  resource: LinkResourceBaseDto,
  organizationConfig: UpdateOrganizationConfigCommandValues,
) => {
  const updateOrganizationConfigLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ORGANIZATIONCONFIG_LINK_KEY,
  );
  if (updateOrganizationConfigLink) {
    const result = await execLink(
      updateOrganizationConfigLink,
      organizationConfig,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_ORGANIZATIONCONFIG_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteOrganizationConfigRequest = async (
  organizationConfig: OrganizationConfigDto,
) => {
  const deleteOrganizationConfigLink = organizationConfig.links?.find(
    (x: LinkDto) => x.rel === DELETE_ORGANIZATIONCONFIG_LINK_KEY,
  );
  if (deleteOrganizationConfigLink) {
    const result = await execLink(
      deleteOrganizationConfigLink,
      organizationConfig,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_ORGANIZATIONCONFIG_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
