import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import { StateDto, StateDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  stateStore,
  getStates,
  deleteState,
  updateStatesColumns,
} from '../states.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_STATE_LINK_KEY,
  DELETE_STATE_LINK_KEY,
  GetStateParams,
} from '../states.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { STATE_CREATE_PATH } from '../states.route';
import { ListParams } from '../../common/models/ListParams';
import { StateDialog } from './state.dialog';
import { userHas } from '../../auth/auth.store';

export type StatesListProps = {
  goToDetails?: (stateParams: GetStateParams) => void;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (state: StateDto, stateParams: GetStateParams) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const StatesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  filter = null,
  search = {},
  sort = null,
  onFilter = () => {},
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  onSearch,
  isDropDownList = false,
}: StatesListProps) => {
  const [states, setStates] = useState<StateDtoPagedResult | null>(null);

  useEffect(() => {
    getStatesData({ offset, limit, sort, filter, search });
  }, [offset, limit, sort, filter, search]);

  const [loading, setLoading] = useState(true);

  const getStatesData = (params: ListParams = {}) => {
    getStates(params).then((statesData) => {
      setStates(statesData);
      setLoading(false);
    });
  };
  const { stateColumns: columns } = useStore(stateStore);

  const onDeleteState = (state: StateDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${state.stateCode} State`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteState(state).then(() => {
          onDelete();
          getStatesData({ offset, limit, sort, filter });
        });
      }
    });
  };

  const onEditState = (state: StateDto) => {
    goToDetails({ countryCode: state.countryCode, stateCode: state.stateCode });
  };
  const history = useHistory();
  const onCreateNewState = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + STATE_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onStateSelect = (state, stateId) => {
    if (onSelect) onSelect(state, stateId);
    showDialog({
      dialog: StateDialog,
      props: {
        countryCode: state.countryCode,
        stateCode: state.stateCode,
        title: 'Update State',
      },
    }).then((state) => {
      if (state !== null) {
        getStatesData({ offset, limit, sort, filter, search });
      }
    });
  };
  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          isDropDownList={isDropDownList}
          search={search}
          onSearch={onSearch}
          showEmptyTable={true}
          rowKeys={['stateCode', 'name']}
          showAllStore={true}
          data={states?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={states?.totalCount}
          sort={sort}
          filter={filter}
          onDelete={
            userHas(DELETE_STATE_LINK_KEY, states?.items[0]?.links)
              ? onDeleteState
              : null
          }
          onSort={onSort}
          onEdit={onEditState}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateStatesColumns}
          onSelect={onStateSelect}
          onFilter={onFilter}
          className={'state-list'}
        />
        {(!states || states?.totalCount == 0) && !filter && !search && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">You Don't Have Any State Yet</h3>
            {userHas(CREATE_STATE_LINK_KEY) && (
              <Button size={'lg'} color="primary" onClick={onCreateNewState}>
                Create State
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
};
