import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import { CountryDto, CountryDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  countriesStore,
  getCountries,
  deleteCountry,
  updateColumns,
} from '../countries.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_COUNTRY_LINK_KEY,
  DELETE_COUNTRY_LINK_KEY,
  GetCountryParams,
} from '../countries.service';
import { CountryDialog } from './country.dialog';
import { userHas } from '../../auth/auth.store';

export type CountriesListProps = {
  goToDetails?: (id?: string) => void;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  sort?: string;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onFilter?: (query: string) => void;
  onSelect?: (country: CountryDto, countryParams: GetCountryParams) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};
export const CountriesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  filter = null,
  search = '',
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onFilter = () => {},
  onSelect = () => {},
  onSearch,
  isDropDownList = false,
}: CountriesListProps) => {
  const [countries, setCountries] = useState<CountryDtoPagedResult | null>({
    items: [],
    totalCount: 0,
    limit: 20,
    offset: 0,
    links: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetCountries();
  }, [offset, limit, sort, filter, search]);
  function GetCountries() {
    getCountries({ offset, limit, sort, filter, search }).then(
      (countriesData) => {
        setCountries(countriesData);
        setLoading(false);
      },
      () => {},
    );
  }
  const { countriesColumns: columns } = useStore(countriesStore);

  const onDeleteCountry = (country: CountryDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${country.countryCode} Country`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteCountry(country).then(() => {
          onDelete();
          GetCountries();
        });
      }
    });
  };

  const onEditCountry = (country: CountryDto) => {
    goToDetails(country.countryCode);
  };

  const onCreateNewCountry = () => {
    goToDetails('create');
  };
  const onCountrySelect = (country, countryId) => {
    if (onSelect) onSelect(country, countryId);
    showDialog({
      dialog: CountryDialog,
      props: {
        countryCode: country.countryCode,
        title: 'Update Country',
      },
    }).then((country) => {
      if (country !== null) {
        GetCountries();
      }
    });
  };
  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          isDropDownList={isDropDownList}
          showAllStore={true}
          rowKeys={null}
          data={countries?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={countries?.totalCount}
          sort={sort}
          filter={filter}
          onFilter={onFilter}
          search={search}
          onDelete={
            userHas(DELETE_COUNTRY_LINK_KEY, countries?.items[0]?.links)
              ? onDeleteCountry
              : null
          }
          onSort={onSort}
          showEmptyTable={true}
          onEdit={onEditCountry}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateColumns}
          onSelect={onCountrySelect}
          onSearch={onSearch}
          className={'country-list'}
        />
        {(!countries || countries?.totalCount == 0) && !filter && !search && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">You Don't Have Any Country Yet</h3>
            {userHas(CREATE_COUNTRY_LINK_KEY) && (
              <Button size={'lg'} color="primary" onClick={onCreateNewCountry}>
                Create Country
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
};
