import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { FactoringCompanyScreen } from './screens/factoringCompany.screen';
import { FactoringCompanyListScreen } from './screens/factoringCompany-list.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_FACTORING_COMPANIES } from '../contacts/contact.route';

export const FACTORING_COMPANIES_LIST_PATH = '/factoring-companies';
export const FACTORING_COMPANIES_EDIT_PATH = '/factoring-companies/:contactId';
export const FACTORING_COMPANIES_CREATE_PATH = '/factoring-companies/0';
export const FACTORING_COMPANIES_PATH =
  '/org/:organizationId/factoring-companies';

export const FactoringCompanysRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute
          exact
          path={`${match.path}${FACTORING_COMPANIES_LIST_PATH}`}
        >
          <FactoringCompanyListScreen pageTitle="Factoring Companies" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${FACTORING_COMPANIES_CREATE_PATH}`}
        >
          <FactoringCompanyScreen pageTitle="Add New Factoring Company" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${FACTORING_COMPANIES_EDIT_PATH}`}
        >
          <FactoringCompanyScreen pageTitle="Update Factoring Company" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${CONTACTS_TAB_EDIT_PATH_FOR_FACTORING_COMPANIES}`}
        >
          <ContactScreen pageTitle="Contacts" isTab={true} />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
