import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { InvoiceEdit } from '../components/invoice-edit.component';
import { INVOICES_CREATE_PATH, INVOICES_LIST_PATH } from '../invoices.route';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import { CREATE_ACCOUNTINGTRANSACTION_LINK_KEY } from '../../accountingTransactions/accountingTransactions.service';
import { userHas } from '../../auth/auth.store';

export type InvoicesScreenProps = { pageTitle: string };

export const InvoicesScreen = ({ pageTitle }: InvoicesScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, accountingTransactionId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + INVOICES_CREATE_PATH, {
      organizationId,
    });
  }
  const onAccountingTransactionCreated = () => {
    const path = generatePath(INTERNAL_PATH + INVOICES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'invoice-created',
      type: 'success',
      autoHide: true,
      message: 'Invoice successfully created!',
    };
    history.push(path);
    addMessage(message);
  };
  const onAccountingTransactionUpdated = () => {
    const path = generatePath(INTERNAL_PATH + INVOICES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'invoice-updated',
      type: 'success',
      autoHide: true,
      message: 'Invoice successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + INVOICES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };
  return (
    <InternalLayout
      title={'Invoices'}
      createPath={
        userHas(CREATE_ACCOUNTINGTRANSACTION_LINK_KEY) ? createPath() : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <InvoiceEdit
        organizationId={organizationId}
        organizationConfigName={'QuickBooks'}
        accountingTransactionId={accountingTransactionId}
        onAccountingTransactionUpdated={onAccountingTransactionUpdated}
        onAccountingTransactionCreated={onAccountingTransactionCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
