import React from 'react';
import { AiOutlineDollar } from 'react-icons/ai';
import { ChargeStatusesColors } from '../../../models/data.models';

export type ChargeStatusProps = {
  chargeStatus: string;
  chargeType: string;
};

export const ChargeStatus = ({
  chargeStatus = '',
  chargeType = '',
}: ChargeStatusProps) => {
  return (
    <div className="d-flex align-items-center">
      <AiOutlineDollar
        color={
          ChargeStatusesColors[
            ['Open', 'Posted'].includes(chargeStatus)
              ? `${chargeStatus}${chargeType}`
              : chargeStatus
          ]
        }
        size={'17px'}
        className="mr-2"
      />
      <span style={{ lineHeight: '1' }}>{chargeStatus}</span>
    </div>
  );
};
