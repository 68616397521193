import {
  CountryDto,
  CreateCountryCommandValues,
  LinkDto,
  LinkResourceBaseDto,
  CountryDtoPagedResult,
  UpdateCountryCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_COUNTRIES_LINK_KEY = 'get-countries';
export const CREATE_COUNTRY_LINK_KEY = 'create-country';
export const GET_COUNTRY_LINK_KEY = 'get-country';
export const UPDATE_COUNTRY_LINK_KEY = 'update-country';
export const DELETE_COUNTRY_LINK_KEY = 'delete-country';

export const COUNTRY_ENTITY_NAME = 'Country';

export interface GetCountryParams {
  countryCode: string;
}

export const getCountryRequest = async (
  resource: LinkResourceBaseDto | null,
  { countryCode }: GetCountryParams,
): Promise<CountryDto> => {
  if (resource && countryCode) {
    const getCountryLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_COUNTRY_LINK_KEY,
    );
    if (getCountryLink) {
      const result = await execLink(getCountryLink, { countryCode });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_COUNTRY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getCountriesListRequest = (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CountryDtoPagedResult> => {
  if (resource) {
    const getCountriesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_COUNTRIES_LINK_KEY,
    );
    if (getCountriesListLink) {
      return execLink(getCountriesListLink, params).then((result: any) => {
        return result.data;
      });
    }
  }
  addMessage({
    message: `Action ${GET_COUNTRIES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createCountryRequest = (
  resource: LinkResourceBaseDto,
  country: CreateCountryCommandValues,
) => {
  const createCountryLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_COUNTRY_LINK_KEY,
  );
  if (createCountryLink) {
    return execLink(createCountryLink, country).then((result: any) => {
      return result.data;
    });
  }
  addMessage({
    message: `Action ${CREATE_COUNTRY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateCountryRequest = (
  resource: LinkResourceBaseDto,
  country: UpdateCountryCommandValues,
) => {
  const updateCountryLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_COUNTRY_LINK_KEY,
  );
  if (updateCountryLink) {
    return execLink(updateCountryLink, country).then((result: any) => {
      return result.data;
    });
  }
  addMessage({
    message: `Action ${UPDATE_COUNTRY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteCountryRequest = (country: LinkResourceBaseDto) => {
  const deleteCountryLink = country.links?.find(
    (x: LinkDto) => x.rel === DELETE_COUNTRY_LINK_KEY,
  );
  if (deleteCountryLink) {
    return execLink(deleteCountryLink, country).then((result: any) => {
      return result.data;
    });
  }
  addMessage({
    message: `Action ${DELETE_COUNTRY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
