import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { InvoicesListScreen } from './screens/invoices-list.screen';
import { InvoicesScreen } from './screens/invoices.screen';

export const INVOICES_LIST_PATH = '/invoices';
export const INVOICES_EDIT_PATH = '/invoices/:accountingTransactionId';
export const INVOICES_CREATE_PATH = '/invoices/0';
export const INVOICES_PATH = '/org/:organizationId/invoices';

export const InvoicesRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${INVOICES_LIST_PATH}`}>
          <InvoicesListScreen pageTitle="Invoices" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${INVOICES_CREATE_PATH}`}>
          <InvoicesScreen pageTitle="Add New Invoice" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${INVOICES_EDIT_PATH}`}>
          <InvoicesScreen pageTitle="Update Invoice" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
