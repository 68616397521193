import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PaymentScreen } from './screen/payment.screen';
import { PaymentListScreen } from './screen/payment-list.screen';

export const PAYMENT_LIST_PATH = '/payments';
export const PAYMENT_EDIT_PATH = '/payments/:paymentId';
export const PAYMENT_CREATE_PATH = '/payments/0';
export const PAYMENTS_PATH = '/org/:organizationId/payments';

export const PaymentRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${PAYMENT_LIST_PATH}`}>
          <PaymentListScreen pageTitle="Payments" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${PAYMENT_CREATE_PATH}`}>
          <PaymentScreen pageTitle="Add New Payment" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${PAYMENT_EDIT_PATH}`}>
          <PaymentScreen pageTitle="Update Payment" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
