import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { DivisionForm } from './division.form';
import {
  CountryDto,
  DivisionDto,
  PortDto,
  StateDto,
} from '../../../models/data.models';
import {
  createDivision,
  getDivision,
  updateDivision,
} from '../divisions.store';
import { Panel } from '../../common/components/panel/panel.component';
import { DivisionDefaultValues } from '../../common/DefaultValues';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_DIVISION_LINK_KEY } from '../divisions.service';

export type DivisionEditProps = {
  divisionId?: number | null;
  onDivisionCreated?: (division: DivisionDto) => void;
  onDivisionUpdated?: (division: DivisionDto) => void;
  onDivisionLoaded?: (division: DivisionDto) => void;
  onCancel?: () => void;
};

const initialState: DivisionDto = {
  divisionId: 0,
  assignDivisionToEntities: DivisionDefaultValues.assignDivisionToEntities,
  comments: DivisionDefaultValues.comments,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  divisionName: DivisionDefaultValues.divisionName,
  email: DivisionDefaultValues.email,
  faxNumber: DivisionDefaultValues.faxNumber,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  organizationId: 0,
  phoneNumber: DivisionDefaultValues.phoneNumber,
  useDivisionInDocumentHeaders:
    DivisionDefaultValues.useDivisionInDocumentHeaders,
  streetAndNumber: DivisionDefaultValues.streetAndNumber,
  airAMSOriginatorCode: DivisionDefaultValues.airAMSOriginatorCode,
  city: DivisionDefaultValues.city,
  countryCode: DivisionDefaultValues.countryCode,
  countryName: DivisionDefaultValues.countryName,
  zipCode: DivisionDefaultValues.zipCode,
  stateCode: DivisionDefaultValues.stateCode,
  stateName: DivisionDefaultValues.stateName,
  portId: DivisionDefaultValues.portId,
  portName: DivisionDefaultValues.portName,
  links: [],
};

const divisionSchema = Yup.object().shape({
  divisionName: Yup.string().required("Can't be blank").nullable(true),
});

export const DivisionEdit = ({
  divisionId,
  onDivisionLoaded = () => {},
  onDivisionCreated = () => {},
  onDivisionUpdated = () => {},
  onCancel = () => {},
}: DivisionEditProps) => {
  const isCreateMode = !divisionId || divisionId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [division, setDivision] = useState<DivisionDto | null>(null);
  const [filterCountry, setFilterCountry] = useState<string>(` `);
  const [initialValues, setInitialValues] = useState<DivisionDto | null>(
    initialState,
  );

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (divisionId) {
      getDivision({ divisionId }).then(
        (divisionDto: DivisionDto) => {
          setDivision(divisionDto);
          setInitialValues(divisionDto);
          setFilterCountry(
            divisionDto.countryCode
              ? `countryCode:${divisionDto.countryCode}`
              : 'countryCode:0',
          );
          setIsLoading(false);
          onDivisionLoaded(divisionDto);
        },
        () => {},
      );
    } else {
      throw new Error('Division keys were not provided');
    }
  }, [divisionId]);

  const onSubmit = (data: DivisionDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createDivision(data)
        .then(
          (result) => {
            onDivisionCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateDivision(data)
        .then(
          (result) => {
            onDivisionUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };

  const onChangeDivisionCountryCode = (
    country: CountryDto,
    context: FormikProps<FormikValues>,
  ) => {
    context.setFieldValue(`countryCode`, '');
    context.setFieldValue(`countryName`, '');
    context.setFieldValue(`stateCode`, null);
    context.setFieldValue(`stateName`, null);
    context.setFieldValue(`portId`, null);
    context.setFieldValue(`portName`, null);
    setFilterCountry(
      country?.countryCode
        ? `countryCode:${country.countryCode}`
        : 'countryCode:0',
    );
    setDivision((oldDivision) => {
      if (!oldDivision) {
        oldDivision = initialState;
      }
      oldDivision.countryCode = country?.countryCode;
      oldDivision.countryName = country?.name;
      delete oldDivision.stateCode;
      delete oldDivision.stateName;
      delete oldDivision.portId;
      delete oldDivision.portName;
      return { ...oldDivision };
    });
  };

  const onChangeDivisionStateCode = (
    state: StateDto,
    context: FormikProps<FormikValues>,
  ) => {
    setDivision((oldDivision) => {
      if (!oldDivision) {
        oldDivision = initialState;
      }
      oldDivision.stateCode = state?.stateCode;
      oldDivision.stateName = state?.name;
      return { ...oldDivision };
    });
  };

  const onChangeDivisionPortId = (
    port?: PortDto,
    context?: FormikProps<FormikValues>,
  ) => {
    setDivision((oldDivision) => {
      if (!oldDivision) {
        oldDivision = initialState;
      }

      oldDivision.portId = port?.portId;
      oldDivision.portName = port?.name;
      return { ...oldDivision };
    });
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Tabs forceRenderTabPanel={true}>
      <TabList>
        <Tab name="division">Division</Tab>
        <Tab name="address">Address</Tab>
        <Tab name="usCustomsCodes">US Customs Codes</Tab>
      </TabList>
      <DivisionForm
        id={'division-form'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={divisionSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h3 className="header-form">Add Division</h3>
            ) : (
              <h3 className="header-form">Update Division</h3>
            )}
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.DivisionName />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.Phone />
                  </div>
                  <div className="col-4">
                    <DivisionForm.Fax />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.Email />
                  </div>
                </div>
                <DivisionForm.UseDivisionInDocumentHeaders />
                <div className="row">
                  <div className="col">
                    <DivisionForm.Comments
                      onChange={(e) => {
                        setInitialValues((oldInitialValues) => {
                          oldInitialValues.comments = e.target.value;
                          return { ...oldInitialValues };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-content-end row">
              {(userHas(UPDATE_DIVISION_LINK_KEY, division?.links) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    form={'division-form'}
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    {isCreateMode ? 'Create Division' : 'Save Division'}
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        </TabPanel>

        <TabPanel>
          <Panel className="m-3">
            <h3 className="header-form">Address</h3>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.StreetAndNumber
                      onChange={(e) => {
                        setInitialValues((oldInitialValues) => {
                          oldInitialValues.streetAndNumber = e.target.value;
                          return { ...oldInitialValues };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.State
                      selectedFilter={filterCountry}
                      defaultValue={
                        division &&
                        division.stateCode &&
                        division.stateName &&
                        division.countryCode
                          ? {
                              stateCode: division.stateCode,
                              name: division.stateName,
                              countryCode: division.countryCode,
                            }
                          : ''
                      }
                      onChange={onChangeDivisionStateCode}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.City />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.Country
                      defaultValue={
                        division && division.countryCode && division.countryName
                          ? {
                              countryCode: division.countryCode,
                              name: division.countryName,
                            }
                          : ''
                      }
                      onChange={onChangeDivisionCountryCode}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.ZipCode />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.Port
                      selectedFilter={filterCountry}
                      defaultValue={
                        division && division.portId && division.portName
                          ? {
                              portId: division.portId,
                              name: division.portName,
                              countryCode: division.countryCode,
                            }
                          : ''
                      }
                      onChange={onChangeDivisionPortId}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-content-end row">
              {(userHas(UPDATE_DIVISION_LINK_KEY, division?.links) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    form={'division-form'}
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    {isCreateMode ? 'Create Division' : 'Save Division'}
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        </TabPanel>
        <TabPanel>
          <Panel className="m-3">
            <h3 className="header-form">US Customs Codes</h3>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <DivisionForm.AirAMSOriginatorCode />
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-content-end row">
              {(userHas(UPDATE_DIVISION_LINK_KEY, division?.links) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    form={'division-form'}
                    type="submit"
                    color="primary"
                    className="btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    {isCreateMode ? 'Create Division' : 'Save Division'}
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </DivisionForm>
    </Tabs>
  );
};
