import {
  AddressType,
  ContactAddressDto,
  ContactDto,
  ContactType,
  OrderPickupDto,
} from '../../../../models/data.models';
import { OrderForm } from '../order.form';
import React, { useEffect } from 'react';
import { FormContext } from '../../../common/components/form/form.component';
import { formatAddress } from '../../../../utils/helper.utils';
import { getContactAddress } from '../../../contactAddresses/contactAddresses.store';

export type ShipperFormProps = {
  orderPickupDto: OrderPickupDto | null;
  index: number;
  allowDelete: boolean;
  onShipperCreated?: (orderPickupDto: OrderPickupDto) => void;
  onShipperUpdated?: (orderPickupDto: OrderPickupDto) => void;
  onShipperLoaded?: (orderPickupDto: OrderPickupDto) => void;
  onShipperDeleted?: () => void;
  onShipperDateChange: (date?: any) => void;
  onShipperNoteChange: (note?: string) => void;
  setShipperContact: (
    contactId: number,
    name: string,
    contactType: ContactType,
  ) => void;
  setShipperFilter: (filter: string) => void;
  setShipperContactAddress: (
    contactAddressId: number,
    addressLine: string,
  ) => void;
  shipperFilter: string;
  shipperContact: { contactId: number; name: string; contactType: ContactType };
  shipperContactAddress: {
    contactAddressId: string;
    addressLine: string;
    city?: string;
    stateCode?: string;
    postalCode?: string;
    countryCode?: string;
  };
  shipperDateShowTimeOption?: boolean;
  addressType?: AddressType;
};
export const ShipperForm = ({
  orderPickupDto,
  index,
  onShipperDateChange,
  setShipperContact,
  setShipperFilter,
  setShipperContactAddress,
  shipperFilter,
  shipperContact,
  shipperContactAddress,
  allowDelete = false,
  onShipperDeleted,
  onShipperNoteChange,
  shipperDateShowTimeOption = false,
  addressType = AddressType.Other,
}: ShipperFormProps) => {
  useEffect(() => {
    if (shipperContactAddress.addressLine != null) {
      getContactAddress({
        contactAddressId: Number(shipperContactAddress.contactAddressId),
      }).then((data) => {
        setShipperContactAddress(data?.contactAddressId, formatAddress(data));
      });
    }
  }, []);

  const isDisabledShipperContact = (): boolean => {
    if (
      shipperFilter == null ||
      shipperFilter === ' ' ||
      shipperContact?.contactId == null
    ) {
      return true;
    }
    return false;
  };
  return (
    <div className="row">
      <div className="col-3">
        <OrderForm.ShipperCompany
          id={`orderPickups[${index}].shipperContactId`}
          header={`Shipper ${index + 1}`}
          contactTypes={[
            ContactType.Customer,
            ContactType.Vendor,
            ContactType.ForwardingAgent,
            ContactType.Contact,
          ]}
          selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact}`}
          required={false}
          defaultValue={
            shipperContact &&
            shipperContact.contactId != null &&
            shipperContact.name != null &&
            shipperContact.contactType != null
              ? {
                  contactId: shipperContact.contactId,
                  name: shipperContact.name,
                  contactType: shipperContact.contactType,
                }
              : ''
          }
          onChange={(data?: ContactDto, context?: any) => {
            setShipperContact(data?.contactId, data?.name, data?.contactType);
            setShipperFilter(
              data?.contactId ? `contactId:${data?.contactId}` : ' ',
            );
          }}
          nameId={`orderPickups[${index}].shipperContactName`}
        />
      </div>
      <div className="col-3">
        <OrderForm.ShipperLocation
          selectedFilter={shipperFilter}
          id={`orderPickups[${index}].shipperAddressId`}
          header={'Location'}
          required={false}
          disabled={isDisabledShipperContact()}
          defaultValue={
            shipperContactAddress &&
            shipperContactAddress.contactAddressId != null &&
            shipperContactAddress.addressLine != null
              ? {
                  ...shipperContactAddress,
                  contactAddressId: shipperContactAddress.contactAddressId,
                }
              : ''
          }
          onChange={(data?: ContactAddressDto) => {
            setShipperContactAddress(
              data?.contactAddressId,
              formatAddress(data),
            );
          }}
          nameId={`orderPickups[${index}].shipperAddressName`}
          contactId={shipperContact.contactId}
          addressType={addressType}
        />
      </div>
      <div className="col-3">
        <OrderForm.ShipperDate
          defaultValue={orderPickupDto?.pickDate}
          onChange={onShipperDateChange}
          name={`orderPickups[${index}].pickDate`}
          disabled={isDisabledShipperContact()}
          showTimeOption={shipperDateShowTimeOption}
        />
      </div>
      {allowDelete ? (
        <div className="col-3 d-flex">
          <div className={'w-100 mw-100 pr-3'}>
            <FormContext.Consumer>
              {(context) => (
                <OrderForm.ShipperNotes
                  name={`orderPickups[${index}].shippingNotes`}
                  type={'text'}
                  disabled={isDisabledShipperContact()}
                />
              )}
            </FormContext.Consumer>
          </div>
          <div className={'d-flex align-items-center pt-3'}>
            <div
              data-cy={`delete-shipper[${index}]`}
              onClick={(event) => {
                onShipperDeleted();
              }}
              color="primary"
              className="btn-close icon-close"
            />
          </div>
        </div>
      ) : (
        <div className="col-3 d-flex">
          <div className={'w-100'}>
            <FormContext.Consumer>
              {(context) => (
                <OrderForm.ShipperNotes
                  name={`orderPickups[${index}].shippingNotes`}
                  type={'text'}
                  disabled={isDisabledShipperContact()}
                />
              )}
            </FormContext.Consumer>
          </div>
        </div>
      )}
    </div>
  );
};
