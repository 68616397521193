import {
  PackageCategoryDto,
  LinkDto,
  LinkResourceBaseDto,
  PackageCategoryDtoPagedResult,
  CreatePackageCategoryCommandValues,
  UpdatePackageCategoryCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_PACKAGECATEGORIES_LINK_KEY = 'get-packagecategories';
export const CREATE_PACKAGECATEGORY_LINK_KEY = 'create-packagecategory';
export const GET_PACKAGECATEGORY_LINK_KEY = 'get-packagecategory';
export const UPDATE_PACKAGECATEGORY_LINK_KEY = 'update-packagecategory';
export const DELETE_PACKAGECATEGORY_LINK_KEY = 'delete-packagecategory';

export const PACKAGE_CATEGORY_ENTITY_NAME = 'Package Category';

export interface GetPackageCategoryParams {
  packageCategoryCode: string;
}

export const getPackageCategoryRequest = async (
  resource: LinkResourceBaseDto | null,
  { packageCategoryCode }: GetPackageCategoryParams,
): Promise<PackageCategoryDto> => {
  if (resource && packageCategoryCode) {
    const getPackageCategoryLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PACKAGECATEGORY_LINK_KEY,
    );
    if (getPackageCategoryLink) {
      const result = await execLink(getPackageCategoryLink, {
        packageCategoryCode,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PACKAGECATEGORY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getPackageCategoriesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PackageCategoryDtoPagedResult> => {
  if (resource) {
    const getPackageCategoriesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PACKAGECATEGORIES_LINK_KEY,
    );
    if (getPackageCategoriesListLink) {
      const result = await execLink(getPackageCategoriesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PACKAGECATEGORIES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createPackageCategoryRequest = async (
  resource: LinkResourceBaseDto,
  packageCategory: CreatePackageCategoryCommandValues,
) => {
  const createPackageCategoryLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PACKAGECATEGORY_LINK_KEY,
  );
  if (createPackageCategoryLink) {
    const result = await execLink(createPackageCategoryLink, packageCategory);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_PACKAGECATEGORY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updatePackageCategoryRequest = async (
  resource: LinkResourceBaseDto,
  packageCategory: UpdatePackageCategoryCommandValues,
) => {
  const updatePackageCategoryLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PACKAGECATEGORY_LINK_KEY,
  );
  if (updatePackageCategoryLink) {
    const result = await execLink(updatePackageCategoryLink, packageCategory);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_PACKAGECATEGORY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deletePackageCategoryRequest = async (
  packageCategory: PackageCategoryDto,
) => {
  const deletePackageCategoryLink = packageCategory.links?.find(
    (x: LinkDto) => x.rel === DELETE_PACKAGECATEGORY_LINK_KEY,
  );
  if (deletePackageCategoryLink) {
    const result = await execLink(deletePackageCategoryLink, packageCategory);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_PACKAGECATEGORY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
