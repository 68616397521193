import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { AccountingItemForm } from './accountingItem.form';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  AccountingAccountDto,
  AccountingItemDto,
  ApplyBy,
  ItemType,
  SalesTaxDto,
} from '../../../models/data.models';
import {
  createAccountingItem,
  getAccountingItem,
  updateAccountingItem,
} from '../accountingItems.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ReactSelectItem } from '../../../models/custom.models';
import {
  getEnumValues,
  validateNumberInput,
} from '../../../utils/helper.utils';
import { AccountingItemDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { userHas } from '../../auth/auth.store';
import { UPDATE_ACCOUNTINGITEM_LINK_KEY } from '../accountingItems.service';
import { TariffEdit } from '../../tariffs/components/tariff-edit.component';

export type AccountingItemEditProps = {
  accountingItemId?: number | null;
  onAccountingItemCreated?: (accountingItem: AccountingItemDto) => void;
  onAccountingItemUpdated?: (accountingItem: AccountingItemDto) => void;
  onAccountingItemLoaded?: (accountingItem: AccountingItemDto) => void;
  onCancel?: () => void;
};

const initialState: AccountingItemDto = {
  accountingItemId: 0,
  organizationId: null,
  accountId: AccountingItemDefaultValues.accountId,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: AccountingItemDefaultValues.description,
  isInactive: AccountingItemDefaultValues.isInactive,
  itemCode: AccountingItemDefaultValues.itemCode,
  itemType: AccountingItemDefaultValues.itemType,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  price: AccountingItemDefaultValues.price,
  salesTaxId: AccountingItemDefaultValues.salesTaxId,
  tariff: AccountingItemDefaultValues.tariff,
  accountName: AccountingItemDefaultValues.accountName,
  salesTaxCode: AccountingItemDefaultValues.salesTaxCode,
  links: [],
};

const accountingItemsSchema = Yup.object().shape({
  itemType: Yup.string().required("Can't be blank").nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
  accountId: Yup.string().required("Can't be blank").nullable(true),
  price: Yup.string()
    .notRequired()
    .transform((value) => (value === null ? '' : value))
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === '' ||
        value === undefined
      );
    })
    .test('length', 'Max value is 999999', (value) => {
      return value === undefined || Number(value) <= 999999;
    })
    .nullable(true),
});

export const AccountingItemEdit = ({
  accountingItemId,
  onAccountingItemLoaded = () => {},
  onAccountingItemCreated = () => {},
  onAccountingItemUpdated = () => {},
  onCancel = () => {},
}: AccountingItemEditProps) => {
  const isCreateMode = !accountingItemId || accountingItemId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    accountingItem,
    setAccountingItem,
  ] = useState<AccountingItemDto | null>(null);
  const [initialValues, setInitialValues] = useState<AccountingItemDto | null>(
    initialState,
  );
  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (accountingItemId) {
      getAccountingItem({ accountingItemId }).then(
        (accountingItemDto: AccountingItemDto) => {
          setAccountingItem(accountingItemDto);
          setInitialValues(accountingItemDto);
          setIsLoading(false);
          onAccountingItemLoaded(accountingItemDto);
        },
        () => {},
      );
    } else {
      throw new Error('AccountingItem keys were not provided');
    }
  }, [accountingItemId]);

  const onSubmit = (data: AccountingItemDto) => {
    if (data?.tariff?.rateData && data?.tariff?.applyBy === ApplyBy.Container) {
      const filteredRateData = data.tariff.rateData.filter(
        (rate) => rate.rateIndex !== 0 && rate.rateIndex !== undefined,
      );
      data = {
        ...data,
        tariff: { ...data.tariff, rateData: filteredRateData },
      };
    }
    setIsSending(true);
    if (isCreateMode) {
      createAccountingItem(data)
        .then(
          (result) => {
            onAccountingItemCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateAccountingItem(data)
        .then(
          (result) => {
            onAccountingItemUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  const onItemTypeChange = (itemType: ReactSelectItem) => {
    setAccountingItem((accountingItem) => {
      if (!accountingItem) {
        accountingItem = initialState;
      }
      accountingItem.itemType = ItemType[itemType?.label];
      return { ...accountingItem };
    });
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Tabs forceRenderTabPanel={true}>
      <TabList>
        <Tab>Definition</Tab>
        <Tab>Automatic Creation</Tab>
        <Tab>Notes</Tab>
      </TabList>
      <TabPanel>
        <Panel className="m-3">
          <AccountingItemForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={ref}
            id={'accounting-item-form'}
            validationSchema={accountingItemsSchema}
          >
            <div className="row">
              <div className="col-4">
                <AccountingItemForm.ItemType
                  placeholder={'Select Item Type'}
                  id={'itemType'}
                  header={'Item Type'}
                  name={'itemType'}
                  disabled={false}
                  required={true}
                  defaultValue={
                    accountingItem?.itemType
                      ? {
                          label: ItemType[accountingItem?.itemType],
                          value: accountingItem?.itemType,
                        }
                      : null
                  }
                  onChange={onItemTypeChange}
                  multiple={false}
                  options={getEnumValues(ItemType)}
                />
              </div>
              <div className="col-4">
                <AccountingItemForm.SalesTaxId
                  id={'salesTaxId'}
                  header={'Tax Code'}
                  selectedFilter={` `}
                  required={false}
                  defaultValue={
                    accountingItem &&
                    accountingItem.salesTaxId != null &&
                    accountingItem.salesTaxCode != null
                      ? {
                          salesTaxId: accountingItem.salesTaxId,
                          taxCode: accountingItem.salesTaxCode,
                        }
                      : ''
                  }
                  onChange={(data?: SalesTaxDto) => {
                    setAccountingItem((accountingItem) => {
                      if (!accountingItem) {
                        accountingItem = initialState;
                      }
                      accountingItem.salesTaxId = data?.salesTaxId;
                      accountingItem.salesTaxCode = data?.taxCode;
                      return { ...accountingItem };
                    });
                  }}
                  nameId={'salesTaxCode'}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <AccountingItemForm.Description />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <AccountingItemForm.ItemCode />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <AccountingItemForm.AccountId
                  id={'accountId'}
                  header={'Account'}
                  placeholder={'Select Account'}
                  required={true}
                  defaultValue={
                    accountingItem &&
                    accountingItem.accountId != null &&
                    accountingItem.accountName != null
                      ? {
                          accountId: accountingItem.accountId,
                          accountName: accountingItem.accountName,
                          accountType: accountingItem.accountType,
                        }
                      : ''
                  }
                  onChange={(data?: AccountingAccountDto) => {
                    setAccountingItem((accountingItem) => {
                      if (!accountingItem) {
                        accountingItem = initialState;
                      }
                      accountingItem.accountId = data?.accountId;
                      accountingItem.accountName = data?.accountName;
                      accountingItem.accountType = data?.accountType;
                      return { ...accountingItem };
                    });
                  }}
                  nameId={'accountName'}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <AccountingItemForm.Price
                  required={false}
                  onKeyDown={validateNumberInput}
                />
              </div>
            </div>

            <TariffEdit />

            <div className="row">
              <div className="col-4">
                <AccountingItemForm.IsInactive />
              </div>
            </div>
          </AccountingItemForm>
          <div className="justify-content-end row">
            {(userHas(UPDATE_ACCOUNTINGITEM_LINK_KEY, accountingItem?.links) ||
              isCreateMode) && (
              <div className="col-3">
                <Button
                  form={'accounting-item-form'}
                  name="save-accountingItem"
                  type="submit"
                  color="primary"
                  className="btn-block btn-md"
                  disabled={isSending}
                  isSending={isSending}
                >
                  Save Item
                </Button>
              </div>
            )}
            <div className="col-3">
              <Button
                type="button"
                color="primary"
                onClick={onCancel}
                className="w-100 btn-secondary"
                disabled={isSending}
              >
                Close
              </Button>
            </div>
          </div>
        </Panel>
      </TabPanel>
      <TabPanel />
      <TabPanel />
    </Tabs>
  );
};
