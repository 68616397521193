import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { ContactAddressForm } from './contactAddress.form';
import {
  AddressInformationType,
  AddressType,
  ContactAddressDto,
} from '../../../models/data.models';
import {
  createContactAddress,
  getContactAddress,
  updateContactAddress,
} from '../contactAddresses.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ContactAddressDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { FormikProps, FormikValues } from 'formik';
import {
  getEnumValues,
  parseGeocodeResults,
} from '../../../utils/helper.utils';

export type ContactAddressEditProps = {
  contactAddressId?: number;
  contactId?: number;
  addressType?: AddressType;
  UseParameterAddressTypeInSubmitForm?: boolean;
  UseCurrentContact?: boolean;
  validateOnChange?: boolean;
  onContactAddressCreated?: (contactAddress: ContactAddressDto) => void;
  onContactAddressUpdated?: (contactAddress: ContactAddressDto) => void;
  onContactAddressLoaded?: (contactAddress: ContactAddressDto) => void;
  onCancel?: () => void;
};

const initialState: ContactAddressDto = {
  addressType: ContactAddressDefaultValues.addressType,
  contactId: ContactAddressDefaultValues.contactId,
  stateName: ContactAddressDefaultValues.stateName,
  stateCode: ContactAddressDefaultValues.stateCode,
  links: null,
  countryName: ContactAddressDefaultValues.countryName,
  countryCode: ContactAddressDefaultValues.countryCode,
  createdBy: null,
  lastModified: null,
  created: null,
  lastModifiedBy: null,
  addressLine: ContactAddressDefaultValues.addressLine,
  organizationId: null,
  contactAddressId: null,
  addressLine2: ContactAddressDefaultValues.addressLine2,
  city: ContactAddressDefaultValues.city,
  postalCode: ContactAddressDefaultValues.postalCode,
  latitude: ContactAddressDefaultValues.latitude,
  longitude: ContactAddressDefaultValues.longitude,
  addressInformationType: ContactAddressDefaultValues.addressInformationType,
};

const contactAddressFullSchema = Yup.object().shape({
  addressLine: Yup.string().required("Can't be blank").nullable(true),
  countryCode: Yup.string().required("Can't be blank").nullable(true),
  stateCode: Yup.string().when('countryCode', {
    is: (countryCode) => countryCode == 'US' || countryCode == 'CA',
    then: Yup.string().required("Can't be blank").nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
});

const contactAddressOnlyPostalCodeSchema = Yup.object().shape({
  postalCode: Yup.string().required("Can't be blank").nullable(true),
  countryCode: Yup.string().required("Can't be blank").nullable(true),
});

export const ContactAddressEdit = ({
  contactAddressId,
  contactId,
  addressType,
  UseCurrentContact,
  onContactAddressLoaded = () => {},
  onContactAddressCreated = () => {},
  onContactAddressUpdated = () => {},
  onCancel = () => {},
}: ContactAddressEditProps) => {
  const isCreateMode = !contactAddressId || contactAddressId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [
    contactAddress,
    setContactAddress,
  ] = useState<ContactAddressDto | null>(null);
  const [initialValues, setInitialValues] = useState<ContactAddressDto | null>(
    initialState,
  );

  const [addressInformationType, setAddressInformationType] = useState(
    initialValues.addressInformationType,
  );

  useEffect(() => {
    if (isCreateMode) {
      setContactAddress((contactAddress) => {
        if (!contactAddress) {
          contactAddress = initialState;
        }
        contactAddress.contactId = contactId;
        contactAddress.addressType = addressType;
        return { ...contactAddress };
      });
      setIsLoading(false);
    } else if (contactAddressId) {
      getContactAddress({ contactAddressId }).then(
        (contactAddressDto: ContactAddressDto) => {
          setContactAddress(contactAddressDto);
          setInitialValues(contactAddressDto);
          setIsLoading(false);
          onContactAddressLoaded(contactAddressDto);
        },
        () => {},
      );
    } else {
      throw new Error('ContactAddress keys were not provided');
    }
  }, [contactAddressId]);

  useEffect(() => {
    if (isCreateMode && addressType === AddressType.Quote) {
      setAddressInformationType(AddressInformationType.OnlyPostalCode);
    }
  }, []);

  const onSubmit = (data: ContactAddressDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createContactAddress(data)
        .then(
          (result) => {
            onContactAddressCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateContactAddress(data)
        .then(
          (result) => {
            onContactAddressUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const onSelectPlaceAutocompleteContactAddress = (
    results: any,
    context: FormikProps<FormikValues>,
  ) => {
    const addressInfo = parseGeocodeResults(results);
    context.setFieldValue('addressLine', addressInfo.addressLine);
    context.setFieldValue('city', addressInfo.city);
    context.setFieldValue('postalCode', addressInfo.postalCode);
    context.setFieldValue('countryCode', addressInfo.countryCode);
    context.setFieldValue('stateCode', addressInfo.stateCode);
    context.setFieldValue('latitude', addressInfo.latitude);
    context.setFieldValue('longitude', addressInfo.longitude);
    setContactAddress((oldContactAddress) => {
      if (!oldContactAddress) {
        oldContactAddress = initialValues;
      }
      oldContactAddress.addressLine = addressInfo.addressLine;
      oldContactAddress.city = addressInfo.city;
      oldContactAddress.postalCode = addressInfo.postalCode;
      oldContactAddress.countryCode = addressInfo.countryCode;
      oldContactAddress.countryName = addressInfo.countryName;
      oldContactAddress.stateCode = addressInfo.stateCode;
      oldContactAddress.stateName = addressInfo.stateName;
      oldContactAddress.latitude = addressInfo.latitude;
      oldContactAddress.longitude = addressInfo.longitude;
      return { ...oldContactAddress };
    });
    return addressInfo.addressLine;
  };

  const contactAddressSchema =
    addressInformationType === AddressInformationType.Full
      ? contactAddressFullSchema
      : contactAddressOnlyPostalCodeSchema;

  return (
    <Panel className="m-3">
      <div className="row">
        <div className="col-4">
          <ContactAddressForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            id={'ContactAddressForm'}
            validationSchema={contactAddressSchema}
          >
            {addressType === AddressType.Quote && (
              <ContactAddressForm.AddressInformationType
                defaultValue={{
                  value: addressInformationType,
                  label: addressInformationType,
                }}
                options={getEnumValues(AddressInformationType)}
                onChange={(data) => {
                  setAddressInformationType(AddressInformationType[data.value]);
                }}
              />
            )}
            {addressInformationType === AddressInformationType.Full && (
              <>
                <ContactAddressForm.AddressLine
                  defaultValue={contactAddress?.addressLine}
                  onSelect={onSelectPlaceAutocompleteContactAddress}
                  lat={contactAddress?.latitude}
                  lng={contactAddress?.longitude}
                />
                <ContactAddressForm.AddressLine2 />
                <ContactAddressForm.StateCode
                  id={'stateCode'}
                  header={'State'}
                  required={true}
                  defaultValue={
                    contactAddress &&
                    contactAddress.stateCode &&
                    contactAddress.stateName
                      ? {
                          stateCode: contactAddress.stateCode,
                          name: contactAddress.stateName,
                          countryCode: contactAddress.countryCode,
                        }
                      : ''
                  }
                  onChange={(state) => {
                    setContactAddress((contactAddress) => {
                      if (!contactAddress) {
                        contactAddress = initialState;
                      }
                      contactAddress.stateCode = state?.stateCode;
                      contactAddress.stateName = state?.name;
                      return { ...contactAddress };
                    });
                  }}
                  nameId={`stateName`}
                  selectedFilter={`countryCode: ${contactAddress.countryCode}`}
                  disabled={!contactAddress.countryCode}
                />
              </>
            )}
            <ContactAddressForm.City />
            <ContactAddressForm.CountryCode
              id={'countryCode'}
              header={'Country'}
              required={true}
              defaultValue={
                contactAddress &&
                contactAddress.countryCode &&
                contactAddress.countryName
                  ? {
                      countryCode: contactAddress.countryCode,
                      name: contactAddress.countryName,
                    }
                  : ''
              }
              onChange={(country) => {
                setContactAddress((contactAddress) => {
                  if (!contactAddress) {
                    contactAddress = initialState;
                  }
                  contactAddress.countryCode = country?.countryCode;
                  contactAddress.countryName = country?.name;
                  contactAddress.stateCode = null;
                  contactAddress.stateName = null;
                  return { ...contactAddress };
                });
              }}
              nameId={`countryName`}
            />
            <ContactAddressForm.PostalCode />
            {addressInformationType === AddressInformationType.Full && (
              <>
                <ContactAddressForm.Latitude
                  onChange={(value) =>
                    setContactAddress((oldContactAddress) => {
                      if (!oldContactAddress) {
                        oldContactAddress = initialState;
                      }
                      oldContactAddress.latitude = value;
                      return { ...oldContactAddress };
                    })
                  }
                />
                <ContactAddressForm.Longitude
                  onChange={(value) =>
                    setContactAddress((oldContactAddress) => {
                      if (!oldContactAddress) {
                        oldContactAddress = initialState;
                      }
                      oldContactAddress.longitude = value;
                      return { ...oldContactAddress };
                    })
                  }
                />
              </>
            )}
            {UseCurrentContact ? (
              <div></div>
            ) : (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <Button
                  type="submit"
                  color="primary"
                  className="btn-block mr-3"
                  disabled={isSending}
                  isSending={isSending}
                >
                  Save Contact Address
                </Button>
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="btn-light btn-cancel"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            )}
          </ContactAddressForm>
        </div>
      </div>
    </Panel>
  );
};
