import React, { ChangeEvent, useEffect } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';
import { SelectContactPaginate } from '../../contacts/components/contact-react-select.component';
import { SelectDivisionPaginate } from '../../divisions/components/division-react-select.component';
import { SelectContactAddressesPaginate } from '../../contactAddresses/components/contactAddresses-react-select.component';
import { SelectEquipmentTypePaginate } from '../../equipmentTypes/components/equipmentType-react-select.component';
import { CustomValuesInput } from '../../common/components/input/customFields-input.component';
import { ContactAddressSelectInputProps } from '../../../models/custom.models';
import { ReactEnumInputSelect } from '../../common/components/input/enum-input.select';
import { SelectModeOfTransportationPaginate } from '../../modeOfTransportations/components/modeOfTransportation-react-select.component';
import { SelectPaymentTermPaginate } from '../../paymentTerms/components/paymentTerm-react-select.component';
import { ReactDatepickerComponent } from '../../common/components/react-datepicker/react-datepicker.component';

export const OrderForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

OrderForm.BillToContactSelect = ({
  selectedFilter = ``,
  nameId = 'billToContactName',
  placeholder = 'Select Bill To Customer',
  required = true,
  defaultValue,
  sort = 'name',
  id = 'billToContactId',
  header = 'Customer',
  onChange = () => {},
  readonly,
  contactTypes = [],
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.CarrierContactSelect = ({
  selectedFilter = '',
  placeholder = 'Select Carrier',
  sort = 'name',
  searchKey = 'name',
  defaultValue,
  id = 'carrierContactId',
  nameId = 'carrierContactName',
  header = 'Carrier / Driver',
  required = false,
  onChange = () => {},
  readonly,
  contactTypes,
  multiple = true,
  closeMenuOnSelect = false,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      showContactType={true}
      useContext={true}
      isMulti={multiple}
      header={header}
      placeholder={placeholder}
      selectedSort={sort}
      selectedSearchKey={searchKey}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
};

OrderForm.PaymentTermsSelect = ({
  className,
  style,
  size,
  selectedFilter,
  onChange,
  id = 'paymentTermId',
  nameId = 'paymentTermDescription',
  header = 'Payment Terms',
  required = false,
  readonly,
  disabled,
  sort = 'description',
  placeholder = 'Select Payment Term',
  defaultValue,
}: InputPublicProps) => {
  return (
    <SelectPaymentTermPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangePaymentTerm={onChange}
    />
  );
};

OrderForm.ExpDate = ({
  required = false,
  defaultValue,
  header = 'Exp Date',
  nameId = 'expDate',
  placeholder = 'Select Exp Date',
  className,
  style,
  readonly,
  disabled,
  size,
  onChange,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      id={nameId}
      label={header}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      disabled={disabled}
      readonly={readonly}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

OrderForm.Division = ({
  selectedFilter = ``,
  defaultValueFilter = ``,
  placeholder = 'Select Division',
  required = true,
  defaultValue,
  sort = 'divisionName',
  id = 'divisionId',
  header = 'Division',
  onChange = () => {},
  readonly,
  nameId = 'divisionName',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectDivisionPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      isClearable={false}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      defaultValueFilter={defaultValueFilter}
      onChangeDivision={onChange}
      isDisabled={disabled}
    />
  );
};

OrderForm.EquipmentType = ({
  selectedFilter = ``,
  placeholder = 'Select EquipmentType',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'equipmentType',
  header = 'Equipment Type',
  onChange = () => {},
  readonly,
  nameId = 'name',
}: InputPublicProps) => {
  return (
    <SelectEquipmentTypePaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeEquipmentType={onChange}
    />
  );
};

OrderForm.EmployeeContactSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Dispatcher',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'employeeContactId',
  nameId = 'employeeContactName',
  header = 'Dispatchers',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.ModeOfTransportation = ({
  selectedFilter = ``,
  placeholder = 'Select Mode of Transportation',
  required = false,
  defaultValue,
  sort = 'description',
  id = 'modeOfTransportationId',
  header = 'Mode of Transportation',
  onChange = () => {},
  nameId = 'modeOfTransportationDescription',
  disabled,
}: InputPublicProps) => {
  return (
    <SelectModeOfTransportationPaginate
      id={id}
      nameId={nameId}
      required={required}
      isMulti={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeModeOfTransportation={onChange}
      isDisabled={disabled}
    />
  );
};

OrderForm.OrderNumber = ({
  className,
  style,
  size,
  placeholder = 'Order Number',
  label = 'Order Number',
}: InputPublicProps) => {
  return (
    <Input
      name={'orderNumber'}
      label={label}
      placeholder={placeholder}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={true}
    />
  );
};

OrderForm.TrackingNumber = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'trackingNumber'}
      label={'Tracking Number'}
      placeholder={'Tracking Number'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrderForm.SalespersonContactSelect = ({
  selectedFilter = ``,
  placeholder = 'Select Sales Person',
  required = false,
  defaultValue,
  sort = 'name',
  id = 'salespersonContactId',
  nameId = 'salespersonContactName',
  header = 'Sales Persons',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={false}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.ShipperCompany = ({
  selectedFilter = ``,
  placeholder = 'Select Shipper',
  required = true,
  defaultValue,
  sort = 'name',
  nameId = 'orderPickups[0].shipperContactName',
  id = 'orderPickups[0].shipperContactId',
  header = 'Shipper',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.ShipperLocation = ({
  selectedFilter = ``,
  placeholder = 'Select Location',
  required = true,
  defaultValue,
  sort = 'addressLine',
  nameId = 'orderPickups[0].shipperAddressName',
  id = 'orderPickups[0].shipperAddressId',
  header = 'Location',
  onChange = () => {},
  disabled = false,
  readonly,
  contactId,
  addressType,
}: ContactAddressSelectInputProps) => {
  return (
    <SelectContactAddressesPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      isDisabled={disabled}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContactAddress={onChange}
      contactId={contactId}
      contactAddressType={addressType}
    />
  );
};

OrderForm.ShipperDate = ({
  className,
  style,
  size,
  defaultValue,
  onChange,
  readonly,
  name = 'orderPickups[0].pickDate',
  disabled,
  showTimeOption,
}: InputPublicProps) => {
  return (
    <ReactDatepickerComponent
      readonly={readonly}
      id={name}
      label={'Pickup Date'}
      placeholder={'Pickup Date'}
      required={false}
      className={className}
      style={style}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      showTimeOption={showTimeOption}
      timeIntervals={15}
      timeCaption={'Time'}
      timeFormat={'h:mm aa'}
      dateFormat={'MM/dd/yyyy'}
    />
  );
};
OrderForm.ShipperBroker = ({
  className,
  style,
  size,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={'shipperBroker'}
      label={'Customs Broker'}
      placeholder={'Customs Broker'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
OrderForm.ShipperNotes = ({
  className,
  style,
  size,
  readonly,
  name = 'orderPickups[0].shippingNotes',
  onChange,
  type = 'text',
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={'Shipping Notes'}
      placeholder={'Shipping Notes'}
      required={false}
      type={type}
      className={className}
      onChange={onChange}
      style={style}
      size={size}
      disabled={disabled}
    />
  );
};

OrderForm.ConsigneeCompany = ({
  selectedFilter = ``,
  placeholder = 'Select Consignee',
  required = true,
  defaultValue,
  sort = 'name',
  nameId = 'orderDeliveries[0].consigneeContactName',
  id = 'orderDeliveries[0].consigneeContactId',
  header = 'Consignee',
  onChange = () => {},
  readonly,
  contactTypes,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      contactTypes={contactTypes}
    />
  );
};

OrderForm.ConsigneeLocation = ({
  selectedFilter = ``,
  placeholder = 'Select Location',
  required = true,
  defaultValue,
  sort = 'addressLine',
  nameId = 'orderDeliveries[0].consigneeAddressName',
  id = 'orderDeliveries[0].consigneeAddressId',
  header = 'Location',
  onChange = () => {},
  disabled = false,
  readonly,
  contactId,
  addressType,
}: ContactAddressSelectInputProps) => {
  return (
    <SelectContactAddressesPaginate
      nameId={nameId}
      readonly={readonly === 'readonly'}
      id={id}
      required={required}
      isMulti={false}
      useContext={true}
      isDisabled={disabled}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      selectedSort={sort}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContactAddress={onChange}
      contactId={contactId}
      contactAddressType={addressType}
    />
  );
};

OrderForm.ConsigneeDate = ({
  className,
  style,
  size,
  defaultValue,
  onChange,
  readonly,
  name = 'orderDeliveries[0].deliveryDate',
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={'Delivery Date'}
      placeholder={'Delivery Date'}
      required={false}
      type={'date'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
};
OrderForm.ConsigneeNotes = ({
  className,
  style,
  size,
  readonly,
  name = 'orderDeliveries[0].deliveryNotes',
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      name={name}
      label={'Delivery Notes'}
      placeholder={'Delivery Notes'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
    />
  );
};
OrderForm.OrderStatus = ({
  onChange,
  options,
  name = 'orderStatus',
  id = 'orderStatus',
  placeholder = 'Select Order Status',
  required = true,
  multiple = false,
  disabled = false,
  header,
  defaultValue = '',
  readonly,
}: InputPublicProps) => {
  return (
    <ReactEnumInputSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={false}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      tabIndex={5}
      useContext={true}
    />
  );
};
OrderForm.TotalPcsCrt = ({
  className,
  style,
  size,
  selectedName,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      key={'totalPcsCrt'}
      disabled={true}
      valueInput={selectedName.toString()}
      name={'totalPcsCrt'}
      label={'Total Pcs/Crt'}
      placeholder={'Total Pcs/Crt'}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
    />
  );
};
OrderForm.WeightTotal = ({
  className,
  style,
  size,
  selectedName,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      key={'weightTotal'}
      disabled={true}
      valueInput={selectedName.toString()}
      name={'weightTotal'}
      label={'Weight Total'}
      placeholder={'Weight Total'}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
    />
  );
};
OrderForm.VolumeTotal = ({
  className,
  style,
  size,
  selectedName,
  readonly,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      key={'volumeTotal'}
      disabled={true}
      valueInput={selectedName.toString()}
      name={'volumeTotal'}
      label={'Volume Total'}
      placeholder={'Volume Total'}
      required={false}
      type={'input-value'}
      className={className}
      style={style}
      size={size}
    />
  );
};
OrderForm.CustomValues = ({
  onChange,
  entityType,
  defaultValue,
  customFields,
  saveButtonRenderCondition,
  isSending,
  formName,
  entityName,
  onCancel,
  context,
}: CustomValuesInputProps) => {
  return (
    <CustomValuesInput
      entityType={entityType}
      customFields={customFields}
      onChange={onChange}
      defaultValue={defaultValue}
      saveButtonRenderCondition={saveButtonRenderCondition}
      isSending={isSending}
      formName={formName}
      entityName={entityName}
      onCancel={onCancel}
      context={context}
    />
  );
};
