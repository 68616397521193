import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CountriesListScreen } from './screens/countries-list.screen';
import { CountryScreen } from './screens/country.screen';

export const COUNTRIES_LIST_PATH = '/countries';
export const COUNTRY_EDIT_PATH = '/countries/:countryCode';
export const COUNTRY_CREATE_PATH = '/countries/create';
export const COUNTRIES_PATH = '/org/:organizationId/countries';

export const CountriesRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${COUNTRIES_LIST_PATH}`}>
          <CountriesListScreen pageTitle="Countries" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${COUNTRY_CREATE_PATH}`}>
          <CountryScreen pageTitle="Add New Country" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${COUNTRY_EDIT_PATH}`}>
          <CountryScreen pageTitle="Update Country" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
