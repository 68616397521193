import React, { useEffect, useState } from 'react';
import { EventGrid } from '../../common/components/grid/eventDefinitions-grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  EventDefinitionDto,
  EventDefinitionDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  eventDefinitionStore,
  getEventDefinitions,
  deleteEventDefinition,
  updateEventDefinitionsColumns,
} from '../eventDefinitions.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_EVENTDEFINITION_LINK_KEY,
  DELETE_EVENTDEFINITION_LINK_KEY,
  GetEventDefinitionParams,
} from '../eventDefinitions.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { EVENTDEFINITIONS_CREATE_PATH } from '../eventDefinitions.route';
import { EventDefinitionDialog } from './eventDefinition.dialog';
import { userHas } from '../../auth/auth.store';

export type EventDefinitionsListProps = {
  goToDetails?: (eventDefinitionParams: GetEventDefinitionParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    eventDefinition: EventDefinitionDto,
    eventDefinitionParams: GetEventDefinitionParams,
  ) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const EventDefinitionsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  showAllStore = true,
  rowKeys = ['eventDefinitionId'],
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: EventDefinitionsListProps) => {
  const [
    eventDefinitions,
    setEventDefinitions,
  ] = useState<EventDefinitionDtoPagedResult | null>(null);

  useEffect(() => {
    getEventDefinitionsData();
  }, [offset, limit, sort, filter, search]);

  const getEventDefinitionsData = () => {
    getEventDefinitions({ offset, limit, sort, filter, search }).then(
      (eventDefinitionsData) => {
        setEventDefinitions(eventDefinitionsData);
      },
    );
  };
  const { eventDefinitionColumns: columns } = useStore(eventDefinitionStore);

  const onDeleteEventDefinition = (eventDefinition: EventDefinitionDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete "${eventDefinition.eventName}" Event Definition`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteEventDefinition(eventDefinition).then(() => {
          onDelete();
          getEventDefinitionsData();
        });
      }
    });
  };

  const onEditEventDefinition = (eventDefinition: EventDefinitionDto) => {
    goToDetails({ eventDefinitionId: eventDefinition.eventDefinitionId });
  };

  const history = useHistory();
  const onCreateNewEventDefinition = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(
      INTERNAL_PATH + EVENTDEFINITIONS_CREATE_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    history.push(createPath);
  };
  const onEventDefinitionSelect = (eventDefinition, eventDefinitionId) => {
    if (onSelect) onSelect(eventDefinition, eventDefinitionId);
    showDialog({
      dialog: EventDefinitionDialog,
      props: {
        eventDefinitionId: eventDefinition.eventDefinitionId,
        title: 'Update Event Definition',
      },
    }).then((eventDefinition) => {
      if (eventDefinition !== null) {
        getEventDefinitionsData();
      }
    });
  };

  return (
    <>
      <EventGrid
        isDropDownList={isDropDownList}
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={showAllStore}
        rowKeys={rowKeys}
        data={eventDefinitions?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={eventDefinitions?.totalCount}
        sort={sort}
        onDelete={
          userHas(
            DELETE_EVENTDEFINITION_LINK_KEY,
            eventDefinitions?.items[0]?.links,
          )
            ? onDeleteEventDefinition
            : null
        }
        onSort={onSort}
        onEdit={onEditEventDefinition}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateEventDefinitionsColumns}
        onSelect={onEventDefinitionSelect}
        className={'eventDefinitions-list'}
        filter={filter}
        onFilter={onFilter}
      />
      {!(
        (eventDefinitions &&
          eventDefinitions.items &&
          eventDefinitions.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Event Definition Yet
          </h3>
          {userHas(CREATE_EVENTDEFINITION_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={onCreateNewEventDefinition}
            >
              Create Event Definition
            </Button>
          )}
        </div>
      )}
    </>
  );
};
