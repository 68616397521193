import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { AccountingAccountScreen } from './screens/accountingAccount.screen';
import { AccountingAccountListScreen } from './screens/accountingAccount-list.screen';

export const ACCOUNTINGACCOUNT_LIST_PATH = '/accounting-account';
export const ACCOUNTINGACCOUNT_EDIT_PATH = '/accounting-account/:accountId';
export const ACCOUNTINGACCOUNT_CREATE_PATH = '/accounting-account/0';
export const ACCOUNTINGACCOUNTS_PATH =
  '/org/:organizationId/accounting-account';

export const AccountingAccountRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute
          exact
          path={`${match.path}${ACCOUNTINGACCOUNT_LIST_PATH}`}
        >
          <AccountingAccountListScreen pageTitle="Accounting Account" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${ACCOUNTINGACCOUNT_CREATE_PATH}`}
        >
          <AccountingAccountScreen pageTitle="Add New Accounting Account" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${ACCOUNTINGACCOUNT_EDIT_PATH}`}
        >
          <AccountingAccountScreen pageTitle="Update Accounting Account" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
