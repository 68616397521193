import React, { useEffect, useState } from 'react';
import { DocumentTemplateDtoPagedResult } from '../../../models/data.models';
import { ErrorMessage, Field, FormikProps, FormikValues } from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { v4 as uuidv4 } from 'uuid';
import { getDocumentTemplates } from '../documentTemplates.store';
import { showDialog } from '../../common/dialog.store';
import { DocumentTemplateDialog } from './documentTemplate.dialog';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import {
  CREATE_DOCUMENTTEMPLATE_LINK_KEY,
  DOCUMENT_TEMPLATE_ENTITY_NAME,
} from '../documentTemplates.service';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';

export const SelectDocumentTemplatePaginate = ({
  id = 'documentTemplateId' + uuidv4(),
  nameId = 'name' + uuidv4(),
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeDocumentTemplate = {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  classNamePrefix = 'select',
  readonly = false,
  showCreateDialogTitle = 'Create Document Template',
  canCreateNewEntity = null,
}): JSX.Element => {
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const [context, setContext] = useState(null);
  useEffect(() => {
    setContext(useContext);
  }, [useContext]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseContactDtoPagedResult: DocumentTemplateDtoPagedResult = await getDocumentTemplates(
      {
        limit: 10,
        offset: (page - 1) * 10,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseContactDtoPagedResult.items,
      hasMore: responseContactDtoPagedResult.items.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const onChange = (option, contextDto) => {
    if (typeof onChangeDocumentTemplate === 'function') {
      onChangeDocumentTemplate(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };
  if (context) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              className={id}
              entityName={DOCUMENT_TEMPLATE_ENTITY_NAME}
              canCreateNewEntity={
                canCreateNewEntity ?? userHas(CREATE_DOCUMENTTEMPLATE_LINK_KEY)
              }
              onCreateNewEntity={() => {
                return showDialog({
                  dialog: DocumentTemplateDialog,
                  props: {
                    title: showCreateDialogTitle,
                    documentTemplateId: 0,
                  },
                });
              }}
              key={filter}
              value={value}
              loadOptions={loadOptions}
              getOptionValue={(option) => option?.documentTemplateId}
              getOptionLabel={(option) => `${option?.name}`}
              onChange={(documentTemplate?: any) => {
                onChange(documentTemplate, context);
                if (context) {
                  if (isMulti === true) {
                    if (documentTemplate?.length > 0) {
                      context.setFieldValue(id, documentTemplate?.length);
                      context.setFieldError(id, undefined);
                    } else {
                      if (required) {
                        context.setFieldValue(id, '');
                      }
                    }
                  } else {
                    context.setFieldValue(
                      id,
                      documentTemplate?.documentTemplateId,
                    );
                    context.setFieldValue(nameId, documentTemplate?.name);
                    context.setFieldError(id, undefined);
                    if (required && !documentTemplate) {
                      context.setFieldValue(id, '');
                    }
                  }
                }
              }}
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true)}
              isDisabled={isDisabled}
              isClearable={readonly ? false : isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={readonly ? false : isSearchable}
              placeholder={placeholder}
              components={{
                IndicatorSeparator: () => null,
              }}
              menuIsOpen={readonly ? false : undefined}
              classNamePrefix={classNamePrefix}
              additional={{
                page: 1,
              }}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={DOCUMENT_TEMPLATE_ENTITY_NAME}
        canCreateNewEntity={
          canCreateNewEntity ?? userHas(CREATE_DOCUMENTTEMPLATE_LINK_KEY)
        }
        onCreateNewEntity={() => {
          return showDialog({
            dialog: DocumentTemplateDialog,
            props: {
              title: showCreateDialogTitle,
              documentTemplateId: 0,
            },
          });
        }}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.documentTemplateId}
        getOptionLabel={(option) => `${option?.name}`}
        onChange={(documentTemplate?: any) => {
          onChange(documentTemplate, context);
        }}
        onFocus={clearMessages}
        isDisabled={isDisabled}
        isClearable={readonly ? false : isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={readonly ? false : isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        menuIsOpen={readonly ? false : undefined}
        classNamePrefix={classNamePrefix}
        additional={{
          page: 1,
        }}
      />
    );
  }
};
