import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const QuickBooksForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

QuickBooksForm.PostToQB = ({
  className,
  style,
  size,
  name,
  id,
  label,
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      name={name}
      id={id}
      label={label}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      size={size}
      onChange={onChange}
    />
  );
};
