import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button } from '../../common/components/button/button.component';
import { ChargeForm } from './charge.form';
import {
  AccountingItemDto,
  ApplyBy,
  CalculatedOf,
  ChargeDto,
  ChargeStatuses,
  ChargeType,
  ContactDto,
  ContactType,
  CurrencyDto,
  PaidAs,
  SalesTaxDto,
} from '../../../models/data.models';
import { createCharge, getCharge, updateCharge } from '../charges.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ReactSelectItem } from '../../../models/custom.models';
import { FormikProps, FormikValues } from 'formik';
import {
  getEnumValues,
  validateNumberInput,
} from '../../../utils/helper.utils';
import {
  getFormattedPrice,
  getMaxDecimals,
  getSingleCommaFormattedPrice,
} from '../../../utils/formatting.utils';
import { currencyStore, getCurrency } from '../../currencies/currencies.store';
import * as Yup from 'yup';
import { organizationsStore } from '../../organization/organization.store';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { getAccountingItem } from '../../accountingItems/accountingItems.store';

export type ChargeEditProps = {
  chargeId?: number | null;
  chargeType?: ChargeType | null;
  defaultCharge?: ChargeDto | null;
  onChargeCreated?: (charge: ChargeDto) => void;
  onChargeUpdated?: (charge: ChargeDto) => void;
  onChargeLoaded?: (charge: ChargeDto) => void;
  isEditMode?: boolean | null;
  isCreateModeForTransaction?: boolean | null;
  onCancel?: () => void;
  charges?: ChargeDto[] | undefined;
};

const initialState: ChargeDto = {
  currencyName: '',
  currencyId: null,
  currencyDecimals: null,
  currencySymbol: null,
  note: undefined,
  amountAndTaxAmount: null,
  currency: null,
  expense: null,
  income: null,
  prepaid: null,
  chargeStatus: undefined,
  salesTaxAmount: null,
  salesTaxCode: '',
  salesTaxId: null,
  salesTaxRate: null,
  totalAmount: null,
  accountingItemName: '',
  applyToContactName: '',
  applyToContactType: null,
  chargeId: null,
  accountingItemId: null,
  amount: null,
  applyBy: null,
  applyToContactId: null,
  chargeType: null,
  description: null,
  freightServiceClassId: null,
  grossVolume: null,
  grossWeight: null,
  isConsolidated: false,
  paidAs: null,
  pieces: null,
  price: null,
  quantity: null,
  showInDocuments: false,
  allowAutomaticUpdate: false,
  unit: null,
  links: [],
};

const chargeSchema = Yup.object().shape({
  accountingItemId: Yup.string().required("Can't be blank").nullable(true),
  applyToContactId: Yup.string().required("Can't be blank").nullable(true),
  quantity: Yup.number()
    .positive("Can't be less than or equal to 0")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Can't be blank")
    .test('numberFormat', 'The value is too big', (value) => {
      return value < Number.MAX_SAFE_INTEGER;
    })
    .nullable(true),
  unit: Yup.string()
    .required("Can't be blank")
    .transform((value) => (value === null ? '' : value))
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER) ||
        value === undefined ||
        value === ''
      );
    })
    .test('positive', "Value can't be equal to or less than zero", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max number length is 17', (value) => {
      return value?.replace('-', '').length <= 17;
    })
    .test('integer', 'Should be integer', (value) => {
      return Number.isInteger(Number(value));
    })
    .nullable(true),
  price: Yup.number()
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === undefined
      );
    })
    .test('length', 'Max number length is 17', (value) => {
      return value?.toString().replace('-', '').length <= 17;
    })
    .required("Can't be blank")
    .nullable(true),
  amount: Yup.number().required("Can't be blank").nullable(true),
});

export const ChargeEdit = ({
  chargeId,
  defaultCharge = null,
  chargeType,
  onChargeLoaded = () => {},
  onChargeCreated = () => {},
  onChargeUpdated = () => {},
  onCancel = () => {},
  isEditMode = false,
  isCreateModeForTransaction = false,
  charges = [],
}: ChargeEditProps) => {
  const defaultCurrency = currencyStore?.getState().defaultCurrency;

  initialState.currencyId = defaultCurrency?.currencyId;
  initialState.currencyName = defaultCurrency?.currencyName;
  initialState.currency = defaultCurrency;

  const isCreateMode = !isEditMode;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCalculated, setIsCalculated] = useState(false);
  const [initialValues, setInitialValues] = useState<ChargeDto | null>(
    defaultCharge || initialState,
  );
  const [charge, setCharge] = useState<ChargeDto | null>(initialValues);
  const [chargeStatus, setChargeStatus] = useState<ChargeStatuses>(null);
  const isDisabled = chargeStatus === ChargeStatuses.Paid;
  const [defaultApplyBy, setDefaultApplyBy] = useState(
    charge?.applyBy
      ? {
          label: ApplyBy[charge?.applyBy],
          value: charge?.applyBy,
        }
      : '',
  );
  const [accountItem, setAccountItem] = useState(null);
  const [isLoadedAccountItem, setIsLoadedAccountItem] = useState(false);
  const [defaultCalculatedOf, setDefaultCalculatedOf] = useState(null);

  const [salesTax, setSalesTax] = useState<{
    salesTaxId: string;
    salesTaxCode: string;
  }>(null);

  useEffect(() => {
    if (defaultCharge && defaultCharge.accountingItemId) {
      getAccountingItem({
        accountingItemId: defaultCharge.accountingItemId,
      }).then((data) => {
        setAccountItem(data);
        setFormattedPrice(
          getSingleCommaFormattedPrice(
            initialValues?.price *
              (data.tariff?.applyBy === ApplyBy.Calculated ? 100 : 1),
            initialValues?.currencyDecimals,
          ),
        );
        if (
          data.tariff?.applyBy === ApplyBy.Calculated &&
          data.tariff?.calculatedOf
        ) {
          setDefaultCalculatedOf({
            label: data.tariff.calculatedOf,
            value: data.tariff.calculatedOf,
          });
        }
      });
    } else {
      setIsLoadedAccountItem(true);
    }
  }, []);

  useEffect(() => {
    if (accountItem) {
      const chargeIsCalculated =
        accountItem?.tariff?.applyBy === ApplyBy.Calculated;
      setIsCalculated(chargeIsCalculated);
      if (chargeIsCalculated && isLoadedAccountItem) {
        setFormattedPrice(
          getSingleCommaFormattedPrice(
            accountItem?.tariff?.rateMultiplier * 100,
            initialValues?.currencyDecimals,
          ),
        );
        const rate = Number(accountItem?.tariff?.rateMultiplier || 1);
        const quantity = calculatedTotal(accountItem);
        setCharge((chargeDto) => {
          chargeDto.price = Number(rate);
          chargeDto.quantity = quantity;
          chargeDto.amount = Number(getFormattedChargeAmount());
          return { ...chargeDto };
        });
      }
      if (!isLoadedAccountItem) setIsLoadedAccountItem(true);
    }
  }, [accountItem]);

  const calculatedTotal = (accountItemLocal = null) => {
    let totalIncome = 0;
    let totalExpense = 0;
    charges.forEach((chargeItem) => {
      if (chargeItem.applyBy !== ApplyBy.Calculated) {
        switch (chargeItem.chargeType) {
          case ChargeType.IncomeCharge:
            totalIncome += chargeItem.totalAmount;
            break;
          case ChargeType.ExpenseCharge:
            totalExpense += chargeItem.totalAmount;
            break;
        }
      }
    });

    const totalProfit = totalIncome - totalExpense;

    switch (
      accountItemLocal
        ? accountItemLocal?.tariff?.calculatedOf
        : accountItem?.tariff?.calculatedOf
    ) {
      case CalculatedOf.Income:
        return totalIncome;
      case CalculatedOf.Expense:
        return totalExpense;
      case CalculatedOf.Profit:
        return totalProfit;
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (chargeId || isEditMode) {
      if (defaultCharge) {
        setChargeStatus(defaultCharge?.chargeStatus);
        setCharge(defaultCharge);
        setInitialValues(defaultCharge);
        setSalesTax((salesTaxDto) => {
          if (!salesTaxDto) {
            salesTaxDto = {
              salesTaxId: null,
              salesTaxCode: null,
            };
          }
          salesTaxDto.salesTaxId = defaultCharge?.salesTaxId?.toString();
          salesTaxDto.salesTaxCode = defaultCharge?.salesTaxCode;
          return { ...salesTaxDto };
        });
        setIsLoading(false);
        onChargeLoaded(defaultCharge);
      } else {
        getCharge({ chargeId }).then((chargeDto: ChargeDto) => {
          setChargeStatus(chargeDto?.chargeStatus);
          setCharge(chargeDto);
          setInitialValues(chargeDto);
          setSalesTax((salesTaxDto) => {
            if (!salesTaxDto) {
              salesTaxDto = {
                salesTaxId: null,
                salesTaxCode: null,
              };
            }
            salesTaxDto.salesTaxId = chargeDto?.salesTaxId?.toString();
            salesTaxDto.salesTaxCode = chargeDto?.salesTaxCode;
            return { ...salesTaxDto };
          });
          setIsLoading(false);
          onChargeLoaded(chargeDto);
        });
      }
    } else {
      throw new Error('Charge keys were not provided');
    }
  }, [chargeId]);

  const [formattedPrice, setFormattedPrice] = useState(
    getSingleCommaFormattedPrice(
      initialValues?.price,
      initialValues?.currencyDecimals,
    ),
  );

  const onSubmitFunction = (data: ChargeDto) => {
    if (chargeType && data) {
      data.chargeType = chargeType;
      data.currencyDecimals = charge.currencyDecimals;
      data.price = Number(data.price);
      data.amount =
        (data?.quantity ? data?.quantity : 0) * (data?.price ? data?.price : 0);
      if (data.chargeType === ChargeType.IncomeCharge) {
        data.income = data.amount;
      } else {
        data.expense = data.amount;
      }
    }
    const currencyId = data.currencyId;
    getCurrency({ currencyId }).then((currency) => {
      data.currencySymbol = currency.symbol;
      data.currencyDecimals = currency.decimalPlaces;
      data.currencyName = currency.currencyName;
      if (defaultCharge == null) {
        setIsSending(true);
        if (isCreateMode) {
          if (data) {
            data.chargeStatus = ChargeStatuses.Open;
          }
          createCharge(data)
            .then((result) => {
              onChargeCreated(result);
            })
            .finally(() => setIsSending(false));
        } else {
          updateCharge(data)
            .then((result) => {
              onChargeUpdated(result);
            })
            .finally(() => setIsSending(false));
        }
      } else {
        if (isCreateMode) {
          data.chargeStatus = ChargeStatuses.Open;
          onChargeCreated(data);
        } else {
          onChargeUpdated(data);
        }
      }
    });
  };

  const onSubmit = (data: ChargeDto) => {
    const organizationId = organizationsStore.getState().currentOrganization
      ?.organizationId;
    const chargeId = data?.chargeId;
    const chargeCreateMode = data?.createMode;
    if (!chargeCreateMode) {
      getCharge({ organizationId, chargeId }).then((chargeDto: ChargeDto) => {
        if (
          chargeDto?.chargeStatus === ChargeStatuses.Paid &&
          isDisabled === false
        ) {
          addMessage({
            message: `${chargeDto?.chargeStatus} charge has limited update capabilities. Please, refresh the page.`,
            type: 'danger',
            id: v4(),
          });
        } else {
          onSubmitFunction(data);
        }
      });
    } else {
      onSubmitFunction(data);
    }
  };

  const getFormattedChargeAmount = (): string => {
    return getFormattedPrice(
      (charge?.quantity ? charge?.quantity : 0) *
        (charge?.price ? charge?.price : 0),
      charge?.currencyDecimals,
    );
  };
  const quantityOnChange = (value, context) => {
    context.setFieldValue('quantity', Number(value));
    setCharge((chargeDto) => {
      chargeDto.quantity = Number(value);
      return { ...chargeDto };
    });
  };
  const priceOnChange = (value, context) => {
    context.setFieldValue('price', Number(value));
    setFormattedPrice(value);
    setCharge((chargeDto) => {
      chargeDto.price = Number(value);
      return { ...chargeDto };
    });
  };
  const applyOnChange = (value, context) => {
    const fixedRate = getSingleCommaFormattedPrice(
      value / 100,
      initialValues?.currencyDecimals,
    );
    context.setFieldValue('price', Number(fixedRate));
    setFormattedPrice(value);
    setCharge((chargeDto) => {
      chargeDto.price = Number(fixedRate);
      return { ...chargeDto };
    });
  };

  const accountingItemOnChange = (
    data: AccountingItemDto,
    context: FormikProps<FormikValues>,
    recalculate?: boolean,
  ) => {
    setCharge((chargeDto) => {
      if (!chargeDto) {
        chargeDto = initialValues;
      }
      chargeDto.accountingItemId = data?.accountingItemId;
      chargeDto.accountingItemName = data?.description;
      chargeDto.salesTaxId = data?.salesTaxId;
      chargeDto.salesTaxCode = data?.salesTaxCode;
      chargeDto.description = data?.description;
      setSalesTax((salesTaxDto) => {
        if (!salesTaxDto) {
          salesTaxDto = {
            salesTaxCode: null,
            salesTaxId: null,
          };
        }
        salesTaxDto.salesTaxId = chargeDto.salesTaxId?.toString();
        salesTaxDto.salesTaxCode = chargeDto.salesTaxCode;
        return { ...salesTaxDto };
      });

      if (chargeDto.price === 0 || recalculate) {
        chargeDto.price = data?.price ?? 0;
        setFormattedPrice(
          getSingleCommaFormattedPrice(
            chargeDto.price,
            initialValues?.currencyDecimals,
          ),
        );
        context.setFieldValue('price', chargeDto.price);
      }
      context.setFieldValue('description', chargeDto.description ?? '');
      context.setFieldValue('amount', chargeDto.amount);
      context.setFieldValue('salesTaxId', chargeDto.salesTaxId);
      context.setFieldValue('salesTaxCode', chargeDto.salesTaxCode);

      setAccountItem(data);
      if (data?.tariff?.applyBy) {
        setDefaultApplyBy({
          label: data.tariff.applyBy,
          value: data.tariff.applyBy,
        });
        context.setFieldValue('applyBy', data.tariff.applyBy);
        if (data.tariff.applyBy === ApplyBy.Calculated) {
          setDefaultCalculatedOf({
            label: data.tariff.calculatedOf,
            value: data.tariff.calculatedOf,
          });
          const rate = Number(data?.tariff?.rateMultiplier || 1);
          const amount = Number(getFormattedChargeAmount());
          const quantity = calculatedTotal(data);
          context.setFieldValue('price', rate);
          context.setFieldValue('amount', amount);
          context.setFieldValue('allowAutomaticUpdate', true);
          context.setFieldValue('quantity', quantity);
          chargeDto.quantity = quantity;
          chargeDto.price = rate;
          chargeDto.amount = amount;
        }
      }

      return { ...chargeDto };
    });
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  const onChangeApplyBy = (newValueApplyBy?: ReactSelectItem) => {};
  const onChangeCalculatedOf = (newValueApplyBy?: ReactSelectItem) => {};
  const onChangePaidAs = (newValuePaidAs?: ReactSelectItem) => {};
  if (chargeType === ChargeType.IncomeCharge) {
    return (
      <ChargeForm
        id={'ChargeForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={chargeSchema}
      >
        <Tabs>
          <TabList>
            <Tab>Standard Charge</Tab>
          </TabList>
          <TabPanel>
            <Panel className="mt-3 mx-3">
              <div className="row p-0 row-charge">
                <div className={'col-12 d-flex p-0'}>
                  <div className={'col mr-3 p-0'}>
                    <ChargeForm.AccountingItemSelect
                      required={true}
                      nameId={'accountingItemName'}
                      id={'accountingItemId'}
                      header={'Charge'}
                      placeholder={'Select Charge'}
                      defaultValue={
                        charge?.accountingItemId && charge?.accountingItemName
                          ? {
                              accountingItemId: charge?.accountingItemId,
                              description: charge?.accountingItemName,
                            }
                          : ''
                      }
                      onChange={accountingItemOnChange}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={`col mr-3 p-0`}>
                    <ChargeForm.TaxCodeSelect
                      id={'salesTaxId'}
                      header={'Tax Code'}
                      selectedFilter={` `}
                      required={false}
                      defaultValue={
                        salesTax &&
                        salesTax.salesTaxId != null &&
                        salesTax.salesTaxCode != null
                          ? {
                              salesTaxId: salesTax.salesTaxId,
                              taxCode: salesTax.salesTaxCode,
                            }
                          : ''
                      }
                      onChange={(data?: SalesTaxDto) => {
                        setSalesTax((salesTaxDto) => {
                          if (!salesTaxDto) {
                            salesTaxDto = {
                              salesTaxCode: null,
                              salesTaxId: null,
                            };
                          }
                          salesTaxDto.salesTaxId = data?.salesTaxId?.toString();
                          salesTaxDto.salesTaxCode = data?.taxCode;
                          return { ...salesTaxDto };
                        });
                      }}
                      nameId={'salesTaxCode'}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={'col p-0'}>
                    <ChargeForm.CurrencySelect
                      selectedFilter={` `}
                      required={false}
                      nameId={'currencyCode'}
                      id={'currencyId'}
                      header={'Currency'}
                      defaultValue={
                        charge?.currencyId &&
                        charge?.currencyName &&
                        charge?.currencyDecimals
                          ? {
                              currencyId: charge.currencyId,
                              currencyCode: charge.currencyName,
                            }
                          : ''
                      }
                      onChange={(data?: CurrencyDto) => {
                        setCharge((chargeDto) => {
                          if (!chargeDto) {
                            chargeDto = initialValues;
                          }
                          chargeDto.currencyId = data?.currencyId;
                          chargeDto.currencyName = data?.currencyCode;
                          chargeDto.currencyDecimals = data?.decimalPlaces;
                          const price = getSingleCommaFormattedPrice(
                            chargeDto?.price,
                            data?.decimalPlaces,
                          );
                          chargeDto.price = Number(price);
                          setFormattedPrice(price);
                          return { ...chargeDto };
                        });
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.Description />
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col p-0 mr-3'}>
                    <ChargeForm.ApplyToContactSelect
                      id={'applyToContactId'}
                      header={'Apply To Contact'}
                      contactTypes={[
                        ContactType.Customer,
                        ContactType.Vendor,
                        ContactType.ForwardingAgent,
                        ContactType.Contact,
                      ]}
                      selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact}`}
                      required={false}
                      defaultValue={
                        charge.applyToContactId &&
                        charge.applyToContactName != null &&
                        charge.applyToContactType !== null
                          ? {
                              contactId: charge.applyToContactId,
                              name: charge.applyToContactName,
                              contactType: charge.applyToContactType,
                            }
                          : ''
                      }
                      onChange={(
                        data?: ContactDto,
                        context?: FormikProps<FormikValues>,
                      ) => {
                        context?.setFieldValue(
                          'applyToContactType',
                          data?.contactType,
                        );
                        setCharge((chargeDto) => {
                          if (!chargeDto) {
                            chargeDto = initialValues;
                          }
                          chargeDto.applyToContactName = data?.name;
                          chargeDto.applyToContactId = data?.contactId;
                          chargeDto.applyToContactType = data?.contactType;
                          return { ...chargeDto };
                        });
                      }}
                      nameId={'applyToContactName'}
                      typeId={'applyToContactType'}
                      disabled={!!defaultCharge?.applyToContactId}
                    />
                  </div>
                  <div className={'col-3 p-0'}>
                    <ChargeForm.PaidAs
                      defaultValue={
                        charge?.paidAs
                          ? {
                              label: PaidAs[charge?.paidAs],
                              value: charge?.paidAs,
                            }
                          : ''
                      }
                      options={getEnumValues(PaidAs)}
                      onChange={onChangePaidAs}
                      disabled={
                        isCreateModeForTransaction && !!defaultCharge?.paidAs
                      }
                    />
                  </div>
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.ApplyBy
                      defaultValue={defaultApplyBy}
                      options={getEnumValues(ApplyBy)}
                      onChange={onChangeApplyBy}
                      disabled={isCalculated}
                    />
                  </div>
                  {isCalculated && (
                    <div className={'col-3 p-0 pr-3'}>
                      <ChargeForm.CalculatedOf
                        defaultValue={defaultCalculatedOf}
                        options={getEnumValues(CalculatedOf)}
                        onChange={onChangeCalculatedOf}
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.IsConsolidated />
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.ShowInDocuments />
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.AllowAutomaticUpdate disabled={isCalculated} />
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.Quantity
                      defaultValue={charge?.quantity}
                      onChange={quantityOnChange}
                      disabled={isDisabled || isCalculated}
                    />
                  </div>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.Unit
                      disabled={isDisabled}
                      onKeyDown={validateNumberInput}
                    />
                  </div>
                  <div className={'col-3 p-0 pr-3'}>
                    {!isCalculated ? (
                      <ChargeForm.Price
                        defaultValue={formattedPrice}
                        onChange={priceOnChange}
                        maxDecimals={getMaxDecimals(charge?.currencyDecimals)}
                        onBlur={() =>
                          setFormattedPrice(
                            getSingleCommaFormattedPrice(
                              charge?.price,
                              charge?.currencyDecimals,
                            ),
                          )
                        }
                        disabled={isDisabled}
                      />
                    ) : (
                      <ChargeForm.Apply
                        defaultValue={formattedPrice}
                        onChange={applyOnChange}
                        maxDecimals={getMaxDecimals(charge?.currencyDecimals)}
                        disabled={isDisabled}
                      />
                    )}
                  </div>
                  <div className={'col-3 p-0'}>
                    <ChargeForm.Amount
                      defaultValue={getFormattedChargeAmount()}
                      disabled={isDisabled || isCalculated}
                    />
                  </div>
                </div>
                <div className={'col-12 d-flex p-0 pb-3'}>
                  <ChargeForm.Note className={'w-100'} />
                </div>
                <div className="justify-content-end d-flex col-12 p-0">
                  <div className="col-6 pl-0">
                    <Button
                      name="save-charge"
                      type="submit"
                      color="primary"
                      className="btn-block"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save Charge
                    </Button>
                  </div>
                  <div className="col-6 pr-0">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="col-12 btn-light btn-cancel"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Panel>
          </TabPanel>
        </Tabs>
      </ChargeForm>
    );
  } else if (chargeType === ChargeType.ExpenseCharge) {
    return (
      <ChargeForm
        id={'ChargeForm'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={chargeSchema}
      >
        <Tabs>
          <TabList>
            <Tab>Standard Charge</Tab>
          </TabList>
          <TabPanel>
            <Panel className="mt-3 mx-3">
              <div className="row p-0 row-charge">
                <div className={'col-12 d-flex p-0'}>
                  <div className={'col mr-3 p-0'}>
                    <ChargeForm.AccountingItemSelect
                      required={true}
                      nameId={'accountingItemName'}
                      id={'accountingItemId'}
                      header={'Charge'}
                      placeholder={'Select Charge'}
                      defaultValue={
                        charge?.accountingItemId && charge?.accountingItemName
                          ? {
                              accountingItemId: charge?.accountingItemId,
                              description: charge?.accountingItemName,
                            }
                          : ''
                      }
                      onChange={accountingItemOnChange}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={`col mr-3 p-0`}>
                    <ChargeForm.TaxCodeSelect
                      id={'salesTaxId'}
                      header={'Tax Code'}
                      selectedFilter={` `}
                      required={false}
                      defaultValue={
                        salesTax &&
                        salesTax.salesTaxId != null &&
                        salesTax.salesTaxCode != null
                          ? {
                              salesTaxId: salesTax.salesTaxId,
                              taxCode: salesTax.salesTaxCode,
                            }
                          : ''
                      }
                      onChange={(data?: SalesTaxDto) => {
                        setSalesTax((salesTaxDto) => {
                          if (!salesTaxDto) {
                            salesTaxDto = {
                              salesTaxCode: null,
                              salesTaxId: null,
                            };
                          }
                          salesTaxDto.salesTaxId = data?.salesTaxId?.toString();
                          salesTaxDto.salesTaxCode = data?.taxCode;
                          return { ...salesTaxDto };
                        });
                      }}
                      nameId={'salesTaxCode'}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={'col p-0'}>
                    <ChargeForm.CurrencySelect
                      selectedFilter={` `}
                      required={true}
                      nameId={'currencyName'}
                      id={'currencyId'}
                      header={'Currency'}
                      defaultValue={
                        charge?.currencyId &&
                        charge?.currencyName &&
                        charge?.currencyDecimals
                          ? {
                              currencyId: charge.currencyId,
                              currencyCode: charge.currencyName,
                            }
                          : ''
                      }
                      onChange={(data?: CurrencyDto) => {
                        setCharge((chargeDto) => {
                          if (!chargeDto) {
                            chargeDto = initialValues;
                          }
                          chargeDto.currencyId = data?.currencyId;
                          chargeDto.currencyName = data?.currencyCode;
                          chargeDto.currencyDecimals = data?.decimalPlaces;
                          const price = getSingleCommaFormattedPrice(
                            chargeDto?.price,
                            data?.decimalPlaces,
                          );
                          chargeDto.price = Number(price);
                          setFormattedPrice(price);
                          return { ...chargeDto };
                        });
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.Description />
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col p-0 mr-3'}>
                    <ChargeForm.ApplyToContactSelect
                      id={'applyToContactId'}
                      header={'Apply To Contact'}
                      contactTypes={[
                        ContactType.SalesPerson,
                        ContactType.Carrier,
                        ContactType.Driver,
                      ]}
                      selectedFilter={`contactType: ${ContactType.SalesPerson} OR contactType: ${ContactType.Carrier} OR contactType: ${ContactType.Driver}`}
                      required={false}
                      defaultValue={
                        charge.applyToContactId &&
                        charge.applyToContactName != null &&
                        charge.applyToContactType !== null
                          ? {
                              contactId: charge.applyToContactId,
                              name: charge.applyToContactName,
                              contactType: charge.applyToContactType,
                            }
                          : ''
                      }
                      onChange={(
                        data?: ContactDto,
                        context?: FormikProps<FormikValues>,
                      ) => {
                        context?.setFieldValue(
                          'applyToCarrierType',
                          data?.contactType,
                        );
                        setCharge((chargeDto) => {
                          if (!chargeDto) {
                            chargeDto = initialValues;
                          }
                          chargeDto.applyToContactName = data?.name;
                          chargeDto.applyToContactId = data?.contactId;
                          chargeDto.applyToContactType = data?.contactType;
                          return { ...chargeDto };
                        });
                      }}
                      nameId={'applyToContactName'}
                      disabled={
                        (isCreateModeForTransaction &&
                          !!defaultCharge?.applyToContactId) ||
                        isDisabled
                      }
                    />
                  </div>
                  <div className={'col-3 p-0'}>
                    <ChargeForm.PaidAs
                      defaultValue={
                        charge?.paidAs
                          ? {
                              label: PaidAs[charge?.paidAs],
                              value: charge?.paidAs,
                            }
                          : ''
                      }
                      options={getEnumValues(PaidAs)}
                      onChange={onChangePaidAs}
                      disabled={
                        isCreateModeForTransaction && !!defaultCharge?.paidAs
                      }
                    />
                  </div>
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.ApplyBy
                      defaultValue={defaultApplyBy}
                      options={getEnumValues(ApplyBy)}
                      onChange={onChangeApplyBy}
                      disabled={isCalculated}
                    />
                  </div>
                  {isCalculated && (
                    <div className={'col-3 p-0 pr-3'}>
                      <ChargeForm.CalculatedOf
                        defaultValue={defaultCalculatedOf}
                        options={getEnumValues(CalculatedOf)}
                        onChange={onChangeCalculatedOf}
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.IsConsolidated />
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.ShowInDocuments />
                </div>
                <div className={'col-12 p-0'}>
                  <ChargeForm.AllowAutomaticUpdate disabled={isCalculated} />
                </div>
                <div className={'col-12 p-0 d-flex'}>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.Quantity
                      defaultValue={charge?.quantity}
                      onChange={quantityOnChange}
                      disabled={isDisabled || isCalculated}
                    />
                  </div>
                  <div className={'col-3 p-0 pr-3'}>
                    <ChargeForm.Unit
                      disabled={isDisabled}
                      onKeyDown={validateNumberInput}
                    />
                  </div>
                  <div className={'col-3 p-0 pr-3'}>
                    {!isCalculated ? (
                      <ChargeForm.Price
                        defaultValue={formattedPrice}
                        onChange={priceOnChange}
                        maxDecimals={getMaxDecimals(charge?.currencyDecimals)}
                        onBlur={() =>
                          setFormattedPrice(
                            getSingleCommaFormattedPrice(
                              charge?.price,
                              charge?.currencyDecimals,
                            ),
                          )
                        }
                        disabled={isDisabled}
                      />
                    ) : (
                      <ChargeForm.Apply
                        defaultValue={formattedPrice}
                        onChange={applyOnChange}
                        maxDecimals={getMaxDecimals(charge?.currencyDecimals)}
                        disabled={isDisabled}
                      />
                    )}
                  </div>
                  <div className={'col-3 p-0'}>
                    <ChargeForm.Amount
                      defaultValue={getFormattedChargeAmount()}
                      disabled={isDisabled || isCalculated}
                    />
                  </div>
                </div>
                <div className={'col-12 d-flex p-0 pb-3'}>
                  <ChargeForm.Note className={'w-100'} />
                </div>
                <div className="justify-content-end d-flex col-12 p-0">
                  <div className="col-6 pl-0">
                    <Button
                      name="save-charge"
                      type="submit"
                      color="primary"
                      className="btn-block"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save Charge
                    </Button>
                  </div>

                  <div className="col-6 pr-0">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="col-12 btn-light btn-cancel"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Panel>
          </TabPanel>
        </Tabs>
      </ChargeForm>
    );
  } else return <div></div>;
};
