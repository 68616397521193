import React from 'react';
import ReactDOM from 'react-dom';
import './style/theme.scss';
import { App } from './app.component';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';

// @ts-ignore
window.jQuery = window.$ = require('jquery');
require('popper.js');
require('bootstrap');

Sentry.init({
  dsn: 'https://cae418942be7455b8ef82e4ca5b3ec81@sentry.ankocorp.com/22',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/seahorsetms-dev\.ankocorp\.com\/api/,
        /^https:\/\/tms\.seahorseexpress\.com\/api/,
      ],
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="407796565858-43gvoe33eg3g3n2hhrsknd7jgoc9p7k8.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
