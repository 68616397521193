import React from 'react';
import { BsBoxSeam } from 'react-icons/bs';
import { CommodityStatusesColors } from '../../../models/data.models';

export type CommodityStatusProps = {
  commodityStatus: string;
};

export const CommodityStatus = ({
  commodityStatus = '',
}: CommodityStatusProps) => {
  return (
    <div className="d-flex align-items-center">
      <BsBoxSeam
        color={CommodityStatusesColors[commodityStatus]}
        className="mr-2"
      />
      <span style={{ lineHeight: '1' }}>{commodityStatus}</span>
    </div>
  );
};
