import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ContactScreen } from './screens/contact.screen';
import { ContactsListScreen } from './screens/contact-list.screen';

export const CONTACTS_LIST_PATH = '/contacts';
export const CONTACTS_EDIT_PATH = '/contacts/:contactId';
export const CONTACTS_CREATE_PATH = '/contacts/0';
export const CONTACTS_PATH = '/org/:organizationId/contacts';
export const CONTACTS_TAB_EDIT_PATH_FOR_CUSTOMERS =
  '/customers/:customerId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_LEADS =
  '/leads/:leadId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_CARRIERS =
  '/carriers/:carrierId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_VENDORS =
  '/vendors/:vendorId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_FACTORING_COMPANIES =
  '/factoring-companies/:factoringCompanyId/contacts/:contactId';
export const CONTACTS_TAB_EDIT_PATH_FOR_FORWARDING_AGENTS =
  '/forwarding-agents/:forwardingAgentId/contacts/:contactId';

export const ContactsRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${CONTACTS_LIST_PATH}`}>
          <ContactsListScreen pageTitle="Contacts" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CONTACTS_CREATE_PATH}`}>
          <ContactScreen pageTitle="Add New Contact" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CONTACTS_EDIT_PATH}`}>
          <ContactScreen pageTitle="Update Contact" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
