import {
  DocumentTemplateDto,
  LinkDto,
  LinkResourceBaseDto,
  DocumentTemplateDtoPagedResult,
  CreateDocumentTemplateCommandValues,
  UpdateDocumentTemplateCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_DOCUMENTTEMPLATES_LINK_KEY = 'get-documenttemplates';
export const CREATE_DOCUMENTTEMPLATE_LINK_KEY = 'create-documenttemplate';
export const GET_DOCUMENTTEMPLATE_LINK_KEY = 'get-documenttemplate';
export const UPDATE_DOCUMENTTEMPLATE_LINK_KEY = 'update-documenttemplate';
export const DELETE_DOCUMENTTEMPLATE_LINK_KEY = 'delete-documenttemplate';
export const DOWNLOAD_DEFAULT_DOCUMENTTEMPLATES_LINK_KEY =
  'download-documenttemplates';
export const GET_RENDEREDDOCUMENT_LINK_KEY = 'get-rendereddocument';

export const DOCUMENT_TEMPLATE_ENTITY_NAME = 'Document Template';

export interface GetDocumentTemplateParams {
  documentTemplateId: number;
}

export const getDocumentTemplateRequest = async (
  resource: LinkResourceBaseDto | null,
  { documentTemplateId }: GetDocumentTemplateParams,
): Promise<DocumentTemplateDto> => {
  if (resource && documentTemplateId) {
    const getDocumentTemplateLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_DOCUMENTTEMPLATE_LINK_KEY,
    );
    if (getDocumentTemplateLink) {
      const result = await execLink(getDocumentTemplateLink, {
        documentTemplateId,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_DOCUMENTTEMPLATE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getDocumentTemplatesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<DocumentTemplateDtoPagedResult> => {
  if (resource) {
    const getDocumentTemplatesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_DOCUMENTTEMPLATES_LINK_KEY,
    );
    if (getDocumentTemplatesListLink) {
      const result = await execLink(getDocumentTemplatesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_DOCUMENTTEMPLATES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createDocumentTemplateRequest = async (
  resource: LinkResourceBaseDto,
  documentTemplate: CreateDocumentTemplateCommandValues,
) => {
  const createDocumentTemplateLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_DOCUMENTTEMPLATE_LINK_KEY,
  );
  if (createDocumentTemplateLink) {
    const result = await execLink(createDocumentTemplateLink, documentTemplate);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_DOCUMENTTEMPLATE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateDocumentTemplateRequest = async (
  resource: LinkResourceBaseDto,
  documentTemplate: UpdateDocumentTemplateCommandValues,
) => {
  const updateDocumentTemplateLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_DOCUMENTTEMPLATE_LINK_KEY,
  );
  if (updateDocumentTemplateLink) {
    const result = await execLink(updateDocumentTemplateLink, documentTemplate);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_DOCUMENTTEMPLATE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteDocumentTemplateRequest = async (
  documentTemplate: DocumentTemplateDto,
) => {
  const deleteDocumentTemplateLink = documentTemplate.links?.find(
    (x: LinkDto) => x.rel === DELETE_DOCUMENTTEMPLATE_LINK_KEY,
  );
  if (deleteDocumentTemplateLink) {
    const result = await execLink(deleteDocumentTemplateLink, documentTemplate);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_DOCUMENTTEMPLATE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const downloadDefaultDocumentTemplatesRequest = async (
  resource?: LinkResourceBaseDto | null,
): Promise<DocumentTemplateDtoPagedResult> => {
  if (resource) {
    const getDocumentTemplatesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === DOWNLOAD_DEFAULT_DOCUMENTTEMPLATES_LINK_KEY,
    );
    if (getDocumentTemplatesListLink) {
      const result = await execLink(getDocumentTemplatesListLink);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_DOCUMENTTEMPLATES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
