import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { SalesPersonListScreen } from './screens/salesPerson-list.screen';
import { SalesPersonScreen } from './screens/salesPerson.screen';

export const SALES_PERSONS_LIST_PATH = '/sales-persons';
export const SALES_PERSONS_EDIT_PATH = '/sales-persons/:contactId';
export const SALES_PERSONS_CREATE_PATH = '/sales-persons/0';
export const SALES_PERSONS_PATH = '/org/:organizationId/sales-persons';

export const SalesPersonsRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${SALES_PERSONS_LIST_PATH}`}>
          <SalesPersonListScreen pageTitle="Sales Persons" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${SALES_PERSONS_CREATE_PATH}`}>
          <SalesPersonScreen pageTitle="Add New Sales Person" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${SALES_PERSONS_EDIT_PATH}`}>
          <SalesPersonScreen pageTitle="Update Sales Person" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
