import {
  Router,
  BrowserRouter,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {
  FORGOT_PASSWORD_PATH,
  ForgotPasswordRoute,
  LOGIN_PATH,
  LoginRoute,
  REGISTER_PATH,
  RegisterRoute,
  RESET_PASSWORD_PATH,
  ResetPasswordRoute,
} from './modules/auth/auth.router';
import React from 'react';
import { clearMessages } from './modules/common/messages.store';
import {
  NOT_FOUND_PATH,
  NotFoundRoute,
} from './modules/notFound/not-found.route';
import {
  OrganizationRoute,
  OUTER_ORGANIZATION_CREATE_PATH,
} from './modules/organization/organization.route';
import {
  COUNTRIES_PATH,
  CountriesRoute,
} from './modules/countries/countries.route';
import { STATES_PATH, StatesRoute } from './modules/states/states.route';
import { PortRoute, PORTS_PATH } from './modules/port/ports.route';
import { CONTACTS_PATH, ContactsRoute } from './modules/contacts/contact.route';
import {
  DivisionRoute,
  DIVISIONS_PATH,
} from './modules/divisions/division.route';
import { OrderRoute, ORDERS_PATH } from './modules/orders/order.route';
import {
  ContactAddressRoute,
  CONTACTS_ADDRESSES_PATH,
} from './modules/contactAddresses/contactAddress.route';
import { CARRIERS_PATH, CarriersRoute } from './modules/carriers/carrier.route';
import { VendorRoute, VENDORS_PATH } from './modules/vendor/vendor.route';
import { DRIVERS_PATH, DriversRoute } from './modules/drivers/driver.route';
import {
  SALES_PERSONS_PATH,
  SalesPersonsRoute,
} from './modules/salesPersons/salesPerson.route';
import {
  EMPLOYEES_PATH,
  EmployeesRoute,
} from './modules/employees/employee.route';
import {
  FORWARDING_AGENTS_PATH,
  ForwardingAgentsRoute,
} from './modules/forwardingAgent/forwardingAgent.route';
import {
  AccountingAccountRoute,
  ACCOUNTINGACCOUNTS_PATH,
} from './modules/accountingAccounts/accountingAccount.route';
import {
  SALESTAXES_PATH,
  SalesTaxRoute,
} from './modules/salesTaxes/salesTax.route';
import {
  ACCOUNTINGITEMS_PATH,
  AccountingItemsRoute,
} from './modules/accountingItems/accountingItem.route';
import {
  CURRENCIES_PATH,
  CurrencyRoute,
} from './modules/currencies/currencies.route';
import {
  PaymentTermRoute,
  PAYMENTTERMS_PATH,
} from './modules/paymentTerms/paymentTerms.route';
import { INVOICES_PATH, InvoicesRoute } from './modules/invoice/invoices.route';
import { BILLS_PATH, BillsRoute } from './modules/bill/bills.route';
import {
  COMMODITIES_PATH,
  CommodityRoute,
} from './modules/commodities/commodity.route';
import {
  CONTAINER_TYPES_PATH,
  ContainerTypesRoute,
} from './modules/containerTypes/containerTypes.route';
import {
  CONTAINER_DESCRIPTIONS_PATH,
  ContainerDescriptionsRoute,
} from './modules/containerDescriptions/containerDescriptions.route';
import {
  MODE_OF_TRANSPORTATIONS_PATH,
  ModeOfTransportationRoute,
} from './modules/modeOfTransportations/modeOfTransportation.route';
import {
  CARRIER_PAY_MANAGER_PATH,
  CarrierPayManagerRoute,
} from './modules/carrierPayManager/carrierPayManager.route';
import {
  INVOICE_MANAGER_PATH,
  InvoiceManagerRoute,
} from './modules/invoiceManager/invoiceManager.route';
import {
  DRIVER_PAY_MANAGER_PATH,
  DriverPayManagerRoute,
} from './modules/driverPayManager/driverPayManager.route';
import {
  EquipmentTypeRoute,
  EQUIPMENTTYPES_PATH,
} from './modules/equipmentTypes/equipmentType.route';
import {
  FACTORING_COMPANIES_PATH,
  FactoringCompanysRoute,
} from './modules/factoringCompanies/factoringCompany.route';
import {
  InnerOrganizationRoute,
  ORGANIZATION_PATH,
} from './modules/organization/inner-organization.route';
import {
  DOCUMENT_TEMPLATES_PATH,
  DocumentTemplateRoute,
} from './modules/documentTemplates/documentTemplate.route';
import {
  CUSTOM_FIELDS_PATH,
  CustomFieldRoute,
} from './modules/customFields/customFields.route';
import { USERS_LIST_PATH, UsersRoute } from './modules/users/users.route';
import {
  UserGroupRoute,
  USERGROUPS_PATH,
} from './modules/userGroups/userGroup.route';
import { PaymentRoute, PAYMENTS_PATH } from './modules/payments/payment.route';
import {
  DASHBOARD_PATH,
  DashboardRoute,
} from './modules/dashboard/dashboard.route';
import { RootRoute } from './modules/common/root.route';
import {
  PackageTypeRoute,
  PACKAGETYPES_PATH,
} from './modules/packageTypes/packageType.route';
import {
  PACKAGE_CATEGORIES_PATH,
  PackageCategoryRoute,
} from './modules/packageCategories/packageCategories.route';
import {
  CustomerRoute,
  CUSTOMERS_PATH,
} from './modules/customers/customer.route';
import {
  EventDefinitionRoute,
  EVENTDEFINITIONS_PATH,
} from './modules/eventDefinitions/eventDefinitions.route';
import {
  TrackingRoute,
  TRACKING_PATH,
} from './modules/tracking/tracking.route';
import { LeadRoute, LEADS_PATH } from './modules/leads/lead.route';
import { QuoteRoute, QUOTES_PATH } from './modules/quotes/quote.route';
import {
  OrderTrackingRoute,
  ORDERTRACKING_PATH,
} from './modules/orderTrackings/orderTrackings.route';
import {
  QuickBooksRoute,
  QUICKBOOKS_EDIT_PATH,
  QUICKBOOKS_PATH,
} from './modules/quickBooks/quickBooks.route';
import {
  QUICKBOOKS_REDIRECT_PATH,
  QuickBooksRedirectRoute,
} from './modules/quickBooks/quickBooksRedirect.route';

export const INTERNAL_PATH = '/org/:organizationId';

export const history = createBrowserHistory();

history.listen((location, action) => {
  clearMessages();
  // tslint:disable-next-line:no-console
  console.log(action, location.pathname, location.state);
});

export const useQuery = (): any => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  return Object.fromEntries(urlSearchParams.entries());
};

export default class AppRouter extends BrowserRouter {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <LoginRoute path={LOGIN_PATH} />
          <RegisterRoute path={REGISTER_PATH} />
          <ForgotPasswordRoute path={FORGOT_PASSWORD_PATH} />
          <ResetPasswordRoute path={RESET_PASSWORD_PATH} />
          <OrganizationRoute path={OUTER_ORGANIZATION_CREATE_PATH} />
          <UsersRoute path={USERS_LIST_PATH} />
          <NotFoundRoute path={NOT_FOUND_PATH} />
          <QuickBooksRedirectRoute path={QUICKBOOKS_REDIRECT_PATH} />
          <Route path={INTERNAL_PATH}>
            <Switch>
              <DashboardRoute exact path={DASHBOARD_PATH} />
              <CountriesRoute path={COUNTRIES_PATH} />
              <StatesRoute path={STATES_PATH} />
              <PortRoute path={PORTS_PATH} />
              <DivisionRoute path={DIVISIONS_PATH} />
              <OrderRoute path={ORDERS_PATH} />
              <QuoteRoute path={QUOTES_PATH} />
              <ContactAddressRoute path={CONTACTS_ADDRESSES_PATH} />
              <PackageTypeRoute path={PACKAGETYPES_PATH} />
              <PackageCategoryRoute path={PACKAGE_CATEGORIES_PATH} />
              <CustomerRoute path={CUSTOMERS_PATH} />
              <LeadRoute path={LEADS_PATH} />
              <CarriersRoute path={CARRIERS_PATH} />
              <VendorRoute path={VENDORS_PATH} />
              <ContactsRoute path={CONTACTS_PATH} />
              <DriversRoute path={DRIVERS_PATH} />
              <SalesPersonsRoute path={SALES_PERSONS_PATH} />
              <EmployeesRoute path={EMPLOYEES_PATH} />
              <ForwardingAgentsRoute path={FORWARDING_AGENTS_PATH} />
              <AccountingAccountRoute path={ACCOUNTINGACCOUNTS_PATH} />
              <SalesTaxRoute path={SALESTAXES_PATH} />
              <AccountingItemsRoute path={ACCOUNTINGITEMS_PATH} />
              <CurrencyRoute path={CURRENCIES_PATH} />
              <PaymentTermRoute path={PAYMENTTERMS_PATH} />
              <InvoicesRoute path={INVOICES_PATH} />
              <BillsRoute path={BILLS_PATH} />
              <CommodityRoute path={COMMODITIES_PATH} />
              <ContainerTypesRoute path={CONTAINER_TYPES_PATH} />
              <ContainerDescriptionsRoute path={CONTAINER_DESCRIPTIONS_PATH} />
              <ModeOfTransportationRoute path={MODE_OF_TRANSPORTATIONS_PATH} />
              <CarrierPayManagerRoute path={CARRIER_PAY_MANAGER_PATH} />
              <InvoiceManagerRoute path={INVOICE_MANAGER_PATH} />
              <DriverPayManagerRoute path={DRIVER_PAY_MANAGER_PATH} />
              <EquipmentTypeRoute path={EQUIPMENTTYPES_PATH} />
              <FactoringCompanysRoute path={FACTORING_COMPANIES_PATH} />
              <InnerOrganizationRoute path={ORGANIZATION_PATH} />
              <DocumentTemplateRoute path={DOCUMENT_TEMPLATES_PATH} />
              <CustomFieldRoute path={CUSTOM_FIELDS_PATH} />
              <UserGroupRoute path={USERGROUPS_PATH} />
              <EventDefinitionRoute path={EVENTDEFINITIONS_PATH} />
              <QuickBooksRoute path={QUICKBOOKS_PATH} />
              <PaymentRoute path={PAYMENTS_PATH} />
              <TrackingRoute path={TRACKING_PATH} />
              <OrderTrackingRoute path={ORDERTRACKING_PATH} />
              <RootRoute path={''} />
            </Switch>
          </Route>
          <TrackingRoute path={TRACKING_PATH} />
          <RootRoute path={''} />
        </Switch>
      </Router>
    );
  }
}
