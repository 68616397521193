import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { QuickBooksRedirectScreen } from './screens/quickBooksRedirect.screen';

export const QUICKBOOKS_REDIRECT_PATH = '/quickBooks/redirectUrl';

export const QuickBooksRedirectRoute = (props: RouteProps) => {
  return (
    <Route {...props}>
      <QuickBooksRedirectScreen />
    </Route>
  );
};
