import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import { PortDto, PortDtoPagedResult } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  portsStore,
  getPorts,
  removePort,
  updateColumns,
} from '../ports.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PORTS_CREATE_PATH } from '../ports.route';
import { PortDialog } from './port.dialog';
import { userHas } from '../../auth/auth.store';
import { CREATE_PORT_LINK_KEY, DELETE_PORT_LINK_KEY } from '../port.service';

export type PortsListProps = {
  goToDetails?: (id?: number) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (country: PortDto, countryCode: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const PortsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: PortsListProps) => {
  const [ports, setPorts] = useState<PortDtoPagedResult | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    GetPorts();
  }, [offset, limit, sort, filter, search]);

  function GetPorts() {
    getPorts({ offset, limit, sort, filter, search }).then((port) => {
      port.items = port?.items?.map((item) => {
        let method = ' ';
        if (item.isMariTime === true) {
          method += 'MariTime';
        } else if (item.isMail === true) {
          method += 'Mail';
        } else if (item.isBorderCrossingPoint === true) {
          method += 'Border Crossing Point';
        } else if (item.isAir === true) {
          method += 'Air';
        } else if (item.isRail === true) {
          method += 'Rail';
        } else if (item.isRoad === true) {
          method += 'Road';
        }

        const portView: PortDto = {
          portId: item.portId,
          organizationId: item.organizationId,
          countryCode: item.countryCode,
          stateCode: item.stateCode,
          created: item.created,
          createdBy: item.createdBy,
          lastModified: item.lastModified,
          isMyCompany: item.isMyCompany,
          name: item.name,
          lastModifiedBy: item.lastModifiedBy,
          //  methods: method,
          links: item.links,
          isAir: item.isAir,
          isBorderCrossingPoint: false,
          isMail: false,
          isMariTime: false,
          portRemarks: '',
          isRail: false,
          isRoad: false,
        };
        return portView;
      });
      setPorts(port);
      setLoading(false);
    });
  }
  const history = useHistory();
  const { portsColumns: columns } = useStore(portsStore);
  const onPortSelect = (port, portId) => {
    if (onSelect) onSelect(port, portId);
    showDialog({
      dialog: PortDialog,
      props: {
        portId: port.portId,
        title: 'Update Port',
      },
    }).then((port) => {
      if (port !== null) {
        GetPorts();
      }
    });
  };

  const onDeletePort = (port: PortDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete Port`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        removePort(port).then(() => {
          onDelete();
          GetPorts();
        });
      }
    });
  };

  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          isDropDownList={isDropDownList}
          showEmptyTable={true}
          filter={filter}
          onFilter={onFilter}
          showAllStore={true}
          rowKeys={null}
          data={ports?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={ports?.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_PORT_LINK_KEY, ports?.items[0]?.links)
              ? onDeletePort
              : null
          }
          onSort={onSort}
          onEdit={(port: PortDto) => {
            goToDetails(port.portId);
          }}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateColumns}
          onSelect={onPortSelect}
          onSearch={onSearch}
          search={search}
        />
        {(!ports || !ports?.items || !ports?.items?.length) &&
          !filter &&
          !search && (
            <div className="m-5 text-center">
              <h3 className="text-muted mb-4">You Don't Have Any Port Yet</h3>
              {userHas(CREATE_PORT_LINK_KEY) && (
                <Button
                  size={'lg'}
                  color="primary"
                  onClick={() => {
                    const {
                      currentOrganization,
                    } = organizationsStore.getState();
                    const createPath = generatePath(
                      INTERNAL_PATH + PORTS_CREATE_PATH,
                      {
                        organizationId: currentOrganization.organizationId,
                      },
                    );
                    history.push(createPath);
                  }}
                >
                  Create Port
                </Button>
              )}
            </div>
          )}
      </>
    );
  }
};
