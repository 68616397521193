import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authStore } from '../auth/auth.store';
import { useStore } from 'effector-react';
import { organizationsStore } from '../organization/organization.store';

export type PrivateRouteProps = {
  children: any;
  exact?: boolean;
  path: string;
};

export const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  const { user } = useStore(authStore);
  const { organizations } = useStore(organizationsStore);
  return (
    <Route {...rest}>
      {user ? (
        organizations.length ? (
          children
        ) : (
          <Redirect to="/createOrganization" />
        )
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
};
