import React, { CSSProperties } from 'react';

import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { BsFilter, BsListTask, BsThreeDotsVertical } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';

import { Pagination } from '../pagination/pagination.component';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import { CarrierPayManagerForListDto } from '../../../../models/custom.models';
import {
  Column,
  ContactType,
  OrderStatuses,
} from '../../../../models/data.models';
import { showDialog } from '../../dialog.store';
import { Button } from '../button/button.component';
import { BillDialog } from '../../../bill/components/bill.dialog';
import { getFormattedPrice } from '../../../../utils/formatting.utils';
import { currencyStore } from '../../../currencies/currencies.store';

export type CarrierPayManagerGridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: CarrierPayManagerForListDto[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onSelect?: (item: any, keys: any) => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  onChangeItem?: (items: any[]) => void;
  showAllFilters?: boolean;
  receiveCarrierPay?: (item: any) => void;
};

export const CarrierPayManagerGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onColumnsChanged = () => {},
  onSort = () => {},
  onCopy = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  onChangeItem = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  showEmptyTable = false,
  showAllStore = false,
  showAllFilters = true,
  receiveCarrierPay = () => {},
}: CarrierPayManagerGridProps) => {
  const colByName: { [key: string]: any } = {};
  const updateCols = (colName: string) => {
    const col = colByName[colName];
    col.visible = !col.visible;
    return onColumnsChanged(columns);
  };
  const updateSort = (colName: string) => {
    const sortName = columns.find((col) => col.name === colName).sortName;
    colName = sortName ?? colName;
    if (sort && new RegExp('^-?' + colName + '$', 'igm').test(sort)) {
      onSort(sort.indexOf('-') === 0 ? colName : '-' + colName);
    } else {
      onSort(colName);
    }
  };
  const updateFilter = (filters: any) => {
    return onFilter(getSearchQuery(filters));
  };
  const updateSearch = (event) => {
    return onSearch(encodeURIComponent(event.target.value));
  };

  const { defaultCurrency } = currencyStore?.getState();

  const getClassGridItem = (row?: any): string => {
    const orderStatus = OrderStatuses[row?.orderStatus];
    if (orderStatus === OrderStatuses.OnRoute) {
      return 'bg-light-green';
    } else if (orderStatus === OrderStatuses.Open) {
      return 'bg-light-red';
    } else if (orderStatus === OrderStatuses.Refused) {
      return 'bg-violet';
    } else if (orderStatus === OrderStatuses.Covered) {
      return 'bg-dark-blue';
    } else if (orderStatus === OrderStatuses.Dispatched) {
      return 'bg-brown';
    } else if (
      orderStatus === OrderStatuses.Loading ||
      orderStatus === OrderStatuses.UnLoading
    ) {
      return 'bg-gray';
    } else if (orderStatus === OrderStatuses.InYard) {
      return 'bg-pink';
    }
    return '';
  };
  const isEqualsColumnType = (columnName: string, type: string): boolean => {
    if (columnName == type) {
      return true;
    }
    return false;
  };

  const getTdClass = (itemName?: any) => {
    let resultClasses = ['cursor-pointer'];
    if (itemName === 'firstOrderPickupsShipperAddressName') {
      resultClasses.push('three-dots order-grid-origin');
    } else if (itemName === 'firstOrderDeliveriesConsigneeAddressName') {
      resultClasses.push('three-dots order-grid-destination');
    } else if (itemName === 'orderStatus') {
      resultClasses.push('order-grid-order-status');
    } else if (itemName === 'billReceived') {
      resultClasses.push('font-weight-bold');
    }
    return resultClasses.join(' ');
  };

  const getFilteredRowsFilter = (): Column[] => {
    return columns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  return (
    <div className={`grid ${className}`} style={style}>
      <div className="grid-toolbar d-flex pl-3">
        {onSearch ? (
          <input
            type="search"
            className="form-control my-2"
            placeholder="Search"
            onChange={updateSearch}
            value={decodeURIComponent(search)}
          />
        ) : null}
        {onFilter ? (
          <div className="dropdown dropdown-columns my-3 px-3 w-100">
            {columns.some((column) => column.showFilter) && (
              <span
                className="dropdown-toggle pointer"
                id="dropdownFilterButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <BsFilter />
                &nbsp;
                <small className="align-middle pl-1 text-uppercase">
                  Filters
                </small>
                &nbsp;
                <small className="align-middle text-primary">
                  {getReadableQueryString(filter, getFilteredRowsFilter())}
                </small>
              </span>
            )}
            {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
              <div
                className="dropdown-menu px-5"
                aria-labelledby="dropdownFilterButton"
              >
                <div>
                  <Filters
                    query={filter}
                    columns={getFilteredRowsFilter()}
                    onFilter={updateFilter}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="dropdown dropdown-columns my-3 px-3 ml-auto pointer">
          <div
            className="dropdown-toggle"
            id="dropdownColumnsButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <BsListTask className="" />
            &nbsp;
            <small className="align-middle pl-1 text-uppercase">Columns</small>
          </div>
          <div
            className="dropdown-menu"
            aria-labelledby="dropdownColumnsButton"
            style={{ width: 'max-content', maxWidth: 'unset' }}
          >
            {columns
              .filter((item) => {
                if (showAllStore) {
                  return true;
                }
                return rowKeys?.includes(item.name);
              })
              .map((col) => {
                return (
                  <label key={col.name} className="dropdown-item pointer">
                    <input
                      type="checkbox"
                      onChange={() => updateCols(col.name)}
                      defaultChecked={col.visible}
                    />{' '}
                    {col.title}
                  </label>
                );
              })}
          </div>
        </div>
      </div>
      {total > 0 || showEmptyTable === true ? (
        <div className="bg-white mx-3">
          <table className="table" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                {columns
                  .filter((col) => {
                    if (showAllStore && col.visible) {
                      return true;
                    }
                    return col.visible && rowKeys?.includes(col.name);
                  })
                  .map((col) => {
                    return (
                      <th scope="col" key={col.name}>
                        <a
                          className={col.sortName ? 'link' : 'inactive-link'}
                          onClick={() =>
                            col.sortName ? updateSort(col.name) : null
                          }
                        >
                          {col.title}
                          {sort === col.name || sort === col.sortName ? (
                            <FaSortAlphaDown />
                          ) : null}
                          {sort === '-' + col.name ||
                          sort === '-' + col.sortName ? (
                            <FaSortAlphaUp />
                          ) : null}
                        </a>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {data.map((row) => {
                return (
                  <tr
                    className={getClassGridItem(row)}
                    key={rowKeys?.map((x) => row[x]).join('_')}
                    data-cy-user-name={row.carrierName}
                  >
                    {Object.values(columns)
                      .filter((item) => {
                        if (showAllStore && item.visible) {
                          return true;
                        }
                        return item.visible && rowKeys?.includes(item.name);
                      })
                      .map((item, index) => {
                        switch (item.name) {
                          case 'billReceived':
                            return (
                              <td
                                style={{
                                  width: `calc(100% / ${
                                    columns.filter(
                                      (column) => column.visible === true,
                                    ).length
                                  })`,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                                className={getTdClass(item.name)}
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                onClick={() => {
                                  if (row.accountingTransactionId) {
                                    showDialog({
                                      dialog: BillDialog,
                                      props: {
                                        className:
                                          'accounting-transaction-modal',
                                        title: 'Update Accounting Transaction',
                                        accountingTransactionId:
                                          row.accountingTransactionId,
                                      },
                                    }).then((result) => {});
                                  }
                                }}
                              >
                                {row[item.name] ? (
                                  <u>{row.transactionNumber}</u>
                                ) : (
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={(event) => {
                                      receiveCarrierPay(row);
                                    }}
                                    className="col-12 btn-light btn-cancel px-1"
                                    style={{ fontSize: '0.775rem' }}
                                  >
                                    Receive now
                                  </Button>
                                )}
                              </td>
                            );
                          case 'carrierFee':
                          case 'balanceOwning':
                            return (
                              <td
                                style={{
                                  width: `calc(100% / ${
                                    columns.filter(
                                      (column) => column.visible === true,
                                    ).length
                                  })`,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                                className={getTdClass(item.name)}
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                onClick={() => {
                                  if (onSelect) {
                                    onSelect(
                                      row,
                                      rowKeys?.reduce((keyObj, field) => {
                                        return row[field];
                                      }),
                                    );
                                  }
                                }}
                              >
                                {getFormattedPrice(
                                  row[item.name],
                                  defaultCurrency?.decimalPlaces,
                                  defaultCurrency?.symbol,
                                )}
                              </td>
                            );
                          default:
                            return (
                              <td
                                style={{
                                  width: `calc(100% / ${
                                    columns.filter(
                                      (column) => column.visible === true,
                                    ).length
                                  })`,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                                className={getTdClass(item.name)}
                                key={`${rowKeys
                                  ?.map((x) => row[x])
                                  .join('_')}_${item.name}`}
                                onClick={() => {
                                  if (onSelect) {
                                    onSelect(
                                      row,
                                      rowKeys?.reduce((keyObj, field) => {
                                        return row[field];
                                      }),
                                    );
                                  }
                                }}
                              >
                                {row[item.name]}
                              </td>
                            );
                        }
                      })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-4">Nothing Found</p>
      )}
      {total > 0 && showPagination === true ? (
        <div className="mt-3 d-flex justify-content-center">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
          />
        </div>
      ) : null}
    </div>
  );
};
