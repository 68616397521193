import {
  PaymentTermDto,
  LinkDto,
  LinkResourceBaseDto,
  PaymentTermDtoPagedResult,
  CreatePaymentTermCommandValues,
  UpdatePaymentTermCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPaymentTermRequest,
  getPaymentTermsListRequest,
  getPaymentTermRequest,
  deletePaymentTermRequest,
  updatePaymentTermRequest,
  GetPaymentTermParams,
  downloadQuickBooksPaymentTermsRequest,
} from './paymentTerms.service';

type PaymentTermsStoreState = {
  links: LinkDto[];
  paymentTermColumns: Column[];
};

export const updatePaymentTermsColumns = createEvent<Column[]>();

export const getPaymentTerms = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPaymentTermsListRequest(currentOrganization, params);
});

export const createPaymentTerm = createEffect(
  (paymentTermData: PaymentTermDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createPaymentTermCommand: CreatePaymentTermCommandValues = {
      ...paymentTermData,
    };

    return createPaymentTermRequest(
      currentOrganization!,
      createPaymentTermCommand,
    );
  },
);

export const getPaymentTerm = createEffect(
  (paymentTermParams: GetPaymentTermParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getPaymentTermRequest(
      currentOrganization as LinkResourceBaseDto,
      paymentTermParams,
    );
  },
);

export const updatePaymentTerm = createEffect((paymentTerm: PaymentTermDto) => {
  const updatePaymentTermCommand: UpdatePaymentTermCommandValues = {
    ...paymentTerm,
  };
  return updatePaymentTermRequest(paymentTerm, updatePaymentTermCommand);
});

export const deletePaymentTerm = createEffect((paymentTerm: PaymentTermDto) => {
  return deletePaymentTermRequest(paymentTerm);
});

export const downloadQuickBooksPaymentTerms = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return downloadQuickBooksPaymentTermsRequest(currentOrganization);
});

const defaultState: PaymentTermsStoreState = {
  links: [],
  paymentTermColumns: [
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'netDueDays',
      visible: true,
      title: 'Due Days',
      sortName: 'netDueDays',
    },
    {
      name: 'discountPercentage',
      visible: true,
      title: 'Discount Percentage',
      sortName: 'discountPercentage',
    },
    {
      name: 'discountPaidWithinDays',
      visible: true,
      title: 'Discount',
      sortName: 'discountPaidWithinDays',
    },
  ],
};

export const paymentTermStore: Store<PaymentTermsStoreState> = createStore(
  defaultState,
).on(updatePaymentTermsColumns, (state, payload) => {
  return { ...state, paymentTermColumns: payload };
});
