import React, { useEffect, useState } from 'react';
import { CountryDtoPagedResult } from '../../../models/data.models';
import {
  ErrorMessage,
  Field,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { FormContext } from '../../common/components/form/form.component';
import { SelectEntityAsyncPaginate } from '../../common/components/react-select-entity/react-select-entity';
import { showDialog } from '../../common/dialog.store';
import { getCountries, getCountry } from '../countries.store';
import {
  COUNTRY_ENTITY_NAME,
  CREATE_COUNTRY_LINK_KEY,
  GET_COUNTRY_LINK_KEY,
} from '../countries.service';
import { CountryDialog } from './country.dialog';
import { userHas } from '../../auth/auth.store';
import { clearMessages } from '../../common/messages.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useStore } from 'effector-react';
import {
  clearCountriesLoadOptionsCache,
  countryReactSelectStore,
  updateLastEditedCountry,
} from './country-react-select.store';

export const SelectCountryPaginate = ({
  id = 'countryCode',
  nameId = 'name',
  required = false,
  limit = 10,
  useContext = true,
  placeholder = '',
  header = '',
  selectedFilter = '',
  selectedSort = '',
  selectedSearchKey = '',
  selectedValue = null,
  onChangeCountry = (option, context) => {},
  isMulti = false,
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  readonly = false,
  classNamePrefix = 'select',
  showCreateDialogTitle = 'Create Country',
}) => {
  const context = useContext ? useFormikContext() : null;
  const [value, setValue] = useState(null);
  const [valueIsLoaded, setValueIsLoaded] = useState(false);
  useEffect(() => {
    setValue(selectedValue);
    setValueIsLoaded(true);
  }, [selectedValue]);

  const [sort, setSort] = useState(null);
  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  const [searchKey, setSearchKey] = useState(null);
  useEffect(() => {
    setSearchKey(selectedSearchKey);
  }, [selectedSearchKey]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseCountryDtoPagedResult: CountryDtoPagedResult = await getCountries(
      {
        limit: 10,
        offset: (page - 1) * 10,
        sort,
        filter,
        search: searchQuery,
      },
    );

    return {
      options: responseCountryDtoPagedResult.items,
      hasMore: responseCountryDtoPagedResult.items.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const onChange = (option, contextDto = null) => {
    if (typeof onChangeCountry === 'function') {
      onChangeCountry(option, contextDto);
    }
  };

  const getContextValue = (
    context: FormikProps<FormikValues>,
    field: string,
  ) => {
    const changedValues = Object.entries(context.values).find(
      ([key, value]) => key === field,
    );
    if (changedValues && changedValues[1]) {
      return changedValues[1];
    }
    return null;
  };
  const onChangeWithContext = (country, context) => {
    onChange(country, context);
    if (context) {
      if (isMulti === true) {
        if (country?.length > 0) {
          context.setFieldValue(id, country?.length);
          context.setFieldError(id, undefined);
        } else {
          if (required) {
            context.setFieldValue(id, '');
          }
        }
      } else {
        context.setFieldValue(id, country?.countryCode);
        context.setFieldValue(nameId, country?.name);
        context.setFieldError(id, undefined);
        if (required && !country) {
          context.setFieldValue(id, '');
        }
      }
    }
  };

  const { clearLoadOptionsCache, lastEditedCountry } = useStore(
    countryReactSelectStore,
  );

  const onCountryEditIconClick = () => {
    if (value?.countryCode) {
      showDialog(
        {dialog: CountryDialog, props: {
          countryCode: value.countryCode,
          title: 'Update Country',
        }},
      ).then(
        async (country) => {
          if (country !== null) {
            clearCountriesLoadOptionsCache();
            updateLastEditedCountry(
              await getCountry({
                countryCode: value.countryCode || null,
              }),
            );
          }
        },
        () => {},
      );
    }
  };

  useEffect(() => {
    if (
      valueIsLoaded &&
      lastEditedCountry &&
      value?.countryCode &&
      lastEditedCountry.countryCode === value.countryCode
    ) {
      setValue(lastEditedCountry);
    }
  }, [lastEditedCountry, valueIsLoaded]);

  useEffect(() => {
    if (!selectedValue)
      getCountries({ filter: 'countryCode: US', limit: 1 }).then(
        (result) => {
          if (result.items.length > 0) {
            if (useContext) {
              onChangeWithContext(result.items[0], context);
            } else {
              onChange(result.items[0]);
            }
          }
        },
        () => {},
      );
  }, []);
  if (useContext) {
    return (
      <FormContext.Consumer>
        {(context) => (
          <div className="form-group">
            <label className="input-label" htmlFor={id}>
              <span className="d-flex justify-content-between align-items-center">
                <span className="input-label-primary">{header}</span>
                {!isMulti && value && userHas(GET_COUNTRY_LINK_KEY) ? (
                  <div
                    className="menu-icon-wrapper d-flex icon-edit-entity"
                    onClick={onCountryEditIconClick}
                    data-cy={`country-edit-icon`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </div>
                ) : null}
              </span>
            </label>
            <Field
              required={required}
              type="hidden"
              name={id}
              value={getContextValue(context, id)}
            />
            <SelectEntityAsyncPaginate
              className={id}
              entityName={COUNTRY_ENTITY_NAME}
              canCreateNewEntity={userHas(CREATE_COUNTRY_LINK_KEY)}
              onCreateNewEntity={() => {
                return showDialog(
                  {dialog: CountryDialog, props: {
                    title: showCreateDialogTitle,
                    countryCode: '',
                  }},
                ).then(
                  (country) => {
                    clearCountriesLoadOptionsCache();
                    return country;
                  },
                  () => {},
                );
              }}
              key={filter}
              value={value}
              loadOptions={loadOptions}
              getOptionValue={(option) => option?.countryCode}
              getOptionLabel={(option) => `${option?.name}`}
              onChange={(country?: any) =>
                onChangeWithContext(country, context)
              }
              onFocus={clearMessages}
              onBlur={() => context.setFieldTouched(id, true)}
              isDisabled={isDisabled}
              isClearable={readonly ? false : isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              isSearchable={readonly ? false : isSearchable}
              placeholder={placeholder}
              menuIsOpen={readonly ? false : undefined}
              classNamePrefix={classNamePrefix}
              components={{
                IndicatorSeparator: () => null,
              }}
              additional={{
                page: 1,
              }}
              cacheUniqs={[clearLoadOptionsCache]}
            />
            <ErrorMessage
              name={id}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </FormContext.Consumer>
    );
  } else {
    return (
      <SelectEntityAsyncPaginate
        className={id}
        entityName={COUNTRY_ENTITY_NAME}
        canCreateNewEntity={userHas(CREATE_COUNTRY_LINK_KEY)}
        onCreateNewEntity={() => {
          return showDialog(
            {dialog: CountryDialog, props: {
              title: showCreateDialogTitle,
              countryCode: '',
            }},
          ).then(
            (country) => {
              clearCountriesLoadOptionsCache();
              return country;
            },
            () => {},
          );
        }}
        key={filter}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={(option) => option?.countryCode}
        getOptionLabel={(option) => `${option?.name}`}
        onChange={(country?: any) => {
          onChange(country);
        }}
        onFocus={clearMessages}
        classNamePrefix={classNamePrefix}
        menuIsOpen={readonly ? false : undefined}
        isDisabled={isDisabled}
        isClearable={readonly ? false : isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={readonly ? false : isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        additional={{
          page: 1,
        }}
        cacheUniqs={[clearLoadOptionsCache]}
      />
    );
  }
};
