import React, { CSSProperties } from 'react';

export type PanelProps = {
  className?: string;
  style?: CSSProperties;
  children: any;
};

export const Panel = ({ children, style = {}, className = '' }: PanelProps) => {
  return (
    <div className={`card ${className}`} style={style}>
      <div className="card-body login-register-card-body">{children}</div>
    </div>
  );
};
