import React from 'react';
import { useStore } from 'effector-react';
import { organizationsStore } from '../organization.store';
import { generatePath, Link } from 'react-router-dom';
import { ActionItem } from '../../common/components/action-item/action-item.component';
import { INTERNAL_PATH } from '../../../app.router';
import { GET_ORGANIZATION_LINK_KEY } from '../organization.service';
import { ORGANIZATION_EDIT_PATH } from '../organization.route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckLoading } from '@fortawesome/free-solid-svg-icons';

export const OrganizationMenu = ({ className }) => {
  const { currentOrganization } = useStore(organizationsStore);
  return (
    <ActionItem
      context={currentOrganization}
      actionKey={GET_ORGANIZATION_LINK_KEY}
      render={({ available }) => {
        if (available) {
          return (
            <li className={className}>
              <Link
                to={generatePath(INTERNAL_PATH + ORGANIZATION_EDIT_PATH, {
                  organizationId: currentOrganization?.organizationId,
                })}
                className="nav-link active nav-icon-size-medium"
                data-cy="link-organization"
              >
                <div className="menu-icon-wrapper d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faTruckLoading} />
                </div>
                <div className="nav-link-text">Organization</div>
              </Link>
            </li>
          );
        }
        return null;
      }}
    />
  );
};
