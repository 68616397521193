import {
  DivisionDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateDivisionCommandValues,
  UpdateDivisionCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createDivisionRequest,
  getDivisionsListRequest,
  getDivisionRequest,
  deleteDivisionRequest,
  updateDivisionRequest,
  GetDivisionParams,
} from './divisions.service';

type DivisionsStoreState = {
  links: LinkDto[];
  divisionColumns: Column[];
};

export const updateDivisionsColumns = createEvent<Column[]>();

export const getDivisions = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getDivisionsListRequest(currentOrganization, params);
});

export const createDivision = createEffect((divisionData: DivisionDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createDivisionCommand: CreateDivisionCommandValues = {
    ...divisionData,
  };

  return createDivisionRequest(currentOrganization!, createDivisionCommand);
});

export const getDivision = createEffect((divisionParams: GetDivisionParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getDivisionRequest(
    currentOrganization as LinkResourceBaseDto,
    divisionParams,
  );
});

export const updateDivision = createEffect((division: DivisionDto) => {
  const updateDivisionCommand: UpdateDivisionCommandValues = { ...division };
  return updateDivisionRequest(division, updateDivisionCommand);
});

export const deleteDivision = createEffect((division: DivisionDto) => {
  return deleteDivisionRequest(division);
});

const defaultState: DivisionsStoreState = {
  links: [],
  divisionColumns: [
    {
      name: 'divisionId',
      visible: true,
      title: 'Division Id',
      sortName: 'divisionId',
    },
    {
      name: 'divisionName',
      visible: true,
      title: 'Division Name',
      sortName: 'divisionName',
    },
    { name: 'email', visible: true, title: 'Email', sortName: 'email' },
    {
      name: 'faxNumber',
      visible: true,
      title: 'Fax Number',
      sortName: 'faxNumber',
    },
    {
      name: 'phoneNumber',
      visible: true,
      title: 'Phone Number',
      sortName: 'phoneNumber',
    },
    {
      name: 'comments',
      visible: true,
      title: 'Comments',
      sortName: 'comments',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
    { name: 'created', visible: false, title: 'Created', sortName: 'created' },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
  ],
};

export const divisionStore: Store<DivisionsStoreState> = createStore(
  defaultState,
).on(updateDivisionsColumns, (state, payload) => {
  return { ...state, divisionColumns: payload };
});
