import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { AttachmentForm } from './attachment.form';
import { AttachmentDto } from '../../../models/data.models';
import { getAttachment, updateAttachment } from '../attachments.store';
import { Panel } from '../../common/components/panel/panel.component';

export type AttachmentEditProps = {
  attachmentId?: number | null;
  onAttachmentCreated?: (attachment: AttachmentDto) => void;
  onAttachmentUpdated?: (attachment: AttachmentDto) => void;
  onAttachmentLoaded?: (attachment: AttachmentDto) => void;
};

const initialState: AttachmentDto = {
  attachmentId: 0,
  attachmentType: null,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: null,
  fileName: '',
  fileUri: '',
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  organizationId: 0,
  parentId: 0,
  parentType: null,
  previewUri: null,
  thumbnailUri: null,
  createdByUserName: '',
  lastModifiedByUserName: '',
  presignedPreviewUri: null,
  presignedThumbnailUri: null,
  presignedFileUri: null,
  links: [],
};

export const AttachmentEdit = ({
  attachmentId,
  onAttachmentLoaded = () => {},
  onAttachmentCreated = () => {},
  onAttachmentUpdated = () => {},
}: AttachmentEditProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [attachment, setAttachment] = useState<AttachmentDto | null>(null);
  const [initialValues, setInitialValues] = useState<AttachmentDto | null>(
    initialState,
  );

  useEffect(() => {
    if (attachmentId) {
      getAttachment({ attachmentId }).then((attachmentDto: AttachmentDto) => {
        setAttachment(attachmentDto);
        setInitialValues(attachmentDto);
        setIsLoading(false);
        onAttachmentLoaded(attachmentDto);
      });
    } else {
      throw new Error('Attachment keys were not provided');
    }
  }, [attachmentId]);

  const onSubmit = (data: AttachmentDto) => {
    setIsSending(true);
    updateAttachment(data)
      .then((result) => {
        onAttachmentUpdated(result.data);
      })
      .finally(() => setIsSending(false));
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Panel className="m-4">
      <div className="row">
        <div className="col-4">
          <AttachmentForm initialValues={initialValues} onSubmit={onSubmit}>
            <AttachmentForm.Description />
            <Button
              name="save-attachment"
              type="submit"
              color="primary"
              className="btn-block"
              disabled={isSending}
              isSending={isSending}
            >
              Save Attachment
            </Button>
          </AttachmentForm>
        </div>
      </div>
    </Panel>
  );
};
