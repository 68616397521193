import {
  EventDefinitionDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateEventDefinitionCommandValues,
  UpdateEventDefinitionCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createEventDefinitionRequest,
  getEventDefinitionsListRequest,
  getEventDefinitionRequest,
  deleteEventDefinitionRequest,
  updateEventDefinitionRequest,
  GetEventDefinitionParams,
} from './eventDefinitions.service';

type EventDefinitionsStoreState = {
  links: LinkDto[];
  eventDefinitionColumns: Column[];
  defaultEventDefinition: EventDefinitionDto;
};

export const updateEventDefinitionsColumns = createEvent<Column[]>();

export const getEventDefinitions = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getEventDefinitionsListRequest(currentOrganization, params);
});

export const createEventDefinition = createEffect(
  (eventDefinitionData: EventDefinitionDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createEventDefinitionCommand: CreateEventDefinitionCommandValues = {
      automaticCreate: eventDefinitionData.automaticCreate,
      description: eventDefinitionData.description,
      location: eventDefinitionData.location,
      eventName: eventDefinitionData.eventName,
      isInactive: eventDefinitionData.isInactive,
      includeInTracking: eventDefinitionData.includeInTracking,
      sendEmail: eventDefinitionData.sendEmail,
    };

    return createEventDefinitionRequest(
      currentOrganization!,
      createEventDefinitionCommand,
    );
  },
);

export const getEventDefinition = createEffect(
  (eventDefinitionParams: GetEventDefinitionParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getEventDefinitionRequest(
      currentOrganization as LinkResourceBaseDto,
      eventDefinitionParams,
    );
  },
);

export const updateEventDefinition = createEffect(
  (eventDefinition: EventDefinitionDto) => {
    const updateEventDefinitionCommand: UpdateEventDefinitionCommandValues = {
      automaticCreate: eventDefinition.automaticCreate,
      description: eventDefinition.description,
      location: eventDefinition.location,
      eventName: eventDefinition.eventName,
      isInactive: eventDefinition.isInactive,
      includeInTracking: eventDefinition.includeInTracking,
      sendEmail: eventDefinition.sendEmail,
    };
    return updateEventDefinitionRequest(
      eventDefinition,
      updateEventDefinitionCommand,
    );
  },
);

export const deleteEventDefinition = createEffect(
  (eventDefinition: EventDefinitionDto) => {
    return deleteEventDefinitionRequest(eventDefinition);
  },
);

const defaultState: EventDefinitionsStoreState = {
  links: [],
  eventDefinitionColumns: [
    { name: 'eventName', visible: true, title: 'Name', sortName: 'eventName' },
    {
      name: 'location',
      visible: true,
      title: 'Location',
      sortName: 'location',
    },
    {
      name: 'description',
      visible: true,
      title: 'Details',
      sortName: 'description',
    },
    {
      name: 'includeInTracking',
      visible: true,
      title: 'Include in Tracking',
      sortName: 'includeInTracking',
    },
    {
      name: 'isInactive',
      visible: false,
      title: 'Is Inactive',
      sortName: 'isInactive',
    },
    {
      name: 'sendEmail',
      visible: false,
      title: 'Send in Email',
      sortName: 'sendEmail',
    },
    {
      name: 'automaticCreate',
      visible: true,
      title: 'Automatic Creation',
      sortName: 'automaticCreate',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'createdByUserName',
      visible: false,
      title: 'Created by',
      sortName: 'CreatedUser.UserName',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
    {
      name: 'lastModifiedByUserName',
      visible: false,
      title: 'Last Modified by',
      sortName: 'UpdatedUser.UserName',
    },
  ],
  defaultEventDefinition: null,
};

export const eventDefinitionStore: Store<EventDefinitionsStoreState> = createStore(
  defaultState,
).on(updateEventDefinitionsColumns, (state, payload) => {
  return { ...state, eventDefinitionColumns: payload };
});
