import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { ORGANIZATION_LIST_PATH } from '../organization.route';
import { OrganizationEdit } from '../components/organization-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';

export type OrganizationScreenProps = { pageTitle: string };

export const OrganizationScreen = ({ pageTitle }: OrganizationScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId } = useParams();

  const onOrganizationCreated = () => {
    const path = generatePath(INTERNAL_PATH + ORGANIZATION_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'organization-created',
      type: 'success',
      autoHide: true,
      message: 'Organization successfully created!',
    };
    history.push(path);
    addMessage(message);
  };

  const onOrganizationUpdated = () => {
    const message: Message = {
      id: 'organization-updated',
      type: 'success',
      autoHide: true,
      message: 'Organization successfully updated!',
    };
    addMessage(message);
  };
  return (
    <InternalLayout title={'Organizations'}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OrganizationEdit
        organizationId={organizationId}
        onOrganizationUpdated={onOrganizationUpdated}
        onOrganizationCreated={onOrganizationCreated}
      />
    </InternalLayout>
  );
};
