import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { EquipmentTypeScreen } from './screens/equipmentType.screen';
import { EquipmentTypeListScreen } from './screens/equipmentType-list.screen';

export const EQUIPMENTTYPE_LIST_PATH = '/equipment-types';
export const EQUIPMENTTYPE_EDIT_PATH = '/equipment-types/:equipmentTypeId';
export const EQUIPMENTTYPE_CREATE_PATH = '/equipment-types/0';
export const EQUIPMENTTYPES_PATH = '/org/:organizationId/equipment-types';

export const EquipmentTypeRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${EQUIPMENTTYPE_LIST_PATH}`}>
          <EquipmentTypeListScreen pageTitle="Equipment Types" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${EQUIPMENTTYPE_CREATE_PATH}`}>
          <EquipmentTypeScreen pageTitle="Add New Equipment Type" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${EQUIPMENTTYPE_EDIT_PATH}`}>
          <EquipmentTypeScreen pageTitle="Update Equipment Type" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
