import {
  CommodityDto,
  LinkDto,
  LinkResourceBaseDto,
  CommodityDtoPagedResult,
  CreateCommodityCommandValues,
  UpdateCommodityCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_COMMODITIES_LINK_KEY = 'get-commodities';
export const CREATE_COMMODITY_LINK_KEY = 'create-commodity';
export const GET_COMMODITY_LINK_KEY = 'get-commodity';
export const UPDATE_COMMODITY_LINK_KEY = 'update-commodity';
export const DELETE_COMMODITY_LINK_KEY = 'delete-commodity';

export interface GetCommodityParams {
  commodity?: CommodityDto | null;
  commodityId: number | null;
}

export const getCommodityRequest = async (
  resource: LinkResourceBaseDto | null,
  { commodityId }: GetCommodityParams,
): Promise<CommodityDto> => {
  if (resource && commodityId) {
    const getCommodityLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_COMMODITY_LINK_KEY,
    );
    if (getCommodityLink) {
      const result = await execLink(getCommodityLink, { commodityId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_COMMODITY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getCommoditiesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<CommodityDtoPagedResult> => {
  if (resource) {
    const getCommoditiesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_COMMODITIES_LINK_KEY,
    );
    if (getCommoditiesListLink) {
      const result = await execLink(getCommoditiesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_COMMODITIES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createCommodityRequest = async (
  resource: LinkResourceBaseDto,
  commodity: CreateCommodityCommandValues,
) => {
  const createCommodityLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_COMMODITY_LINK_KEY,
  );
  if (createCommodityLink) {
    const result = await execLink(createCommodityLink, commodity);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_COMMODITY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateCommodityRequest = async (
  resource: LinkResourceBaseDto,
  commodity: UpdateCommodityCommandValues,
) => {
  const updateCommodityLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_COMMODITY_LINK_KEY,
  );
  if (updateCommodityLink) {
    const result = await execLink(updateCommodityLink, commodity);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_COMMODITY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteCommodityRequest = async (commodity: CommodityDto) => {
  const deleteCommodityLink = commodity.links?.find(
    (x: LinkDto) => x.rel === DELETE_COMMODITY_LINK_KEY,
  );
  if (deleteCommodityLink) {
    const result = await execLink(deleteCommodityLink, commodity);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_COMMODITY_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
