import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetCountriesQuery } from '../../../models/data.models';
import {
  STATE_CREATE_PATH,
  STATE_EDIT_PATH,
  STATES_LIST_PATH,
} from '../states.route';
import { StatesList } from '../components/states-list.component';
import { CREATE_STATE_LINK_KEY, GetStateParams } from '../states.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';

export type StatesListScreenProps = { pageTitle: string };

export const StatesListScreen = ({ pageTitle }: StatesListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    filter = '',
    sort = '',
    search = '',
  } = useQuery() as GetCountriesQuery;
  const history = useHistory();
  const createPath = generatePath(INTERNAL_PATH + STATE_CREATE_PATH, {
    organizationId,
  });

  const goToDetails = (stateParams: GetStateParams) => {
    const path = generatePath(INTERNAL_PATH + STATE_EDIT_PATH, {
      organizationId,
      ...stateParams,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + STATES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + STATES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };
  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + STATES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };
  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + STATES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };
  return (
    <InternalLayout
      title={'States'}
      createPath={userHas(CREATE_STATE_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <StatesList
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        filter={filter}
        onFilter={onFilter}
        search={search}
        onSearch={onSearch}
      />
    </InternalLayout>
  );
};
