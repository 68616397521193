import React from 'react';

export type PaginationProps = {
  offset?: number;
  limit?: number;
  total?: number;
  pagesToLeft?: number;
  pagesToRight?: number;
  goToPage?: (page: number) => void;
};

export const Pagination = ({
  offset = 0,
  limit = 20,
  total = 0,
  pagesToLeft = 5,
  pagesToRight = 5,
  goToPage = () => {},
}: PaginationProps) => {
  const changePage = (page: number) => {
    goToPage(page);
  };
  const lastPage = Math.ceil(total / limit) - 1;
  const pages = (() => {
    const pages = [];
    const currentPage = Math.ceil(offset / limit);
    const additionalPagesToRight =
      currentPage - pagesToLeft < 0 ? pagesToLeft - currentPage : 0;
    const additionalPagesToLeft =
      currentPage + pagesToRight > lastPage
        ? currentPage + pagesToRight - lastPage
        : 0;
    const start =
      currentPage - pagesToLeft - additionalPagesToLeft > 0
        ? currentPage - pagesToLeft - additionalPagesToLeft
        : 0;
    const end =
      currentPage + pagesToRight + additionalPagesToRight > lastPage
        ? lastPage
        : currentPage + pagesToRight + additionalPagesToRight;
    for (let index = start; index <= end; index++) {
      pages.push(index);
    }
    return pages;
  })();
  const leftPagesSkipped = pages[0] > 0;
  const showLeftDots = pages[0] > 1;
  const rightPagesSkipped = lastPage - pages[pages.length - 1] > 0;
  const showRightDots = lastPage - pages[pages.length - 1] > 1;
  return (
    <nav
      className="d-flex justify-content-center"
      aria-label="Page navigation example"
    >
      <ul className="pagination">
        <li className="page-item">
          <a
            className="page-link"
            aria-label="Previous"
            onClick={() => {
              let currentPage = Math.ceil(offset / limit);
              if (currentPage > 0) {
                changePage(--currentPage);
              }
            }}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {leftPagesSkipped && (
          <li key={0} className={`page-item`}>
            <a
              onClick={() => {
                changePage(0);
              }}
              className="page-link"
              href="javascript:void(0);"
            >
              {1}
            </a>
          </li>
        )}
        {showLeftDots && (
          <li key={'dots-left'} className={`page-item disabled`}>
            <a className="page-link" href="javascript:void(0);">
              &hellip;
            </a>
          </li>
        )}
        {pages.map((index) => (
          <li
            key={index}
            className={`page-item ${
              Math.ceil(offset / limit) === index ? 'active' : ''
            }`}
          >
            <a
              onClick={() => {
                changePage(index);
              }}
              className="page-link"
              href="javascript:void(0);"
            >
              {index + 1}
            </a>
          </li>
        ))}
        {showRightDots && (
          <li key={'dots-right'} className={`page-item disabled`}>
            <a className="page-link" href="javascript:void(0);">
              &hellip;
            </a>
          </li>
        )}
        {rightPagesSkipped && (
          <li key={lastPage} className={`page-item`}>
            <a
              onClick={() => {
                changePage(lastPage);
              }}
              className="page-link"
              href="javascript:void(0);"
            >
              {lastPage + 1}
            </a>
          </li>
        )}
        <li className="page-item">
          <a
            className="page-link"
            href="javascript:void(0);"
            aria-label="Next"
            onClick={() => {
              let currentPage = Math.ceil(offset / limit);
              const totalPages = Math.ceil(total / limit);
              if (currentPage < totalPages - 1) {
                changePage(++currentPage);
              }
            }}
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};
