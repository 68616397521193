import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { OrderTrackingForm } from './orderTracking.form';
import { FormContext } from '../../common/components/form/form.component';
import {
  OrderDto,
  RouteDto,
  TrackingEventDto,
} from '../../../models/data.models';
import * as Yup from 'yup';
import { OrderTrackingButton } from './orderTrackingButton.component';
import { OrderTrackingRoute } from './orderTrackingRoute.component';
import { showDialog } from '../../common/dialog.store';
import { OrderTrackingMapDialog } from './orderTrackingMap.dialog';
import { getLocationName } from '../../../utils/helper.utils';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { useGeolocated } from 'react-geolocated';
import { NOT_FOUND_PATH } from '../../notFound/not-found.route';
import { Redirect, useParams } from 'react-router-dom';
import { OrderTrackingEventCard } from './orderTrackingEventCard.component';
import { OrderTrackingEventsList } from './orderTrackingEvents-list.component';
import { getOrderTrackingAnonymous } from '../orderTrackings.service';
import {
  getOrderAnonymous,
  updateOrderAnonymous,
} from '../../orders/orders.service';

export type OrderTrackingProps = {
  orderTrackingId?: string;
};

export const orderTrackingInitialState = {
  eventType: '',
  eventTypeId: null,
  locationName: '',
  locationLat: null,
  locationLng: null,
  details: '',
};

const orderTrackingEventSchema = Yup.object().shape({
  eventType: Yup.string().required("Can't be blank").nullable(true),
  locationName: Yup.string().required("Can't be blank").nullable(true),
});

export const OrderTracking = ({ orderTrackingId }: OrderTrackingProps) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isWrongGuid, setIsWrongGuid] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState(orderTrackingInitialState);
  const [trackingEvents, setTrackingEvents] = useState<
    TrackingEventDto[] | null
  >(null);
  const [order, setOrder] = useState<OrderDto | null>(null);
  const [origins, setOrigins] = useState<RouteDto[] | null>(null);
  const [destinations, setDestinations] = useState<RouteDto[] | null>(null);
  const [status, setStatus] = useState<string>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const { organizationId } = useParams<{ organizationId: string }>();

  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth < 769 ? true : false);
  });

  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
  });

  const setInitialValuesAsync = (oldInitialValues) => {
    return getLocationName(coords.latitude, coords.longitude).then(
      (locationName) => {
        oldInitialValues.locationName = locationName;
        oldInitialValues.locationLat = coords.latitude;
        oldInitialValues.locationLng = coords.longitude;
        return { ...oldInitialValues };
      },
    );
  };

  useEffect(() => {
    setIsMobile(window.innerWidth < 769 ? true : false);
  }, []);

  useEffect(() => {
    if (coords) {
      setInitialValuesAsync(initialValues).then((newInitialValues) => {
        setInitialValues(newInitialValues);
      });
    }
  }, [coords]);

  const getOrderTrackingEventsData = () => {
    getOrderTrackingAnonymous(organizationId, orderTrackingId)
      .then((orderTrackingDto) => {
        if (orderTrackingDto) {
          getOrderAnonymous(organizationId, orderTrackingDto.orderId).then(
            (orderDto) => {
              const shipperIndexes: number[] = orderTrackingDto.customValues[
                'routes'
              ]
                .split(',')
                .filter((route: string) => route.includes('Shipper'))
                .map((route: string) =>
                  parseInt(route.replace('Shipper ', '')),
                );
              const consigneeIndexes: number[] = orderTrackingDto.customValues[
                'routes'
              ]
                .split(',')
                .filter((route: string) => route.includes('Consignee'))
                .map((route: string) =>
                  parseInt(route.replace('Consignee ', '')),
                );

              const origins: RouteDto[] = [];
              const destinations: RouteDto[] = [];

              shipperIndexes.map((shipperIndex) => {
                const pickup = orderDto.orderPickups[shipperIndex - 1];
                const route: RouteDto = {
                  address: `${pickup.shipperAddressName}, ${pickup.shipperStateCode}, ${pickup.shipperCountryCode}`,
                  date: getFormattedDate(pickup.pickDate, false),
                };
                origins.push(route);
              });
              consigneeIndexes.map((consigneeIndex) => {
                const delivery = orderDto.orderDeliveries[consigneeIndex - 1];
                const route: RouteDto = {
                  address: `${delivery.consigneeAddressName}, ${delivery.consigneeStateCode}, ${delivery.consigneeCountryCode}`,
                  date: getFormattedDate(delivery.deliveryDate, false),
                };
                destinations.push(route);
              });

              orderDto.trackingEvents?.map((trackingEvent) => {
                trackingEvent.description =
                  trackingEvent.description == ''
                    ? '-'
                    : trackingEvent.description;
              });

              const trackingEvents = orderDto.trackingEvents
                .sort((a, b) => a.trackingEventId - b.trackingEventId)
                .filter((trackingEvent) => {
                  return trackingEvent.includeInTracking;
                });
              setStatus(
                trackingEvents[trackingEvents?.length - 1]?.eventName ??
                  'Pending',
              );

              setTrackingEvents(trackingEvents);
              setOrder(orderDto);
              setOrigins(origins);
              setDestinations(destinations);
              setIsLoading(false);
            },
          );
        }
      })
      .catch(() => {
        setIsWrongGuid(true);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getOrderTrackingEventsData();
  }, []);

  const onSubmit = (data: any) => {
    const trackingEventDto: TrackingEventDto = {
      trackingEventId: 0,
      description: data.details,
      location: data.locationName,
      eventDate: new Date(),
      eventDefinitionId: data.eventTypeId,
      eventName: data.eventType,
      isInactive: false,
      includeInTracking: true,
      sendEmail: false,
    };

    const orderDto: OrderDto = order;
    orderDto.trackingEvents.push(trackingEventDto);

    setIsSending(true);
    updateOrderAnonymous(organizationId, orderDto)
      .then(
        (result) => {
          addMessage({
            message: `Order is successfully updated with new tracking event`,
            type: 'success',
            id: v4(),
          });
          getOrderTrackingEventsData();
        },
        () => {},
      )
      .finally(() => setIsSending(false));
  };

  const onLocate = () => {
    showDialog({
      dialog: OrderTrackingMapDialog,
      props: {
        title: 'Order Tracking Map',
        lat: initialValues?.locationLat,
        lng: initialValues?.locationLng,
        coords,
        isGeolocationAvailable,
        isGeolocationEnabled,
        isMobile,
      },
    }).then((data) => {
      setInitialValues((oldInitialValues) => {
        if (data) {
          oldInitialValues.locationName = data.locationName;
          oldInitialValues.locationLat = data.lat;
          oldInitialValues.locationLng = data.lng;
        }
        return { ...oldInitialValues };
      });
    });
  };

  return isWrongGuid ? (
    <Redirect to={NOT_FOUND_PATH} />
  ) : !isMobile ? (
    <div className="order-tracking-wrapper">
      <OrderTrackingForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        id={'OrderTrackingForm'}
        validationSchema={orderTrackingEventSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <>
              <div className="order-tracking-header">
                <img className="logo-seahorse-order-tracking" />
              </div>
              <div className="order-tracking-container">
                <div className="container d-flex justify-content-center flex-column">
                  <div className="w-100 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="order-tracking-title">
                        {`Load Tracking`}
                      </div>
                      <div className={`order-tracking-status`}>{status}</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <OrderTrackingButton type={'call'} />
                      <OrderTrackingButton type={'email'} />
                    </div>
                  </div>

                  {isLoading ? (
                    <h1 className="m-4 font-weight-normal text-center">
                      Loading...
                    </h1>
                  ) : (
                    <div className="routes w-100 d-flex align-items-stretch">
                      <div className="origins">
                        {origins?.map((origin) => {
                          return (
                            <OrderTrackingRoute
                              type={'origin'}
                              route={origin}
                            />
                          );
                        })}
                      </div>
                      <div className="destinations">
                        {destinations?.map((destination) => {
                          return (
                            <OrderTrackingRoute
                              type={'destination'}
                              route={destination}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div className="event-details d-flex align-items-start justify-content-between">
                    <div className="event-form">
                      <div className={'col-12 pl-0'}>
                        <OrderTrackingForm.EventType
                          defaultValue={
                            initialValues?.eventType &&
                            initialValues?.eventTypeId
                              ? {
                                  eventDefinitionId: initialValues?.eventTypeId,
                                  eventName: initialValues?.eventType,
                                }
                              : ''
                          }
                          onChange={(value) =>
                            setInitialValues((oldInitialValues) => {
                              oldInitialValues.eventType = value?.eventName;
                              oldInitialValues.eventTypeId =
                                value?.eventDefinitionId;
                              oldInitialValues.details =
                                value?.description ?? '';
                              return { ...oldInitialValues };
                            })
                          }
                          organizationId={organizationId}
                        />
                      </div>
                      <div className={'col-12 mt-5 pl-0'}>
                        <OrderTrackingForm.Location
                          defaultValue={initialValues?.locationName}
                          onChange={(e) => {
                            setInitialValues((oldInitialValues) => {
                              oldInitialValues.locationName = e.target.value;
                              return { ...oldInitialValues };
                            });
                          }}
                          onLocate={onLocate}
                        />
                      </div>
                      <div className={'col-12 mt-5 pl-0'}>
                        <OrderTrackingForm.Details
                          onChange={(e) => {
                            setInitialValues((oldInitialValues) => {
                              oldInitialValues.details = e.target.value;
                              return { ...oldInitialValues };
                            });
                          }}
                        />
                      </div>
                      <div className="justify-content-start row mx-0 mt-4 col-6 pl-0">
                        <Button
                          type="submit"
                          form="OrderTrackingForm"
                          color="primary"
                          className="btn-block"
                          disabled={isSending}
                          isSending={isSending}
                        >
                          Send Tracking Update
                        </Button>
                      </div>
                    </div>
                    <div className="event-history-grid">
                      <div className="d-flex flex-column align-items-start">
                        <div className="pickup-title mb-5">Event History</div>
                        <OrderTrackingEventsList data={trackingEvents} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </FormContext.Consumer>
      </OrderTrackingForm>
    </div>
  ) : (
    <div className="order-tracking-wrapper">
      <OrderTrackingForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        id={'OrderTrackingForm'}
        validationSchema={orderTrackingEventSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <>
              <div className="order-tracking-header">
                <img className="logo-seahorse-order-tracking-mobile" />
              </div>
              <div className="order-tracking-container-mobile">
                <div className="container d-flex justify-content-center flex-column">
                  <div className="w-100 d-flex justify-content-between mb-5">
                    <div className="d-flex align-items-start flex-column">
                      <div className="order-tracking-title-mobile">
                        Load Tracking
                      </div>
                      <div
                        className={`order-tracking-status order-tracking-status-${status
                          ?.toLowerCase()
                          .replace(' ', '-')}`}
                      >
                        {status}
                      </div>
                    </div>
                  </div>

                  <div className="event-form">
                    <div className={'col-12 px-0'}>
                      <OrderTrackingForm.EventType
                        defaultValue={
                          initialValues?.eventType && initialValues?.eventTypeId
                            ? {
                                eventDefinitionId: initialValues?.eventTypeId,
                                eventName: initialValues?.eventType,
                              }
                            : ''
                        }
                        onChange={(value) =>
                          setInitialValues((oldInitialValues) => {
                            oldInitialValues.eventType = value?.eventName;
                            oldInitialValues.eventTypeId =
                              value?.eventDefinitionId;
                            return { ...oldInitialValues };
                          })
                        }
                        organizationId={organizationId}
                      />
                    </div>
                    <div className={'col-12 mt-5 px-0'}>
                      <OrderTrackingForm.Location
                        defaultValue={initialValues?.locationName}
                        onChange={(e) => {
                          setInitialValues((oldInitialValues) => {
                            oldInitialValues.locationName = e.target.value;
                            return { ...oldInitialValues };
                          });
                        }}
                        onLocate={onLocate}
                      />
                    </div>
                    <div className={'col-12 mt-5 px-0'}>
                      <OrderTrackingForm.Details
                        onChange={(e) => {
                          setInitialValues((oldInitialValues) => {
                            oldInitialValues.details = e.target.value;
                            return { ...oldInitialValues };
                          });
                        }}
                      />
                    </div>
                    <div className="justify-content-start row mx-0 mt-4 col-12 px-0">
                      <Button
                        type="submit"
                        form="OrderTrackingForm"
                        color="primary"
                        className="btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Send Tracking Update
                      </Button>
                    </div>
                  </div>

                  <div className="d-flex flex-column align-items-center mt-8">
                    <OrderTrackingButton
                      type={'call'}
                      className={
                        'w-100 call-mobile justify-content-center mb-3'
                      }
                    />
                    <OrderTrackingButton
                      type={'email'}
                      className={'w-100 justify-content-center'}
                    />
                  </div>

                  {isLoading ? (
                    <h1 className="m-4 font-weight-normal text-center">
                      Loading...
                    </h1>
                  ) : (
                    <div className="routes-mobile w-100 d-flex flex-column align-items-stretch">
                      <div className="origins-mobile">
                        {origins?.map((origin) => {
                          return (
                            <OrderTrackingRoute
                              type={'origin'}
                              route={origin}
                            />
                          );
                        })}
                      </div>
                      <div className="destinations-mobile">
                        {destinations?.map((destination) => {
                          return (
                            <OrderTrackingRoute
                              type={'destination'}
                              route={destination}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div className="d-flex flex-column align-items-start">
                    <div className="pickup-title-mobile mb-5">
                      Event History
                    </div>
                    {trackingEvents?.map((trackingEvent) => (
                      <OrderTrackingEventCard trackingEvent={trackingEvent} />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </FormContext.Consumer>
      </OrderTrackingForm>
    </div>
  );
};
