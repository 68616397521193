import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto, PackageCategoryDto } from '../../../models/data.models';
import { GenerateInvoice } from './generateInvoice.component';

export type ReceiveCarrierPayDialogProperties = {
  title: string;
  className?: string;
  orderId: number;
  customerId: number;
  transactionNumber: string;
  onInvoiceGenerated: () => void;
};

export class GenerateInvoiceDialog extends Dialog<ReceiveCarrierPayDialogProperties> {
  onInvoiceGenerated: () => void;

  constructor(props: ReceiveCarrierPayDialogProperties) {
    super(props);
    this.onInvoiceGenerated = props.onInvoiceGenerated.bind(this);
  }

  renderContent(): any {
    const { orderId, customerId, transactionNumber } = this
      .props as ReceiveCarrierPayDialogProperties;
    return (
      <GenerateInvoice
        orderId={orderId}
        customerId={customerId}
        onInvoiceGenerated={() => {
          this.onInvoiceGenerated();
          this.close();
        }}
        transactionNumber={transactionNumber}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
