import {
  AccountingAccountDto,
  LinkDto,
  LinkResourceBaseDto,
  AccountingAccountDtoPagedResult,
  CreateAccountingAccountCommandValues,
  UpdateAccountingAccountCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createAccountingAccountRequest,
  getAccountingAccountsListRequest,
  getAccountingAccountRequest,
  deleteAccountingAccountRequest,
  updateAccountingAccountRequest,
  GetAccountingAccountParams,
  downloadQuickBooksAccountingAccountsRequest,
} from './accountingAccounts.service';

type AccountingAccountsStoreState = {
  links: LinkDto[];
  accountingAccountColumns: Column[];
};

export const updateAccountingAccountsColumns = createEvent<Column[]>();

export const getAccountingAccounts = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getAccountingAccountsListRequest(currentOrganization, params);
});

export const createAccountingAccount = createEffect(
  (accountingAccountData: AccountingAccountDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');
    const createAccountingAccountCommand: CreateAccountingAccountCommandValues = {
      ...accountingAccountData,
    };

    return createAccountingAccountRequest(
      currentOrganization!,
      createAccountingAccountCommand,
    );
  },
);

export const getAccountingAccount = createEffect(
  (accountingAccountParams: GetAccountingAccountParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getAccountingAccountRequest(
      currentOrganization as LinkResourceBaseDto,
      accountingAccountParams,
    );
  },
);

export const updateAccountingAccount = createEffect(
  (accountingAccount: AccountingAccountDto) => {
    const updateAccountingAccountCommand: UpdateAccountingAccountCommandValues = {
      ...accountingAccount,
    };
    return updateAccountingAccountRequest(
      accountingAccount,
      updateAccountingAccountCommand,
    );
  },
);

export const deleteAccountingAccount = createEffect(
  (accountingAccount: AccountingAccountDto) => {
    return deleteAccountingAccountRequest(accountingAccount);
  },
);

export const downloadQuickBooksAccountingAccounts = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return downloadQuickBooksAccountingAccountsRequest(currentOrganization);
});

const defaultState: AccountingAccountsStoreState = {
  links: [],
  accountingAccountColumns: [
    {
      name: 'accountId',
      visible: true,
      title: 'Account Id',
      sortName: 'accountId',
    },
    {
      name: 'accountName',
      visible: true,
      title: 'Account Name',
      sortName: 'accountName',
    },
    {
      name: 'accountType',
      visible: true,
      title: 'Account Type',
      sortName: 'accountType',
    },
    {
      name: 'accountNumber',
      visible: true,
      title: 'Account Number',
      sortName: 'accountNumber',
    },
    {
      name: 'isInactive',
      visible: true,
      title: 'Is Inactive',
      sortName: 'isInactive',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
    { name: 'created', visible: false, title: 'Created', sortName: 'created' },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
  ],
};

export const accountingAccountStore: Store<AccountingAccountsStoreState> = createStore(
  defaultState,
).on(updateAccountingAccountsColumns, (state, payload) => {
  return { ...state, accountingAccountColumns: payload };
});
