import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { DivisionEdit } from '../components/division-edit.component';
import { DIVISION_CREATE_PATH, DIVISION_LIST_PATH } from '../division.route';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_DIVISION_LINK_KEY } from '../divisions.service';
import { userHas } from '../../auth/auth.store';

export type DivisionScreenProps = { pageTitle: string };

export const DivisionScreen = ({ pageTitle }: DivisionScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, divisionId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + DIVISION_CREATE_PATH, {
    organizationId,
  });

  const onDivisionCreated = () => {
    const path = generatePath(INTERNAL_PATH + DIVISION_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'division-created',
      type: 'success',
      autoHide: true,
      message: 'Division successfully created!',
    };
    history.push(path);
    addMessage(message);
  };

  const onDivisionUpdated = () => {
    const path = generatePath(INTERNAL_PATH + DIVISION_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'division-updated',
      type: 'success',
      autoHide: true,
      message: 'Division successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + DIVISION_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };

  return (
    <InternalLayout
      title={'Divisions'}
      createPath={userHas(CREATE_DIVISION_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <DivisionEdit
        divisionId={divisionId}
        onDivisionUpdated={onDivisionUpdated}
        onDivisionCreated={onDivisionCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
