import {
  AddressType,
  ContactAddressDto,
  ContactDto,
  ContactType,
  OrderDeliveryDto,
} from '../../../../models/data.models';
import { formatAddress } from '../../../../utils/helper.utils';
import { OrderForm } from '../order.form';
import React, { useEffect } from 'react';
import { getContactAddress } from '../../../contactAddresses/contactAddresses.store';

export type ConsigneeFormProps = {
  orderDeliveryDto: OrderDeliveryDto | null;
  index: number;
  allowDelete: boolean;
  onDeliveryCreated?: (orderDeliveryDto: OrderDeliveryDto) => void;
  onDeliveryUpdated?: (orderDeliveryDto: OrderDeliveryDto) => void;
  onDeliveryLoaded?: (orderDeliveryDto: OrderDeliveryDto) => void;
  onDeliveryDeleted?: () => void;
  onDeliveryNoteChange: (date?: any) => void;
  onDeliveryDateChange: (date?: any) => void;
  setDeliveryContact: (
    contactId: number,
    name: string,
    contactType: ContactType,
  ) => void;
  setDeliveryFilter: (filter: string) => void;
  setDeliveryContactAddress: (
    contactAddressId: number,
    addressLine: string,
  ) => void;
  deliveryFilter: string;
  deliveryContact: {
    contactId: number;
    name: string;
    contactType: ContactType;
  };
  deliveryContactAddress: { contactAddressId: string; addressLine: string };
  addressType?: AddressType;
};
export const ConsigneeForm = ({
  orderDeliveryDto,
  index,
  onDeliveryDateChange,
  onDeliveryNoteChange,
  setDeliveryContact,
  setDeliveryFilter,
  setDeliveryContactAddress,
  deliveryFilter,
  deliveryContact,
  deliveryContactAddress,
  allowDelete = false,
  onDeliveryDeleted,
  addressType = AddressType.Other,
}: ConsigneeFormProps) => {
  useEffect(() => {
    if (deliveryContactAddress.addressLine != null) {
      getContactAddress({
        contactAddressId: Number(deliveryContactAddress.contactAddressId),
      }).then((data) => {
        setDeliveryContactAddress(data?.contactAddressId, formatAddress(data));
      });
    }
  }, []);

  const isDisabledDeliveryContact = (): boolean => {
    if (
      deliveryFilter == null ||
      deliveryFilter === ' ' ||
      deliveryFilter === '' ||
      deliveryContact?.contactId == null
    ) {
      return true;
    }
    return false;
  };
  return (
    <div className="row">
      <div className="col-3">
        <OrderForm.ConsigneeCompany
          id={`orderDeliveries[${index}].consigneeContactId`}
          header={`Consignee ${index + 1}`}
          contactTypes={[
            ContactType.Customer,
            ContactType.Vendor,
            ContactType.ForwardingAgent,
            ContactType.Contact,
          ]}
          selectedFilter={`contactType: ${ContactType.Customer} OR contactType: ${ContactType.Vendor} OR contactType: ${ContactType.ForwardingAgent} OR contactType: ${ContactType.Contact}`}
          required={false}
          defaultValue={
            deliveryContact &&
            deliveryContact.contactId != null &&
            deliveryContact.name != null &&
            deliveryContact.contactType != null
              ? {
                  contactId: deliveryContact.contactId,
                  name: deliveryContact.name,
                  contactType: deliveryContact.contactType,
                }
              : ''
          }
          onChange={(data?: ContactDto, context?: any) => {
            setDeliveryContact(data?.contactId, data?.name, data?.contactType);
            setDeliveryFilter(
              data?.contactId ? `contactId:${data?.contactId}` : ' ',
            );
          }}
          nameId={`orderDeliveries[${index}].consigneeContactName`}
        />
      </div>
      <div className="col-3">
        <OrderForm.ConsigneeLocation
          selectedFilter={deliveryFilter}
          id={`orderDeliveries[${index}].consigneeAddressId`}
          header={'Location'}
          required={false}
          disabled={isDisabledDeliveryContact()}
          defaultValue={
            deliveryContactAddress &&
            deliveryContactAddress.contactAddressId != null &&
            deliveryContactAddress.addressLine != null
              ? {
                  ...deliveryContactAddress,
                  contactAddressId: deliveryContactAddress.contactAddressId,
                }
              : ''
          }
          onChange={(data?: ContactAddressDto) => {
            setDeliveryContactAddress(
              data?.contactAddressId,
              formatAddress(data),
            );
          }}
          nameId={`orderDeliveries[${index}].consigneeAddressName`}
          contactId={deliveryContact.contactId}
          addressType={addressType}
        />
      </div>
      <div className="col-3">
        <OrderForm.ConsigneeDate
          defaultValue={orderDeliveryDto?.deliveryDate}
          onChange={onDeliveryDateChange}
          name={`orderDeliveries[${index}].deliveryDate`}
          disabled={isDisabledDeliveryContact()}
        />
      </div>
      {allowDelete ? (
        <div className="col-3 d-flex">
          <div className={'w-100 mw-100 pr-3'}>
            <OrderForm.ConsigneeNotes
              name={`orderDeliveries[${index}].deliveryNotes`}
              disabled={isDisabledDeliveryContact()}
            />
          </div>
          <div className={'d-flex align-items-center pt-3'}>
            <div
              data-cy={`delete-consignee[${index}]`}
              onClick={(event) => {
                onDeliveryDeleted();
              }}
              color="primary"
              className="btn-close icon-close"
            />
          </div>
        </div>
      ) : (
        <div className="col-3 d-flex">
          <div className={'w-100'}>
            <OrderForm.ConsigneeNotes
              name={`orderDeliveries[${index}].deliveryNotes`}
              disabled={isDisabledDeliveryContact()}
            />
          </div>
        </div>
      )}
    </div>
  );
};
