import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { SalesTaxForm } from './salesTax.form';
import {
  ContactDto,
  ContactType,
  SalesTaxDto,
} from '../../../models/data.models';
import {
  createSalesTax,
  getSalesTax,
  updateSalesTax,
} from '../salesTaxes.store';
import { Panel } from '../../common/components/panel/panel.component';
import { SalesTaxesDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { userHas } from '../../auth/auth.store';
import { UPDATE_SALESTAX_LINK_KEY } from '../salesTaxes.service';
import { validatePositiveNumberInput } from '../../../utils/helper.utils';

export type SalesTaxEditProps = {
  salesTaxId?: number | null;
  onSalesTaxCreated?: (salesTax: SalesTaxDto) => void;
  onSalesTaxUpdated?: (salesTax: SalesTaxDto) => void;
  onSalesTaxLoaded?: (salesTax: SalesTaxDto) => void;
  onCancel?: () => void;
};

const initialState: SalesTaxDto = {
  createdByUserName: '',
  updatedByUserName: '',
  authorityContactName: SalesTaxesDefaultValues.authorityContactName,
  parentSalesTaxName: SalesTaxesDefaultValues.parentSalesTaxName,
  salesTaxId: 0,
  authorityContactId: SalesTaxesDefaultValues.authorityContactId,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: SalesTaxesDefaultValues.description,
  isGroupTax: SalesTaxesDefaultValues.isGroupTax,
  isInactive: SalesTaxesDefaultValues.isInactive,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  organizationId: 0,
  parentSalesTaxId: SalesTaxesDefaultValues.parentSalesTaxId,
  rate: SalesTaxesDefaultValues.rate,
  taxCode: SalesTaxesDefaultValues.taxCode,
  links: [],
};

const salesTaxesSchema = Yup.object().shape({
  taxCode: Yup.string()
    .required("Can't be blank")
    .max(3, 'Max length is 3')
    .nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
  rate: Yup.string()
    .required("Can't be blank")
    .transform((value) => (value === null ? '' : value))
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER &&
          Number(value) > Number.MIN_SAFE_INTEGER) ||
        value === '' ||
        value === undefined
      );
    })
    .test('length', 'Max value is 100', (value) => {
      return value === undefined || Number(value) <= 100;
    })
    .nullable(true),
});

export const SalesTaxEdit = ({
  salesTaxId,
  onSalesTaxLoaded = () => {},
  onSalesTaxCreated = () => {},
  onSalesTaxUpdated = () => {},
  onCancel = () => {},
}: SalesTaxEditProps) => {
  const isCreateMode = !salesTaxId || salesTaxId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isGroupTax, setIsGroupTax] = useState(false);
  const [salesTax, setSalesTax] = useState<SalesTaxDto | null>(null);
  const [initialValues, setInitialValues] = useState<SalesTaxDto | null>(
    initialState,
  );
  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (salesTaxId) {
      getSalesTax({ salesTaxId }).then(
        (salesTaxDto: SalesTaxDto) => {
          setSalesTax(salesTaxDto);
          setInitialValues(salesTaxDto);
          setIsLoading(false);
          onSalesTaxLoaded(salesTaxDto);
          setIsGroupTax(salesTaxDto?.isGroupTax);
        },
        () => {},
      );
    } else {
      throw new Error('SalesTax keys were not provided');
    }
  }, [salesTaxId]);

  const onSubmit = (data: SalesTaxDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createSalesTax(data)
        .then(
          (result) => {
            onSalesTaxCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateSalesTax(data)
        .then(
          (result) => {
            onSalesTaxUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  const onChangeGroupTax = (data?: any) => {
    setIsGroupTax(data);
  };
  return (
    <Tabs>
      <TabList>
        <Tab name="salesTax">Sales Tax</Tab>
      </TabList>
      <SalesTaxForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={ref}
        id={'sales-tax-form'}
        validationSchema={salesTaxesSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add Sales Taxes</h2>
            ) : (
              <h2>Update Sales Taxes</h2>
            )}
            <div className="row">
              <div className="col-4">
                {isCreateMode ? (
                  <SalesTaxForm.TaxCode />
                ) : (
                  <SalesTaxForm.TaxCode disabled={true} />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <SalesTaxForm.Description />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <SalesTaxForm.AuthorityContactSelect
                  id={'authorityContactId'}
                  header={'Tax Authority'}
                  contactTypes={[ContactType.Vendor]}
                  selectedFilter={`contactType: ${ContactType.Vendor}`}
                  required={false}
                  defaultValue={
                    salesTax &&
                    salesTax.authorityContactId != null &&
                    salesTax.authorityContactName != null
                      ? {
                          contactId: salesTax.authorityContactId,
                          name: salesTax.authorityContactName,
                          contactType: ContactType.Vendor,
                        }
                      : ''
                  }
                  onChange={(data?: ContactDto) => {
                    setSalesTax((salesTax) => {
                      if (!salesTax) {
                        salesTax = initialState;
                      }
                      salesTax.authorityContactId = data?.contactId;
                      salesTax.authorityContactName = data?.name;
                      return { ...salesTax };
                    });
                  }}
                  nameId={'authorityContactName'}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <SalesTaxForm.Rate onKeyDown={validatePositiveNumberInput} />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <SalesTaxForm.SalesTaxSelect
                  id={'parentSalesTaxId'}
                  header={'Parent Tax Code'}
                  selectedFilter={` `}
                  required={false}
                  defaultValue={
                    salesTax &&
                    salesTax.parentSalesTaxId != null &&
                    salesTax.parentSalesTaxName != null
                      ? {
                          salesTaxId: salesTax.parentSalesTaxId,
                          taxCode: salesTax.parentSalesTaxName,
                        }
                      : ''
                  }
                  onChange={(data?: SalesTaxDto) => {
                    setSalesTax((salesTaxDto) => {
                      if (!salesTaxDto) {
                        salesTaxDto = initialState;
                      }
                      salesTaxDto.parentSalesTaxId = data?.salesTaxId;
                      salesTaxDto.parentSalesTaxName = data?.taxCode;
                      return { ...salesTaxDto };
                    });
                  }}
                  nameId={'parentSalesTaxName'}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <SalesTaxForm.IsInactive />
              </div>
              <div className="col-2">
                <SalesTaxForm.IsGroupTax onChange={onChangeGroupTax} />
                {isGroupTax ? (
                  <div>Group Tax not implemented now!</div>
                ) : (
                  <div />
                )}
              </div>
            </div>
            <div className="justify-content-end row">
              {(userHas(UPDATE_SALESTAX_LINK_KEY, salesTax?.links) ||
                isCreateMode) && (
                <div className="col-3">
                  <Button
                    name="save-salesTax"
                    type="submit"
                    color="primary"
                    className="btn-block"
                    form={'sales-tax-form'}
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save Sales Tax
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </SalesTaxForm>
    </Tabs>
  );
};
