import {
  PortDto,
  LinkDto,
  LinkResourceBaseDto,
  PortDtoPagedResult,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_PORTS_LINK_KEY = 'get-ports';
export const CREATE_PORT_LINK_KEY = 'create-port';
export const GET_PORT_LINK_KEY = 'get-port';
export const UPDATE_PORT_LINK_KEY = 'update-port';
export const DELETE_PORT_LINK_KEY = 'delete-port';
export const PORT_ENTITY_NAME = 'Port';

export interface GetPortParams {
  portId: number;
}

export const getPortRequest = async (
  resource: LinkResourceBaseDto | null,
  { portId }: GetPortParams,
): Promise<PortDto> => {
  if (resource && portId) {
    const getPortLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PORT_LINK_KEY,
    );
    if (getPortLink) {
      const result = await execLink(getPortLink, { portId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PORT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getPortsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PortDtoPagedResult> => {
  if (resource) {
    const getPortsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PORTS_LINK_KEY,
    );
    if (getPortsListLink) {
      const result = await execLink(getPortsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PORTS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createPortRequest = async (
  resource: LinkResourceBaseDto,
  port: PortDto,
) => {
  const createPortLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PORT_LINK_KEY,
  );
  if (createPortLink) {
    const result = await execLink(createPortLink, port);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_PORT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updatePortRequest = async (port: PortDto) => {
  const updatePortLink = port.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PORT_LINK_KEY,
  );
  if (updatePortLink) {
    const result = await execLink(updatePortLink, port);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_PORT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deletePortRequest = async (port: PortDto) => {
  const deletePortLink = port.links?.find(
    (x: LinkDto) => x.rel === DELETE_PORT_LINK_KEY,
  );
  if (deletePortLink) {
    const result = await execLink(deletePortLink, port);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_PORT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
