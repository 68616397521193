import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ContactAddressScreen } from './screens/contactAddress.screen';
import { ContactAddressesListScreen } from './screens/contactAddresses-list.screen';

export const CONTACTS_ADDRESS_LIST_PATH = '/contact-address';
export const CONTACTS_ADDRESS_EDIT_PATH = '/contact-address/:contactAddressId';
export const CONTACTS_ADDRESS_CREATE_PATH = '/contact-address/0';
export const CONTACTS_ADDRESSES_PATH = '/org/:organizationId/contact-address';

export const ContactAddressRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${CONTACTS_ADDRESS_LIST_PATH}`}>
          <ContactAddressesListScreen pageTitle="Contact Addresses" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${CONTACTS_ADDRESS_CREATE_PATH}`}
        >
          <ContactAddressScreen pageTitle="Add New Contact Address" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CONTACTS_ADDRESS_EDIT_PATH}`}>
          <ContactAddressScreen pageTitle="Update Contact Address" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
