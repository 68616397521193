import { Dialog } from '../../common/components/dialog/dialog.component';
import { CommodityEdit } from './commodity-edit.component';
import { CommodityDto } from '../../../models/data.models';

export type CommodityDialogProperties = {
  commodityId?: number | null;
  commodity?: CommodityDto | null;
  title: string;
  className?: string;
  saveButtonRenderCondition?: boolean;
};

export class CommodityDialog extends Dialog<CommodityDialogProperties> {
  constructor(props: CommodityDialogProperties) {
    super(props);
    this.onCommoditySaved = this.onCommoditySaved.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onCommoditySaved = (commodity: CommodityDto) => {
    this.close(commodity);
  };

  onCancel = () => {
    this.close(null);
  };

  renderContent(): any {
    const { commodityId, commodity, saveButtonRenderCondition } = this
      .props as CommodityDialogProperties;
    return (
      <CommodityEdit
        defaultCommodity={commodity}
        commodityId={commodityId}
        onCommodityCreated={this.onCommoditySaved}
        onCommodityUpdated={this.onCommoditySaved}
        onCancel={this.onCancel}
        saveButtonRenderCondition={saveButtonRenderCondition}
      />
    );
  }
}
