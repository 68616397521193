import { Column } from '../models/data.models';

export const getSearchQuery = (filter: any, columns?: Column[]) => {
  const keys = Object.keys(filter);
  return keys
    .filter(
      (key) =>
        filter[key] !== '' && filter[key] !== null && !key.startsWith('__'),
    )
    .map((key) => {
      const column = columns?.find((c) => c.name === key);
      const value = filter[key];
      return `${column?.dataField ?? key}:${value}`;
    })
    .join(' AND ');
};

export const getAdditionalColumnsData = (filter: any) => {
  const additionalColumnsData = {};
  Object.keys(filter)
    .filter(
      (key) =>
        filter[key] !== '' && filter[key] !== null && key.startsWith('__'),
    )
    .map((key) => {
      const value = filter[key];
      return (additionalColumnsData[key] = value);
    });

  return additionalColumnsData;
};

export const parseSearchQuery = (query: string) => {
  const result = {};
  if (query) {
    const params = query.split('AND');
    params.forEach((param) => {
      let [key, value] = param.split(':');
      if (key && value) {
        key = key.trim();
        value = value.trim();
        const numberValue = Number(value);
        if (!isNaN(numberValue)) {
          return (result[key] = numberValue);
        }
        return (result[key] = value);
      }
    });
  }
  return result;
};

export const getReadableQueryString = (
  query: string,
  columns: Column[],
  addData?: any,
) => {
  if (query) {
    const filters = parseSearchQuery(query);
    const keys = Object.keys(filters).filter((key) =>
      columns?.some((col) => col?.name === key || col?.dataField === key),
    );
    return keys
      .map((key) => {
        const column = columns?.find(
          (col) => col?.name === key || col?.dataField === key,
        );
        if (column?.type === 'customField') {
          return addData[`__${key}`]
            ? `${column?.title}: ${addData[`__${key}`]}`
            : '';
        } else {
          switch (key) {
            case 'BillToContactId':
              return addData['__BillToContactName']
                ? `${column?.title}: ${addData['__BillToContactName']}`
                : '';
            default:
              return column?.title
                ? `${column?.title}: ${addData[`__${column?.name}`]}`
                : '';
          }
        }
      })
      .join(', ');
  }
  return '';
};
