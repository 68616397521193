import { Route, RouteProps, Switch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { UsersListScreen } from './screens/users-list.screen';
import { UserScreen } from './screens/user.screen';
import React from 'react';

export const USERS_LIST_PATH = '/users';
export const USER_EDIT_PATH = '/users/:userId';
export const USER_CREATE_PATH = '/users/0';

export const UsersRoute = (props: RouteProps) => {
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${USERS_LIST_PATH}`}>
          <UsersListScreen pageTitle="Users" />
        </PrivateRoute>
        <PrivateRoute exact path={`${USER_CREATE_PATH}`}>
          <UserScreen pageTitle="Add New User" />
        </PrivateRoute>
        <PrivateRoute exact path={`${USER_EDIT_PATH}`}>
          <UserScreen pageTitle="Update User" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
