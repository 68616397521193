import React, { CSSProperties } from 'react';

import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { BsListTask, BsThreeDotsVertical, BsFilter } from 'react-icons/bs';
import { Filters } from '../filters/filters.component';
import { Button } from '../button/button.component';
import { showDialog } from '../../../common/dialog.store';
import { ContactDialog } from '../../../contacts/components/contact.dialog';
import { Pagination } from '../pagination/pagination.component';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../../utils/query.utils';
import { CommodityStatus } from '../../../commodities/components/commodity-status.component';
import { ChargeStatus } from '../../../charges/components/charge-status.component';
import { Column } from '../../../../models/data.models';
import { getFormattedDate } from '../../../../utils/formatting.utils';

export type OrderTrackingsGridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: any[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onSelect?: (item: any) => void;
  getContactsData?: () => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  showAllFilters?: boolean;
  hideColumnsSelect?: boolean;
  isDropDownList?: boolean;
  actionBar?: JSX.Element | undefined;
  loading?: boolean;
  disableDots?: boolean;
  isTab?: boolean;
  parentId?: number;
  addButtonRenderCondition?: boolean;
};

export const OrderTrackingsGrid = ({
  data = [],
  columns = [],
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  style = {},
  showPagination = true,
  onDelete = () => {},
  onEdit = () => {},
  onPageChanged = () => {},
  rowKeys = ['id'],
  onSelect,
  getContactsData,
  showEmptyTable = false,
  showAllStore = true,
  loading,
  disableDots,
}: OrderTrackingsGridProps) => {
  const colByName: { [key: string]: any } = {};

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });

  return (
    <div className={`grid ${className}`} style={style}>
      {total > 0 || showEmptyTable === true ? (
        <div className="bg-white mx-3">
          <table className="table" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                {columns
                  .filter((col) => {
                    if (showAllStore && col.visible) {
                      return true;
                    }
                    return col.visible && rowKeys?.includes(col.name);
                  })
                  .map((col) => {
                    return (
                      <th scope="col" key={col.name}>
                        <a className={'inactive-link'}>{col.title}</a>
                      </th>
                    );
                  })}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="m-5 text-center">
                  <h3 className="text-muted mb-4">Loading...</h3>
                </div>
              ) : (
                data.map((row, index) => {
                  return (
                    <tr
                      key={
                        rowKeys ? rowKeys?.map((x) => row[x]).join('_') : index
                      }
                    >
                      {Object.values(columns)
                        .filter((item) => {
                          if (showAllStore && item.visible) {
                            return true;
                          }
                          return item.visible && rowKeys?.includes(item.name);
                        })
                        .map((item, index) => {
                          return (
                            <td
                              key={`${rowKeys?.map((x) => row[x]).join('_')}_${
                                item.name
                              }`}
                              onClick={() => {
                                if (onSelect) {
                                  onSelect(row);
                                }
                              }}
                              className={'cursor-pointer'}
                              style={{
                                width: `calc(100% / ${
                                  columns.filter(
                                    (column) => column.visible === true,
                                  ).length
                                })`,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {item.name == 'created' ? (
                                getFormattedDate(
                                  (row[item.name] as string)?.split(',')[0],
                                  false,
                                )
                              ) : !row[item.name] ? (
                                '-'
                              ) : (
                                <>{row[item.name]}</>
                              )}
                            </td>
                          );
                        })}
                      <td style={{ textAlign: 'center' }}>
                        <div className="dropdown dropdown-grid">
                          <BsThreeDotsVertical
                            className="dropdown-toggle dropdown-dots-vertical pointer"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          />
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            style={{ width: 'max-content' }}
                          >
                            {onDelete && (
                              <a
                                role="button"
                                className="dropdown-item"
                                onClick={() => {
                                  onDelete(row);
                                }}
                              >
                                Delete
                              </a>
                            )}
                            {onEdit && (
                              <a
                                role="button"
                                className="dropdown-item"
                                onClick={() => {
                                  onEdit(row);
                                }}
                              >
                                Edit
                              </a>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-4">Nothing Found</p>
      )}
      {total > limit && showPagination === true ? (
        <div className="mt-3 d-flex justify-content-center">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
          />
        </div>
      ) : null}
    </div>
  );
};
