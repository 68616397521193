import React, { useEffect, useState } from 'react';
import { initApi } from './modules/api/api.store';
import { getUserAdditionalData, getUserInfo } from './modules/auth/auth.store';
import { getOrganizations } from './modules/organization/organization.store';
import AppRouter from './app.router';
import { DialogsHolder } from './modules/common/components/dialogs-holder/dialogs-holder.component';
import { Messages } from './modules/common/components/messages/messages.component';
import { getCurrencies } from './modules/currencies/currencies.store';
import useJsScript, { GOOGLE_PLACES_SCRIPT } from './utils/useJsScript.utils';

export const App = () => {
  const [initialized, setInitialized] = useState(false);
  useJsScript(GOOGLE_PLACES_SCRIPT);
  useEffect(() => {
    initApi()
      .then((ignore) => {
        return getUserInfo()
          .then(() => {
            return getOrganizations({
              sort: 'organizationId',
            });
          })
          .then(async (orgData) => {
            getUserAdditionalData();
            await getCurrencies();
            return orgData;
          })
          .catch(() => {
            return {};
          });
      })
      .finally(() => {
        setInitialized(true);
      });
  }, []);
  return (
    <main>
      {initialized ? (
        <>
          <AppRouter />
          <Messages className="message-wrapper" />
          <DialogsHolder />
        </>
      ) : (
        <h1 className="m-4 font-weight-normal text-center">Loading...</h1>
      )}
    </main>
  );
};
