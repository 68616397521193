import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ForwardingAgentScreen } from './screens/forwardingAgent.screen';
import { ForwardingAgentListScreen } from './screens/forwardingAgent-list.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_FORWARDING_AGENTS } from '../contacts/contact.route';

export const FORWARDING_AGENTS_LIST_PATH = '/forwarding-agents';
export const FORWARDING_AGENTS_EDIT_PATH = '/forwarding-agents/:contactId';
export const FORWARDING_AGENTS_CREATE_PATH = '/forwarding-agents/0';
export const FORWARDING_AGENTS_PATH = '/org/:organizationId/forwarding-agents';

export const ForwardingAgentsRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute
          exact
          path={`${match.path}${FORWARDING_AGENTS_LIST_PATH}`}
        >
          <ForwardingAgentListScreen pageTitle="Forwarding Agents" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${FORWARDING_AGENTS_CREATE_PATH}`}
        >
          <ForwardingAgentScreen pageTitle="Add New Forwarding Agent" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${FORWARDING_AGENTS_EDIT_PATH}`}
        >
          <ForwardingAgentScreen pageTitle="Update Forwarding Agent" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${CONTACTS_TAB_EDIT_PATH_FOR_FORWARDING_AGENTS}`}
        >
          <ContactScreen pageTitle="Contacts" isTab={true} />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
