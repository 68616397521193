import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputProps,
  InputPublicProps,
} from '../../common/components/input/input.component';
import {
  TransportationMethod,
  TransportationMethodDescription,
} from '../../../models/data.models';
import { ReactSelect } from '../../common/components/input/input.select';

export const ModeOfTransportationForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      innerRef={innerRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      id={id}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

ModeOfTransportationForm.Description = ({
  className,
  style,
  size,
  disabled,
  name = 'description',
  label = 'Description',
  placeholder = 'Description',
  required = true,
  type = 'text',
  onChange,
}: InputPublicProps) => {
  return (
    <Input
      onChange={onChange}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={type}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ModeOfTransportationForm.TransportationMethod = ({
  onChange,
  options,
  name = 'transportationMethod',
  id = 'transportationMethod',
  placeholder = 'Select Transportation Method',
  required = true,
  multiple = false,
  disabled = false,
  header,
  defaultValue = '',
  readonly,
}: InputProps) => {
  return (
    <ReactSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={true}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      useContext={true}
    />
  );
};
ModeOfTransportationForm.TransportationMethodDescription = ({
  onChange,
  options,
  name = 'transportationMethodDescription',
  id = 'transportationMethodDescription',
  placeholder = 'Select Transportation Method Description',
  required = true,
  multiple = false,
  disabled = false,
  header,
  defaultValue,
  readonly,
}: InputProps) => {
  return (
    <ReactSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={true}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      useContext={true}
    />
  );
};
ModeOfTransportationForm.USCustomsCode = ({
  className,
  style,
  size,
  disabled,
  name = 'usCustomsCode',
  label = 'US Customs Code',
  placeholder = 'US Customs Code',
  required = true,
  type = 'text',
  onChange,
  defaultValue,
  validate,
}: InputProps) => {
  return (
    <Input
      onChange={onChange}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      type={type}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
      defaultValue={defaultValue}
      validate={validate}
    />
  );
};

ModeOfTransportationForm.AvailableForOnlineSalesOrders = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'availableForOnlineSalesOrders'}
      label={'Available for Online Sales Orders'}
      placeholder={'Available for Online Sales Orders'}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ModeOfTransportationForm.AvailableForOnlinePickupOrders = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'availableForOnlinePickupOrders'}
      label={'Available for Online Pickup Orders'}
      placeholder={'Available for Online Pickup Orders'}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
ModeOfTransportationForm.AvailableForOnlineShippingOrders = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'availableForOnlineShippingOrders'}
      label={'Available for Online Shipping Orders'}
      placeholder={'Available for Online Shipping Orders'}
      required={false}
      type={'checkbox'}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    />
  );
};
