import { createEvent, createStore, Event, Store } from 'effector';
import { Message } from './messages.store';
import { requestError } from '../api/api.store';

const defaultState = {
  menuOpen: true,
};

export const toggleMenu: Event<void> = createEvent();
export const toggleMenuItem: Event<string> = createEvent();

export const navigationStore: Store<any> = createStore(defaultState)
  .on(toggleMenuItem, (state, payload) => {
    return { ...state, [payload]: !state[payload] };
  })
  .on(toggleMenu, (state, payload) => {
    return { ...state, menuOpen: !state.menuOpen };
  });
