import React from 'react';
import { useStore } from 'effector-react';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, Link } from 'react-router-dom';
import { ActionItem } from '../../common/components/action-item/action-item.component';
import { INTERNAL_PATH } from '../../../app.router';
import { GET_CONTACTS_LINK_KEY } from '../../contacts/contacts.service';
import { VENDORS_LIST_PATH } from '../vendor.route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

export const VendorMenu = ({ className }) => {
  const { currentOrganization } = useStore(organizationsStore);
  return (
    <ActionItem
      context={currentOrganization}
      actionKey={GET_CONTACTS_LINK_KEY}
      render={({ available }) => {
        if (available) {
          return (
            <li className={className}>
              <Link
                to={generatePath(INTERNAL_PATH + VENDORS_LIST_PATH, {
                  organizationId: currentOrganization?.organizationId,
                })}
                className="nav-link active nav-icon-size-medium"
                data-cy="link-vendors"
              >
                <div className="menu-icon-wrapper d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="nav-link-text">Vendors</div>
              </Link>
            </li>
          );
        }
        return null;
      }}
    />
  );
};
