import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CurrenciesListScreen } from './screens/currencies-list.screen';
import { CurrencyScreen } from './screens/currencies.screen';

export const CURRENCIES_LIST_PATH = '/currencies';
export const CURRENCIES_EDIT_PATH = '/currencies/:currencyId';
export const CURRENCIES_CREATE_PATH = '/currencies/0';
export const CURRENCIES_PATH = '/org/:organizationId/currencies';

export const CurrencyRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${CURRENCIES_LIST_PATH}`}>
          <CurrenciesListScreen pageTitle="Currencies" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CURRENCIES_CREATE_PATH}`}>
          <CurrencyScreen pageTitle="Add New Currency" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CURRENCIES_EDIT_PATH}`}>
          <CurrencyScreen pageTitle="Update Currency" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
