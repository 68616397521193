import { DimensionsUnit, VolumeUnit, WeightUnit } from '../models/data.models';

export enum DimensionsUnitAdd {
  M = 'M',
  Ft = 'Ft',
}
export type DimensionsUnitExt = DimensionsUnit | DimensionsUnitAdd;
const dimensionsUnits = { ...DimensionsUnit, ...DimensionsUnitAdd };

const lengthFactors = {
  In: 0.0254,
  Cm: 0.01,
  Ft: 0.3048,
  M: 1,
};

const weightFactors = {
  Lb: 0.453592,
  Kg: 1,
};

export const convertDimension = (
  value: number,
  unitFrom: DimensionsUnitExt,
  unitTo: DimensionsUnitExt,
) => {
  return unitFrom && unitTo
    ? Number(
        ((value * lengthFactors[unitFrom]) / lengthFactors[unitTo]).toFixed(2),
      )
    : 0;
};

export const convertWeight = (
  value: number,
  unitFrom: WeightUnit,
  unitTo: WeightUnit,
) => {
  return unitFrom && unitTo
    ? Number(
        ((value * weightFactors[unitFrom]) / weightFactors[unitTo]).toFixed(2),
      )
    : 0;
};

export const getVolume = (
  length: number,
  width: number,
  height: number,
  dimensionsUnit: DimensionsUnitExt,
  volumeUnit: VolumeUnit,
) => {
  if (!length || !width || !height) {
    return 0;
  }

  const isVolumetricWeight =
    (volumeUnit as string) == 'Vlb' || (volumeUnit as string) == 'Vkg';

  let dimensionsUnitN = dimensionsUnit;

  switch (volumeUnit as string) {
    case 'M':
      dimensionsUnitN = dimensionsUnits.M;
      break;
    case 'Ft':
      dimensionsUnitN = dimensionsUnits.Ft;
      break;
    case 'Vkg':
      dimensionsUnitN = dimensionsUnits.Cm;
      break;
    case 'Vlb':
      dimensionsUnitN = dimensionsUnits.In;
      break;
  }

  const lengthN = convertDimension(length, dimensionsUnit, dimensionsUnitN);
  const widthN = convertDimension(width, dimensionsUnit, dimensionsUnitN);
  const heightN = convertDimension(height, dimensionsUnit, dimensionsUnitN);

  return isVolumetricWeight
    ? Number(((lengthN * widthN * heightN) / 5000).toFixed(2))
    : Number((lengthN * widthN * heightN).toFixed(2));
};
