import {
  OrderDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateOrderCommandValues,
  UpdateOrderCommandValues,
  CreateChargeCommand,
  UpdateChargeCommand,
  UpdateCommodityCommand,
  UpdateOrderDeliveryCommand,
  UpdateOrderPickupCommand,
  CreateTrackingEventCommand,
  UpdateTrackingEventCommand,
  ContactType,
  Column,
  OrderTypes,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createOrderRequest,
  getOrdersListRequest,
  getOrderRequest,
  deleteOrderRequest,
  updateOrderRequest,
  GetOrderParams,
  patchOrderRequest,
  PatchOrderParams,
} from './orders.service';

type OrdersStoreState = {
  links: LinkDto[];
  orderColumns: Column[];
};

export const updateOrdersColumns = createEvent<Column[]>();

export const getOrders = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getOrdersListRequest(currentOrganization, params);
});

export const carriersAndCustomValuesCreation = (order: OrderDto) => {
  let carriersId: number[] = [];
  if (order.orderType === OrderTypes.Quote) {
    if (order.carrierContactId) {
      carriersId.push(order.carrierContactId);
    }
  } else {
    carriersId = order?.carriers?.map<number>((item) => {
      return item.contactId;
    });
  }

  let customValues = order.customValues ?? {};
  const specialQuoteFields: string[] = [
    'modeOfTransportationId',
    'modeOfTransportationDescription',
    'paymentTermId',
    'paymentTermDescription',
    'expDate',
    'quoteStatus',
  ];

  specialQuoteFields.forEach((field) => {
    if (order[field]) {
      customValues[field] = order[field];
    } else {
      delete customValues[field];
    }
  });

  return { carriersId, customValues };
};

export const createOrder = createEffect((order: OrderDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const charges: CreateChargeCommand[] = order?.charges?.map<CreateChargeCommand>(
    (item) => {
      const createChargeCommand: CreateChargeCommand = {
        values: {
          amount: item.amount,
          currencyId: item.currencyId,
          accountingItemId: item.accountingItemId,
          applyBy: item.applyBy,
          applyToContactId: item.applyToContactId,
          chargeType: item.chargeType,
          description: item.description,
          freightServiceClassId: item.freightServiceClassId,
          grossVolume: item.grossVolume,
          grossWeight: item.grossWeight,
          isConsolidated: item.isConsolidated,
          note: item.note,
          paidAs: item.paidAs,
          pieces: item.pieces,
          price: item.price,
          quantity: item.quantity,
          showInDocuments: item.showInDocuments,
          unit: item.unit,
          salesTaxRate: item.salesTaxRate ?? 0,
          totalAmount: item.totalAmount,
          salesTaxId: item.salesTaxId,
          salesTaxAmount: item.salesTaxAmount,
          allowAutomaticUpdate: item.allowAutomaticUpdate,
        },
      };
      return createChargeCommand;
    },
  );

  const trackingEvents: CreateTrackingEventCommand[] = order?.trackingEvents?.map<CreateTrackingEventCommand>(
    (item) => {
      const createTrackingEventCommand: CreateTrackingEventCommand = {
        values: {
          description: item.description,
          location: item.location,
          eventDate: item.eventDate,
          eventDefinitionId: item.eventDefinitionId,
          isInactive: item.isInactive,
          includeInTracking: item.includeInTracking,
          sendEmail: item.sendEmail,
        },
      };
      return createTrackingEventCommand;
    },
  );

  const { carriersId, customValues } = carriersAndCustomValuesCreation(order);

  const createOrderCommand: CreateOrderCommandValues = {
    orderStatus: order.orderStatus,
    commodities: order.commodities,
    billToContactId: order.billToContactId,
    carrierContactId: order.carrierContactId,
    divisionId: order.divisionId,
    equipmentTypeId: order.equipmentTypeId,
    employeeContactId: order.employeeContactId,
    orderDeliveries: order.orderDeliveries,
    orderPickups: order.orderPickups,
    salespersonContactId: order.salespersonContactId,
    orderNumber: order.orderNumber,
    charges,
    trackingEvents,
    carriersId,
    customValues,
    trackingNumber: order.trackingNumber,
    orderType: order.orderType,
  };
  return createOrderRequest(currentOrganization!, createOrderCommand);
});

export const getOrder = createEffect((orderParams: GetOrderParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getOrderRequest(
    currentOrganization as LinkResourceBaseDto,
    orderParams,
  );
});

export const updateOrder = createEffect((order: OrderDto) => {
  const charges: UpdateChargeCommand[] = order?.charges?.map<UpdateChargeCommand>(
    (item) => {
      const updateChargeCommand: UpdateChargeCommand = {
        chargeId: item.chargeId,
        values: {
          amount: item.amount,
          currencyId: item.currencyId,
          accountingItemId: item.accountingItemId,
          applyBy: item.applyBy,
          applyToContactId: item.applyToContactId,
          chargeType: item.chargeType,
          description: item.description,
          freightServiceClassId: item.freightServiceClassId,
          grossVolume: item.grossVolume,
          grossWeight: item.grossWeight,
          isConsolidated: item.isConsolidated,
          note: item.note,
          paidAs: item.paidAs,
          pieces: item.pieces,
          price: item.price,
          quantity: item.quantity,
          showInDocuments: item.showInDocuments,
          unit: item.unit,
          salesTaxRate: item.salesTaxRate ?? 0,
          totalAmount: item.totalAmount,
          salesTaxId: item.salesTaxId,
          salesTaxAmount: item.salesTaxAmount,
          allowAutomaticUpdate: item.allowAutomaticUpdate,
        },
      };
      return updateChargeCommand;
    },
  );
  const commodities: UpdateCommodityCommand[] = order?.commodities?.map<UpdateCommodityCommand>(
    (item) => {
      const updateCommodityCommand: UpdateCommodityCommand = {
        commodityId: item.commodityId,
        values: {
          commodityStatus: item.commodityStatus,
          description: item.description,
          dimensionsUnit: item.dimensionsUnit,
          height: item.height,
          length: item.length,
          note: item.note,
          organizationId: item.organizationId,
          packageTypeId: item.packageTypeId,
          pieces: item.pieces,
          quantity: item.quantity,
          unit: item.unit,
          unitaryValue: item.unitaryValue,
          volumePiece: item.volumePiece,
          volumeTotal: item.volumeTotal,
          volumeUnit: item.volumeUnit,
          weight: item.weight,
          weightByTotal: item.weightByTotal,
          weightTotal: item.weightTotal,
          weightUnit: item.weightUnit,
          width: item.width,
        },
      };
      return updateCommodityCommand;
    },
  );
  const orderDeliveries: UpdateOrderDeliveryCommand[] = order.orderDeliveries.map<UpdateOrderDeliveryCommand>(
    (item) => {
      const updateOrderDeliveryCommand: UpdateOrderDeliveryCommand = {
        orderDeliveryId: item.orderDeliveryId,
        orderId: item.orderId,
        values: {
          consigneeAddressId: item.consigneeAddressId,
          consigneeContactId: item.consigneeContactId,
          deliveryDate: item.deliveryDate,
          deliveryNotes: item.deliveryNotes,
          orderDeliverySequence: item.orderDeliverySequence,
        },
      };
      return updateOrderDeliveryCommand;
    },
  );
  const orderPickups: UpdateOrderPickupCommand[] = order.orderPickups.map<UpdateOrderPickupCommand>(
    (item) => {
      const updateOrderPickupCommand: UpdateOrderPickupCommand = {
        orderPickupId: item.orderPickupId,
        orderId: item.orderId,
        values: {
          orderPickupSequence: item.orderPickupSequence,
          pickDate: item.pickDate,
          shipperAddressId: item.shipperAddressId,
          shipperContactId: item.shipperContactId,
          shippingNotes: item.shippingNotes,
        },
      };
      return updateOrderPickupCommand;
    },
  );

  const trackingEvents: UpdateTrackingEventCommand[] = order?.trackingEvents?.map<UpdateTrackingEventCommand>(
    (item) => {
      const updateTrackingEventCommand: UpdateTrackingEventCommand = {
        trackingEventId: item.trackingEventId,
        values: {
          description: item.description,
          location: item.location,
          eventDate: item.eventDate,
          eventDefinitionId: item.eventDefinitionId,
          isInactive: item.isInactive,
          includeInTracking: item.includeInTracking,
          sendEmail: item.sendEmail,
        },
      };
      return updateTrackingEventCommand;
    },
  );

  const { carriersId, customValues } = carriersAndCustomValuesCreation(order);

  const updateOrderCommand: UpdateOrderCommandValues = {
    orderStatus: order.orderStatus,
    commodities,
    billToContactId: order.billToContactId,
    carrierContactId: order.carrierContactId,
    divisionId: order.divisionId,
    equipmentTypeId: order.equipmentTypeId,
    employeeContactId: order.employeeContactId,
    orderDeliveries,
    orderPickups,
    salespersonContactId: order.salespersonContactId,
    orderNumber: order.orderNumber,
    charges,
    trackingEvents,
    carriersId,
    customValues,
    trackingNumber: order.trackingNumber,
    orderType: order.orderType,
  };
  return updateOrderRequest(order, updateOrderCommand);
});

export const patchOrder = createEffect((patchOrderParams: PatchOrderParams) => {
  return patchOrderRequest(
    patchOrderParams.resource,
    patchOrderParams.patchOrderCommand,
  );
});

export const deleteOrder = createEffect((order: OrderDto) => {
  return deleteOrderRequest(order);
});

const defaultState: OrdersStoreState = {
  links: [],
  orderColumns: [
    {
      name: 'orderNumber',
      visible: true,
      title: 'Load',
      showFilter: false,
      sortName: 'orderNumber~ToInt32',
    },
    {
      name: 'billToContactName',
      visible: true,
      title: 'Customer',
      type: 'contact',
      contactType: ContactType.Customer,
      contactTypes: [
        ContactType.Customer,
        ContactType.Vendor,
        ContactType.ForwardingAgent,
        ContactType.Contact,
      ],
      dataField: 'BillToContactId',
      showFilter: true,
      sortName: 'BillToContact.Name',
    },
    {
      name: 'carrierContactName',
      visible: true,
      title: 'Carrier / Driver',
      type: 'contact',
      contactType: ContactType.Carrier,
      contactTypes: [ContactType.Carrier, ContactType.Driver],
      showFilter: false,
    },
    {
      name: 'firstOrderPickupsPickDate',
      visible: true,
      title: 'Ship Date',
      showFilter: false,
      type: 'date',
      dataField: 'OrderPickups[0].PickDate',
    },
    {
      name: 'firstOrderDeliveriesDeliveryDate',
      visible: true,
      title: 'Del Date',
      showFilter: false,
      type: 'date',
      dataField: 'OrderDeliveries[0].DeliveryDate',
    },
    {
      name: 'firstOrderPickupsShipperAddressName',
      visible: true,
      title: 'Origin',
      showFilter: false,
    },
    {
      name: 'firstOrderDeliveriesConsigneeAddressName',
      visible: true,
      title: 'Destination',
      showFilter: false,
    },
    {
      name: 'orderStatus',
      visible: true,
      title: 'Order Status',
      type: 'orderStatus',
      showFilter: true,
      sortName: 'orderStatus',
    },
    {
      name: 'createdByUserName',
      visible: true,
      title: 'User',
      dataField: 'CreatedUser.UserName',
      showFilter: true,
      sortName: 'CreatedUser.UserName',
    },
  ],
};

export const ordersStore: Store<OrdersStoreState> = createStore(
  defaultState,
).on(updateOrdersColumns, (state, payload) => {
  return { ...state, orderColumns: payload };
});
