import {
  CarrierCodes,
  Column,
  ContactDto,
  ContactType,
  CreateContactCommandValues,
  ExportDto,
  FileType,
  LinkDto,
  LinkResourceBaseDto,
  UpdateContactCommandValues,
} from '../../models/data.models';
import { createEffect, createEvent, createStore, Store } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createContactAndContactAddressesRequest,
  createContactRequest,
  deleteContactRequest,
  exportContactsRequest,
  fmcaImportContactsRequest,
  GetContactParams,
  getContactRequest,
  getContactsListRequest,
  importContactsRequest,
  updateContactAndContactAddressesRequest,
  updateContactRequest,
} from './contacts.service';
import {
  CreateContactAndContactAddressesCommand,
  UpdateContactAndContactAddressesCommand,
} from '../../models/custom.models';

type ContactsStoreState = {
  links: LinkDto[];
  contactColumns: Column[];
};

export const updateContactsColumns = createEvent<Column[]>();

export const getContacts = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getContactsListRequest(currentOrganization, params);
});

export const createContact = createEffect((contactData: ContactDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createContactCommand: CreateContactCommandValues = { ...contactData };

  return createContactRequest(currentOrganization!, createContactCommand);
});
export const createContactAndContactAddresses = createEffect(
  (contactData: CreateContactAndContactAddressesCommand) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const organizationId = currentOrganization.organizationId;
    if (contactData.contact == null) {
      contactData.contact = {};
    }
    contactData.contact.organizationId = organizationId;
    if (
      !contactData.shippingAddress?.addressLine &&
      !contactData.shippingAddress?.addressLine2 &&
      !contactData.shippingAddress?.city
    ) {
      delete contactData.shippingAddress;
    }
    if (
      !contactData.billingAddress?.addressLine &&
      !contactData.billingAddress?.addressLine2 &&
      !contactData.billingAddress?.city
    ) {
      delete contactData.billingAddress;
    }
    if (
      contactData.shippingAddress !== null &&
      contactData.shippingAddress !== undefined
    ) {
      contactData.shippingAddress.organizationId = organizationId;
    }
    if (
      contactData.billingAddress !== null &&
      contactData.billingAddress !== undefined
    ) {
      contactData.billingAddress.organizationId = organizationId;
    }
    if (contactData.contact?.emailAddress === '') {
      contactData.contact.emailAddress = null;
    }

    return createContactAndContactAddressesRequest(
      currentOrganization!,
      contactData,
    );
  },
);

export const getContact = createEffect((contactParams: GetContactParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getContactRequest(
    currentOrganization as LinkResourceBaseDto,
    contactParams,
  );
});
export const updateContactAndContactAddresses = createEffect(
  (updateContactCommand: UpdateContactAndContactAddressesCommand) => {
    if (
      !updateContactCommand.shippingAddress?.addressLine &&
      !updateContactCommand.shippingAddress?.addressLine2 &&
      !updateContactCommand.shippingAddress?.city
    ) {
      delete updateContactCommand.shippingAddress;
    }
    if (
      !updateContactCommand.billingAddress?.addressLine &&
      !updateContactCommand.billingAddress?.addressLine2 &&
      !updateContactCommand.billingAddress?.city
    ) {
      delete updateContactCommand.billingAddress;
    }
    if (updateContactCommand.contact?.emailAddress === '') {
      updateContactCommand.contact.emailAddress = null;
    }
    return updateContactAndContactAddressesRequest(
      updateContactCommand.contact,
      updateContactCommand,
    );
  },
);
export const updateContact = createEffect((contact: ContactDto) => {
  const updateContactCommand: UpdateContactCommandValues = { ...contact };
  return updateContactRequest(contact, updateContactCommand);
});

export const deleteContact = createEffect((contact: ContactDto) => {
  return deleteContactRequest(contact);
});

export const exportContacts = createEffect((exportDto: ExportDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return exportContactsRequest({
    organizationId: currentOrganization?.organizationId,
    contactType: ContactType[exportDto.contactType],
    fileType: exportDto.fileType,
  });
});

type ImportContacts = {
  divisionId: number;
  contactType: ContactType;
  file: File;
};
export const importContacts = createEffect((params: ImportContacts) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return importContactsRequest({
    organizationId: currentOrganization?.organizationId,
    divisionId: params.divisionId,
    contactType: ContactType[params.contactType],
    file: params.file,
  });
});

type FMCAImportContacts = {
  code: string;
  codeType: CarrierCodes;
};
export const fmcaImportContacts = createEffect((params: FMCAImportContacts) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return fmcaImportContactsRequest({
    organizationId: currentOrganization?.organizationId,
    code: params.code,
    codeType: params.codeType,
  });
});

const defaultState: ContactsStoreState = {
  links: [],
  contactColumns: [
    {
      name: 'contactId',
      visible: true,
      title: 'Contact Id',
      showFilter: false,
      sortName: 'contactId',
    },
    {
      name: 'name',
      visible: true,
      title: 'Name',
      showFilter: false,
      sortName: 'name',
    },
    {
      name: 'contactFirstName',
      visible: true,
      title: 'Contact First Name',
      showFilter: false,
      sortName: 'contactFirstName',
    },
    {
      name: 'contactLastName',
      visible: true,
      title: 'Contact Last Name',
      showFilter: false,
      sortName: 'contactLastName',
    },
    {
      name: 'contactType',
      visible: false,
      title: 'Contact Type',
      showFilter: false,
    },
    {
      name: 'mobilePhoneNumber',
      visible: true,
      title: 'Mobile Phone Number',
      showFilter: false,
      sortName: 'mobilePhoneNumber',
    },
    {
      name: 'phoneNumber',
      visible: true,
      title: 'Phone Number',
      showFilter: false,
      sortName: 'phoneNumber',
    },
    {
      name: 'faxNumber',
      visible: false,
      title: 'Fax Number',
      showFilter: false,
      sortName: 'faxNumber',
    },
    {
      name: 'emailAddress',
      visible: true,
      title: 'Email Address',
      showFilter: false,
      sortName: 'emailAddress',
    },
    {
      name: 'website',
      visible: true,
      title: 'Website',
      showFilter: false,
      sortName: 'website',
    },
    {
      name: 'accountNumber',
      visible: true,
      title: 'Account Number',
      showFilter: true,
      sortName: 'accountNumber',
    },
    {
      name: 'idNumber',
      visible: false,
      title: 'Id Number',
      showFilter: true,
      sortName: 'idNumber',
    },
    {
      name: 'idNumberType',
      visible: false,
      title: 'Id Number Type',
      showFilter: true,
      type: 'idNumberType',
      sortName: 'idNumberType',
    },
    {
      name: 'divisionName',
      visible: true,
      title: 'Division Name',
      showFilter: true,
      sortName: 'Division.DivisionName',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      showFilter: false,
      sortName: 'created',
    },
    {
      name: 'createdByUserName',
      visible: false,
      title: 'Created By User',
      showFilter: true,
      sortName: 'CreatedUser.UserName',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      showFilter: false,
      sortName: 'lastModified',
    },
    {
      name: 'lastModifiedByUserName',
      visible: false,
      title: 'Last Modified By User',
      showFilter: false,
      sortName: 'UpdatedUser.UserName',
    },
  ],
};

export const contactStore: Store<ContactsStoreState> = createStore(
  defaultState,
).on(updateContactsColumns, (state, payload) => {
  return { ...state, contactColumns: payload };
});

export const contactFilterFields: any = [
  'contactId',
  'accountNumber',
  'contactFirstName',
  'created',
  'contactLastName',
  'emailAddress',
  'faxNumber',
  'idNumber',
  'idNumberType',
  'lastModified',
  'mobilePhoneNumber',
  'name',
  'phoneNumber',
  'website',
];
