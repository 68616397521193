import React, { CSSProperties, useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { BsCalendar3 } from 'react-icons/bs';
import { clearMessages } from '../../messages.store';
import { StandaloneCheckbox } from '../checkbox/standalone-checkbox.component';

export type ReactDatepickerProps = {
  size?: 'sm' | 'md' | 'lg';
  id?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  readonly?: string;
  style?: CSSProperties;
  className?: string;
  validate?: (value?: string) => Promise<string>;
  secondLabel?: () => any;
  disabled?: boolean;
  defaultValue?: any;
  onChange?: (data: any, context?: any) => void;
  formId?: string;
  showTimeOption?: boolean;
  timeIntervals?: number,
  timeCaption?: string,
  timeFormat?: string,
  dateFormat?: string,
};

export const ReactDatepickerComponent = ({
  size = 'md',
  id,
  label,
  placeholder,
  required,
  readonly,
  style = {},
  className = '',
  secondLabel,
  disabled,
  defaultValue,
  onChange,
  showTimeOption,
  timeIntervals,
  timeCaption,
  timeFormat = 'h:mm aa',
  dateFormat = 'MM/dd/yyyy',
  formId,
}: ReactDatepickerProps) => {
  const context = useFormikContext();

  const getDefaultIsTimeShow = () => {
    if (!showTimeOption) {
      return false;
    }
    return !!defaultValue && !defaultValue.toString().includes('00:00:00');
  };

  const [isTimeShow, setIsTimeShow] = useState<boolean>(getDefaultIsTimeShow());

  const removeTime = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  };

  const setDate = (date: Date) => {
    onChange(date);
    context.setFieldValue(id, date);
  };

  return (
    <>
      <div className={`form-group ${className}`} style={style}>
        {showTimeOption || label ? (
          <label className="input-label" htmlFor={id}>
            <span className="d-flex justify-content-between align-items-center">
              <span className="input-label-primary">{label || ''}</span>
              {showTimeOption ? (
                <StandaloneCheckbox
                  name={`${id}ShowTime`}
                  label={'Show Time'}
                  size={'sm'}
                  onChange={(value) => {
                    setIsTimeShow(value);
                    let date = context.getFieldProps(id).value;
                    if (date) {
                      if (!value) {
                        date = removeTime(date);
                      }
                      setDate(new Date(date));
                    }
                  }}
                  checked={isTimeShow}
                />
              ) : secondLabel ? (
                <span className="input-label-secondary">{secondLabel}</span>
              ) : null}
            </span>
          </label>
        ) : null}
        <div className="d-flex flex-column">
          <Field
            required={required}
            type="hidden"
            name={id}
            value={defaultValue}
          />
          <div
            className={`d-flex align-items-center position-relative${
              isTimeShow ? ' react-datepicker-fix-width' : ''
            }`}
          >
            <DatePicker
              selected={defaultValue ? new Date(defaultValue) : false}
              className={`form-control form-control-${size} ${id}`}
              onChange={(date: Date) => {
                if (showTimeOption && !isTimeShow) {
                  date = removeTime(date);
                }
                setDate(date);
              }}
              onCalendarClose={() => context.setFieldTouched(id, true, true)}
              onFocus={clearMessages}
              placeholderText={placeholder}
              readonly={readonly}
              disabled={disabled}
              required={required}
              shouldCloseOnSelect={true}
              id={`${id}Picker`}
              autoComplete={'off'}
              showTimeSelect={showTimeOption && isTimeShow}
              timeFormat={timeFormat}
              timeIntervals={timeIntervals}
              timeCaption={timeCaption}
              dateFormat={
                showTimeOption && isTimeShow
                  ? `${dateFormat} ${timeFormat}`
                  : dateFormat
              }
            />
            <label
              htmlFor={`${id}Picker`}
              className="label-datepicker calendar-icon position-absolute"
            >
              <BsCalendar3 />
            </label>
          </div>
          <ErrorMessage
            name={id}
            component="div"
            className="invalid-feedback"
          />
        </div>
      </div>
    </>
  );
};
