import {
  UserDto,
  LinkDto,
  LinkResourceBaseDto,
  UserDtoPagedResult,
  CreateUserCommandValues,
  UpdateUserCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createUserRequest,
  getUsersListRequest,
  getUserRequest,
  deleteUserRequest,
  updateUserRequest,
  GetUserParams,
} from './users.service';

type UsersStoreState = {
  links: LinkDto[];
  userColumns: Column[];
};

export const updateUsersColumns = createEvent<Column[]>();

export const getUsers = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getUsersListRequest(currentOrganization, params);
});

export const createUser = createEffect((userData: UserDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createUserCommand: CreateUserCommandValues = { ...userData };

  return createUserRequest(currentOrganization!, createUserCommand);
});

export const getUser = createEffect((userParams: GetUserParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getUserRequest(currentOrganization as LinkResourceBaseDto, userParams);
});

export const updateUser = createEffect((user: UserDto) => {
  const updateUserCommand: UpdateUserCommandValues = { ...user };
  return updateUserRequest(user, updateUserCommand);
});

export const deleteUser = createEffect((user: UserDto) => {
  return deleteUserRequest(user);
});

const defaultState: UsersStoreState = {
  links: [],
  userColumns: [
    { name: 'userId', visible: false, title: 'UserId', sortName: 'id' },
    {
      name: 'userName',
      visible: true,
      title: 'User Name',
      sortName: 'userName',
    },
    { name: 'name', visible: true, title: 'Name' },
    {
      name: 'firstName',
      visible: false,
      title: 'First Name',
      sortName: 'firstName',
    },
    {
      name: 'lastName',
      visible: false,
      title: 'Last Name',
      sortName: 'lastName',
    },
    { name: 'email', visible: true, title: 'Email', sortName: 'email' },
  ],
};

export const userStore: Store<UsersStoreState> = createStore(defaultState).on(
  updateUsersColumns,
  (state, payload) => {
    return { ...state, userColumns: payload };
  },
);
