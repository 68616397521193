import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { QuickBooksScreen } from './screens/quickBooks.screen';

export const QUICKBOOKS_EDIT_PATH = '/quickBooks';
export const QUICKBOOKS_PATH = '/org/:organizationId/quickBooks';

export const QuickBooksRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${QUICKBOOKS_EDIT_PATH}`}>
          <QuickBooksScreen pageTitle="QuickBooks" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
