import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetPortsQuery } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import React from 'react';
import {
  PAYMENT_CREATE_PATH,
  PAYMENT_EDIT_PATH,
  PAYMENT_LIST_PATH,
} from '../payment.route';
import { CREATE_PAYMENT_LINK_KEY, GetPaymentParams } from '../payments.service';
import { PaymentsList } from '../components/payments-list.component';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';

export type PaymentListScreenProps = { pageTitle: string };

export const PaymentListScreen = ({ pageTitle }: PaymentListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = '-paymentId',
    filter = '',
    search = '',
  } = useQuery() as GetPortsQuery;
  const history = useHistory();

  const createPath = generatePath(INTERNAL_PATH + PAYMENT_CREATE_PATH, {
    organizationId,
  });

  const goToDetails = (getPaymentParams: GetPaymentParams) => {
    const path = generatePath(INTERNAL_PATH + PAYMENT_EDIT_PATH, {
      organizationId,
      paymentId: getPaymentParams.paymentId,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + PAYMENT_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + PAYMENT_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + PAYMENT_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + PAYMENT_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };
  return (
    <InternalLayout
      title={'Payments'}
      createPath={userHas(CREATE_PAYMENT_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PaymentsList
        search={search}
        onSearch={onSearch}
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        filter={filter}
        onFilter={onFilter}
      />
    </InternalLayout>
  );
};
