import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { SalesTaxesScreen } from './screens/salesTaxes.screen';
import { SalesTaxesListScreen } from './screens/salesTaxes-list.screen';
import React from 'react';

export const SALESTAXES_LIST_PATH = '/sales-tax';
export const SALESTAX_EDIT_PATH = '/sales-tax/:salesTaxId';
export const SALESTAX_CREATE_PATH = '/sales-tax/0';
export const SALESTAXES_PATH = '/org/:organizationId/sales-tax';

export const SalesTaxRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${SALESTAXES_LIST_PATH}`}>
          <SalesTaxesListScreen pageTitle="Sales Taxes" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${SALESTAX_CREATE_PATH}`}>
          <SalesTaxesScreen pageTitle="Add New Sales Tax" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${SALESTAX_EDIT_PATH}`}>
          <SalesTaxesScreen pageTitle="Update Sales Tax" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
