import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  DocumentTemplateDto,
  DocumentTemplateDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  documentTemplateStore,
  getDocumentTemplates,
  deleteDocumentTemplate,
  updateDocumentTemplatesColumns,
  downloadDefaultDocumentTemplates,
} from '../documentTemplates.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_DOCUMENTTEMPLATE_LINK_KEY,
  DELETE_DOCUMENTTEMPLATE_LINK_KEY,
  GetDocumentTemplateParams,
} from '../documentTemplates.service';
import { DocumentTemplateDialog } from './documentTemplate.dialog';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { userHas } from '../../auth/auth.store';

export type DocumentTemplatesListProps = {
  goToDetails?: (documentTemplateParams: GetDocumentTemplateParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    documentTemplate: DocumentTemplateDto,
    documentTemplateParams: GetDocumentTemplateParams,
  ) => void;
  showAllStore?: boolean;
  onSearch?: (query: string) => void;
  onFilter?: (query: string) => void;
  filter?: string;
};

export const DocumentTemplatesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = 'documentTemplateId',
  onDelete = () => {},
  onPageChanged = () => {},
  showAllStore = true,
  onSort = () => {},
  onSelect,
  onSearch,
  onFilter,
  filter,
}: DocumentTemplatesListProps) => {
  const [
    documentTemplates,
    setDocumentTemplates,
  ] = useState<DocumentTemplateDtoPagedResult | null>(null);

  const getData = () => {
    getDocumentTemplates({ offset, limit, sort, filter, search }).then(
      (documentTemplatesData) => {
        documentTemplatesData.items.forEach((item) => {
          item.created = getFormattedDate(item.created);
          item.lastModified = getFormattedDate(item.lastModified);
        });

        setDocumentTemplates(documentTemplatesData);
      },
      () => {},
    );
  };

  useEffect(() => {
    getData();
  }, [offset, limit, sort, filter, search]);

  const { documentTemplateColumns: columns } = useStore(documentTemplateStore);

  const onDeleteDocumentTemplate = (documentTemplate: DocumentTemplateDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${documentTemplate.documentTemplateId} Document Template`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteDocumentTemplate(documentTemplate).then(
            () => {
              onDelete();
              getData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditDocumentTemplate = (documentTemplate: DocumentTemplateDto) => {
    goToDetails({ documentTemplateId: documentTemplate.documentTemplateId });
  };

  const onCreateNewDocumentTemplate = () => {
    goToDetails({ documentTemplateId: 0 });
  };
  const onDocumentTemplateSelect = (documentTemplate, documentTemplateId) => {
    if (onSelect) onSelect(documentTemplate, documentTemplateId);
    showDialog({
      dialog: DocumentTemplateDialog,
      props: {
        documentTemplateId: documentTemplate.documentTemplateId,
        title: 'Update Document Template',
      },
    }).then(
      (documentTemplate) => {
        if (documentTemplate !== null) {
          getData();
        }
      },
      () => {},
    );
  };

  const downloadDefaultTemplates = () =>
    downloadDefaultDocumentTemplates().then(getData, () => {});

  return (
    <>
      <Grid
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={showAllStore}
        rowKeys={['documentTemplateId']}
        data={documentTemplates?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={documentTemplates?.totalCount}
        sort={sort}
        onDelete={
          userHas(
            DELETE_DOCUMENTTEMPLATE_LINK_KEY,
            documentTemplates?.items[0]?.links,
          )
            ? onDeleteDocumentTemplate
            : null
        }
        onSort={onSort}
        onEdit={onEditDocumentTemplate}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateDocumentTemplatesColumns}
        onSelect={onDocumentTemplateSelect}
        actionBar={
          <ActionBarComponent
            buttonText={<>&middot;&middot;&middot;</>}
            style={{ minWidth: 'fit-content' }}
          >
            <button type={'button'} onClick={downloadDefaultTemplates}>
              Download Default Templates
            </button>
          </ActionBarComponent>
        }
      />
      {!(
        documentTemplates &&
        documentTemplates.items &&
        documentTemplates.items.length
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Document Templates Yet
          </h3>
          {userHas(CREATE_DOCUMENTTEMPLATE_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={onCreateNewDocumentTemplate}
            >
              Create Document Template
            </Button>
          )}
        </div>
      )}
    </>
  );
};
