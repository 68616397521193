import React from 'react';

export type OrderTrackingButtonProps = {
  type: 'call' | 'email';
  className?: string;
};

export const OrderTrackingButton = ({
  type,
  className,
}: OrderTrackingButtonProps) => {
  return (
    <div
      className={`order-tracking-button d-flex align-items-center ${type} ${className}`}
    >
      {type.charAt(0).toUpperCase() + type.slice(1)}
    </div>
  );
};
