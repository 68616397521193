import {
  PortDto,
  LinkDto,
  LinkResourceBaseDto,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPortRequest,
  getPortsListRequest,
  getPortRequest,
  updatePortRequest,
  deletePortRequest,
  GetPortParams,
} from './port.service';

type PortsStoreState = {
  links: LinkDto[];
  portsColumns: Column[];
};

type Method = {
  name: string;
  field: string;
};

export const updateColumns = createEvent<Column[]>();

export const getPorts = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();
  return getPortsListRequest(currentOrganization, params);
});

export const createPort = createEffect((portData: PortDto) => {
  const { currentOrganization } = organizationsStore.getState();
  portData.organizationId = currentOrganization?.organizationId;
  return createPortRequest(currentOrganization, portData);
});

export const getPort = createEffect((portId: number) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPortRequest(currentOrganization, { portId });
});

export const updatePort = createEffect((portData: PortDto) => {
  return updatePortRequest(portData);
});

export const removePort = createEffect((portData: PortDto) => {
  return deletePortRequest(portData);
});

const defaultState: PortsStoreState = {
  links: [],
  portsColumns: [
    { name: 'name', visible: true, title: 'Name', sortName: 'name' },
    {
      name: 'countryCode',
      visible: true,
      title: 'Code',
      sortName: 'countryCode',
    },
    {
      name: 'stateCode',
      visible: true,
      title: 'Subdivision',
      sortName: 'stateCode',
    },
    {
      name: 'isMyCompany',
      visible: true,
      title: 'Used',
      sortName: 'isMyCompany',
    },
    { name: 'methods', visible: false, title: 'Methods' },
    { name: 'created', visible: false, title: 'Created', sortName: 'created' },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
  ],
};

export const portsStore: Store<PortsStoreState> = createStore(defaultState)
  .on(getPorts.done, (state, payload) => {
    const { result: ports } = payload;
    return { ...state, links: ports.links || state.links };
  })
  .on(updateColumns, (state, payload) => {
    return { ...state, portsColumns: payload };
  });
