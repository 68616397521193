import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { AccountingItemScreen } from './screens/accountingItem.screen';
import { AccountingItemListScreen } from './screens/accountingItem-list.screen';

export const ACCOUNTINGITEM_LIST_PATH = '/accounting-items';
export const ACCOUNTINGITEM_EDIT_PATH = '/accounting-items/:accountingItemId';
export const ACCOUNTINGITEM_CREATE_PATH = '/accounting-items/0';
export const ACCOUNTINGITEMS_PATH = '/org/:organizationId/accounting-items';

export const AccountingItemsRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${ACCOUNTINGITEM_LIST_PATH}`}>
          <AccountingItemListScreen pageTitle="Accounting Items" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${ACCOUNTINGITEM_CREATE_PATH}`}>
          <AccountingItemScreen pageTitle="Add New Accounting Item" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${ACCOUNTINGITEM_EDIT_PATH}`}>
          <AccountingItemScreen pageTitle="Update Accounting Item" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
