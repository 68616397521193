import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { OrderTrackingForm } from './orderTracking.form';
import {
  ContactDto,
  OrderDto,
  OrderTrackingDto,
  OrderTrackingRoutesDto,
} from '../../../models/data.models';
import {
  createOrderTracking,
  getOrderTracking,
  orderTrackingStore,
} from '../orderTrackings.store';
import { Panel } from '../../common/components/panel/panel.component';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { OrderTrackingDefaultValues } from '../../common/DefaultValues';
import { getOrder } from '../../orders/orders.store';
import { OrderTrackingRoutesGrid } from '../../common/components/grid/orderTrackingRoutes-grid.component';
import { useStore } from 'effector-react';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { authStore } from '../../auth/auth.store';
import * as Yup from 'yup';
import { FormContext } from '../../common/components/form/form.component';
import { addMessage } from '../../common/messages.store';
import { v4 } from 'uuid';

export type OrderTrackingEditProps = {
  orderTrackingId?: string | null;
  orderId?: number | null;
  onOrderTrackingCreated?: (orderTracking: OrderTrackingDto) => void;
  onOrderTrackingUpdated?: (orderTracking: OrderTrackingDto) => void;
  onOrderTrackingLoaded?: (orderTracking: OrderTrackingDto) => void;
  onCancel?: () => void;
};

const getInitialState = () => {
  const initialState: OrderTrackingDto = {
    orderTrackingId: OrderTrackingDefaultValues.orderTrackingId,
    orderId: OrderTrackingDefaultValues.orderId,
    driverContactId: OrderTrackingDefaultValues.driverContactId,
    driverContactName: OrderTrackingDefaultValues.driverContactName,
    carrierContactId: OrderTrackingDefaultValues.carrierContactId,
    carrierContactName: OrderTrackingDefaultValues.carrierContactName,
    fullName: OrderTrackingDefaultValues.fullName,
    phoneNumber: OrderTrackingDefaultValues.phoneNumber,
    emailAddress: OrderTrackingDefaultValues.emailAddress,
    customValues: OrderTrackingDefaultValues.customValues,
    selectedRows: [],
    created: new Date('2011-10-05T14:00:00.000Z'),
    createdBy: '',
    lastModified: new Date('2011-10-05T14:00:00.000Z'),
    lastModifiedBy: '',
    links: [],
  };
  return initialState;
};

const orderTrackingSchema = Yup.object().shape(
  {
    driverContactId: Yup.string().required("Can't be blank").nullable(true),
    phoneNumber: Yup.string()
      .when('emailAddress', {
        is: (emailAddress) => !emailAddress || emailAddress.length === 0,
        then: Yup.string()
          .required('At least one of the fields "Phone" or "Email" is required')
          .nullable(true),
      })
      .nullable(true),
    emailAddress: Yup.string()
      .when('phoneNumber', {
        is: (phoneNumber) => !phoneNumber || phoneNumber.length === 0,
        then: Yup.string()
          .required('At least one of the fields "Phone" or "Email" is required')
          .nullable(true),
      })
      .nullable(true),
  },
  [['phoneNumber', 'emailAddress']],
);

export const OrderTrackingEdit = ({
  orderTrackingId,
  orderId,
  onOrderTrackingCreated = () => {},
  onCancel = () => {},
}: OrderTrackingEditProps) => {
  const isCreateMode = orderTrackingId == '' || orderTrackingId == null;
  const { user: currentUser } = authStore.getState();
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderTracking, setOrderTracking] = useState<OrderTrackingDto | null>(
    getInitialState(),
  );
  const [orderTrackingRoutesDto, setOrderTrackingRoutesDto] = useState<
    OrderTrackingRoutesDto[] | null
  >(null);

  const ref = useRef<HTMLFormElement>();

  const { orderTrackingRoutesColumns: columns } = useStore(orderTrackingStore);

  const isOrgAdminOrOperation: boolean =
    currentUser?.isInOrgAdminRole || currentUser?.isInOperationRole;

  const getOrderTrackingRoutes = (
    orderDto: OrderDto,
  ): OrderTrackingRoutesDto[] => {
    let orderTrackingRoutes: OrderTrackingRoutesDto[] = [];

    orderDto?.orderPickups?.map((orderPickup, index) => {
      if (orderPickup.shipperContactId) {
        orderTrackingRoutes.push({
          type: `Shipper ${index + 1}`,
          name: orderPickup.shipperContactName,
          address: `${orderPickup.shipperAddressName}, ${orderPickup.shipperCityName} ${orderPickup.shipperStateCode}, ${orderPickup.shipperPostalCode} ${orderPickup.shipperCountryCode}`,
          date: getFormattedDate(orderPickup.pickDate, false),
          notes: orderPickup.shippingNotes,
        });
      }
    });

    orderDto?.orderDeliveries?.map((orderDelivery, index) => {
      if (orderDelivery.consigneeContactId) {
        orderTrackingRoutes.push({
          type: `Consignee ${index + 1}`,
          name: orderDelivery.consigneeContactName,
          address: `${orderDelivery.consigneeAddressName}, ${orderDelivery.consigneeCityName} ${orderDelivery.consigneeStateCode}, ${orderDelivery.consigneePostalCode} ${orderDelivery.consigneeCountryCode}`,
          date: getFormattedDate(orderDelivery.deliveryDate, false),
          notes: orderDelivery.deliveryNotes,
        });
      }
    });

    return orderTrackingRoutes;
  };

  useEffect(() => {
    if (isCreateMode) {
      getOrder({ orderId }).then((orderDto) => {
        setOrderTrackingRoutesDto(getOrderTrackingRoutes(orderDto));
      });
      setIsLoading(false);
    } else if (orderTrackingId) {
      getOrderTracking({ orderTrackingId }).then(
        (orderTrackingDto: OrderTrackingDto) => {
          getOrder({ orderId }).then((orderDto) => {
            const routes: string[] = orderTrackingDto.customValues[
              'routes'
            ]?.split(',');
            setOrderTrackingRoutesDto(
              getOrderTrackingRoutes(orderDto).filter((route) =>
                routes?.includes(route.type),
              ),
            );
            setOrderTracking(orderTrackingDto);
            setIsLoading(false);
          });
        },
      );
    } else {
      throw new Error('OrderTracking keys were not provided');
    }
  }, [orderTrackingId]);

  const onSubmit = (data: OrderTrackingDto) => {
    const isShipperSelected: boolean = data?.selectedRows.some((row: string) =>
      row.includes('Shipper'),
    );
    const isConsigneeSelected: boolean = data?.selectedRows.some(
      (row: string) => row.includes('Consignee'),
    );
    const isCorrectSelection: boolean =
      isShipperSelected && isConsigneeSelected;

    if (!isCorrectSelection) {
      addMessage({
        message: `You have to select at least one of Shippers and Consignees`,
        type: 'danger',
        id: v4(),
      });
    } else {
      data.fullName = data?.driverContactName;
      data.orderTrackingId = v4();
      data.orderId = orderId;
      data.customValues['routes'] = data?.selectedRows.join(',');

      setIsSending(true);
      if (isCreateMode) {
        createOrderTracking(data)
          .then(
            (result) => {
              onOrderTrackingCreated(result.data);
            },
            () => {},
          )
          .finally(() => setIsSending(false));
      }
    }
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <div className={'order-edit-form'}>
      <OrderTrackingForm
        id={'orderTracking-form'}
        initialValues={orderTracking}
        onSubmit={onSubmit}
        innerRef={ref}
        validationSchema={orderTrackingSchema}
      >
        <FormContext.Consumer>
          {(context) => (
            <>
              <p className="load-tracking-text">
                Enable load tracking on this load by sending an automated text
                message to the driver of this load. You must verify each stop
                address before submitting a track request.
              </p>
              <Tabs forceRenderTabPanel={true}>
                <TabList>
                  <Tab>Driver Details</Tab>
                </TabList>
                <TabPanel>
                  <Panel className="m-3">
                    <div className="row">
                      <div className={'col-3'}>
                        <OrderTrackingForm.CarrierContactSelect
                          disabled={!isCreateMode}
                          defaultValue={
                            orderTracking &&
                            orderTracking.carrierContactId != null &&
                            orderTracking.carrierContactName != null
                              ? {
                                  contactId: orderTracking.carrierContactId,
                                  name: orderTracking.carrierContactName,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setOrderTracking((orderTrackingDto) => {
                              if (!orderTrackingDto) {
                                orderTrackingDto = getInitialState();
                              }
                              orderTrackingDto.carrierContactId =
                                data?.contactId;
                              orderTrackingDto.carrierContactName = data?.name;
                              return { ...orderTrackingDto };
                            });
                          }}
                        />
                      </div>
                      <div className={'col-3'}>
                        <OrderTrackingForm.DriverContactSelect
                          disabled={!isCreateMode}
                          defaultValue={
                            orderTracking &&
                            orderTracking.driverContactId != null &&
                            orderTracking.driverContactName != null
                              ? {
                                  contactId: orderTracking.driverContactId,
                                  name: orderTracking.driverContactName,
                                }
                              : ''
                          }
                          onChange={(data?: ContactDto) => {
                            setOrderTracking((orderTrackingDto) => {
                              if (!orderTrackingDto) {
                                orderTrackingDto = getInitialState();
                              }
                              orderTrackingDto.driverContactId =
                                data?.contactId;
                              orderTrackingDto.driverContactName = data?.name;
                              orderTrackingDto.phoneNumber = data?.phoneNumber;
                              orderTrackingDto.emailAddress =
                                data?.emailAddress;
                              return { ...orderTrackingDto };
                            });
                          }}
                        />
                      </div>
                      <div className={'col-3'}>
                        <OrderTrackingForm.Phone
                          disabled={!isCreateMode}
                          defaultValue={orderTracking?.phoneNumber}
                          onChange={(data: any) => {
                            setOrderTracking((orderTrackingDto) => {
                              if (!orderTrackingDto) {
                                orderTrackingDto = getInitialState();
                              }
                              orderTrackingDto.phoneNumber = data.target.value;
                              return { ...orderTrackingDto };
                            });
                          }}
                        />
                      </div>
                      <div className={'col-3'}>
                        <OrderTrackingForm.Email
                          disabled={!isCreateMode}
                          defaultValue={orderTracking?.emailAddress}
                          onChange={(data: any) => {
                            setOrderTracking((orderTrackingDto) => {
                              if (!orderTrackingDto) {
                                orderTrackingDto = getInitialState();
                              }
                              orderTrackingDto.emailAddress = data.target.value;
                              return { ...orderTrackingDto };
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
              </Tabs>

              <Tabs forceRenderTabPanel={true}>
                <TabList>
                  <Tab>Route Stops</Tab>
                </TabList>
                <TabPanel>
                  <Panel className="m-3">
                    <OrderTrackingRoutesGrid
                      showEmptyTable={false}
                      showAllStore={true}
                      data={orderTrackingRoutesDto}
                      columns={columns}
                      total={orderTrackingRoutesDto?.length}
                      className={'order-tracking-routes-list'}
                      loading={isLoading}
                      context={context}
                      isCreateMode={isCreateMode}
                    />
                    <div className="justify-content-end row">
                      {isOrgAdminOrOperation && isCreateMode && (
                        <div className="col-3 pt-4">
                          <Button
                            type="submit"
                            form={'orderTracking-form'}
                            color="primary"
                            className="btn-block"
                            disabled={isSending}
                            isSending={isSending}
                          >
                            Save and Sent Request
                          </Button>
                        </div>
                      )}
                      <div className="col-3 pt-4">
                        <Button
                          type="button"
                          color="primary"
                          onClick={onCancel}
                          className="col-12 btn-light btn-cancel"
                          disabled={isSending}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
              </Tabs>
            </>
          )}
        </FormContext.Consumer>
      </OrderTrackingForm>
    </div>
  );
};
