import React, { useEffect, useState } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
} from 'react-google-maps';
import { GeolocationDto } from '../../../models/data.models';
import { Button } from '../../common/components/button/button.component';
import { getLocationName } from '../../../utils/helper.utils';

export type OrderTrackingMapProps = {
  onSaveLocation?: (locationName: string, lat: number, lng: number) => void;
  lat?: number;
  lng?: number;
  coords?: GeolocationCoordinates;
  isGeolocationAvailable?: boolean;
  isGeolocationEnabled?: boolean;
  isMobile?: boolean;
};

export const OrderTrackingMap = withScriptjs(
  withGoogleMap(
    ({
      onSaveLocation,
      lat,
      lng,
      coords,
      isGeolocationAvailable,
      isGeolocationEnabled,
      isMobile,
    }: OrderTrackingMapProps) => {
      const [geolocation, setGeolocation] = useState<GeolocationDto>(null);
      const [locationName, setLocationName] = useState<string>(null);

      useEffect(() => {
        if (lat && lng) {
          setGeolocation({
            lat: lat,
            lng: lng,
            zoom: 15,
          });
        } else {
          if (coords) {
            setGeolocation({
              lat: coords.latitude,
              lng: coords.longitude,
              zoom: 15,
            });
          }
          if (!isGeolocationAvailable || !isGeolocationEnabled) {
            setGeolocation({ lat: 40.341339, lng: -97.592237, zoom: 5 });
          }
        }
      }, [coords, isGeolocationAvailable, isGeolocationEnabled]);

      const handleLocation = (e) => {
        setGeolocation({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          zoom: geolocation.zoom,
        });
        getLocationName(e.latLng.lat(), e.latLng.lng()).then((result) => {
          setLocationName(result);
        });
      };

      useEffect(() => {
        if (geolocation) {
          getLocationName(geolocation.lat, geolocation.lng).then((result) => {
            setLocationName(result);
          });
        }
      }, [geolocation]);

      return (
        <>
          <GoogleMap
            zoom={geolocation?.zoom}
            center={{ lat: geolocation?.lat, lng: geolocation?.lng }}
            options={{ streetViewControl: false }}
            onClick={(e) => handleLocation(e)}
          >
            <Marker
              position={{ lat: geolocation?.lat, lng: geolocation?.lng }}
              options={{ draggable: true }}
              onDragEnd={(e) => handleLocation(e)}
            />
          </GoogleMap>

          <div className="justify-content-end row">
            <div className={`${isMobile ? 'col-12' : 'col-3'} pt-4`}>
              <Button
                type="button"
                color="primary"
                className="btn-block"
                onClick={() =>
                  onSaveLocation(locationName, geolocation.lat, geolocation.lng)
                }
              >
                Save Location
              </Button>
            </div>
          </div>
        </>
      );
    },
  ),
);
