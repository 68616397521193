import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetContactAddressesQuery } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import React from 'react';
import {
  CONTACTS_ADDRESS_CREATE_PATH,
  CONTACTS_ADDRESS_EDIT_PATH,
  CONTACTS_ADDRESS_LIST_PATH,
} from '../contactAddress.route';
import { ContactAddressesList } from '../components/contactAddresses-list.component';
import { GetContactAddressParams } from '../contactAddresses.service';
import { Helmet } from 'react-helmet';

export type ContactAddressesListScreenProps = { pageTitle: string };

export const ContactAddressesListScreen = ({
  pageTitle,
}: ContactAddressesListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = '',
  } = useQuery() as GetContactAddressesQuery;
  const history = useHistory();

  const createPath = generatePath(
    INTERNAL_PATH + CONTACTS_ADDRESS_CREATE_PATH,
    { organizationId },
  );

  const goToDetails = (contactAddressParams: GetContactAddressParams) => {
    const contactAddressId = contactAddressParams.contactAddressId;
    const createOrEdit =
      contactAddressId === 0
        ? CONTACTS_ADDRESS_CREATE_PATH
        : CONTACTS_ADDRESS_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      contactAddressId,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + CONTACTS_ADDRESS_LIST_PATH, {
      organizationId,
    });
    history.push(`${path}?offset=${page * limit}&limit=${limit}&sort=${sort}`);
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + CONTACTS_ADDRESS_LIST_PATH, {
      organizationId,
    });
    history.push(`${path}?offset=${offset}&limit=${limit}&sort=${field}`);
  };

  return (
    <InternalLayout title={'Contact Addresses'} createPath={createPath}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ContactAddressesList
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
      />
    </InternalLayout>
  );
};
