import React from 'react';
import StickyBox from 'react-sticky-box';
import { TopBar } from '../top-bar/top-bar.component';
import { useStore } from 'effector-react';
import { authStore, logout } from '../../../auth/auth.store';
import {
  organizationsStore,
  setCurrentOrganization,
} from '../../../organization/organization.store';
import { history } from '../../../../app.router';
import { CountryMenu } from '../../../countries/components/country.menu';
import { PortMenu } from '../../../port/components/port.menu';
import { DivisionMenu } from '../../../divisions/components/division.menu';
import { OrderMenu } from '../../../orders/components/order.menu';
import { PackageTypeMenu } from '../../../packageTypes/components/packageType.menu';
import { PackageCategoryMenu } from '../../../packageCategories/components/packageCategory.menu';
import { CustomersMenu } from '../../../customers/components/customer.menu';
import { CarrierMenu } from '../../../carriers/components/carrier.menu';
import { VendorMenu } from '../../../vendor/components/vendor.menu';
import { ContactMenu } from '../../../contacts/components/contact.menu';
import { DriverMenu } from '../../../drivers/components/driver.menu';
import { SalesPersonMenu } from '../../../salesPersons/components/salesPerson.menu';
import { EmployeeMenu } from '../../../employees/components/employee.menu';
import { navigationStore } from '../../nav.store';
import { ForwardingAgentMenu } from '../../../forwardingAgent/components/forwardingAgent.menu';
import { AccountingAccountMenu } from '../../../accountingAccounts/components/accountingAccount.menu';
import { SalesTaxesMenu } from '../../../salesTaxes/components/salesTaxes.menu';
import { StatesMenu } from '../../../states/components/state.menu';
import { AccountingItemMenu } from '../../../accountingItems/components/accountingItem.menu';
import { CurrenciesMenu } from '../../../currencies/components/currencies.menu';
import { PaymentTermMenu } from '../../../paymentTerms/components/paymentTerm.menu';
import { InvoiceMenu } from '../../../invoice/components/invoice.menu';
import { BillMenu } from '../../../bill/components/bill.menu';
import { CommodityMenu } from '../../../commodities/components/commodity.menu';
import { ContainerTypeMenu } from '../../../containerTypes/components/containerType.menu';
import { ContainerDescriptionMenu } from '../../../containerDescriptions/components/containerDescription.menu';
import { ModeOfTransportationMenu } from '../../../modeOfTransportations/components/modeOfTransportation.menu';
import {
  faCog,
  faUserCog,
  faPollH,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { CarrierPayManagerMenu } from '../../../carrierPayManager/components/carrierPayManager.menu';
import { InvoiceManagerMenu } from '../../../invoiceManager/components/invoiceManager.menu';
import { DriverPayManagerMenu } from '../../../driverPayManager/components/driverPayManager.menu';
import { EquipmentTypeMenu } from '../../../equipmentTypes/components/equipmentType.menu';
import { FactoringCompanyMenu } from '../../../factoringCompanies/components/factoringCompany.menu';
import { OrganizationMenu } from '../../../organization/components/organization.menu';
import { DocumentTemplateMenu } from '../../../documentTemplates/components/documentTemplate.menu';
import { CustomFieldMenu } from '../../../customFields/components/customFields.menu';
import { UsersMenu } from '../../../users/components/user.menu';
import { ActionsGroupComponent } from '../actions-group/actions-group.component';
import { GET_USERS_LINK_KEY } from '../../../users/users.service';
import { GET_CUSTOMFIELDS_LINK_KEY } from '../../../customFields/customFields.service';
import { GET_DOCUMENTTEMPLATES_LINK_KEY } from '../../../documentTemplates/documentTemplates.service';
import { GET_MODEOFTRANSPORTATIONS_LINK_KEY } from '../../../modeOfTransportations/modeOfTransportations.service';
import { GET_CURRENCIES_LINK_KEY } from '../../../currencies/currencies.service';
import { GET_ORGANIZATION_LINK_KEY } from '../../../organization/organization.service';
import { GET_ACCOUNTINGACCOUNTS_LINK_KEY } from '../../../accountingAccounts/accountingAccounts.service';
import { GET_SALESTAXES_LINK_KEY } from '../../../salesTaxes/salesTaxes.service';
import { GET_ACCOUNTINGITEMS_LINK_KEY } from '../../../accountingItems/accountingItems.service';
import { GET_PAYMENTTERMS_LINK_KEY } from '../../../paymentTerms/paymentTerms.service';
import { GET_ACCOUNTINGTRANSACTIONS_LINK_KEY } from '../../../accountingTransactions/accountingTransactions.service';
import { GET_CARRIER_PAYS_LINK_KEY } from '../../../carrierPayManager/carrierPayManager.service';
import { GET_UNINVOICED_ORDERS_LINK_KEY } from '../../../invoiceManager/invoiceManager.service';
import { GET_DRIVER_PAYS_LINK_KEY } from '../../../driverPayManager/driverPayManager.service';
import { GET_DIVISIONS_LINK_KEY } from '../../../divisions/divisions.service';
import { GET_COUNTRIES_LINK_KEY } from '../../../countries/countries.service';
import { GET_STATES_LINK_KEY } from '../../../states/states.service';
import { GET_PACKAGETYPES_LINK_KEY } from '../../../packageTypes/packageTypes.service';
import { GET_PACKAGECATEGORIES_LINK_KEY } from '../../../packageCategories/packageCategories.service';
import { GET_PORTS_LINK_KEY } from '../../../port/port.service';
import { GET_CONTACTS_LINK_KEY } from '../../../contacts/contacts.service';
import { GET_COMMODITIES_LINK_KEY } from '../../../commodities/commodities.service';
import { GET_CONTAINERTYPES_LINK_KEY } from '../../../containerTypes/containerTypes.service';
import { GET_CONTAINERDESCRIPTIONS_LINK_KEY } from '../../../containerDescriptions/containerDescriptions.service';
import { GET_EQUIPMENTTYPES_LINK_KEY } from '../../../equipmentTypes/equipmentType.service';
import { GET_USERGROUPS_LINK_KEY } from '../../../userGroups/userGroups.service';
import { UserGroupMenu } from '../../../userGroups/components/userGroup.menu';
import { PaymentMenu } from '../../../payments/components/payment.menu';
import { GET_PAYMENTS_LINK_KEY } from '../../../payments/payments.service';
import { EventDefinitionsMenu } from '../../../eventDefinitions/components/eventDefinitions.menu';
import { GET_EVENTDEFINITIONS_LINK_KEY } from '../../../eventDefinitions/eventDefinitions.service';
import { LeadsMenu } from '../../../leads/components/lead.menu';
import { QuoteMenu } from '../../../quotes/components/quote.menu';
import { DashboardMenu } from '../../../dashboard/components/dashboard.menu';
import { QuickBooksMenu } from '../../../quickBooks/components/quickBooks.menu';

export type InternalLayoutProps = {
  children: any;
  title: string;
  createPath?: string;
};
export const InternalLayout = ({
  children,
  title,
  createPath,
}: InternalLayoutProps) => {
  const { user } = useStore(authStore);
  const { organizations, currentOrganization } = useStore(organizationsStore);
  const navStore = useStore(navigationStore);
  const goToPage = (path: string) => {
    history.push(path);
  };
  return (
    <div className="d-flex w-100 nav-background">
      <aside
        className={`drawer px-5 border-right bg-white ${
          navStore.menuOpen ? '' : 'd-none'
        }`}
      >
        <StickyBox offsetTop={0} offsetBottom={20}>
          <div className="mud-drawer-content">
            <nav className="seahorse-navmenu">
              <div className="d-flex justify-center">
                <a
                  className={'link'}
                  onClick={() =>
                    goToPage(`/org/${currentOrganization.organizationId}/`)
                  }
                >
                  <img className="logo-seahorse-color" />
                </a>
              </div>

              <ul className="seahorse-navmenu pl-0">
                <DashboardMenu className="nav-item nav-ripple" />
                <OrderMenu className="nav-item nav-ripple" />
                <QuoteMenu className="nav-item nav-ripple" />
                <ActionsGroupComponent
                  actions={[
                    GET_COUNTRIES_LINK_KEY,
                    GET_STATES_LINK_KEY,
                    GET_PORTS_LINK_KEY,
                    GET_DIVISIONS_LINK_KEY,
                    GET_PACKAGETYPES_LINK_KEY,
                    GET_PACKAGECATEGORIES_LINK_KEY,
                    GET_CONTACTS_LINK_KEY,
                    GET_COMMODITIES_LINK_KEY,
                    GET_CONTAINERTYPES_LINK_KEY,
                    GET_CONTAINERDESCRIPTIONS_LINK_KEY,
                    GET_EQUIPMENTTYPES_LINK_KEY,
                  ]}
                  groupName={'maintenance'}
                  icon={faPollH}
                  displayGroupName={'Maintenance'}
                >
                  <>
                    <CountryMenu className="nav-item nav-ripple" />
                    <StatesMenu className="nav-item nav-ripple" />
                    <PortMenu className="nav-item nav-ripple" />
                    <DivisionMenu className="nav-item nav-ripple" />
                    <PackageTypeMenu className="nav-item nav-ripple" />
                    <PackageCategoryMenu className="nav-item nav-ripple" />
                    <CustomersMenu className="nav-item nav-ripple" />
                    <LeadsMenu className="nav-item nav-ripple" />
                    <CarrierMenu className="nav-item nav-ripple" />
                    <VendorMenu className="nav-item nav-ripple" />
                    <ContactMenu className="nav-item nav-ripple" />
                    <SalesPersonMenu className="nav-item nav-ripple" />
                    <FactoringCompanyMenu className="nav-item nav-ripple" />
                    <DriverMenu className="nav-item nav-ripple" />
                    <EmployeeMenu className="nav-item nav-ripple" />
                    <ForwardingAgentMenu className="nav-item nav-ripple" />
                    <CommodityMenu className="nav-item nav-ripple" />
                    <ContainerTypeMenu className="nav-item nav-ripple" />
                    <ContainerDescriptionMenu className="nav-item nav-ripple" />
                    <EquipmentTypeMenu className="nav-item nav-ripple" />
                  </>
                </ActionsGroupComponent>
                {!user?.isInOrgUserRole && (
                  <ActionsGroupComponent
                    actions={[
                      GET_ACCOUNTINGACCOUNTS_LINK_KEY,
                      GET_SALESTAXES_LINK_KEY,
                      GET_ACCOUNTINGITEMS_LINK_KEY,
                      GET_PAYMENTTERMS_LINK_KEY,
                      GET_ACCOUNTINGTRANSACTIONS_LINK_KEY,
                      GET_CARRIER_PAYS_LINK_KEY,
                      GET_UNINVOICED_ORDERS_LINK_KEY,
                      GET_DRIVER_PAYS_LINK_KEY,
                      GET_PAYMENTS_LINK_KEY,
                    ]}
                    groupName={'accounting'}
                    icon={faUserCircle}
                    displayGroupName={'Accounting'}
                  >
                    <>
                      <AccountingAccountMenu className="nav-item nav-ripple" />
                      <SalesTaxesMenu className="nav-item nav-ripple" />
                      <AccountingItemMenu className="nav-item nav-ripple" />
                      <PaymentTermMenu className="nav-item nav-ripple" />
                      <InvoiceMenu className="nav-item nav-ripple" />
                      <BillMenu className="nav-item nav-ripple" />
                      <CarrierPayManagerMenu className="nav-item nav-ripple" />
                      <InvoiceManagerMenu className="nav-item nav-ripple" />
                      <DriverPayManagerMenu className="nav-item nav-ripple" />
                      <PaymentMenu className="nav-item nav-ripple" />
                    </>
                  </ActionsGroupComponent>
                )}
                {!user?.isInSysAdminRole && (
                  <ActionsGroupComponent
                    actions={[
                      GET_CUSTOMFIELDS_LINK_KEY,
                      GET_DOCUMENTTEMPLATES_LINK_KEY,
                      GET_MODEOFTRANSPORTATIONS_LINK_KEY,
                      GET_CURRENCIES_LINK_KEY,
                      GET_ORGANIZATION_LINK_KEY,
                      GET_USERGROUPS_LINK_KEY,
                      GET_EVENTDEFINITIONS_LINK_KEY,
                    ]}
                    groupName={'systemConfiguration'}
                    icon={faCog}
                    displayGroupName={'Configuration'}
                  >
                    <>
                      {!user?.isInOrgUserRole && (
                        <CustomFieldMenu className="nav-item nav-ripple" />
                      )}
                      <DocumentTemplateMenu className="nav-item nav-ripple" />
                      <ModeOfTransportationMenu className="nav-item nav-ripple" />
                      <CurrenciesMenu className="nav-item nav-ripple" />
                      <OrganizationMenu className="nav-item nav-ripple" />
                      <UserGroupMenu className="nav-item nav-ripple" />
                      {!user?.isInOrgUserRole && (
                        <EventDefinitionsMenu className="nav-item nav-ripple" />
                      )}
                      {!user?.isInOrgUserRole && (
                        <QuickBooksMenu className="nav-item nav-ripple" />
                      )}
                    </>
                  </ActionsGroupComponent>
                )}
                <ActionsGroupComponent
                  actions={[GET_USERS_LINK_KEY]}
                  groupName={'systemAdministration'}
                  icon={faUserCog}
                  displayGroupName={'Administration'}
                >
                  <UsersMenu className="nav-item nav-ripple" />
                </ActionsGroupComponent>
              </ul>
            </nav>
          </div>
        </StickyBox>
      </aside>

      <div className="flex-grow-1">
        <header>
          <TopBar
            user={user}
            organizations={organizations}
            currentOrganization={currentOrganization}
            createPath={createPath}
            title={title}
            goToPage={goToPage}
            organizationSetCurrent={setCurrentOrganization}
            logout={logout}
          />
        </header>
        <div style={{ height: 'calc(100vh - 68px)' }}>{children}</div>
      </div>
    </div>
  );
};
