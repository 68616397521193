import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderDto } from '../../../models/data.models';
import { QuoteEdit } from './quote-edit.component';

export type QuoteDialogProperties = {
  orderId?: number | null;
  title: string;
  className?: string;
  onQuoteSaved?: (quote: OrderDto) => void;
  history?: any;
};

export class QuoteDialog extends Dialog<QuoteDialogProperties> {
  constructor(props: QuoteDialogProperties) {
    super(props);
    this.onQuoteSaved = this.onQuoteSaved.bind(this);
  }

  onQuoteSaved = (quote: OrderDto) => {
    this.close(quote);
  };

  renderContent(): any {
    const { orderId, history } = this.props as QuoteDialogProperties;
    return (
      <QuoteEdit
        orderId={orderId}
        onQuoteCreated={this.onQuoteSaved}
        onQuoteUpdated={this.onQuoteSaved}
        onCancel={this.close.bind(this)}
        history={history}
      />
    );
  }
}
