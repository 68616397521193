import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  SalesTaxDto,
  SalesTaxDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  salesTaxStore,
  getSalesTaxes,
  deleteSalesTax,
  updateSalesTaxesColumns,
} from '../salesTaxes.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_SALESTAX_LINK_KEY,
  DELETE_SALESTAX_LINK_KEY,
  GetSalesTaxParams,
} from '../salesTaxes.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { SALESTAX_CREATE_PATH } from '../salesTax.route';
import { SalesTaxDialog } from './salesTax.dialog';
import { userHas } from '../../auth/auth.store';

export type SalesTaxesListProps = {
  goToDetails?: (salesTaxParams: GetSalesTaxParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (salesTax: SalesTaxDto, salesTaxParams: GetSalesTaxParams) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const SalesTaxesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  showAllStore = true,
  rowKeys = [
    'authorityContactName',
    'taxCode',
    'description',
    'rate',
    'created',
  ],
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: SalesTaxesListProps) => {
  const [salesTaxes, setSalesTaxes] = useState<SalesTaxDtoPagedResult | null>(
    null,
  );

  useEffect(() => {
    getSalesTaxesData();
  }, [offset, limit, sort, filter, search]);
  const getSalesTaxesData = () => {
    getSalesTaxes({ offset, limit, sort, filter, search }).then(
      (salesTaxesData) => {
        setSalesTaxes(salesTaxesData);
      },
    );
  };
  const { salesTaxColumns: columns } = useStore(salesTaxStore);

  const onDeleteSalesTax = (salesTax: SalesTaxDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${salesTax.taxCode} Sales Tax`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteSalesTax(salesTax).then(() => {
          onDelete();
          getSalesTaxesData();
        });
      }
    });
  };

  const onEditSalesTax = (salesTax: SalesTaxDto) => {
    goToDetails({ salesTaxId: salesTax.salesTaxId });
  };
  const history = useHistory();
  const onCreateNewSalesTax = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + SALESTAX_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onSalesTaxSelect = (salesTax, salesTaxId) => {
    if (onSelect) onSelect(salesTax, salesTaxId);
    showDialog({
      dialog: SalesTaxDialog,
      props: {
        salesTaxId: salesTax.salesTaxId,
        title: 'Update Sales Tax',
      },
    }).then((salesTax) => {
      if (salesTax !== null) {
        getSalesTaxesData();
      }
    });
  };

  return (
    <>
      <Grid
        isDropDownList={isDropDownList}
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={showAllStore}
        rowKeys={rowKeys}
        data={salesTaxes?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={salesTaxes?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_SALESTAX_LINK_KEY, salesTaxes?.items[0]?.links)
            ? onDeleteSalesTax
            : null
        }
        onSort={onSort}
        onEdit={onEditSalesTax}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateSalesTaxesColumns}
        onSelect={onSalesTaxSelect}
        className={'salesTaxes-list'}
        filter={filter}
        onFilter={onFilter}
      />
      {!(
        (salesTaxes && salesTaxes.items && salesTaxes.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">You Don't Have Any Sales Tax Yet</h3>
          {userHas(CREATE_SALESTAX_LINK_KEY) && (
            <Button size={'lg'} color="primary" onClick={onCreateNewSalesTax}>
              Create Sales Tax
            </Button>
          )}
        </div>
      )}
    </>
  );
};
