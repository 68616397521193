import {
  ContainerDescriptionDto,
  LinkDto,
  LinkResourceBaseDto,
  ContainerDescriptionDtoPagedResult,
  CreateContainerDescriptionCommandValues,
  UpdateContainerDescriptionCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createContainerDescriptionRequest,
  getContainerDescriptionsListRequest,
  getContainerDescriptionRequest,
  deleteContainerDescriptionRequest,
  updateContainerDescriptionRequest,
  GetContainerDescriptionParams,
} from './containerDescriptions.service';

type ContainerDescriptionsStoreState = {
  links: LinkDto[];
  containerDescriptionColumns: Column[];
};

export const updateContainerDescriptionsColumns = createEvent<Column[]>();

export const getContainerDescriptions = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContainerDescriptionsListRequest(currentOrganization, params);
  },
);

export const createContainerDescription = createEffect(
  (containerDescriptionData: ContainerDescriptionDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createContainerDescriptionCommand: CreateContainerDescriptionCommandValues = {
      ...containerDescriptionData,
    };

    return createContainerDescriptionRequest(
      currentOrganization!,
      createContainerDescriptionCommand,
    );
  },
);

export const getContainerDescription = createEffect(
  (containerDescriptionParams: GetContainerDescriptionParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContainerDescriptionRequest(
      currentOrganization as LinkResourceBaseDto,
      containerDescriptionParams,
    );
  },
);

export const updateContainerDescription = createEffect(
  (containerDescription: ContainerDescriptionDto) => {
    const updateContainerDescriptionCommand: UpdateContainerDescriptionCommandValues = {
      ...containerDescription,
    };
    return updateContainerDescriptionRequest(
      containerDescription,
      updateContainerDescriptionCommand,
    );
  },
);

export const deleteContainerDescription = createEffect(
  (containerDescription: ContainerDescriptionDto) => {
    return deleteContainerDescriptionRequest(containerDescription);
  },
);

const defaultState: ContainerDescriptionsStoreState = {
  links: [],
  containerDescriptionColumns: [
    {
      name: 'containerDescriptionCode',
      visible: true,
      title: 'Container Description Code',
      sortName: 'containerDescriptionCode',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
  ],
};

export const containerDescriptionStore: Store<ContainerDescriptionsStoreState> = createStore(
  defaultState,
).on(updateContainerDescriptionsColumns, (state, payload) => {
  return { ...state, containerDescriptionColumns: payload };
});
