import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  Column,
  ContactDto,
  ContactType,
  ContactViewDto,
  ContactViewDtoPagedResult,
  ContactViewPagedResult,
  CustomFieldEntityType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  contactFilterFields,
  deleteContact,
  getContacts,
} from '../../contacts/contacts.store';
import { updateEmployeesColumns, employeeStore } from '../employee.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { EMPLOYEES_CREATE_PATH } from '../employee.route';
import { EmployeeDialog } from './employee.dialog';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { getCustomFields } from '../../customFields/customFields.store';
import { authStore, userHas } from '../../auth/auth.store';
import {
  CREATE_CONTACT_LINK_KEY,
  DELETE_CONTACT_LINK_KEY,
} from '../../contacts/contacts.service';
import { ContactsGrid } from '../../common/components/grid/contacts-grid.component';

export type EmployeeListProps = {
  goToDetails?: (id?: number) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (country: ContactDto, contactId: string) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const EmployeeList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  filter = `contactType:${ContactType.Employee}`,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch,
}: EmployeeListProps) => {
  const { user: currentUser } = authStore.getState();

  const [contacts, setContacts] = useState<ContactViewDtoPagedResult | null>(
    null,
  );
  const history = useHistory();
  useEffect(() => {
    getCustomFieldsColumns();
    getContactsData();
  }, [offset, limit, sort, filter, search]);
  const getContactsData = () => {
    getContacts({ offset, limit, sort, filter, search }).then(
      (contact) => {
        const contactPagedResult: ContactViewPagedResult = {
          items: contact.items.map((item) => {
            const contactViewDto: ContactViewDto = {
              contactId: item.contactId,
              accountNumber: item.accountNumber,
              contactFirstName: item.contactFirstName,
              created: getFormattedDate(item.created),
              contactLastName: item.contactLastName,
              contactType: item.contactType,
              createdBy: item.createdBy,
              createdByUserName: item.createdByUserName,
              divisionId: item.divisionId,
              divisionName: item.divisionName,
              emailAddress: item.emailAddress,
              faxNumber: item.faxNumber,
              idNumber: item.idNumber,
              idNumberType: item.idNumberType,
              lastModified: getFormattedDate(item.lastModified),
              lastModifiedBy: item.lastModifiedBy,
              lastModifiedByUserName: item.lastModifiedByUserName,
              links: item.links,
              mobilePhoneNumber: item.mobilePhoneNumber,
              name: item.name,
              organizationId: item.organizationId,
              linkToContactId: item.linkToContactId,
              phoneNumber: item.phoneNumber,
              website: item.website,
            };
            if (item?.customValues) {
              Object.keys(item.customValues).forEach(
                (customFieldInternalName) => {
                  if (!contactViewDto.hasOwnProperty(customFieldInternalName))
                    contactViewDto[customFieldInternalName] =
                      item.customValues[customFieldInternalName];
                },
              );
            }
            return contactViewDto;
          }),
          limit: contact.limit,
          links: contact.links,
          offset: contact.offset,
          totalCount: contact.totalCount,
        };

        setContacts(contactPagedResult);
      },
      () => {},
    );
  };

  const [customFieldsNames, setCustomFieldsNames] = useState<string[]>([]);
  const getCustomFieldsColumns = () => {
    getCustomFields({
      filter: `customFieldEntityType: ${CustomFieldEntityType.Employee} AND isInactive: false AND isSystem: false`,
    }).then((customFieldsData) => {
      const customFieldsColumns: Column[] = [];
      const customFieldsColumnsNames: string[] = [];
      customFieldsData.items.forEach((customFieldDto) => {
        customFieldsColumns.push({
          name: customFieldDto.internalName,
          visible: true,
          title: customFieldDto.displayName,
          showFilter: true,
          type: 'customField',
          customFieldType: customFieldDto.customFieldType,
        });
        customFieldsColumnsNames.push(customFieldDto.internalName);
      });
      setCustomFieldsNames(customFieldsColumnsNames);
      const existingColumns = employeeStore.getState().employeeColumns;
      const existingColumnsNames: string[] = employeeStore
        .getState()
        .employeeColumns.map((column) => {
          return column.name;
        });
      const customFieldsColumnsToAdd: Column[] = [];
      customFieldsColumns.forEach((customColumn) => {
        if (existingColumnsNames.indexOf(customColumn.name) === -1) {
          customFieldsColumnsToAdd.push(customColumn);
        }
      });
      for (
        let i = employeeStore.defaultState.employeeColumns.length;
        i < existingColumns.length;
        i++
      ) {
        if (customFieldsColumnsNames.indexOf(existingColumns[i].name) === -1) {
          existingColumns.splice(i, 1);
        }
      }
      if (customFieldsColumnsToAdd.length > 0) {
        updateEmployeesColumns([
          ...existingColumns,
          ...customFieldsColumnsToAdd,
        ]);
      }
    });
  };

  const { employeeColumns: columns } = useStore(employeeStore);
  const onEmployeeSelect = (employee, employeeId) => {
    if (onSelect) onSelect(employee, employeeId);
    showDialog({
      dialog: EmployeeDialog,
      props: {
        contactId: employee.contactId,
        title: 'Update Employee',
      },
    }).then(
      (employee) => {
        if (employee !== null) {
          getContactsData();
        }
      },
      () => {},
    );
  };

  const onDeleteContact = (contact: ContactDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete Employee`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteContact(contact).then(() => {
          onDelete();
          getContactsData();
        });
      }
    });
  };

  if (
    (contacts &&
      contacts.items &&
      contacts.items.length &&
      contacts.items.length > 0) ||
    (filter && filter !== `contactType:${ContactType.Employee}`) ||
    search
  ) {
    return (
      <ContactsGrid
        contactType={'Employee'}
        showEmptyTable={true}
        onSearch={onSearch}
        search={search}
        showAllStore={true}
        showAllFilters={false}
        rowIncludeFilterKeys={[...contactFilterFields, ...customFieldsNames]}
        rowKeys={['contactId']}
        data={contacts?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={contacts?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_CONTACT_LINK_KEY, contacts?.items[0]?.links) &&
          currentUser?.isInOrgAdminRole
            ? onDeleteContact
            : null
        }
        onSort={onSort}
        onEdit={(contact: ContactDto) => {
          goToDetails(contact.contactId);
        }}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateEmployeesColumns}
        onSelect={onEmployeeSelect}
        onFilter={onFilter}
        filter={filter}
        getContactsData={getContactsData}
      />
    );
  }
  return (
    <div className="m-5 text-center">
      <h3 className="text-muted mb-4">You Don't Have Any Employee Yet</h3>
      {userHas(CREATE_CONTACT_LINK_KEY) && currentUser?.isInOrgAdminRole && (
        <Button
          size={'lg'}
          color="primary"
          onClick={() => {
            const { currentOrganization } = organizationsStore.getState();
            const createPath = generatePath(
              INTERNAL_PATH + EMPLOYEES_CREATE_PATH,
              {
                organizationId: currentOrganization.organizationId,
              },
            );
            history.push(createPath);
          }}
        >
          Create Employee
        </Button>
      )}
    </div>
  );
};
