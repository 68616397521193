import {
  SalesTaxDto,
  LinkDto,
  LinkResourceBaseDto,
  SalesTaxDtoPagedResult,
  CreateSalesTaxCommandValues,
  UpdateSalesTaxCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_SALESTAXES_LINK_KEY = 'get-salestaxes';
export const CREATE_SALESTAX_LINK_KEY = 'create-salestax';
export const GET_SALESTAX_LINK_KEY = 'get-salestax';
export const UPDATE_SALESTAX_LINK_KEY = 'update-salestax';
export const DELETE_SALESTAX_LINK_KEY = 'delete-salestax';

export const SALES_TAX_ENTITY_NAME = 'Sales Tax';

export interface GetSalesTaxParams {
  salesTaxId: number;
}

export const getSalesTaxRequest = async (
  resource: LinkResourceBaseDto | null,
  { salesTaxId }: GetSalesTaxParams,
): Promise<SalesTaxDto> => {
  if (resource && salesTaxId) {
    const getSalesTaxLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_SALESTAX_LINK_KEY,
    );
    if (getSalesTaxLink) {
      const result = await execLink(getSalesTaxLink, { salesTaxId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_SALESTAX_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getSalesTaxesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<SalesTaxDtoPagedResult> => {
  if (resource) {
    const getSalesTaxesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_SALESTAXES_LINK_KEY,
    );
    if (getSalesTaxesListLink) {
      const result = await execLink(getSalesTaxesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_SALESTAXES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createSalesTaxRequest = async (
  resource: LinkResourceBaseDto,
  salesTax: CreateSalesTaxCommandValues,
) => {
  const createSalesTaxLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_SALESTAX_LINK_KEY,
  );
  if (createSalesTaxLink) {
    const result = await execLink(createSalesTaxLink, salesTax);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_SALESTAX_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateSalesTaxRequest = async (
  resource: LinkResourceBaseDto,
  salesTax: UpdateSalesTaxCommandValues,
) => {
  const updateSalesTaxLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_SALESTAX_LINK_KEY,
  );
  if (updateSalesTaxLink) {
    const result = await execLink(updateSalesTaxLink, salesTax);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_SALESTAX_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteSalesTaxRequest = async (salesTax: SalesTaxDto) => {
  const deleteSalesTaxLink = salesTax.links?.find(
    (x: LinkDto) => x.rel === DELETE_SALESTAX_LINK_KEY,
  );
  if (deleteSalesTaxLink) {
    const result = await execLink(deleteSalesTaxLink, salesTax);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_SALESTAX_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
