import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DriverScreen } from './screens/driver.screen';
import { DriverListScreen } from './screens/driver-list.screen';

export const DRIVERS_LIST_PATH = '/drivers';
export const DRIVERS_EDIT_PATH = '/drivers/:contactId';
export const DRIVERS_CREATE_PATH = '/drivers/0';
export const DRIVERS_PATH = '/org/:organizationId/drivers';

export const DriversRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${DRIVERS_LIST_PATH}`}>
          <DriverListScreen pageTitle="Drivers" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${DRIVERS_CREATE_PATH}`}>
          <DriverScreen pageTitle="Add New Driver" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${DRIVERS_EDIT_PATH}`}>
          <DriverScreen pageTitle="Update Driver" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
