import React, { ChangeEvent, useEffect } from 'react';
import {
  Form,
  FormProps,
} from '../../../common/components/form/form.component';
import {
  ContactSelectInputProps,
  CustomValuesInputProps,
  Input,
  InputPublicProps,
} from '../../../common/components/input/input.component';
import { SelectContactPaginate } from '../../../contacts/components/contact-react-select.component';
import { ReactEnumInputSelect } from '../../../common/components/input/enum-input.select';

export const FiltersForm = ({
  children,
  onSubmit,
  initialValues,
  id,
  innerRef,
  validationSchema,
  className,
}: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      id={id}
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      className={className}
    >
      {children}
    </Form>
  );
};

FiltersForm.Textbox = ({
  id,
  placeholder,
  header,
  defaultValue,
  onChange = () => {},
}: ContactSelectInputProps) => {
  return (
    <Input
      key={id}
      size={'sm'}
      name={id}
      type={'filterText'}
      label={header}
      placeholder={placeholder}
      valueInput={defaultValue}
      onChange={onChange}
    />
  );
};

FiltersForm.CustomerSelect = ({
  selectedFilter = ``,
  nameId = '__BillToContactName',
  placeholder = 'Select Bill To Customer',
  required = false,
  defaultValue,
  sort = 'name',
  id,
  header = 'Customer',
  onChange = () => {},
  readonly,
  customStyles,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      nameId={nameId}
      readonly={readonly === 'readonly'}
      selectedSort={sort}
      required={required}
      isMulti={false}
      showContactType={true}
      useContext={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      header={header}
      placeholder={placeholder}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      canCreateNewEntity={false}
      customStyles={customStyles}
    />
  );
};

FiltersForm.CarrierContactSelect = ({
  selectedFilter = '',
  placeholder = 'Select Carrier',
  sort = 'name',
  searchKey = 'name',
  defaultValue,
  id = 'carrierContactId',
  header = 'Carrier / Driver',
  required = false,
  onChange = () => {},
  readonly,
  customStyles,
}: ContactSelectInputProps) => {
  return (
    <SelectContactPaginate
      id={id}
      readonly={readonly === 'readonly'}
      required={required}
      showContactType={true}
      useContext={true}
      isMulti={false}
      header={header}
      placeholder={placeholder}
      selectedSort={sort}
      selectedSearchKey={searchKey}
      selectedValue={defaultValue}
      selectedFilter={selectedFilter}
      onChangeContact={onChange}
      canCreateNewEntity={false}
      customStyles={customStyles}
    />
  );
};

FiltersForm.Date = ({
  className,
  style,
  size,
  defaultValue,
  onChange,
  readonly,
  id,
  name,
  disabled,
  header,
  placeholder,
}: InputPublicProps) => {
  return (
    <Input
      readonly={readonly}
      id={id}
      name={name}
      label={header}
      placeholder={placeholder}
      required={false}
      type={'date'}
      className={className}
      style={style}
      size={size}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

FiltersForm.OrderStatus = ({
  onChange,
  options,
  name = 'orderStatus',
  id = 'orderStatus',
  placeholder = 'Select Order Status',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Order Status',
  defaultValue = '',
  readonly,
  customStyles,
}: InputPublicProps) => {
  return (
    <ReactEnumInputSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={true}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      tabIndex={5}
      useContext={true}
      customStyles={customStyles}
    />
  );
};

FiltersForm.IdNumberType = ({
  onChange,
  options,
  name = 'idNumberType',
  id = 'idNumberType',
  placeholder = 'Select Id Number Type',
  required = false,
  multiple = false,
  disabled = false,
  header = 'Id Number Type',
  defaultValue = '',
  readonly,
  customStyles,
}: InputPublicProps) => {
  return (
    <ReactEnumInputSelect
      name={name}
      id={id}
      error={false}
      isSearchable={true}
      isClearable={true}
      readonly={readonly}
      header={header}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      options={options}
      tabIndex={5}
      useContext={true}
      customStyles={customStyles}
    />
  );
};
