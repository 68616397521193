import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  getOrganization,
  organizationsStore,
  setCurrentOrganization,
} from '../../organization/organization.store';
import { getRedirectOrganization } from '../../../utils/helper.utils';

export const QuickBooksRedirectScreen = () => {
  const { currentOrganization } = organizationsStore.getState();
  const oldOrganizationId = currentOrganization.organizationId;
  const newOrganizationId = localStorage.getItem('referer_org');
  getRedirectOrganization(newOrganizationId);

  getOrganization({
    organizationId: Number(newOrganizationId),
  })
    .then((organizationDto) => setCurrentOrganization(organizationDto))
    .catch(() => getRedirectOrganization(oldOrganizationId.toString()));

  if (newOrganizationId) {
    return (
      <Redirect
        to={`/org/${newOrganizationId}/quickBooks/${window.location.search}`}
      />
    );
  }

  return <Redirect to="/login" />;
};
