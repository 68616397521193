import {
  UserGroupDto,
  LinkDto,
  LinkResourceBaseDto,
  UserGroupDtoPagedResult,
  CreateUserGroupCommandValues,
  UpdateUserGroupCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createUserGroupRequest,
  getUserGroupsListRequest,
  getUserGroupRequest,
  deleteUserGroupRequest,
  updateUserGroupRequest,
  GetUserGroupParams,
} from './userGroups.service';

type UserGroupsStoreState = {
  links: LinkDto[];
  userGroupColumns: Column[];
};

export const updateUserGroupsColumns = createEvent<Column[]>();

export const getUserGroups = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getUserGroupsListRequest(currentOrganization, params);
});

export const createUserGroup = createEffect((userGroupData: UserGroupDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createUserGroupCommand: CreateUserGroupCommandValues = {
    ...userGroupData,
  };

  return createUserGroupRequest(currentOrganization!, createUserGroupCommand);
});

export const getUserGroup = createEffect(
  (userGroupParams: GetUserGroupParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getUserGroupRequest(
      currentOrganization as LinkResourceBaseDto,
      userGroupParams,
    );
  },
);

export const updateUserGroup = createEffect((userGroup: UserGroupDto) => {
  const updateUserGroupCommand: UpdateUserGroupCommandValues = { ...userGroup };
  return updateUserGroupRequest(userGroup, updateUserGroupCommand);
});

export const deleteUserGroup = createEffect((userGroup: UserGroupDto) => {
  return deleteUserGroupRequest(userGroup);
});

const defaultState: UserGroupsStoreState = {
  links: [],
  userGroupColumns: [
    {
      name: 'userGroupId',
      visible: false,
      title: 'User Group Id',
      sortName: 'userGroupId',
    },
    { name: 'name', visible: true, title: 'Name', sortName: 'name' },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
  ],
};

export const userGroupStore: Store<UserGroupsStoreState> = createStore(
  defaultState,
).on(updateUserGroupsColumns, (state, payload) => {
  return { ...state, userGroupColumns: payload };
});
