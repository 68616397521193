import { DecimalPlace } from '../models/data.models';

export const getFormattedDate = (
  date: string | Date,
  time: boolean = true,
): string => {
  if (date) {
    if (time) {
      return new Date(date).toLocaleString('en-US', {
        hour12: false,
      });
    } else {
      return new Date(date).toLocaleString('en-US', {
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }
  }
};

export const getMaxDecimals = (decimalPlaces?: DecimalPlace) => {
  switch (decimalPlaces) {
    case DecimalPlace.Two:
      return 2;
    default:
      return null;
  }
};

export const getFormattedPrice = (
  price?: number,
  decimalPlaces?: DecimalPlace,
  currencySymbol: string = '',
) => {
  const decimals = getMaxDecimals(decimalPlaces);
  const formattedPrice = `${currencySymbol}${price
    ?.toFixed(decimals ?? 0)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  return formattedPrice;
};

export const getSingleCommaFormattedPrice = (
  price?: number,
  decimalPlaces?: DecimalPlace,
) => {
  const decimals = getMaxDecimals(decimalPlaces);
  return price?.toFixed(decimals ?? 0);
};
