import React, { useState } from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { Button } from '../button/button.component';

export type ReactDropzoneProps = {
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  children?: JSX.Element;
};

export const ReactDropzoneComponent = ({
  onDrop,
  children,
}: ReactDropzoneProps) => {
  const [dragOver, setDragOver] = useState(false);
  const onDragOver = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setDragOver(true);
  };
  const onDragLeave = () => setDragOver(false);
  const onFilesDrop = (acceptedFiles, fileRejections, event) => {
    event.preventDefault();
    onDrop(acceptedFiles, fileRejections, event);
    setDragOver(false);
  };
  const getDragElementProps = (props) => {
    props.onClick = () => {};
    return props;
  };
  const getClickElementProps = (props) => {
    props.onDrop = () => {};
    return props;
  };
  return (
    <Dropzone
      onDrop={onFilesDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getDragElementProps(getRootProps())}
            className={'position-relative'}
          >
            {dragOver && (
              <div
                className={
                  'position-absolute w-100 h-100 bg-white border border-primary rounded z-index-1 d-flex justify-content-center align-items-center'
                }
                style={{ zIndex: 1, pointerEvents: 'none' }}
              >
                <h3 className="text-muted">Drop files here</h3>
              </div>
            )}
            <div className="col-3">
              <Button
                type="button"
                color="primary"
                className="w-100 cursor-pointer px-3"
                {...getClickElementProps(getRootProps())}
              >
                Click here or drag'n'drop files to upload
              </Button>
            </div>
            <input {...getInputProps()} />
            {children}
          </div>
        </section>
      )}
    </Dropzone>
  );
};
