import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PortsListScreen } from './screens/ports-list.screen';
import { PortScreen } from './screens/port.screen';

export const PORTS_LIST_PATH = '/ports';
export const PORTS_EDIT_PATH = '/ports/:portId';
export const PORTS_CREATE_PATH = '/ports/0';
export const PORTS_PATH = '/org/:organizationId/ports';

export const PortRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${PORTS_LIST_PATH}`}>
          <PortsListScreen pageTitle="Ports" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${PORTS_CREATE_PATH}`}>
          <PortScreen pageTitle="Add New Port" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${PORTS_EDIT_PATH}`}>
          <PortScreen pageTitle="Update Port" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
