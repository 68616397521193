import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  AccountType,
  AccountingAccountDto,
  AccountingAccountDtoPagedResult,
  OrganizationConfigDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  accountingAccountStore,
  getAccountingAccounts,
  deleteAccountingAccount,
  updateAccountingAccountsColumns,
  downloadQuickBooksAccountingAccounts,
} from '../accountingAccounts.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_ACCOUNTINGACCOUNT_LINK_KEY,
  DELETE_ACCOUNTINGACCOUNT_LINK_KEY,
  GetAccountingAccountParams,
} from '../accountingAccounts.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { ACCOUNTINGACCOUNT_CREATE_PATH } from '../accountingAccount.route';
import { AccountingAccountDialog } from './accountingAccount.dialog';
import { userHas } from '../../auth/auth.store';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';
import { getOrganizationConfigs } from '../../organizationConfigs/organizationConfigs.store';

export type AccountingAccountsListProps = {
  goToDetails?: (accountingAccountParams: GetAccountingAccountParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    accountingAccount: AccountingAccountDto,
    accountingAccountParams: GetAccountingAccountParams,
  ) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const AccountingAccountsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: AccountingAccountsListProps) => {
  // @ts-ignore
  const { organizationId } = useParams();

  const [
    accountingAccounts,
    setAccountingAccounts,
  ] = useState<AccountingAccountDtoPagedResult | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [QBConnected, setQBConnected] = useState<boolean>(false);

  useEffect(() => {
    getAccountingAccountsData();
  }, [offset, limit, sort, filter, search]);

  useEffect(() => {
    getQuickBooksData();
  }, []);

  const getAccountingAccountsData = () => {
    getAccountingAccounts({ offset, limit, sort, filter, search }).then(
      (accountingAccountsData) => {
        accountingAccountsData.items.forEach((item) => {
          item.accountType = AccountType[item.accountType];
        });
        setAccountingAccounts(accountingAccountsData);
      },
      () => {},
    );
  };
  const { accountingAccountColumns: columns } = useStore(
    accountingAccountStore,
  );

  const onDeleteAccountingAccount = (
    accountingAccount: AccountingAccountDto,
  ) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${accountingAccount.accountId} Accounting Account`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteAccountingAccount(accountingAccount).then(
            () => {
              onDelete();
              getAccountingAccountsData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const getQuickBooksData = async (): Promise<void> => {
    if (organizationId) {
      const qbConfigName = 'QuickBooks';
      getOrganizationConfigs({
        limit: 1,
        offset: 0,
        sort: '',
        filter: `configName:${qbConfigName} AND organizationId:${organizationId}`,
        search: '',
      }).then(
        (quickBooksDto: OrganizationConfigDtoPagedResult) => {
          if (quickBooksDto.items.length === 0) {
            setQBConnected(false);
          } else {
            setQBConnected(true);
          }
        },
        () => {},
      );
    }
  };

  const onEditAccountingAccount = (accountingAccount: AccountingAccountDto) => {
    goToDetails({ accountId: accountingAccount.accountId });
  };
  const history = useHistory();
  const onCreateNewAccountingAccount = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(
      INTERNAL_PATH + ACCOUNTINGACCOUNT_CREATE_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    history.push(createPath);
  };
  const onAccountingAccountSelect = (
    accountingAccount,
    accountingAccountId,
  ) => {
    if (onSelect) onSelect(accountingAccount, accountingAccountId);
    showDialog({
      dialog: AccountingAccountDialog,
      props: {
        accountId: accountingAccount.accountId,
        title: 'Update Accounting Account',
      },
    }).then(
      (accountingAccount) => {
        if (accountingAccount !== null) {
          getAccountingAccountsData();
        }
      },
      () => {},
    );
  };

  const downloadQuickBooksAccounts = () => {
    setIsLoading(true);
    downloadQuickBooksAccountingAccounts()
      .then(getAccountingAccountsData, () => {})
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <>
      <Grid
        isDropDownList={isDropDownList}
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={['accountId']}
        data={accountingAccounts?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={accountingAccounts?.totalCount}
        sort={sort}
        onDelete={
          userHas(
            DELETE_ACCOUNTINGACCOUNT_LINK_KEY,
            accountingAccounts?.items[0]?.links,
          )
            ? onDeleteAccountingAccount
            : null
        }
        onSort={onSort}
        onEdit={onEditAccountingAccount}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateAccountingAccountsColumns}
        onSelect={onAccountingAccountSelect}
        filter={filter}
        onFilter={onFilter}
        className={'accountingAccounts-list'}
        actionBar={
          <ActionBarComponent
            buttonText={<>&middot;&middot;&middot;</>}
            style={{ minWidth: 'fit-content' }}
          >
            <button
              disabled={!QBConnected}
              type={'button'}
              onClick={downloadQuickBooksAccounts}
            >
              Download QuickBooks Accounts
            </button>
          </ActionBarComponent>
        }
      />
      {!(
        (accountingAccounts &&
          accountingAccounts.items &&
          accountingAccounts.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Accounting Account Yet
          </h3>
          <div className="m-4">
            {userHas(CREATE_ACCOUNTINGACCOUNT_LINK_KEY) && (
              <Button
                size={'lg'}
                color="primary"
                onClick={onCreateNewAccountingAccount}
              >
                Create Accounting Account
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
