import { createStore, createEffect, Store, Event, createEvent } from 'effector';
import {
  LinkDto,
  UpdateUserCommandValues,
  UserDto,
} from '../../models/data.models';
import { execLink, postURLSearchParams, renewToken } from '../api/api.service';
import { addRoute, authError } from '../api/api.store';
import { history } from '../../app.router';
import { clearMessages } from '../common/messages.store';
import * as Sentry from '@sentry/browser';

import {
  clearOrganizations,
  organizationsStore,
} from '../organization/organization.store';

export type AuthStoreState = {
  links: any;
  user?: UserDto | null;
  token?: string | null;
  refreshToken?: string | null;
};

export const userHas = (linkKey: string, linkSet?: LinkDto[]): boolean => {
  if (linkSet) {
    return linkSet.some((x: LinkDto) => x.rel === linkKey);
  }
  const { currentOrganization } = organizationsStore.getState();
  return currentOrganization?.links?.some((x: LinkDto) => x.rel === linkKey);
};

export const getUserAdditionalData = () => {
  const { currentOrganization } = organizationsStore.getState();
  const { user: currentUser } = authStore.getState();

  currentUser.divisionId =
    currentUser.organizationDivisionIds[currentOrganization.organizationId];

  currentUser.isInOrgAdminRole =
    currentUser.organizationAdminRoles[currentOrganization.organizationId];

  currentUser.isInOperationRole =
    currentUser.organizationOperationRoles[currentOrganization.organizationId];

  currentUser.isInAccountingRole =
    currentUser.organizationAccountingRoles[currentOrganization.organizationId];

  currentUser.isInOrgUserRole =
    currentUser.organizationUserRoles[currentOrganization.organizationId];

  currentUser.visibleTransactions =
    currentUser.organizationVisibleTransactions[
      currentOrganization.organizationId
    ];
};

export const getUserInfo = createEffect(() => {
  const state: AuthStoreState = authStore.getState();
  const link: LinkDto = state.links['userInfo'];
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  if (token) {
    return execLink(link).then(
      (result) => {
        return result.data;
      },
      () => {},
    );
  } else {
    return renewToken().then(() =>
      execLink(link).then((result) => {
        return result.data;
      }),
    );
  }
});

export const updateUserInfo = createEffect(async (user: UserDto) => {
  const updateUserCommand: UpdateUserCommandValues = { ...user };
  const state: AuthStoreState = authStore.getState();
  const link: LinkDto = state.links['updateUserInfo'];
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  if (token) {
    const result = await execLink(link, updateUserCommand);
    return result.data;
  } else {
    return renewToken().then(async () => {
      const result = await execLink(link, updateUserCommand);
      return result.data;
    });
  }
});

export type LoginParams = {
  grant_type: string;
  username?: string;
  password?: string;
  code?: string;
  redirect_uri?: string;
  rememberMe: boolean;
};

export const login = createEffect((loginParams: LoginParams) => {
  clearMessages();

  const state: AuthStoreState = authStore.getState();
  const link: LinkDto = state.links['getToken'];
  if (link && link.href) {
    const authParams: any = {
      grant_type: loginParams.grant_type,
      scope: 'offline_access Seahorse.TMS.ApiAPI',
      client_id: 'Seahorse.TMS.ClientApp',
    };
    switch (loginParams.grant_type) {
      case 'password':
        authParams.username = loginParams.username;
        authParams.password = loginParams.password;
        break;
      case 'external-google':
        authParams.code = loginParams.code;
        authParams.redirect_uri = loginParams.redirect_uri;
        break;
      default:
        return Promise.reject(new Error('Unsupported grant_type'));
    }

    return postURLSearchParams(link.href, authParams);
  }
  return Promise.reject(new Error("Link can't be blank"));
});

export const logout: Event<void> = createEvent();

export type SignUpData = {
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  password: string;
};

export const signUp = createEffect((signUpData: SignUpData) => {
  const state: AuthStoreState = authStore.getState();
  const registerLink: LinkDto = state.links['registerUrl'];
  return execLink(registerLink, signUpData)
    .then((result) => {
      return result.data;
    })
    .then((data) => {
      const signInLink: LinkDto = state.links['getToken'];
      if (signInLink && signInLink.href) {
        return postURLSearchParams(signInLink.href, {
          grant_type: 'otac',
          code: data.oneTimeAuthorizationCode,
          username: signUpData.userName,
          scope: 'offline_access Seahorse.TMS.ApiAPI',
          client_id: 'Seahorse.TMS.ClientApp',
        });
      }
      return Promise.reject(new Error("Link can't be blank"));
    });
});

export type ForgotPasswordData = {
  username: string;
};

export const forgotPassword = createEffect(
  (forgotPasswordData: ForgotPasswordData) => {
    const state: AuthStoreState = authStore.getState();
    const link: LinkDto = state.links['sendResetPasswordEmail'];

    if (link && link.href) {
      return execLink(link, forgotPasswordData).then((result) => result.data);
    }
    return Promise.reject(new Error("Link can't be blank"));
  },
);

export type ResetPasswordData = {
  userId: string;
  code: string;
  password: string;
};

export const resetPassword = createEffect(
  (resetPasswordData: ResetPasswordData) => {
    const state: AuthStoreState = authStore.getState();
    const link: LinkDto = state.links['resetUserPassword'];

    if (link && link.href) {
      return execLink(link, resetPasswordData).then((result) => result.data);
    }
    return Promise.reject(new Error("Link can't be blank"));
  },
);

const defaultState: AuthStoreState = {
  links: {},
  user: null,
  token: null,
  refreshToken: null,
};

const setTokens = ({ token, refreshToken }) => {
  if (token) {
    localStorage.setItem('token', token);
    sessionStorage.setItem('token', token);
  }
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
    sessionStorage.setItem('refreshToken', refreshToken);
  }
};

const removeTokens = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('refreshToken');
};

export const authStore: Store<AuthStoreState> = createStore(defaultState)
  .on(addRoute, (state: any, payload: LinkDto) => {
    switch (payload.rel) {
      case 'register-user':
        state.links.registerUrl = payload;
        break;
      case 'get-token':
        state.links.getToken = payload;
        break;
      case 'get-userInfo':
        state.links.userInfo = payload;
        break;
      case 'update-userInfo':
        state.links.updateUserInfo = payload;
        break;
      case 'send-reset-password-email':
        state.links.sendResetPasswordEmail = payload;
        break;
      case 'reset-user-password':
        state.links.resetUserPassword = payload;
        break;
      case 'get-organizations':
        state.links.getOrganizations = payload;
        break;
    }
    return state;
  })
  .on(getUserInfo.done, (state, payload) => {
    payload.result.links.forEach((link) => addRoute(link));
    Sentry.setUser({ email: payload.result?.email });
    return { ...state, user: payload.result };
  })
  .on(getUserInfo.fail, (state, payload) => {
    clearOrganizations();
    return { ...state, user: null };
  })
  .on(updateUserInfo.done, (state, payload) => {
    getUserInfo().then(
      (userDto: UserDto) => {
        return { ...state, user: userDto };
      },
      () => {
        return { ...state };
      },
    );
  })
  .on(login.done, (state, payload) => {
    const token = payload.result.data.access_token;
    const refreshToken = payload.result.data.refresh_token;
    setTokens({ token, refreshToken });
    return { ...state, token, refreshToken };
  })
  .on(signUp.done, (state, payload) => {
    const token = payload.result.data.access_token;
    const refreshToken = payload.result.data.refresh_token;
    setTokens({ token, refreshToken });
    return { ...state, token, refreshToken };
  })
  .on(renewToken.done, (state, payload) => {
    const token = payload.result.data.access_token;
    const refreshToken = payload.result.data.refresh_token;
    setTokens({ token, refreshToken });
    return { ...state, token, refreshToken };
  })
  .on(authError, (state, payload) => {
    removeTokens();
    clearOrganizations();
    history.push('/login');
    return { ...state, user: null, token: null, refreshToken: null };
  })
  .on(logout, (state, payload) => {
    removeTokens();
    clearOrganizations();
    Sentry.setUser(null);
    history.push('/login');
    return { ...state, user: null, token: null, refreshToken: null };
  });
