import React, { useEffect, useState } from 'react';
import {
  CustomFieldDto,
  CustomFieldEntityType,
  CustomFieldType,
} from '../../../../models/data.models';
import { getCustomFields } from '../../../customFields/customFields.store';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Panel } from '../panel/panel.component';
import { Input } from './input.component';
import { Button } from '../button/button.component';
import { validateNumberInput } from '../../../../utils/helper.utils';
import { FormikProps, FormikValues } from 'formik';

export type CustomValuesInputProps = {
  entityType: CustomFieldEntityType;
  defaultValue?: object;
  customFields?: CustomFieldDto[];
  saveButtonRenderCondition?: boolean;
  isSending?: boolean;
  formName?: string;
  entityName?: string;
  context?: FormikProps<FormikValues>;
  onChange?: (result: object) => void;
  onSubmit?: (result: object) => void;
  onCancel?: (result: object) => void;
};

export const CustomValuesInput = ({
  entityType,
  defaultValue = {},
  customFields = [],
  isSending,
  saveButtonRenderCondition,
  formName,
  entityName,
  context,
  onSubmit = (result) => {},
  onChange = (result) => {},
  onCancel = (result) => {},
}: CustomValuesInputProps) => {
  const [fieldsByCategories, setFieldsByCategories] = useState([]);

  for (let el in defaultValue) {
    if (defaultValue[el] === 'true') {
      defaultValue[el] = true;
    } else if (defaultValue[el] === 'false') {
      defaultValue[el] = false;
    }
  }
  const [stateValue, setStateValue] = useState(defaultValue);

  const getFields = async () => {
    if (!customFields) {
      getCustomFields({
        filter: `customFieldEntityType: ${entityType} AND isSystem: false`,
      }).then((fields) => {
        setFieldsByCategories((fieldsByCategories) => {
          fieldsByCategories = [];
          fields.items.forEach((field) => {
            let currentCategory = fieldsByCategories.find(
              (group) => group.category === field.category,
            );
            if (!currentCategory) {
              currentCategory = {
                category: field.category,
                fields: [],
              };
              fieldsByCategories.push(currentCategory);
            }
            currentCategory.fields.push(field);
          });
          return [...fieldsByCategories];
        });
      });
    } else {
      setFieldsByCategories((fieldsByCategories) => {
        fieldsByCategories = [];
        customFields.forEach((field) => {
          let currentCategory = fieldsByCategories.find(
            (group) =>
              group.category === field.category ||
              (!group.category && !field.category),
          );
          if (!currentCategory) {
            currentCategory = {
              category: field.category,
              fields: [],
            };
            fieldsByCategories.push(currentCategory);
          }
          currentCategory.fields.push(field);
        });
        return [...fieldsByCategories];
      });
    }
  };

  useEffect(() => {
    getFields();
  }, [customFields]);

  useEffect(() => {
    onChange(stateValue);
  }, [stateValue]);

  return (
    <Tabs>
      <TabList>
        {fieldsByCategories.map((group) => {
          return <Tab>{group.category ? group.category : 'General'}</Tab>;
        })}
      </TabList>
      <div>
        {fieldsByCategories.map((group) => {
          return (
            <TabPanel forceRender={true}>
              <Panel className="m-3">
                <div className="row">
                  <div className="col-4">
                    {group.fields.map((field) => {
                      switch (field.customFieldType) {
                        case CustomFieldType.Text:
                          return (
                            <Input
                              type={'input-OnChange-text-with-value'}
                              name={field.internalName}
                              valueInput={
                                stateValue[field.internalName]
                                  ? stateValue[field.internalName]
                                  : null
                              }
                              placeholder={field.displayName}
                              header={field.displayName}
                              label={field.displayName}
                              onChange={(value) => {
                                setStateValue((stateValue) => {
                                  stateValue[field.internalName] = value;
                                  return { ...stateValue };
                                });
                              }}
                              maxLength={200}
                            />
                          );
                        case CustomFieldType.Number:
                          return (
                            <Input
                              type={'text'}
                              name={`customValues.${field.internalName}`}
                              defaultValue={
                                stateValue[field.internalName] &&
                                !isNaN(stateValue[field.internalName])
                                  ? stateValue[field.internalName]
                                  : null
                              }
                              placeholder={field.displayName}
                              header={field.displayName}
                              label={field.displayName}
                              onChange={(value) => {
                                setStateValue((stateValue) => {
                                  stateValue[field.internalName] =
                                    value.target.value;
                                  context.setFieldValue(
                                    `customValues.${field.internalName}`,
                                    value.target.value,
                                  );
                                  return { ...stateValue };
                                });
                              }}
                              onKeyDown={validateNumberInput}
                            />
                          );
                        case CustomFieldType.Date:
                          return (
                            <Input
                              type={'date'}
                              name={field.internalName}
                              defaultValue={
                                stateValue[field.internalName] &&
                                Date.parse(stateValue[field.internalName])
                                  ? stateValue[field.internalName]
                                  : null
                              }
                              placeholder={field.displayName}
                              header={field.displayName}
                              label={field.displayName}
                              onChange={(value) => {
                                setStateValue((stateValue) => {
                                  stateValue[field.internalName] = value;
                                  return { ...stateValue };
                                });
                              }}
                            />
                          );
                        case CustomFieldType.Boolean:
                          return (
                            <Input
                              type={'checkbox'}
                              name={`customValues.${field.internalName}`}
                              label={field.displayName}
                              onChange={(value) => {
                                setStateValue((stateValue) => {
                                  stateValue[field.internalName] = value;
                                  return { ...stateValue };
                                });
                              }}
                            />
                          );
                      }
                    })}
                  </div>
                </div>
                <div className="justify-content-end row mt-3">
                  {saveButtonRenderCondition && (
                    <div className="col-3">
                      <Button
                        form={formName}
                        type="submit"
                        color="primary"
                        className="w-100 btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save {entityName}
                      </Button>
                    </div>
                  )}
                  <div className="col-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="w-100 btn-secondary"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Panel>
            </TabPanel>
          );
        })}
        {customFields.length === 0 && (
          <Panel className="m-3">
            <div className="mb-0 text-center">
              <h3 className="text-muted">
                There are no custom fields for this entity
              </h3>
            </div>
            <div className="justify-content-end row mt-3">
              {saveButtonRenderCondition && (
                <div className="col-3">
                  <Button
                    form={formName}
                    type="submit"
                    color="primary"
                    className="w-100 btn-block"
                    disabled={isSending}
                    isSending={isSending}
                  >
                    Save {entityName}
                  </Button>
                </div>
              )}
              <div className="col-3">
                <Button
                  type="button"
                  color="primary"
                  onClick={onCancel}
                  className="w-100 btn-secondary"
                  disabled={isSending}
                >
                  Close
                </Button>
              </div>
            </div>
          </Panel>
        )}
      </div>
    </Tabs>
  );
};
