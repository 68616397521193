import { v4 } from 'uuid';
import {
  OrderTrackingDto,
  LinkDto,
  LinkResourceBaseDto,
  OrderTrackingDtoPagedResult,
  CreateOrderTrackingCommandValues,
} from '../../models/data.models';
import { API_ENDPOINT } from '../../properties';
import { execLink } from '../api/api.service';
import { addMessage } from '../common/messages.store';
import { ListParams } from '../common/models/ListParams';

export const GET_ORDERTRACKINGS_LINK_KEY = 'get-ordertrackings';
export const CREATE_ORDERTRACKING_LINK_KEY = 'create-ordertracking';
export const GET_ORDERTRACKING_LINK_KEY = 'get-ordertracking';
export const UPDATE_ORDERTRACKING_LINK_KEY = 'update-ordertracking';
export const DELETE_ORDERTRACKING_LINK_KEY = 'delete-ordertracking';

export interface GetOrderTrackingParams {
  orderTrackingId: string;
}

export const getOrderTrackingRequest = async (
  resource: LinkResourceBaseDto | null,
  { orderTrackingId }: GetOrderTrackingParams,
): Promise<OrderTrackingDto> => {
  if (resource && orderTrackingId) {
    const getOrderTrackingLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERTRACKING_LINK_KEY,
    );
    if (getOrderTrackingLink) {
      const result = await execLink(getOrderTrackingLink, { orderTrackingId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDERTRACKING_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrderTrackingsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrderTrackingDtoPagedResult> => {
  if (resource) {
    const getOrderTrackingsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERTRACKINGS_LINK_KEY,
    );
    if (getOrderTrackingsListLink) {
      const result = await execLink(getOrderTrackingsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDERTRACKINGS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createOrderTrackingRequest = async (
  resource: LinkResourceBaseDto,
  orderTracking: CreateOrderTrackingCommandValues,
) => {
  const createOrderTrackingLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORDERTRACKING_LINK_KEY,
  );

  if (createOrderTrackingLink) {
    const result = await execLink(createOrderTrackingLink, orderTracking);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_ORDERTRACKING_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrderTrackingAnonymous = async (
  organizationId: string,
  orderTrackingId: string,
): Promise<OrderTrackingDto> => {
  if (organizationId && orderTrackingId) {
    const url = `${API_ENDPOINT}/api/organizations/${organizationId}/orders/orderTrackings/${orderTrackingId}`;
    const getOrderTrackingLink: LinkDto = {
      href: url,
      templated: false,
      type: 'get',
    };
    const result = await execLink(getOrderTrackingLink);
    return result.data;
  }
  return Promise.reject();
};
