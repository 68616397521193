import {
  CreateOrderCommandValues,
  LinkDto,
  LinkResourceBaseDto,
  OrderDto,
  OrderDtoPagedResult,
  PatchCommandBase,
  UpdateChargeCommand,
  UpdateCommodityCommand,
  UpdateOrderCommandValues,
  UpdateOrderDeliveryCommand,
  UpdateOrderPickupCommand,
  UpdateTrackingEventCommand,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';
import { API_ENDPOINT } from '../../properties';
import { carriersAndCustomValuesCreation } from './orders.store';

export const GET_ORDERS_LINK_KEY = 'get-orders';
export const CREATE_ORDER_LINK_KEY = 'create-order';
export const GET_ORDER_LINK_KEY = 'get-order';
export const UPDATE_ORDER_LINK_KEY = 'update-order';
export const PATCH_ORDER_LINK_KEY = 'patch-order';
export const DELETE_ORDER_LINK_KEY = 'delete-order';
export const GET_ORDER_CONFIRMATION_LINK_KEY = 'get-order-confirmation';

export interface GetOrderParams {
  orderId: number;
}
export interface GetOrderConfirmationParams {
  orderId: number;
}
export interface PatchOrderParams {
  resource: LinkResourceBaseDto;
  patchOrderCommand: PatchCommandBase;
}
export const getOrderRequest = async (
  resource: LinkResourceBaseDto | null,
  { orderId }: GetOrderParams,
): Promise<OrderDto> => {
  if (resource && orderId) {
    const getOrderLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ORDER_LINK_KEY,
    );
    if (getOrderLink) {
      const result = await execLink(getOrderLink, { orderId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrdersListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<OrderDtoPagedResult> => {
  if (resource) {
    const getOrdersListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ORDERS_LINK_KEY,
    );
    if (getOrdersListLink) {
      const result = await execLink(getOrdersListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ORDERS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createOrderRequest = async (
  resource: LinkResourceBaseDto,
  order: CreateOrderCommandValues,
) => {
  const createOrderLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ORDER_LINK_KEY,
  );
  if (createOrderLink) {
    const result = await execLink(createOrderLink, order);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateOrderRequest = async (
  resource: LinkResourceBaseDto,
  order: UpdateOrderCommandValues,
) => {
  const updateOrderLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ORDER_LINK_KEY,
  );
  if (updateOrderLink) {
    const result = await execLink(updateOrderLink, order);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateOrderAnonymous = async (
  organizationId: string,
  order: OrderDto,
) => {
  if (organizationId && order) {
    const charges: UpdateChargeCommand[] = order?.charges?.map<UpdateChargeCommand>(
      (item) => {
        const updateChargeCommand: UpdateChargeCommand = {
          chargeId: item.chargeId,
          values: {
            amount: item.amount,
            currencyId: item.currencyId,
            accountingItemId: item.accountingItemId,
            applyBy: item.applyBy,
            applyToContactId: item.applyToContactId,
            chargeType: item.chargeType,
            description: item.description,
            freightServiceClassId: item.freightServiceClassId,
            grossVolume: item.grossVolume,
            grossWeight: item.grossWeight,
            isConsolidated: item.isConsolidated,
            note: item.note,
            paidAs: item.paidAs,
            pieces: item.pieces,
            price: item.price,
            quantity: item.quantity,
            showInDocuments: item.showInDocuments,
            unit: item.unit,
            salesTaxRate: item.salesTaxRate ?? 0,
            totalAmount: item.totalAmount,
            salesTaxId: item.salesTaxId,
            salesTaxAmount: item.salesTaxAmount,
            allowAutomaticUpdate: item.allowAutomaticUpdate,
          },
        };
        return updateChargeCommand;
      },
    );
    const commodities: UpdateCommodityCommand[] = order?.commodities?.map<UpdateCommodityCommand>(
      (item) => {
        const updateCommodityCommand: UpdateCommodityCommand = {
          commodityId: item.commodityId,
          values: {
            commodityStatus: item.commodityStatus,
            description: item.description,
            dimensionsUnit: item.dimensionsUnit,
            height: item.height,
            length: item.length,
            note: item.note,
            organizationId: item.organizationId,
            packageTypeId: item.packageTypeId,
            pieces: item.pieces,
            quantity: item.quantity,
            unit: item.unit,
            unitaryValue: item.unitaryValue,
            volumePiece: item.volumePiece,
            volumeTotal: item.volumeTotal,
            volumeUnit: item.volumeUnit,
            weight: item.weight,
            weightByTotal: item.weightByTotal,
            weightTotal: item.weightTotal,
            weightUnit: item.weightUnit,
            width: item.width,
          },
        };
        return updateCommodityCommand;
      },
    );
    const orderDeliveries: UpdateOrderDeliveryCommand[] = order.orderDeliveries.map<UpdateOrderDeliveryCommand>(
      (item) => {
        const updateOrderDeliveryCommand: UpdateOrderDeliveryCommand = {
          orderDeliveryId: item.orderDeliveryId,
          orderId: item.orderId,
          values: {
            consigneeAddressId: item.consigneeAddressId,
            consigneeContactId: item.consigneeContactId,
            deliveryDate: item.deliveryDate,
            deliveryNotes: item.deliveryNotes,
            orderDeliverySequence: item.orderDeliverySequence,
          },
        };
        return updateOrderDeliveryCommand;
      },
    );
    const orderPickups: UpdateOrderPickupCommand[] = order.orderPickups.map<UpdateOrderPickupCommand>(
      (item) => {
        const updateOrderPickupCommand: UpdateOrderPickupCommand = {
          orderPickupId: item.orderPickupId,
          orderId: item.orderId,
          values: {
            orderPickupSequence: item.orderPickupSequence,
            pickDate: item.pickDate,
            shipperAddressId: item.shipperAddressId,
            shipperContactId: item.shipperContactId,
            shippingNotes: item.shippingNotes,
          },
        };
        return updateOrderPickupCommand;
      },
    );

    const trackingEvents: UpdateTrackingEventCommand[] = order?.trackingEvents?.map<UpdateTrackingEventCommand>(
      (item) => {
        const updateTrackingEventCommand: UpdateTrackingEventCommand = {
          trackingEventId: item.trackingEventId,
          values: {
            description: item.description,
            location: item.location,
            eventDate: item.eventDate,
            eventDefinitionId: item.eventDefinitionId,
            isInactive: item.isInactive,
            includeInTracking: item.includeInTracking,
            sendEmail: item.sendEmail,
          },
        };
        return updateTrackingEventCommand;
      },
    );

    const { carriersId, customValues } = carriersAndCustomValuesCreation(order);

    const updateOrderCommand: UpdateOrderCommandValues = {
      orderStatus: order.orderStatus,
      commodities,
      billToContactId: order.billToContactId,
      carrierContactId: order.carrierContactId,
      divisionId: order.divisionId,
      equipmentTypeId: order.equipmentTypeId,
      employeeContactId: order.employeeContactId,
      orderDeliveries,
      orderPickups,
      salespersonContactId: order.salespersonContactId,
      orderNumber: order.orderNumber,
      charges,
      trackingEvents,
      carriersId,
      customValues,
      trackingNumber: order.trackingNumber,
      orderType: order.orderType,
    };

    const url = `${API_ENDPOINT}/api/organizations/${organizationId}/orders/updateAnonymous/${order.orderId}`;
    const updateOrderLink: LinkDto = {
      href: url,
      templated: false,
      type: 'put',
    };
    const result = await execLink(updateOrderLink, updateOrderCommand);
    return result.data;
  }
  return Promise.reject();
};

export const patchOrderRequest = async (
  resource: LinkResourceBaseDto,
  order: PatchCommandBase,
) => {
  const patchOrderLink = resource.links?.find((x: LinkDto) => {
    return x.rel === PATCH_ORDER_LINK_KEY;
  });
  if (patchOrderLink) {
    const result = await execLink(patchOrderLink, order?.patchItems);
    return result.data;
  }
  addMessage({
    message: `Action ${PATCH_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteOrderRequest = async (order: OrderDto) => {
  const deleteOrderLink = order.links?.find(
    (x: LinkDto) => x.rel === DELETE_ORDER_LINK_KEY,
  );
  if (deleteOrderLink) {
    const result = await execLink(deleteOrderLink, order);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_ORDER_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getOrderAnonymous = async (
  organizationId: string,
  orderId: number,
): Promise<OrderDto> => {
  if (organizationId && orderId) {
    const url = `${API_ENDPOINT}/api/organizations/${organizationId}/orders/getAnonymous/${orderId}`;
    const getOrderLink: LinkDto = {
      href: url,
      templated: false,
      type: 'get',
    };
    const result = await execLink(getOrderLink);
    return result.data;
  }
  return Promise.reject();
};
