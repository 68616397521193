import React from 'react';
import { Panel } from '../../common/components/panel/panel.component';
import { CreateOrganization } from '../components/create-organization.component';
import { OrganizationDto } from '../../../models/data.models';
import { createOrganization } from '../organization.store';
import { history } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { getUserAdditionalData, getUserInfo } from '../../auth/auth.store';

export type CreateOrganizationScreenProps = { pageTitle: string };

export const CreateOrganizationScreen = ({
  pageTitle,
}: CreateOrganizationScreenProps) => {
  const onSubmit = (data: OrganizationDto) => {
    createOrganization(data)
      .then(
        (organization) => {
          const message: Message = {
            id: 'organization-created',
            type: 'success',
            autoHide: true,
            message: 'Organization successfully created!',
          };
          history.push(`/org/${organization.organizationId}`);
          addMessage(message);
        },
        () => {},
      )
      .finally(() => {
        getUserInfo().then(() => getUserAdditionalData());
      });
  };
  return (
    <div className="background-gray h-100">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="container py-5 background-register">
        <div className="d-flex justify-content-center">
          <Panel style={{ maxWidth: '500px' }}>
            <h1 className="text-center register-header font-weight-light mb-5">
              Create organization
            </h1>
            <CreateOrganization onSubmit={onSubmit} />
          </Panel>
        </div>
      </div>
    </div>
  );
};
