import {
  OrganizationConfigDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateOrganizationConfigCommandValues,
  UpdateOrganizationConfigCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createOrganizationConfigRequest,
  getOrganizationConfigsListRequest,
  getOrganizationConfigRequest,
  deleteOrganizationConfigRequest,
  updateOrganizationConfigRequest,
  GetOrganizationConfigParams,
} from './organizationConfigs.service';

type OrganizationConfigsStoreState = {
  links: LinkDto[];
  organizationConfigColumns: Column[];
};

export const updateOrganizationConfigsColumns = createEvent<Column[]>();

export const getOrganizationConfigs = createEffect(
  (params: ListParams = {}) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getOrganizationConfigsListRequest(currentOrganization, params);
  },
);

export const createOrganizationConfig = createEffect(
  (organizationConfigData: OrganizationConfigDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createOrganizationConfigCommand: CreateOrganizationConfigCommandValues = {
      ...organizationConfigData,
    };

    return createOrganizationConfigRequest(
      currentOrganization!,
      createOrganizationConfigCommand,
    );
  },
);

export const getOrganizationConfig = createEffect(
  (organizationConfigParams: GetOrganizationConfigParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getOrganizationConfigRequest(
      currentOrganization as LinkResourceBaseDto,
      organizationConfigParams,
    );
  },
);

export const updateOrganizationConfig = createEffect(
  (organizationConfig: OrganizationConfigDto) => {
    const updateOrganizationConfigCommand: UpdateOrganizationConfigCommandValues = {
      ...organizationConfig,
    };
    return updateOrganizationConfigRequest(
      organizationConfig,
      updateOrganizationConfigCommand,
    );
  },
);

export const deleteOrganizationConfig = createEffect(
  (organizationConfig: OrganizationConfigDto) => {
    return deleteOrganizationConfigRequest(organizationConfig);
  },
);

const defaultState: OrganizationConfigsStoreState = {
  links: [],
  organizationConfigColumns: [
    {
      name: 'organizationConfigId',
      visible: true,
      title: 'Organization Config Id',
      sortName: 'organizationConfigId',
    },
    {
      name: 'configName',
      visible: true,
      title: 'Config Name',
      sortName: 'configName',
    },
  ],
};

export const organizationConfigStore: Store<OrganizationConfigsStoreState> = createStore(
  defaultState,
).on(updateOrganizationConfigsColumns, (state, payload) => {
  return { ...state, organizationConfigColumns: payload };
});
