import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { ContactType, GetContactsQuery } from '../../../models/data.models';
import {
  VENDORS_CREATE_PATH,
  VENDORS_EDIT_PATH,
  VENDORS_LIST_PATH,
} from '../vendor.route';
import { CONTACTS_TAB_EDIT_PATH_FOR_VENDORS } from '../../contacts/contact.route';
import { VendorList } from '../components/vendor-list.component';
import { Helmet } from 'react-helmet';
import { CREATE_CONTACT_LINK_KEY } from '../../contacts/contacts.service';
import { userHas } from '../../auth/auth.store';

export type VendorListScreenProps = { pageTitle: string };

export const VendorListScreen = ({ pageTitle }: VendorListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = 'contactId',
    filter = `contactType:${ContactType.Vendor}`,
    search = '',
  } = useQuery() as GetContactsQuery;
  const history = useHistory();

  const createPath = () => {
    return generatePath(INTERNAL_PATH + VENDORS_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = (contactId: number = 0) => {
    let path;
    if (window.location.href.includes('contacts')) {
      path = generatePath(INTERNAL_PATH + CONTACTS_TAB_EDIT_PATH_FOR_VENDORS, {
        organizationId,
        contactId,
      });
    } else {
      path = generatePath(INTERNAL_PATH + VENDORS_EDIT_PATH, {
        organizationId,
        contactId,
      });
    }
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + VENDORS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + VENDORS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + VENDORS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + VENDORS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };

  return (
    <InternalLayout
      title={'Vendors'}
      createPath={userHas(CREATE_CONTACT_LINK_KEY) ? createPath() : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <VendorList
        onSearch={onSearch}
        search={search}
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        filter={filter}
      />
    </InternalLayout>
  );
};
