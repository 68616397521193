import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';

import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { ContactEdit } from '../components/contact-edit.component';
import { CONTACTS_CREATE_PATH, CONTACTS_LIST_PATH } from '../contact.route';
import { addMessage, Message } from '../../common/messages.store';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import { CREATE_CONTACT_LINK_KEY } from '../contacts.service';
import { userHas } from '../../auth/auth.store';

export type ContactScreenProps = { pageTitle: string; isTab?: boolean };

export const ContactScreen = ({
  pageTitle,
  isTab = false,
}: ContactScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, contactId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + CONTACTS_CREATE_PATH, {
    organizationId,
  });

  const onContactCreated = () => {
    const path = generatePath(INTERNAL_PATH + CONTACTS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'contact-created',
      type: 'success',
      autoHide: true,
      message: 'Contact successfully created!',
    };
    history.push(path);
    addMessage(message);
  };

  const onContactUpdated = () => {
    const message: Message = {
      id: 'contact-updated',
      type: 'success',
      autoHide: true,
      message: 'Contact successfully updated!',
    };
    if (isTab) {
      window.history.back();
    } else {
      const path = generatePath(INTERNAL_PATH + CONTACTS_LIST_PATH, {
        organizationId,
      });
      history.push(path);
    }
    addMessage(message);
  };

  const onCancel = () => {
    window.history.back();
  };

  return (
    <InternalLayout
      title={'Contacts'}
      createPath={userHas(CREATE_CONTACT_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ContactEdit
        contactId={contactId}
        onContactUpdated={onContactUpdated}
        onContactCreated={onContactCreated}
        onCancel={onCancel}
        isTab={isTab}
      />
    </InternalLayout>
  );
};
