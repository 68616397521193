import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { InvoiceManagerListScreen } from './screens/invoiceManager-list.screen';

export const INVOICE_MANAGER_LIST_PATH = '/invoice-manager';
export const INVOICE_MANAGER_PATH = '/org/:organizationId/invoice-manager';

export const InvoiceManagerRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <PrivateRoute exact path={`${match.path}${INVOICE_MANAGER_LIST_PATH}`}>
        <InvoiceManagerListScreen pageTitle="Invoice Manager" />
      </PrivateRoute>
    </Route>
  );
};
