import React, { ChangeEvent } from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const PackageCategoryForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

PackageCategoryForm.PackageCategoryCode = ({
  className,
  style,
  size,
  disabled,
}: InputPublicProps) => {
  return (
    <Input
      name={'packageCategoryCode'}
      label={'Package Category Code'}
      placeholder={'Package Category Code'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
      disabled={disabled}
    />
  );
};
PackageCategoryForm.IsContainer = ({
  className,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'isContainer'}
      id={'isContainerCheckbox'}
      label={'Is Container'}
      placeholder={'Is Container'}
      required={true}
      type={'checkbox'}
      className={className}
      style={style}
      size={size}
    />
  );
};
PackageCategoryForm.Name = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'name'}
      label={'Name'}
      placeholder={'Name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
