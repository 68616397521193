import React, { CSSProperties, useState } from 'react';
import { FormContext } from '../form/form.component';
import { ErrorMessage, Field, FormikProps, FormikValues } from 'formik';
import { clearMessages } from '../../messages.store';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { FaLandmark, FaLocationArrow } from 'react-icons/fa';

export type PlaceAutocompleteAsyncInputProps = {
  name?: string;
  header?: string;
  placeholder?: string;
  defaultValue?: string;
  onSelect?: (
    results: any,
    context: FormikProps<FormikValues>,
  ) => Promise<string>;
  customStyles?: CSSProperties;
  renderDropdown?: boolean;
  onChange?: () => void;
  types?: string[];
};

export const PlaceAutocompleteAsyncInput = ({
  name,
  header,
  placeholder = '',
  defaultValue = '',
  onSelect,
  customStyles = {},
  renderDropdown = true,
  onChange = () => {},
  types = ['address'],
}: PlaceAutocompleteAsyncInputProps) => {
  const [stateValue, setStateValue] = useState(defaultValue);

  const getIconForType = (types) => {
    if (types.includes('establishment')) {
      return <FaLandmark />;
    }
    return <FaLocationArrow />;
  };

  return (
    <FormContext.Consumer>
      {(context) => (
        <>
          <Field type="hidden" name={name} value={stateValue} />
          <PlacesAutocomplete
            value={stateValue}
            debounce={1000}
            onChange={(address) => {
              setStateValue(address);
              context.setFieldValue(name, address);
            }}
            onSelect={
              onSelect &&
              ((address: string, placeId?: string, suggestion?: object) => {
                if (placeId && suggestion) {
                  geocodeByPlaceId(placeId)
                    .then(async (results) => {
                      console.log(results);
                      setStateValue(
                        await onSelect({ ...results[0], suggestion }, context),
                      );
                    })
                    .catch((error) => console.error(error));
                }
              })
            }
            searchOptions={{
              language: 'en',
              types,
              componentRestrictions: { country: 'us' },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              return (
                <div className="form-group">
                  <label className="input-label" htmlFor={name}>
                    <span className="input-label-primary">{header}</span>
                  </label>
                  <input
                    {...getInputProps({
                      name,
                      id: name,
                      placeholder,
                      className:
                        'location-search-input form-control form-control-md',
                      onFocus: () => {
                        clearMessages();
                        onChange();
                      },
                    })}
                  />
                  {loading && (
                    <div
                      className="autocomplete-dropdown-container"
                      style={customStyles}
                    >
                      Searching...
                    </div>
                  )}
                  {suggestions.length > 0 && renderDropdown ? (
                    <div
                      className="autocomplete-dropdown-container"
                      style={customStyles}
                    >
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              title: suggestion.description,
                            })}
                          >
                            <span>
                              <span className="suggestion-item-main">
                                {suggestion.formattedSuggestion.mainText}
                              </span>{' '}
                              <span className="suggestion-item-secondary">
                                {suggestion.formattedSuggestion.secondaryText}{' '}
                              </span>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            }}
          </PlacesAutocomplete>
          <ErrorMessage
            name={name}
            component="div"
            className="invalid-feedback"
          />
        </>
      )}
    </FormContext.Consumer>
  );
};
