import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ModeOfTransportationListScreen } from './screen/modeOfTransportation-list.screen';
import { ModeOfTransportationScreen } from './screen/modeOfTransportation.screen';

export const MODE_OF_TRANSPORTATIONS_LIST_PATH = '/mode-of-transportations';
export const MODE_OF_TRANSPORTATION_EDIT_PATH =
  '/mode-of-transportations/:modeOfTransportationId';
export const MODE_OF_TRANSPORTATION_CREATE_PATH = '/mode-of-transportations/0';
export const MODE_OF_TRANSPORTATIONS_PATH =
  '/org/:organizationId/mode-of-transportations';

export const ModeOfTransportationRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute
          exact
          path={`${match.path}${MODE_OF_TRANSPORTATIONS_LIST_PATH}`}
        >
          <ModeOfTransportationListScreen pageTitle="Mode Of Transportations" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${MODE_OF_TRANSPORTATION_CREATE_PATH}`}
        >
          <ModeOfTransportationScreen pageTitle="Add New Mode Of Transportation" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${MODE_OF_TRANSPORTATION_EDIT_PATH}`}
        >
          <ModeOfTransportationScreen pageTitle="Update Mode Of Transportation" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
