import React from 'react';
import { OrganizationForm } from './organizations.form';
import { Button } from '../../common/components/button/button.component';
import { OrganizationDto } from '../../../models/data.models';
import * as Yup from 'yup';
import { FormikProps, FormikValues } from 'formik';
import { parseGeocodeResults } from '../../../utils/helper.utils';

export type CreateOrganizationParam = {
  initialValues?: OrganizationDto;
  onSubmit: (data: OrganizationDto) => void;
};

const initialState: OrganizationDto = {
  created: new Date(),
  createdBy: '',
  lastModified: new Date(),
  lastModifiedBy: '',
  links: [],
  organizationId: 0,
  addressLine: '',
  addressLine2: '',
  city: '',
  companyName: '',
  countryCode: '',
  faxNumber: '',
  phoneNumber: '',
  postalCode: '',
  stateCode: '',
};

const organizationSchema = Yup.object().shape({
  companyName: Yup.string().required("Can't be blank").nullable(true),
});

export const CreateOrganization = ({
  initialValues = initialState,
  onSubmit = () => {},
}: CreateOrganizationParam) => {
  const onSelectPlaceAutocompleteAddress = (
    results: any,
    context: FormikProps<FormikValues>,
  ) => {
    const addressInfo = parseGeocodeResults(results);
    context.setFieldValue('addressLine', addressInfo.addressLine);
    context.setFieldValue('addressLine2', addressInfo.addressLine2 ?? '');
    context.setFieldValue('city', addressInfo.city);
    context.setFieldValue('postalCode', addressInfo.postalCode);
    context.setFieldValue('countryCode', addressInfo.countryCode);
    context.setFieldValue('stateCode', addressInfo.stateCode);
    return addressInfo.addressLine;
  };

  return (
    <OrganizationForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      id={'organization-create-form'}
      validationSchema={organizationSchema}
    >
      <div className="mb-5">
        <OrganizationForm.CompanyName />
      </div>
      <OrganizationForm.AddressLine
        defaultValue={initialValues?.addressLine}
        onSelect={onSelectPlaceAutocompleteAddress}
        customStyles={{ width: '84%' }}
      />
      <OrganizationForm.AddressLine2 />
      <div className="row mb-5">
        <div className="col-6">
          <OrganizationForm.City />
        </div>
        <div className="col-6">
          <OrganizationForm.StateCode />
        </div>
        <div className="col-6">
          <OrganizationForm.CountryCode />
        </div>
        <div className="col-6">
          <OrganizationForm.PostalCode />
        </div>
      </div>

      <div className="mb-5">
        <OrganizationForm.PhoneNumber />
        <OrganizationForm.FaxNumber />
      </div>
      <Button
        name="create-organization"
        type="submit"
        color="primary"
        className="btn-block"
      >
        Create Organization
      </Button>
    </OrganizationForm>
  );
};
