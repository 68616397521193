import {
  ChargeDto,
  LinkDto,
  LinkResourceBaseDto,
  ChargeDtoPagedResult,
  CreateChargeCommandValues,
  UpdateChargeCommandValues,
  ChargeType,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_CHARGES_LINK_KEY = 'get-charges';
export const CREATE_CHARGE_LINK_KEY = 'create-charge';
export const GET_CHARGE_LINK_KEY = 'get-charge';
export const UPDATE_CHARGE_LINK_KEY = 'update-charge';
export const DELETE_CHARGE_LINK_KEY = 'delete-charge';

export interface GetChargeParams {
  organizationId?: number;
  chargeId: number;
  charge?: ChargeDto;
  chargeType?: ChargeType;
}

export const getChargeRequest = async (
  resource: LinkResourceBaseDto | null,
  { organizationId, chargeId }: GetChargeParams,
): Promise<ChargeDto> => {
  if (resource && chargeId) {
    const getChargeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CHARGE_LINK_KEY,
    );
    if (getChargeLink) {
      const result = await execLink(getChargeLink, {
        organizationId,
        chargeId,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CHARGE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getChargesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ChargeDtoPagedResult> => {
  if (resource) {
    const getChargesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CHARGES_LINK_KEY,
    );
    if (getChargesListLink) {
      const result = await execLink(getChargesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CHARGES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createChargeRequest = async (
  resource: LinkResourceBaseDto,
  charge: CreateChargeCommandValues,
) => {
  const createChargeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CHARGE_LINK_KEY,
  );
  if (createChargeLink) {
    const result = await execLink(createChargeLink, charge);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_CHARGE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateChargeRequest = async (
  resource: LinkResourceBaseDto,
  charge: UpdateChargeCommandValues,
) => {
  const updateChargeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CHARGE_LINK_KEY,
  );
  if (updateChargeLink) {
    const result = await execLink(updateChargeLink, charge);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_CHARGE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteChargeRequest = async (charge: ChargeDto) => {
  const deleteChargeLink = charge.links?.find(
    (x: LinkDto) => x.rel === DELETE_CHARGE_LINK_KEY,
  );
  if (deleteChargeLink) {
    const result = await execLink(deleteChargeLink, charge);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_CHARGE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
