import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { PackageTypeListScreen } from './screen/packageType-list.screen';
import { PackageTypeScreen } from './screen/packageType.screen';

export const PACKAGETYPE_LIST_PATH = '/package-type';
export const PACKAGETYPE_EDIT_PATH = '/package-type/:packageTypeId';
export const PACKAGETYPE_CREATE_PATH = '/package-type/0';
export const PACKAGETYPES_PATH = '/org/:organizationId/package-type';

export const PackageTypeRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${PACKAGETYPE_LIST_PATH}`}>
          <PackageTypeListScreen pageTitle="Package Types" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${PACKAGETYPE_CREATE_PATH}`}>
          <PackageTypeScreen pageTitle="Add New Package Type" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${PACKAGETYPE_EDIT_PATH}`}>
          <PackageTypeScreen pageTitle="Update Package Type" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
