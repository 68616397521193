import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import {
  EQUIPMENTTYPE_CREATE_PATH,
  EQUIPMENTTYPE_LIST_PATH,
} from '../equipmentType.route';
import { EquipmentTypeEdit } from '../components/equipmentType-edit.component';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_EQUIPMENTTYPE_LINK_KEY } from '../equipmentType.service';
import { userHas } from '../../auth/auth.store';

export type EquipmentTypeScreenProps = { pageTitle: string };

export const EquipmentTypeScreen = ({
  pageTitle,
}: EquipmentTypeScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, equipmentTypeId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + EQUIPMENTTYPE_CREATE_PATH, {
      organizationId,
    });
  }
  const onEquipmentTypeCreated = () => {
    const path = generatePath(INTERNAL_PATH + EQUIPMENTTYPE_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'equipment-type-created',
      type: 'success',
      autoHide: true,
      message: 'Equipment Type successfully created!',
    };
    history.push(path);
    addMessage(message);
  };
  const onEquipmentTypeUpdated = () => {
    const path = generatePath(INTERNAL_PATH + EQUIPMENTTYPE_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'equipment-type-updated',
      type: 'success',
      autoHide: true,
      message: 'Equipment Type successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + EQUIPMENTTYPE_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };

  return (
    <InternalLayout
      title={'Equipment Types'}
      createPath={userHas(CREATE_EQUIPMENTTYPE_LINK_KEY) ? createPath() : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <EquipmentTypeEdit
        equipmentTypeId={equipmentTypeId}
        onEquipmentTypeUpdated={onEquipmentTypeUpdated}
        onEquipmentTypeCreated={onEquipmentTypeCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
