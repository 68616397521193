import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { ORDER_CREATE_PATH, ORDER_LIST_PATH } from '../order.route';
import { OrderEdit } from '../components/order-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { Helmet } from 'react-helmet';
import { CREATE_ORDER_LINK_KEY } from '../orders.service';
import { userHas } from '../../auth/auth.store';

export type OrderScreenProps = { pageTitle: string };

export const OrderScreen = ({ pageTitle }: OrderScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, orderId } = useParams();

  const createPath = generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
    organizationId,
  });

  const onOrderCreated = () => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'order-created',
      type: 'success',
      autoHide: true,
      message: 'Order successfully created!',
    };
    history.push(path);
    addMessage(message);
  };

  const onOrderUpdated = () => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'order-updated',
      type: 'success',
      autoHide: true,
      message: 'Order successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const onCancel = () => {
    window.history.back();
  };

  return (
    <InternalLayout
      title={'Orders'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OrderEdit
        orderId={orderId}
        onOrderUpdated={onOrderUpdated}
        onOrderCreated={onOrderCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
