import React from 'react';
import Draggable from 'react-draggable';
export type DialogProps = {
  title: string;
  className?: string;
  message?: string;
  orderNumber?: string;
  close?: (obj: any) => void | null;
};
export class Dialog<
  Props extends DialogProps
> extends React.Component<DialogProps> {
  _resolve: (obj: any) => void | null = () => {};
  constructor(props: Props) {
    super(props);
  }

  close(result?) {
    this.props.close(result);
  }

  renderContent(): any {
    return null;
  }

  renderDialog(title: string, className: string) {
    const modalClassName = 'modal fade show ' + className;
    const handle = ['.modal-header', '.modal-body'];
    const cancel = [
      'input',
      'button',
      'label',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'a',
      'svg',
      'p',
      'span',
      'textarea',
      'img',
      '.select__value-container',
      '.icon-edit-entity',
      '.custom-control',
      '.grid',
      '.header-form',
      '.quill',
      '.form-border-text',
      '.google-maps',
    ];
    return (
      <div
        className={modalClassName}
        style={{ display: 'block' }}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <Draggable handle={handle.join(', ')} cancel={cancel.join(', ')}>
            <div className="modal-content shadow">
              <div className="modal-header">
                <span className="modal-title">{title}</span>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.close(null)}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="x-square" />
                </button>
              </div>
              <div className="modal-body">{this.renderContent()}</div>
            </div>
          </Draggable>
        </div>
      </div>
    );
  }

  render() {
    const { title, className } = this.props;
    return this.renderDialog(title, className);
  }
}
