import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { UserGroupScreen } from './screens/userGroup.screen';
import { UserGroupsListScreen } from './screens/userGroups-list.screen';

export const USERGROUPS_LIST_PATH = '/userGroups';
export const USERGROUP_EDIT_PATH = '/userGroups/:userGroupId';
export const USERGROUP_CREATE_PATH = '/userGroups/0';
export const USERGROUPS_PATH = '/org/:organizationId/userGroups';

export const UserGroupRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${USERGROUPS_LIST_PATH}`}>
          <UserGroupsListScreen pageTitle="User Groups" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${USERGROUP_CREATE_PATH}`}>
          <UserGroupScreen pageTitle="Add New User Group" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${USERGROUP_EDIT_PATH}`}>
          <UserGroupScreen pageTitle="Update User Group" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
