import React from 'react';
import { CommodityDto } from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  updateCommoditiesForOrderColumns,
  commodityStoreForOrder,
} from '../commodities.store';
import {
  DELETE_COMMODITY_LINK_KEY,
  GetCommodityParams,
} from '../commodities.service';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { CommodityGrid } from '../../common/components/grid/commodity-grid.component';
import { userHas } from '../../auth/auth.store';

export type CommoditiesForOrderListProps = {
  goToDetails?: (commodityParams: GetCommodityParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  items?: CommodityDto[];
  showPagination?: boolean;
  className?: string;
  changeItems?: React.Dispatch<React.SetStateAction<CommodityDto[]>>;
  onSelect?: (
    commodity: CommodityDto,
    commodityParams: GetCommodityParams,
  ) => void;
  userCanDelete?: boolean;
};

export const CommoditiesForOrderList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = 'commodityId',
  showPagination = true,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  items,
  changeItems,
  className = '',
  userCanDelete = false,
}: CommoditiesForOrderListProps) => {
  const { commodityColumns: columns } = useStore(commodityStoreForOrder);

  const onDeleteCommodity = (commodity: CommodityDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete "${commodity.description}" Commodity`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        let isDeleted = false;
        items = items.filter((obj) => {
          const compareCommodityResult = obj !== commodity;
          if (!compareCommodityResult && isDeleted === false) {
            isDeleted = true;
            return false;
          }
          return true;
        });
        changeItems(items);
      }
    });
  };

  const onEditCommodity = (commodity: CommodityDto) => {
    goToDetails({ commodity, commodityId: commodity?.commodityId });
  };
  return (
    <CommodityGrid
      className={className}
      showAllStore={true}
      showEmptyTable={true}
      rowKeys={['commodityId']}
      data={items}
      columns={columns}
      offset={offset}
      limit={limit}
      total={items.length}
      sort={sort}
      showPagination={showPagination}
      onDelete={
        userHas(DELETE_COMMODITY_LINK_KEY, items[0]?.links) || userCanDelete
          ? onDeleteCommodity
          : null
      }
      onSort={onSort}
      onEdit={onEditCommodity}
      onPageChanged={onPageChanged}
      onColumnsChanged={updateCommoditiesForOrderColumns}
      onSelect={(commodity, commodityId) =>
        goToDetails({ commodity, commodityId })
      }
      hideColumnsSelect={true}
      showSortColumns={false}
    />
  );
};
