import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { CustomerListScreen } from './screens/customer-list.screen';
import { CustomerScreen } from './screens/customer.screen';
import { ContactScreen } from '../contacts/screens/contact.screen';
import { CONTACTS_TAB_EDIT_PATH_FOR_CUSTOMERS } from '../contacts/contact.route';

export const CUSTOMERS_LIST_PATH = '/customers';
export const CUSTOMERS_EDIT_PATH = '/customers/:contactId';
export const CUSTOMERS_CREATE_PATH = '/customers/0';
export const CUSTOMERS_PATH = '/org/:organizationId/customers';

export const CustomerRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${CUSTOMERS_LIST_PATH}`}>
          <CustomerListScreen pageTitle="Customers" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CUSTOMERS_CREATE_PATH}`}>
          <CustomerScreen pageTitle="Add New Customer" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${CUSTOMERS_EDIT_PATH}`}>
          <CustomerScreen pageTitle="Update Customer" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${CONTACTS_TAB_EDIT_PATH_FOR_CUSTOMERS}`}
        >
          <ContactScreen pageTitle="Contacts" isTab={true} />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
