import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { ContainerDescriptionsListScreen } from './screens/containerDescriptions-list.screen';
import { ContainerDescriptionScreen } from './screens/containerDescription.screen';

export const CONTAINER_DESCRIPTIONS_LIST_PATH = '/container-descriptions';
export const CONTAINER_DESCRIPTION_EDIT_PATH =
  '/container-descriptions/:containerDescriptionCode';
export const CONTAINER_DESCRIPTION_CREATE_PATH = '/container-descriptions/0';
export const CONTAINER_DESCRIPTIONS_PATH =
  '/org/:organizationId/container-descriptions';

export const ContainerDescriptionsRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute
          exact
          path={`${match.path}${CONTAINER_DESCRIPTIONS_LIST_PATH}`}
        >
          <ContainerDescriptionsListScreen pageTitle="Container Descriptions" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${CONTAINER_DESCRIPTION_CREATE_PATH}`}
        >
          <ContainerDescriptionScreen />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${CONTAINER_DESCRIPTION_EDIT_PATH}`}
        >
          <ContainerDescriptionScreen />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
