import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DocumentTemplateScreen } from './screens/documentTemplates.screen';
import { DocumentTemplatesListScreen } from './screens/documentTemplates-list.screen';

export const DOCUMENT_TEMPLATES_LIST_PATH = '/documentTemplates';
export const DOCUMENT_TEMPLATES_EDIT_PATH =
  '/documentTemplates/:documentTemplateId';
export const DOCUMENT_TEMPLATES_CREATE_PATH = '/documentTemplates/0';
export const DOCUMENT_TEMPLATES_PATH = '/org/:organizationId/documentTemplates';

export const DocumentTemplateRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute
          exact
          path={`${match.path}${DOCUMENT_TEMPLATES_LIST_PATH}`}
        >
          <DocumentTemplatesListScreen pageTitle="Document Templates" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${DOCUMENT_TEMPLATES_CREATE_PATH}`}
        >
          <DocumentTemplateScreen pageTitle="Add New Document Template" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${match.path}${DOCUMENT_TEMPLATES_EDIT_PATH}`}
        >
          <DocumentTemplateScreen pageTitle="Update Document Template" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
