import { Dialog } from '../../common/components/dialog/dialog.component';
import { PortEdit } from './port-edit.component';
import { PortDto } from '../../../models/data.models';

export type PortDialogProperties = {
  portId: number;
  title: string;
};

export class PortDialog extends Dialog<PortDialogProperties> {
  constructor(props: PortDialogProperties) {
    super(props);
    this.onPortSaved = this.onPortSaved.bind(this);
  }

  onPortSaved = (port: PortDto) => {
    this.close(port);
  };

  renderContent(): any {
    const { portId } = this.props as PortDialogProperties;
    return (
      <PortEdit
        portId={portId}
        onPortCreated={this.onPortSaved}
        onPortUpdated={this.onPortSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
