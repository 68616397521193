import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  CustomFieldDto,
  CustomFieldDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  customFieldStore,
  getCustomFields,
  deleteCustomField,
  updateCustomFieldsColumns,
  recreateSystemCustomFields,
} from '../customFields.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_CUSTOMFIELD_LINK_KEY,
  DELETE_CUSTOMFIELD_LINK_KEY,
  GetCustomFieldParams,
  recreateSystemCustomFieldsRequest,
  RECREATE_SYSTEM_CUSTOMFIELDS_KEY,
} from '../customFields.service';
import { CustomFieldDialog } from './customField.dialog';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { userHas } from '../../auth/auth.store';
import { organizationsStore } from '../../organization/organization.store';

export type CustomFieldsListProps = {
  goToDetails?: (customFieldParams: GetCustomFieldParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  filter?: string;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onFilter?: (query: string) => void;
  onSelect?: (
    customField: CustomFieldDto,
    customFieldParams: GetCustomFieldParams,
  ) => void;
  onSearch?: (query: string) => void;
};

export const CustomFieldsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  filter = '',
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  onFilter = () => {},
  onSearch,
}: CustomFieldsListProps) => {
  const [
    customFields,
    setCustomFields,
  ] = useState<CustomFieldDtoPagedResult | null>(null);

  useEffect(() => {
    getCustomFieldsData();
  }, [offset, limit, sort, filter, search]);

  const getCustomFieldsData = () => {
    getCustomFields({ offset, limit, sort, filter, search }).then(
      (customFieldsData) => {
        customFieldsData.items.forEach((customField) => {
          customField.created = customField.created
            ? getFormattedDate(customField.created)
            : null;
          customField.lastModified = customField.lastModified
            ? getFormattedDate(customField.lastModified)
            : null;
        });
        setCustomFields(customFieldsData);
      },
      () => {},
    );
  };

  const { customFieldColumns: columns } = useStore(customFieldStore);

  const onDeleteCustomField = (customField: CustomFieldDto) => {
    if (customField.isSystem) return;
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${customField.fieldId} Custom Field`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteCustomField(customField).then(
            () => {
              onDelete();
              getCustomFieldsData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditCustomField = (customField: CustomFieldDto) => {
    goToDetails({ fieldId: customField.fieldId });
  };

  const onCreateNewCustomField = () => {
    goToDetails({ fieldId: 0 });
  };
  const onCustomFieldSelect = (customField, customFieldId) => {
    if (onSelect) onSelect(customField, customFieldId);
    showDialog({
      dialog: CustomFieldDialog,
      props: {
        fieldId: customField.fieldId,
        title: 'Update Custom Field',
      },
    }).then((customField) => {
      if (customField !== null) {
        getCustomFieldsData();
      }
    });
  };

  const isSystemCustomField = (customField: CustomFieldDto): boolean => {
    return customField.isSystem;
  };

  const recreateSystemCustomfieldsRequest = () => {
    recreateSystemCustomFields()
      .then(() => {
        getCustomFieldsData();
      })
      .catch((error) => console.log(error));
  };

  const { currentOrganization } = organizationsStore.getState();

  return (
    <>
      <Grid
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={['fieldId']}
        data={customFields?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={customFields?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_CUSTOMFIELD_LINK_KEY, customFields?.items[0]?.links)
            ? onDeleteCustomField
            : null
        }
        onSort={onSort}
        onEdit={onEditCustomField}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateCustomFieldsColumns}
        onSelect={onCustomFieldSelect}
        isSystemCustomField={isSystemCustomField}
        onRecreateSystemCustomFields={
          userHas(RECREATE_SYSTEM_CUSTOMFIELDS_KEY, currentOrganization?.links)
            ? recreateSystemCustomfieldsRequest
            : null
        }
      />
      {!(customFields && customFields.items && customFields.items.length) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Custom Fields Yet
          </h3>
          {userHas(CREATE_CUSTOMFIELD_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={onCreateNewCustomField}
            >
              Create Custom Field
            </Button>
          )}
        </div>
      )}
    </>
  );
};
