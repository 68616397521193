import {
  EquipmentTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  EquipmentTypeDtoPagedResult,
  CreateEquipmentTypeCommandValues,
  UpdateEquipmentTypeCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_EQUIPMENTTYPES_LINK_KEY = 'get-equipmenttypes';
export const CREATE_EQUIPMENTTYPE_LINK_KEY = 'create-equipmenttype';
export const GET_EQUIPMENTTYPE_LINK_KEY = 'get-equipmenttype';
export const UPDATE_EQUIPMENTTYPE_LINK_KEY = 'update-equipmenttype';
export const DELETE_EQUIPMENTTYPE_LINK_KEY = 'delete-equipmenttype';

export const EQUIPMENT_TYPE_ENTITY_NAME = 'Equipment Type';

export interface GetEquipmentTypeParams {
  equipmentTypeId: number;
}

export const getEquipmentTypeRequest = async (
  resource: LinkResourceBaseDto | null,
  { equipmentTypeId }: GetEquipmentTypeParams,
): Promise<EquipmentTypeDto> => {
  if (resource && equipmentTypeId) {
    const getEquipmentTypeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_EQUIPMENTTYPE_LINK_KEY,
    );
    if (getEquipmentTypeLink) {
      const result = await execLink(getEquipmentTypeLink, { equipmentTypeId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_EQUIPMENTTYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getEquipmentTypesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<EquipmentTypeDtoPagedResult> => {
  if (resource) {
    const getEquipmentTypesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_EQUIPMENTTYPES_LINK_KEY,
    );
    if (getEquipmentTypesListLink) {
      const result = await execLink(getEquipmentTypesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_EQUIPMENTTYPES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createEquipmentTypeRequest = async (
  resource: LinkResourceBaseDto,
  equipmentType: CreateEquipmentTypeCommandValues,
) => {
  const createEquipmentTypeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_EQUIPMENTTYPE_LINK_KEY,
  );
  if (createEquipmentTypeLink) {
    const result = await execLink(createEquipmentTypeLink, equipmentType);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_EQUIPMENTTYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateEquipmentTypeRequest = async (
  resource: LinkResourceBaseDto,
  equipmentType: UpdateEquipmentTypeCommandValues,
) => {
  const updateEquipmentTypeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_EQUIPMENTTYPE_LINK_KEY,
  );
  if (updateEquipmentTypeLink) {
    const result = await execLink(updateEquipmentTypeLink, equipmentType);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_EQUIPMENTTYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteEquipmentTypeRequest = async (
  equipmentType: EquipmentTypeDto,
) => {
  const deleteEquipmentTypeLink = equipmentType.links?.find(
    (x: LinkDto) => x.rel === DELETE_EQUIPMENTTYPE_LINK_KEY,
  );
  if (deleteEquipmentTypeLink) {
    const result = await execLink(deleteEquipmentTypeLink, equipmentType);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_EQUIPMENTTYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
