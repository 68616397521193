import {
  CountryDto,
  LinkDto,
  LinkResourceBaseDto,
  CountryDtoPagedResult,
  UpdateCountryCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCountryRequest,
  getCountriesListRequest,
  getCountryRequest,
  deleteCountryRequest,
  updateCountryRequest,
  GetCountryParams,
} from './countries.service';

type CountriesStoreState = {
  links: LinkDto[];
  countriesColumns: Column[];
};

export const updateColumns = createEvent<Column[]>();

export const getCountries = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');
  return getCountriesListRequest(currentOrganization, params);
});

export const createCountry = createEffect((countryData: CountryDto) => {
  const { currentOrganization } = organizationsStore.getState();
  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return createCountryRequest(currentOrganization, countryData);
});

export const getCountry = createEffect(({ countryCode }: GetCountryParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCountryRequest(currentOrganization as LinkResourceBaseDto, {
    countryCode,
  });
});

export const updateCountry = createEffect((country: CountryDto) => {
  const updateCommand: UpdateCountryCommandValues = { ...country };
  return updateCountryRequest(country, updateCommand);
});

export const deleteCountry = createEffect((country: CountryDto) => {
  return deleteCountryRequest(country);
});

const defaultState: CountriesStoreState = {
  links: [],
  countriesColumns: [
    {
      name: 'countryCode',
      visible: true,
      title: 'Country Code',
      sortName: 'countryCode',
    },
    { name: 'name', visible: true, title: 'Name', sortName: 'name' },
  ],
};

export const countriesStore: Store<CountriesStoreState> = createStore(
  defaultState,
)
  .on(getCountries.done, (state, payload) => {
    const { result: countries } = payload;
    return { ...state, links: countries.links || state.links };
  })
  .on(updateColumns, (state, payload) => {
    return { ...state, countriesColumns: payload };
  });
