import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { EmployeeListScreen } from './screens/employee-list.screen';
import { EmployeeScreen } from './screens/employee.screen';

export const EMPLOYEES_LIST_PATH = '/employees';
export const EMPLOYEES_EDIT_PATH = '/employees/:contactId';
export const EMPLOYEES_CREATE_PATH = '/employees/0';
export const EMPLOYEES_PATH = '/org/:organizationId/employees';

export const EmployeesRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${EMPLOYEES_LIST_PATH}`}>
          <EmployeeListScreen pageTitle="Employees" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${EMPLOYEES_CREATE_PATH}`}>
          <EmployeeScreen pageTitle="Add New Employee" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${EMPLOYEES_EDIT_PATH}`}>
          <EmployeeScreen pageTitle="Update Employee" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
