import {
  ContainerDescriptionDto,
  LinkDto,
  LinkResourceBaseDto,
  ContainerDescriptionDtoPagedResult,
  CreateContainerDescriptionCommandValues,
  UpdateContainerDescriptionCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_CONTAINERDESCRIPTIONS_LINK_KEY = 'get-containerdescriptions';
export const CREATE_CONTAINERDESCRIPTION_LINK_KEY =
  'create-containerdescription';
export const GET_CONTAINERDESCRIPTION_LINK_KEY = 'get-containerdescription';
export const UPDATE_CONTAINERDESCRIPTION_LINK_KEY =
  'update-containerdescription';
export const DELETE_CONTAINERDESCRIPTION_LINK_KEY =
  'delete-containerdescription';

export const CONTAINER_DESCRIPTION_ENTITY_NAME = 'Container Description';

export interface GetContainerDescriptionParams {
  containerDescriptionCode: string;
}

export const getContainerDescriptionRequest = async (
  resource: LinkResourceBaseDto | null,
  { containerDescriptionCode }: GetContainerDescriptionParams,
): Promise<ContainerDescriptionDto> => {
  if (resource && containerDescriptionCode) {
    const getContainerDescriptionLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTAINERDESCRIPTION_LINK_KEY,
    );
    if (getContainerDescriptionLink) {
      const result = await execLink(getContainerDescriptionLink, {
        containerDescriptionCode,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTAINERDESCRIPTION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getContainerDescriptionsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContainerDescriptionDtoPagedResult> => {
  if (resource) {
    const getContainerDescriptionsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTAINERDESCRIPTIONS_LINK_KEY,
    );
    if (getContainerDescriptionsListLink) {
      const result = await execLink(getContainerDescriptionsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTAINERDESCRIPTIONS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createContainerDescriptionRequest = async (
  resource: LinkResourceBaseDto,
  containerDescription: CreateContainerDescriptionCommandValues,
) => {
  const createContainerDescriptionLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTAINERDESCRIPTION_LINK_KEY,
  );
  if (createContainerDescriptionLink) {
    const result = await execLink(
      createContainerDescriptionLink,
      containerDescription,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_CONTAINERDESCRIPTION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateContainerDescriptionRequest = async (
  resource: LinkResourceBaseDto,
  containerDescription: UpdateContainerDescriptionCommandValues,
) => {
  const updateContainerDescriptionLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTAINERDESCRIPTION_LINK_KEY,
  );
  if (updateContainerDescriptionLink) {
    const result = await execLink(
      updateContainerDescriptionLink,
      containerDescription,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_CONTAINERDESCRIPTION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteContainerDescriptionRequest = async (
  containerDescription: ContainerDescriptionDto,
) => {
  const deleteContainerDescriptionLink = containerDescription.links?.find(
    (x: LinkDto) => x.rel === DELETE_CONTAINERDESCRIPTION_LINK_KEY,
  );
  if (deleteContainerDescriptionLink) {
    const result = await execLink(
      deleteContainerDescriptionLink,
      containerDescription,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_CONTAINERDESCRIPTION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
