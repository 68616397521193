import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CustomFieldForm } from './customField.form';
import {
  CustomFieldDto,
  CustomFieldEntityType,
  CustomFieldType,
} from '../../../models/data.models';
import {
  createCustomField,
  getCustomField,
  updateCustomField,
} from '../customFields.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ReactSelectItem } from '../../../models/custom.models';
import { getEnumValues } from '../../../utils/helper.utils';
import { CustomFieldDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { userHas } from '../../auth/auth.store';
import { UPDATE_CUSTOMFIELD_LINK_KEY } from '../customFields.service';

export type CustomFieldEditProps = {
  fieldId?: number | null;
  onCustomFieldCreated?: (customField: CustomFieldDto) => void;
  onCustomFieldUpdated?: (customField: CustomFieldDto) => void;
  onCustomFieldLoaded?: (customField: CustomFieldDto) => void;
  onCancel?: () => void;
};

const getInitialState = (): CustomFieldDto => {
  const initialState: CustomFieldDto = {
    fieldId: 0,
    category: CustomFieldDefaultValues.category,
    created: new Date('2011-10-05T14:00:00.000Z'),
    createdBy: '',
    customFieldEntityType: CustomFieldDefaultValues.customFieldEntityType,
    customFieldType: CustomFieldDefaultValues.customFieldType,
    description: CustomFieldDefaultValues.description,
    displayName: CustomFieldDefaultValues.displayName,
    fieldConfig: null,
    internalName: CustomFieldDefaultValues.internalName,
    isInactive: CustomFieldDefaultValues.isInactive,
    lastModified: new Date('2011-10-05T14:00:00.000Z'),
    lastModifiedBy: '',
    organizationId: 0,
    createdByUserName: '',
    lastModifiedByUserName: '',
    links: [],
  };
  return initialState;
};

const customFieldsSchema = Yup.object().shape({
  customFieldEntityType: Yup.string().required("Can't be blank").nullable(true),
  customFieldType: Yup.string().required("Can't be blank").nullable(true),
  displayName: Yup.string().required("Can't be blank").nullable(true),
  internalName: Yup.string().required("Can't be blank").nullable(true),
});

export const CustomFieldEdit = ({
  fieldId,
  onCustomFieldLoaded = () => {},
  onCustomFieldCreated = () => {},
  onCustomFieldUpdated = () => {},
  onCancel = () => {},
}: CustomFieldEditProps) => {
  const isCreateMode = !fieldId || fieldId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customField, setCustomField] = useState<CustomFieldDto | null>(null);
  const [initialValues, setInitialValues] = useState<CustomFieldDto | null>(
    getInitialState(),
  );

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (fieldId) {
      getCustomField({ fieldId }).then(
        (customFieldDto: CustomFieldDto) => {
          setCustomField(customFieldDto);
          setInitialValues(customFieldDto);
          setIsLoading(false);
          onCustomFieldLoaded(customFieldDto);
        },
        () => {},
      );
    } else {
      throw new Error('CustomField keys were not provided');
    }
  }, [fieldId]);

  const onEntityTypeChange = (newValueEntityType: ReactSelectItem) => {
    setCustomField((customField) => {
      if (!customField) {
        customField = getInitialState();
      }
      customField.customFieldEntityType = newValueEntityType?.label;
      return customField;
    });
  };

  const onFieldTypeChange = (newValueFieldType: ReactSelectItem) => {
    setCustomField((customField) => {
      if (!customField) {
        customField = getInitialState();
      }
      customField.customFieldType = newValueFieldType?.label;
      return customField;
    });
  };

  const onSubmit = (data: CustomFieldDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createCustomField(data)
        .then(
          (result) => {
            onCustomFieldCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateCustomField(data)
        .then(
          (result) => {
            onCustomFieldUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Tabs>
      <TabList>
        <Tab name="customField">Custom Field</Tab>
      </TabList>
      <CustomFieldForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={customFieldsSchema}
        id="customFieldForm"
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add Custom Field</h2>
            ) : (
              <h2>Update Custom Field</h2>
            )}
            <div className="row">
              <div className="col-4">
                <CustomFieldForm.Category
                  disabled={customField?.isSystem || false}
                />
                <CustomFieldForm.CustomFieldEntityType
                  placeholder={'Select Entity'}
                  id={'customFieldEntityType'}
                  header={'Entity'}
                  name={'customFieldEntityType'}
                  disabled={customField?.isSystem || false}
                  required={true}
                  defaultValue={
                    customField?.customFieldEntityType
                      ? {
                          label:
                            CustomFieldEntityType[
                              customField?.customFieldEntityType
                            ],
                          value: customField?.customFieldEntityType,
                        }
                      : {
                          label: CustomFieldDefaultValues.customFieldEntityType,
                          value: CustomFieldDefaultValues.customFieldEntityType,
                        }
                  }
                  onChange={onEntityTypeChange}
                  multiple={false}
                  options={getEnumValues(CustomFieldEntityType)}
                />
                <CustomFieldForm.CustomFieldType
                  placeholder={'Select Type'}
                  id={'customFieldType'}
                  header={'Field Type'}
                  name={'customFieldType'}
                  disabled={customField?.isSystem || false}
                  required={true}
                  defaultValue={
                    customField?.customFieldType
                      ? {
                          label: CustomFieldType[customField?.customFieldType],
                          value: customField?.customFieldType,
                        }
                      : {
                          label: CustomFieldDefaultValues.customFieldType,
                          value: CustomFieldDefaultValues.customFieldType,
                        }
                  }
                  onChange={onFieldTypeChange}
                  multiple={false}
                  options={getEnumValues(CustomFieldType)}
                />
                <CustomFieldForm.Description />
                <div className="displayNameBlock">
                  <CustomFieldForm.DisplayName
                    disabled={customField?.isSystem || false}
                  />
                </div>
                {/*<CustomFieldForm.FieldConfig />*/}
                {isCreateMode && (
                  <div className="internalNameBlock">
                    <CustomFieldForm.InternalName
                      disabled={customField?.isSystem || false}
                    />
                  </div>
                )}
                <CustomFieldForm.IsInactive
                  disabled={customField?.isSystem || false}
                />
                <div className="w-100 d-flex align-items-center justify-content-end">
                  {(userHas(UPDATE_CUSTOMFIELD_LINK_KEY, customField?.links) ||
                    isCreateMode) && (
                    <Button
                      name="save-customField"
                      type="submit"
                      color="primary"
                      className="btn-block mr-3"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save Custom Field
                    </Button>
                  )}
                  <Button
                    type="button"
                    color="primary"
                    onClick={onCancel}
                    className="btn-light btn-cancel"
                    disabled={isSending}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </CustomFieldForm>
    </Tabs>
  );
};
