import { LinkDto, QuickBooksTokensDto } from '../../models/data.models';
import { API_ENDPOINT } from '../../properties';
import { execLink } from '../api/api.service';

export const initQuickBooks = async (): Promise<string> => {
  const url = `${API_ENDPOINT}/api/quickBooks/init`;
  const initQuickBooksLink: LinkDto = {
    href: url,
    templated: false,
    type: 'get',
  };
  const result = await execLink(initQuickBooksLink);
  return result.status === 200 ? result.data : Promise.reject();
};

export const getQuickBooksTokens = async (
  code: string,
  realmId: string,
): Promise<QuickBooksTokensDto> => {
  const url = `${API_ENDPOINT}/api/quickBooks/getTokens/?code=${code}&realmId=${realmId}`;
  const getQuickBooksTokensLink: LinkDto = {
    href: url,
    templated: false,
    type: 'get',
  };
  const result = await execLink(getQuickBooksTokensLink);
  return result.status === 200 ? result.data : Promise.reject();
};
