import React, { CSSProperties } from 'react';
import { Oval } from 'react-loader-spinner';
import { clearMessages } from '../../messages.store';

let loader = (
  <Oval
    strokeWidthSecondary={3}
    strokeWidth={3}
    secondaryColor="#eff1f7"
    color="#e6e6e6"
    height={25}
    width={25}
  />
);

export type ButtonProps = {
  className?: string;
  style?: CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit' | 'reset';
  color?:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
  children: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  outline?: boolean;
  form?: string;
  name?: string;
  isSending?: boolean;
};

export const Button = ({
  size = 'md',
  type = 'button',
  color = 'secondary',
  children,
  onClick = () => {},
  className = '',
  style = {},
  disabled = false,
  outline = false,
  form = null,
  name = null,
  isSending = false,
}: ButtonProps) => {
  const _className = `
                        btn
                        btn-${size}
                        btn-${outline ? 'outline-' : ''}${color}
                        btn-${type} ${className}
                        `;
  return (
    <button
      type={type}
      className={_className}
      onClick={
        type === 'submit'
          ? () => {
              clearMessages();
              onClick;
            }
          : onClick
      }
      style={style}
      disabled={disabled}
      form={form}
      data-cy={name}
      autoFocus={type === 'submit' ? true : false}
    >
      {type === 'submit' ? (
        isSending ? (
          <div className="loader-wrapper">{loader}</div>
        ) : (
          children
        )
      ) : (
        children
      )}
    </button>
  );
};
