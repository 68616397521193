import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { UserGroupForm } from './userGroup.form';
import { UserGroupDto, UserRoleDto } from '../../../models/data.models';
import {
  createUserGroup,
  getUserGroup,
  updateUserGroup,
} from '../userGroups.store';
import { Panel } from '../../common/components/panel/panel.component';
import { getUserRoles } from '../../role/userRoles.store';
import { UserGroupDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { UPDATE_USERGROUP_LINK_KEY } from '../userGroups.service';
import {
  getUserAdditionalData,
  getUserInfo,
  userHas,
} from '../../auth/auth.store';

export type UserGroupEditProps = {
  userGroupId?: number | null;
  onUserGroupCreated?: (userGroup: UserGroupDto) => void;
  onUserGroupUpdated?: (userGroup: UserGroupDto) => void;
  onUserGroupLoaded?: (userGroup: UserGroupDto) => void;
  onCancel?: () => void;
};

const initialState: UserGroupDto = {
  userGroupId: 0,
  created: new Date('2011-10-05T14:00:00.000Z'),
  createdBy: '',
  description: UserGroupDefaultValues.description,
  lastModified: new Date('2011-10-05T14:00:00.000Z'),
  lastModifiedBy: '',
  name: UserGroupDefaultValues.userGroupName,
  organizationId: 0,
  links: [],
};

const userGroupSchema = Yup.object().shape({
  name: Yup.string().required("Can't be blank").nullable(true),
  description: Yup.string().required("Can't be blank").nullable(true),
});

export const UserGroupEdit = ({
  userGroupId,
  onUserGroupLoaded = () => {},
  onUserGroupCreated = () => {},
  onUserGroupUpdated = () => {},
  onCancel = () => {},
}: UserGroupEditProps) => {
  const isCreateMode = !userGroupId || userGroupId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userGroup, setUserGroup] = useState<UserGroupDto | null>(null);
  const [initialValues, setInitialValues] = useState<UserGroupDto | null>(
    initialState,
  );
  const [roles, setRoles] = useState<UserRoleDto[]>([]);

  useEffect(() => {
    getUserRoles({}).then((result) => setRoles(result.items));
    if (isCreateMode) {
      setIsLoading(false);
    } else if (userGroupId) {
      getUserGroup({ userGroupId }).then((userGroupDto: UserGroupDto) => {
        userGroupDto['roles'] = {};
        userGroupDto.userRoles?.forEach(
          (role) => (userGroupDto['roles'][role.id] = true),
        );
        setUserGroup(userGroupDto);
        setInitialValues(userGroupDto);
        setIsLoading(false);
        onUserGroupLoaded(userGroupDto);
      });
    } else {
      throw new Error('UserGroup keys were not provided');
    }
  }, [userGroupId]);

  const onSubmit = (data: UserGroupDto) => {
    if (data['roles']) {
      let selectedRoleIds = Object.keys(data['roles']).filter(
        (roleId) => data['roles'][roleId],
      );
      data.userRoles = roles.filter((role) =>
        selectedRoleIds.includes(role.id),
      );
    }
    setIsSending(true);
    if (isCreateMode) {
      createUserGroup(data)
        .then((result) => {
          onUserGroupCreated(result);
        })
        .finally(() => {
          setIsSending(false);
          getUserInfo().then(
            () => {
              getUserAdditionalData();
            },
            () => {},
          );
        });
    } else {
      updateUserGroup(data)
        .then((result) => {
          onUserGroupUpdated(result);
        })
        .finally(() => {
          setIsSending(false);
          getUserInfo().then(
            () => {
              getUserAdditionalData();
            },
            () => {},
          );
        });
    }
  };
  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Tabs>
      <TabList>
        <Tab name="userGroup">User Group</Tab>
      </TabList>
      <UserGroupForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        id="userGroupForm"
        validationSchema={userGroupSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add User Group</h2>
            ) : (
              <h2>Update User Group</h2>
            )}
            <div className="row">
              <div className="col-4">
                <UserGroupForm.Name />
                <UserGroupForm.Description />
                {roles?.map((role, index) => (
                  <UserGroupForm.GroupRole
                    name={`roles[${role.id}]`}
                    label={role.name}
                    key={`role-${index}`}
                  />
                ))}
                <div className="w-100 d-flex align-items-center justify-content-end">
                  {(userHas(UPDATE_USERGROUP_LINK_KEY, userGroup?.links) ||
                    isCreateMode) && (
                    <Button
                      name="save-userGroup"
                      type="submit"
                      color="primary"
                      className="btn-block mr-3"
                      disabled={isSending}
                      isSending={isSending}
                    >
                      Save User Group
                    </Button>
                  )}
                  <Button
                    type="button"
                    color="primary"
                    onClick={onCancel}
                    className="btn-light btn-cancel"
                    disabled={isSending}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </UserGroupForm>
    </Tabs>
  );
};
