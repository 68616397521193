import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { CountryForm } from './country.form';
import { CountryDto } from '../../../models/data.models';
import { createCountry, getCountry, updateCountry } from '../countries.store';
import { Panel } from '../../common/components/panel/panel.component';
import { CountryDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { UPDATE_COUNTRY_LINK_KEY } from '../countries.service';
import { userHas } from '../../auth/auth.store';

export type CountryEditProps = {
  countryCode?: string;
  onCountryCreated?: (country: CountryDto) => void;
  onCountryUpdated?: (country: CountryDto) => void;
  onCountryLoaded?: (country: CountryDto) => void;
  onCancel?: () => void;
};

const initialState: CountryDto = {
  links: [],
  organizationId: 0,
  countryCode: CountryDefaultValues.countryCode,
  name: CountryDefaultValues.countryName,
};

const countrySchema = Yup.object().shape({
  countryCode: Yup.string()
    .required("Can't be blank")
    .max(3, 'Max length is 3')
    .nullable(true),
  name: Yup.string().required("Can't be blank").nullable(true),
});

export const CountryEdit = ({
  countryCode,
  onCountryLoaded = () => {},
  onCountryCreated = () => {},
  onCountryUpdated = () => {},
  onCancel = () => {},
}: CountryEditProps) => {
  const isCreateMode = !countryCode || countryCode === 'create';
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [country, setCountry] = useState<CountryDto | null>(null);

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (countryCode) {
      getCountry({ countryCode }).then((countryDto: CountryDto) => {
        setCountry(countryDto);
        setIsLoading(false);
        onCountryLoaded(countryDto);
      });
    }
  }, [countryCode]);

  const onSubmit = (data: CountryDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createCountry(data)
        .then((result) => {
          onCountryCreated(result);
        })
        .finally(() => setIsSending(false));
    } else {
      updateCountry(data)
        .then((result) => {
          onCountryUpdated(result);
        })
        .finally(() => setIsSending(false));
    }
  };
  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }
  return (
    <Panel className="m-3">
      {isCreateMode ? (
        <h3 className="header-form">Add New Country</h3>
      ) : (
        <h3 className="header-form">Update Country</h3>
      )}
      <div className="row">
        <div className="col-4">
          <CountryForm
            id={'country-form'}
            initialValues={country || initialState}
            onSubmit={onSubmit}
            validationSchema={countrySchema}
          >
            <CountryForm.CountryCode disabled={!isCreateMode} />
            <CountryForm.Name />
          </CountryForm>
        </div>
      </div>
      <div className="d-flex justify-content-end row">
        {(userHas(UPDATE_COUNTRY_LINK_KEY, country?.links) || isCreateMode) && (
          <div className="col-3">
            <Button
              name="save-country"
              type="submit"
              color="primary"
              form={'country-form'}
              className="btn-block"
              disabled={isSending}
              isSending={isSending}
            >
              Save Country
            </Button>
          </div>
        )}
        <div className="col-3">
          <Button
            type="button"
            color="primary"
            onClick={onCancel}
            className="w-100 btn-secondary"
            disabled={isSending}
          >
            Close
          </Button>
        </div>
      </div>
    </Panel>
  );
};
