import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { PaymentForm } from './payment.form';
import {
  AccountingAccountDto,
  AccountingTransactionPaymentDto,
  ContactDto,
  ContactType,
  CurrencyDto,
  OrganizationConfigDtoPagedResult,
  PaymentDto,
  PaymentStatus,
} from '../../../models/data.models';
import { createPayment, getPayment, updatePayment } from '../payments.store';
import { Panel } from '../../common/components/panel/panel.component';
import { AccountingTransactionPaymentsListComponent } from './accountingTransactionPayments-list.component';
import { FormContext } from '../../common/components/form/form.component';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { PaymentDefaultValues } from '../../common/DefaultValues';
import { currencyStore, getCurrency } from '../../currencies/currencies.store';
import { $enum } from 'ts-enum-util';
import * as Yup from 'yup';
import { authStore, userHas } from '../../auth/auth.store';
import { UPDATE_PAYMENT_LINK_KEY } from '../payments.service';
import { v4 } from 'uuid';
import { addMessage } from '../../common/messages.store';
import { validateNumberInput } from '../../../utils/helper.utils';
import { getContact } from '../../contacts/contacts.store';
import { getOrganizationConfigs } from '../../organizationConfigs/organizationConfigs.store';

export type PaymentEditProps = {
  organizationId?: number | null;
  organizationConfigName?: string | null;
  paymentId?: number | null;
  onPaymentCreated?: (payment: PaymentDto) => void;
  onPaymentUpdated?: (payment: PaymentDto) => void;
  onPaymentLoaded?: (payment: PaymentDto) => void;
  onCancel?: () => void;
  defaultPayment?: PaymentDto | null;
  selectedFilter?: string;
  isModal?: boolean;
};

export const paymentInitialState: PaymentDto = {
  paymentId: null,
  accountingAccountId: PaymentDefaultValues.accountingAccountId,
  amountReceived: PaymentDefaultValues.amountReceived,
  amountPaid: PaymentDefaultValues.amountPaid,
  applyToContactId: PaymentDefaultValues.applyToContactId,
  checkNumber: PaymentDefaultValues.checkNumber,
  created: null,
  createdBy: '',
  currencyId: PaymentDefaultValues.currencyId,
  divisionId: PaymentDefaultValues.divisionId,
  lastModified: null,
  lastModifiedBy: '',
  memo: PaymentDefaultValues.memo,
  organizationId: null,
  paymentDate: PaymentDefaultValues.paymentDate,
  accountingAccountName: PaymentDefaultValues.accountingAccountName,
  applyToContactName: PaymentDefaultValues.applyToContactName,
  createdByUserName: '',
  currencyName: PaymentDefaultValues.currencyName,
  currency: PaymentDefaultValues.currency,
  divisionName: PaymentDefaultValues.divisionName,
  customValues: PaymentDefaultValues.customValues,
  updatedByUserName: '',
  links: [],
};

const paymentSchema = Yup.object().shape({
  applyToContactId: Yup.string().required("Can't be blank").nullable(true),
  amountPaid: Yup.string().nullable(true),
  amountReceived: Yup.string()
    .required("Can't be blank")
    .transform((value) => (value === null ? '' : value))
    .test('numberFormat', 'Incorrect number format', (value) => {
      return (
        (new RegExp(/^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0\.\d*[1-9])$/gm).test(
          value?.toString(),
        ) &&
          Number(value) < Number.MAX_SAFE_INTEGER) ||
        value === undefined ||
        value === ''
      );
    })
    .test('positive', "Value can't be equal to or less than zero", (value) => {
      return Number(value) > 0;
    })
    .test('length', 'Max number length is 17', (value) => {
      return value?.replace('-', '').length <= 17;
    })
    .equals(
      [Yup.ref('amountPaid')],
      'Amount Received should match Amount Applied',
    )
    .nullable(true),
  paymentDate: Yup.date().required("Can't be blank").nullable(true),
  accountingAccountId: Yup.string().required("Can't be blank").nullable(true),
  currencyId: Yup.string().required("Can't be blank").nullable(true),
  divisionId: Yup.string().required("Can't be blank").nullable(true),
});

export const PaymentEdit = ({
  organizationId,
  organizationConfigName,
  paymentId,
  onPaymentLoaded = () => {},
  onPaymentCreated = () => {},
  onPaymentUpdated = () => {},
  onCancel = () => {},
  defaultPayment = null,
  selectedFilter = '',
  isModal = false,
}: PaymentEditProps) => {
  const { user: currentUser } = authStore.getState();
  const defaultCurrency = currencyStore?.getState().defaultCurrency;

  paymentInitialState.currencyId = defaultCurrency?.currencyId;
  paymentInitialState.currencyName = defaultCurrency?.currencyName;
  paymentInitialState.currency = defaultCurrency;

  const isCreateMode = !paymentId || paymentId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [payment, setPayment] = useState<PaymentDto | null>(null);
  const [initialValues, setInitialValues] = useState<PaymentDto | null>(
    paymentInitialState,
  );
  const [applyToContactId, setApplyToContactId] = useState(null);
  const [applyToContact, setApplyToContact] = useState<ContactDto>(null);
  const [
    accountingTransactionPayments,
    setAccountingTransactionPayments,
  ] = useState<AccountingTransactionPaymentDto[]>([]);
  const [QBConnected, setQBConnected] = useState<boolean>(false);

  useEffect(() => {
    if (isCreateMode) {
      if (defaultPayment) {
        if (!defaultPayment.currencyId) {
          defaultPayment.currencyId = defaultCurrency?.currencyId;
          defaultPayment.currencyName = defaultCurrency?.currencyName;
          defaultPayment.currency = defaultCurrency;
        }
        setPayment(defaultPayment);
        setInitialValues(defaultPayment);
        setApplyToContactId(defaultPayment.applyToContactId);
        setAccountingTransactionPayments(
          defaultPayment.accountingTransactionPayments,
        );
        onPaymentLoaded(defaultPayment);
      } else {
        setPayment(initialValues);
        setInitialValues(initialValues);
      }
      setIsLoading(false);
    } else if (paymentId) {
      getQuickBooksData().then(
        () => {
          getPayment({ paymentId }).then(
            (paymentDto: PaymentDto) => {
              paymentDto.amountPaid = paymentDto.accountingTransactionPayments?.reduce(
                (prev, cur) => prev + cur.amountApplied,
                0,
              );
              const currencyId = paymentDto.currencyId;
              getCurrency({ currencyId }).then((currencyDto) => {
                paymentDto.currency = currencyDto;
                setPayment(paymentDto);
                setInitialValues(paymentDto);
                setApplyToContactId(paymentDto.applyToContactId);
                setAccountingTransactionPayments(
                  paymentDto.accountingTransactionPayments,
                );
                onPaymentLoaded(paymentDto);
                setIsLoading(false);
              });
            },
            () => {},
          );
        },
        () => {},
      );
    } else {
      throw new Error('Payment keys were not provided');
    }
  }, [paymentId]);

  const onSubmit = (data: PaymentDto) => {
    let errors: boolean = false;
    data.accountingTransactionPayments?.forEach(
      (accountingTransactionPayment) => {
        if (
          accountingTransactionPayment.amountApplied >
          accountingTransactionPayment.amountDue
        ) {
          addMessage({
            id: v4(),
            message: `Payment amount can't be greater than payment due (transaction ${accountingTransactionPayment.transactionNumber})`,
            type: 'danger',
          });
          errors = true;
        }
      },
    );
    if (!errors) {
      setIsSending(true);
      if (isCreateMode) {
        createPayment(data)
          .then(
            (result) => {
              onPaymentCreated(result.data);
            },
            () => {},
          )
          .finally(() => setIsSending(false));
      } else {
        updatePayment(data)
          .then(
            (result) => {
              onPaymentUpdated(result.data);
            },
            () => {},
          )
          .finally(() => setIsSending(false));
      }
    }
  };
  const onPaymentDateChange = (date?: any) => {
    setPayment((payment) => {
      if (!payment) {
        payment = paymentInitialState;
      }
      payment.paymentDate = new Date(date);
      return { ...payment };
    });
  };
  const onAccountingTransactionPaymentsChange = (
    accountingTransactionPayments,
  ) => {
    setPayment((payment) => {
      payment.accountingTransactionPayments = accountingTransactionPayments;
      return { ...payment };
    });
  };

  const voidPayment = () => {
    showDialog({
      dialog: Confirm,
      props: {
        title: 'Void Payment',
        message: 'Are you sure?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          const voidedPayment = {
            ...initialValues,
            paymentStatus: PaymentStatus.Void,
          };
          onSubmit(voidedPayment);
        }
      },
      () => {},
    );
  };

  const isVoidDisabled = (): boolean => {
    return payment?.paymentStatus === PaymentStatus.Void;
  };

  const getQuickBooksData = async (): Promise<void> => {
    if (organizationId) {
      getOrganizationConfigs({
        limit: 1,
        offset: 0,
        sort: '',
        filter: `configName:${organizationConfigName} AND organizationId:${organizationId}`,
        search: '',
      }).then(
        (quickBooksDto: OrganizationConfigDtoPagedResult) => {
          if (quickBooksDto.items.length === 0) {
            setQBConnected(false);
          } else {
            setQBConnected(true);
          }
        },
        () => {},
      );
    }
  };

  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <PaymentForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      id={'PaymentForm'}
      validationSchema={paymentSchema}
    >
      <FormContext.Consumer>
        {(context) => (
          <Tabs>
            <TabList>
              <div className="d-flex justify-content-between">
                <div>
                  <Tab>Payment</Tab>
                  <Tab>Events</Tab>
                  <Tab>Attachments</Tab>
                  <Tab>Notes</Tab>
                  <Tab>Internal Notes</Tab>
                </div>
                {!isCreateMode && (
                  <ActionBarComponent
                    buttonText={<>&middot;&middot;&middot;</>}
                    style={{
                      minWidth: 'fit-content',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      height: '37px',
                    }}
                  >
                    <button
                      type={'button'}
                      onClick={voidPayment}
                      disabled={isVoidDisabled() || QBConnected}
                      title={
                        isVoidDisabled() || QBConnected
                          ? QBConnected
                            ? 'QB connected'
                            : 'This payment is already voided'
                          : null
                      }
                    >
                      Void Payment
                    </button>
                  </ActionBarComponent>
                )}
              </div>
            </TabList>
            <TabPanel>
              <Panel className="m-3">
                <div className="row">
                  <div className="col-6">
                    <PaymentForm.ApplyToContactId
                      defaultValue={
                        payment &&
                        payment.applyToContactId != null &&
                        payment.applyToContactName != null
                          ? {
                              contactId: payment.applyToContactId,
                              name: payment.applyToContactName,
                            }
                          : ''
                      }
                      onChange={(contactDto?: ContactDto, context?: any) => {
                        if (contactDto?.contactId) {
                          getContact({
                            contactId: contactDto.contactId,
                          }).then((contactDto) => {
                            setApplyToContact(contactDto);
                            setPayment((payment) => {
                              if (!payment) {
                                payment = paymentInitialState;
                              }
                              payment.applyToContactId = contactDto?.contactId;
                              payment.applyToContactName = contactDto?.name;
                              payment.divisionId = contactDto.divisionId;
                              context.setFieldValue(
                                'divisionId',
                                contactDto.divisionId,
                                true,
                              );
                              payment.divisionName = contactDto.divisionName;
                              context.setFieldValue(
                                'divisionName',
                                contactDto.divisionName,
                              );
                              setApplyToContactId(payment.applyToContactId);
                              return { ...payment };
                            });
                          });
                        } else {
                          setApplyToContact(null);
                          setPayment((payment) => {
                            if (!payment) {
                              payment = paymentInitialState;
                            }
                            payment.applyToContactId = null;
                            payment.applyToContactName = null;
                            payment.divisionId = null;
                            context.setFieldValue('divisionId', null, true);
                            payment.divisionName = null;
                            context.setFieldValue('divisionName', null);
                            setApplyToContactId(payment.applyToContactId);
                            return { ...payment };
                          });
                        }

                        setAccountingTransactionPayments([]);
                      }}
                      id="applyToContactId"
                      nameId="applyToContactName"
                      header="Apply To Contact"
                      required={true}
                      contactTypes={$enum(ContactType).map((contactType) => {
                        return contactType;
                      })}
                      disabled={!isCreateMode || isModal}
                    />
                  </div>
                  <div className="col-2">
                    <PaymentForm.AmountReceived
                      disabled={!isCreateMode}
                      onKeyDown={validateNumberInput}
                    />
                  </div>
                  <div className="col-2">
                    <PaymentForm.PaymentDate
                      defaultValue={payment?.paymentDate}
                      onChange={onPaymentDateChange}
                    />
                  </div>
                  <div className="col-2">
                    <PaymentForm.CheckNumber />
                  </div>
                  <div className="col-6">
                    <PaymentForm.AccountingAccountId
                      disabled={QBConnected}
                      id={'accountingAccountId'}
                      header={'Account'}
                      placeholder={'Select Account'}
                      required={true}
                      defaultValue={
                        payment &&
                        payment.accountingAccountId != null &&
                        payment.accountingAccountName != null &&
                        payment.accountingAccountType != null
                          ? {
                              accountId: payment.accountingAccountId,
                              accountName: payment.accountingAccountName,
                              accountType: payment.accountingAccountType,
                            }
                          : ''
                      }
                      onChange={(data?: AccountingAccountDto) => {
                        setPayment((payment) => {
                          if (!payment) {
                            payment = paymentInitialState;
                          }
                          payment.accountingAccountId = data?.accountId;
                          payment.accountingAccountName = data?.accountName;
                          payment.accountingAccountType = data?.accountType;
                          return { ...payment };
                        });
                      }}
                      nameId={'accountingAccountName'}
                    />
                  </div>
                  <div className="offset-2 col-2">
                    <PaymentForm.CurrencyId
                      selectedFilter={` `}
                      required={true}
                      nameId={'currencyName'}
                      id={'currencyId'}
                      header={'Currency'}
                      defaultValue={
                        payment &&
                        payment.currencyId != null &&
                        payment.currencyName != null
                          ? {
                              currencyId: payment.currencyId,
                              currencyCode: payment.currencyName,
                            }
                          : initialValues.currencyId != null &&
                            initialValues.currencyName != null
                          ? {
                              currencyId: initialValues.currencyId,
                              currencyCode: initialValues.currencyName,
                            }
                          : ''
                      }
                      onChange={(data?: CurrencyDto) => {
                        setPayment((payment) => {
                          if (!payment) {
                            payment = paymentInitialState;
                          }
                          payment.currencyId = data?.currencyId;
                          payment.currencyName = data?.currencyCode;
                          context.setFieldValue('currency', data);
                          return { ...payment };
                        });
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <PaymentForm.DivisionId
                      id={'divisionId'}
                      header={'Division'}
                      required={true}
                      defaultValue={
                        applyToContact &&
                        applyToContact.divisionId != null &&
                        applyToContact.divisionName != null
                          ? {
                              divisionId: applyToContact.divisionId,
                              divisionName: applyToContact.divisionName,
                            }
                          : payment &&
                            payment.divisionId != null &&
                            payment.divisionName != null
                          ? {
                              divisionId: payment.divisionId,
                              divisionName: payment.divisionName,
                            }
                          : ''
                      }
                      nameId={'divisionName'}
                      defaultValueFilter={`divisionId:${currentUser?.divisionId}`}
                      disabled={true}
                    />
                  </div>
                  <div className="col-6">
                    <PaymentForm.Memo />
                  </div>
                </div>
                <AccountingTransactionPaymentsListComponent
                  applyToContactId={applyToContactId}
                  onChange={onAccountingTransactionPaymentsChange}
                  accountingTransactionPayments={accountingTransactionPayments}
                  setAccountingTransactionPayments={
                    setAccountingTransactionPayments
                  }
                  isCreateMode={isCreateMode}
                  selectedFilter={selectedFilter}
                />
                <div className="row mt-4">
                  <div className="col-2 ml-auto">
                    <PaymentForm.AmountPaid
                      defaultValue={accountingTransactionPayments?.reduce(
                        (prev, cur) => prev + cur.amountApplied,
                        0,
                      )}
                    />
                  </div>
                </div>
                <div className="justify-content-end row">
                  {(userHas(UPDATE_PAYMENT_LINK_KEY, payment?.links) ||
                    isCreateMode) && (
                    <div className="col-3">
                      <Button
                        name="save-payment"
                        type="submit"
                        color="primary"
                        className="btn-block mt-4"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Payment
                      </Button>
                    </div>
                  )}
                  <div className="col-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="w-100 btn-secondary mt-4"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Panel>
            </TabPanel>
            <TabPanel />
            <TabPanel />
            <TabPanel />
            <TabPanel />
          </Tabs>
        )}
      </FormContext.Consumer>
    </PaymentForm>
  );
};
