import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { CUSTOMERS_CREATE_PATH, CUSTOMERS_LIST_PATH } from '../customer.route';
import { CustomerEdit } from '../components/customer-edit.component';
import { addMessage, Message } from '../../common/messages.store';
import { organizationsStore } from '../../organization/organization.store';
import { Helmet } from 'react-helmet';
import { CREATE_CONTACT_LINK_KEY } from '../../contacts/contacts.service';
import { authStore, userHas } from '../../auth/auth.store';
import { useStore } from 'effector-react';

export type CustomerScreenProps = { pageTitle: string };

export const CustomerScreen = ({ pageTitle }: CustomerScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, contactId } = useParams();
  const { user } = useStore(authStore);

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + CUSTOMERS_CREATE_PATH, {
      organizationId,
    });
  }
  const onCustomerCreated = () => {
    const path = generatePath(INTERNAL_PATH + CUSTOMERS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'customer-created',
      type: 'success',
      autoHide: true,
      message: 'Customer successfully created!',
    };
    history.push(path);
    addMessage(message);
  };
  const onCustomerUpdated = () => {
    const path = generatePath(INTERNAL_PATH + CUSTOMERS_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'customer-updated',
      type: 'success',
      autoHide: true,
      message: 'Customer successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };
  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + CUSTOMERS_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };
  return (
    <InternalLayout
      title={'Customers'}
      createPath={
        userHas(CREATE_CONTACT_LINK_KEY) &&
        (user?.isInOrgAdminRole || user?.isInAccountingRole)
          ? createPath()
          : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CustomerEdit
        contactId={contactId}
        onCustomerUpdated={onCustomerUpdated}
        onCustomerCreated={onCustomerCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
