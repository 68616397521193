import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { addMessage, Message } from '../../common/messages.store';
import {
  DOCUMENT_TEMPLATES_CREATE_PATH,
  DOCUMENT_TEMPLATES_LIST_PATH,
} from '../documentTemplate.route';
import { DocumentTemplateEdit } from '../components/documentTemplate-edit.component';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_DOCUMENTTEMPLATE_LINK_KEY } from '../documentTemplates.service';
import { userHas } from '../../auth/auth.store';

export type DocumentTemplatesScreenProps = { pageTitle: string };

export const DocumentTemplateScreen = ({
  pageTitle,
}: DocumentTemplatesScreenProps) => {
  const history = useHistory();
  // @ts-ignore
  const { organizationId, documentTemplateId } = useParams();

  useEffect(() => {}, []);
  function createPath() {
    return generatePath(INTERNAL_PATH + DOCUMENT_TEMPLATES_CREATE_PATH, {
      organizationId,
    });
  }
  const onDocumentTemplateCreated = () => {
    const path = generatePath(INTERNAL_PATH + DOCUMENT_TEMPLATES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'documentTemplate-created',
      type: 'success',
      autoHide: true,
      message: 'DocumentTemplate successfully created!',
    };
    history.push(path);
    addMessage(message);
  };
  const onDocumentTemplateUpdated = () => {
    const path = generatePath(INTERNAL_PATH + DOCUMENT_TEMPLATES_LIST_PATH, {
      organizationId,
    });
    const message: Message = {
      id: 'documentTemplate-updated',
      type: 'success',
      autoHide: true,
      message: 'DocumentTemplate successfully updated!',
    };
    history.push(path);
    addMessage(message);
  };

  const onCancel = () => {
    const { currentOrganization } = organizationsStore.getState();
    const path = generatePath(INTERNAL_PATH + DOCUMENT_TEMPLATES_LIST_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(path);
  };

  return (
    <InternalLayout
      title={'Document Templates'}
      createPath={
        userHas(CREATE_DOCUMENTTEMPLATE_LINK_KEY) ? createPath() : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <DocumentTemplateEdit
        documentTemplateId={documentTemplateId}
        onDocumentTemplateUpdated={onDocumentTemplateUpdated}
        onDocumentTemplateCreated={onDocumentTemplateCreated}
        onCancel={onCancel}
      />
    </InternalLayout>
  );
};
