import React, { Fragment } from 'react';
import { components, MenuProps } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

export const SelectEntityAsyncPaginate = (selectProps) => {
  const {
    entityName,
    canCreateNewEntity,
    onCreateNewEntity,
    isMulti,
    onChange,
    value,
  } = selectProps;
  const entityNameElement = <>{entityName}</>;
  const Menu = (props: MenuProps) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          {props.children}
          {canCreateNewEntity ? (
            <button
              type={'button'}
              data-cy={'create-new-entity'}
              className={'btn btn-sm btn-primary w-100'}
              style={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}
              onClick={() => {
                onCreateNewEntity().then(
                  (result) => {
                    if (result) {
                      if (isMulti) {
                        onChange([...value, result]);
                      } else {
                        onChange(result);
                      }
                    }
                  },
                  () => {},
                );
              }}
            >
              Create new {entityNameElement}
            </button>
          ) : (
            <></>
          )}
        </components.Menu>
      </Fragment>
    );
  };
  return (
    <AsyncPaginate
      {...selectProps}
      components={{
        IndicatorSeparator: () => null,
        Menu,
      }}
    />
  );
};
