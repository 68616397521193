import {
  PackageTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  PackageTypeDtoPagedResult,
  CreatePackageTypeCommandValues,
  UpdatePackageTypeCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createPackageTypeRequest,
  getPackageTypesListRequest,
  getPackageTypeRequest,
  deletePackageTypeRequest,
  updatePackageTypeRequest,
  GetPackageTypeParams,
} from './packageTypes.service';

type PackageTypesStoreState = {
  links: LinkDto[];
  packageTypeColumns: Column[];
};

export const updatePackageTypesColumns = createEvent<Column[]>();

export const getPackageTypes = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getPackageTypesListRequest(currentOrganization, params);
});

export const createPackageType = createEffect(
  (packageTypeData: PackageTypeDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createPackageTypeCommand: CreatePackageTypeCommandValues = {
      ...packageTypeData,
    };

    return createPackageTypeRequest(
      currentOrganization!,
      createPackageTypeCommand,
    );
  },
);

export const getPackageType = createEffect(
  (packageTypeParams: GetPackageTypeParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getPackageTypeRequest(
      currentOrganization as LinkResourceBaseDto,
      packageTypeParams,
    );
  },
);

export const updatePackageType = createEffect((packageType: PackageTypeDto) => {
  const updatePackageTypeCommand: UpdatePackageTypeCommandValues = {
    ...packageType,
  };
  return updatePackageTypeRequest(packageType, updatePackageTypeCommand);
});

export const deletePackageType = createEffect((packageType: PackageTypeDto) => {
  return deletePackageTypeRequest(packageType);
});

const defaultState: PackageTypesStoreState = {
  links: [],
  packageTypeColumns: [
    {
      name: 'packageTypeId',
      visible: true,
      title: 'Id',
      sortName: 'packageTypeId',
    },
    { name: 'name', visible: true, title: 'Name', sortName: 'name' },
    {
      name: 'containerTypeCode',
      visible: true,
      title: 'Type Code',
      sortName: 'containerTypeCode',
    },
    {
      name: 'containerDescriptionCode',
      visible: true,
      title: 'Description Code',
      sortName: 'containerDescriptionCode',
    },
    {
      name: 'packageCategoryCode',
      visible: true,
      title: 'Category Code',
      sortName: 'packageCategoryCode',
    },
    { name: 'air', visible: true, title: 'Air', sortName: 'air' },
    { name: 'ground', visible: true, title: 'Ground', sortName: 'ground' },
    { name: 'ocean', visible: true, title: 'Ocean', sortName: 'ocean' },
    { name: 'height', visible: true, title: 'Height', sortName: 'height' },
    { name: 'length', visible: true, title: 'Length', sortName: 'length' },
    {
      name: 'maximumWeight',
      visible: true,
      title: 'Maximum Weight',
      sortName: 'maximumWeight',
    },
    { name: 'volume', visible: true, title: 'Volume', sortName: 'volume' },
    { name: 'weight', visible: true, title: 'Weight', sortName: 'weight' },
    { name: 'width', visible: true, title: 'Width', sortName: 'width' },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
    { name: 'created', visible: false, title: 'Created', sortName: 'created' },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
  ],
};

export const packageTypeStore: Store<PackageTypesStoreState> = createStore(
  defaultState,
).on(updatePackageTypesColumns, (state, payload) => {
  return { ...state, packageTypeColumns: payload };
});
