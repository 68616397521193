import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  DivisionDto,
  DivisionDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  divisionStore,
  getDivisions,
  deleteDivision,
  updateDivisionsColumns,
} from '../divisions.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_DIVISION_LINK_KEY,
  DELETE_DIVISION_LINK_KEY,
  GetDivisionParams,
} from '../divisions.service';
import { DivisionDialog } from './division.dialog';
import { userHas } from '../../auth/auth.store';

export type DivisionsListProps = {
  goToDetails?: (divisionParams: GetDivisionParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (division: DivisionDto, divisionParams: GetDivisionParams) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const DivisionsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  showAllStore = true,
  rowKeys = ['divisionId'],
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: DivisionsListProps) => {
  const [divisions, setDivisions] = useState<DivisionDtoPagedResult | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getDivisionsData();
  }, [offset, limit, sort, filter, search]);
  const getDivisionsData = () => {
    getDivisions({ offset, limit, sort, filter, search }).then(
      (divisionsData) => {
        setDivisions(divisionsData);
        setLoading(false);
      },
      () => {},
    );
  };
  const { divisionColumns: columns } = useStore(divisionStore);

  const onDeleteDivision = (division: DivisionDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${division.divisionId} Division`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteDivision(division).then(
            () => {
              onDelete();
              getDivisionsData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditDivision = (division: DivisionDto) => {
    goToDetails({ divisionId: division.divisionId });
  };

  const onCreateNewDivision = () => {
    goToDetails({ divisionId: 0 });
  };
  const onDivisionSelect = (division, divisionId) => {
    if (onSelect) onSelect(division, divisionId);
    showDialog({
      dialog: DivisionDialog,
      props: {
        divisionId: division.divisionId,
        title: 'Update Division',
      },
    }).then(
      (division) => {
        if (division !== null) {
          getDivisionsData();
        }
      },
      () => {},
    );
  };
  if (loading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          isDropDownList={isDropDownList}
          showEmptyTable={true}
          onSearch={onSearch}
          search={search}
          showAllStore={showAllStore}
          rowKeys={rowKeys}
          data={divisions?.items}
          columns={columns}
          offset={offset}
          limit={limit}
          total={divisions?.totalCount}
          sort={sort}
          onDelete={
            userHas(DELETE_DIVISION_LINK_KEY, divisions?.items[0]?.links)
              ? onDeleteDivision
              : null
          }
          onSort={onSort}
          onEdit={onEditDivision}
          onPageChanged={onPageChanged}
          onColumnsChanged={updateDivisionsColumns}
          onSelect={onDivisionSelect}
          className={'division-list'}
          filter={filter}
          onFilter={onFilter}
        />
        {!(
          (divisions && divisions.items && divisions.items.length) ||
          filter ||
          search
        ) && (
          <div className="m-5 text-center">
            <h3 className="text-muted mb-4">You Don't Have Any Division Yet</h3>
            {userHas(CREATE_DIVISION_LINK_KEY) && (
              <Button size={'lg'} color="primary" onClick={onCreateNewDivision}>
                Create Division
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
};
