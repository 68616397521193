import {
  AccountingTransactionDto,
  LinkDto,
  LinkResourceBaseDto,
  AccountingTransactionDtoPagedResult,
  CreateAccountingTransactionCommandValues,
  UpdateAccountingTransactionCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_ACCOUNTINGTRANSACTIONS_LINK_KEY = 'get-accountingtransactions';
export const CREATE_ACCOUNTINGTRANSACTION_LINK_KEY =
  'create-accountingtransaction';
export const GET_ACCOUNTINGTRANSACTION_LINK_KEY = 'get-accountingtransaction';
export const UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY =
  'update-accountingtransaction';
export const POST_ACCOUNTINGTRANSACTIONTOQB_LINK_KEY =
  'post-accountingtransactiontoqb';
export const DELETE_ACCOUNTINGTRANSACTION_LINK_KEY =
  'delete-accountingtransaction';

export const ACCOUNTING_TRANSACTION_ENTITY_NAME = 'Accounting Transaction';

export interface GetAccountingTransactionParams {
  accountingTransactionId: number;
}

export const getAccountingTransactionRequest = async (
  resource: LinkResourceBaseDto | null,
  { accountingTransactionId }: GetAccountingTransactionParams,
): Promise<AccountingTransactionDto> => {
  if (resource && accountingTransactionId) {
    const getAccountingTransactionLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGTRANSACTION_LINK_KEY,
    );
    if (getAccountingTransactionLink) {
      const result = await execLink(getAccountingTransactionLink, {
        accountingTransactionId,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ACCOUNTINGTRANSACTION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getAccountingTransactionsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<AccountingTransactionDtoPagedResult> => {
  if (resource) {
    const getAccountingTransactionsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGTRANSACTIONS_LINK_KEY,
    );
    if (getAccountingTransactionsListLink) {
      const result = await execLink(getAccountingTransactionsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ACCOUNTINGTRANSACTIONS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createAccountingTransactionRequest = async (
  resource: LinkResourceBaseDto,
  accountingTransaction: CreateAccountingTransactionCommandValues,
) => {
  const createAccountingTransactionLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  );
  if (createAccountingTransactionLink) {
    const result = await execLink(
      createAccountingTransactionLink,
      accountingTransaction,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_ACCOUNTINGTRANSACTION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateAccountingTransactionRequest = async (
  resource: LinkResourceBaseDto,
  accountingTransaction: UpdateAccountingTransactionCommandValues,
) => {
  const updateAccountingTransactionLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  );
  if (updateAccountingTransactionLink) {
    const result = await execLink(
      updateAccountingTransactionLink,
      accountingTransaction,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_ACCOUNTINGTRANSACTION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const postAccountingTransactionToQBRequest = async (
  resource: LinkResourceBaseDto,
  accountingTransaction: UpdateAccountingTransactionCommandValues,
) => {
  const postAccountingTransactionToQBLink = resource.links?.find(
    (x: LinkDto) => x.rel === POST_ACCOUNTINGTRANSACTIONTOQB_LINK_KEY,
  );
  if (postAccountingTransactionToQBLink) {
    const result = await execLink(
      postAccountingTransactionToQBLink,
      accountingTransaction,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${POST_ACCOUNTINGTRANSACTIONTOQB_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteAccountingTransactionRequest = async (
  accountingTransaction: AccountingTransactionDto,
) => {
  const deleteAccountingTransactionLink = accountingTransaction.links?.find(
    (x: LinkDto) => x.rel === DELETE_ACCOUNTINGTRANSACTION_LINK_KEY,
  );
  if (deleteAccountingTransactionLink) {
    const result = await execLink(
      deleteAccountingTransactionLink,
      accountingTransaction,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_ACCOUNTINGTRANSACTION_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
