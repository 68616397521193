import React from 'react';
import { Helmet } from 'react-helmet';
import { OrderTracking } from '../components/orderTracking.component';

export type OrderTrackingScreenProps = { pageTitle: string };

export const OrderTrackingScreen = ({
  pageTitle,
}: OrderTrackingScreenProps) => {
  const orderTrackingId = new URLSearchParams(window.location.search).get('id');
  // @ts-ignore

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OrderTracking orderTrackingId={orderTrackingId} />
    </>
  );
};
