import {
  PaymentDto,
  LinkDto,
  LinkResourceBaseDto,
  PaymentDtoPagedResult,
  CreatePaymentCommandValues,
  UpdatePaymentCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_PAYMENTS_LINK_KEY = 'get-payments';
export const CREATE_PAYMENT_LINK_KEY = 'create-payment';
export const GET_PAYMENT_LINK_KEY = 'get-payment';
export const UPDATE_PAYMENT_LINK_KEY = 'update-payment';
export const DELETE_PAYMENT_LINK_KEY = 'delete-payment';

export interface GetPaymentParams {
  paymentId: number;
}

export const getPaymentRequest = async (
  resource: LinkResourceBaseDto | null,
  { paymentId }: GetPaymentParams,
): Promise<PaymentDto> => {
  if (resource && paymentId) {
    const getPaymentLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PAYMENT_LINK_KEY,
    );
    if (getPaymentLink) {
      const result = await execLink(getPaymentLink, { paymentId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PAYMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getPaymentsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PaymentDtoPagedResult> => {
  if (resource) {
    const getPaymentsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PAYMENTS_LINK_KEY,
    );
    if (getPaymentsListLink) {
      const result = await execLink(getPaymentsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PAYMENTS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createPaymentRequest = async (
  resource: LinkResourceBaseDto,
  payment: CreatePaymentCommandValues,
) => {
  const createPaymentLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PAYMENT_LINK_KEY,
  );
  if (createPaymentLink) {
    const result = await execLink(createPaymentLink, payment);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_PAYMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updatePaymentRequest = async (
  resource: LinkResourceBaseDto,
  payment: UpdatePaymentCommandValues,
) => {
  const updatePaymentLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PAYMENT_LINK_KEY,
  );
  if (updatePaymentLink) {
    const result = await execLink(updatePaymentLink, payment);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_PAYMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deletePaymentRequest = async (payment: PaymentDto) => {
  const deletePaymentLink = payment.links?.find(
    (x: LinkDto) => x.rel === DELETE_PAYMENT_LINK_KEY,
  );
  if (deletePaymentLink) {
    const result = await execLink(deletePaymentLink, payment);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_PAYMENT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
