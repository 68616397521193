import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DivisionScreen } from './screens/division.screen';
import { DivisionsListScreen } from './screens/divisions-list.screen';

export const DIVISION_LIST_PATH = '/divisions';
export const DIVISION_EDIT_PATH = '/divisions/:divisionId';
export const DIVISION_CREATE_PATH = '/divisions/0';
export const DIVISIONS_PATH = '/org/:organizationId/divisions';

export const DivisionRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${DIVISION_LIST_PATH}`}>
          <DivisionsListScreen pageTitle="Divisions" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${DIVISION_CREATE_PATH}`}>
          <DivisionScreen pageTitle="Add New Division" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${DIVISION_EDIT_PATH}`}>
          <DivisionScreen pageTitle="Update Division" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
