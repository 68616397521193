import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  CurrencyDto,
  CurrencyDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  currencyStore,
  getCurrencies,
  deleteCurrency,
  updateCurrenciesColumns,
} from '../currencies.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_CURRENCY_LINK_KEY,
  DELETE_CURRENCY_LINK_KEY,
  GetCurrencyParams,
} from '../currencies.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { CURRENCIES_CREATE_PATH } from '../currencies.route';
import { CurrencyDialog } from './currency.dialog';
import { userHas } from '../../auth/auth.store';

export type CurrenciesListProps = {
  goToDetails?: (currencyParams: GetCurrencyParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (currency: CurrencyDto, currencyParams: GetCurrencyParams) => void;
  showAllStore?: boolean;
  rowKeys?: string[];
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const CurrenciesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  showAllStore = true,
  rowKeys = ['currencyId'],
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: CurrenciesListProps) => {
  const [currencies, setCurrencies] = useState<CurrencyDtoPagedResult | null>(
    null,
  );

  useEffect(() => {
    getCurrenciesData();
  }, [offset, limit, sort, filter, search]);
  const getCurrenciesData = () => {
    getCurrencies({ offset, limit, sort, filter, search }).then(
      (currenciesData) => {
        setCurrencies(currenciesData);
      },
    );
  };
  const { currencyColumns: columns } = useStore(currencyStore);

  const onDeleteCurrency = (currency: CurrencyDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${currency.currencyId} Currency`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteCurrency(currency).then(() => {
          onDelete();
          getCurrenciesData();
        });
      }
    });
  };

  const onEditCurrency = (currency: CurrencyDto) => {
    goToDetails({ currencyId: currency.currencyId });
  };

  const history = useHistory();
  const onCreateNewCurrency = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + CURRENCIES_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onCurrencySelect = (currency, currencyId) => {
    if (onSelect) onSelect(currency, currencyId);
    showDialog({
      dialog: CurrencyDialog,
      props: {
        currencyId: currency.currencyId,
        title: 'Update Currency',
      },
    }).then((currency) => {
      if (currency !== null) {
        getCurrenciesData();
      }
    });
  };

  return (
    <>
      <Grid
        isDropDownList={isDropDownList}
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={showAllStore}
        rowKeys={rowKeys}
        data={currencies?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={currencies?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_CURRENCY_LINK_KEY, currencies?.items[0]?.links)
            ? onDeleteCurrency
            : null
        }
        onSort={onSort}
        onEdit={onEditCurrency}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateCurrenciesColumns}
        onSelect={onCurrencySelect}
        className={'currencies-list'}
        filter={filter}
        onFilter={onFilter}
      />
      {!(
        (currencies && currencies.items && currencies.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">You Don't Have Any Currency Yet</h3>
          {userHas(CREATE_CURRENCY_LINK_KEY) && (
            <Button size={'lg'} color="primary" onClick={onCreateNewCurrency}>
              Create Currency
            </Button>
          )}
        </div>
      )}
    </>
  );
};
