import React from 'react';
import { TrackingEventDto } from '../../../models/data.models';
import { getFormattedDate } from '../../../utils/formatting.utils';

export type OrderTrackingEventCardProps = {
  trackingEvent: TrackingEventDto | null | undefined;
};

export const OrderTrackingEventCard = ({
  trackingEvent,
}: OrderTrackingEventCardProps) => {
  return (
    <div className={`order-tracking-event-card`}>
      <table>
        <tr>
          <td className="card-event-title">Event date</td>
          <td className="card-event-value">
            {getFormattedDate(trackingEvent.eventDate, false)}
          </td>
        </tr>
        <tr>
          <td className="card-event-title">Event type</td>
          <td className="card-event-value">{trackingEvent.eventName}</td>
        </tr>
        <tr>
          <td className="card-event-title">Location</td>
          <td className="card-event-value">{trackingEvent.location}</td>
        </tr>
        <tr>
          <td className="card-event-title">Details</td>
          <td className="card-event-value">{trackingEvent.description}</td>
        </tr>
      </table>
    </div>
  );
};
