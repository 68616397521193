import {
  ContactAddressDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateContactAddressCommandValues,
  UpdateContactAddressCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createContactAddressRequest,
  getContactAddressesListRequest,
  getContactAddressRequest,
  deleteContactAddressRequest,
  updateContactAddressRequest,
  GetContactAddressParams,
} from './contactAddresses.service';

type ContactAddressesStoreState = {
  links: LinkDto[];
  contactAddressColumns: Column[];
};

export const updateContactAddressesColumns = createEvent<Column[]>();

export const getContactAddresses = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getContactAddressesListRequest(currentOrganization, params);
});

export const createContactAddress = createEffect(
  (contactAddressData: ContactAddressDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');
    const createContactAddressCommand: CreateContactAddressCommandValues = {
      ...contactAddressData,
    };

    return createContactAddressRequest(
      currentOrganization!,
      createContactAddressCommand,
    );
  },
);

export const getContactAddress = createEffect(
  (contactAddressParams: GetContactAddressParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContactAddressRequest(
      currentOrganization as LinkResourceBaseDto,
      contactAddressParams,
    );
  },
);

export const updateContactAddress = createEffect(
  (contactAddress: ContactAddressDto) => {
    const updateContactAddressCommand: UpdateContactAddressCommandValues = {
      ...contactAddress,
    };
    return updateContactAddressRequest(
      contactAddress,
      updateContactAddressCommand,
    );
  },
);

export const deleteContactAddress = createEffect(
  (contactAddress: ContactAddressDto) => {
    return deleteContactAddressRequest(contactAddress);
  },
);

const defaultState: ContactAddressesStoreState = {
  links: [],
  contactAddressColumns: [
    { name: 'contactAddressId', visible: true, title: 'Contact Address Id' },
    { name: 'addressLine', visible: true, title: 'Address Line' },
    { name: 'addressLine2', visible: true, title: 'Address Line2' },
    { name: 'addressType', visible: true, title: 'Address Type' },
    { name: 'city', visible: true, title: 'City' },
    { name: 'contactId', visible: true, title: 'Contact Id' },
    { name: 'countryCode', visible: true, title: 'Country Code' },
    { name: 'created', visible: true, title: 'Created' },
    { name: 'createdBy', visible: true, title: 'Created By' },
    { name: 'lastModified', visible: true, title: 'Last Modified' },
    { name: 'lastModifiedBy', visible: true, title: 'Last Modified By' },
    { name: 'organizationId', visible: true, title: 'Organization Id' },
    { name: 'postalCode', visible: true, title: 'Postal Code' },
    { name: 'stateCode', visible: true, title: 'State Code' },
  ],
};

export const contactAddressStore: Store<ContactAddressesStoreState> = createStore(
  defaultState,
).on(updateContactAddressesColumns, (state, payload) => {
  return { ...state, contactAddressColumns: payload };
});
