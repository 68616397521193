import {
  DocumentTemplateDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateDocumentTemplateCommandValues,
  UpdateDocumentTemplateCommandValues,
  DocumentTemplateType,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createDocumentTemplateRequest,
  getDocumentTemplatesListRequest,
  getDocumentTemplateRequest,
  deleteDocumentTemplateRequest,
  updateDocumentTemplateRequest,
  GetDocumentTemplateParams,
  downloadDefaultDocumentTemplatesRequest,
  GET_RENDEREDDOCUMENT_LINK_KEY,
} from './documentTemplates.service';
import { getBearerToken } from '../api/api.service';
import { getEnumKeyByValue } from '../../utils/helper.utils';
import URITemplate from 'urijs/src/URITemplate';

type DocumentTemplatesStoreState = {
  links: LinkDto[];
  documentTemplateColumns: Column[];
};

export const updateDocumentTemplatesColumns = createEvent<Column[]>();

export const getDocumentTemplates = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getDocumentTemplatesListRequest(currentOrganization, params);
});

export const createDocumentTemplate = createEffect(
  (documentTemplateData: DocumentTemplateDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createDocumentTemplateCommand: CreateDocumentTemplateCommandValues = {
      ...documentTemplateData,
    };

    return createDocumentTemplateRequest(
      currentOrganization!,
      createDocumentTemplateCommand,
    );
  },
);

export const getDocumentTemplate = createEffect(
  (documentTemplateParams: GetDocumentTemplateParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getDocumentTemplateRequest(
      currentOrganization as LinkResourceBaseDto,
      documentTemplateParams,
    );
  },
);

export const updateDocumentTemplate = createEffect(
  (documentTemplate: DocumentTemplateDto) => {
    const updateDocumentTemplateCommand: UpdateDocumentTemplateCommandValues = {
      ...documentTemplate,
    };
    return updateDocumentTemplateRequest(
      documentTemplate,
      updateDocumentTemplateCommand,
    );
  },
);

export const deleteDocumentTemplate = createEffect(
  (documentTemplate: DocumentTemplateDto) => {
    return deleteDocumentTemplateRequest(documentTemplate);
  },
);

export const downloadDefaultDocumentTemplates = createEffect(() => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return downloadDefaultDocumentTemplatesRequest(currentOrganization);
});

export const getRenderedDocumentLink = (documentTemplateType, metadata) => {
  const { currentOrganization } = organizationsStore.getState();
  const getRenderedDocumentLink = currentOrganization.links?.find(
    (x: LinkDto) => x.rel === GET_RENDEREDDOCUMENT_LINK_KEY,
  );
  if (getRenderedDocumentLink) {
    if (getRenderedDocumentLink.href) {
      const bearerToken = `Bearer ${getBearerToken()}`;
      const documentTemplateTypeValue = getEnumKeyByValue(
        documentTemplateType,
        DocumentTemplateType,
      );
      const metadataString = JSON.stringify(metadata);
      return URITemplate(getRenderedDocumentLink.href)
        .expand({
          documentTemplateType: documentTemplateTypeValue,
          metadata: metadataString,
          authorization: bearerToken,
        } as any)
        .toString();
    }
  }
};

const defaultState: DocumentTemplatesStoreState = {
  links: [],
  documentTemplateColumns: [
    {
      name: 'documentTemplateId',
      visible: true,
      title: 'Document Template Id',
      sortName: 'documentTemplateId',
    },
    { name: 'name', visible: true, title: 'Name', sortName: 'name' },
    {
      name: 'documentTemplateType',
      visible: true,
      title: 'Document Template Type',
      sortName: 'documentTemplateType',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'fileNameTemplate',
      visible: true,
      title: 'File Name Template',
      sortName: 'fileNameTemplate',
    },
    {
      name: 'isDefault',
      visible: true,
      title: 'Is Default',
      sortName: 'isDefault',
    },
    {
      name: 'isInactive',
      visible: true,
      title: 'Is Inactive',
      sortName: 'isInactive',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'createdByUserName',
      visible: false,
      title: 'Created by',
      sortName: 'CreatedUser.UserName',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
    {
      name: 'updatedByUserName',
      visible: false,
      title: 'Updated by',
      sortName: 'UpdatedUser.UserName',
    },
  ],
};

export const documentTemplateStore: Store<DocumentTemplatesStoreState> = createStore(
  defaultState,
).on(updateDocumentTemplatesColumns, (state, payload) => {
  return { ...state, documentTemplateColumns: payload };
});
