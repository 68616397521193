import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { ContactType, GetContactsQuery } from '../../../models/data.models';

import { CustomersList } from '../components/customers-list.component';
import {
  CUSTOMERS_CREATE_PATH,
  CUSTOMERS_EDIT_PATH,
  CUSTOMERS_LIST_PATH,
} from '../customer.route';
import { CONTACTS_TAB_EDIT_PATH_FOR_CUSTOMERS } from '../../contacts/contact.route';
import { Helmet } from 'react-helmet';
import { CREATE_CONTACT_LINK_KEY } from '../../contacts/contacts.service';
import { authStore, userHas } from '../../auth/auth.store';
import { useStore } from 'effector-react';

export type CustomerListScreenProps = { pageTitle: string };

export const CustomerListScreen = ({ pageTitle }: CustomerListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = 'contactId',
    filter = `contactType:${ContactType.Customer}`,
    search = '',
  } = useQuery() as GetContactsQuery;
  const history = useHistory();
  const { user } = useStore(authStore);

  const createPath = () => {
    return generatePath(INTERNAL_PATH + CUSTOMERS_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = (contactId: number = 0) => {
    let path;
    if (window.location.href.includes('contacts')) {
      path = generatePath(
        INTERNAL_PATH + CONTACTS_TAB_EDIT_PATH_FOR_CUSTOMERS,
        {
          organizationId,
          contactId,
        },
      );
    } else {
      path = generatePath(INTERNAL_PATH + CUSTOMERS_EDIT_PATH, {
        organizationId,
        contactId,
      });
    }

    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + CUSTOMERS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + CUSTOMERS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CUSTOMERS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CUSTOMERS_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };

  return (
    <InternalLayout
      title={'Customers'}
      createPath={
        userHas(CREATE_CONTACT_LINK_KEY) &&
        (user?.isInOrgAdminRole || user?.isInAccountingRole)
          ? createPath()
          : null
      }
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CustomersList
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        filter={filter}
        search={search}
        onSearch={onSearch}
      />
    </InternalLayout>
  );
};
