import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import { EventDefinitionForm } from './eventDefinition.form';
import {
  AutomaticCreate,
  EventDefinitionDto,
} from '../../../models/data.models';
import {
  createEventDefinition,
  getEventDefinition,
  updateEventDefinition,
} from '../eventDefinitions.store';
import { Panel } from '../../common/components/panel/panel.component';
import { ReactSelectItem } from '../../../models/custom.models';
import { getEnumKeyByValue, getEnumValues } from '../../../utils/helper.utils';
import { EventDefinitionDefaultValues } from '../../common/DefaultValues';
import * as Yup from 'yup';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { UPDATE_EVENTDEFINITION_LINK_KEY } from '../eventDefinitions.service';
import { userHas } from '../../auth/auth.store';

export type EventDefinitionEditProps = {
  eventDefinitionId?: number | null;
  onEventDefinitionCreated?: (eventDefinition: EventDefinitionDto) => void;
  onEventDefinitionUpdated?: (eventDefinition: EventDefinitionDto) => void;
  onEventDefinitionLoaded?: (eventDefinition: EventDefinitionDto) => void;
  onCancel?: () => void;
};

const initialState: EventDefinitionDto = {
  eventDefinitionId: EventDefinitionDefaultValues.eventDefinitionId,
  eventName: EventDefinitionDefaultValues.eventName,
  automaticCreate: EventDefinitionDefaultValues.automaticCreate,
  description: EventDefinitionDefaultValues.description,
  location: EventDefinitionDefaultValues.location,
  isInactive: EventDefinitionDefaultValues.isInactive,
  includeInTracking: EventDefinitionDefaultValues.includeInTracking,
  sendEmail: EventDefinitionDefaultValues.sendEmail,
  organizationId: EventDefinitionDefaultValues.organizationId,
  created: null,
  createdBy: '',
  lastModified: null,
  lastModifiedBy: '',
  links: [],
};

const eventDefinitionSchema = Yup.object().shape({
  eventName: Yup.string()
    .max(50, 'Max length is 50')
    .required("Can't be blank")
    .nullable(true),
});

export const EventDefinitionEdit = ({
  eventDefinitionId,
  onEventDefinitionLoaded = () => {},
  onEventDefinitionCreated = () => {},
  onEventDefinitionUpdated = () => {},
  onCancel = () => {},
}: EventDefinitionEditProps) => {
  const isCreateMode = !eventDefinitionId || eventDefinitionId == 0;
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    eventDefinition,
    setEventDefinition,
  ] = useState<EventDefinitionDto | null>(null);
  const [initialValues, setInitialValues] = useState<EventDefinitionDto | null>(
    initialState,
  );
  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    if (isCreateMode) {
      setIsLoading(false);
    } else if (eventDefinitionId) {
      getEventDefinition({ eventDefinitionId }).then(
        (eventDefinitionDto: EventDefinitionDto) => {
          setEventDefinition(eventDefinitionDto);
          setInitialValues(eventDefinitionDto);
          setIsLoading(false);
          onEventDefinitionLoaded(eventDefinitionDto);
        },
        () => {},
      );
    } else {
      throw new Error('EventDefinition keys were not provided');
    }
  }, [eventDefinitionId]);

  const onSubmit = (data: EventDefinitionDto) => {
    setIsSending(true);
    if (isCreateMode) {
      createEventDefinition(data)
        .then(
          (result) => {
            onEventDefinitionCreated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    } else {
      updateEventDefinition(data)
        .then(
          (result) => {
            onEventDefinitionUpdated(result);
          },
          () => {},
        )
        .finally(() => setIsSending(false));
    }
  };

  const onAutomaticCreateChange = (
    newValueAutomaticCreate: ReactSelectItem,
  ) => {
    setEventDefinition((oldEventDefinition) => {
      if (!oldEventDefinition) {
        oldEventDefinition = initialState;
      }
      oldEventDefinition.automaticCreate = getEnumKeyByValue(
        newValueAutomaticCreate,
        AutomaticCreate,
      );
      return { ...oldEventDefinition };
    });
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <Tabs>
      <TabList>
        <Tab name="eventDefinition">Event Definition</Tab>
      </TabList>
      <EventDefinitionForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={ref}
        id="eventDefinitionForm"
        validationSchema={eventDefinitionSchema}
      >
        <TabPanel>
          <Panel className="m-3">
            {isCreateMode ? (
              <h2 className="header-form">Add Event Definition</h2>
            ) : (
              <h2>Update Event Definition</h2>
            )}
            <div className="row">
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <EventDefinitionForm.EventName />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <EventDefinitionForm.Description />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <EventDefinitionForm.Location />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <EventDefinitionForm.AutomaticCreation
                    defaultValue={
                      eventDefinition?.automaticCreate
                        ? {
                            label:
                              AutomaticCreate[eventDefinition.automaticCreate],
                            value: eventDefinition.automaticCreate,
                          }
                        : null
                    }
                    onChange={onAutomaticCreateChange}
                    multiple={false}
                    options={getEnumValues(AutomaticCreate)}
                  />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <EventDefinitionForm.IsInactive />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <EventDefinitionForm.IncludeInTracking />
                </div>
              </div>
              <div className={'col-12 p-0'}>
                <div className={'col-4'}>
                  <EventDefinitionForm.SendEmail />
                </div>
              </div>

              <div className="col-12">
                <div className="justify-content-end row">
                  {(userHas(
                    UPDATE_EVENTDEFINITION_LINK_KEY,
                    eventDefinition?.links,
                  ) ||
                    isCreateMode) && (
                    <div className="col-3">
                      <Button
                        name="save-eventDefinition"
                        type="submit"
                        color="primary"
                        className="w-100 btn-block"
                        disabled={isSending}
                        isSending={isSending}
                      >
                        Save Event Definition
                      </Button>
                    </div>
                  )}
                  <div className="col-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={onCancel}
                      className="w-100 btn-secondary"
                      disabled={isSending}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </EventDefinitionForm>
    </Tabs>
  );
};
