import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetPortsQuery, OrderTypes } from '../../../models/data.models';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import React from 'react';
import {
  ORDER_CREATE_PATH,
  ORDER_EDIT_PATH,
  ORDER_LIST_PATH,
} from '../order.route';
import { OrdersList } from '../components/orders-list.component';
import { CREATE_ORDER_LINK_KEY, GetOrderParams } from '../orders.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';

export type OrdersListScreenProps = { pageTitle: string };

export const OrdersListScreen = ({ pageTitle }: OrdersListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = '-orderNumber~ToInt32',
    filter = `orderType:${OrderTypes.Order}`,
    search = '',
  } = useQuery() as GetPortsQuery;
  const history = useHistory();

  const createPath = generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
    organizationId,
  });

  const goToDetails = (orderParams: GetOrderParams) => {
    const orderId = orderParams.orderId;
    const createOrEdit = orderId === 0 ? ORDER_CREATE_PATH : ORDER_EDIT_PATH;
    const path = generatePath(INTERNAL_PATH + createOrEdit, {
      organizationId,
      orderId,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + ORDER_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };

  return (
    <InternalLayout
      title={'Orders'}
      createPath={userHas(CREATE_ORDER_LINK_KEY) ? createPath : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OrdersList
        offset={offset}
        limit={limit}
        sort={sort}
        search={search}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        onSearch={onSearch}
        filter={filter}
      />
    </InternalLayout>
  );
};
