import { Dialog } from '../../common/components/dialog/dialog.component';
import { OrderTrackingEdit } from './orderTracking-edit.component';
import { OrderTrackingDto } from '../../../models/data.models';
import { addMessage, Message } from '../../common/messages.store';

export type OrderTrackingDialogProperties = {
  orderTrackingId?: string | null;
  orderId?: number | null;
  title: string;
};

export class OrderTrackingDialog extends Dialog<OrderTrackingDialogProperties> {
  constructor(props: OrderTrackingDialogProperties) {
    super(props);
    this.onOrderTrackingSaved = this.onOrderTrackingSaved.bind(this);
  }

  onOrderTrackingSaved = (orderTracking: OrderTrackingDto) => {
    const message: Message = {
      id: 'orderTracking-created',
      type: 'success',
      autoHide: true,
      message: 'Order Tracking successfully created',
    };
    addMessage(message);
    this.close(orderTracking);
  };

  renderContent(): any {
    const { orderTrackingId, orderId } = this
      .props as OrderTrackingDialogProperties;
    return (
      <OrderTrackingEdit
        orderId={orderId}
        orderTrackingId={orderTrackingId}
        onOrderTrackingCreated={this.onOrderTrackingSaved}
        onOrderTrackingUpdated={this.onOrderTrackingSaved}
        onCancel={this.close.bind(this)}
      />
    );
  }
}
