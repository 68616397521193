import {
  AccountingAccountDto,
  LinkDto,
  LinkResourceBaseDto,
  AccountingAccountDtoPagedResult,
  CreateAccountingAccountCommandValues,
  UpdateAccountingAccountCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_ACCOUNTINGACCOUNTS_LINK_KEY = 'get-accountingaccounts';
export const CREATE_ACCOUNTINGACCOUNT_LINK_KEY = 'create-accountingaccount';
export const GET_ACCOUNTINGACCOUNT_LINK_KEY = 'get-accountingaccount';
export const UPDATE_ACCOUNTINGACCOUNT_LINK_KEY = 'update-accountingaccount';
export const DELETE_ACCOUNTINGACCOUNT_LINK_KEY = 'delete-accountingaccount';
export const DOWNLOAD_QB_ACCOUNTINGACCOUNTS_LINK_KEY =
  'download-qbaccountingaccounts';

export const ACCOUNTING_ACCOUNT_ENTITY_NAME = 'Accounting Account';

export interface GetAccountingAccountParams {
  accountId: number;
}

export const getAccountingAccountRequest = async (
  resource: LinkResourceBaseDto | null,
  { accountId }: GetAccountingAccountParams,
): Promise<AccountingAccountDto> => {
  if (resource && accountId) {
    const getAccountingAccountLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGACCOUNT_LINK_KEY,
    );
    if (getAccountingAccountLink) {
      const result = await execLink(getAccountingAccountLink, { accountId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ACCOUNTINGACCOUNT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getAccountingAccountsListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<AccountingAccountDtoPagedResult> => {
  if (resource) {
    const getAccountingAccountsListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_ACCOUNTINGACCOUNTS_LINK_KEY,
    );
    if (getAccountingAccountsListLink) {
      const result = await execLink(getAccountingAccountsListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_ACCOUNTINGACCOUNTS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createAccountingAccountRequest = async (
  resource: LinkResourceBaseDto,
  accountingAccount: CreateAccountingAccountCommandValues,
) => {
  const createAccountingAccountLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_ACCOUNTINGACCOUNT_LINK_KEY,
  );
  if (createAccountingAccountLink) {
    const result = await execLink(
      createAccountingAccountLink,
      accountingAccount,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_ACCOUNTINGACCOUNT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateAccountingAccountRequest = async (
  resource: LinkResourceBaseDto,
  accountingAccount: UpdateAccountingAccountCommandValues,
) => {
  const updateAccountingAccountLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_ACCOUNTINGACCOUNT_LINK_KEY,
  );
  if (updateAccountingAccountLink) {
    const result = await execLink(
      updateAccountingAccountLink,
      accountingAccount,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_ACCOUNTINGACCOUNT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteAccountingAccountRequest = async (
  accountingAccount: AccountingAccountDto,
) => {
  const deleteAccountingAccountLink = accountingAccount.links?.find(
    (x: LinkDto) => x.rel === DELETE_ACCOUNTINGACCOUNT_LINK_KEY,
  );
  if (deleteAccountingAccountLink) {
    const result = await execLink(
      deleteAccountingAccountLink,
      accountingAccount,
    );
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_ACCOUNTINGACCOUNT_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const downloadQuickBooksAccountingAccountsRequest = async (
  resource?: LinkResourceBaseDto | null,
): Promise<any> => {
  if (resource) {
    const downloadQuickBooksAccountingAccountsLink = resource?.links?.find(
      (x: LinkDto) => x.rel === DOWNLOAD_QB_ACCOUNTINGACCOUNTS_LINK_KEY,
    );
    if (downloadQuickBooksAccountingAccountsLink) {
      const result = await execLink(downloadQuickBooksAccountingAccountsLink);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${DOWNLOAD_QB_ACCOUNTINGACCOUNTS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
