import {
  PackageTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  PackageTypeDtoPagedResult,
  CreatePackageTypeCommandValues,
  UpdatePackageTypeCommandValues,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_PACKAGETYPES_LINK_KEY = 'get-packagetypes';
export const CREATE_PACKAGETYPE_LINK_KEY = 'create-packagetype';
export const GET_PACKAGETYPE_LINK_KEY = 'get-packagetype';
export const UPDATE_PACKAGETYPE_LINK_KEY = 'update-packagetype';
export const DELETE_PACKAGETYPE_LINK_KEY = 'delete-packagetype';

export const PACKAGE_TYPE_ENTITY_NAME = 'Package Type';

export interface GetPackageTypeParams {
  packageTypeId: number;
}

export const getPackageTypeRequest = async (
  resource: LinkResourceBaseDto | null,
  { packageTypeId }: GetPackageTypeParams,
): Promise<PackageTypeDto> => {
  if (resource && packageTypeId) {
    const getPackageTypeLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_PACKAGETYPE_LINK_KEY,
    );
    if (getPackageTypeLink) {
      const result = await execLink(getPackageTypeLink, { packageTypeId });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PACKAGETYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getPackageTypesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<PackageTypeDtoPagedResult> => {
  if (resource) {
    const getPackageTypesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_PACKAGETYPES_LINK_KEY,
    );
    if (getPackageTypesListLink) {
      const result = await execLink(getPackageTypesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_PACKAGETYPES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createPackageTypeRequest = async (
  resource: LinkResourceBaseDto,
  packageType: CreatePackageTypeCommandValues,
) => {
  const createPackageTypeLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_PACKAGETYPE_LINK_KEY,
  );
  if (createPackageTypeLink) {
    const result = await execLink(createPackageTypeLink, packageType);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_PACKAGETYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updatePackageTypeRequest = async (
  resource: LinkResourceBaseDto,
  packageType: UpdatePackageTypeCommandValues,
) => {
  const updatePackageTypeLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_PACKAGETYPE_LINK_KEY,
  );
  if (updatePackageTypeLink) {
    const result = await execLink(updatePackageTypeLink, packageType);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_PACKAGETYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deletePackageTypeRequest = async (packageType: PackageTypeDto) => {
  const deletePackageTypeLink = packageType.links?.find(
    (x: LinkDto) => x.rel === DELETE_PACKAGETYPE_LINK_KEY,
  );
  if (deletePackageTypeLink) {
    const result = await execLink(deletePackageTypeLink, packageType);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_PACKAGETYPE_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
