import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DashboardScreen } from './screens/dashboard.screen';
import { INTERNAL_PATH } from '../../app.router';

export const DASHBOARD_PATH = '/org/:organizationId/';

export const DashboardRoute = (props: RouteProps) => {
  let match = useRouteMatch();
  return (
    <Route {...props}>
      <PrivateRoute exact path={`${match.path}/`}>
        <DashboardScreen pageTitle="Dashboard" />
      </PrivateRoute>
    </Route>
  );
};
