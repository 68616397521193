import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  OrderTrackingDto,
  OrderTrackingDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { orderTrackingStore, getOrderTrackings } from '../orderTrackings.store';
import { showDialog } from '../../common/dialog.store';
import {
  CREATE_ORDERTRACKING_LINK_KEY,
  GetOrderTrackingParams,
} from '../orderTrackings.service';
import { userHas } from '../../auth/auth.store';
import { OrderTrackingDialog } from './orderTracking.dialog';
import { OrderTrackingsGrid } from '../../common/components/grid/orderTrackings-grid.component';

export type OrderTrackingsListProps = {
  orderId: number;
  onDelete?: () => void;
  onSelect?: (
    orderTracking: OrderTrackingDto,
    orderTrackingParams: GetOrderTrackingParams,
  ) => void;
};

export const OrderTrackingsList = ({ orderId }: OrderTrackingsListProps) => {
  const [
    orderTrackings,
    setOrderTrackings,
  ] = useState<OrderTrackingDtoPagedResult | null>(null);

  const getOrderTrackingsData = () => {
    getOrderTrackings({
      filter: `orderId:${orderId}`,
      sort: 'created',
    }).then((orderTrackingsData) => {
      setOrderTrackings(orderTrackingsData);
    });
  };
  useEffect(() => {
    getOrderTrackingsData();
  }, []);

  const goToDetails = (orderTracking) => {
    if (orderTracking?.orderTrackingId) {
      showDialog({
        dialog: OrderTrackingDialog,
        props: {
          title: 'Order Tracking',
          orderTrackingId: orderTracking.orderTrackingId,
          orderId: orderId,
        },
      }).then(
        () => {
          getOrderTrackingsData();
        },
        () => {},
      );
    }
  };

  const { orderTrackingColumns: columns } = useStore(orderTrackingStore);

  const onCreateNewOrderTracking = () => {
    showDialog({
      dialog: OrderTrackingDialog,
      props: {
        orderTrackingId: 0,
        orderId: orderId,
        title: 'Load Tracking',
      },
    }).then(
      () => {
        getOrderTrackingsData();
      },
      () => {},
    );
  };

  return (
    <>
      <div>
        <div className="row mb-3">
          <div className="col-2">
            <h3 className="font-weight-normal">Tracking</h3>
          </div>
          {userHas(CREATE_ORDERTRACKING_LINK_KEY) && (
            <div className={'offset-8 col-2'}>
              <Button
                size={'sm'}
                color="primary"
                className="btn-light btn-cancel w-100 h-100"
                name="create-orderTracking"
                onClick={onCreateNewOrderTracking}
              >
                Send Request
              </Button>
            </div>
          )}
        </div>
      </div>
      <OrderTrackingsGrid
        rowKeys={['orderTrackingId']}
        data={orderTrackings?.items}
        columns={columns}
        total={orderTrackings?.totalCount}
        onEdit={goToDetails}
        onSelect={goToDetails}
      />
    </>
  );
};
