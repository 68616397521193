import {
  CurrencyDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateCurrencyCommandValues,
  UpdateCurrencyCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createCurrencyRequest,
  getCurrenciesListRequest,
  getCurrencyRequest,
  deleteCurrencyRequest,
  updateCurrencyRequest,
  GetCurrencyParams,
} from './currencies.service';

type CurrenciesStoreState = {
  links: LinkDto[];
  currencyColumns: Column[];
  defaultCurrency: CurrencyDto;
};

export const updateCurrenciesColumns = createEvent<Column[]>();

export const getCurrencies = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCurrenciesListRequest(currentOrganization, params);
});

export const createCurrency = createEffect((currencyData: CurrencyDto) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  const createCurrencyCommand: CreateCurrencyCommandValues = {
    currencyCode: currencyData.currencyCode,
    currencyName: currencyData.currencyName,
    decimalPlaces: currencyData.decimalPlaces,
    exchangeRateLastUpdate: currencyData.exchangeRateLastUpdate,
    exchangeRateToPrimary: currencyData.exchangeRateToPrimary,
    gainLossAccountId: currencyData.gainLossAccountId,
    isInactive: currencyData.isInactive,
    symbol: currencyData.symbol,
  };

  return createCurrencyRequest(currentOrganization!, createCurrencyCommand);
});

export const getCurrency = createEffect((currencyParams: GetCurrencyParams) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getCurrencyRequest(
    currentOrganization as LinkResourceBaseDto,
    currencyParams,
  );
});

export const updateCurrency = createEffect((currency: CurrencyDto) => {
  const updateCurrencyCommand: UpdateCurrencyCommandValues = {
    currencyCode: currency.currencyCode,
    currencyName: currency.currencyName,
    decimalPlaces: currency.decimalPlaces,
    exchangeRateLastUpdate: currency.exchangeRateLastUpdate,
    exchangeRateToPrimary: currency.exchangeRateToPrimary,
    gainLossAccountId: currency.gainLossAccountId,
    isInactive: currency.isInactive,
    symbol: currency.symbol,
  };
  return updateCurrencyRequest(currency, updateCurrencyCommand);
});

export const deleteCurrency = createEffect((currency: CurrencyDto) => {
  return deleteCurrencyRequest(currency);
});

const defaultState: CurrenciesStoreState = {
  links: [],
  currencyColumns: [
    {
      name: 'currencyId',
      visible: true,
      title: 'Currency Id',
      sortName: 'currencyId',
    },
    {
      name: 'currencyName',
      visible: true,
      title: 'Currency Name',
      sortName: 'currencyName',
    },
    {
      name: 'currencyCode',
      visible: true,
      title: 'Currency Code',
      sortName: 'currencyCode',
    },
    { name: 'symbol', visible: true, title: 'Symbol', sortName: 'symbol' },
    {
      name: 'decimalPlaces',
      visible: true,
      title: 'Decimal Places',
      sortName: 'decimalPlaces',
    },
    {
      name: 'gainLossAccountDescription',
      visible: true,
      title: 'Gain Loss Account Description',
      sortName: 'GainLossAccount.Description',
    },
    {
      name: 'exchangeRateToPrimary',
      visible: true,
      title: 'Exchange Rate',
      sortName: 'exchangeRateToPrimary',
    },
    {
      name: 'isInactive',
      visible: true,
      title: 'Is Inactive',
      sortName: 'isInactive',
    },
    {
      name: 'createdByUserName',
      visible: false,
      title: 'Created By',
      sortName: 'CreatedUser.UserName',
    },
    {
      name: 'lastModifiedByUserName',
      visible: false,
      title: 'Last Modified By',
      sortName: 'UpdatedUser.UserName',
    },
  ],
  defaultCurrency: null,
};

export const currencyStore: Store<CurrenciesStoreState> = createStore(
  defaultState,
)
  .on(updateCurrenciesColumns, (state, payload) => {
    return { ...state, currencyColumns: payload };
  })
  .on(getCurrencies.done, (state, payload) => {
    const currencies = payload.result;
    const usdCurrency: CurrencyDto = currencies.items.find(
      (currency) => currency.currencyCode === 'USD' && !currency.isInactive,
    );
    state.defaultCurrency = usdCurrency ?? null;
  });
