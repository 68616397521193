import {
  ContainerTypeDto,
  LinkDto,
  LinkResourceBaseDto,
  ContainerTypeDtoPagedResult,
  CreateContainerTypeCommandValues,
  UpdateContainerTypeCommandValues,
  Column,
} from '../../models/data.models';
import { createStore, Store, createEffect, createEvent } from 'effector';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import {
  createContainerTypeRequest,
  getContainerTypesListRequest,
  getContainerTypeRequest,
  deleteContainerTypeRequest,
  updateContainerTypeRequest,
  GetContainerTypeParams,
} from './containerTypes.service';

type ContainerTypesStoreState = {
  links: LinkDto[];
  containerTypeColumns: Column[];
};

export const updateContainerTypesColumns = createEvent<Column[]>();

export const getContainerTypes = createEffect((params: ListParams = {}) => {
  const { currentOrganization } = organizationsStore.getState();

  if (currentOrganization === null)
    throw new Error('Organization was not set in the current context.');

  return getContainerTypesListRequest(currentOrganization, params);
});

export const createContainerType = createEffect(
  (containerTypeData: ContainerTypeDto) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    const createContainerTypeCommand: CreateContainerTypeCommandValues = {
      ...containerTypeData,
    };

    return createContainerTypeRequest(
      currentOrganization!,
      createContainerTypeCommand,
    );
  },
);

export const getContainerType = createEffect(
  (containerTypeParams: GetContainerTypeParams) => {
    const { currentOrganization } = organizationsStore.getState();

    if (currentOrganization === null)
      throw new Error('Organization was not set in the current context.');

    return getContainerTypeRequest(
      currentOrganization as LinkResourceBaseDto,
      containerTypeParams,
    );
  },
);

export const updateContainerType = createEffect(
  (containerType: ContainerTypeDto) => {
    const updateContainerTypeCommand: UpdateContainerTypeCommandValues = {
      ...containerType,
    };
    return updateContainerTypeRequest(
      containerType,
      updateContainerTypeCommand,
    );
  },
);

export const deleteContainerType = createEffect(
  (containerType: ContainerTypeDto) => {
    return deleteContainerTypeRequest(containerType);
  },
);

const defaultState: ContainerTypesStoreState = {
  links: [],
  containerTypeColumns: [
    {
      name: 'containerTypeCode',
      visible: true,
      title: 'Container Type Code',
      sortName: 'containerTypeCode',
    },
    {
      name: 'description',
      visible: true,
      title: 'Description',
      sortName: 'description',
    },
    {
      name: 'organizationId',
      visible: false,
      title: 'Organization Id',
      sortName: 'organizationId',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      sortName: 'created',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      sortName: 'lastModified',
    },
  ],
};

export const containerTypeStore: Store<ContainerTypesStoreState> = createStore(
  defaultState,
).on(updateContainerTypesColumns, (state, payload) => {
  return { ...state, containerTypeColumns: payload };
});
