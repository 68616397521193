import React, { useEffect, useState } from 'react';
import { Button } from '../../common/components/button/button.component';
import {
  AccountingTransactionDto,
  AccountingTransactionDtoPagedResult,
  AccountingTransactionType,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  CREATE_ACCOUNTINGTRANSACTION_LINK_KEY,
  DELETE_ACCOUNTINGTRANSACTION_LINK_KEY,
  GetAccountingTransactionParams,
} from '../../accountingTransactions/accountingTransactions.service';
import {
  accountingTransactionFilterFields,
  accountingTransactionStore,
  deleteAccountingTransaction,
  getAccountingTransactions,
  updateAccountingTransactionsColumns,
} from '../../accountingTransactions/accountingTransactions.store';
import { INVOICES_CREATE_PATH } from '../invoices.route';
import { InvoiceDialog } from './invoice.dialog';
import { getFormattedDate } from '../../../utils/formatting.utils';
import { userHas } from '../../auth/auth.store';
import { AccountingTransactionGrid } from '../../common/components/grid/accountingTransaction-grid.component';

export type InvoicesListProps = {
  goToDetails?: (
    accountingTransactionParams: GetAccountingTransactionParams,
  ) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSelect?: (
    accountingTransaction: AccountingTransactionDto,
    accountingTransactionParams: GetAccountingTransactionParams,
  ) => void;
  onSearch?: (query: string) => void;
};

export const InvoicesList = ({
  goToDetails = () => {},
  onFilter = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  filter = `accountingTransactionType: ${AccountingTransactionType.Invoice}`,
  onSelect,
  onSearch,
}: InvoicesListProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const [
    accountingTransactions,
    setAccountingTransactions,
  ] = useState<AccountingTransactionDtoPagedResult | null>(null);
  const history = useHistory();
  useEffect(() => {
    getAccountingTransactionsData();
  }, [offset, limit, sort, filter, search]);
  const getAccountingTransactionsData = () => {
    getAccountingTransactions({ offset, limit, sort, filter, search }).then(
      (accountingTransactionsData) => {
        accountingTransactionsData.items.forEach(
          (accountingTransactionsDto) => {
            accountingTransactionsDto.transactionDate = getFormattedDate(
              accountingTransactionsDto.transactionDate,
              false,
            );
          },
        );
        setAccountingTransactions(accountingTransactionsData);
      },
    );
  };
  const { accountingTransactionColumns: columns } = useStore(
    accountingTransactionStore,
  );

  const onDeleteAccountingTransaction = (
    accountingTransaction: AccountingTransactionDto,
  ) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${accountingTransaction.transactionNumber} Invoice`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteAccountingTransaction(accountingTransaction).then(() => {
          onDelete();
          getAccountingTransactionsData();
        });
      }
    });
  };

  const onEditAccountingTransaction = (
    accountingTransaction: AccountingTransactionDto,
  ) => {
    goToDetails({
      accountingTransactionId: accountingTransaction.accountingTransactionId,
    });
  };

  const onCreateNewAccountingTransaction = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + INVOICES_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onInvoiceSelect = (invoice, invoiceId) => {
    if (onSelect) onSelect(invoice, invoiceId);
    showDialog({
      dialog: InvoiceDialog,
      props: {
        accountingTransactionId: invoice.accountingTransactionId,
        title: 'Update Invoice',
        className: '',
        organizationId,
      },
    }).then((invoice) => {
      if (invoice !== null) {
        getAccountingTransactionsData();
      }
    });
  };

  return (
    <>
      <AccountingTransactionGrid
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={true}
        showAllFilters={false}
        rowIncludeFilterKeys={accountingTransactionFilterFields}
        rowKeys={['accountingTransactionId']}
        data={accountingTransactions?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={accountingTransactions?.totalCount}
        sort={sort}
        onDelete={
          userHas(
            DELETE_ACCOUNTINGTRANSACTION_LINK_KEY,
            accountingTransactions?.items[0]?.links,
          )
            ? onDeleteAccountingTransaction
            : null
        }
        onSort={onSort}
        filter={filter}
        onFilter={onFilter}
        onEdit={onEditAccountingTransaction}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateAccountingTransactionsColumns}
        onSelect={onInvoiceSelect}
      />
      {!(
        (accountingTransactions &&
          accountingTransactions.items &&
          accountingTransactions.items.length &&
          accountingTransactions.items.length > 0) ||
        (filter &&
          filter !==
            `accountingTransactionType: ${AccountingTransactionType.Invoice}`) ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">You Don't Have Any Invoice Yet</h3>
          {userHas(CREATE_ACCOUNTINGTRANSACTION_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={onCreateNewAccountingTransaction}
            >
              Create Invoice
            </Button>
          )}
        </div>
      )}
    </>
  );
};
