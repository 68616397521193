import React, { CSSProperties } from 'react';
import { BsFilter, BsThreeDotsVertical } from 'react-icons/bs';
import {
  getReadableQueryString,
  getSearchQuery,
} from '../../../utils/query.utils';
import { Pagination } from '../../common/components/pagination/pagination.component';
import { Filters } from '../../common/components/filters/filters.component';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AttachmentDto,
  AttachmentType,
  Column,
  ContactType,
} from '../../../models/data.models';

export type AttachmentsGridProps = {
  rowKeys?: string[] | null;
  rowIncludeFilterKeys?: string[] | null;
  columns?: Column[];
  sort?: string | null;
  offset?: number;
  limit?: number;
  filter?: string;
  search?: string;
  total?: number;
  data?: AttachmentDto[];
  showPagination?: boolean;
  className?: string;
  style?: CSSProperties;
  onColumnsChanged?: (columns: Column[]) => void;
  onSort?: (field: string) => void;
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onCopy?: (row: any) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  onPageChanged?: (page: number) => void;
  onSelect?: (item: any, keys: any) => void;
  showEmptyTable?: boolean;
  showAllStore?: boolean;
  showAllFilters?: boolean;
  hideColumnsSelect?: boolean;
  isDropDownList?: boolean;
  actionBar?: JSX.Element | undefined;
};

export const AttachmentsGrid = ({
  data = [],
  columns = [],
  sort = '',
  className = '',
  limit = 20,
  offset = 0,
  total = 0,
  filter = '',
  search = '',
  style = {},
  showPagination = true,
  onColumnsChanged = () => {},
  onSort = () => {},
  onCopy = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onFilter,
  onSearch,
  onPageChanged = () => {},
  rowKeys = ['id'],
  rowIncludeFilterKeys = ['id'],
  onSelect,
  showEmptyTable = true,
  showAllStore = false,
  showAllFilters = true,
  isDropDownList = false,
  hideColumnsSelect,
  actionBar,
}: AttachmentsGridProps) => {
  const colByName: { [key: string]: any } = {};

  const updateFilter = (filters: any) => {
    return onFilter(getSearchQuery(filters));
  };
  const getFilteredRowsFilter = (): Column[] => {
    return columns.filter((item) => {
      if (showAllFilters) {
        return true;
      }
      return rowIncludeFilterKeys?.includes(item.name);
    });
  };
  const updateSearch = (event) => {
    return onSearch(event.target.value);
  };

  columns?.forEach((col) => {
    colByName[col.name] = col;
  });
  return (
    <div className={`grid ${className}`} style={style}>
      <div
        className={
          isDropDownList === true
            ? 'grid-toolbar d-flex flex-wrap px-3 my-2 justify-content-center'
            : 'grid-toolbar d-flex pl-3 my-2'
        }
      >
        {onSearch && data.length > 0 ? (
          <input
            type="search"
            className={
              (isDropDownList === true ? 'w-100 col-12 ' : ' ') +
              'form-control my-2'
            }
            placeholder="Search"
            onChange={updateSearch}
            value={search}
          />
        ) : null}
        {onFilter && data.length > 0 ? (
          <div
            className={
              isDropDownList === true
                ? 'dropdown dropdown-columns my-3 px-auto mx-auto w-auto col-auto'
                : 'dropdown dropdown-columns my-3 px-3 w-100'
            }
          >
            <span
              className="dropdown-toggle"
              id="dropdownFilterButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <BsFilter />
              &nbsp;
              <small className="align-middle pl-1 text-uppercase">
                Filters
              </small>
              &nbsp;
              <small className="align-middle text-primary">
                {getReadableQueryString(filter, getFilteredRowsFilter())}
              </small>
            </span>
            {showAllFilters === true || rowIncludeFilterKeys?.length > 0 ? (
              <div
                className="dropdown-menu px-5"
                aria-labelledby="dropdownFilterButton"
              >
                <div>
                  <Filters
                    query={filter}
                    columns={getFilteredRowsFilter()}
                    onFilter={updateFilter}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {actionBar && <div className={'mr-3 py-2'}>{actionBar}</div>}
      </div>
      {total > 0 || showEmptyTable === true ? (
        <div className="bg-white mx-3">
          <div className="row">
            {data.map((attachment) => (
              <div className={'col-2'}>
                <div className="position-relative border border-secondary rounded">
                  <div className="dropdown position-absolute bottom-0 right-0">
                    <BsThreeDotsVertical
                      className="dropdown-toggle dropdown-dots-vertical pointer"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    />
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        role="button"
                        className="dropdown-item"
                        onClick={() => {
                          onDelete(attachment);
                        }}
                      >
                        Delete
                      </a>
                      <a
                        role="button"
                        className="dropdown-item"
                        onClick={() => {
                          onEdit(attachment);
                        }}
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                  <div
                    className={'cursor-pointer'}
                    onClick={(e) =>
                      onSelect(
                        attachment,
                        rowKeys?.reduce((keyObj, field) => {
                          return attachment[field];
                        }),
                      )
                    }
                  >
                    {attachment.attachmentType === AttachmentType.Picture &&
                    attachment.presignedThumbnailUri ? (
                      <img
                        src={attachment.presignedThumbnailUri}
                        alt=""
                        className={'w-100 rounded-top'}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faFile}
                        size={'10x'}
                        className={'w-100 mb-2'}
                      />
                    )}
                    <div className={'text-center font-weight-bold p-3'}>
                      {attachment.fileName}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <p className="text-center mt-4">
            <h3 className="text-muted mb-4">Nothing Found</h3>
          </p>
          <p className="text-center mt-4">
            <h3 className="text-muted mb-4">Drag'n'drop some files here</h3>
          </p>
        </>
      )}
      {total > limit && showPagination === true ? (
        <div className="mt-3 d-flex justify-content-center">
          <Pagination
            goToPage={onPageChanged}
            offset={offset}
            limit={limit}
            total={total}
          />
        </div>
      ) : null}
    </div>
  );
};
