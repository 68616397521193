import React, { useEffect } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { GetCountriesQuery } from '../../../models/data.models';
import {
  CONTAINER_TYPE_EDIT_PATH,
  CONTAINER_TYPES_LIST_PATH,
  CONTAINER_TYPE_CREATE_PATH,
} from '../containerTypes.route';
import { ContainerTypesList } from '../components/containerTypes-list.component';
import {
  CREATE_CONTAINERTYPE_LINK_KEY,
  GetContainerTypeParams,
} from '../containerTypes.service';
import { Helmet } from 'react-helmet';
import { userHas } from '../../auth/auth.store';

export type ContainerTypesListScreenProps = { pageTitle: string };

export const ContainerTypesListScreen = ({
  pageTitle,
}: ContainerTypesListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = 'containerTypeCode',
    filter = '',
    search = '',
  } = useQuery() as GetCountriesQuery;
  const history = useHistory();

  const createPath = (): string => {
    return generatePath(INTERNAL_PATH + CONTAINER_TYPE_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = (containerTypeParams: GetContainerTypeParams) => {
    const path = generatePath(INTERNAL_PATH + CONTAINER_TYPE_EDIT_PATH, {
      organizationId,
      containerTypeCode: containerTypeParams.containerTypeCode,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + CONTAINER_TYPES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + CONTAINER_TYPES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CONTAINER_TYPES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + CONTAINER_TYPES_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };
  return (
    <InternalLayout
      title={'Container Types'}
      createPath={userHas(CREATE_CONTAINERTYPE_LINK_KEY) ? createPath() : null}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ContainerTypesList
        search={search}
        onSearch={onSearch}
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        filter={filter}
        onFilter={onFilter}
      />
    </InternalLayout>
  );
};
