import {
  ContactAddressDto,
  LinkDto,
  LinkResourceBaseDto,
  CreateContactAddressCommandValues,
  UpdateContactAddressCommandValues,
  ContactAddressDtoPagedResult,
} from '../../models/data.models';
import { execLink } from '../api/api.service';
import { ListParams } from '../common/models/ListParams';
import { organizationsStore } from '../organization/organization.store';
import { addMessage } from '../common/messages.store';
import { v4 } from 'uuid';

export const GET_CONTACTADDRESSES_LINK_KEY = 'get-contactaddresses';
export const CREATE_CONTACTADDRESS_LINK_KEY = 'create-contactaddress';
export const GET_CONTACTADDRESS_LINK_KEY = 'get-contactaddress';
export const UPDATE_CONTACTADDRESS_LINK_KEY = 'update-contactaddress';
export const DELETE_CONTACTADDRESS_LINK_KEY = 'delete-contactaddress';

export const CONTACT_ADDRESS_ENTITY_NAME = 'Contact Address';

export interface GetContactAddressParams {
  contactAddressId: number;
}

export const getContactAddressRequest = async (
  resource: LinkResourceBaseDto | null,
  { contactAddressId }: GetContactAddressParams,
): Promise<ContactAddressDto> => {
  if (resource && contactAddressId) {
    const getContactAddressLink = resource.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACTADDRESS_LINK_KEY,
    );
    if (getContactAddressLink) {
      const result = await execLink(getContactAddressLink, {
        contactAddressId,
      });
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTACTADDRESS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const getContactAddressesListRequest = async (
  resource?: LinkResourceBaseDto | null,
  params: ListParams = {},
): Promise<ContactAddressDtoPagedResult> => {
  if (resource) {
    const getContactAddressesListLink = resource?.links?.find(
      (x: LinkDto) => x.rel === GET_CONTACTADDRESSES_LINK_KEY,
    );
    if (getContactAddressesListLink) {
      const result = await execLink(getContactAddressesListLink, params);
      return result.data;
    }
  }
  addMessage({
    message: `Action ${GET_CONTACTADDRESSES_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const createContactAddressRequest = async (
  resource: LinkResourceBaseDto,
  contactAddress: CreateContactAddressCommandValues,
) => {
  const createContactAddressLink = resource?.links?.find(
    (x: LinkDto) => x.rel === CREATE_CONTACTADDRESS_LINK_KEY,
  );
  if (createContactAddressLink) {
    const result = await execLink(createContactAddressLink, contactAddress);
    return result.data;
  }
  addMessage({
    message: `Action ${CREATE_CONTACTADDRESS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const updateContactAddressRequest = async (
  resource: LinkResourceBaseDto,
  contactAddress: UpdateContactAddressCommandValues,
) => {
  const updateContactAddressLink = resource.links?.find(
    (x: LinkDto) => x.rel === UPDATE_CONTACTADDRESS_LINK_KEY,
  );
  if (updateContactAddressLink) {
    const result = await execLink(updateContactAddressLink, contactAddress);
    return result.data;
  }
  addMessage({
    message: `Action ${UPDATE_CONTACTADDRESS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};

export const deleteContactAddressRequest = async (
  contactAddress: ContactAddressDto,
) => {
  const deleteContactAddressLink = contactAddress.links?.find(
    (x: LinkDto) => x.rel === DELETE_CONTACTADDRESS_LINK_KEY,
  );
  if (deleteContactAddressLink) {
    const result = await execLink(deleteContactAddressLink, contactAddress);
    return result.data;
  }
  addMessage({
    message: `Action ${DELETE_CONTACTADDRESS_LINK_KEY} is not allowed or missing link action`,
    type: 'danger',
    id: v4(),
  });
  return Promise.reject();
};
