import React from 'react';
import { Form, FormProps } from '../../common/components/form/form.component';
import { PlaceAutocompleteInput } from '../../common/components/input/input-placeAutocomplete.component';
import {
  Input,
  InputPublicProps,
} from '../../common/components/input/input.component';

export const OrganizationForm = ({
  id,
  children,
  onSubmit,
  initialValues,
  validationSchema,
}: FormProps) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

OrganizationForm.AddressLine = ({
  name = 'addressLine',
  label = 'Address Line',
  placeholder = 'Enter Address',
  defaultValue,
  onSelect,
  customStyles,
}: InputPublicProps) => {
  return (
    <PlaceAutocompleteInput
      name={name}
      placeholder={placeholder}
      header={label}
      defaultValue={defaultValue}
      onSelect={onSelect}
      customStyles={customStyles}
    />
  );
};

OrganizationForm.AddressLine2 = ({
  className,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'addressLine2'}
      label={'Address 2'}
      placeholder={'Enter address line 2'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrganizationForm.City = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'city'}
      label={'City'}
      placeholder={'Enter City'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrganizationForm.CompanyName = ({
  className,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'companyName'}
      label={'Company name'}
      placeholder={'Enter company name'}
      required={true}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrganizationForm.FaxNumber = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'faxNumber'}
      label={'Fax Number'}
      placeholder={'Enter Fax Number'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrganizationForm.PhoneNumber = ({
  className,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'phoneNumber'}
      label={'Phone Number'}
      placeholder={'Enter Phone Number'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrganizationForm.CountryCode = ({
  className,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'countryCode'}
      label={'Country Code'}
      placeholder={'Enter country code'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrganizationForm.PostalCode = ({
  className,
  style,
  size,
}: InputPublicProps) => {
  return (
    <Input
      name={'postalCode'}
      label={'Postal Code'}
      placeholder={'Enter postal code'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};

OrganizationForm.StateCode = ({ className, style, size }: InputPublicProps) => {
  return (
    <Input
      name={'stateCode'}
      label={'State Code'}
      placeholder={'Enter state code'}
      required={false}
      type={'text'}
      className={className}
      style={style}
      size={size}
    />
  );
};
