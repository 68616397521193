import { Column, LinkDto } from '../../models/data.models';
import { createEvent, createStore, Store } from 'effector';

type LeadsStoreState = {
  links: LinkDto[];
  leadColumns: Column[];
};

const defaultState: LeadsStoreState = {
  links: [],
  leadColumns: [
    {
      name: 'contactId',
      visible: true,
      title: 'Contact Id',
      showFilter: false,
      sortName: 'contactId',
    },
    {
      name: 'name',
      visible: true,
      title: 'Name',
      showFilter: false,
      sortName: 'name',
    },
    {
      name: 'contactFirstName',
      visible: true,
      title: 'Contact First Name',
      showFilter: false,
      sortName: 'contactFirstName',
    },
    {
      name: 'contactLastName',
      visible: true,
      title: 'Contact Last Name',
      showFilter: false,
      sortName: 'contactLastName',
    },
    {
      name: 'contactType',
      visible: false,
      title: 'Contact Type',
      showFilter: false,
    },
    {
      name: 'mobilePhoneNumber',
      visible: true,
      title: 'Mobile Phone Number',
      showFilter: false,
      sortName: 'mobilePhoneNumber',
    },
    {
      name: 'phoneNumber',
      visible: true,
      title: 'Phone Number',
      showFilter: false,
      sortName: 'phoneNumber',
    },
    {
      name: 'faxNumber',
      visible: false,
      title: 'Fax Number',
      showFilter: false,
      sortName: 'faxNumber',
    },
    {
      name: 'emailAddress',
      visible: true,
      title: 'Email Address',
      showFilter: false,
      sortName: 'emailAddress',
    },
    {
      name: 'website',
      visible: true,
      title: 'Website',
      showFilter: false,
      sortName: 'website',
    },
    {
      name: 'accountNumber',
      visible: true,
      title: 'Account Number',
      showFilter: true,
      sortName: 'accountNumber',
    },
    {
      name: 'idNumber',
      visible: false,
      title: 'Id Number',
      showFilter: true,
      sortName: 'idNumber',
    },
    {
      name: 'idNumberType',
      visible: false,
      title: 'Id Number Type',
      showFilter: true,
      type: 'idNumberType',
      sortName: 'idNumberType',
    },
    {
      name: 'divisionName',
      visible: true,
      title: 'Division Name',
      showFilter: true,
      sortName: 'Division.DivisionName',
    },
    {
      name: 'created',
      visible: false,
      title: 'Created',
      showFilter: false,
      sortName: 'created',
    },
    {
      name: 'createdByUserName',
      visible: false,
      title: 'Created By User',
      showFilter: true,
      sortName: 'CreatedUser.UserName',
    },
    {
      name: 'lastModified',
      visible: false,
      title: 'Last Modified',
      showFilter: false,
      sortName: 'lastModified',
    },
    {
      name: 'lastModifiedByUserName',
      visible: false,
      title: 'Last Modified By User',
      showFilter: false,
      sortName: 'UpdatedUser.UserName',
    },
  ],
};

export const updateLeadsColumns = createEvent<Column[]>();

export const leadStore: Store<LeadsStoreState> = createStore(defaultState).on(
  updateLeadsColumns,
  (state, payload) => {
    return { ...state, leadColumns: payload };
  },
);
