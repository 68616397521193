import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  ModeOfTransportationDto,
  ModeOfTransportationDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  modeOfTransportationStore,
  getModeOfTransportations,
  deleteModeOfTransportation,
  updateModeOfTransportationsColumns,
} from '../modeOfTransportations.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_MODEOFTRANSPORTATION_LINK_KEY,
  DELETE_MODEOFTRANSPORTATION_LINK_KEY,
  GetModeOfTransportationParams,
} from '../modeOfTransportations.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { MODE_OF_TRANSPORTATION_CREATE_PATH } from '../modeOfTransportation.route';
import { ModeOfTransportationDialog } from './modeOfTransportation.dialog';
import { userHas } from '../../auth/auth.store';

export type ModeOfTransportationsListProps = {
  goToDetails?: (
    modeOfTransportationParams: GetModeOfTransportationParams,
  ) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    modeOfTransportation: ModeOfTransportationDto,
    modeOfTransportationParams: GetModeOfTransportationParams,
  ) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
};

export const ModeOfTransportationsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch,
}: ModeOfTransportationsListProps) => {
  const [
    modeOfTransportations,
    setModeOfTransportations,
  ] = useState<ModeOfTransportationDtoPagedResult | null>(null);
  const history = useHistory();
  useEffect(() => {
    getModeOfTransportationsData();
  }, [offset, limit, sort, filter, search]);
  const getModeOfTransportationsData = () => {
    getModeOfTransportations({ offset, limit, sort, filter, search }).then(
      (modeOfTransportationsData) => {
        setModeOfTransportations(modeOfTransportationsData);
      },
    );
  };
  const { modeOfTransportationColumns: columns } = useStore(
    modeOfTransportationStore,
  );

  const onDeleteModeOfTransportation = (
    modeOfTransportation: ModeOfTransportationDto,
  ) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete Mode of Transportation`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then((result) => {
      if (result) {
        deleteModeOfTransportation(modeOfTransportation).then(() => {
          onDelete();
          getModeOfTransportationsData();
        });
      }
    });
  };

  const onEditModeOfTransportation = (
    modeOfTransportation: ModeOfTransportationDto,
  ) => {
    goToDetails({
      modeOfTransportationId: modeOfTransportation.modeOfTransportationId,
    });
  };

  const onCreateNewModeOfTransportation = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(
      INTERNAL_PATH + MODE_OF_TRANSPORTATION_CREATE_PATH,
      {
        organizationId: currentOrganization.organizationId,
      },
    );
    history.push(createPath);
  };
  const onModeOfTransportationSelect = (
    modeOfTransportation,
    modeOfTransportationId,
  ) => {
    if (onSelect) onSelect(modeOfTransportation, modeOfTransportationId);
    showDialog({
      dialog: ModeOfTransportationDialog,
      props: {
        modeOfTransportationId: modeOfTransportation.modeOfTransportationId,
        title: 'Update Mode of Transportation',
      },
    }).then((modeOfTransportation) => {
      if (modeOfTransportation !== null) {
        getModeOfTransportationsData();
      }
    });
  };

  return (
    <>
      <Grid
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={['modeOfTransportationId']}
        data={modeOfTransportations?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={modeOfTransportations?.totalCount}
        sort={sort}
        onDelete={
          userHas(
            DELETE_MODEOFTRANSPORTATION_LINK_KEY,
            modeOfTransportations?.items[0]?.links,
          )
            ? onDeleteModeOfTransportation
            : null
        }
        onSort={onSort}
        onEdit={onEditModeOfTransportation}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateModeOfTransportationsColumns}
        onSelect={onModeOfTransportationSelect}
        filter={filter}
        onFilter={onFilter}
      />
      {!(
        (modeOfTransportations &&
          modeOfTransportations.items &&
          modeOfTransportations.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Mode Of Transportation Yet
          </h3>
          {userHas(CREATE_MODEOFTRANSPORTATION_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={onCreateNewModeOfTransportation}
            >
              Create Mode Of Transportation
            </Button>
          )}
        </div>
      )}
    </>
  );
};
