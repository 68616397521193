import React from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { INTERNAL_PATH, useQuery } from '../../../app.router';
import { DRIVER_PAY_MANAGER_LIST_PATH } from '../driverPayManager.route';
import { ORDER_CREATE_PATH, ORDER_EDIT_PATH } from '../../orders/order.route';
import { GetOrdersQuery } from '../../../models/data.models';
import { DriverPayManagerList } from '../components/driverPayManager-list.component';
import { GetOrderParams } from '../../orders/orders.service';
import { Helmet } from 'react-helmet';

export type DriverPayManagerListScreenProps = {
  pageTitle: string;
};

export const DriverPayManagerListScreen = ({
  pageTitle,
}: DriverPayManagerListScreenProps) => {
  // @ts-ignore
  const { organizationId } = useParams();
  const {
    offset = 0,
    limit = 20,
    sort = '-Order.OrderNumber~ToInt32',
    filter = ``,
    search = '',
  } = useQuery() as GetOrdersQuery;
  const history = useHistory();

  const createPath = () => {
    return generatePath(INTERNAL_PATH + ORDER_CREATE_PATH, {
      organizationId,
    });
  };

  const goToDetails = (orderParams: GetOrderParams) => {
    const path = generatePath(INTERNAL_PATH + ORDER_EDIT_PATH, {
      organizationId,
      orderId: orderParams.orderId,
    });
    history.push(path);
  };

  const onDelete = () => {
    history.go(history.length - 1);
  };

  const onPageChanged = (page: number) => {
    const path = generatePath(INTERNAL_PATH + DRIVER_PAY_MANAGER_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${
        page * limit
      }&limit=${limit}&sort=${sort}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSort = (field: string) => {
    const path = generatePath(INTERNAL_PATH + DRIVER_PAY_MANAGER_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${offset}&limit=${limit}&sort=${field}&filter=${filter}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onFilter = (query: string) => {
    const path = generatePath(INTERNAL_PATH + DRIVER_PAY_MANAGER_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${query}&search=${encodeURIComponent(
        search,
      )}`,
    );
  };

  const onSearch = (query: string) => {
    const path = generatePath(INTERNAL_PATH + DRIVER_PAY_MANAGER_LIST_PATH, {
      organizationId,
    });
    history.push(
      `${path}?offset=${0}&limit=${limit}&sort=${sort}&filter=${filter}&search=${query}`,
    );
  };

  return (
    <InternalLayout title={'Driver Pay Manager'}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <DriverPayManagerList
        onSearch={onSearch}
        search={search}
        offset={offset}
        limit={limit}
        sort={sort}
        goToDetails={goToDetails}
        onDelete={onDelete}
        onPageChanged={onPageChanged}
        onSort={onSort}
        onFilter={onFilter}
        filter={filter}
      />
    </InternalLayout>
  );
};
