import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { DriverPayManagerListScreen } from './screens/driverPayManager-list.screen';

export const DRIVER_PAY_MANAGER_LIST_PATH = '/driver-pay-manager';
export const DRIVER_PAY_MANAGER_PATH =
  '/org/:organizationId/driver-pay-manager';

export const DriverPayManagerRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <PrivateRoute exact path={`${match.path}${DRIVER_PAY_MANAGER_LIST_PATH}`}>
        <DriverPayManagerListScreen pageTitle="Driver Pay Manager" />
      </PrivateRoute>
    </Route>
  );
};
