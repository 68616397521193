import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../common/private.route';
import { StatesListScreen } from './screens/states-list.screen';
import { StateScreen } from './screens/state.screen';
import React from 'react';

export const STATES_LIST_PATH = '/states';
export const STATE_EDIT_PATH = '/states/:stateCode,:countryCode';
export const STATE_CREATE_PATH = '/states/0';
export const STATES_PATH = '/org/:organizationId/states';

export const StatesRoute = (props: RouteProps) => {
  const match = useRouteMatch();
  return (
    <Route {...props}>
      <Switch>
        <PrivateRoute exact path={`${match.path}${STATES_LIST_PATH}`}>
          <StatesListScreen pageTitle="States" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${STATE_CREATE_PATH}`}>
          <StateScreen pageTitle="Add New State" />
        </PrivateRoute>
        <PrivateRoute exact path={`${match.path}${STATE_EDIT_PATH}`}>
          <StateScreen pageTitle="Update State" />
        </PrivateRoute>
      </Switch>
    </Route>
  );
};
