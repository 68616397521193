import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  PaymentTermDto,
  PaymentTermDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  paymentTermStore,
  getPaymentTerms,
  deletePaymentTerm,
  updatePaymentTermsColumns,
  downloadQuickBooksPaymentTerms,
} from '../paymentTerms.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_PAYMENTTERM_LINK_KEY,
  DELETE_PAYMENTTERM_LINK_KEY,
  GetPaymentTermParams,
} from '../paymentTerms.service';
import { organizationsStore } from '../../organization/organization.store';
import { generatePath, useHistory } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import { PAYMENTTERMS_CREATE_PATH } from '../paymentTerms.route';
import { PaymentTermDialog } from './paymentTerm.dialog';
import { userHas } from '../../auth/auth.store';
import { ActionBarComponent } from '../../common/components/action-bar/action-bar.component';

export type PaymentTermsListProps = {
  goToDetails?: (paymentTermParams: GetPaymentTermParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    paymentTerm: PaymentTermDto,
    paymentTermParams: GetPaymentTermParams,
  ) => void;
  filter?: string;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const PaymentTermsList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: PaymentTermsListProps) => {
  const [
    paymentTerms,
    setPaymentTerms,
  ] = useState<PaymentTermDtoPagedResult | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getPaymentTermsData();
  }, [offset, limit, sort, filter, search]);
  const getPaymentTermsData = () => {
    getPaymentTerms({ offset, limit, sort, filter, search }).then(
      (paymentTermsData) => {
        setPaymentTerms(paymentTermsData);
      },
      () => {},
    );
  };
  const { paymentTermColumns: columns } = useStore(paymentTermStore);

  const onDeletePaymentTerm = (paymentTerm: PaymentTermDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete Payment Term`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deletePaymentTerm(paymentTerm).then(
            () => {
              onDelete();
              getPaymentTermsData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditPaymentTerm = (paymentTerm: PaymentTermDto) => {
    goToDetails({ paymentTermId: paymentTerm.paymentTermId });
  };
  const history = useHistory();
  const onCreateNewPaymentTerm = () => {
    const { currentOrganization } = organizationsStore.getState();
    const createPath = generatePath(INTERNAL_PATH + PAYMENTTERMS_CREATE_PATH, {
      organizationId: currentOrganization.organizationId,
    });
    history.push(createPath);
  };
  const onPaymentTermSelect = (paymentTerm, paymentTermId) => {
    if (onSelect) onSelect(paymentTerm, paymentTermId);
    showDialog({
      dialog: PaymentTermDialog,
      props: {
        paymentTermId: paymentTerm.paymentTermId,
        title: 'Update Payment Term',
      },
    }).then(
      (paymentTerm) => {
        if (paymentTerm !== null) {
          getPaymentTermsData();
        }
      },
      () => {},
    );
  };

  const downloadQuickBooksTerms = async (): Promise<void> => {
    setIsLoading(true);
    downloadQuickBooksPaymentTerms()
      .then(getPaymentTermsData, () => {})
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return (
      <div className="m-5 text-center">
        <h3 className="text-muted mb-4">Loading...</h3>
      </div>
    );
  }

  return (
    <>
      <Grid
        isDropDownList={isDropDownList}
        search={search}
        showEmptyTable={true}
        onSearch={onSearch}
        showAllStore={true}
        rowKeys={['paymentTermId']}
        data={paymentTerms?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={paymentTerms?.totalCount}
        sort={sort}
        onDelete={
          userHas(DELETE_PAYMENTTERM_LINK_KEY, paymentTerms?.items[0]?.links)
            ? onDeletePaymentTerm
            : null
        }
        onSort={onSort}
        onEdit={onEditPaymentTerm}
        onPageChanged={onPageChanged}
        onColumnsChanged={updatePaymentTermsColumns}
        onSelect={onPaymentTermSelect}
        className={'paymentTerms-list'}
        filter={filter}
        onFilter={onFilter}
        actionBar={
          <ActionBarComponent
            buttonText={<>&middot;&middot;&middot;</>}
            style={{ minWidth: 'fit-content' }}
          >
            <button type={'button'} onClick={downloadQuickBooksTerms}>
              Download QuickBooks Terms
            </button>
          </ActionBarComponent>
        }
      />
      {!(
        (paymentTerms && paymentTerms.items && paymentTerms.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Payment Term Yet
          </h3>
          {userHas(CREATE_PAYMENTTERM_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={onCreateNewPaymentTerm}
            >
              Create Payment Term
            </Button>
          )}
        </div>
      )}
    </>
  );
};
