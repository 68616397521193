import React from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';

import { NotFoundSecreen } from './screens/not-found.secreen';

export const NOT_FOUND_PATH = '/404';

export const NotFoundRoute = (props: RouteProps) => {
  return (
    <Route {...props}>
      <NotFoundSecreen pageTitle="Not Found" />
    </Route>
  );
};
