import React, { useEffect, useState } from 'react';
import { Grid } from '../../common/components/grid/grid.component';
import { Button } from '../../common/components/button/button.component';
import {
  ContainerTypeDto,
  ContainerTypeDtoPagedResult,
} from '../../../models/data.models';
import { useStore } from 'effector-react';
import {
  containerTypeStore,
  getContainerTypes,
  deleteContainerType,
  updateContainerTypesColumns,
} from '../containerTypes.store';
import { showDialog } from '../../common/dialog.store';
import { Confirm } from '../../common/components/confirm/confirm.component';
import {
  CREATE_CONTAINERTYPE_LINK_KEY,
  DELETE_CONTAINERTYPE_LINK_KEY,
  GetContainerTypeParams,
} from '../containerTypes.service';
import { useHistory } from 'react-router-dom';
import { ContainerTypeDialog } from './containerType.dialog';
import { userHas } from '../../auth/auth.store';

export type ContainerTypesListProps = {
  goToDetails?: (containerTypeParams: GetContainerTypeParams) => void;
  offset?: number;
  limit?: number;
  search?: any;
  filter?: string;
  sort?: any;
  onDelete?: () => void;
  onPageChanged?: (page: number) => void;
  onSort?: (field: string) => void;
  onSelect?: (
    containerType: ContainerTypeDto,
    containerTypeParams: GetContainerTypeParams,
  ) => void;
  onFilter?: (query: string) => void;
  onSearch?: (query: string) => void;
  isDropDownList?: boolean;
};

export const ContainerTypesList = ({
  goToDetails = () => {},
  offset = 0,
  limit = 20,
  search = {},
  sort = null,
  onDelete = () => {},
  onPageChanged = () => {},
  onSort = () => {},
  onSelect,
  filter = null,
  onFilter = () => {},
  onSearch,
  isDropDownList = false,
}: ContainerTypesListProps) => {
  const [
    containerTypes,
    setContainerTypes,
  ] = useState<ContainerTypeDtoPagedResult | null>(null);

  useEffect(() => {
    getContainerTypesData();
  }, [offset, limit, sort, filter, search]);
  const getContainerTypesData = () => {
    getContainerTypes({ offset, limit, sort, filter, search }).then(
      (containerTypesData) => {
        setContainerTypes(containerTypesData);
      },
      () => {},
    );
  };
  const { containerTypeColumns: columns } = useStore(containerTypeStore);

  const onDeleteContainerType = (containerType: ContainerTypeDto) => {
    showDialog({
      dialog: Confirm,
      props: {
        title: `Delete ${containerType.containerTypeCode} Container Type`,
        message: 'Are you sure you want to delete?',
        className: 'delete-modal',
      },
    }).then(
      (result) => {
        if (result) {
          deleteContainerType(containerType).then(
            () => {
              onDelete();
              getContainerTypesData();
            },
            () => {},
          );
        }
      },
      () => {},
    );
  };

  const onEditContainerType = (containerType: ContainerTypeDto) => {
    goToDetails({ containerTypeCode: containerType.containerTypeCode });
  };
  const history = useHistory();
  const onCreateNewContainerType = () => {
    goToDetails({ containerTypeCode: null });
  };
  const onContainerTypeSelect = (containerType, containerTypeId) => {
    if (onSelect) onSelect(containerType, containerTypeId);
    showDialog({
      dialog: ContainerTypeDialog,
      props: {
        containerTypeCode: containerType.containerTypeCode,
        title: 'Update Container Type',
      },
    }).then(
      (containerType) => {
        if (containerType !== null) {
          getContainerTypesData();
        }
      },
      () => {},
    );
  };

  return (
    <>
      <Grid
        isDropDownList={isDropDownList}
        onSearch={onSearch}
        search={search}
        showEmptyTable={true}
        showAllStore={true}
        rowKeys={['containerTypeCode']}
        data={containerTypes?.items}
        columns={columns}
        offset={offset}
        limit={limit}
        total={containerTypes?.totalCount}
        sort={sort}
        filter={filter}
        onFilter={onFilter}
        onDelete={
          userHas(
            DELETE_CONTAINERTYPE_LINK_KEY,
            containerTypes?.items[0]?.links,
          )
            ? onDeleteContainerType
            : null
        }
        onSort={onSort}
        onEdit={onEditContainerType}
        onPageChanged={onPageChanged}
        onColumnsChanged={updateContainerTypesColumns}
        onSelect={onContainerTypeSelect}
      />
      {!(
        (containerTypes &&
          containerTypes.items &&
          containerTypes.items.length) ||
        filter ||
        search
      ) && (
        <div className="m-5 text-center">
          <h3 className="text-muted mb-4">
            You Don't Have Any Container Type Yet
          </h3>
          {userHas(CREATE_CONTAINERTYPE_LINK_KEY) && (
            <Button
              size={'lg'}
              color="primary"
              onClick={onCreateNewContainerType}
            >
              Create Container Type
            </Button>
          )}
        </div>
      )}
    </>
  );
};
